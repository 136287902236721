import React, { Component } from 'react';
import Switch from 'react-switch';
import { Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';

import './ActiveStatusModal.css';

class ActiveStatusModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      success: false,
      isActive: this.props.isActive,
      modalType: this.props.modalType,
      errors: {},
    };

    this.toggleSuccess = this.toggleSuccess.bind(this);
    this.saveSuccess = this.saveSuccess.bind(this);
  }

  saveSuccess() {
    this.props.onClickSubmit(this.state.isActive);
    this.setState({
      success: !this.state.success,
    });
  }

  renderAppSwitch(activeStatus) {
    const statusErrors = this.props.errors || this.props.getErrors();
    const isUrlMissing = statusErrors ? statusErrors.isUrlMissing : false;
    const isUserAttributeMissing = statusErrors ? statusErrors.isUserAttributeMissing : false;
    const isDisabled =
      this.props.isActive === null ||
      this.props.disabled === true ||
      isUrlMissing === true ||
      isUserAttributeMissing === true;
    return (
      <Switch
        className={'react-switch'}
        disabled={isDisabled}
        onChange={this.toggleSuccess}
        checked={activeStatus !== null ? activeStatus : false}
        onColor={'#4dbd74'}
        offColor={'#f86c6b'}
        width={70}
        //  handleDiameter={28}
        checkedIcon={
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '96%',
              fontSize: 11,
            }}
          >
            Active
          </div>
        }
        uncheckedIcon={
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '96%',
              fontSize: 11,
            }}
          >
            Inactive
          </div>
        }
      />
    );
  }

  toggleSuccess = (isCancel) => {
    return this.setState({
      success: !this.state.success,
      isActive: !this.state.isActive,
    });
    // }
  };

  componentWillReceiveProps(nextProps) {
    this.setState({
      isActive: nextProps.isActive,
    });
  }

  render() {
    const modalType = this.state.modalType;

    const { isActive } = this.state;
    return (
      <>
        {this.renderAppSwitch(isActive)}
        <div className='animated float-right'>
          <Modal
            isOpen={this.state.success}
            toggle={() => this.toggleSuccess(true)}
            className={'modal-danger ' + this.props.className}
          >
            <ModalHeader toggle={() => this.toggleSuccess(true)}>Active Status</ModalHeader>
            <ModalBody>
              <Row>
                <Col
                  lg={2}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    color: '#f86c6b',
                  }}
                >
                  <i className='fa fa-warning fa-3x'></i>
                </Col>
                <Col lg={10}>
                  <h3>{this.props.content && this.props.content.name}</h3>
                  Are you sure you want to
                  {!this.state.isActive ? ' deactivate ' : ' activate '} this
                  {modalType}?
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter>
              <Button color='danger' onClick={this.saveSuccess}>
                {!this.state.isActive ? 'Deactivate ' : 'Activate '}
              </Button>{' '}
              <Button color='secondary' onClick={() => this.toggleSuccess(true)}>
                Cancel
              </Button>
            </ModalFooter>
          </Modal>
        </div>
      </>
    );
  }
}
export default ActiveStatusModal;
