import _fp from 'lodash/fp';
import React, { useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import { Col, Input, Row } from 'reactstrap';
import SearchLabel from '../../components/SearchLabel/SearchLabel';
import './Table.scss';
import ZuulLoader from '../ZuulLoader/ZuulLoader';

const mapWithIndex = _fp.map.convert({ cap: false });

/**
 * Handles display of a table of some entity like offers, landing pages, offer actions, etc.
 *
 * @param entity an object containing data about the entity and some entity specific configuration for the table
 * @param handleSearchChange function that handles filtering of data
 * @param loading boolean if data is loading
 * @param searchTerm a string containing the search term. will be provided to handleSearchChange.
 * @param paginationConfig  configuration for bootstrap-table-next paginator
 *                          https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/pagination-props.html#paginationsizeperpage-number
 * @param remoteConfig  configuration for bootstrap-table-next remote
 *                      https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/table-props.html#remote-bool-object
 * @param onTableChange function used to communicate with the table when remote is enabled
 *                      https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/table-props.html#ontablechange-function
 * @param tableHeaderItems  additional components that go alongside the search bar at the top of the table
 * @returns {JSX.Element} the table
 */
const TableCard = ({
  entity,
  handleSearchChange,
  loading,
  searchTerm,
  paginationConfig,
  remoteConfig,
  onTableChange,
  tableHeaderItems,
}) => {
  const [activeRow, setActiveRow] = useState(null);

  const Search = () => (
    <div className='table-search'>
      <SearchLabel className='table-search--label' />
      <Input
        type='text'
        id={`${entity.entityName}Search`}
        name={`${entity.entityName}Search`}
        className='table-search--input'
        placeholder='Use spaces to separate search terms'
        onChange={handleSearchChange}
        value={searchTerm}
        aria-label={`${entity.entityName}Search`}
      />
    </div>
  );

  const _tableHeaderItems = [Search, ...tableHeaderItems];

  const handleRowEvents = {
    onMouseEnter: (e, row, rowIndex) => {
      setActiveRow(rowIndex);
    },
  };

  const handleRowStyle = (row, rowIndex) => {
    if (activeRow === rowIndex) {
      return { cursor: 'pointer' };
    }
  };

  return (
    <div>
      <ToolkitProvider keyField='id' data={entity.data} columns={entity.columns} search bootstrap4={true}>
        {(props) => {
          return (
            <div>
              {loading === true ? (
                <ZuulLoader text={`Loading ${entity.entityName}s...`} />
              ) : (
                <div>
                  <div className='table-header'>
                    {mapWithIndex((item, idx) => (
                      <div key={idx} className='table-header--item'>
                        {item()}
                      </div>
                    ))(_tableHeaderItems)}
                  </div>
                  <hr />
                  <Row>
                    <Col lg={12}>
                      <BootstrapTable
                        hover
                        headerClasses='header-class'
                        {...props.baseProps}
                        remote={remoteConfig}
                        pagination={paginationFactory(paginationConfig)}
                        rowStyle={handleRowStyle}
                        rowEvents={handleRowEvents}
                        onTableChange={onTableChange}
                        defaultSorted={entity.defaultSorted}
                      />
                    </Col>
                  </Row>
                </div>
              )}
            </div>
          );
        }}
      </ToolkitProvider>
    </div>
  );
};

export default TableCard;
