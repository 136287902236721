import api from '../index';

const createPublisherPrePing = async publisherPrePing => {
  const resp = await api.post(`publisher-pre-pings`, publisherPrePing).catch(savedPublisherPrePingError => {
    return savedPublisherPrePingError.response.data.message;
  });

  return resp.status === 200;
};

const updatePublisherPrePing = async publisherPrePing => {
  const resp = await api
    .put(`publisher-pre-pings/${publisherPrePing.id}`, publisherPrePing)
    .catch(savedPublisherPrePingError => {
      return savedPublisherPrePingError.response.data.message;
    });

  return resp.status === 200;
};

const updatePublisherPrePingActiveStatus = async publisherPrePing => {
  const resp = await api.put(`publisher-pre-pings/active/${publisherPrePing.id}`, publisherPrePing);
  return resp.data;
};

const getPublisherPrePing = async publisherPrePingId => {
  const resp = await api.get(`publisher-pre-pings/${publisherPrePingId}`);

  return resp.data;
};

const getAllAdvertiserPrePings = async () => {
  const resp = await api.get(`advertiser-pre-pings`);

  return resp.data.content;
};

const getAllUserAttributes = async () => {
  const resp = await api.get(`user-attribute`);

  return resp.data;
};

const getAllHandlers = async () => {
  const resp = await api.get(`handlers`);

  return resp.data;
};

const getAllDomains = async () => {
  const resp = await api.get(`domains`);

  return resp.data;
};



export {
  getPublisherPrePing,
  getAllAdvertiserPrePings,
  updatePublisherPrePing,
  updatePublisherPrePingActiveStatus,
  createPublisherPrePing,
  getAllUserAttributes,
  getAllDomains,
  getAllHandlers
};
