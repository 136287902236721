import { handleCriteriaSubmit } from '../../utils/CriteriaHelpers';

export const parsePPPFormValues = (formValues, publisherPrePingId) => {
  const clonedPubPrePingCriteria = Object.assign({}, formValues.publisherPrePingCriteria);
  const publisherPrePingCriteria = handleCriteriaSubmit(clonedPubPrePingCriteria);
  const publisherPrePing = {
    active: true,
    name: formValues.name,
    advertiserPrePings: formValues.advertiserPrePings?.map((advertiserPrePing) => {
      if (advertiserPrePing.id === null) {
        advertiserPrePing = advertiserPrePing.name;
      }
      return advertiserPrePing;
    }),
    publisherPrePingCriteria,
    // use handleCriteriaSubmit function after we rename all the criteria fields
    vanityUrl: formValues.vanityUrl === '' ? null : formValues.vanityUrl,
    acceptAllNewUserEmails: formValues.acceptAllNewUserEmails === false ? formValues.acceptAllNewUserEmails : true,
  };
  if (formValues.id) publisherPrePing.id = publisherPrePingId;
  return publisherPrePing;
};
