import { useState } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { Button, Col, Input, Row, FormFeedback } from 'reactstrap';
import { isValidName } from '../LayoutsDetails';

const CopyLayoutModal = ({ isOpen, layout, closeModal, onCopy }) => {
  const [name, setName] = useState(layout.name + '_copy');

  const handleNameChange = (event) => {
    setName(event.target.value);
  };
  return (
    <Modal isOpen={isOpen} className={'modal-warning'}>
      <ModalHeader toggle={closeModal}>Copy</ModalHeader>
      <ModalBody>
        <Row>
          <Col
            lg={2}
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              color: '#ffc107',
            }}
          >
            <i className='fa fa-warning fa-3x'></i>
          </Col>
          <Col lg={10}>
            Any changes made without saving will be <strong>copied</strong> to the new layout but not reflected in the{' '}
            <strong>current</strong> layout until you click save offer.
          </Col>
        </Row>
        <br />
        <Row>
          <Col lg={12}>
            New Layout Name:
            <Input
              name='newLayoutName'
              placeholder='New Layout Name'
              type='text'
              value={name}
              onChange={handleNameChange}
              invalid={!isValidName(name)}
            />
            <FormFeedback>
              {'The name cannot be empty or contain spaces nor any of the following characters: <>:"/\\|?*'}
            </FormFeedback>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button
          color='warning'
          disabled={!isValidName(name)}
          onClick={() => {
            closeModal();
            onCopy({ ...layout, name: name, id: undefined });
          }}
        >
          Copy
        </Button>
        <Button color='secondary' disabled={false} onClick={closeModal}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default CopyLayoutModal;
