import { isBefore, parse } from 'date-fns';
import { convertDailyRestrictionToArray, convertDailyRestrictionToJson } from './conversionFunctions';

export function handleCriteriaAffNames(affNames) {
  return affNames
    ? affNames.map((affName) => {
        if (typeof affName === 'object') {
          return affName.name;
        } else {
          return affName === '' || affName === '[None]' ? { name: '[None]', id: null } : affName;
        }
      })
    : [];
}

export function handleCriteriaDomains(domains) {
  return domains
    ? domains.map((domain) => {
        if (typeof domain === 'object' && domain.name) {
          return domain.name;
        } else {
          return domain;
        }
      })
    : [];
}

export const handleCriteriaDomainAffiliateNames = (entries) => {
  if (!entries || typeof entries !== 'object') return null;
  if (entries.hasOwnProperty('domainAffiliateEntries')) {
    const domainAffiliateEntries = entries.domainAffiliateEntries.map((entry) => {
      const { affiliates, domains, ...rest } = entry;

      const processedAffiliates = affiliates?.map((affiliate) =>
        typeof affiliate === 'object' && affiliate.name ? affiliate.name : affiliate
      );

      const processedDomains = domains?.map((domain) =>
        typeof domain === 'object' && domain.name ? domain.name : domain
      );

      return {
        ...rest,
        ...(processedAffiliates ? { affiliates: processedAffiliates } : {}),
        ...(processedDomains ? { domains: processedDomains } : {}),
      };
    });

    return {
      ...entries,
      domainAffiliateEntries: domainAffiliateEntries,
    };
  } else {
    return entries;
  }
};

export function handleCriteriaInitialize(criteria, additionalOffersObj) {
  /*
			This function is used to handle the initialization of the various forms of criteria data for offers, offer actions, offer wall ads, offer blocks and publisher pre-pings.
			The criteria field names for each entity should now be uniform across all entities. The differences lie in the data model.
			TODO: Unify all implementations of criteria data storage into a single table. Will require a migration. from all three tables to a new table or to the offer_criteria table.
		 */

  if (!criteria) return null;
  console.log(criteria);
  const dayHourRestrictions =
    criteria.criteriaDayHourRestrictions !== null && criteria.criteriaDayHourRestrictions
      ? convertDailyRestrictionToArray(criteria.criteriaDayHourRestrictions)
      : criteria.dayHourRestrictions !== null && criteria.dayHourRestrictions
      ? convertDailyRestrictionToArray(criteria.dayHourRestrictions)
      : [];

  // suppressionRules is represented as a JSON string in the database for offers and multiple columns for offerCrtieria so we need to handle the separate cases accordingly
  const suppressionRulesOffer =
    criteria.suppressionRules &&
    criteria.suppressionRules !== null &&
    JSON.stringify(criteria.suppressionRules) !== '{}'
      ? JSON.parse(criteria.suppressionRules, function(k, v) {
          return typeof v === 'object' || isNaN(v) ? v : parseInt(v, 10);
        })
      : null;

  const {
    prePingSuppressionAmount,
    prePingSuppressionLevel,
    prePingSuppressionType,
    prePingSuppressionMaxLimit,
    prePingSuppressionAnswer,
    additionOffers,
    additionalOffers,
    additionalOfferActions,
  } = criteria;
  const duration = {};
  let suppressionRulesOfferCriteria = {};
  if (prePingSuppressionAmount || prePingSuppressionLevel) {
    suppressionRulesOfferCriteria = { duration };
  }
  if (prePingSuppressionAmount) {
    duration.amount = prePingSuppressionAmount;
  }
  if (prePingSuppressionType) {
    duration.type = prePingSuppressionType;
  }

  if (prePingSuppressionLevel) {
    suppressionRulesOfferCriteria.suppression_type = prePingSuppressionLevel;
  }

  if (prePingSuppressionMaxLimit) {
    duration.max_limit = prePingSuppressionMaxLimit;
  }

  if (prePingSuppressionAnswer) {
    suppressionRulesOfferCriteria.suppression_answer = prePingSuppressionAnswer;
  }

  // TODO: Check that this is properly mapped
  let addtlOffers = additionalOffers ?? additionOffers ?? null;
  if (addtlOffers) {
    console.log(addtlOffers);
    let additionalOffersArray = addtlOffers.map((offer) => {
      return offer[0] === '{' ? JSON.parse(offer) : handleAdditionalOfferInts(offer, additionalOffersObj);
    });
    additionalOffersArray = additionalOffersArray.filter((offer) => offer !== null);
    if (additionalOffersArray.length > 0) {
      suppressionRulesOfferCriteria.additional_offer_ids = additionalOffersArray;
    }
  }

  if (additionalOfferActions && additionalOfferActions.length > 0) {
    suppressionRulesOfferCriteria.additional_offer_action_ids = additionalOfferActions;
  }

  const criteriaObjInit = {
    //Users
    triggersRegistration: criteria.triggersRegistration ?? false,
    criteriaTcpaLastOptInInterval:
      criteria.criteriaTcpaLastOptInInterval ??
      criteria.tcpaLastOptInInterval ??
      criteria.tcpaOptInRequiredOverLastInterval ??
      false,
    requiresOptInBeforeSend: criteria.requiresOptInBeforeSend ?? false,
    criteriaOfferRequiresOptIn: criteria.criteriaOfferRequiresOptIn ?? criteria.tcpaOptInRequired ?? false,
    criteriaPhoneHasBeenVerifiedWithBriteverify:
      criteria.criteriaPhoneHasBeenVerifiedWithBriteverify ?? criteria.briteVerifyPhoneValidated ?? false,
    criteriaEmailHasBeenVerifiedWithBriteverify:
      criteria.criteriaEmailHasBeenVerifiedWithBriteverify ?? criteria.briteVerifyEmailValidated ?? false,
    criteriaPhoneBlacklist: criteria.criteriaPhoneBlacklist ?? criteria.phoneBlacklist ?? false,

    criteriaMaxAge: criteria.criteriaMaxAge ?? criteria.maxAge ?? null,
    criteriaMinAge: criteria.criteriaMinAge ?? criteria.minAge ?? null,
    criteriaExcludeBrowserType: criteria.criteriaExcludeBrowserType ?? criteria.browserType ?? null,

    criteriaIncludeOrExcludeBrowsers:
      criteria.criteriaIncludeOrExcludeBrowsers ?? criteria.browsersIncludeExclude ?? null,
    criteriaIncludeOrExcludeDomains: criteria.criteriaIncludeOrExcludeDomains ?? criteria.domainsIncludeExclude ?? null,
    criteriaIncludeOrExcludeDevices: criteria.criteriaIncludeOrExcludeDevices ?? criteria.devicesIncludeExclude ?? null,
    criteriaIncludeOrExcludeOperatingSystems:
      criteria.criteriaIncludeOrExcludeOperatingSystems ?? criteria.operatingSystemIncludeExclude ?? null,

    criteriaNullSourceNewUserIncludeExclude:
      criteria.criteriaNullSourceNewUserIncludeExclude ?? criteria.nullSourceNewUserIncludeExclude ?? null,
    criteriaIncludeOrExcludeUserIdLastDigit:
      criteria.criteriaIncludeOrExcludeUserIdLastDigit ?? criteria.userIdLastDigitIncludeExclude ?? null,
    criteriaUserIdLastDigit: criteria.criteriaUserIdLastDigit ?? criteria.userIdLastDigitValues ?? [],

    criteriaBrowsers: criteria.criteriaBrowsers ?? criteria.browsers ?? null,
    criteriaDomains: criteria.criteriaDomains ?? criteria.domains ?? null,
    criteriaDevices: criteria.criteriaDevices ?? criteria.devices ?? null,
    criteriaOperatingSystems: criteria.criteriaOperatingSystems ?? criteria.operatingSystems ?? null,

    criteriaExcludeGender: criteria.criteriaExcludeGender ?? criteria.genderValue ?? null,
    criteriaIncludeOrExcludeStates: criteria.criteriaIncludeOrExcludeStates ?? criteria.statesIncludeExclude ?? null,
    criteriaIncludeOrExcludeZips: criteria.criteriaIncludeOrExcludeZips ?? criteria.zipsIncludeExclude ?? null,

    criteriaZips:
      criteria.criteriaZips && Array.isArray(criteria.criteriaZips)
        ? criteria.criteriaZips.join('\n')
        : criteria.zipsValues && Array.isArray(criteria.zipsValues)
        ? criteria.zipsValues.join('\n')
        : criteria.criteriaZips || !criteria.zipsValues
        ? criteria.criteriaZips || criteria.zipsValues
        : null,

    criteriaAibListIncludeExclude: criteria.criteriaAibListIncludeExclude ?? criteria.aibListIncludeExclude ?? null,
    criteriaAibList: criteria.criteriaAibList ?? criteria.aibList ?? null,
    domainAffiliateConfiguration:
      criteria.domainAffiliateConfiguration === null ||
      JSON.stringify(criteria.domainAffiliateConfiguration?.domainAffiliateEntries) === '[]'
        ? null
        : criteria.domainAffiliateConfiguration,
    previousRegistrationConfiguration:
      criteria.previousRegistrationConfiguration === null ||
      JSON.stringify(criteria.previousRegistrationConfiguration?.previousRegistrations) === '[]'
        ? null
        : {
            ...criteria.previousRegistrationConfiguration,
            andOrLogic: criteria.previousRegistrationConfiguration?.andOrLogic
              ? criteria.previousRegistrationConfiguration.andOrLogic.toUpperCase()
              : null,
            previousRegistrations:
              criteria.previousRegistrationConfiguration?.previousRegistrations?.map((reg) => ({
                ...reg,
                includeExcludeAction:
                  reg?.includeExcludeAction !== null
                    ? reg?.includeExcludeAction.charAt(0).toUpperCase() + reg?.includeExcludeAction.slice(1)
                    : null,
              })) ?? [],
          },
    criteriaUserDataIncludeExclude:
      criteria.criteriaUserDataIncludeExclude ?? criteria.requiredUserDataIncludeExclude ?? null,
    criteriaRequiredUserData:
      criteria.criteriaRequiredUserData && criteria.criteriaRequiredUserData !== null
        ? criteria.criteriaRequiredUserData?.split(',')
        : criteria.requiredUserDataValues ?? null,
    criteriaEmailDomains: criteria.criteriaEmailDomains?.split(',') ?? criteria.emailDomains ?? null,
    // User Criteria
    criteriaQuestion: criteria.criteriaQuestion ?? criteria.offerQuestion ?? null,
    criteriaQuestionAnswers: criteria.criteriaQuestionAnswers ?? criteria.offerQuestionAnswers ?? null,
    criteriaIncludeOrExcludeUserAttributes:
      criteria.criteriaIncludeOrExcludeUserAttributes ?? criteria.userAttributesIncludeExclude ?? null,
    userAttributeRules: criteria.userAttributeRules ?? null,
    criteriaIncludeOrExcludeAffNames:
      criteria.criteriaIncludeOrExcludeAffNames ?? criteria.affiliateNamesIncludeExclude ?? null,
    criteriaAffNames:
      criteria.criteriaAffNames && criteria.criteriaAffNames !== null
        ? handleCriteriaAffNames(criteria.criteriaAffNames)
        : criteria.affiliateNames && criteria.affiliateNames != null
        ? handleCriteriaAffNames(criteria.affiliateNames)
        : [],
    criteriaStates: criteria.criteriaStates ?? criteria.statesValues ?? null,

    criteriaIncludeOrExcludeEmailDomains:
      criteria.criteriaIncludeOrExcludeEmailDomains ?? criteria.emailDomainsIncludeExclude ?? null,

    criteriaLandingPageGroupIncludeExclude:
      criteria.criteriaLandingPageGroupIncludeExclude ?? criteria.landingPageGroupIncludeExclude ?? null,
    criteriaLandingPageGroupIds: criteria.criteriaLandingPageGroupIds ?? criteria.landingPageGroupIds ?? null,
    criteriaLandingPageIncludeExclude:
      criteria.criteriaLandingPageIncludeExclude ?? criteria.landingPageIncludeExclude ?? null,
    criteriaLandingPageIds: criteria.criteriaLandingPageIds ?? criteria.landingPageIds ?? null,

    // Pre Ping
    payoutType: criteria.payoutType?.id ?? null,
    payoutAmount: criteria.payoutAmount ?? null,
    capAmount: criteria.capAmount ?? 0,
    capInterval: criteria.capInterval ?? null,
    capSegmentConfiguration:
      criteria.capSegmentConfiguration === null ||
      JSON.stringify(criteria.capSegmentConfiguration?.capSegments) === '[]'
        ? null
        : criteria.capSegmentConfiguration,
    domainLevelPrePing: criteria.domainLevelPrePing ?? null,
    prePing: criteria.prePing ?? criteria.prePingUrl ?? null,
    prePingMethod: criteria.prePingMethod ?? criteria.prePingType ?? null,
    prePingResultSessionCapture: criteria.prePingResultSessionCapture ?? null,
    prePingHandlerId: criteria.prePingHandlerId ?? criteria.prePingHandler ?? null,
    prePingCustomHttpStatus: criteria.prePingCustomHttpStatus ?? null,
    prePingBody: criteria.prePingBody ?? null,
    prePingTimeout: criteria.prePingTimeout ?? null,
    prePingHeader1: criteria.prePingHeader1 ?? null,
    prePingHeader2: criteria.prePingHeader2 ?? null,
    prePingHeader3: criteria.prePingHeader3 ?? null,
    prePingSuppressionAmount: prePingSuppressionAmount ?? null,
    prePingSuppressionLevel: prePingSuppressionLevel ?? null,
    prePingSuppressionType: prePingSuppressionType ?? null,
    prePingSuppressionMaxLimit: prePingSuppressionMaxLimit ?? null,

    // Suppression
    suppressionRules: suppressionRulesOffer ?? suppressionRulesOfferCriteria ?? null,
    criteriaDailyLimit: criteria.criteriaDailyLimit ?? criteria.dailyLimit ?? null,
    criteriaDailyLimitAnswers: criteria.criteriaDailyLimitAnswers ?? criteria.dailyLimitAnswers ?? null,
    criteriaDayHourRestrictions: dayHourRestrictions,
  };
  console.log('Criteria', criteriaObjInit);
  return criteriaObjInit;
}

function replacer(key, value) {
  // console.log(key, value);
  // Filtering out properties
  if (key === 'amount') {
    return parseInt(value);
  }
  if (key === 'additional_offer_ids' && Array.isArray(value) && value !== null) {
    return value.map((additionalOffer) => (Number.isInteger(additionalOffer) ? additionalOffer : additionalOffer.id));
  }
  return value;
}

export function handleCriteriaSubmit(values, handlers) {
  /*
			This function is used to handle/sanitize the various forms of criteria data for offers, offer actions, offer wall ads, offer blocks and publisher pre-pings.
			The offer's criteria is stored directly on the offer table, where as the offer action, offer wall ad, offer block criteria data is stored on the offer_criteria table.
			The publisher pre-ping criteria is stored on the publisher_pre_ping_criteria table.
			TODO: Unify all implementations of criteria data storage into a single table. Will require a migration. from all three tables to a new table or to the offer_criteria table.
		 */
  let {
    criteriaAffNames,
    criteriaIncludeOrExcludeAffNames,
    criteriaIncludeOrExcludeUserAttributes,
    criteriaNullSourceNewUserIncludeExclude,
    criteriaIncludeOrExcludeUserIdLastDigit,
    criteriaReturnFrequencyIncludeExclude,
    suppressionRules,
  } = values;

  let criteriaQuestionValue = null;
  if (values.criteriaQuestion !== undefined && values.criteriaQuestion !== null) {
    if (values.criteriaQuestion.name !== undefined && values.criteriaQuestion.name !== null) {
      criteriaQuestionValue = values.criteriaQuestion.name === null ? null : values.criteriaQuestion.name;
    } else {
      criteriaQuestionValue = values.criteriaQuestion === null ? null : values.criteriaQuestion;
    }
  }

  if (
    criteriaIncludeOrExcludeUserAttributes === null ||
    !criteriaIncludeOrExcludeUserAttributes ||
    criteriaIncludeOrExcludeUserAttributes === 'None'
  ) {
    criteriaIncludeOrExcludeUserAttributes = null;
  }

  const isDayHoursRestrictions =
    values.criteriaDayHourRestrictions && values.criteriaDayHourRestrictions.length > 0
      ? Object.keys(values.criteriaDayHourRestrictions[0]).length > 0
      : null;

  let prePingHandler = null;
  let prePingHandlerValue =
    typeof values.prePingHandlerId === 'string'
      ? findIdFromName(handlers, values.prePingHandlerId)
      : values.prePingHandlerId;
  if (prePingHandlerValue === undefined) prePingHandler = null;
  else {
    prePingHandler =
      typeof values.prePingHandlerId === 'string'
        ? { id: prePingHandlerValue, name: values.prePingHandlerId }
        : prePingHandlerValue;
  }

  const prePingMethod =
    values.prePingMethod && values.prePingMethod !== null && values.prePingMethod !== 'NONE'
      ? values.prePingMethod
      : null;

  let suppressionRulesOffer = null;
  let suppressionDuration = null;
  if (
    suppressionRules &&
    suppressionRules.duration &&
    suppressionRules.duration.type &&
    suppressionRules.duration.type.toLowerCase() !== 'none'
  ) {
    // if (!suppressionRules.hasOwnProperty('suppression_type') || suppressionRules.suppression_type === 'select') {
    //   suppressionRules['suppression_type'] = 'none';
    // }
    suppressionRulesOffer = JSON.stringify(values.suppressionRules, replacer);
    suppressionDuration = suppressionRules.duration;
  } else {
    suppressionRules = null;
  }

  let zipsValues =
    values.criteriaZips && typeof values.criteriaZips !== 'string'
      ? values.criteriaZips
      : typeof values.criteriaZips === 'string'
      ? values.criteriaZips.split(/\D/)
      : [];
  zipsValues = zipsValues
    .map((zip) =>
      zip
        .split('')
        .map((char) => char.replace(/\D/g, ''))
        .join('')
    )
    .filter((el) => el !== '');

  const additionalOffers =
    suppressionRules && suppressionRules.additional_offer_ids
      ? parseAdditionalOffers(suppressionRules.additional_offer_ids)
      : [];

  const additionalOfferActions =
    suppressionRules && suppressionRules.additional_offer_action_ids
      ? parseAdditionalOffers(suppressionRules.additional_offer_action_ids)
      : [];

  const criteriaObj = {
    aibListIncludeExclude:
      !values.criteriaAibListIncludeExclude || values.criteriaAibListIncludeExclude === 'None'
        ? null
        : values.criteriaAibListIncludeExclude,
    aibList:
      !values.criteriaAibListIncludeExclude ||
      values.criteriaAibListIncludeExclude === 'None' ||
      values.criteriaAibList === null
        ? null
        : values.criteriaAibList,
    additionalOffers,
    additionOffers: additionalOffers,
    additionalOfferActions,
    affiliateNames: criteriaIncludeOrExcludeAffNames === 'None' ? [] : handleCriteriaAffNames(criteriaAffNames),
    affiliateNamesIncludeExclude: criteriaIncludeOrExcludeAffNames === 'None' ? null : criteriaIncludeOrExcludeAffNames,
    ageEnabled: values.criteriaMinAge || values.criteriaMaxAge ? true : false,
    briteVerifyEmailValidated: values.criteriaEmailHasBeenVerifiedWithBriteverify,
    briteVerifyPhoneValidated: values.criteriaPhoneHasBeenVerifiedWithBriteverify,
    browserType: values.criteriaExcludeBrowserType === 'null' ? null : values.criteriaExcludeBrowserType,
    browsers: values.criteriaIncludeOrExcludeBrowsers === 'None' ? null : values.criteriaBrowsers,
    browsersIncludeExclude:
      values.criteriaIncludeOrExcludeBrowsers === 'None' ? null : values.criteriaIncludeOrExcludeBrowsers,
    capAmount: values.capAmount ? parseInt(values.capAmount) : null,
    capInterval: values.capInterval ? values.capInterval : null,
    capSegmentConfiguration:
      !values.capSegmentConfiguration || JSON.stringify(values.capSegmentConfiguration?.capSegments) === '[]'
        ? null
        : values.capSegmentConfiguration,
    criteriaAibListIncludeExclude:
      !values.criteriaAibListIncludeExclude || values.criteriaAibListIncludeExclude === 'None'
        ? null
        : values.criteriaAibListIncludeExclude,
    criteriaAibList:
      !values.criteriaAibListIncludeExclude ||
      values.criteriaAibListIncludeExclude === 'None' ||
      values.criteriaAibList === null
        ? null
        : values.criteriaAibList,
    criteriaBrowsers: values.criteriaIncludeOrExcludeBrowsers === 'None' ? null : values.criteriaBrowsers,
    criteriaDailyLimit: values.criteriaDailyLimit ?? null,
    criteriaDailyLimitAnswers: values.criteriaDailyLimitAnswers?.length ? values.criteriaDailyLimitAnswers : null,
    criteriaDayHourRestrictions: isDayHoursRestrictions
      ? JSON.stringify(convertDailyRestrictionToJson(values.criteriaDayHourRestrictions))
      : null,
    criteriaDevices: values.criteriaIncludeOrExcludeDevices === 'None' ? null : values.criteriaDevices,
    criteriaDomains:
      values.criteriaIncludeOrExcludeDomains === 'None' ? null : handleCriteriaDomains(values.criteriaDomains),
    criteriaEmailDomains:
      values.criteriaIncludeOrExcludeEmailDomains === 'None'
        ? null
        : Array.isArray(values.criteriaEmailDomains) && values.criteriaEmailDomains.length
        ? values.criteriaEmailDomains.join(',')
        : typeof values.criteriaEmailDomains === 'string'
        ? values.criteriaEmailDomains
        : null,
    criteriaExcludeBrowserType: values.criteriaExcludeBrowserType === 'null' ? null : values.criteriaExcludeBrowserType,
    criteriaExcludeGender: values.criteriaExcludeGender === 'null' ? null : values.criteriaExcludeGender,
    criteriaIncludeOrExcludeAffNames:
      criteriaIncludeOrExcludeAffNames === 'None' ? null : criteriaIncludeOrExcludeAffNames,
    criteriaIncludeOrExcludeBrowsers:
      values.criteriaIncludeOrExcludeBrowsers === 'None' ? null : values.criteriaIncludeOrExcludeBrowsers,
    criteriaIncludeOrExcludeDevices:
      values.criteriaIncludeOrExcludeDevices === 'None' ? null : values.criteriaIncludeOrExcludeDevices,
    criteriaIncludeOrExcludeDomains:
      values.criteriaIncludeOrExcludeDomains === 'None' ? null : values.criteriaIncludeOrExcludeDomains,
    criteriaIncludeOrExcludeEmailDomains:
      values.criteriaIncludeOrExcludeEmailDomains === 'None' ? null : values.criteriaIncludeOrExcludeEmailDomains,
    criteriaIncludeOrExcludeOperatingSystems:
      values.criteriaIncludeOrExcludeOperatingSystems === 'None'
        ? null
        : values.criteriaIncludeOrExcludeOperatingSystems,
    criteriaIncludeOrExcludeStates:
      values.criteriaIncludeOrExcludeStates === 'None' ? null : values.criteriaIncludeOrExcludeStates,
    criteriaIncludeOrExcludeUserAttributes,
    criteriaIncludeOrExcludeZips:
      values.criteriaIncludeOrExcludeZips === 'None' ? null : values.criteriaIncludeOrExcludeZips,
    criteriaNullSourceNewUserIncludeExclude:
      criteriaNullSourceNewUserIncludeExclude === 'None' ? null : criteriaNullSourceNewUserIncludeExclude,
    criteriaOperatingSystems:
      values.criteriaIncludeOrExcludeOperatingSystems === 'None' ? null : values.criteriaOperatingSystems,
    criteriaPhoneBlacklist: values.criteriaPhoneBlacklist || false,

    criteriaAffNames:
      !criteriaAffNames || criteriaIncludeOrExcludeAffNames === 'None' ? [] : handleCriteriaAffNames(criteriaAffNames),

    criteriaIncludeOrExcludeUserIdLastDigit:
      !criteriaIncludeOrExcludeUserIdLastDigit || criteriaIncludeOrExcludeUserIdLastDigit === 'None'
        ? null
        : criteriaIncludeOrExcludeUserIdLastDigit,
    criteriaLandingPageGroupIncludeExclude:
      !values.criteriaLandingPageGroupIncludeExclude || values.criteriaLandingPageGroupIncludeExclude === 'None'
        ? null
        : values.criteriaLandingPageGroupIncludeExclude,
    criteriaLandingPageGroupIds:
      !values.criteriaLandingPageGroupIncludeExclude || values.criteriaLandingPageGroupIncludeExclude === 'None'
        ? null
        : values.criteriaLandingPageGroupIds.map((item) => (item?.name && item?.id ? item.id : item)),

    criteriaLandingPageIncludeExclude:
      !values.criteriaLandingPageIncludeExclude || values.criteriaLandingPageIncludeExclude === 'None'
        ? null
        : values.criteriaLandingPageIncludeExclude,
    criteriaLandingPageIds:
      !values.criteriaLandingPageIncludeExclude || values.criteriaLandingPageIncludeExclude === 'None'
        ? null
        : values.criteriaLandingPageIds.map((item) => (item?.name && item?.id ? item.id : item)),
    criteriaMaxAge: values.criteriaMaxAge && parseInt(values.criteriaMaxAge),
    criteriaMinAge: values.criteriaMinAge && parseInt(values.criteriaMinAge),

    criteriaOfferRequiresOptIn: values.criteriaOfferRequiresOptIn,
    criteriaQuestion: criteriaQuestionValue,
    criteriaQuestionAnswers: values.criteriaQuestionAnswers,
    criteriaUserDataIncludeExclude:
      !values.criteriaUserDataIncludeExclude || values.criteriaUserDataIncludeExclude === 'None'
        ? null
        : values.criteriaUserDataIncludeExclude,
    criteriaRequiredUserData:
      !values.criteriaUserDataIncludeExclude || values.criteriaUserDataIncludeExclude === 'None'
        ? null
        : Array.isArray(values.criteriaRequiredUserData) && values.criteriaRequiredUserData.length
        ? values.criteriaRequiredUserData.join(',')
        : typeof values.criteriaRequiredUserData === 'string'
        ? values.criteriaRequiredUserData
        : null,
    criteriaReturnFrequencyDomains:
      !criteriaReturnFrequencyIncludeExclude || criteriaReturnFrequencyIncludeExclude === 'None'
        ? []
        : values.criteriaReturnFrequencyDomains,
    criteriaReturnFrequencyIncludeExclude:
      !criteriaReturnFrequencyIncludeExclude || criteriaReturnFrequencyIncludeExclude === 'None'
        ? null
        : criteriaReturnFrequencyIncludeExclude,
    criteriaReturnFrequencyMax:
      !criteriaReturnFrequencyIncludeExclude || criteriaReturnFrequencyIncludeExclude === 'None'
        ? null
        : values.criteriaReturnFrequencyMax,
    criteriaReturnFrequencyMin:
      !criteriaReturnFrequencyIncludeExclude || criteriaReturnFrequencyIncludeExclude === 'None'
        ? null
        : values.criteriaReturnFrequencyMin,
    criteriaStates:
      values.criteriaIncludeOrExcludeStates && values.criteriaIncludeOrExcludeStates !== 'None' && values.criteriaStates
        ? values.criteriaStates
        : null,
    criteriaUserIdLastDigit:
      !criteriaIncludeOrExcludeUserIdLastDigit ||
      criteriaIncludeOrExcludeUserIdLastDigit === 'None' ||
      values.criteriaUserIdLastDigit === null
        ? []
        : values.criteriaUserIdLastDigit,
    criteriaZips:
      values.criteriaIncludeOrExcludeZips && values.criteriaIncludeOrExcludeZips !== 'None' && values.criteriaZips
        ? values.criteriaZips
        : null,
    dailyLimit: values.dailyLimit ?? null,
    dailyLimitAnswers:
      values.criteriaDailyLimitAnswers != null && values.criteriaDailyLimitAnswers.length > 0
        ? values.criteriaDailyLimitAnswers
        : null,
    dayHourRestrictions: isDayHoursRestrictions
      ? JSON.stringify(convertDailyRestrictionToJson(values.criteriaDayHourRestrictions))
      : null,
    devices:
      values.criteriaIncludeOrExcludeDevices === null ||
      values.criteriaIncludeOrExcludeDevices === 'None' ||
      values.criteriaDevices === null
        ? null
        : values.criteriaDevices,
    devicesIncludeExclude:
      !values.criteriaIncludeOrExcludeDevices || values.criteriaIncludeOrExcludeDevices === 'None'
        ? null
        : values.criteriaIncludeOrExcludeDevices,
    domains:
      values.criteriaIncludeOrExcludeDomains === null ||
      values.criteriaIncludeOrExcludeDomains === 'None' ||
      values.criteriaDomains === null
        ? null
        : handleCriteriaDomains(values.criteriaDomains),
    domainsIncludeExclude:
      values.criteriaIncludeOrExcludeDomains === 'None' ? null : values.criteriaIncludeOrExcludeDomains,
    domainAffiliateConfiguration:
      !values.domainAffiliateConfiguration ||
      JSON.stringify(values.domainAffiliateConfiguration?.domainAffiliateEntries) === '[]' ||
      (values.domainAffiliateConfiguration?.domainAffiliateEntries.length === 1 &&
        values.domainAffiliateConfiguration?.domainAffiliateEntries[0]?.includeExcludeAction === null) ||
      (values.domainAffiliateConfiguration?.domainAffiliateEntries?.length === 1 &&
        values.domainAffiliateConfiguration?.domainAffiliateEntries[0]?.includeExcludeAction === 'None')
        ? null
        : handleCriteriaDomainAffiliateNames(values.domainAffiliateConfiguration),
    previousRegistrationConfiguration:
      !values.previousRegistrationConfiguration ||
      JSON.stringify(values.previousRegistrationConfiguration?.previousRegistrations) === '[]' ||
      !values.previousRegistrationConfiguration.andOrLogic ||
      values.previousRegistrationConfiguration?.andOrLogic.toLowerCase() === 'select'
        ? null
        : {
            ...values.previousRegistrationConfiguration,
            previousRegistrations:
              values.previousRegistrationConfiguration?.previousRegistrations?.map((reg) => {
                if (reg?.domains) {
                  return {
                    ...reg,
                    domains: handleCriteriaDomains(reg.domains),
                    includeExcludeAction: reg?.includeExcludeAction?.toLowerCase(),
                  };
                } else {
                  return {
                    ...reg,
                    includeExcludeAction: reg?.includeExcludeAction?.toLowerCase(),
                  };
                }
              }) ?? [],
          },
    emailDomainsIncludeExclude:
      !values.criteriaIncludeOrExcludeEmailDomains || values.criteriaIncludeOrExcludeEmailDomains === 'None'
        ? null
        : values.criteriaIncludeOrExcludeEmailDomains,
    domainLevelPrePing:
      !values.domainLevelPrePing || values.domainLevelPrePing.name === 'None' ? null : values.domainLevelPrePing,
    emailDomains:
      values.criteriaIncludeOrExcludeEmailDomains === null ||
      values.criteriaIncludeOrExcludeEmailDomains === 'None' ||
      values.criteriaEmailDomains === null
        ? null
        : values.criteriaEmailDomains && values.criteriaEmailDomains !== null && values.criteriaEmailDomains.length > 0
        ? values.criteriaEmailDomains
        : null,
    genderEnabled: !values.criteriaExcludeGender || values.criteriaExcludeGender === 'null' ? false : true,
    genderValue:
      !values.criteriaExcludeGender || values.criteriaExcludeGender === 'null' ? null : values.criteriaExcludeGender,
    landingPageGroupIncludeExclude:
      !values.criteriaLandingPageGroupIncludeExclude || values.criteriaLandingPageGroupIncludeExclude === 'None'
        ? null
        : values.criteriaLandingPageGroupIncludeExclude,
    landingPageGroupIds:
      !values.criteriaLandingPageGroupIncludeExclude || values.criteriaLandingPageGroupIncludeExclude === 'None'
        ? null
        : values.criteriaLandingPageGroupIds.map((item) => item.id),

    landingPageIncludeExclude:
      !values.criteriaLandingPageIncludeExclude || values.criteriaLandingPageIncludeExclude === 'None'
        ? null
        : values.criteriaLandingPageIncludeExclude,
    landingPageIds:
      !values.criteriaLandingPageIncludeExclude || values.criteriaLandingPageIncludeExclude === 'None'
        ? null
        : values.criteriaLandingPageIds.map((item) => item.id),
    maxAge: values.criteriaMaxAge && parseInt(values.criteriaMaxAge),
    minAge: values.criteriaMinAge && parseInt(values.criteriaMinAge),
    nullSourceNewUserIncludeExclude:
      !values.criteriaNullSourceNewUserIncludeExclude || values.criteriaNullSourceNewUserIncludeExclude === 'None'
        ? null
        : values.criteriaNullSourceNewUserIncludeExclude,
    offerQuestion: values.criteriaQuestion,
    offerQuestionAnswers: values.criteriaQuestionAnswers,
    operatingSystemIncludeExclude:
      !values.criteriaIncludeOrExcludeOperatingSystems || values.criteriaIncludeOrExcludeOperatingSystems === 'None'
        ? null
        : values.criteriaIncludeOrExcludeOperatingSystems,
    operatingSystems:
      values.criteriaIncludeOrExcludeOperatingSystems === null ||
      values.criteriaIncludeOrExcludeOperatingSystems === 'None' ||
      values.criteriaOperatingSystems === null
        ? null
        : values.criteriaOperatingSystems,
    payoutAmount:
      values.payoutType !== null && values.payoutType !== '0' && values.payoutType !== '4' ? values.payoutAmount : null,
    payoutType: values.payoutType && values.payoutType !== '0' ? { id: values.payoutType } : null,
    phoneBlacklist: values.criteriaPhoneBlacklist,
    prePing: prePingMethod === null ? null : values.prePing,
    prePingBody: prePingMethod === null ? null : values.prePingBody,
    prePingCustomHttpStatus: prePingMethod === null ? null : values.prePingCustomHttpStatus,
    prePingHandler: prePingMethod === null ? null : prePingHandler,
    prePingHandlerId: prePingMethod === null ? null : prePingHandler,
    prePingTimeout: prePingMethod === null ? null : values.prePingTimeout,
    prePingHeader1: prePingMethod === null ? null : values.prePingHeader1,
    prePingHeader2: prePingMethod === null ? null : values.prePingHeader2,
    prePingHeader3: prePingMethod === null ? null : values.prePingHeader3,
    prePingMethod,
    prePingResultSessionCapture: prePingMethod === null ? null : values.prePingResultSessionCapture,
    prePingSuppressionAmount: suppressionDuration ? suppressionDuration.amount : null,
    prePingSuppressionLevel:
      suppressionDuration && suppressionRules.suppression_type !== 'none' ? suppressionRules.suppression_type : null,
    prePingSuppressionType:
      suppressionDuration && suppressionDuration.type !== 'none' ? suppressionDuration.type : null,
    prePingSuppressionMaxLimit:
      suppressionDuration && suppressionDuration.max_limit !== null ? suppressionDuration.max_limit : null,
    prePingType: prePingMethod,
    prePingUrl: prePingMethod === null ? null : values.prePing,
    requiredUserDataIncludeExclude: values.criteriaUserDataIncludeExclude,
    requiredUserDataValues:
      values.criteriaRequiredUserData && values.criteriaRequiredUserData.length > 0
        ? values.criteriaRequiredUserData
        : null,
    returnFrequencyIncludeExclude:
      !criteriaReturnFrequencyIncludeExclude || criteriaReturnFrequencyIncludeExclude === 'None'
        ? null
        : criteriaReturnFrequencyIncludeExclude,
    returnFrequencyDomains:
      !criteriaReturnFrequencyIncludeExclude || criteriaReturnFrequencyIncludeExclude === 'None'
        ? null
        : values.criteriaReturnFrequencyDomains,
    returnFrequencyMin:
      !criteriaReturnFrequencyIncludeExclude || criteriaReturnFrequencyIncludeExclude === 'None'
        ? null
        : values.criteriaReturnFrequencyMin,
    returnFrequencyMax:
      !criteriaReturnFrequencyIncludeExclude || criteriaReturnFrequencyIncludeExclude === 'None'
        ? null
        : values.criteriaReturnFrequencyMax,
    statesIncludeExclude:
      !values.criteriaIncludeOrExcludeStates || values.criteriaIncludeOrExcludeStates === 'None'
        ? null
        : values.criteriaIncludeOrExcludeStates,
    statesValues:
      values.criteriaIncludeOrExcludeStates === null ||
      values.criteriaIncludeOrExcludeStates === 'None' ||
      values.criteriaStates === null
        ? null
        : values.criteriaStates,
    suppressionRules: suppressionRulesOffer,
    tcpaLastOptInInterval: values.criteriaTcpaLastOptInInterval,
    tcpaOptInRequiredOverLastInterval: values.criteriaTcpaLastOptInInterval,
    tcpaOptInRequired: values.criteriaOfferRequiresOptIn,
    userIdLastDigitIncludeExclude:
      !values.criteriaIncludeOrExcludeUserIdLastDigit || values.criteriaIncludeOrExcludeUserIdLastDigit === 'None'
        ? null
        : values.criteriaIncludeOrExcludeUserIdLastDigit,
    userIdLastDigitValues:
      !values.criteriaIncludeOrExcludeUserIdLastDigit ||
      values.criteriaIncludeOrExcludeUserIdLastDigit === 'None' ||
      values.criteriaUserIdLastDigit === null
        ? []
        : values.criteriaUserIdLastDigit,
    userAttributesIncludeExclude: criteriaIncludeOrExcludeUserAttributes,
    userAttributeRules: criteriaIncludeOrExcludeUserAttributes === null ? null : values.userAttributeRules,
    zipsIncludeExclude:
      !values.criteriaIncludeOrExcludeZips || values.criteriaIncludeOrExcludeZips === 'None'
        ? null
        : values.criteriaIncludeOrExcludeZips,
    zipsValues,
  };
  console.log('criteriaObj', criteriaObj);
  return criteriaObj;
}

function parseAdditionalOffers(additionalOffers) {
  const additionalOfferIds = additionalOffers.map((offer) =>
    typeof offer === 'object' && offer !== null ? parseInt(offer.id) : offer
  );
  console.log(additionalOfferIds);
  return additionalOfferIds;
}

function findIdFromName(jsonContainer, name) {
  for (let o of jsonContainer) {
    if (o.name === name) {
      const { id, name, code } = o;
      return { id, name, code };
    }
  }
  return null;
}

function handleAdditionalOfferInts(offerAsInteger, additionalOffersObj) {
  let offerId = typeof offerAsInteger === 'string' ? offerAsInteger.replace(/\D/g, '') : offerAsInteger;

  if (!offerId || offerAsInteger === null) return null;
  offerId = parseInt(offerId);
  const offer = additionalOffersObj && additionalOffersObj[offerId];
  return offer || offerId;
}

export function validateCriteria(values) {
  const errors = {};
  if (!values) return;

  // The criteria fields in the publisherPrePingDetails have a different naming convention than the criteria fields in the other entities.
  // TODO: WET-1290 We'll need to refactor those fields to match the other entities upon save/init
  // Publisher Pre-Ping Details
  if (values.minAge && values.maxAge) {
    if (values.minAge > values.maxAge) {
      errors.minAge = 'Minimum age must be lower than max age.';
    }
  }
  if (values.minAge || values.maxAge) {
    if (values.minAge && values.minAge < 10) {
      errors.minAge = 'Age must be greater than 10.';
    }
    if (values.maxAge && values.maxAge < 10) {
      errors.maxAge = 'Age must be greater than 10.';
    }
  }

  if (values.userIdLastDigitIncludeExclude && values.userIdLastDigitIncludeExclude !== 'None') {
    if (
      !values.userIdLastDigitValues ||
      (values.userIdLastDigitValues &&
        values.userIdLastDigitValues.length === 0 &&
        values.userIdLastDigitIncludeExclude !== 'None')
    ) {
      errors.userIdLastDigitValues = 'Please enter at least one digit.';
    }
  }

  // Previous Registration Required
  if (values.returnFrequencyIncludeExclude && values.returnFrequencyIncludeExclude !== 'None') {
    if (
      !values.returnFrequencyDomains ||
      (values.returnFrequencyDomains && values.returnFrequencyDomains.length === 0)
    ) {
      errors.returnFrequencyDomains = 'Please add one or more domains.';
    }
    if (
      values.returnFrequencyDomains &&
      values.returnFrequencyDomains.includes('All Domains') &&
      values.returnFrequencyDomains.length > 1
    ) {
      errors.returnFrequencyDomains = 'Please remove other domains while "All Domains" is selected';
    }
  }

  if (values.returnFrequencyMin && values.returnFrequencyMax) {
    if (parseInt(values.returnFrequencyMin) > parseInt(values.returnFrequencyMax)) {
      errors.returnFrequencyMin = 'Minimum registration must be lower than max registration.';
    }
  }

  // states
  if (values.statesIncludeExclude && values.statesIncludeExclude !== 'None') {
    if (!values.statesValues || (values.statesValues && values.statesValues.length === 0)) {
      errors.statesValues = 'Please enter at least one state.';
    }
  }

  // zips
  if (values.zipsIncludeExclude && values.zipsIncludeExclude !== 'None') {
    const { zipsValues } = values;
    if (!zipsValues || (zipsValues && zipsValues !== null && zipsValues.length === 0)) {
      errors.zipsValues = 'Please add one or more zip codes.';
    } else if (zipsValues) {
      let zipsArray =
        zipsValues && typeof zipsValues !== 'string'
          ? zipsValues
          : typeof zipsValues === 'string'
          ? zipsValues.split(/\D/)
          : [];
      zipsArray = zipsArray.filter((zip) => zip !== '');
      zipsArray.forEach((zip) => {
        if (zip.length < 5) {
          errors.zipsValues = 'One or more of your zip codes are invalid.';
        }
      });
    }
  }

  // Email Domains
  if (values.emailDomainsIncludeExclude && values.emailDomainsIncludeExclude !== 'None') {
    if (
      !values.emailDomains ||
      (values.emailDomains && values.emailDomains !== null && values.emailDomains.length === 0)
    ) {
      errors.emailDomains = 'Please add one or more email domains.';
    }
  }

  // Pre-ping

  // Internal
  if (values.prePingSuppressionType) {
    if (!values.prePingSuppressionAmount || values.prePingSuppressionAmount === null) {
      errors.prePingSuppressionAmount = 'Please select a suppression amount.';
    }

    if (!values.prePingSuppressionLevel || values.prePingSuppressionLevel === 'None') {
      errors.prePingSuppressionLevel = 'Please select a suppression level';
    }
  }

  if (values.suppressionRules && values.suppressionRules.suppression_type !== 'answer')
    values.suppressionRules.suppression_answer = null;

  // Criteria
  // USERS
  // Age requirements
  if (values.criteriaMinAge && values.criteriaMaxAge) {
    if (values.criteriaMinAge > values.criteriaMaxAge) {
      errors.criteriaMinAge = 'Minimum age must be lower than max age.';
    }
  }
  if (values.criteriaMinAge || values.criteriaMaxAge) {
    if (values.criteriaMinAge && values.criteriaMinAge < 10) {
      errors.criteriaMinAge = 'Age must be greater than 10.';
    }
    if (values.criteriaMaxAge && values.criteriaMaxAge < 10) {
      errors.criteriaMaxAge = 'Age must be greater than 10.';
    }
  }

  // User ID digits
  if (values.criteriaIncludeOrExcludeUserIdLastDigit && values.criteriaIncludeOrExcludeUserIdLastDigit !== 'None') {
    if (
      !values.criteriaUserIdLastDigit ||
      (values.criteriaUserIdLastDigit &&
        values.criteriaUserIdLastDigit !== null &&
        values.criteriaUserIdLastDigit.length === 0)
    ) {
      errors.criteriaUserIdLastDigit = 'Please add one or more user ID digits.';
    }
  }

  // Previous Registrations
  if (
    values.previousRegistrationConfiguration?.andOrLogic &&
    values.previousRegistrationConfiguration?.andOrLogic.toLowerCase() !== 'select'
  ) {
    errors.previousRegistrationConfiguration = {};
    const previousRegistrationErrors = [];
    if (Array.isArray(values.previousRegistrationConfiguration?.previousRegistrations)) {
      values.previousRegistrationConfiguration.previousRegistrations.forEach((previousReg) => {
        const previousRegistrationError = {};

        if (!previousReg?.includeExcludeAction || previousReg?.includeExcludeAction?.toLowerCase() === 'none') {
          previousRegistrationError.includeExcludeAction = 'Please select include or exclude.';
        } else if (
          previousReg?.includeExcludeAction?.toLowerCase() === 'include' ||
          previousReg?.includeExcludeAction?.toLowerCase() === 'exclude'
        ) {
          if (previousReg.domains.length === 0) {
            previousRegistrationError.domains = 'Please add one or more domains.';
          } else if (
            previousReg.domains &&
            previousReg.domains.includes('All Domains') &&
            previousReg.domains.length > 1
          ) {
            previousRegistrationError.domains = 'Please remove other domains while "All Domains" is selected';
          }
          if (!previousReg.previousRegistrationsMin && !previousReg.previousRegistrationsMax) {
            previousRegistrationError.previousRegistrationsMin = 'Please add a Min or a Max.';
            previousRegistrationError.previousRegistrationsMax = 'Please add a Min or a Max.';
          }
          if (previousReg.previousRegistrationsMin && previousReg.previousRegistrationsMax) {
            if (parseInt(previousReg.previousRegistrationsMin) > parseInt(previousReg.previousRegistrationsMax)) {
              previousRegistrationError.previousRegistrationsMin =
                'Minimum registration must be lower than max registration.';
            }
          }
        }
        previousRegistrationErrors.push(previousRegistrationError);
      });
    } else {
      console.error('previousRegistrations is not an array!');
    }

    errors.previousRegistrationConfiguration.previousRegistrations = previousRegistrationErrors;
  }

  // Devices
  if (values.criteriaIncludeOrExcludeDevices && values.criteriaIncludeOrExcludeDevices !== 'None') {
    if (
      !values.criteriaDevices ||
      (values.criteriaDevices && values.criteriaDevices !== null && values.criteriaDevices.length === 0)
    ) {
      errors.criteriaDevices = 'Please add one or more devices.';
    }
  }

  // AIB List

  if (values.criteriaAibListIncludeExclude && values.criteriaAibListIncludeExclude !== 'None') {
    if (!values.criteriaAibList || (values.criteriaAibList && values.criteriaAibList.length === 0)) {
      errors.criteriaAibList = 'Please add one or more AIB list codes.';
    }
  }

  // Browsers

  if (values.criteriaIncludeOrExcludeBrowsers && values.criteriaIncludeOrExcludeBrowsers !== 'None') {
    if (
      !values.criteriaBrowsers ||
      (values.criteriaBrowsers && values.criteriaBrowsers !== null && values.criteriaBrowsers.length === 0)
    ) {
      errors.criteriaBrowsers = 'Please add one or more browsers.';
    }
  }

  // What If Domains

  if (values.criteriaIncludeOrExcludeDomains && values.criteriaIncludeOrExcludeDomains !== 'None') {
    if (
      !values.criteriaDomains ||
      (values.criteriaDomains && values.criteriaDomains !== null && values.criteriaDomains.length === 0)
    ) {
      errors.criteriaDomains = 'Please add one or more domains.';
    }
  }

  // Domain - Affiliates

  if (values.domainAffiliateConfiguration != null) {
    errors.domainAffiliateConfiguration = {};
    const domainAffiliateErrors = [];
    values.domainAffiliateConfiguration.domainAffiliateEntries.forEach((domainAffiliate, i) => {
      const domainAffiliateError = {};
      if (
        (domainAffiliate.affiliates.length !== 0 || domainAffiliate.domains.length !== 0) &&
        (!domainAffiliate?.includeExcludeAction || domainAffiliate?.includeExcludeAction?.toLowerCase() === 'none')
      ) {
        domainAffiliateError.includeExcludeAction = 'Please select include or exclude.';
      } else if (
        domainAffiliate?.includeExcludeAction?.toLowerCase() === 'include' ||
        domainAffiliate?.includeExcludeAction?.toLowerCase() === 'exclude'
      ) {
        if (domainAffiliate.affiliates.length === 0)
          domainAffiliateError.affiliates = 'Please add one or more affiliates.';
        if (domainAffiliate.domains.length === 0) domainAffiliateError.domains = 'Please add one or more domains.';
      }
      domainAffiliateErrors.push(domainAffiliateError);
    });
    errors.domainAffiliateConfiguration.domainAffiliateEntries = domainAffiliateErrors;
  }

  // Operating systems
  if (values.criteriaIncludeOrExcludeOperatingSystems && values.criteriaIncludeOrExcludeOperatingSystems !== 'None') {
    if (
      !values.criteriaOperatingSystems ||
      (values.criteriaOperatingSystems &&
        values.criteriaOperatingSystems !== null &&
        values.criteriaOperatingSystems.length === 0)
    ) {
      errors.criteriaOperatingSystems = 'Please add one or more operating systems.';
    }
  }

  // States
  if (values.criteriaIncludeOrExcludeStates && values.criteriaIncludeOrExcludeStates !== 'None') {
    if (
      !values.criteriaStates ||
      (values.criteriaStates && values.criteriaStates !== null && values.criteriaStates.length === 0)
    ) {
      errors.criteriaStates = 'Please add one or more states.';
    }
  }

  // Zips
  if (values.criteriaIncludeOrExcludeZips && values.criteriaIncludeOrExcludeZips !== 'None') {
    const { criteriaZips } = values;
    if (!criteriaZips || (criteriaZips && criteriaZips !== null && criteriaZips.length === 0)) {
      errors.criteriaZips = 'Please add one or more zip codes.';
    } else if (criteriaZips) {
      let zipsArray =
        criteriaZips && typeof criteriaZips !== 'string'
          ? criteriaZips
          : typeof criteriaZips === 'string'
          ? criteriaZips.split(/\D/)
          : [];
      zipsArray = zipsArray.filter((zip) => zip !== '');
      zipsArray.forEach((zip) => {
        if (zip.length < 5) {
          errors.criteriaZips = 'One or more of your zip codes are invalid.';
        }
      });
    }
  }
  // ATTRIBUTE

  // Criteria Questions
  if (values.criteriaQuestion) {
    if (
      !values.criteriaQuestionAnswers ||
      (values.criteriaQuestionAnswers && values.criteriaQuestionAnswers.length === 0)
    ) {
      errors.criteriaQuestionAnswers = 'Please add one or more question answers.';
    }
  }

  // User Attributes
  if (values.criteriaIncludeOrExcludeUserAttributes && values.criteriaIncludeOrExcludeUserAttributes !== 'None') {
    if (!values.userAttributeRules || values.userAttributeRules?.rules?.length === 0) {
      errors.criteriaIncludeOrExcludeUserAttributes = 'Add a rule';
    }
  }

  // Affiliate Names
  if (values.criteriaIncludeOrExcludeAffNames && values.criteriaIncludeOrExcludeAffNames !== 'None') {
    if (
      !values.criteriaAffNames ||
      (values.criteriaAffNames && values.criteriaAffNames !== null && values.criteriaAffNames.length === 0)
    ) {
      errors.criteriaAffNames = 'Please add one or more affiliate names.';
    }
  }

  // Email Domains
  if (values.criteriaIncludeOrExcludeEmailDomains && values.criteriaIncludeOrExcludeEmailDomains !== 'None') {
    if (
      !values.criteriaEmailDomains ||
      (values.criteriaEmailDomains && values.criteriaEmailDomains !== null && values.criteriaEmailDomains.length === 0)
    ) {
      errors.criteriaEmailDomains = 'Please add one or more email domains.';
    }
  }

  // Validate Landing Page Group and Landing Page IDs
  if (values.criteriaLandingPageGroupIncludeExclude && values.criteriaLandingPageGroupIncludeExclude !== 'None') {
    if (!values.criteriaLandingPageGroupIds || values.criteriaLandingPageGroupIds.length === 0) {
      errors.criteriaLandingPageGroupIds = 'Please add one or more landing page groups.';
    }

    if (values.criteriaLandingPageGroupIds && values.criteriaLandingPageGroupIds.length == 0) {
      errors.criteriaLandingPageGroupIds = 'Please select at least one landing page group.';
    }
  }

  if (values.criteriaLandingPageIncludeExclude && values.criteriaLandingPageIncludeExclude !== 'None') {
    if (!values.criteriaLandingPageIds || values.criteriaLandingPageIds.length === 0) {
      errors.criteriaLandingPageIds = 'Please add one or more landing pages.';
    }

    if (values.criteriaLandingPageIds && values.criteriaLandingPageIds.length === 0) {
      errors.criteriaLandingPageIds = 'Please select at least one landing page.';
    }
  }

  if (values.landingPageGroupIncludeExclude && values.landingPageGroupIncludeExclude !== 'None') {
    if (!values.landingPageGroupIds || values.landingPageGroupIds.length === 0) {
      errors.landingPageGroupIds = 'Please add one or more landing page groups.';
    }

    if (values.landingPageGroupIds && values.landingPageGroupIds.length == 0) {
      errors.landingPageGroupIds = 'Please select at least one landing page group.';
    }
  }

  if (values.landingPageIncludeExclude && values.landingPageIncludeExclude !== 'None') {
    if (!values.landingPageIds || values.landingPageIds.length === 0) {
      errors.landingPageIds = 'Please add one or more landing pages.';
    }

    if (values.landingPageIds && values.landingPageIds.length === 0) {
      errors.landingPageIds = 'Please select at least one landing page.';
    }
  }

  // Pre-ping

  // Internal
  let { suppressionRules } = values;
  suppressionRules = typeof suppressionRules === 'object' && suppressionRules !== null ? suppressionRules : {};
  const duration =
    typeof suppressionRules.duration === 'object' && suppressionRules.duration !== null
      ? suppressionRules.duration
      : {};
  const type = duration.type || '';
  const answer = suppressionRules.suppression_answer || '';
  errors.suppressionRules = {};
  if (type && type.toLowerCase() !== 'none') {
    if (!duration.amount || parseInt(duration.amount) === 0) {
      errors.suppressionRules = { ...errors.suppressionRules };
      errors.suppressionRules.duration = { amount: 'Please select a suppression amount.' };
    }

    if (
      (suppressionRules.suppression_type &&
        (suppressionRules.suppression_type === 'select' ||
          suppressionRules.suppression_type.toLowerCase() === 'none')) ||
      !suppressionRules.suppression_type
    ) {
      errors.suppressionRules = { ...errors.suppressionRules };
      errors.suppressionRules.suppression_type = 'Please select a suppression level.';
    }

    if (suppressionRules.suppression_type === 'answer' && answer === '') {
      errors.suppressionRules = { ...errors.suppressionRules };
      errors.suppressionRules.suppression_answer = 'Please select a suppression answer.';
    }

    console.log('suppressionRules', suppressionRules);
  } else if (
    type &&
    type.toLowerCase() !== 'none' &&
    (duration.amount ||
      (suppressionRules.suppression_type &&
        suppressionRules.suppression_type !== 'select' &&
        suppressionRules.suppression_type !== 'none') ||
      (suppressionRules.additional_offer_ids && suppressionRules.additional_offer_ids.length > 0) ||
      (suppressionRules.additional_offer_action_ids && suppressionRules.additional_offer_action_ids.length > 0) ||
      duration.max_limit)
  ) {
    errors.suppressionRules.duration = { type: 'Please select a suppression type.' };
  }

  // External

  const { prePingMethod } = values;

  if (prePingMethod && prePingMethod !== null && prePingMethod !== 'NONE') {
    if (!values.prePingHandlerId) {
      errors.prePingHandlerId = 'Please select a handler.';
    }
  }

  // Daily Restriction

  let {
    criteriaDayHourRestrictions,
    criteriaDailyLimit,
    criteriaDailyLimitAnswers,
    offerType,
    offerActionType,
  } = values;
  if (criteriaDailyLimit && criteriaDailyLimit !== null) {
    if (
      (criteriaDailyLimitAnswers === null || (criteriaDailyLimitAnswers && criteriaDailyLimitAnswers.length === 0)) &&
      offerType?.id !== 9 &&
      !offerActionType
    ) {
      errors.criteriaDailyLimitAnswers = 'Please select one or more answers.';
    }
  }
  criteriaDayHourRestrictions =
    !criteriaDayHourRestrictions || criteriaDayHourRestrictions === null ? [] : criteriaDayHourRestrictions;
  errors.criteriaDayHourRestrictions = [];
  criteriaDayHourRestrictions.forEach((restriction, i) => {
    let start, startMeridiem, end, endMeridiem;
    errors.criteriaDayHourRestrictions[i] = {};
    const currentRestriction = errors.criteriaDayHourRestrictions[i];
    if (restriction.restrictionStart && restriction.restrictionStart != undefined) {
      let splitTime = restriction.restrictionStart.split(' ');
      start = splitTime[0];
      startMeridiem = splitTime[1];
    }
    if (restriction.restrictionEnd && restriction.restrictionStart != undefined) {
      let splitTime = restriction.restrictionEnd.split(' ');
      end = splitTime[0];
      endMeridiem = splitTime[1];
    }

    if (!restriction.restriction) {
      currentRestriction.restriction = 'Please select a restriction type.';
    }

    if (restriction.restriction === 'Partially Restricted') {
      if (!restriction.restrictionStart || restriction.restrictionStart === 'undefined') {
        currentRestriction.restrictionStart = 'Please select a start time.';
      }
      if (!restriction.restrictionEnd || restriction.restrictionEnd === 'undefined') {
        currentRestriction.restrictionEnd = 'Please select an end time.';
      }
    }

    if (start && start !== 'undefined' && end && end !== 'undefined') {
      const startDate = parse(restriction.restrictionStart, 'h:mm aa', new Date());
      const endDate = parse(restriction.restrictionEnd, 'h:mm aa', new Date());
      if (isBefore(endDate, startDate)) {
        handleTimingConflict(errors, i);
      }
    }
  });

  if (values?.capAmount && !values?.capInterval) {
    errors.capInterval = 'Please select a cap interval.';
  }

  if (values.capInterval) {
    if (values.capAmount == null || values?.capAmount < 1) {
      console.log(values.capAmount);
      errors.capAmount = 'Please enter a cap amount.';
    }
  }

  // Cap Segments
  let totalSegmentAmount = 0;
  console.log(values.capSegmentConfiguration);
  if (
    values.offerType &&
    values.offerType.name !== 'Offer Block' &&
    values.capSegmentConfiguration !== undefined &&
    values.capSegmentConfiguration !== null
  ) {
    errors.capSegmentConfiguration = {};
    errors.capSegmentConfiguration.capSegments = [];
    values?.capSegmentConfiguration?.capSegments.forEach((segment, i) => {
      totalSegmentAmount += segment.segmentAmount;
      errors.capSegmentConfiguration.capSegments[i] = {};
      if (!segment.domains || segment.domains.length === 0) {
        errors.capSegmentConfiguration.capSegments[i].domains = 'Please add one or more domains.';
      }
      if (!segment.segmentAmount || segment.segmentAmount === 0) {
        errors.capSegmentConfiguration.capSegments[i].segmentAmount = 'Please enter a segment amount.';
      }
      if (segment.segmentAmount && (values.capAmount == null || values?.capAmount < 1)) {
        errors.capAmount = 'Please enter a cap amount.';
      }
    });
  }
  if (totalSegmentAmount !== null && values.capAmount !== null && totalSegmentAmount > values.capAmount) {
    errors.capAmount = 'Cap amount must be greater than segment amount.';
  }

  return errors;
}

const handleTimingConflict = (errors, index) => {
  errors.criteriaDayHourRestrictions[index] = {
    ...errors.criteriaDayHourRestrictions[index],
  };
  errors.criteriaDayHourRestrictions[index].restrictionStart = 'Your start time must be earlier than your end time.';
  errors.criteriaDayHourRestrictions[index].restrictionEnd = 'Your start time must be earlier than your end time.';
};
