import React, { Component } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Input, Row, Col, Alert } from 'reactstrap';
import { connect } from 'react-redux';
import { saveAsEntityName, resetModal } from '../../actions';

const initialData = {
  isOpen: false,
  formValidationError: false,
  formErrorMessage: null,
  saveErrorMessage: null,
  saveError: null
};
const states = {
  open: 'OPEN',
  closed: 'CLOSED',
  saving: 'SAVING',
  updating: 'UPDATING',
  saveError: 'SAVE_ERROR',
  formValidationError: 'FORM_VALIDATION_ERROR'
};
const colors = {
  warning: '#FFC107',
  error: '#FF485D'
};
const modalBodyStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
};

class SaveAsEntityModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newEntityName: '',
      state: states.closed,
      data: initialData
    };
  }

  componentWillReceiveProps(nextProps, nextContext) {
    // toggle success state if stuck
    const isStuck = this.state.state === states.submitting;
    if (isStuck) {
      this.moveToState(states.closed);
    }

    // if form submission failed due to validation
    const errors = this.props.getErrors();
    if (errors.invalid) {
      this.moveToState(states.formValidationError);
    }

    if (nextProps.saveError && nextProps.saveError.response) {
      this.moveToState(states.saveError);
    }
  }

  moveToState = (state, payload = {}) => {
    const { open, closed, saving, updating, saveError, formValidationError } = states;

    switch (state) {
      case open:
        this.setState(prevState => ({
          state: open,
          data: { ...prevState.data, isOpen: true, formValidationError: false }
        }));
        break;
      case closed:
        this.props.saveAsEntityName(null);
        setTimeout(() => {
          this.props.resetModal();
        }, 5000);
        this.setState(prevState => ({
          state: closed,
          data: { ...prevState.data, ...initialData }
        }));
        break;
      case saving:
        const afterSetLocal = async () => {
          if (this.state.newEntityName === '') {
            this.setState(prevState => ({
              ...prevState,
              data: { ...prevState.data, formErrorMessage: 'Must provide a name' }
            }));
            this.moveToState(open);
          } else {
            await this.props.saveAsEntityName(this.state.newEntityName);
            const isSuccessful = await this.props.onClickSubmit();

            if (isSuccessful) {
              this.moveToState(closed);
              await this.props.saveAsEntityName(null);
            } else {
              this.moveToState(saveError, { saveError: 'Failed to save' });
              await this.props.saveAsEntityName(null);
            }
          }
        };

        this.setState(
          prevState => ({
            state: saving,
            data: { ...prevState.data, formErrorMessage: null }
          }),
          afterSetLocal
        );

        break;
      case updating:
        this.setState(
          prevState => ({
            newEntityName: payload.newEntityName,
            state: updating,
            data: {
              ...prevState.data,
              formErrorMessage: null
            }
          }),
          () => {
            this.moveToState(open);
          }
        );
        break;
      case saveError:
        this.setState(prevState => ({
          state: saveError,
          data: { ...prevState.data, ...payload }
        }));
        break;
      case formValidationError:
        this.setState(prevState => ({
          state: formValidationError,
          data: { ...prevState.data }
        }));
        break;
      default:
        console.log(`<${state}> is not a valid state for ${this.props.entityType} modal`);
    }
  };

  save = () => {
    this.moveToState(states.saving);
  };

  closeModal = () => {
    this.moveToState(states.closed);
  };

  openModal = () => {
    this.moveToState(states.open);
  };

  updateNewItemName = event => {
    this.moveToState(states.updating, { newEntityName: event.target.value });
  };

  toggleFunc = () => {
    const { state } = this.state;

    if (state !== states.saving) {
      return this.moveToState(states.closed);
    }

    return () => {};
  };

  /**
   * Need this to share what an error means to rendering the body and footer in the modal.
   * This differs from this.props.getErrors in that it is used to tell if the button should be shown or not.
   */
  getError = () => this.props.saveError || this.state.data.saveError || null;

  renderErrorBody = () => {
    const errorMessage =
      this.props.saveError && this.props.saveError.response
        ? this.props.saveError.response.data.message
        : this.state.data.saveError
        ? this.state.data.saveError
        : '';

    return (
      <ModalBody>
        <Row>
          <Col lg={2} style={modalBodyStyle}>
            <i className='fa fa-exclamation-triangle fa-3x' style={{ color: colors.error }} />
          </Col>
          <Col lg={10}>
            <span>Error copying {`${this.props.entityType}`}. Please close and try again.</span>
            <br />
            <br />
            <span>Message:</span>
            <br />
            <br />
            <Alert color='danger'>{errorMessage}</Alert>
            <br />
          </Col>
        </Row>
      </ModalBody>
    );
  };

  renderNormalBody = () => {
    const inputStyle = this.state.data.formErrorMessage ? { borderColor: colors.error } : {};

    return (
      <ModalBody>
        <Row>
          <Col lg={2} style={modalBodyStyle}>
            <i className='fa fa-warning fa-3x' style={{ color: colors.warning }} />
          </Col>
          <Col lg={10}>
            <h3>{this.props.content && this.props.content.name}</h3>
            Any changes made without saving will be <strong>copied</strong> to the new offer but not reflected in the{' '}
            <strong>current</strong> offer until you click save offer.
          </Col>
        </Row>
        <br />
        <Row>
          <Col lg={12}>
            <span style={{ color: colors.error }}>
              {this.state.data.formErrorMessage ? this.state.data.formErrorMessage : ''}
            </span>
            <Input
              name='newEntityName'
              placeholder={`New ${this.props.entityType}  Name`}
              type='text'
              onChange={this.updateNewItemName}
              value={this.state.newEntityName}
              style={inputStyle}
            />{' '}
          </Col>
        </Row>
        <br />
        {this.props.children}
      </ModalBody>
    );
  };

  renderBody = () => {
    if (this.getError()) {
      return this.renderErrorBody();
    } else {
      return this.renderNormalBody();
    }
  };

  renderErrorFooter = () => {
    return (
      <ModalFooter>
        <Button color='secondary' onClick={this.closeModal}>
          Close
        </Button>
      </ModalFooter>
    );
  };

  renderNormalFooter = () => {
    const { state } = this.state;
    const isSaving = state === states.saving;

    return (
      <ModalFooter>
        <Button color='warning' disabled={isSaving} onClick={this.save}>
          {isSaving ? 'Processing' : 'Copy'}
        </Button>
        <Button color='secondary' disabled={isSaving} onClick={this.closeModal}>
          {isSaving ? 'Processing' : 'Cancel'}
        </Button>
      </ModalFooter>
    );
  };

  renderFooter = () => {
    if (this.getError()) {
      return this.renderErrorFooter();
    } else {
      return this.renderNormalFooter();
    }
  };

  render() {
    const errors = this.props.getErrors();
    const isDisabled = errors.invalid || !this.props.isEditMode;

    return (
      <div className='animated'>
        <Button
          color='success'
          onClick={this.openModal}
          disabled={isDisabled}
          style={{
            marginBottom: '2%',
            marginLeft: '2%',
            float: 'right'
          }}
          className={this.props.className}
        >
          Copy {`${this.props.entityType}`}
        </Button>
        <Modal
          isOpen={this.state.data.isOpen}
          toggle={this.toggleFunc}
          onClosed={this.closeModal}
          className={this.getError() ? 'modal-danger' : 'modal-warning'}
        >
          <ModalHeader toggle={this.closeModal}>Copy</ModalHeader>
          {this.renderBody()}
          {this.renderFooter()}
        </Modal>
      </div>
    );
  }
}

const mapState = state => {
  const { saveError } = state.landingPage;
  return { saveError };
};

// const mapDispatch = { setNewLandingPageName, resetModal };

export default connect(mapState, { saveAsEntityName, resetModal })(SaveAsEntityModal);
