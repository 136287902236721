import React, { Component } from 'react';
import { Alert, Button, Card, CardBody, CardHeader, Col, Collapse, Label, Row } from 'reactstrap';
import { Field, FieldArray, reduxForm } from 'redux-form';
import { withRouter } from 'react-router-dom';
import {
  createLandingPageGroupApi,
  fetchAllSites,
  getLandingPageGroup,
  setLandingPageGroup,
  updateLandingPageGroupApi,
  updateSelectedLPs,
} from '../actions';
import { getAllLandingPages } from '../../LandingPages/actions';
import { connect } from 'react-redux';
import 'react-widgets/styles.css';
import './LPGDetails.css';
import ReturnUserSegment from './ReturnUserSegment';
import { renderComboBox, renderField } from '../../Offers/OfferTitle/renderConsts';
import ActiveStatusModal from '../../../components/ActiveStatusModal/ActiveStatusModal';
import ActiveStatusBadge from '../../Offers/OfferTitle/ActiveStatusBadge';
import validate from './validate';
import api from '../../../services';
import CancelEntityModal from '../../../components/CancelEntityModal/CancelEntityModal';
import SaveEntityModal from '../../../components/SaveEntityModal/SaveEntityModal';

class LPGDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: null,
      isEditMode: false,
      isActive: false,
      collapseLPGDetails: true,
      errors: {
        disable: false,
      },
    };
  }

  getErrors = () => {
    const { saveError, saveErrorMessage, submitFailed, invalid, saveWarning, saveWarningMessage } = this.props;
    let errors = {
      ...this.state.errors,
      invalid: submitFailed ? invalid : false,
      saveError: saveError,
      _error: saveError ? saveErrorMessage : submitFailed ? 'One or more of the fields you entered are invalid.' : null,
      saveWarning: saveWarning,
      _warning: saveWarningMessage,
    };
    return errors;
  };

  handleLPGSlotsInit = (slots) => {
    let lpgSlots = slots.map((slot) => {
      const { flex, id, landingPage } = slot;
      return {
        flex,
        id,
        slot_lp: landingPage,
      };
    });
    // order by flex asc
    lpgSlots = lpgSlots.sort((a, b) => {
      return a.flex < b.flex ? 1 : -1;
    });
    return lpgSlots;
  };

  handleReturnUserSegmentsInit = (rUS) => {
    let returnUserSegments = rUS.map((returnUserSegment) => {
      return {
        id: returnUserSegment.id,
        returnValue: parseInt(returnUserSegment.minimumPreviousRegistrations),
        slots: this.handleLPGSlotsInit(returnUserSegment.landingPagesLandingPageGroupSegments),
      };
    });
    // order by return value asc asc
    returnUserSegments = returnUserSegments.sort((a, b) => {
      return a.returnValue < b.returnValue ? -1 : 1;
    });
    return returnUserSegments;
  };

  handleReturnUserSegmentSlots = (slots = [], existingLandingPageGroupSegment) => {
    return slots.map((slot) => {
      const segmentSlotParsed = {
        flex: slot.flex,
        landingPage: slot.slot_lp,
        orderBy: 1,
      };
      if (slot.id) {
        segmentSlotParsed.id = slot.id;
      }
      return segmentSlotParsed;
    });
  };

  handleReturnUserSegment = (returnUserSegments = []) => {
    console.log(returnUserSegments);
    const landingPagesGroupSegments = returnUserSegments.map((returnUserSegment) => {
      const returnUserSegmentParsed = {
        landingPagesLandingPageGroupSegments: this.handleReturnUserSegmentSlots(
          returnUserSegment.slots,
          returnUserSegment
        ),
        lastEdited: new Date(),
        minimumPreviousRegistrations: parseInt(returnUserSegment.returnValue),
      };
      if (returnUserSegment.id) {
        returnUserSegmentParsed.id = returnUserSegment.id;
      }
      return returnUserSegmentParsed;
    });
    console.log(landingPagesGroupSegments);
    return landingPagesGroupSegments;
  };

  handleInitialize = () => {
    const { landingPageGroup } = this.props;
    const { id, isActive = false, landingPagesGroupSegments, name, site } = landingPageGroup;

    let initData = {
      landingPageGroupName: name,
      site,
      returnUserSegments: this.handleReturnUserSegmentsInit(landingPagesGroupSegments),
    };
    // set the selected LPs after we've already sorted by return value
    initData.returnUserSegments.map((LPGSegment, index) => {
      const selectedLPs = LPGSegment.slots.map((slot) => {
        return slot.slot_lp.id;
      });
      this.props.updateSelectedLPs(index, selectedLPs);
    });

    this.props.initialize(initData);
    this.setState({ isActive: isActive, id, isEditMode: true });
  };

  onSubmit = async (values) => {
    console.log(values);

    let { landingPageGroupName, site, returnUserSegments } = values;
    const landingPagesGroupSegments = this.handleReturnUserSegment(returnUserSegments);

    const landingPageGroup = {
      isActive: this.state.isActive,
      landingPagesGroupSegments,
      isDefault: false,
      lastEdited: new Date(),
      name: landingPageGroupName,
      site,
      status: 1,
      zuulUser: JSON.parse(localStorage.getItem('userData')),
    };
    if (!this.state.id) {
      await this.props.createLandingPageGroupApi(landingPageGroup);
      if (!this.props.saveError) this.props.history.push('/campaigns/landingpagegroups');
    } else {
      const { id } = this.state;
      const existingLPG = await this.props.getLandingPageGroup(id);
      landingPageGroup.id = id;
      landingPageGroup.isDefault = existingLPG.isDefault;
      await this.props.updateLandingPageGroupApi(landingPageGroup);
      return !this.props.saveError && !this.props.saveWarning;
    }
  };

  renderReturnUserSegment(fields) {
    return (
      <Row>
        <Col lg={12}>
          <div id='' className='colorTest'>
            <br />
            <Row id='return-user-segment'>
              {fields.map((returnUserSegment, index) => {
                return (
                  <Col key={index} xl={3} lg={4} md={6} sm={12} xs={12}>
                    <div className='horizontal-UF-panel'>
                      <ReturnUserSegment
                        name={returnUserSegment}
                        returnUserSegment={returnUserSegment}
                        key={index}
                        fields={fields}
                        index={index}
                      />
                    </div>
                  </Col>
                );
              })}
            </Row>
          </div>
        </Col>
      </Row>
    );
  }

  renderReturnUserSegmentSection = ({ fields }) => {
    // initialize with one return user segment
    if (fields.length === 0) {
      fields.push({
        returnValue: fields.length,
        slots: [
          {
            flex: 1,
            id: null,
            slot_lp: null,
          },
        ],
      });
    }
    return (
      <>
        <Row>
          <Col sm={6} lg={6}>
            <h3>Return User Segment</h3>
          </Col>

          <Col sm={6} lg={6}>
            <Button
              color='success'
              id='addReturnUserSegment'
              onClick={() =>
                fields.push({
                  returnValue: fields.length,
                  slots: [
                    {
                      flex: 1,
                      id: null,
                      slot_lp: null,
                    },
                  ],
                })
              }
            >
              Add Return User Segment
            </Button>
          </Col>
        </Row>
        <br />
        {this.renderReturnUserSegment(fields)}
      </>
    );
  };

  async componentDidMount() {
    this.props.fetchAllSites();
    this.props.getAllLandingPages();
    const { lpgId } = this.props.match.params;
    if (lpgId) {
      await this.props.getLandingPageGroup(lpgId);
      this.handleInitialize();
    } else {
      this.props.getLandingPageGroup();
    }
  }

  componentWillUnmount() {
    this.props.setLandingPageGroup({});
    this.props.updateSelectedLPs(null, [], true);
  }

  render() {
    const { lpgName } = this.props;
    const { id, isActive } = this.state;
    const errors = this.getErrors();
    const shouldErrorRender = Object.keys(errors).some((x) => errors[x]);
    return (
      <div className='animated fadeIn'>
        <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
          <Card>
            <CardHeader>
              <Row>
                <Col lg='10'>
                  <span style={{ display: 'flex', alignItems: 'baseline' }}>
                    <ActiveStatusBadge isActive={isActive} />
                    &nbsp;&nbsp;
                    <h3>{lpgName}</h3>
                    &nbsp;&nbsp;
                    {id && (
                      <h6>
                        <em>Landing Page Group ID: </em>
                        {id}
                      </h6>
                    )}
                    &nbsp;&nbsp;
                  </span>
                </Col>
                <Col>
                  <ActiveStatusModal
                    content={this.props.landingPageGroup}
                    isActive={isActive}
                    modalType=' Landing Page Group'
                    getErrors={() => this.getErrors()}
                    onClickSubmit={(activeStatus) => {
                      this.state.isEditMode &&
                        api
                          .put(`landing-page-groups/active/${this.props.landingPageGroup.id}`, {
                            id: this.props.landingPageGroup.id,
                            isActive: activeStatus,
                            zuulUser: JSON.parse(localStorage.getItem('userData')),
                          })
                          .then((res) => {
                            if (res.status === 200) this.setState({ isActive: activeStatus });
                          });
                    }}
                  />
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <Collapse isOpen={this.state.collapseLPGDetails}>
                <Row>
                  <Col lg='6'>
                    <Field
                      name='landingPageGroupName'
                      type='text'
                      id='landingPageGroupName'
                      component={renderField}
                      label='Landing Page Group Name'
                    />
                  </Col>

                  <Col lg='6'>
                    <Label>Site</Label>
                    <Field
                      name='site'
                      type='text'
                      id='landingPageGroupSite'
                      component={renderComboBox}
                      textField='domain'
                      valueField='id'
                      data={this.props.sites}
                      disabled={this.state.isEditMode}
                    />
                  </Col>
                </Row>
                <br />
                <FieldArray name='returnUserSegments' component={this.renderReturnUserSegmentSection} />
                <br />
              </Collapse>
            </CardBody>
          </Card>
          <Row>
            <Col lg={6}>
              {shouldErrorRender && errors._error && <Alert color='danger'>{errors._error}</Alert>}
              {this.props.isLPGSaved && <Alert color='success'>Landing Page Group Saved Successfully</Alert>}
            </Col>
            <Col lg={6}>
              <CancelEntityModal getErrors={this.getErrors} entityUrl={'/campaigns/landingpagegroups'} />

              <SaveEntityModal
                getErrors={this.getErrors}
                onClickSubmit={this.props.handleSubmit(this.onSubmit)}
                isEditMode={this.state.isEditMode}
                entityName={this.props.lpgName}
                entityType={'Landing Page Group'}
                submitting={this.props.submitting}
              />
            </Col>
          </Row>
        </form>
      </div>
    );
  }
}

const form = reduxForm({
  form: 'LPGDetails',
  touchOnChange: true,
  validate,
});

function mapStateToProps(state) {
  const {
    landingPageGroup,
    selector,
    sites,
    isLPGSaved,
    saveError,
    saveErrorMessage,
    saveWarning,
    saveWarningMessage,
  } = state.landingPageGroup;
  return {
    isLPGSaved,
    lpgName: selector(state, 'landingPageGroupName'),
    landingPageGroup,
    selector,
    sites,
    saveError,
    saveErrorMessage,
    saveWarning,
    saveWarningMessage,
  };
}

export default connect(mapStateToProps, {
  createLandingPageGroupApi,
  setLandingPageGroup,
  updateLandingPageGroupApi,
  updateSelectedLPs,
  fetchAllSites,
  getAllLandingPages,
  getLandingPageGroup,
})(form(withRouter(LPGDetails)));
