import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { convertBase64FileToImage, convertImageFileToBase64 } from '../../../../utils/conversionFunctions';

/**
 * Dropzone component for uploading image files.
 *
 * @param {Object} props - The component props.
 * @param {Function} props.handleError - The error handling function.
 * @param {Object} props.input - The input object.
 * @param {Array} props.imagefile - The array of image files.
 * @param {Object} props.meta - The meta object.
 * @param {Function} props.setImageFile - The function to set the image file.
 * @returns {JSX.Element} The Dropzone component.
 */
export default function Dropzone({
  handleError,
  input: { onChange },
  imagefile,
  meta: { error, touched },
  setImageFile,
}) {
  const onDrop = useCallback((acceptedFiles) => {
    convertImageFileToBase64(acceptedFiles[0])
      .then((base) => {
        convertBase64FileToImage(base).then((image) => {
          let height = null,
            width = null;
          if (image.width > 600 || image.height > 400) {
            height = image.height;
            width = image.width;
          }
          const imageFile = {
            file: acceptedFiles[0],
            encodedImageData: base ? base.split(',')[1] : null,
            name: acceptedFiles[0].name,
            preview: URL.createObjectURL(acceptedFiles[0]),
            targetWidth: width,
            targetHeight: height,
            size: acceptedFiles[0].size,
            fileName: acceptedFiles[0].name,
            fileExtension: acceptedFiles[0].name.split('.').pop(),
          };
          setImageFile(imageFile);
          onChange(imageFile);
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: {
      'image/jpeg': [],
      'image/png': [],
      'image/gif': [],
      'image/webp': [],
    },
    multiple: false,
    onDrop,
  });

  return (
    <div className='preview-container'>
      <div
        {...getRootProps()}
        className={`upload-container ${error && touched ? 'has-error' : ''} ${isDragActive ? 'drag-active' : ''}`}
        data-testid='dropzone'
      >
        <input {...getInputProps()} />
        {imagefile && imagefile.length > 0 ? (
          imagefile.map(({ name, preview, size }) => (
            <div key={name} className='render-preview'>
              <div className='image-container'>
                <img onError={handleError} src={preview} alt={name} />
              </div>
              <div className='details'>
                {name} - {(size / 1024000).toFixed(2)}MB
              </div>
            </div>
          ))
        ) : (
          <div className='placeholder-preview'>
            <i className='fa fa-upload' style={{ fontSize: 100, paddingTop: 85 }} />
            <p>Click or drag image file to this area to upload.</p>
          </div>
        )}
      </div>
    </div>
  );
}
