import api from '../index'

const getTags = async () => {
  const resp = await api.get(`/tags`)
  return resp.data
}

export {
  getTags
}
