import { useQuery } from '@tanstack/react-query';
import format from 'date-fns/format';
import _ from 'lodash';
import React from 'react';
import { selectFilter } from 'react-bootstrap-table2-filter';
import { Link } from 'react-router-dom';
import DropdownList from 'react-widgets/DropdownList';
import { Button } from 'reactstrap';
import ActiveStatusModal from '../../components/ActiveStatusModal/ActiveStatusModal';
import SummaryCard from '../../components/SummaryCard/SummaryCard';
import api from '../../services';
import { fetchAll } from '../../services/helpers';
import { columnDefaults } from '../../utils/tableFunctions';

let siteFilter;
const updateStatus = (row, activeStatus, endpoint) => {
  if (row.id) {
    api
      .put(`${endpoint}/active/${row.id}`, {
        id: row.id,
        isActive: activeStatus,
        active: activeStatus,
        zuulUser: JSON.parse(localStorage.getItem('userData')),
      })
      .then((result) => console.log(JSON.stringify(result)));
  }
};

const LandingPageGroups = () => {
  const { data, isLoading: loading, error } = useQuery({
    queryKey: ['LPG'],
    queryFn: () => fetchAll('landing-page-groups'),
  });

  const allDomainsForFilter = !loading
    ? _.uniqBy(
        data
          .map((obj) => {
            return { value: obj?.site?.domain, label: obj?.site?.domain };
          })
          .filter((obj) => obj.value !== undefined),
        'value'
      ).sort((a, b) => (a.label > b.label ? 1 : -1))
    : [];
  // Workaround to clear site filter
  allDomainsForFilter.unshift({ value: '', label: 'All Sites' });

  function linkFormatter(cell, row) {
    return (
      <Link to={`/campaigns/landingpagegroups/${row.id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
        {cell}
      </Link>
    );
  }

  const columns = [
    {
      dataField: 'id',
      text: 'ID',
      formatter: linkFormatter,
      ...columnDefaults,
    },
    {
      dataField: 'name',
      text: 'Landing Page Name',
      formatter: linkFormatter,
      ...columnDefaults,
    },
    {
      dataField: 'domain',
      text: 'Site',
      formatter: linkFormatter,
      filter: selectFilter({
        options: allDomainsForFilter,
        style: {
          backgroundColor: 'pink',
          display: 'none',
        },
        getFilter: (filter) => {
          // siteFilter was assigned once the component has been mounted.
          siteFilter = filter;
        },
      }),
      // sortValue: (cell, row) => cell,
      ...columnDefaults,
    },
    {
      dataField: 'lastEditedBy',
      text: 'Last Edited',
      formatter: linkFormatter,
      sortValue: (cell, row) => new Date(row.lastEdited),
      ...columnDefaults,
    },
    {
      dataField: 'isActive',
      text: 'Status',
      formatter: (cell, row) => {
        return (
          <Link to={`/campaigns/landingpagegroups/${row.id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
            <ActiveStatusModal
              content={row}
              isActive={row.isActive}
              modalType=' Landing Page Group'
              getErrors={() => null}
              onClickSubmit={(activeStatus) => updateStatus(row, activeStatus, 'landing-page-groups')}
              isEditMode={true}
            />
          </Link>
        );
      },
      ...columnDefaults,
    },
  ];

  return (
    <>
      <SummaryCard
        entity='Landing Page Groups'
        createComponent={
          <Link to='/campaigns/landingpagegroups/create' style={{ textDecoration: 'none', color: 'inherit' }}>
            <Button color='primary' className='float-right'>
              <i className='fa fa-plus' />
              &nbsp; Add Landing Page Group
            </Button>
          </Link>
        }
        columns={columns}
        defaultSorted={[{ dataField: 'lastEditedBy', order: 'desc' }]}
        data={data?.map((item) => ({
          ...item,
          domain: item?.site?.domain,
          lastEditedBy: item.zuulUser
            ? format(new Date(item.lastEdited), 'Pp') + ' by ' + item.zuulUser.realname
            : format(new Date(item.lastEdited), 'Pp'),
        }))}
        loading={loading}
        error={error}
      >
        <DropdownList
          dataKey='value'
          textField='label'
          defaultValue={allDomainsForFilter[0].value}
          data={allDomainsForFilter}
          onChange={(value) => siteFilter(value.value)}
        />
      </SummaryCard>
    </>
  );
};

export default LandingPageGroups;
