import axios from 'axios';
import { getAllAdditionalRequests } from '../../../actions';
import api from '../../../services';
import {
  CREATE_SDTS,
  CREATE_SEQUENCE_CHECKOUT,
  CREATE_SEQUENCE_VIEW,
  GET_SDTS,
  GET_SEQUENCE,
  GET_SEQUENCES,
  GET_SEQUENCES_TABLE,
  GET_SEQUENCE_CHECKOUT,
  GET_SEQUENCE_PUBLISHED,
  GET_SEQUENCE_VIEW,
  PUBLISH_SEQUENCE,
  PUBLISH_SEQUENCE_BY_ID,
  SET_REACT_FLOW_INSTANCE,
  SET_SEQUENCE_VIEW,
  UPDATE_ORPHANED_OFFERS,
  UPDATE_SDTS,
  UPDATE_SELECTED_ELEMENT,
  UPDATE_SEQUENCE_CHECKOUT,
  UPDATE_SEQUENCE_ERROR,
  UPDATE_SEQUENCE_VIEW,
  UPDATE_SEQUENCE_WARNING
} from './types';

export const getSequence = sequenceId => dispatch => {
  if (sequenceId) {
    return new Promise(resolve => {
      api.get(`sequences/${sequenceId}`).then(response => {
        dispatch({
          type: GET_SEQUENCE,
          payload: response.data
        });
        resolve(response.data);
      });
    });
  }
  dispatch({
    type: GET_SEQUENCE,
    payload: {}
  });
};

export const getSequencesTable = (limit = 100) => dispatch => {
  return new Promise(resolve => {
    api
      .get(`sequences/table?limit=${limit}&offset=${0}`)
      .then(response => {
        let allSequenceData = Array.from(response.data.content);
        // Retrieve remaining pages
        const { totalPages } = response.data;
        let restOfSequences = getAllAdditionalRequests(totalPages, limit, 'sequences/table');

        // Concurrently fire all page requests
        axios.all(restOfSequences).then(responses => {
          // Add remaining response data
          responses.forEach(response => {
            let { content } = response.data;
            allSequenceData = [...allSequenceData, ...content];
          });

          const nonDeletedSequences = allSequenceData.filter(sequence => sequence.status === 1);

          dispatch({
            type: GET_SEQUENCES_TABLE,
            payload: nonDeletedSequences
          });
          resolve(nonDeletedSequences);
        });

        // Concurrently fire all page request
      })
      .catch(err => console.log(err));
  });
};

export const getSequenceView = sequenceId => dispatch => {
  if (sequenceId) {
    return new Promise(resolve => {
      api.get(`sequences/sequenceView/${sequenceId}`).then(response => {
        dispatch({
          type: GET_SEQUENCE_VIEW,
          payload: response.data
        });
        resolve(response.data);
      });
    });
  }
  dispatch({
    type: GET_SEQUENCE_VIEW,
    payload: {
      sequence: {},
      sequenceDefaultTemplateSettings: [],
      offersSequences: []
    }
  });
};

export const getSequenceCheckout = sequenceId => dispatch => {
  if (sequenceId) {
    return new Promise(resolve => {
      api.get(`sequences/sequenceCheckout/${sequenceId}`).then(response => {
        dispatch({
          type: GET_SEQUENCE_CHECKOUT,
          payload: response.data
        });
        resolve(response.data);
      });
    });
  }
  dispatch({
    type: GET_SEQUENCE_CHECKOUT,
    payload: {
      sequence: {},
      sequencesCheckoutStatus: {},
      zuulUser: {}
    }
  });
};

export const getSequencePublished = sequenceId => dispatch => {
  if (sequenceId) {
    return new Promise(resolve => {
      api.get(`sequences/publish/${sequenceId}`).then(response => {
        dispatch({
          type: GET_SEQUENCE_PUBLISHED,
          payload: response.data
        });
        resolve(response.data);
      });
    });
  }
  dispatch({
    type: GET_SEQUENCE_PUBLISHED,
    payload: {
      sequence: {},
      sequenceDefaultTemplateSettings: [],
      offersSequences: []
    }
  });
};

export const createSDTS = sdts => dispatch => {
  try {
    return new Promise(resolve => {
      const savedSDTS = sdts.map(sequenceDefault => {
        return api.post('sdts', sequenceDefault);
      });
      Promise.all(savedSDTS).then(response => {
        const sequenceDefaults = response.map(res => res.data);

        dispatch({
          type: CREATE_SDTS,
          payload: sequenceDefaults
        });
        resolve(sequenceDefaults);
      });
    });
  } catch (error) {
    console.log(error);
  }
};

export const updateSDTS = sdts => dispatch => {
  try {
    return new Promise(resolve => {
      const savedSDTS = sdts.map(sequenceDefault => {
        return api.put('sdts', sequenceDefault);
      });
      Promise.all(savedSDTS).then(response => {
        const sequenceDefaults = response.map(res => res.data);

        dispatch({
          type: UPDATE_SDTS,
          payload: sequenceDefaults
        });
        resolve(sequenceDefaults);
        // return offerTriggers;
      });
    });
  } catch (error) {
    console.log(error);
  }
};

export const createSequenceDefaultTemplateSettings = sequenceId => dispatch => {
  if (sequenceId) {
    return new Promise(resolve => {
      api.get(`sdts/sequence/${sequenceId}`).then(response => {
        console.log(response);
        resolve(response.data);
      });
    });
  }
  dispatch({
    type: GET_SDTS,
    payload: []
  });
};

export const getSequences = (limit = 100) => dispatch => {
  return new Promise(resolve => {
    api
      .get(`sequences?limit=${limit}&offset=${0}`)
      .then(response => {
        // Set initial data
        let allSequenceData = Array.from(response.data.content);

        // Retrieve remaining pages
        const { totalPages } = response.data;
        let restOfSequences = getAllAdditionalRequests(totalPages, limit, 'sequences');

        // Concurrently fire all page requests
        axios.all(restOfSequences).then(responses => {
          // Add remaining response data
          responses.forEach(response => {
            let { content } = response.data;
            allSequenceData = [...allSequenceData, ...content];
          });

          dispatch({
            type: GET_SEQUENCES,
            payload: allSequenceData.filter(sequence => sequence.status === 1 || 2)
          });
          resolve(allSequenceData);
        });
      })
      .catch(err => console.log(err));
  });
};

export const createSequenceView = sequenceView => dispatch => {
  dispatch({
    type: CREATE_SEQUENCE_VIEW,
    payload: sequenceView
  });
};

export const createSequenceCheckout = sequenceCheckout => dispatch => {
  dispatch({
    type: CREATE_SEQUENCE_CHECKOUT,
    payload: sequenceCheckout
  });
};

export const setSequenceView = sequenceView => {
  return {
    type: SET_SEQUENCE_VIEW,
    payload: sequenceView
  };
};

export const updateSequenceView = sequenceView => dispatch => {
  dispatch({
    type: UPDATE_SEQUENCE_VIEW,
    payload: sequenceView
  });
};

export const updateSequenceCheckout = sequenceCheckout => dispatch => {
  dispatch({
    type: UPDATE_SEQUENCE_CHECKOUT,
    payload: sequenceCheckout
  });
};

export const updateSequenceError = e => {
  console.log(e.response);

  if (e.response?.status === 504) {
    console.log('504 error');
    return {
      type: UPDATE_SEQUENCE_WARNING,
      payload: { message: 'This sequence has a large amount of offers. As a result, the template update process will take a little longer than usual. Please check for your template changes in about 5 or more minutes.',
      status: e.response.status }
    };
  }

  const errorMessage =
    e.response?.data && e.response.data.message
      ? e.response.data.message
      : 'Something unexpected went wrong with your request. Please contact one of the developers.';
  return {
    type: UPDATE_SEQUENCE_ERROR,
    payload: { message: errorMessage, status: e.response?.status }
  };
};

export const createSequenceViewApi = sequenceView => dispatch => {
  console.log(sequenceView);
  try {
    return new Promise(resolve => {
      api
        .post(`sequences/sequenceView`, sequenceView)
        .then(response => {
          console.log(response.data);
          console.log('Created sequence view: ' + JSON.stringify(response.data));
          dispatch({
            type: CREATE_SEQUENCE_VIEW,
            payload: response.data
          });
          resolve(response.data);
        })
        .catch(error => {
          dispatch(updateSequenceError(error));
          resolve(error);
        });
    });
  } catch (error) {
    dispatch(updateSequenceError(error));
  }
};

export const updateSequenceViewApi = sequenceView => dispatch => {
  console.log(sequenceView);
  try {
    return new Promise(resolve => {
      api
        .put(`sequences/sequenceView/${sequenceView.sequence.id}`, sequenceView)
        .then(response => {
          dispatch({
            type: UPDATE_SEQUENCE_VIEW,
            payload: response.data
          });
          resolve(response.data);
        })
        .catch(error => {
          dispatch(updateSequenceError(error));
          resolve(error);
        });
    });
  } catch (error) {
    dispatch(updateSequenceError(error));
  }
};

export const publishSequenceApi = sequenceCheckout => dispatch => {
  console.log(sequenceCheckout);
  try {
    return new Promise(resolve => {
      api
        .post('sequences/publish', sequenceCheckout)
        .then(response => {
          console.log(response);
          dispatch({
            type: PUBLISH_SEQUENCE,
            payload: response.data
          });
          resolve(response.data);
        })
        .catch(error => {
          dispatch(updateSequenceError(error));
          resolve(error);
        });
    });
  } catch (error) {
    console.log(error);
    return dispatch(updateSequenceError(error));
  }
};

export const publishSequenceByIdApi = (sequenceId, sequenceCheckout) => dispatch => {
  try {
    return new Promise(resolve => {
      api
        .put(`sequences/publish/${sequenceId}`, sequenceCheckout)
        .then(response => {
          console.log(response);
          dispatch({
            type: PUBLISH_SEQUENCE_BY_ID,
            payload: response.data
          });
          resolve(response.data);
        })
        .catch(error => {
          dispatch(updateSequenceError(error));
          resolve(error);
        });
    });
  } catch (error) {
    dispatch(updateSequenceError(error));
  }
};

export const createSequenceCheckoutApi = sequenceCheckout => dispatch => {
  console.log(sequenceCheckout);
  try {
    return new Promise(resolve => {
      api
        .post('sequences/sequenceCheckout', sequenceCheckout)
        .then(response => {
          console.log(response.data);
          console.log('Created sequence checkout draft: ' + JSON.stringify(response.data));
          createSequenceCheckout(response.data)(dispatch);
          resolve(response.data);
        })
        .catch(error => {
          dispatch(updateSequenceError(error));
          resolve(error);
        });
    });
  } catch (error) {
    dispatch(updateSequenceError(error));
  }
};
// };

export const updateSequenceCheckoutApi = sequenceCheckout => dispatch => {
  try {
    return new Promise(resolve => {
      console.log(JSON.stringify(sequenceCheckout));
      api
        .put(`sequences/sequenceCheckout/${sequenceCheckout.sequence.id}`, sequenceCheckout)
        .then(response => {
          console.log(response.data);
          console.log('Updated sequence checkout draft: ' + JSON.stringify(response.data));
          updateSequenceCheckout(response.data)(dispatch);
          return resolve(response.data);
        })
        .catch(error => {
          dispatch(updateSequenceError(error));
          resolve(error);
        });
    });
  } catch (error) {
    dispatch(updateSequenceError(error));
  }
};

export const updateSequenceCheckoutStatus = sequenceCheckout => dispatch => {
  try {
    return new Promise(resolve => {
      console.log(JSON.stringify(sequenceCheckout));
      return api
        .put(`sequences/sequenceCheckout/status/${sequenceCheckout.sequence.id}`, sequenceCheckout)
        .then(response => {
          console.log(response.data);
          console.log('Updated Sequence Checkout Status: ' + JSON.stringify(response.data));
          updateSequenceCheckout(response.data)(dispatch);
          resolve(response.data);
        })
        .catch(error => {
          dispatch(updateSequenceError(error));
          resolve(error);
        });
    });
  } catch (error) {
    dispatch(updateSequenceError(error));
  }
};

export const updateSequenceCheckoutStatusIdBySequenceIdApi = (sequenceId, sequenceCheckoutStatusId) => dispatch => {
  console.log(sequenceId, sequenceCheckoutStatusId);
  return new Promise(resolve => {
    api.put(`sequences/sequenceCheckout/status/${sequenceId}/${sequenceCheckoutStatusId}`).then(response => {
      dispatch({
        type: UPDATE_SEQUENCE_CHECKOUT,
        payload: response.data
      });
      resolve(response.data);
    });
  });
};

// export const updateSequenceCheckoutStatusIdBySequenceIdApi = (sequenceId, sequenceCheckoutStatusId) => dispatch => {
//   console.log(sequenceId, sequenceCheckoutStatusId);
//   try {
//     return api
//       .put(`sequences/sequenceCheckout/${sequenceId}/${sequenceCheckoutStatusId}`)
//       .then(savedSequenceCheckout => {
//         updateSequenceCheckout(savedSequenceCheckout)(dispatch);
//       }).catch(savedSequenceCheckout => {
//         dispatch(updateSequenceError(savedSequenceCheckout));
//       })
//   } catch (status) {
//     updateSequenceError(status);
//   }
// };

export const setReactFlowInstance = reactFlowInstance => {
  return {
    type: SET_REACT_FLOW_INSTANCE,
    payload: reactFlowInstance
  };
};

export const updateSelectedElement = flowElement => {
  return {
    type: UPDATE_SELECTED_ELEMENT,
    payload: flowElement
  };
};

export const updateOrphanedOffers = (orphanedOffers, hasMoreThanOneOSHead) => {
  return {
    type: UPDATE_ORPHANED_OFFERS,
    payload: [orphanedOffers, hasMoreThanOneOSHead]
  };
};
