import React, { Component } from 'react';
import { Alert, Input, Row, Col, Button } from 'reactstrap';
import { Field, FieldArray } from 'redux-form';
import { renderCriteriaOptions, renderMultiSelect, renderField } from '../../views/Offers/OfferTitle/renderConsts';
import { connect } from 'react-redux';
import { validateWholeNums } from '../../views/Offers/OfferTitle/valildation';

class PreviousRegistration extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  renderAndOr = ({ input, type, meta }) => {
    return (
      <>
        <Input {...input} type={type}>
          <option value={null}>Select</option>
          <option value='AND'>All entries must pass</option>
          <option value='OR'>Any of these entries can pass</option>
        </Input>
        <br />
        {meta.error && meta.touched && <Alert color='danger'>{meta.error}</Alert>}
      </>
    );
  };

  renderPreviousRegistraions = ({ fields, name, andOrLogicFieldVal, isLoading }) => {
    if (fields.length === 0) {
      fields.push({
        domains: [],
        includeExcludeAction: null,
        previousRegistrationsMin: null,
        previousRegistrationsMax: null,
      });
    }
    return andOrLogicFieldVal === 'AND' || andOrLogicFieldVal === 'OR' ? (
      <Row>
        <Col lg={12}>
          {fields.map((fieldName, index) => (
            <div key={index}>
              <Row>
                {fields.length > 1 && (
                  <Col lg={1}>
                    <Button
                      color='danger'
                      className=''
                      type='button'
                      title='Remove Previous Registration'
                      onClick={() => fields.remove(index)}
                    >
                      <i className='fa fa-close' />
                    </Button>
                  </Col>
                )}
                <Col lg={2}>
                  <Field name={`${fieldName}.includeExcludeAction`} type='select' component={renderCriteriaOptions} />
                </Col>
                <Col lg={4}>
                  <Field
                    name={`${fieldName}.domains`}
                    id={`${fieldName}.domains`}
                    component={renderMultiSelect}
                    type='select'
                    textField={(item) => `${item.id}: ${item.name}`}
                    dataKey='name'
                    data={this.props.domainsForPreviousRegReq}
                    busy={this.props.isLoading}
                  />
                </Col>
                <Col lg={2}>
                  <Field
                    name={`${fieldName}.previousRegistrationsMin`}
                    component={renderField}
                    maxLength={2}
                    normalize={validateWholeNums}
                    placeholder='min'
                  />
                </Col>
                <Col lg={2}>
                  <Field
                    name={`${fieldName}.previousRegistrationsMax`}
                    component={renderField}
                    maxLength={2}
                    normalize={validateWholeNums}
                    placeholder='max'
                  />
                </Col>
              </Row>
              {index === fields.length - 1 && (
                <Row>
                  <Col lg={{ size: 2, offset: 10 }}>
                    <Button
                      color='success'
                      onClick={() =>
                        fields.push({
                          domains: [],
                          includeExcludeAction: null,
                          previousRegistrationsMin: null,
                          previousRegistrationsMax: null,
                        })
                      }
                      className='previous-registration-add'
                      style={{ float: 'right', height: '3em', width: '3em' }}
                    >
                      <i className='fa fa-plus' />
                    </Button>
                  </Col>
                </Row>
              )}

              <br />
            </div>
          ))}
        </Col>
      </Row>
    ) : (
      <></>
    );
  };

  render() {
    return (
      <Row>
        <Col lg={2}>
          <Field name='previousRegistrationConfiguration.andOrLogic' component={this.renderAndOr} type='select' />
        </Col>
        <Col lg={10}>
          <FieldArray
            name='previousRegistrationConfiguration.previousRegistrations'
            component={this.renderPreviousRegistraions}
            andOrLogicFieldVal={this.props.andOrLogicFieldVal}
            isLoading={this.props.isLoading}
          />
        </Col>
      </Row>
    );
  }
}

function mapStateToProps(state) {
  return {
    andOrLogicFieldVal: state.offerState.selector(state, 'previousRegistrationConfiguration.andOrLogic'),
  };
}

export default connect(mapStateToProps, {})(PreviousRegistration);
