import { Col, Input, Row } from 'reactstrap';
import React from 'react';
import Switch from 'react-switch';
import Multiselect from 'react-widgets/Multiselect';
import './SummaryPaneComponents.css';

const rowStyle = { marginTop: '1rem' };

export const SwitchRow = ({ label, value }) =>
  value ? (
    <Row>
      <Col>
        <label>{label}</label>
      </Col>
      <Col>
        <Switch
          checked={!!value}
          onColor='#4dbd74'
          offColor='#f86c6b'
          onHandleColor='#ffffff'
          uncheckedIcon={false}
          checkedIcon={false}
          boxShadow='0px 1px 5px rgba(0, 0, 0, 0.6)'
          activeBoxShadow='0px 0px 1px 10px rgba(0, 0, 0, 0.2)'
          height={18}
          width={42}
          className='react-switch'
          onChange={() => null}
          disabled={true}
        />
      </Col>
    </Row>
  ) : null;

export function SelectSummary({
  value,
  label,
  options = [
    { value: '', option: 'None' },
    { value: 'Include', option: 'Include' },
    { value: 'Exclude', option: 'Exclude' },
  ],
  children,
}) {
  return value ? (
    <Row style={rowStyle}>
      <Col lg={3}>
        <h5>{label}</h5>
      </Col>
      <Col xs={3}>
        <Input type='select' value={value} onChange={() => null} disabled={true}>
          {options.map(({ value, option }) => (
            <option key={value} value={value}>
              {option}
            </option>
          ))}
        </Input>
      </Col>
      {children}
    </Row>
  ) : null;
}

export function MultiselectSummaryRow({ value, label }) {
  const shouldRender = Array.isArray(value) && value.length;
  return shouldRender ? (
    <Row style={rowStyle}>
      {label && (
        <Col xs={3}>
          <h5>{label}</h5>
        </Col>
      )}
      <Col xs={6}>
        <MultiselectSummary value={value} />
      </Col>
    </Row>
  ) : null;
}

export const MultiselectSummary = ({ value, textField = 'name', busy = false }) =>
  value ? (
    <Multiselect
      placeholder='Select'
      value={value}
      textField={textField}
      dataKey='id'
      disabled={true}
      busy={busy}
      style={{ backgroundColor: '#515b65' }}
    />
  ) : null;

export const SelectInput = ({ value }) => (
  <>
    <Input value={value} type='select' readOnly disabled>
      <option value='None'>None</option>
      <option value='Include'>Include</option>
      <option value='Exclude'>Exclude</option>
    </Input>
    <br />
  </>
);

export function SelectRow({
  value,
  label,
  options = [
    { value: '', option: 'None' },
    { value: 'Include', option: 'Include' },
    { value: 'Exclude', option: 'Exclude' },
  ],
  children,
}) {
  return (
    <Row
      style={{
        marginTop: '1rem',
      }}
    >
      <Col lg={3}>
        <h5>{label}</h5>
      </Col>
      <Col lg={3} xs={3}>
        <Input type='select' placeholder='Select' value={value} disabled>
          {options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.option}
            </option>
          ))}
        </Input>
      </Col>
      {children}
    </Row>
  );
}
