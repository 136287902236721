import { formatDate } from '../../utils/conversionFunctions';
import _fp from 'lodash/fp';
import { revDiffPath } from './tableColumns';
import { filterForSearch } from '../../utils/tableFunctions';

/**
 * Given a sequence recommendation, returns a list of the values taken out of the sequence recommendation.
 * Used when filtering a sequence recommendation for the table. If any columns are added, you will need to add it
 * to the list for filtering to work on that column.
 * @param seqReco
 * @returns {(*|string)[]}
 */
const seqRecoSearchTransformer = seqReco => {
  const { id, dataScienceRecommendationId, createdAt, sequence, status } = seqReco;
  return [
    id,
    dataScienceRecommendationId,
    formatDate(createdAt),
    status,
    sequence.id,
    sequence.name,
    sequence.site.id,
    sequence.site.domain,
    _fp.get(revDiffPath)(seqReco)
  ];
};

/**
 * Given a sequence recommendation, returns a list with the site domain. This is used to filter on site name (domain)
 * on the filters of the sequence recommendation table.
 * @param seqReco
 * @returns {[undefined]}
 */
const seqRecoSiteNameTransformer = seqReco => {
  return [seqReco.sequence.site.domain];
};

/**
 * Given the parameters to filter with, will return a function that takes a list of sequence recommendations and
 * filters on the given parameters.
 * @param showRejected  A boolean that tells this function to show or filter rejected sequence recommendations
 * @param searchTerm    A string to search for in each visible column in the sequence recommendations table
 * @param siteName      A site name string to filter on site name only
 * @returns             A function that, given a list of sequence recommendations, will filter on the provided parameters
 */
const filterSeqRecos = (showRejected, searchTerm, siteName) => {
  const showOrHideRejected = !showRejected ? _fp.reject(({ status }) => status === 'REJECTED') : _fp.identity;
  const _searchTerm = searchTerm && searchTerm.length > 0 ? searchTerm : undefined;
  const _siteName = siteName && siteName.length > 0 ? siteName : undefined;

  return _fp.pipe(
    showOrHideRejected,
    filterForSearch(seqRecoSearchTransformer, _searchTerm),
    filterForSearch(seqRecoSiteNameTransformer, _siteName)
  );
};

export { seqRecoSearchTransformer, filterSeqRecos };
