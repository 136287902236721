import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Alert, Col, Row } from 'reactstrap';
import { FieldArray, getFormValues, reduxForm } from 'redux-form';
import { createLandingPageApi, editLandingPage, getLandingPage } from '../actions';
import { updateSelector } from '../../../actions';
import ActiveStatusModal from '../../../components/ActiveStatusModal/ActiveStatusModal';
import CancelEntityModal from '../../../components/CancelEntityModal/CancelEntityModal';
import { ImageCard } from '../../../components/ImageCard/ImageCard';
import PreviewOfferModal from '../../../components/PreviewOfferModal/PreviewOfferModal';
import SaveEntityModal from '../../../components/SaveEntityModal/SaveEntityModal';
import ZuulLoader from '../../../components/ZuulLoader/ZuulLoader';
import api from '../../../services';
import { CDN_ASSETS_BASE } from '../../../utils/constants';
import { handleInitializeActions } from '../../OfferActions/OfferActionsHelpers';
import SaveAsLandingPageModal from '../SaveAsModal';
import { LPActionsCard } from './LPActionsCard';
import { LPCard } from './LPCard';
import './LandingPageDetails.css';
import { ScriptsCard } from './ScriptsCard';
import { createOfferFromOldOffer, transformFormActionsToLandingPageActions } from './landingPageDetailsHelper';
import validate from './validate';
import CustomVariablesCard from '../../../components/CustomVariables/CustomVariablesCard';
import { processCustomVariables } from '../../../components/CustomVariables/utils';
import { ZuulToaster } from '../../../components/Toaster/ZuulToaster';
import toast from 'react-hot-toast';

const LandingPageDetails = (props) => {
  const [isActive, setIsActive] = useState(false);
  const [imageFile, setImageFile] = useState([]);
  const [loading, setLoading] = useState(true);
  const formValues = useSelector((state) => getFormValues('landingPageDetails')(state) || {});
  const errors = {
    landingPageName: false,
    invalid: props.submitFailed ? props.invalid : false,
    saveError: props.saveError,
    _error: props.saveError
      ? props.saveErrorMessage
      : props.submitFailed
      ? 'One or more of the fields you entered are invalid.'
      : null,
  };
  const { landingPageId } = useParams();
  const isEditMode = !!landingPageId;
  const history = useHistory();
  const dispatch = useDispatch();

  const {
    curLandingPage,
    isLandingPageSaved,
    newLandingPageName,
    saveError,
    whatIfAdvertiser,
    landingPageName,
  } = useSelector((state) => {
    const formValues = getFormValues('landingPageDetails')(state) || {};
    return {
      curLandingPage: state.landingPage.curLandingPage,
      isLandingPageSaved: state.landingPage.isLandingPageSaved,
      newLandingPageName: state.landingPage.newLandingPageName,
      saveError: state.landingPage.saveError,
      whatIfAdvertiser: state.advertiser.whatIfAdvertiser,
      landingPageName: formValues.landingPageName,
    };
  });

  useEffect(() => {
    dispatch(updateSelector('Landing Page Detail'));

    if (landingPageId) {
      initializeLandingPageData();
    } else {
      setLoading(false);
      dispatch(getLandingPage({ isActive: false }));
    }
  }, [landingPageId]);

  const initializeLandingPageData = async () => {
    try {
      const [landingPageResponse, landingPageTriggersResponse] = await Promise.all([
        api.get(`landing-pages/${landingPageId}`),
        api.get(`offer-trigger/landingpage/${landingPageId}`),
      ]);

      const landingPageData = landingPageResponse.data;
      const landingPageActions = handleInitializeActions(landingPageTriggersResponse.data);
      const { layout, container, scriptsHead, scriptsBodyTop, scriptsBodyBottom, offer = {} } = landingPageData;
      const {
        name: landingPageName,
        verifyWithBriteverify,
        scrubIsActive,
        triggersRegistration,
        customVariables = '[]',
        isActive = false,
        image1,
      } = offer;

      const imageURL =
        image1 && image1.id !== null ? CDN_ASSETS_BASE + image1.id + '/original.' + image1.fileExtension : null;
      if (imageURL) {
        const imageDataFile = {
          name: image1.fileName,
          id: image1.id,
          fileName: image1.fileName,
          fileExtension: image1.fileExtension,
          doNotResize: image1.doNotResize,
          encodedImageData: image1.encodedImageData,
          sizeCoordinates: offer.image1.sizeCoordinates,
          targetHeight: image1.targetHeight,
          targetWidth: image1.targetWidth,
          version: image1.version,
          preview: imageURL,
        };
        setImageFile([imageDataFile]);
      }

      props.initialize({
        isLandingPageSaved: false,
        landingPageName,
        layout,
        container,
        scriptsHead,
        scriptsBodyTop,
        scriptsBodyBottom,
        verifyWithBriteverify,
        scrubIsActive,
        triggersRegistration,
        customVariables: JSON.parse(customVariables),
        landingPageActions,
        image1: offer.image1,
        shouldResizeImage: image1 ? !image1.doNotResize : null,
      });
      setIsActive(isActive);
      setLoading(false);

      dispatch(getLandingPage(landingPageData));
    } catch (error) {
      console.log(error);
    }
  };

  const onSubmitForm = async (values) => {
    if (values.customVariables) values.customVariables = processCustomVariables(values.customVariables);
    if (isEditMode) {
      return await updateLp(values);
    } else {
      return await createLp(values);
    }
  };

  const prepareOfferToSave = (formValues, opts = {}) => {
    const { id, name } = opts;
    const currentOffer = curLandingPage.offer || {};
    const offerName = name && name !== '' ? name : formValues.landingPageName;
    const offer = createOfferFromOldOffer(currentOffer, id, offerName, whatIfAdvertiser, isActive, formValues);

    return {
      ...offer,
      zuulUser: JSON.parse(localStorage.getItem('userData')),
    };
  };

  const updateLp = async (formValues) => {
    const offerId = curLandingPage.offer.id || null;
    const landingPageActionsReduced = transformFormActionsToLandingPageActions(formValues?.landingPageActions);
    const offer = prepareOfferToSave(formValues, { id: offerId });
    const landingPage = {
      ...formValues,
      name: formValues.landingPageName,
      offer,
      id: landingPageId,
    };
    await dispatch(editLandingPage(landingPage, landingPageActionsReduced, landingPage.id));
    return !saveError;
  };

  const createLp = async (formValues) => {
    const landingPageActionsReduced = transformFormActionsToLandingPageActions(formValues?.landingPageActions);
    const offer = prepareOfferToSave(formValues);
    const landingPage = { ...formValues, name: formValues.landingPageName, offer };

    await dispatch(createLandingPageApi(landingPage, landingPageActionsReduced));
    if (!saveError) history.push('/campaigns/landingpages');
  };

  const copyLp = async (formValues) => {
    const saveAsName = newLandingPageName;
    const landingPageName = saveAsName && saveAsName !== '' ? saveAsName : formValues.landingPageName;
    const landingPageActionsReduced = transformFormActionsToLandingPageActions(formValues?.landingPageActions);
    const offer = prepareOfferToSave(formValues, { name: landingPageName, isActive: false });
    const landingPage = { ...formValues, name: landingPageName, offer };

    await dispatch(createLandingPageApi(landingPage, landingPageActionsReduced));
    toast.success('Landing Page Saved Successfully');
    return !saveError;
  };

  const getPreview = async (formValues) => {
    const offerID = curLandingPage.offer.id || null;
    const containerID = formValues.container.id;
    const layoutID = formValues.layout.id;
    const siteID = formValues.site.id;

    return new Promise((resolve) => {
      api.get(`offers/offer/preview/${offerID}/${containerID}/${layoutID}/${siteID}`).then((response) => {
        const myWindow = window.open();
        let html = response.data.replace(/{{{nr_script_header}}}/g, '').replace(/{{{nr_script_footer}}}/g, '');
        myWindow.document.write(html);
        resolve(html);
      });
    });
  };

  if (loading) {
    return <ZuulLoader />;
  }

  return (
    <div className='animated fadeIn'>
      <ZuulToaster position='top-right' />
      <form onSubmit={props.handleSubmit(onSubmitForm)}>
        <LPCard active={isActive} landingPageName={landingPageName} id={landingPageId}>
          <ActiveStatusModal
            content={curLandingPage}
            isActive={isActive}
            disabled={loading}
            getErrors={() => errors}
            onClickSubmit={async (activeStatus) => {
              await api
                .put(`landing-pages/active/${landingPageId}`, {
                  isActive: activeStatus ?? isActive,
                  zuulUser: JSON.parse(localStorage.getItem('userData')),
                })
                .then((response) => {
                  if (response.status === 200) initializeLandingPageData();
                })
                .catch((error) => {
                  console.error('Failed to update the active status:', error);
                });
            }}
            isEditMode={isEditMode}
            modalType={' landing page'}
          />
        </LPCard>
        <ImageCard
          handleError={(event) => console.error('Error loading image:', event)}
          fieldName='offer.image1'
          imageFile={imageFile}
          clearOnClick={() => setImageFile([])}
          setImageFile={(imageFile) => setImageFile([imageFile])}
        />
        <LPActionsCard />
        <FieldArray
          name='customVariables'
          component={CustomVariablesCard}
          customVarFlag='UD_'
          formValues={formValues}
          showVerify={true}
        />
        <ScriptsCard />
        <Row>
          <Col lg={6}>{isLandingPageSaved && <Alert color='success'>Landing Page Saved Successfully</Alert>}</Col>
          <Col lg={6}>
            <CancelEntityModal getErrors={() => errors} entityUrl={'/campaigns/landingpages'} />
            <SaveAsLandingPageModal
              isEditMode={isEditMode}
              getErrors={() => errors}
              onClickSubmit={props.handleSubmit(copyLp)}
            />
            <SaveEntityModal
              isEditMode={isEditMode}
              entityName={landingPageName}
              entityType={'Landing Page'}
              getErrors={() => errors}
              onClickSubmit={props.handleSubmit(onSubmitForm)}
              submitting={props.submitting}
            />
            <PreviewOfferModal
              isLP={true}
              isEditMode={isEditMode}
              getErrors={() => errors}
              onClickSubmit={props.handleSubmit(getPreview)}
            />
          </Col>
        </Row>
      </form>
    </div>
  );
};

const form = reduxForm({
  form: 'landingPageDetails',
  validate,
})(LandingPageDetails);

export default form;
