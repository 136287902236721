/**
 * Validation of metadata entries requires knowledge of the tagType to work. So, it can't implemented as a validation
 * function for ReduxForm since they ask for a validation function that is external to the component the form exists
 * in.
 */
const validate = tagType => metadataEntries => {
  const metadataKeys = metadataEntries.map(({ key }) => key)

  metadataKeys.forEach(k => {
    if(!k || k === '') {
      const e = Error('No empty keys allowed in metadata')
      e.name = 'EmptyKeyError'
      throw e
    }
  })

  tagType && tagType.requiredKeys.forEach(k => {
    if(!metadataKeys.includes(k)) {
      const e = Error(`Required keys not found in metadata`)
      e.name = 'RequiredKeyError'
      throw e
    }
  })

  return metadataEntries
}

const clearEntriesIfTagTypeDoesNotUseMetadata = tagType => metadataEntries => {
  return tagType && !tagType.usesMetadata ? [] : metadataEntries
}

const metadataEntriesToObj = metadataEntries => {
  return Object.fromEntries(metadataEntries.map(({ key, value }) => [key, value]))
}

export {
  validate,
  clearEntriesIfTagTypeDoesNotUseMetadata,
  metadataEntriesToObj
}
