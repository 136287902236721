import React, { useEffect, useState } from 'react';
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Label,
  Nav,
  NavItem,
  NavLink,
  PopoverBody,
  Row,
  TabContent,
  TabPane,
  UncontrolledPopover,
} from 'reactstrap';
import { Field } from 'redux-form';
import { renderComboBox } from '../../views/Offers/OfferTitle/renderConsts';
import { ZuulEditor } from '../ZuulEditor/ZuulEditor';

export const ImportCustomVariablesButton = ({
  customVariables,
  domains,
  setCustomVariables,
  popoverPosition = 'right',
}) => {
  const [isButtonRendered, setIsButtonRendered] = useState(false);

  useEffect(() => {
    setIsButtonRendered(true);
  }, []);

  return (
    <>
      <Button id='importPopover' type={'button'} color='primary'>
        Import
      </Button>
      {isButtonRendered && (
        <UncontrolledPopover
          target='importPopover'
          trigger='legacy'
          className='popover-container'
          placement={popoverPosition}
        >
          <PopoverBody>
            <ImportCustomVariables
              customVariables={customVariables}
              domains={domains}
              setCustomVariables={setCustomVariables}
            />
          </PopoverBody>
        </UncontrolledPopover>
      )}
    </>
  );
};

export const ImportCustomVariables = ({ customVariables, domains, setCustomVariables }) => {
  const [activeTab, setActiveTab] = useState('domain');

  return (
    <>
      <Nav tabs>
        <NavItem>
          <NavLink className={activeTab === 'domain' ? 'active' : ''} onClick={() => setActiveTab('domain')}>
            Import from Domain
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink className={activeTab === 'json' ? 'active' : ''} onClick={() => setActiveTab('json')}>
            Import from JSON
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId='domain'>
          <ImportCustomVariablesExistingDomain domains={domains} setCustomVariables={setCustomVariables} />
        </TabPane>
        <TabPane tabId='json'>
          <ImportCustomVariablesJSON customVariables={customVariables} setCustomVariables={setCustomVariables} />
        </TabPane>
      </TabContent>
    </>
  );
};

export const ImportCustomVariablesJSON = ({ customVariables, setCustomVariables }) => {
  const [customVariableJson, setCustomVariableJson] = useState(customVariables);
  const [error, setError] = useState('');

  const handleChange = (value) => {
    setCustomVariableJson(value);
    setError(validateCustomVariable(value));
  };

  return (
    <Card>
      <CardHeader>
        <Row>
          {error && (
            <Col lg={10} style={{ display: 'flex', alignItems: 'baseline' }}>
              <Alert color={'danger'}>{error}</Alert>
            </Col>
          )}
          <Col
            lg={2}
            style={{ display: 'flex', alignItems: 'baseline', justifyContent: 'flex-end', marginLeft: 'auto' }}
          >
            <Button disabled={!!error} color='primary' onClick={() => setCustomVariables(customVariableJson)}>
              Apply
            </Button>
          </Col>
        </Row>
      </CardHeader>
      <CardBody style={{ padding: 0 }}>
        <Row>
          <Col lg='12'>
            <ZuulEditor
              language={'json'}
              content={JSON.stringify(customVariables, null, 2)}
              handleEditorChange={handleChange}
              height='36vh'
              validate={false}
            />
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export const ImportCustomVariablesExistingDomain = ({ domains, setCustomVariables }) => {
  const [selectedDomain, setSelectedDomain] = useState();

  return (
    <Card>
      <CardHeader>
        <Row>
          <Col lg={9} style={{ display: 'flex', alignItems: 'baseline' }}>
            <Label for='domainVariable' style={{ width: '7em' }}>
              Domain:
            </Label>
            <Field
              type='select'
              name='domainVariable'
              id='domainVariable'
              data={domains}
              component={renderComboBox}
              allData={domains}
              textField='name'
              filter='contains'
              onChange={(value) => setSelectedDomain(value)}
            />
          </Col>
          {isValidCustomVariable(selectedDomain) && (
            <Col
              lg={3}
              style={{ display: 'flex', alignItems: 'baseline', justifyContent: 'flex-end', marginLeft: 'auto' }}
            >
              <Button color='primary' onClick={() => setCustomVariables(selectedDomain.customVariables)}>
                Apply
              </Button>
            </Col>
          )}
        </Row>
      </CardHeader>
      {isValidCustomVariable(selectedDomain) && (
        <CardBody style={{ padding: 0 }}>
          <Row>
            <Col lg='12'>
              <ZuulEditor
                language={'json'}
                content={JSON.stringify(JSON.parse(selectedDomain.customVariables), null, 2)}
                handleEditorChange={() => {}}
                height='20vh'
                validate={false}
                readOnly
              />
            </Col>
          </Row>
        </CardBody>
      )}
    </Card>
  );
};

function isValidCustomVariable(selectedDomain) {
  return selectedDomain && selectedDomain.customVariables && selectedDomain.customVariables !== 'null';
}

function validateCustomVariable(jsonString) {
  let dataArray;

  try {
    dataArray = JSON.parse(jsonString);
  } catch (error) {
    return 'Invalid JSON format.';
  }

  if (!Array.isArray(dataArray)) {
    return 'Root element must be an array.';
  }

  // Iterate over each item in the array to perform checks
  for (let item of dataArray) {
    if (typeof item !== 'object' || item === null || Array.isArray(item)) {
      return 'Each element in the array must be an object.';
    }

    const keys = Object.keys(item);
    if (keys.length === 2 && (!keys.includes('key') || !keys.includes('value'))) {
      return "Each object can only have 'key' and 'value' properties.";
    }

    if (keys.length === 1 && !keys.includes('key')) {
      return "Each object must have at least a 'key' property.";
    }

    if (keys.length >= 3) {
      return "Each object can only have a single 'key' and 'value' property.";
    }

    if (typeof item.key !== 'string') {
      return "The 'key' property must be a string.";
    }

    if (keys.length !== 1 && typeof item.value !== 'string') {
      return "The 'value' property must be a string.";
    }

    if (!/^DUD_[a-zA-Z0-9_]+$/.test(item.key)) {
      return "The 'key' format is invalid. It must start with 'DUD_' followed by alphanumeric characters or underscores.";
    }
  }
}
