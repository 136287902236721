import React, { Component } from 'react'
import { Row, Col, Label, Alert, Button, Input } from 'reactstrap'
import { Field, FieldArray, reduxForm } from 'redux-form'
import { connect } from 'react-redux';
import { updateTagForm, updateSelector } from '../../actions';
import { renderField, renderDropdownList, renderKVEditor } from '../Offers/OfferTitle/renderConsts'
import * as tagFormService from './tagFormService'
import _ from 'lodash/fp'

import './TagForm.css'

class TagForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      error: undefined
    }

    this.onSubmit = this.onSubmit.bind(this)
    this.renderError = this.renderError.bind(this)
    this.renderMetadataFields = this.renderMetadataFields.bind(this)
  }

  componentDidMount() {
    this.props.updateSelector('tagForm')
    for (let key in this.props.tag) {
      this.props.change(`${key}`, this.props.tag[key]);
    }
  }

  onSubmit(values) {
    const { validate, clearEntriesIfTagTypeDoesNotUseMetadata, metadataEntriesToObj } = tagFormService

    try {
      const validateAndPrepMetadata = _.pipe(
        validate(this.props.tagType),
        clearEntriesIfTagTypeDoesNotUseMetadata(this.props.tagType),
        metadataEntriesToObj
      )
      this.props.onSubmit({
        ...values,
        metadata: validateAndPrepMetadata(values.metadata)
      })
    } catch (e) {
      console.error(e)
      this.setState({ error: e })
    }
  }

  renderMetadataFields() {
    const { tagType, touch } = this.props
    return (
      <FieldArray
        name="metadata"
        component={renderKVEditor}
        props={{ tagType, touch }}
      />
    )
  }

  renderError({ message, name }) {
    const renderRequiredKeys = () => (
      <ul>
        {this.props.tagType.requiredKeys.map((k, idx) => {
          return (<li key={idx}>{k}</li>)
        })}
      </ul>
    )

    return (
      <Alert color="danger">
        { message }
        <br/>
        { name && name === 'RequiredKeyError' && renderRequiredKeys() }
      </Alert>
    )
  }

  render() {
    const { tagType, tagName, handleSubmit, anyTouched, toggleModal, touch } = this.props
    const showSaveButton = anyTouched && tagType && tagName

    return (
      <form
        className="edit-tag-form"
        onSubmit={handleSubmit(this.onSubmit)}>
        <Row>
          <Col lg={12}>
            { this.state.error && this.renderError(this.state.error) }
          </Col>
          <Col lg={12}>
            <Label>Tag Type</Label>
            <Field
              name='type'
              required
              component={renderDropdownList}
              data={this.props.tagTypes}
              valueField='name'
              textField='label'
              onChange={() => { touch('type') }}
            />
          </Col>
          <Col lg={12}>
            <Label>Tag Name</Label>
            <Field
              name='name'
              required
              component={renderField}
              placeholder='Tag'
            />
          </Col>
          <Col lg={12}>
            {tagType && tagType.usesMetadata && this.renderMetadataFields()}
          </Col>
          <Col className="edit-tag-form__footer" lg={12}>
            { showSaveButton && <Button className="edit-tag-form__save-button" color='success'>Save</Button> }
            <Button color='secondary' onClick={toggleModal}>
              Cancel
            </Button>
          </Col>
        </Row>
      </form>
    );
  }
}

const form = reduxForm({
  form: 'tagForm'
});

function mapStateToProps(state) {
  const { selector } = state.tags
  const tagName = selector(state, 'name')
  const tagType = selector(state, 'type')
  const tagMetadata = selector(state, 'metadata')
  return { tagName, tagType, tagMetadata };
}

export default connect(mapStateToProps, {
  updateSelector,
  updateTagForm
})(form(TagForm));
