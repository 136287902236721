export const filterActionTypesByLinkoutCount = (offerTriggers = []) => {
  let countOfLinkoutActions = 0;
  offerTriggers.forEach((offerTrigger) => {
    if (
      offerTrigger?.question_actions === 'LINKOUT' ||
      offerTrigger?.question_actions === 'LINKOUT_WATERFALL' ||
      offerTrigger?.offerActionType === 'LINKOUT' ||
      offerTrigger?.offerActionType === 'LINKOUT_WATERFALL'
    ) {
      countOfLinkoutActions++;
    }
  });
  let offerActionTypes = ['GTM_EVENT', 'LINKOUT', 'POST', 'POST_WATERFALL', 'LINKOUT_WATERFALL', 'USER_ATTRIBUTE'];
  if (countOfLinkoutActions > 0) {
    offerActionTypes = offerActionTypes.filter((actionType) => !actionType.includes('LINKOUT'));
  }
  return offerActionTypes;
};
