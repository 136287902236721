import React from 'react';
import './Button.css';

export default function KButton({ hide = true, onClick }) {
  const isMac = navigator.platform.toUpperCase().includes('MAC');

  return (
    <button
      id='search-button'
      type='button'
      onClick={onClick}
      className={`search-bar  `}
      style={{ display: hide ? 'none' : 'flex' }}
      disabled
    >
      <div className={`search-bar-icon search-bar-icon-medium`}>
        <span>{isMac ? 'Command +' : 'Ctrl +'}</span>
      </div>
      <div className={`search-bar-key search-bar-key-medium`}>K</div>
    </button>
  );
}
