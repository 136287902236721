import {
  UPDATE_SELECTOR,
  UPDATE_ADVERTISER_FORM
} from "../actions/types";
import { formValueSelector } from "redux-form";

const initialState = {
  selector: formValueSelector("advertiserForm")
};
export default function(state = initialState, action) {
  switch (action.type) {
    case UPDATE_SELECTOR:
      return { ...state, selector: formValueSelector(action.payload) };
    case UPDATE_ADVERTISER_FORM:
      return { ...state, advertiserForm: action.payload };
    default:
      return state;
  }
}
