import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import ErrorBoundary from '../../../components/ErrorBoundary/ErrorBoundary';
import WithLoading from '../../../loader/loader';
import {
  getOffersSequenceControlProjectionById,
  getSequenceRecommendationById,
} from '../../../services/sequenceRecommendations/api';
import SeqComparison from './OfferReoderingDetailsComparison';
import Scorecard from './OfferReorderingDetailsScorecard';

const ScorecardWithLoading = WithLoading(Scorecard);
const SeqComparisonWithLoading = WithLoading(SeqComparison);

const OfferReorderingDetails = (props) => {
  const [recommendedSequences, setRecommendedSequences] = useState([]);
  const [controlSequences, setControlSequences] = useState([]);
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const { id } = useParams();

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      setErrors({ errors });
      try {
        const _recommendedSequence = await getSequenceRecommendationById(id);
        const _controlSequences = await getOffersSequenceControlProjectionById(_recommendedSequence?.sequence?.id);
        setRecommendedSequences(_recommendedSequence);
        setControlSequences(_controlSequences);
      } catch (error) {
        setErrors(error?.response?.data);
      }
      setIsLoading(false);
    })();
    // Should include errors, but will look for better solution
  }, [id]);

  return (
    <>
      <ErrorBoundary message='There was an error with Sequence Scorecard'>
        <ScorecardWithLoading isLoading={isLoading} recommendedSequences={recommendedSequences} />
      </ErrorBoundary>
      <ErrorBoundary message='There was an error with Sequence Comparison Tables'>
        <SeqComparisonWithLoading
          isLoading={isLoading}
          controlSequences={controlSequences}
          recommendedSequences={recommendedSequences.sequenceRecommendationOffersList}
        />
      </ErrorBoundary>
    </>
  );
};

export default OfferReorderingDetails;
