import React from 'react';
import {Button, Card, CardBody, Col, Container, Row} from 'reactstrap';
import {Redirect} from 'react-router-dom';
import './Login.css';
import {useOktaAuth} from "@okta/okta-react";
import { toRelativeUrl } from '@okta/okta-auth-js';
import ZuulLoader from '../../../components/ZuulLoader/ZuulLoader';

export const OktaLogin = (props) => {

  const {oktaAuth, authState} = useOktaAuth();
  const login = () => {
    oktaAuth.signInWithRedirect();
  }

  if (authState?.isAuthenticated) {
    // Retrieve the stored URL after successful login
    const preLoginUrl = sessionStorage.getItem('preLoginUrl');
    // If there's a stored URL, redirect to it, otherwise redirect to home
    sessionStorage.removeItem('preLoginUrl');
    return <Redirect to={toRelativeUrl(preLoginUrl, window.location.origin) || '/'} replace />;
  }

  return (
    <div className='app flex-row align-items-center'>
      <Container>
        <Row className='justify-content-center'>
          <Col md='5'>
            <Card className='text-white py-5 shadow'>
              <CardBody className='text-center'>
                <div>
                  <h2>Login if you dare</h2>
                  <img
                    src='https://s3.amazonaws.com/whatif-assets-cdn/images/asset_1+copy2.png'
                    alt='zuul logo'
                    id='zuul_logo'
                    className='mx-auto d-block rounded img-avatar'
                  />
                  {authState === null ? (
                    <ZuulLoader text={'Logging in...'} />
                  ) : (
                    <Button type={"button"} onClick={login} color='primary' className='mt-3' active tabIndex={-1}>
                      Login with Okta
                    </Button>
                  )}
                  <div className='text-center text-secondary small mt-4'>Version: dccb8d4a216ebdc3033b6c2e76f71964ebcb2ac0</div>
                </div>
              </CardBody>
            </Card>
            <p className='text-center' style={{color: 'rgb(115, 129, 143)'}}>
              Copyright &copy; What If Media Group
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
