import React, { useEffect, useState } from 'react';
import { Button, Card, CardBody, CardHeader, Col, Collapse, Label, Row } from 'reactstrap';
import { Field } from 'redux-form';
import Dropzone from '../../views/Offers/OfferTitle/OfferForm/Dropzone';

/**
 * Renders an image card component.
 *
 * @param {Object} props - The component props.
 * @param {string} props.fieldName - The name of the field.
 * @param {Function} props.handleError - The error handling function.
 * @param {Object} props.imageFile - The image file object.
 * @param {Function} props.clearOnClick - The function to clear the image on click.
 * @param {Function} props.setImageFile - The function to set the image file.
 * @returns {JSX.Element} The rendered ImageCard component.
 */
export function ImageCard({ fieldName, handleError, imageFile, clearOnClick, setImageFile }) {
  const [collapse, setCollapse] = useState(false);
  const toggleCollapse = () => setCollapse(!collapse);

  useEffect(() => {
    if (imageFile && imageFile.length > 0) setCollapse(true);
  }, []);

  return (
    <Card>
      <CardHeader>
        <Row>
          <Col onClick={toggleCollapse} id='click' style={{ cursor: 'pointer' }}>
            <h4>
              Image Upload&nbsp;&nbsp;
              {collapse ? (
                <i className='fa fa-chevron-up opened' aria-hidden='true' />
              ) : (
                <i className='fa fa-chevron-down closed' aria-hidden='true' />
              )}
            </h4>
          </Col>
          <Col>
            <div className='resizeImage float-right'>
              <Label for='shouldResizeImage'>Resize Image</Label>&nbsp;&nbsp;
              <Field name='offer.shouldResizeImage' id='shouldResizeImage' component='input' type='checkbox' />
            </div>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <Collapse isOpen={collapse}>
          <Row>
            <Col lg='12'>
              <Field
                name={fieldName}
                component={Dropzone}
                type='file'
                handleError={handleError}
                imagefile={imageFile}
                setImageFile={setImageFile}
              />
            </Col>
            <Row>
              <Col lg='12'>
                <div className='text-right'>
                  <Button type='button' onClick={clearOnClick} className='clear-button'>
                    Clear
                  </Button>
                </div>
              </Col>
            </Row>
          </Row>
        </Collapse>
      </CardBody>
    </Card>
  );
}
