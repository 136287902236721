import React from 'react';
import { Field } from 'react-final-form';
import DropdownList from 'react-widgets/DropdownList';
import NumberPicker from 'react-widgets/NumberPicker';
import { Alert, Input, Label } from 'reactstrap';

export const RenderInputField = (props) => {
  return (
    <>
      <Label>{props.label}: </Label>
      <Field name={props.fieldName}>
        {({ input }) => (
          <>
            <Input
              placeholder={props.label}
              defaultValue={input.value}
              onChange={input.onChange}
              disabled={props.default}
            />
          </>
        )}
      </Field>
    </>
  );
};

export const RenderNumberPicker = ({ input, meta, placeholder, disabled, precision, step }) => {
  return (
    <>
      <NumberPicker
        precision={precision}
        placeholder={placeholder}
        defaultValue={input.value}
        onChange={input.onChange}
        disabled={disabled}
        step={step}
      />
      {meta.error && meta.touched && <Alert color='danger'>{meta.error}</Alert>}
    </>
  );
};

export const RenderDomainDropDown = ({ input, meta, allDomains, disabled }) => {
  console.log('disabled', disabled);
  return (
    <>
      <DropdownList {...input} disabled={disabled} data={allDomains} />
      {meta.touched && meta.error && <Alert color='danger'>{meta.error}</Alert>}
    </>
  );
};
