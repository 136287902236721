import React, {Component} from 'react';
import {Button, Col, Row,} from 'reactstrap';
import {connect} from 'react-redux';
import {fetchAllOfferActions} from "../../../actions";
import OfferActionsByTrigger from "./OfferActionsByTrigger"

class OfferActionsForm extends Component {

  render() {

    const { fields, entityName, triggerTypes, shouldShowSuppression = true } = this.props;

    const touched = this.props.meta.touched;
    const error = this.props.meta.error;

    return (
      <div>
        <hr />
        <Row>
          <Col lg={3}>
            <h4>{entityName} Actions</h4>
          </Col>
          <Col lg={9}>
            <Button
              color='success'
              className='float-right'
              onClick={() => fields.push({})}
            >
              Add {entityName} Action
            </Button>
            {touched && error && <span>{error}</span>}
          </Col>
        </Row>
        <br />
        {fields.map((offerTriggerActionsItem, index) => (
          <OfferActionsByTrigger
            offerActionsByTrigger={offerTriggerActionsItem}
            offerTriggerIndex={index}
            entityName={entityName}
            fields={fields}
            key={index}
            triggerTypes={triggerTypes}
            shouldShowSuppression={shouldShowSuppression}
          />
        ))}
      </div>
    );
  }

  componentDidMount() {
    this.props.fetchAllOfferActions();
  }

};

export default connect(null, {
  fetchAllOfferActions,
})(OfferActionsForm);
