import React, { useEffect, useRef } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { VariableSizeList as List } from 'react-window';

const DynamicList = ({ items, rowHeight, children }) => {
  const listRef = useRef();
  const rowHeights = (index) => (rowHeight ? rowHeight : 80);

  useEffect(() => {
    if (listRef.current) {
      listRef.current.scrollToItem(0);
    }
  }, [items]);

  return (
    <AutoSizer>
      {({ height, width }) => (
        <List
          ref={listRef}
          className='List'
          itemCount={items.length}
          itemSize={rowHeights}
          height={height}
          width={width}
          itemData={items}
        >
          {children}
        </List>
      )}
    </AutoSizer>
  );
};

export default DynamicList;
