import React from 'react';
import { Col, Row, TabPane } from 'reactstrap';
import { Field } from 'redux-form';
import { MultiselectField, NumberField, SelectRow, ToggleSwitchRow } from '.';
import states from '../../utils/states';
import { useAibList, useBrowsers, useDevices, useDomains, useOperatingSystems } from '../../views/OfferBlocks/queries';
import { renderCriteriaOptions, renderMultiSelect, textarea } from '../../views/Offers/OfferTitle/renderConsts';
import PreviousRegistration from './PreviousRegistration';
import { useUserSelectors } from './hooks';

export const UsersPane = (props) => {
  const { data: aibLists } = useAibList(true, (data) =>
    data.map((aibList) => aibList.listCode).sort((a, b) => (a > b ? 1 : -1))
  );
  const { data: browsers } = useBrowsers(true, (data) =>
    data.map((browser) => browser.name).sort((a, b) => (a > b ? 1 : -1))
  );
  const { data: domains, isLoading: domainsLoading } = useDomains(true, (data) =>
    data
      .map((domain) => ({
        id: domain.id,
        name: domain.name,
      }))
      .sort((a, b) => (a > b ? 1 : -1))
  );

  const { data: devices } = useDevices(true, (data) =>
    data.map((device) => device.name).sort((a, b) => (a > b ? 1 : -1))
  );
  const { data: operatingSystems } = useOperatingSystems(true, (data) =>
    data.map((operatingSystem) => operatingSystem.name).sort((a, b) => (a > b ? 1 : -1))
  );

  let domainsForPreviousRegReq = domains ? [...domains] : [];
  domainsForPreviousRegReq.unshift({ name: 'Current Domain', id: null });
  domainsForPreviousRegReq.unshift({ name: 'All Domains', id: null });

  const mobileBrowsers = ['Chrome', 'Opera', 'Firefox', 'Safari', 'IE', 'Other'];
  const {
    browserType,
    criteriaIncludeOrExcludeStates,
    criteriaIncludeOrExcludeZips,
    criteriaIncludeOrExcludeOperatingSystems,
    criteriaIncludeOrExcludeBrowsers,
    criteriaIncludeOrExcludeDomains,
    criteriaIncludeOrExcludeDevices,
    userIdLastDigitIncludeExclude,
    criteriaAibListIncludeExclude,
    criteriaUserDataIncludeExclude,
    criteriaZips,
  } = useUserSelectors();

  return (
    <TabPane tabId='Users'>
      <ToggleSwitchRow label='TCPA Opt-in Required' name='criteriaOfferRequiresOptIn' />
      <ToggleSwitchRow label='TCPA Opt-in Required Over Last Interval' name='criteriaTcpaLastOptInInterval' />
      <ToggleSwitchRow label='Brite Verify Phone Validated' name='criteriaPhoneHasBeenVerifiedWithBriteverify' />
      <ToggleSwitchRow label='Brite Verify Email Validated' name='criteriaEmailHasBeenVerifiedWithBriteverify' />
      <ToggleSwitchRow label='Include users in the phone BlackList' name='criteriaPhoneBlacklist' />
      <Row>
        <Col lg={2}>
          <Field name='criteriaMinAge' component={NumberField} label='Min Age' />
        </Col>
        <Col lg={2}>
          <Field name='criteriaMaxAge' component={NumberField} label='Max Age' />
        </Col>
      </Row>
      <br />
      <SelectRow
        name='criteriaExcludeBrowserType'
        label='Exclude Browser Type'
        options={[
          { value: '', option: 'Select' },
          { value: 'Desktop', option: 'Desktop' },
          { value: 'Mobile', option: 'Mobile' },
        ]}
      />
      <SelectRow
        name='criteriaExcludeGender'
        label='Exclude Gender'
        options={[
          { value: '', option: 'Select' },
          { value: 'female', option: 'Female' },
          { value: 'male', option: 'Male' },
        ]}
      />
      <Row style={{ marginTop: '1rem' }}>
        <Col lg={2}>
          <h5>Required User Data</h5>
        </Col>
        <Col lg={2}>
          <Field name='criteriaUserDataIncludeExclude' component={renderCriteriaOptions} type='select' />
        </Col>
        <Col lg={4}>
          {criteriaUserDataIncludeExclude === 'Include' || criteriaUserDataIncludeExclude === 'Exclude' ? (
            <Field
              name='criteriaRequiredUserData'
              component={MultiselectField}
              data={['email', 'first_name', 'last_name', 'gender', 'dob', 'phone', 'zip', 'state', 'city', 'address_1']}
            />
          ) : null}
        </Col>
      </Row>
      <br />
      <Row>
        <Col lg={2}>
          <h5>AIB List</h5>
        </Col>
        <Col lg={2}>
          <Field name='criteriaAibListIncludeExclude' component={renderCriteriaOptions} type='select' />
        </Col>
        <Col lg={4}>
          {(criteriaAibListIncludeExclude === 'Include' || criteriaAibListIncludeExclude === 'Exclude') && (
            <Field name='criteriaAibList' component={renderMultiSelect} data={aibLists} />
          )}
        </Col>
      </Row>
      <br />
      <Row>
        <Col lg={2}>
          <h5>Previous Registration Required</h5>
        </Col>
        <Col lg={10}>
          <PreviousRegistration domainsForPreviousRegReq={domainsForPreviousRegReq} isLoading={domainsLoading} />
        </Col>
      </Row>
      <SelectRow name='criteriaNullSourceNewUserIncludeExclude' label='Null Source New Users' />
      <SelectRow label='User_ID ends with' name='criteriaIncludeOrExcludeUserIdLastDigit'>
        {(userIdLastDigitIncludeExclude === 'Include' || userIdLastDigitIncludeExclude === 'Exclude') && (
          <Col lg='4'>
            <Field
              name='criteriaUserIdLastDigit'
              component={renderMultiSelect}
              data={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9]}
              allowCreate={true}
            />
          </Col>
        )}
      </SelectRow>
      <SelectRow label='Device' name='criteriaIncludeOrExcludeDevices'>
        {(criteriaIncludeOrExcludeDevices === 'Include' || criteriaIncludeOrExcludeDevices === 'Exclude') && (
          <Col lg='4'>
            <Field name='criteriaDevices' component={renderMultiSelect} data={devices} />
          </Col>
        )}
      </SelectRow>
      <SelectRow label='Browsers' name='criteriaIncludeOrExcludeBrowsers'>
        {(criteriaIncludeOrExcludeBrowsers === 'Include' || criteriaIncludeOrExcludeBrowsers === 'Exclude') && (
          <Col lg='4'>
            <Field
              name='criteriaBrowsers'
              component={renderMultiSelect}
              data={browserType === 'Only mobile' ? mobileBrowsers : browsers}
            />
          </Col>
        )}
      </SelectRow>
      <SelectRow label='What If Domain' name='criteriaIncludeOrExcludeDomains'>
        {(criteriaIncludeOrExcludeDomains === 'Include' || criteriaIncludeOrExcludeDomains === 'Exclude') && (
          <Col lg='4'>
            <Field
              className='text-white'
              name='criteriaDomains'
              id='whatif-domains'
              component={renderMultiSelect}
              textField={(item) => `${item.id}: ${item.name}`}
              dataKey='name'
              data={domains}
              busy={domainsLoading}
            />
          </Col>
        )}
      </SelectRow>
      <SelectRow label='Operating System' name='criteriaIncludeOrExcludeOperatingSystems'>
        {(criteriaIncludeOrExcludeOperatingSystems === 'Include' ||
          criteriaIncludeOrExcludeOperatingSystems === 'Exclude') && (
          <Col lg='4'>
            <Field name='criteriaOperatingSystems' component={renderMultiSelect} data={operatingSystems} />
          </Col>
        )}
      </SelectRow>
      <SelectRow label='States' name='criteriaIncludeOrExcludeStates'>
        {(criteriaIncludeOrExcludeStates === 'Include' || criteriaIncludeOrExcludeStates === 'Exclude') && (
          <Col lg='4'>
            <Field name='criteriaStates' component={renderMultiSelect} data={states} />
          </Col>
        )}
      </SelectRow>
      <SelectRow label='Zip Code' name='criteriaIncludeOrExcludeZips'>
        {(criteriaIncludeOrExcludeZips === 'Include' || criteriaIncludeOrExcludeZips === 'Exclude') && (
          <>
            <Col lg='4'>
              <Field name='criteriaZips' component={textarea} />
            </Col>
            <Col lg='2'>
              Count: {criteriaZips?.split(/\s+/).filter((zip) => zip.trim().length === 5 && !isNaN(zip)).length}
            </Col>
          </>
        )}
      </SelectRow>
    </TabPane>
  );
};
