import React from 'react';
import Editor, { useMonaco } from '@monaco-editor/react';

export const ZuulEditor = ({ content, handleEditorChange, height, minimapOn = false, language, validate = true, readOnly }) => {
  const monaco = useMonaco();

  const validateHTML = (htmlString) => {
    const stack = [];
    const lines = htmlString.split('\n');

    const matchingPair = {
      '{': '}',
      '[': ']',
    };

    let markers = [];

    lines.forEach((line, lineIndex) => {
      for (let i = 0; i < line.length; i++) {
        const char = line[i];

        if (char === '{' || char === '[') {
          stack.push({ char, line: lineIndex + 1, position: i + 1 });
        } else if (char === '}' || char === ']') {
          if (stack.length === 0 || matchingPair[stack[stack.length - 1].char] !== char) {
            markers.push({
              startLineNumber: lineIndex + 1,
              startColumn: i + 1,
              endLineNumber: lineIndex + 1,
              endColumn: i + 2,
              severity: monaco.MarkerSeverity.Error,
              message: `Mismatched delimiter '${char}' at line ${lineIndex + 1}, position ${i + 1}`,
            });
            return;
          }
          stack.pop();
        }
      }
    });

    if (stack.length > 0) {
      markers = stack.map(({ char, line, position }) => {
        return {
          startLineNumber: line,
          startColumn: position,
          endLineNumber: line,
          endColumn: position + 1,
          severity: monaco.MarkerSeverity.Error,
          message: `Mismatched delimiter '${char}' at line ${line + 1}, position ${position + 1}`,
        };
      });

    }

    monaco.editor.setModelMarkers(monaco.editor.getModels()[0], 'my-source', markers);

    handleEditorChange(htmlString, markers.length > 0);
  };

  return (
    <Editor
      height={height || '77vh'}
      defaultLanguage={language || 'html'}
      theme='vs-dark'
      value={content}
      onChange={(value) => validate ? validateHTML(value) : handleEditorChange(value)}
      options={{
        wordWrap: 'on',
        minimap: { enabled: minimapOn },
        fontSize: 14,
        readOnly: readOnly || false,
      }} />
  );
};
