import { useQueryClient } from '@tanstack/react-query';
import React from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Button, Nav, NavItem, NavLink } from 'reactstrap';
import ZuulLoader from '../../components/ZuulLoader/ZuulLoader';
import { useAuth } from '../../services/oktaAuth/useAuth';
import { useOfferActionsTable } from '../OfferActions/queries';
import OfferTitle from './OfferTitle/OfferTitle';
import {
  useDeleteOfferVariationByOfferId,
  useFlexByOfferID,
  useOffer,
  useOfferVariantsByPrimaryOfferId,
} from './queries';

const OfferDetails = () => {
  useAuth();
  const queryClient = useQueryClient();
  const { offerId } = useParams();
  const location = useLocation();
  const history = useHistory();
  const selectedOffer = new URLSearchParams(location.search).get('selectedOffer') || offerId;
  const { data: offerActionsData, isLoading: offerActionsLoading } = useOfferActionsTable();
  const { data: offerData, isLoading: offerLoading } = useOffer(selectedOffer, offerId !== 'create');
  const { data, isLoading, error, refetch: refetchOfferVariations } = useOfferVariantsByPrimaryOfferId(
    selectedOffer,
    offerId !== 'create' && offerData && offerData.isPrimaryVariant !== null
  );
  const { data: flex, isLoading: flexLoading } = useFlexByOfferID(
    selectedOffer,
    offerId !== 'create' && offerData && offerData.isPrimaryVariant !== null
  );
  const { mutate: deleteOfferVariation } = useDeleteOfferVariationByOfferId(() => {
    if(data.length === 2){
      // If there are only 2 variations in state, the user just deleted the only other variation so we redirect to the original offer to clear state
      return window.location.replace(`/campaigns/offertable/offer/${offerId}`);
    }
    return history.push(`/campaigns/offertable/offer/${offerId}?selectedOffer=${offerId}`);
  });

  const handleTabClick = (tab) => {
    history.push(`/campaigns/offertable/offer/${offerId}?selectedOffer=${tab}`);
  };

  const invalidateFlexAndOffer = () => {
    queryClient.invalidateQueries('flex');
    queryClient.invalidateQueries('offer');
  };

  if (offerId !== 'create') {
    const currentUrl = `/campaigns/offertable/offer/${offerId}?selectedOffer=${selectedOffer}`;
    if (location.pathname + location.search !== currentUrl) {
      history.replace(currentUrl);
    }
  }

  if (
    offerActionsLoading ||
    (offerId !== 'create' && offerLoading) ||
    (offerId !== 'create' && selectedOffer !== offerId && isLoading)
  ) {
    return <ZuulLoader />;
  }

  const allTabs = data?.sort((a, b) => a.offer.id - b.offer.id).map((el) => `${el.offer.id}`);

  const posts = [];
  const linkouts = [];
  const attributes = [];
  const gtmEvents = [];
  const postWaterfalls = [];
  const linkoutWaterfalls = [];

  offerActionsData?.forEach((offerAction) => {
    const { offerActionType } = offerAction;

    const commonProps = {
      active: offerAction.active,
      offerActionId: offerAction.id,
      name: offerAction.title,
      advertiser: offerAction.advertiser,
    };

    if (offerActionType === 'POST') {
      posts.push(commonProps);
    } else if (offerActionType === 'LINKOUT') {
      linkouts.push(commonProps);
    } else if (offerActionType === 'USER_ATTRIBUTE') {
      attributes.push(commonProps);
    } else if (offerActionType === 'POST_WATERFALL') {
      postWaterfalls.push(commonProps);
    } else if (offerActionType === 'LINKOUT_WATERFALL') {
      linkoutWaterfalls.push(commonProps);
    } else if (offerActionType === 'GTM_EVENT') {
      gtmEvents.push(commonProps);
    }
  });

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Nav tabs>
          {offerId !== 'create' &&
            allTabs?.map((tab) => (
              <NavItem key={tab}>
                <NavLink active={selectedOffer === tab} onClick={() => handleTabClick(tab)}>
                  {tab}
                </NavLink>
              </NavItem>
            ))}
        </Nav>
        {offerData?.isPrimaryVariant === false ? (
          <Button color='danger' onClick={() => deleteOfferVariation(selectedOffer)}>
            <i className='fa fa-trash'></i>&nbsp; Delete Variation
          </Button>
        ) : null}
      </div>
      <OfferTitle
        key={selectedOffer}
        selectedOfferId={selectedOffer}
        offerActionsData={offerActionsData}
        primaryOfferId={offerId}
        currentOffer={offerData}
        flex={flex}
        posts={posts}
        linkouts={linkouts}
        attributes={attributes}
        gtmEvents={gtmEvents}
        postWaterfalls={postWaterfalls}
        linkoutWaterfalls={linkoutWaterfalls}
        refetchOfferVariations={() => refetchOfferVariations()}
        invalidateFlexAndOffer={invalidateFlexAndOffer}
      />
    </>
  );
};

export default OfferDetails;
