import { GET_ALL_HANDLERS } from '../../actions/types';
import api from '../../../../services';
import { sortByName } from '../../../../utils/sorting';

export const getAllHandlers = (handlers) => {
  return {
    type: GET_ALL_HANDLERS,
    payload: handlers,
  };
};

export const fetchAllHandlers = () => {
  return function (dispatch) {
    return getHandlersApi().then(
      (normalizedHandlers) => dispatch(getAllHandlers(normalizedHandlers)),
      (error) => console.log(error)
    );
  };
};

export const getHandlersApi = (dispatch) => {
  return new Promise((resolve) => {
    api
      .get('handlers')
      .then((response) => {
        let sortedHandlers = sortByName(response.data);

        resolve(sortedHandlers);
      })
      .catch((error) => console.log(error));
  });
};
