import React, { Component } from 'react';
import { differenceBy, uniqBy } from 'lodash';
import { Redirect } from 'react-router-dom';
import { CSVLink } from 'react-csv';
import './UserDetails.css';
import {
  Input,
  Row,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  CardText,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import api from '../../services';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';

class UserDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userID: this.props.match.params.userId,
      userSession: [],
      userhistory: [],
      user: {},
      loading: false,
      open: false,
    };

    this.toggle = this.toggle.bind(this);
    this.setUserSessionInfo = this.setUserSessionInfo.bind(this);
    this.setUserHistoryInfo = this.setUserHistoryInfo.bind(this);
    this.setUserInfo = this.setUserInfo.bind(this);
    this.getUserSessionFileName = this.getUserSessionFileName.bind(this);
    this.getUserSessionFileHeaders = this.getUserSessionFileHeaders.bind(this);
    this.getUserHistoryFileName = this.getUserHistoryFileName.bind(this);
    this.getUserHistoryFileHeaders = this.getUserHistoryFileHeaders.bind(this);
  }

  componentDidMount() {
    this.setUserSessionInfo();
    this.setUserHistoryInfo();
    this.setUserInfo();
  }
  deidentifyUser = async (searchInput) => {
    let res = await api.put(`user/deidentify/${this.state.userID}`);
    if (res.status === 200) {
      console.log('in setState' + res.status);
      this.setState({ userID: null, open: false });
      window.location = '/dashboard';
    }
  };

  setUserInfo() {
    api
      .get(`user/user/${this.state.userID}`)
      .then((response) => {
        this.setState({ user: response.data });
      })
      .catch((error) => console.log(error));
  }

  setUserSessionInfo() {
    api
      .get(`user/usersession/list/${this.state.userID}`)
      .then((response) => {
        this.setState({ userSession: response.data });
      })
      .catch((error) => console.log(error));
  }

  setUserHistoryInfo() {
    api
      .get(`user/userhistory/list/${this.state.userID}`)
      .then((response) => {
        this.setState({ userhistory: response.data });
      })
      .catch((error) => console.log(error));
  }

  toggle() {
    this.setState({ open: !this.state.open });
  }

  getUserSessionFileName() {
    return this.state.user.email + '-sessions.csv';
  }

  getUserSessionFileHeaders(userSessionColumns) {
    return userSessionColumns.map((row) => {
      const header = { label: row.text, key: row.dataField };
      return header;
    });
  }

  getUserHistoryFileName() {
    return this.state.user.email + '-history.csv';
  }

  getUserHistoryFileHeaders(userHistoryColumns) {
    return userHistoryColumns.map((row) => {
      const header = { label: row.text, key: row.dataField };
      return header;
    });
  }

  render() {
    const userSessionColumns = [
      {
        dataField: 'id',
        text: 'User ID',
        sort: true,
      },
      {
        dataField: 'device',
        text: 'Device',
        sort: true,
      },
      {
        dataField: 'operatingSystem',
        text: 'OS',
        sort: true,
      },
      {
        dataField: 'operatingSystemVersion',
        text: 'OS Ver.',
        sort: true,
      },
      {
        dataField: 'browser',
        text: 'Browser',
        sort: true,
      },
      {
        dataField: 'browserVersion',
        text: 'Browser Ver.',
        sort: true,
      },
      {
        dataField: 'userAgent',
        text: 'User Agent',
        sort: true,
      },
      {
        dataField: 'ipAddr',
        text: 'IP Address',
        sort: true,
      },
      {
        dataField: 'ipCity',
        text: 'IP City',
        sort: true,
      },
      {
        dataField: 'ipState',
        text: 'IP State',
        sort: true,
      },
      {
        dataField: 'ipZip',
        text: 'IP Zip',
        sort: true,
      },
      {
        dataField: 'ipCountry',
        text: 'IP Country',
        sort: true,
      },
      {
        dataField: 'createdAt',
        text: 'Creation Date',
        sort: true,
      },
    ];

    const userHistoryColumns = [
      {
        dataField: 'firstName',
        text: 'First Name',
        sort: true,
      },
      {
        dataField: 'lastName',
        text: 'Last Name',
        sort: true,
      },
      {
        dataField: 'dob',
        text: 'Date of Birth',
        sort: true,
      },
      {
        dataField: 'address1',
        text: 'Address 1',
        sort: true,
      },
      {
        dataField: 'address2',
        text: 'Address 2',
        sort: true,
      },
      {
        dataField: 'city',
        text: 'City',
        sort: true,
      },
      {
        dataField: 'state',
        text: 'State',
        sort: true,
      },
      {
        dataField: 'zipcode',
        text: 'Zip Code',
        sort: true,
      },
      {
        dataField: 'country',
        text: 'Country',
        sort: true,
      },
      {
        dataField: 'ipCountry',
        text: 'IP Country',
        sort: true,
      },
      {
        dataField: 'phone',
        text: 'phone',
        sort: true,
      },
      {
        dataField: 'updatedAt',
        text: 'Date of Change',
        sort: true,
      },
    ];
    const leftField = {
      paddingRight: '10px',
      float: 'left',
    };
    const rightField = {
      paddingLeft: '10px',
      float: 'left',
    };

    return (
      <div>
        <Card>
          <CardHeader>User Details</CardHeader>
          <CardBody>
            <Row>
              <Col xs='3'>
                <h4>
                  {this.state.user.firstName} {this.state.user.lastName}
                </h4>
              </Col>
              <Col xs='9' className='text-left'>
                CreatedDate: {this.state.user.createdAt}
              </Col>

              <div className='left_container'>
                <div className='field_lable'>
                  <table>
                    <tr>
                      <td>Email:</td>
                      <td>{this.state.user.email}</td>
                    </tr>
                    <tr>
                      <td>Phone:</td>
                      <td>{this.state.user.phone}</td>
                    </tr>
                    <tr>
                      <td>Date of Birth:</td>
                      <td>{this.state.user.dob}</td>
                    </tr>
                    <tr>
                      <td>Gender:</td>
                      <td>{this.state.user.gender} </td>
                    </tr>
                  </table>
                </div>
              </div>

              <div className='right_container'>
                <div className='field_lable'>
                  <table>
                    <tr>
                      <td>Address1:</td>
                      <td>{this.state.user.address1} </td>
                    </tr>
                    <tr>
                      <td>Address2:</td>
                      <td>{this.state.user.address2} </td>
                    </tr>
                    <tr>
                      <td>City:</td>
                      <td>{this.state.user.city} </td>
                    </tr>
                    <tr>
                      <td>State:</td>
                      <td>{this.state.user.state} </td>
                    </tr>
                    <tr>
                      <td>Zip Code:</td>
                      <td>{this.state.user.zipcode} </td>
                    </tr>
                    <tr>
                      <td>Country:</td>
                      <td>{this.state.user.country} </td>
                    </tr>
                  </table>
                </div>
              </div>
            </Row>
            <br />
            <Row className='button_wrapper'>
              <Col xs={4} sm={4} md={4} lg={2}>
                <CSVLink
                  data={this.state.userSession}
                  filename={this.getUserSessionFileName()}
                  headers={this.getUserSessionFileHeaders(userSessionColumns)}
                  className='btn btn-primary'
                  target='_blank'
                >
                  <i className='fa fa-download'></i> Export User Sessions
                </CSVLink>
              </Col>
              <Col xs={4} sm={4} md={4} lg={2}>
                <CSVLink
                  data={this.state.userhistory}
                  filename={this.getUserHistoryFileName()}
                  headers={this.getUserHistoryFileHeaders(userHistoryColumns)}
                  className='btn btn-primary'
                  target='_blank'
                >
                  <i className='fa fa-download'></i> Export User History
                </CSVLink>
              </Col>
              <Col xs={4} sm={4} md={4} lg={2}>
                <Button color='danger' onClick={() => this.toggle()}>
                  <i className='fa fa-trash'></i>&nbsp; DeIdentify
                </Button>
              </Col>
            </Row>
          </CardBody>
        </Card>

        <div className='display-4'>User Sessions</div>

        <BootstrapTable
          ref='table'
          keyField='id'
          data={this.state.userSession}
          columns={userSessionColumns}
          pagination={paginationFactory()}
          sort
        />
        <br />
        <br />
        <hr />
        <div className='display-4'>User History</div>
        <BootstrapTable
          ref='table'
          keyField='id'
          data={this.state.userhistory}
          columns={userHistoryColumns}
          pagination={paginationFactory()}
          sort
        />
        <br />
        <div>
          <div>
            <br />
            {
              <Modal isOpen={this.state.open} toggle={this.toggle} className={'modal-danger'}>
                <ModalHeader toggle={this.toggleSuccess}>Delete User</ModalHeader>
                <ModalBody>
                  <Row>
                    <Col
                      xs={2}
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        color: '#f86c6b',
                      }}
                    >
                      <i className='fa fa-warning fa-3x'></i>
                    </Col>
                    <Col xs={10}>Are you sure you wish to delete the user with the following information:</Col>
                    <br />
                    <br />
                    <br />
                    <Col xs={{ size: 9, offset: 3 }}>
                      First Name: {this.state.user.firstName}
                      <br />
                      Last Name: {this.state.user.lastName}
                      <br />
                      Email: {this.state.user.email}
                      <br />
                      phone: {this.state.user.phone} <br />
                      Gender: {this.state.user.gender} <br />
                      Address1: {this.state.user.address1} <br />
                      Address2: {this.state.user.address2} <br />
                      City: {this.state.user.city} <br />
                      State: {this.state.user.state} <br />
                      Zip Code: {this.state.user.zipcode} <br />
                      Country: {this.state.user.country} <br />
                    </Col>
                  </Row>
                  <br />
                </ModalBody>
                <ModalFooter>
                  <Button onClick={() => this.deidentifyUser()}>Delete</Button>
                  <Button color='secondary' onClick={this.toggle}>
                    Cancel
                  </Button>
                </ModalFooter>
              </Modal>
            }
          </div>
        </div>
      </div>
    );
  }
}

export default UserDetails;
