import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import api from '../../services';

import { useHistory } from 'react-router-dom';

export const useCreateContainer = () => {
  const queryClient = useQueryClient();
  const history = useHistory();

  return useMutation({
    mutationFn: async (data) => api.post(`containers`, data),
    onSuccess: (response) => {
      queryClient.invalidateQueries({ queryKey: ['containers'] });
      history.push(`/content/containers/${response.data.id}`);
    },
  });
};

export const useOfferTypes = (enabled = true, selectFn = (data) => data) =>
  useQuery({
    queryKey: ['offerTypes'],
    queryFn: () => api.get('offertypes').then((res) => res.data),
    enabled,
    select: selectFn,
  });

export const useContainer = (containerId, selectFn = (data) => data) =>
  useQuery({
    queryKey: ['containers', containerId],
    queryFn: () => api.get(`containers/${containerId}`).then((res) => res.data),
    enabled: isNaN(containerId) ? false : true,
    select: selectFn,
  });

export const useContainers = (selectFn = (data) => data) =>
  useQuery({
    queryKey: ['containers'],
    queryFn: () => api.get(`containers/raw`).then((res) => res.data),
    select: selectFn,
    enabled: true,
  });

export const useContainersTable = (selectFn = (data) => data) =>
  useQuery({
    queryKey: ['containersTable'],
    queryFn: () => api.get(`containers/table`).then((res) => res.data),
    select: selectFn,
    enabled: true,
  });

export const useUpdateContainer = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data) => api.put(`containers/${data.id}`, data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['containers'] });
    },
  });
};

export const useGetContainerById = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (id) => api.get(`containers/${id}`).then((res) => res.data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['containers'] });
    },
  });
};

export const useDeleteContainer = () => {
  const queryClient = useQueryClient();
  const history = useHistory();
  return useMutation({
    mutationFn: async (id) => api.delete(`containers/${id}`),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['containers'] });
      history.push(`/content/containers`);
    },
  });
};
