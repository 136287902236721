import React from 'react';
import Combobox from 'react-widgets/Combobox';
import { Alert, Button, Col, Row } from 'reactstrap';
import { Field } from 'react-final-form';

export default function SelectAdvertiserPrePings({ fields, allAdvertiserPrePings, push }) {
  return (
    <div>
      {fields.length < 20 && (
        <Button color='success' id='addAdvertiserPrePing' onClick={() => push('advertiserPrePings')}>
          Add Pre-Ping
        </Button>
      )}
      <br />
      <br />
      {fields.map((advertiserPrePingEntry, index) => (
        <Row className='rowSpacing' key={index}>
          <Col>
            <Field
              name={advertiserPrePingEntry}
              validate={(value) =>
                value && typeof value === 'object' ? undefined : 'Please select an Advertiser Pre-Ping'
              }
            >
              {({ input, meta }) => (
                <>
                  <Combobox
                    {...input}
                    textField='name'
                    data={allAdvertiserPrePings}
                    onChange={input.onChange}
                    onBlur={() => input.onBlur(input.value)}
                    filter='contains'
                    busy={allAdvertiserPrePings?.length === 0}
                    busySpinner={
                      <span className='fa fa-spinner fa-spin' style={{ color: 'white', marginBottom: '8px' }} />
                    }
                    placeholder='Select or type to search...'
                  />
                  {meta.touched && meta.error && <Alert color='danger'>{meta.error}</Alert>}
                </>
              )}
            </Field>
          </Col>
          <Button className='delete-pre-ping-button' onClick={() => fields.remove(index)} color='danger'>
            <i className='fa fa-minus' />
          </Button>
        </Row>
      ))}
    </div>
  );
}
