import _ from 'lodash';
import {
  GET_QUESTION_ANSWERS,
  GET_CRITERIA_QUESTIONS,
  GET_USER_ATTRIBUTES,
  UPDATE_ADDITIONAL_OFFERS,
  SAVE_INIT_DATA,
  GET_DISPATCH,
  GET_AFFILIATES,
  GET_DOMAINS,
} from '../actions/types';
import { GET_ALL_HANDLERS } from '../views/Metadata/actions/types';
const initialState = {
  criteriaQuestions: [],
  criteriaQuestionMap: {},
  handlers: [],
  offerQuestionsArr: [],
  critQuestionAnswers: [],
  initData: {},
  isLoading: false,
  additionalOffersObj: {},
  additionalOffersArray: [],
  userAttributes: [],
  affiliates: [],
  domains: [],
  domainsForPreviousRegReq: [],
};
export default function(state = initialState, action) {
  switch (action.type) {
    case GET_DISPATCH:
      return { ...state, dispatch: action.payload };
    case GET_ALL_HANDLERS:
      return {
        ...state,
        handlers: action.payload,
      };
    case GET_CRITERIA_QUESTIONS:
      const [criteriaQuestions] = action.payload;
      return {
        ...state,
        criteriaQuestions: [...criteriaQuestions],
      };
    case GET_USER_ATTRIBUTES:
      return {
        ...state,
        userAttributes: action.payload,
      };
    case SAVE_INIT_DATA:
      return { ...state, initData: action.payload };
    case GET_QUESTION_ANSWERS:
      return { ...state, criteriaQuestionAnswers: [...action.payload] };
    case UPDATE_ADDITIONAL_OFFERS:
      const [additionalOffers, isLoading] = action.payload;
      return {
        ...state,
        additionalOffersObj: _.mapKeys(additionalOffers, 'id'),
        additionalOffersArray: additionalOffers,
        isLoading,
      };
    case GET_AFFILIATES:
      return {
        ...state,
        affiliates: action.payload,
      };
    case GET_DOMAINS:
      return {
        ...state,
        domains: action.payload[0],
        domainsForPreviousRegReq: action.payload[1],
      };
    default:
      return state;
  }
}
