import { columnDefaults } from '../../utils/tableFunctions';

export const validateDropdownValues = (dropdownValues) => {
  let isValid = true;
  let isDuplicate = {};
  let isEmpty = {};

  // Check for empty values and duplicates
  dropdownValues.forEach((item) => {
    const trimmedValue = item.value.trim();
    if (!trimmedValue) {
      isEmpty[item.id] = true;
      isValid = false;
    }
    if (trimmedValue && dropdownValues.filter((v) => v.value.trim() === trimmedValue).length > 1) {
      isDuplicate[item.id] = true;
      isValid = false;
    }
  });

  return { isValid, isDuplicate, isEmpty };
};

export const columns = [
  {
    dataField: 'id',
    text: 'ID',
    ...columnDefaults,
  },
  {
    dataField: 'name',
    text: 'Name',
    ...columnDefaults,
  },
  {
    dataField: 'valueType',
    text: 'Value Type',
    ...columnDefaults,
  },
  {
    dataField: 'inputMethod',
    text: 'Value Input Method',
    ...columnDefaults,
  },
  {
    dataField: 'updatedAt',
    text: 'Last Published Time/User',
    formatter: (_, row) => `${new Date(row.updatedAt).toLocaleString()} by ${row.zuulUser.realname}`,
    ...columnDefaults,
  },
];

export const valueInputOptions = [
  { value: '', option: 'Select Input Method...' },
  { value: 'DROPDOWN', option: 'Dropdown' },
  { value: 'USER_INPUT', option: 'User Input' },
];