import React, { useState } from 'react';
import { Card, CardBody, CardHeader, Col, Collapse, Row } from 'reactstrap';
import PropTypes from 'prop-types';

/**
 * CollapsibleCard component for toggling visibility of content.
 *
 * @param {Object} props - The properties object.
 * @param {string} props.title - The title to display on the card header.
 * @param {React.ReactNode} props.children - The content to render inside the collapsible body.
 *
 * @returns {React.Component} A collapsible card component.
 */
const CollapsibleCard = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Card>
      <CardHeader>
        <Row>
          <Col lg='10' onClick={() => setIsOpen(!isOpen)}>
            <h4>
              {title}
              {isOpen ? <i className='fa fa-chevron-up opened' /> : <i className='fa fa-chevron-down closed' />}
            </h4>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <Collapse isOpen={isOpen}>{children}</Collapse>
      </CardBody>
    </Card>
  );
};

CollapsibleCard.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default CollapsibleCard;
