import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import api from '../../services';

export const useCreateHandler = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data) => api.post(`handlers`, data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['handlers'] });
    },
  });
};

export const useHandlers = (enabled = true, selectFn = (data) => data) =>
  useQuery({
    queryKey: ['handlers'],
    queryFn: () => api.get('handlers').then((res) => res.data),
    enabled,
    select: selectFn,
  });

export const useUpdateHandler = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data) => api.put(`handlers/${data.id}`, data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['handlers'] });
    },
  });
};
export const useDeleteHandler = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (id) => api.delete(`handlers/${id}`),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['handlers'] });
    },
  });
};
