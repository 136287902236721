import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import React, { Suspense, useEffect, useState, lazy } from 'react';
import { BrowserRouter } from 'react-router-dom';
import './App.scss';
import OktaApp from './OktaApp';
import CommandK from './components/CommandK/CommandK/CommandK';

const loading = () => <div className='animated fadeIn pt-3 text-center'>Loading...</div>;

const queryClient = new QueryClient();

const ReactQueryDevtoolsProduction = lazy(() =>
  import('@tanstack/react-query-devtools/build/lib/index.prod.js').then((d) => ({
    default: d.ReactQueryDevtools,
  }))
);

const App = () => {
  const [showDevtools, setShowDevtools] = useState(false);

  useEffect(() => {
    // @ts-ignore
    if (!sessionStorage.getItem('preLoginUrl'))
      sessionStorage.setItem(
        'preLoginUrl',
        window.location.pathname.includes('/login') ? '/dashboard' : window.location.href
      );
    window.toggleDevtools = () => setShowDevtools((old) => !old);
  }, []);

  return (
    <Suspense fallback={loading()}>
      <QueryClientProvider client={queryClient}>
        <CommandK />
        <BrowserRouter>
          <OktaApp />
        </BrowserRouter>
        <ReactQueryDevtools initialIsOpen={false} />
        {showDevtools && (
          <React.Suspense fallback={null}>
            <ReactQueryDevtoolsProduction />
          </React.Suspense>
        )}
      </QueryClientProvider>
    </Suspense>
  );
};
export default App;
