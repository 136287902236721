import React, { Component } from 'react';
import { Prompt } from 'react-router-dom';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

export class ExitConfirmation extends Component {
  state = {
    confirmedNavigation: false,
  };

  handleBlockedNavigation = () => {
    if (!this.state.confirmedNavigation) {
      this.props.openModal();
      return false;
    }

    return true;
  };

  handleConfirmNavigationClick = () => {
    this.setState(
      {
        confirmedNavigation: true,
      },
      () => {
        // Navigate to the previous blocked location with your navigate function
        this.props.navigate('/campaigns/sequences');
      }
    );
  };

  render() {
    const { closeModal, visible, when } = this.props;

    return (
      <>
        <Prompt when={when} message={this.handleBlockedNavigation} />
        <Modal isOpen={visible} className='modal-info'>
          <ModalHeader>Exit Sequence Confirmation </ModalHeader>
          <ModalBody>Are you sure you want to exit a sequence without saving?</ModalBody>
          <ModalFooter>
            <Button onClick={this.handleConfirmNavigationClick} color='primary'>
              Confirm
            </Button>
            <Button onClick={() => closeModal()}>Cancel</Button>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

export default ExitConfirmation;
