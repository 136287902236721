import { Col, Label, Row, Card, CardBody } from 'reactstrap';
import { Field } from 'redux-form';
import React, { useEffect, useState } from 'react';
import { renderDropdownList } from '../../views/Offers/OfferTitle/renderConsts';
import { NumberField } from './index';
import { useSelector } from 'react-redux';

const InternalSuppressionItem = ({ shouldRenderSuppression, criteriaType }) => {
  const [suppressionTypeData, setSuppressionTypeData] = useState([]);
  const [showAnswers, setShowAnswers] = useState(false);

  const offerType = useSelector((state) => state.offerState && state.offerState.form);
  const suppressionRules = useSelector((state) => state.offerState.selector(state, 'suppressionRules'));
  const limitAnswers = useSelector((state) => {
    return (
      state.offerState
        .selector?.(state, 'questionAnswers')
        ?.map((questionAnswer) => questionAnswer.text)
        ?.filter(Boolean) || []
    );
  });

  useEffect(() => {
    if (suppressionRules?.suppression_type === 'answer') {
      setShowAnswers(true);
    }
    if (criteriaType === 'Offer Action Criteria') {
      setSuppressionTypeData(['select', 'none', 'action_taken', 'revenue_generated']);
    } else if (offerType === 'offerquestion' || offerType === 'offerform') {
      setSuppressionTypeData(['select', 'none', 'calculated_revenue', 'accepted', 'impression', 'answer']);
    } else {
      setSuppressionTypeData(['select', 'none', 'calculated_revenue', 'accepted', 'impression']);
    }
  }, [suppressionRules, offerType, criteriaType]);

  return (
    <>
      <Card>
        <CardBody>
          <Row>
            <Col>
              <h6>Suppression Item</h6>
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <Label>Type</Label>
              <Field
                name='suppressionRules.duration.type'
                component={renderDropdownList}
                type='select'
                defaultValue='none'
                data={['none', 'days', 'hours', 'minutes']}
              />
            </Col>
            {shouldRenderSuppression && (
              <>
                <Col sm={6}>
                  <Field name='suppressionRules.duration.amount' component={NumberField} label='Amount' />
                </Col>
                <Col sm={showAnswers ? 4 : 6}>
                  <Label>Level</Label>
                  <Field
                    name='suppressionRules.suppression_type'
                    component={renderDropdownList}
                    type='select'
                    defaultValue='select'
                    data={suppressionTypeData}
                    onChange={(e) => {
                      setShowAnswers(e === 'answer');
                    }}
                  />
                </Col>
                {showAnswers && (
                  <Col sm={4}>
                    <Label>Answer</Label>
                    <Field
                      name='suppressionRules.suppression_answer'
                      component={renderDropdownList}
                      type='select'
                      defaultValue=''
                      data={limitAnswers}
                    />
                  </Col>
                )}
                <Col sm={showAnswers ? 4 : 6}>
                  <Field name='suppressionRules.duration.max_limit' component={NumberField} label='Max Limit' />
                </Col>
              </>
            )}
          </Row>
        </CardBody>
      </Card>
    </>
  );
};

export default InternalSuppressionItem;
