import classNames from 'classnames';
import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Nav, NavItem, NavLink, TabContent } from 'reactstrap';
import './DefaultAside.css';
import VariablePane from './VariablePane';
import OffersPane from './OffersPane';

const DefaultAside = (props) => {
  const [state, setState] = useState({ activeTab: '1' });
  const toggle = (tab) => {
    if (state.activeTab !== tab) {
      setState({ activeTab: tab });
    }
  };

  return (
    <>
      <Nav tabs>
        <NavItem>
          <NavLink className={classNames({ active: state.activeTab === '1' })} onClick={() => toggle('1')}>
            <i className='icon-list' />
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink className={classNames({ active: state.activeTab === '2' })} onClick={() => toggle('2')}>
            <i className='cui-code' />
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={state.activeTab}>
        <OffersPane />
        <VariablePane />
      </TabContent>
    </>
  );
};

export default withRouter(DefaultAside);
