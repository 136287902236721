import { Editor } from '@tinymce/tinymce-react';
import React, { useRef } from 'react';
import { Alert } from 'reactstrap';
import api from '../../../../services';

const uploadImage = async (file) => {
  const formData = new FormData();
  formData.append('file', file);

  const response = await api.post('image/upload', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return response.data;
};

const FormEditor = ({ input, meta: { touched, error } }) => {
  const editorRef = useRef(null);
  const url =
    import.meta.env.MODE === 'development' ||
    (import.meta.env.VITE_LOCAL_TESTING && import.meta.env.VITE_LOCAL_TESTING === 'true')
      ? 'http://localhost:3000/'
      : window.env.baseURL;

  const image_upload_handler = (blobInfo, progress) =>
    new Promise((resolve, reject) => {
      const file = blobInfo.blob();

      const location = uploadImage(file);
      return location.then((url) => {
        resolve(url);
      });
    });

  return (
    <>
      <Editor
        {...input}
        tinymceScriptSrc={url + 'tinymce/tinymce.min.js'}
        onInit={(evt, editor) => (editorRef.current = editor)}
        init={{
          plugins: [
            'advlist',
            'autolink',
            'lists',
            'emoticons',
            'link',
            'image',
            'charmap',
            'anchor',
            'searchreplace',
            'visualblocks',
            'code',
            'fullscreen',
            'insertdatetime',
            'table',
            'preview',
            'help',
            'wordcount',
          ],
          promotion: false,
          branding: false,
          toolbar_mode: 'floating',
          skin: 'oxide-dark',
          forced_root_block: 'div',
          newline_behaviour: 'linebreak',
          extended_valid_elements: 'script[language|type|src]',
          images_upload_handler: image_upload_handler,
        }}
        onEditorChange={input.onChange}
        onDrop={(e) => e.stopPropagation()}
      />
      {touched && error && <Alert color='danger'>{error}</Alert>}
    </>
  );
};

export default FormEditor;
