export const env = (() => {
  const commonConfig = {
    OFFER_CDN_URL: 'https://d3v7hbq4afry8x.cloudfront.net',
  };

  switch (import.meta.env.VITE_DEPLOYMENT_ENV) {
    case 'localDev':
      return {
        ...commonConfig,
        baseURL: 'http://localhost:9191/',
        CDN_ASSETS_BASE: 'https://s3.amazonaws.com/whatif-assets-cdn/banners/stagingzuul2/',
      };
    case 'development':
      return {
        ...commonConfig,
        baseURL: 'https://zuul-dev.whatifops.com/',
        CDN_ASSETS_BASE: 'https://s3.amazonaws.com/whatif-assets-cdn/development/',
      };
    case 'staging':
      return {
        ...commonConfig,
        baseURL: 'https://zuul-stage.whatifops.com/',
        CDN_ASSETS_BASE: 'https://s3.amazonaws.com/whatif-assets-cdn/banners/stagingzuul2/',
      };
    case 'prod_test':
      return {
        ...commonConfig,
        baseURL: 'https://zuul-prod-test.whatifops.com/',
        CDN_ASSETS_BASE: 'https://s3.amazonaws.com/whatif-assets-cdn/banners/',
      };
    case 'prod':
      return {
        ...commonConfig,
        baseURL: 'https://zuul.whatifops.com/',
        CDN_ASSETS_BASE: 'https://s3.amazonaws.com/whatif-assets-cdn/banners/',
      };
    default:
      return {
        ...commonConfig,
        baseURL: 'http://localhost:9191/',
        CDN_ASSETS_BASE: 'https://s3.amazonaws.com/whatif-assets-cdn/banners/stagingzuul2/',
      };
  }
})();
