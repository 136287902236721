import React, { useMemo, useState } from 'react';
import Clipboard from 'react-clipboard.js';
import {
  Col,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  ListGroup,
  ListGroupItem,
  Row,
  TabPane,
} from 'reactstrap';
import variableList from './variableList.json';

function VariableItem({ variable }) {
  const { name, code } = variable;
  return (
    <ListGroupItem key={code}>
      <Row>
        <Col lg={4}>
          <small>{name}</small>
        </Col>
        <Col style={{ wordBreak: 'break-word' }} lg={5}>
          <small>{code}</small>
        </Col>
        <Col lg={3}>
          <Clipboard data-clipboard-text={code} className='button'>
            <i className='fa fa-copy' />
          </Clipboard>
        </Col>
      </Row>
    </ListGroupItem>
  );
}

function VariablePane(props) {
  const [search, setSearch] = useState('');

  const filteredVariables = useMemo(() => {
    return variableList.filter((variable) => {
      return variable.name.toLowerCase().includes(search.toLowerCase());
    });
  }, [search]);

  return (
    <TabPane tabId='2'>
      <ListGroup className='list-group-accent' tag={'div'}>
        <div className='sticky-top'>
          <ListGroupItem className='list-group-item-accent-secondary bg-light text-center font-weight-bold text-muted text-uppercase small m-0'>
            Variables
          </ListGroupItem>
          <InputGroup>
            <InputGroupAddon addonType='prepend'>
              <InputGroupText>
                <i className='fa fa-search' />
              </InputGroupText>
            </InputGroupAddon>
            <Input
              className='search-aside'
              type='text'
              onChange={(e) => setSearch(e.target.value)}
              placeholder='Search...'
            />
          </InputGroup>
        </div>
        {filteredVariables.map((variable) => (
          <VariableItem key={variable.code} variable={variable} />
        ))}
      </ListGroup>
    </TabPane>
  );
}

export default VariablePane;
