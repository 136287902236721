import React, { Component } from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
} from 'reactstrap';
import TagForm from './TagForm'

import './EditTag.css'

class EditTag extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tag: {
        ...props.tag,
        metadata: Object.entries(props.tag.metadata || {}).map(([key, value]) => ({ key, value }))
      }
    }
    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit(values) {
    try {
      this.props.toggleModal();
      this.props.onClickSubmit(values, false);
    } catch (e) {
      console.error(e)
      this.setState({ error: e })
    }
  }

  render() {
    const { isModalOpen, toggleModal, tag, tagTypes } = this.props;

    return (
      <div className='animated'>
        <Modal isOpen={isModalOpen} toggle={toggleModal} size='lg'>
          <ModalHeader toggle={toggleModal}>
            { this.props.title }
          </ModalHeader>
          <ModalBody className="edit-tag-modal">
            <TagForm
              tag={this.state.tag}
              tagTypes={tagTypes}
              showSaveButton={this.showSaveButton}
              toggleModal={toggleModal}
              onSubmit={this.onSubmit}/>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

export default EditTag
