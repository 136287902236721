import React from 'react';
import { getBezierPath, getEdgeCenter, getMarkerEnd } from 'react-flow-renderer';
import './dnd.css';
import { Button } from 'reactstrap';

const foreignObjectSize = 40;

const onEdgeClick = (evt, id) => {
  evt.preventDefault();
};

export default function CustomEdge({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  data,
  arrowHeadType,
  markerEndId
}) {
  const edgePath = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition
  });
  const markerEnd = getMarkerEnd(arrowHeadType, markerEndId);
  const [edgeCenterX, edgeCenterY] = getEdgeCenter({
    sourceX,
    sourceY,
    targetX,
    targetY
  });

  return (
    <>
      <path id={id} style={style} className='react-flow__edge-path' d={edgePath} markerEnd={markerEnd} />
      <foreignObject
        width={foreignObjectSize}
        height={foreignObjectSize}
        x={edgeCenterX - foreignObjectSize / 2}
        y={edgeCenterY - foreignObjectSize / 2}
        className={'edgebutton-foreignobject'}
      >
        <Button
          color='danger'
          className='edgebutton'
          type='button'
          title='Remove Connection'
          onClick={event => onEdgeClick(event, id)}
        >
          <i className='fa fa-close' />
        </Button>
      </foreignObject>
    </>
  );
}
