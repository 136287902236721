import { useQueryClient } from '@tanstack/react-query';
import React, { useState } from 'react';
import { Field, Form } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';
import DatePicker from 'react-widgets/DatePicker';
import { Alert, Button, Col, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import api from '../../services';
import { useDomains } from '../OfferBlocks/queries';
import { renderToggleSwitch } from '../Offers/OfferTitle/renderConsts';
import RevShareExceptionModal from './RevShareExceptionModal';
import './RevSharePercentage.css';
import { RenderDomainDropDown, RenderInputField, RenderNumberPicker } from './components';

const RevSharePercentageDetails = (props) => {
  const [showDefaultRuleModal, setShowDefaultRuleModal] = useState(false);
  const [confirmDefaultModalOpen, setConfirmDefaultModalOpen] = useState(false);
  const [oldDefaultRule, setOldDefaultRule] = useState();
  const [newRule, setNewRule] = useState();
  const [exceptionModalOpen, setExceptionModalOpen] = useState(false);
  const [exceptionReason, setExceptionReason] = useState();
  const { data: domains } = useDomains(true, (data) =>
    data.map((domain) => domain.name).sort((a, b) => (a > b ? 1 : -1))
  );
  const queryClient = useQueryClient();

  const affIdsWithDefault = props.revSharePercentageRules.map((el) => {
    if (el.default) {
      return el.affiliateId;
    }
    return null;
  });

  const onSubmit = (form) => {
    setNewRule(form);
    const originalStartDate = form.startDate;
    const originalEndDate = form.endDate;
    if (form.default) {
      delete form.endDate;
      delete form.webDomain;
      delete form.subId;
      delete form.landingPageGroupId;
    }

    delete form.affiliateName;

    // check if there is a default rule for this affiliate and turn it false if there is
    if (affIdsWithDefault.includes(form.affiliateId) && oldDefaultRule?.id) {
      let falseDefaultRule = {
        ...oldDefaultRule,
        default: false,
        startDate: oldDefaultRule.startDate,
        endDate: form.startDate,
      };

      const newDefaultRule = {
        ...form,
        startDate: form.startDate,
      };

      api.put(`revshare-percentage-rules/${oldDefaultRule.id}`, falseDefaultRule).then((resp) => {
        // oldDefault rule is now false
        if (resp.status === 200) {
          api
            .post(`revshare-percentage-rules`, newDefaultRule)
            .finally(() => {
              props.closeDetailModal();
              queryClient.invalidateQueries({ queryKey: ['revSharePercentageRules'] });
            })
            .catch((error) => {
              if (error.response) {
                if (error.response.data.message === 'Newly-created Rule conflicts with an existing Rule') {
                  if (
                    (oldDefaultRule?.default || oldDefaultRule?.default === 'TRUE') &&
                    (form?.default || form?.default === 'TRUE')
                  ) {
                    setExceptionReason('Existing Default Rule');
                  }
                  setExceptionModalOpen(true);
                }
              }
            });
        }
      });
    } else {
      form.id
        ? // update a rule
          api
            .put(`revshare-percentage-rules/${form.id}`, form)
            .finally(() => {
              props.closeDetailModal();
              queryClient.invalidateQueries({ queryKey: ['revSharePercentageRules'] });
            })
            .catch((error) => {
              setExceptionModalOpen(true);
              if (error.response) {
                console.log(error.response.data.message);
              }
            })
        : // create a rule
          api
            .post(`revshare-percentage-rules`, form)
            .finally(() => {
              props.closeDetailModal();
              queryClient.invalidateQueries({ queryKey: ['revSharePercentageRules'] });
            })
            .catch((error) => {
              if (error.response) {
                if (error.response.data.message === 'Newly-created Rule conflicts with an existing Rule') {
                  console.log(oldDefaultRule);
                  console.log(form);
                  if (
                    (oldDefaultRule?.default || oldDefaultRule?.default === 'TRUE') &&
                    (form?.default || form?.default === 'TRUE')
                  ) {
                    setExceptionReason('Existing Default Rule');
                  }
                  setExceptionModalOpen(true);
                }
              }
            });
    }

    form.startDate = originalStartDate;
    form.endDate = originalEndDate;
  };

  return (
    <div>
      <RevShareExceptionModal
        allAffiliates={props.allAffiliates}
        allDomains={domains}
        closeModal={() => {
          props.closeDetailModal();
          setExceptionModalOpen(false);
        }}
        exceptionModalOpen={exceptionModalOpen}
        exceptionReason={exceptionReason}
        newRule={newRule}
        oldRule={oldDefaultRule}
      />
      <Modal isOpen={props.isOpen}>
        <Form
          onSubmit={onSubmit}
          validate={(value) => {
            console.log('validate', value);
            const errors = {};
            if (!value.affiliateId) {
              errors.affiliateId = 'Required';
            }
            if (value.affiliateId) {
              if (
                !affIdsWithDefault.includes(value.affiliateId) &&
                props.allAffiliates.find((el) => el.networkAffiliateId === value.affiliateId) &&
                !value.default
              ) {
                errors.affiliateId = 'This needs a default rule';
              }
            }
            if (!value.revSharePercentage) {
              errors.revSharePercentage = 'Required';
            }
            if (!value.startDate) {
              errors.startDate = 'Required';
            }
            return errors;
          }}
          initialValues={props.rowSelection}
          render={({ handleSubmit, values }) => (
            <form onSubmit={handleSubmit}>
              <ModalBody>
                <Label>Affiliate ID: </Label>
                <Row>
                  <Col>
                    <Field
                      name='affiliateId'
                      component={RenderNumberPicker}
                      placeholder={'Affiliate ID'}
                      disabled={values.default}
                    />
                  </Col>
                  <Col>
                    <Input
                      disabled={true}
                      value={
                        props.allAffiliates.find((el) => el.networkAffiliateId === values.affiliateId)
                          ? props.allAffiliates?.find((el) => el.networkAffiliateId === values.affiliateId)?.name
                          : ''
                      }
                    />
                  </Col>
                </Row>

                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    paddingTop: 10,
                  }}
                >
                  <Label>Default: </Label>
                  <Field name='default' component={renderToggleSwitch} />
                  <OnChange name='default'>
                    {(value) => {
                      if (value && affIdsWithDefault.includes(values.affiliateId)) {
                        props.revSharePercentageRules.forEach((el) => {
                          if (el.affiliateId === values.affiliateId && el.default) {
                            delete el.affiliateName;
                            setOldDefaultRule(el);
                            setConfirmDefaultModalOpen(true);
                          }
                        });
                      } else if (
                        value &&
                        props.allAffiliates.find((el) => el.networkAffiliateId === values.affiliateId)
                      ) {
                        setShowDefaultRuleModal(true);
                      }
                    }}
                  </OnChange>
                </div>

                <Label>Revshare Percentage: </Label>
                <Field
                  name='revSharePercentage'
                  component={RenderNumberPicker}
                  placeholder='Revshare Percentage'
                  precision={1}
                  step={0.1}
                />
                <Label>Start Date: </Label>
                <Field name='startDate'>
                  {({ input, meta }) => {
                    console.log('input', input);
                    return (
                      <>
                        <DatePicker defaultValue={new Date(input.value)} onChange={input.onChange} includeTime />
                        {meta.error && meta.touched && <Alert color='danger'>{meta.error}</Alert>}
                      </>
                    );
                  }}
                </Field>
                <Label>End Date: </Label>
                <Field name='endDate'>
                  {({ input }) => (
                    <>
                      <DatePicker
                        value={input.value ? new Date(input.value) : null}
                        onChange={input.onChange}
                        disabled={values.default}
                        includeTime
                      />
                    </>
                  )}
                </Field>
                <Label>Domain: </Label>
                <Field
                  name='webDomain'
                  id='webDomain'
                  component={RenderDomainDropDown}
                  allDomains={domains}
                  disabled={values.default}
                />
                <Label>Sub ID: </Label>
                <Field name='subId'>
                  {({ input }) => (
                    <>
                      <Input
                        placeholder='Sub ID'
                        defaultValue={input.value}
                        onChange={input.onChange}
                        disabled={values.default}
                      />
                    </>
                  )}
                </Field>
                {values.affiliateId === 1006 && (
                  <>
                    <RenderInputField label='AIB List ID' fieldName='aibListId' default={values.default} />
                    <RenderInputField label='AIB Type ID' fieldName='aibTypeId' default={values.default} />
                  </>
                )}
                <Label>Landing Page Group ID: </Label>
                <Field
                  name='landingPageGroupId'
                  component={RenderNumberPicker}
                  placeholder='Landing Page Group ID'
                  disabled={values.default}
                />
              </ModalBody>
              <ModalFooter>
                <Button color='success' type='submit'>
                  Submit
                </Button>
                <Button
                  color='primary'
                  onClick={() => {
                    props.closeDetailModal();
                  }}
                >
                  Cancel
                </Button>
              </ModalFooter>
              <Modal isOpen={showDefaultRuleModal} className={'modal-info'}>
                <ModalHeader>Create a new Default Rule</ModalHeader>
                <ModalBody>A new default Rule needs to be created for this Affiliate ID</ModalBody>
                <ModalFooter>
                  <Button
                    onClick={() => {
                      setShowDefaultRuleModal(false);
                    }}
                    color='success'
                  >
                    Confirm
                  </Button>
                  <Button
                    onClick={() => {
                      values.default = false;
                      setShowDefaultRuleModal(false);
                    }}
                    color='primary'
                  >
                    Cancel
                  </Button>
                </ModalFooter>
              </Modal>
              <Modal isOpen={confirmDefaultModalOpen} className={'modal-danger'}>
                <ModalHeader>Edit a new Default Rule</ModalHeader>
                <ModalBody>Confirm you are making a change to the Default Rules</ModalBody>
                <ModalFooter>
                  <Button
                    onClick={() => {
                      setConfirmDefaultModalOpen(false);
                    }}
                    color='success'
                  >
                    Confirm
                  </Button>
                  <Button
                    onClick={() => {
                      values.default = false;
                      setConfirmDefaultModalOpen(false);
                    }}
                    color='primary'
                  >
                    Cancel
                  </Button>
                </ModalFooter>
              </Modal>
            </form>
          )}
        />
      </Modal>
    </div>
  );
};

export default RevSharePercentageDetails;
