import { formValueSelector } from 'redux-form';
import { RESET_MODAL } from '../../../actions/types';
import {
  CREATE_LANDING_PAGE,
  GET_ALL_LANDING_PAGES,
  GET_CONTAINER_FOR_PREVIEW,
  GET_LANDING_PAGE,
  GET_LAYOUT_FOR_PREVIEW,
  GET_SITE_FOR_PREVIEW,
  SET_NEW_LANDING_PAGE_NAME,
  UPDATE_LANDING_PAGE,
  UPDATE_LANDING_PAGE_ERROR,
} from './types';

const initialState = {
  selector: formValueSelector('landingPageDetails'),
  curLandingPage: {},
  landingPageAdvertiser: null,
  landingPages: [],
  allOfferActions: [],
  title: 'Landing Page',
  saveError: false,
  saveErrorMessage: null,
  isLandingPageSaved: false,
  newLandingPageName: '',
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_LANDING_PAGE:
      return {
        ...state,
        curLandingPage: action.payload,
        isLandingPageSaved: false,
        saveError: false,
      };
    case GET_ALL_LANDING_PAGES:
      return {
        ...state,
        landingPages: action.payload,
      };
    case UPDATE_LANDING_PAGE:
      return {
        ...state,
        curLandingPage: action.payload,
        isLandingPageSaved: true,
        saveError: false,
      };
    case CREATE_LANDING_PAGE:
      return {
        ...state,
        curLandingPage: action.payload,
        isLandingPageSaved: true,
        saveError: false,
      };
    case UPDATE_LANDING_PAGE_ERROR:
      return {
        ...state,
        saveError: true,
        saveErrorMessage: action.payload,
      };
    case SET_NEW_LANDING_PAGE_NAME:
      return {
        ...state,
        newLandingPageName: action.payload,
      };
    case GET_CONTAINER_FOR_PREVIEW:
      return {
        ...state,
        containerIDForPreview: action.payload,
      };
    case GET_LAYOUT_FOR_PREVIEW:
      return {
        ...state,
        layoutIDForPreview: action.payload,
      };
    case GET_SITE_FOR_PREVIEW:
      return {
        ...state,
        siteIDForPreview: action.payload,
      };
    case RESET_MODAL:
      return {
        ...state,
        saveError: false,
        saveErrorMessage: null,
        isLandingPageSaved: false,
        newLandingPageName: '',
      };
    default:
      return state;
  }
}
