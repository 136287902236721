import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import api from '../../services';

export const useSites = (id, enabled) =>
  useQuery({
    queryKey: ['sites'],
    queryFn: () => api.get(`sites/${id}`).then((res) => res.data),
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    enabled,
  });

export const useCreateSite = () => {
  const queryClient = useQueryClient();
  return useMutation((site) => api.post('sites', site), {
    onSuccess: () => {
      queryClient.invalidateQueries('sites');
    },
  });
};

export const useUpdateSite = () => {
  const queryClient = useQueryClient();
  return useMutation((site) => api.put(`sites/${site.id}`, site), {
    onSuccess: () => {
      queryClient.invalidateQueries('sites');
    },
  });
};

export const useDeleteSite = () => {
  const queryClient = useQueryClient();
  return useMutation((id) => api.delete(`sites/${id}`), {
    onSuccess: () => {
      queryClient.invalidateQueries('sites');
    },
  });
};
