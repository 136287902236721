import { Field } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { Button, Col, Row } from 'reactstrap';
import { Condition, MultiselectField, NumberField, SelectField } from '../../FinalForm';

export function PreviousRegistration({ domainsForPreviousRegReq, source, isLoading }) {
  const generateName = (field) => `${source}.${field}`;
  return (
    <Row
      style={{
        marginTop: '1rem',
      }}
    >
      <Col lg={2}>
        <h5>Previous Registrations Required</h5>
      </Col>
      <Col lg={4}>
        <SelectField
          name={generateName('previousRegistrationConfiguration.andOrLogic')}
          options={[
            {
              value: null,
              option: 'Select',
            },
            {
              value: 'AND',
              option: 'All entries must pass',
            },
            {
              value: 'OR',
              option: 'Any of these entries can pass',
            },
          ]}
        />
      </Col>

      <Col lg={8}>
        <FieldArray name={generateName('previousRegistrationConfiguration.previousRegistrations')}>
          {({ fields }) => {
            return fields.map((fieldName, index) => {
              return (
                <Row
                  key={index}
                  style={{
                    marginBottom: '1rem',
                  }}
                >
                  <Condition
                    key={index}
                    when={generateName('previousRegistrationConfiguration.andOrLogic')}
                    is={['AND', 'OR']}
                  >
                    {fields.length > 1 && (
                      <Col lg={1}>
                        <Button
                          color='danger'
                          className=''
                          type='button'
                          title='Remove Previous Registration'
                          onClick={() => fields.remove(index)}
                        >
                          <i className='fa fa-close' />
                        </Button>
                      </Col>
                    )}
                    <Col lg={2}>
                      <SelectField
                        name={`${fieldName}.includeExcludeAction`}
                        options={[
                          {
                            value: null,
                            option: 'None',
                          },
                          {
                            value: 'Include',
                            option: 'Include',
                          },
                          {
                            value: 'Exclude',
                            option: 'Exclude',
                          },
                        ]}
                      />
                    </Col>
                    <Col lg={4}>
                      <Field name={`${fieldName}.domains`}>
                        {({ input, meta }) => (
                          <>
                            <MultiselectField
                              {...input}
                              data={domainsForPreviousRegReq}
                              textField={(item) => (typeof item === 'string' ? item : `${item?.id}: ${item?.name}`)}
                              placeholder={'Select Domain'}
                              busy={isLoading}
                            />
                          </>
                        )}
                      </Field>
                    </Col>
                    <Col lg={2}>
                      <NumberField name={`${fieldName}.previousRegistrationsMin`} label='Min' noLabel />
                    </Col>
                    <Col lg={2}>
                      <NumberField name={`${fieldName}.previousRegistrationsMax`} label='Max' noLabel />
                    </Col>
                    {index === fields.length - 1 && (
                      <Col lg={{ size: 1, offset: 11 }}>
                        <Row
                          style={{
                            marginTop: '1rem',
                          }}
                        >
                          <Button
                            color='success'
                            onClick={() =>
                              fields.push({
                                domains: [],
                                includeExcludeAction: null,
                                previousRegistrationsMin: null,
                                previousRegistrationsMax: null,
                              })
                            }
                            className='previous-registration-add'
                            style={{ float: 'right', height: '3em', width: '3em' }}
                          >
                            <i className='fa fa-plus' />
                          </Button>
                        </Row>
                      </Col>
                    )}
                  </Condition>
                </Row>
              );
            });
          }}
        </FieldArray>
      </Col>
    </Row>
  );
}
