import React from 'react';
import { Field } from 'redux-form';
import { Col, Label, Row } from 'reactstrap';
import { useSelector } from 'react-redux';
import {
  renderActionSelect,
  renderComboBox,
  renderDropdownList,
  renderField,
  renderTrueOrFalse,
} from '../OfferTitle/renderConsts';
import { validateWholeNums } from '../OfferTitle/valildation';
import CurrentCapAmount from './CurrentCapAmount';

export default function UserAttribute({ handleTitleChange, isEditMode, userAttributes, offerCriteriaId }) {
  const selectedAttribute = useSelector((state) => state.form.offerActionDetail?.values?.attributeName);

  return (
    <div className='colorTest'>
      <br />
      <Row>
        <Col lg={3}>
          <Field
            required
            type='text'
            component={renderField}
            id='title'
            name='title'
            onChange={handleTitleChange}
            placeholder='Set Attribute Action Name'
            label='Set Attribute Action Name'
          />
        </Col>
        {!isEditMode && renderActionSelect(2)}
        <Col lg={3}>
          <Label>Attribute</Label>
          <Field
            type='select'
            name='attributeName'
            component={renderComboBox}
            data={userAttributes}
            textField='name'
            valueField='id'
          />
        </Col>
        <Col lg={2}>
          <Label>Attribute Value</Label>
          {selectedAttribute && selectedAttribute.valueStringTypeHint ? (
            <Field
              name='userAttributesValueString'
              component={renderDropdownList}
              data={selectedAttribute.userAttributesValueString}
              valueField='id'
              textField='valueString'
            />
          ) : (
            <Field
              name='attributeValue'
              component={renderTrueOrFalse}
              type='select'
              selectedAttribute={selectedAttribute}
            />
          )}
        </Col>
      </Row>
      <br />
      <Row>
        <Col lg={2}>
          <Label>Cap Interval</Label>
          <Field name='capInterval' type='select' component={renderComboBox} data={['None', 'Daily']} />
        </Col>
        <Col lg={2}>
          <Field
            name='capAmount'
            component={renderField}
            type='number'
            label='Cap Amount'
            normalize={validateWholeNums}
          />
        </Col>
        <Col lg={2}>
          <CurrentCapAmount id={offerCriteriaId} />
        </Col>
      </Row>
    </div>
  );
}
