import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Card, CardBody, CardHeader, Collapse, Nav, NavItem, NavLink, TabContent } from 'reactstrap';
import api from '../../services';
import { AttributePane } from './AttributePane';
import { DailyRestrictionPane } from './DailyRestrictionPane';
import { PrePingPane } from './PrePingPane';
import { UsersPane } from './UsersPane';
import { SummaryPane } from './SummaryPane/SummaryPane';

const Criteria = (props) => {
  const [state, setState] = useState({
    currentCapAmount: 0,
    capRead: false,
  });
  const [activeTab, setActiveTab] = useState('Summary');
  const [collapse, setCollapse] = useState(true);

  const NavTabs = ({ tab }) => (
    <NavItem>
      <NavLink active={activeTab === tab} onClick={() => setActiveTab(tab)}>
        {tab}
      </NavLink>
    </NavItem>
  );

  const offerId = useSelector((state) => state.offerState.id);
  const criteriaType = useSelector((state) => state.offerState.criteriaType);
  const limitAnswers = useSelector((state) => {
    let limitAnswers = state.offerState.selector ? state.offerState.selector(state, 'questionAnswers') : null;
    return limitAnswers
      ? limitAnswers
          .map((questionAnswer) => {
            if (questionAnswer && questionAnswer.text) {
              return questionAnswer.text;
            }
            return null;
          })
          .filter((answer) => answer !== null)
      : [];
  });
  const offerType = useSelector((state) => state.offerState && state.offerState.form);

  useEffect(() => {
    if (offerId && !state.capRead) {
      setState((prevState) => ({ ...prevState, capRead: true }));
      api.get(`offers/current-cap-amount/${offerId}`).then((response) => {
        setState((prevState) => ({
          ...prevState,
          currentCapAmount: response.data,
        }));
      });
    }
  }, [offerId, state.capRead]);

  return (
    <Card>
      <CardHeader onClick={() => setCollapse(!collapse)}>
        <h4>
          Criteria &nbsp;&nbsp;
          {collapse ? <i className='fa fa-chevron-up opened' /> : <i className='fa fa-chevron-down closed' />}
        </h4>
      </CardHeader>
      <CardBody>
        <Collapse isOpen={collapse}>
          <Nav tabs>
            <NavTabs tab='Summary' />
            <NavTabs tab='Users' />
            <NavTabs tab='Attribute' />
            <NavTabs tab='Pre-ping' />
            <NavTabs tab='Daily Restriction' />
          </Nav>
          <TabContent activeTab={activeTab}>
            <SummaryPane criteriaType={criteriaType} />
            <UsersPane />
            <AttributePane criteriaType={criteriaType} />
            <PrePingPane criteriaType={criteriaType} />
            <DailyRestrictionPane
              currentCapAmount={state.currentCapAmount}
              capAmount={props.capAmount}
              capSegmentConfiguration={props.capSegmentConfiguration}
              criteriaType={criteriaType}
              limitAnswers={limitAnswers}
              offerType={offerType}
            />
          </TabContent>
        </Collapse>
      </CardBody>
    </Card>
  );
};

export default Criteria;
