import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Label, Row, TabPane } from 'reactstrap';
import { change, Field } from 'redux-form';
import { getQuestionAnswers } from '../../actions';
import {
  renderComboBoxSecond,
  renderCriteriaOptions,
  renderMultiSelect,
} from '../../views/Offers/OfferTitle/renderConsts';
import DomainAffiliate from './DomainAffiliate';
import { useAffiliates, useDomains, useUserAttributes } from '../../views/OfferBlocks/queries';
import { useOfferQuestions } from '../../views/Offers/queries';
import CriteriaLandingPageIncludes from './CriteriaLandingPageIncludes';
import { UserAttribute } from './UserAttribute.tsx';

export const AttributePane = ({ criteriaType }) => {
  const dispatch = useDispatch();
  const [shouldQuestionUpdate, setShouldQuestionUpdate] = useState(true);
  const [loadingData, setLoadingData] = useState(true);
  const { data: userAttributes } = useUserAttributes(true, (data) => _.orderBy(data, ['name'], ['asc']));
  const { data: affiliates, isLoading: affiliatesLoading } = useAffiliates(true, (data) =>
    _.orderBy(data, ['name'], ['asc']).map(({ name, networkAffiliateId }) => ({
      id: networkAffiliateId,
      name,
    }))
  );
  const { data: domains, isLoading: domainsLoading } = useDomains(true, (data) =>
    data.map((domain) => ({ id: domain.id, name: domain.name })).sort((a, b) => (a > b ? 1 : -1))
  );
  const { data: criteriaQuestions } = useOfferQuestions();
  let affiliateNames = affiliatesLoading
    ? [{ name: '[None]', id: null }]
    : [{ name: '[None]', id: null }, ...affiliates];
  const { form, criteriaQuestion, criteriaQuestionAnswers, selectedQuestion } = useSelector((state) => ({
    form: state.offerState.form,
    criteriaQuestion: state.offerState.selector(state, 'criteriaQuestion'),
    criteriaQuestionAnswers: state.criteria.criteriaQuestionAnswers,
    selectedQuestion: state.offerState.selector(state, 'criteriaQuestion'),
  }));
  const criteriaAffiliate = useSelector((state) =>
    state.offerState.selector(state, 'criteriaIncludeOrExcludeAffNames')
  );
  const criteriaEmail = useSelector((state) =>
    state.offerState.selector(state, 'criteriaIncludeOrExcludeEmailDomains')
  );
  const userAttributeSelect = useSelector((state) =>
    state.formSelector.selector(state, 'criteriaIncludeOrExcludeUserAttributes')
  );

  useEffect(() => {
    if (shouldQuestionUpdate && criteriaQuestions?.length > 0) {
      const criteriaQuestionObj = _.find(criteriaQuestions, ({ question }) => question === criteriaQuestion);
      if (criteriaQuestionObj) {
        dispatch(getQuestionAnswers(criteriaQuestionObj.id));
        setShouldQuestionUpdate(false);
      }
      dispatch(change(form, 'criteriaQuestion', criteriaQuestion));
    }
  }, [shouldQuestionUpdate, dispatch, criteriaQuestions, criteriaQuestion, form]);

  useEffect(() => {
    domainsLoading || affiliatesLoading ? setLoadingData(true) : setLoadingData(false);
  }, [domainsLoading, affiliatesLoading]);

  return (
    <TabPane tabId='Attribute'>
      <br />
      <Row>
        <Col xs={'auto'}>
          <h5>User Attributes</h5>
        </Col>
        <Col xs={'auto'}>
          <Field name='criteriaIncludeOrExcludeUserAttributes' component={renderCriteriaOptions} type='select' />
        </Col>
        {(userAttributeSelect === 'Include' || userAttributeSelect === 'Exclude') && (
          <Col xs={'auto'} style={{ flexGrow: 1 }}>
            <Field name='userAttributeRules' component={UserAttribute} userAttributes={userAttributes} />
          </Col>
        )}
      </Row>
      <br />
      <Row>
        <Col lg={2}>
          <h5>Affiliate Names</h5>
        </Col>
        <Col lg={2}>
          <Field name='criteriaIncludeOrExcludeAffNames' component={renderCriteriaOptions} type='select' />
        </Col>
        {(criteriaAffiliate === 'Include' || criteriaAffiliate === 'Exclude') && (
          <Col lg='4'>
            <Field
              name='criteriaAffNames'
              component={renderMultiSelect}
              textField={(item) => `${item.id}: ${item.name}`}
              dataKey='name'
              data={affiliateNames}
              busy={affiliatesLoading}
            />
          </Col>
        )}
      </Row>
      <br />
      <Row>
        <Col lg={2}>
          <h5>Domain-specific Affiliates</h5>
        </Col>
        <Col lg={10}>
          <DomainAffiliate domains={domains} affiliates={affiliateNames} isLoading={loadingData} />
        </Col>
      </Row>
      <br />
      <CriteriaLandingPageIncludes />
      <Row>
        <Col lg={2}>
          <h5>Email Domains</h5>
        </Col>
        <Col lg={2}>
          <Field name='criteriaIncludeOrExcludeEmailDomains' component={renderCriteriaOptions} type='select' />
        </Col>
        {(criteriaEmail === 'Include' || criteriaEmail === 'Exclude') && (
          <Col lg='4'>
            <Field
              name='criteriaEmailDomains'
              component={renderMultiSelect}
              data={[
                'aim.com',
                'aol.com',
                'gmail.com',
                'google.com',
                'hotmail.com',
                'live.com',
                'outlook.com',
                'sbcglobal.net',
                'yahoo.com',
              ]}
            />
          </Col>
        )}
      </Row>
      {criteriaType === 'Offer Criteria' && (
        <>
          <Row>
            <Col lg={12}>
              <Label>Criteria Question</Label>
              <Field
                name='criteriaQuestion'
                component={renderComboBoxSecond}
                onSelect={({ id }) => dispatch(getQuestionAnswers(id))}
                data={criteriaQuestions}
                textField={(item) => (typeof item === 'string' ? item : `${item.name} - ${item.question}`)}
                valueField='question'
                placeholder='Select Question'
              />
            </Col>
          </Row>
          <br />
          {selectedQuestion && (
            <Row>
              <Col lg={12}>
                <Label>Criteria Question Answers</Label>
                <Field
                  name='criteriaQuestionAnswers'
                  component={renderMultiSelect}
                  type='select'
                  data={criteriaQuestionAnswers}
                />
              </Col>
            </Row>
          )}
        </>
      )}
    </TabPane>
  );
};
