import api from '../index';

/**
 * Returns a list of all sequence recommendations.
 * Optionally takes a page and size to handle paging.
 */
export const getSequenceRecommendations = async () => {
  const resp = await api.get('sequenceRecommendations');
  return resp.data;
};

export const getSequenceRecommendationById = async (id) => {
  const resp = await api.get(`sequenceRecommendations/${id}`);
  return resp.data;
};

export const getOffersSequenceControlProjectionById = async (id) => {
  const resp = await api.get(`offers-sequence/sequences/${id}/seqRecView`);
  return resp.data;
};

export const approveOffersSequenceRecommendations = async (id, name) => {
  const resp = await api.put(`sequenceRecommendations/${id}/approve?name=${name}`);
  return resp.data;
};

export const rejectOffersSequenceRecommendations = async (id) => {
  const resp = await api.put(`sequenceRecommendations/${id}/reject`);
  return resp.data;
};
