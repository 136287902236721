import React, { Component } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Label, Input } from 'reactstrap';
import CreateUserDropdown from '../Dropdowns/CreateUserDropdown';
import api from '../../../services';

class CreateUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.userId,
      modal: false,
      data: null,
      name: '',
      email: '',
      roleId: this.props.roleId,
      roleName: this.props.roleName,
      userId: null,
    };

    this.toggle = this.toggle.bind(this);
    this.saveOnEdit = this.saveOnEdit.bind(this);
    this.saveOnCreate = this.saveOnCreate.bind(this);
    this.changeRole = this.changeRole.bind(this);
    this.edit = this.edit.bind(this);
    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal,
      userId: null,
    });
  };

  // componentDidMount() {
  //   console.log('mounted');
  //   console.log(this.state);
  //   this.toggle();
  // }

  async fetchUser(userId) {
    await api
      .get(`zuulusers/${userId}`)
      .then((response) => {
        console.log(response);
        this.setState({
          id: response.data.id,
          data: response.data,
          name: response.data.realname,
          email: response.data.email,
          roleId: response.data.zuulUserRole.id,
          roleName: response.data.zuulUserRole.name,
        });
      })
      .catch((error) => console.log(error));
  }

  async componentWillReceiveProps(nextProps) {
    if (this.state.userId !== nextProps.userId && nextProps.isRowClicked) {
      await this.fetchUser(nextProps.userId);
      this.setState({
        userId: nextProps.userId,
      });
      await this.toggle();
    }
  }

  saveOnEdit = () => {
    if (this.state.id) {
      let saveData = this.state.data;
      saveData.realname = this.state.name;
      saveData.email = this.state.email;
      saveData.zuulUserRole = this.state.data.zuulUserRole;
      saveData.zuulUserRole.id = this.state.roleId;
      api.put(`zuulusers/${this.state.id}`, saveData).then((result) => {
        console.log(JSON.stringify(result.data));
        this.props.refreshCallBack(result.data);
      });
    }
    this.setState({
      modal: !this.state.modal,
    });
  };

  saveOnCreate = () => {
    if (this.state.name && this.state.email) {
      let saveData = {
        realname: this.state.name,
        email: this.state.email,
        online: true,
        zuulUserRole: {
          id: this.state.roleId,
        },
      };

      api.post('zuulusers', saveData).then((result) => {
        let jsonResult = result.data;
        console.log(JSON.stringify(jsonResult));
        this.setState({
          name: '',
          email: '',
        });
        this.props.refreshCallBack(jsonResult);
      });
    }
    this.setState({
      modal: !this.state.modal,
    });
  };

  create = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };

  edit = () => {
    api
      .get(`zuulusers/${this.props.userId}`)
      .then((response) => {
        console.log(response.data);
        this.setState({
          data: response.data,
          name: response.data.realname,
          email: response.data.email,
          roleId: response.data.zuulUserRole.id,
        });
      })
      .catch((error) => console.log(error));
    this.setState({
      modal: !this.state.modal,
    });
  };

  handleNameChange = (e) => {
    this.setState({
      name: e.target.value,
    });
  };

  handleEmailChange = (e) => {
    this.setState({
      email: e.target.value,
    });
  };

  changeRole = (roleValue) => {
    this.setState({
      roleId: roleValue,
    });
  };

  render() {
    let save, icon, title;
    if (!this.props.userId) {
      title = 'Create a New User';
      icon = (
        <Button color='primary' onClick={this.toggle} className='mr-1 to-right'>
          <i className='fa fa-plus'></i>&nbsp; Add Staff Member
        </Button>
      );
      save = (
        <Button color='success' onClick={this.saveOnCreate}>
          Save
        </Button>
      );
    } else {
      title = 'Edit User';
      icon = (
        <Button color='success' onClick={this.edit}>
          <i className='fa fa-edit ' /> &nbsp; Edit
        </Button>
      );
      save = (
        <Button color='success' onClick={this.saveOnEdit}>
          Save
        </Button>
      );
    }

    return (
      <span>
        {this.props.isUserCreate && (
          <Button color='primary' onClick={this.toggle} className='mr-1 to-right'>
            <i className='fa fa-plus'></i>&nbsp; Add User
          </Button>
        )}
        <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
          <ModalHeader toggle={this.toggle}>{title}</ModalHeader>
          <ModalBody>
            <h5>
              <i>Enter user details:</i>
            </h5>
            <div className='row'>
              <div className='col-12'>
                <Label>Name:</Label>
                <Input placeholder='Name' defaultValue={this.state.name} onChange={this.handleNameChange} />
              </div>
            </div>
            <br />
            <div className='row'>
              <div className='col-12'>
                <Label>Email:</Label>
                <Input placeholder='Email' defaultValue={this.state.email} onChange={this.handleEmailChange} />
              </div>
            </div>
            <br />

            <CreateUserDropdown
              callbackFromParent={this.changeRole}
              name={this.state.roleName}
              value={this.state.roleId}
            />
          </ModalBody>
          <ModalFooter>
            {save}
            <Button color='secondary' onClick={this.toggle}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </span>
    );
  }
}

export default CreateUser;
