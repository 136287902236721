export const GET_DISPATCH = 'GET_DISPATCH';

// Components
export const SAVE_AS_ENTITY_NAME = 'SAVE_AS_ENTITY_NAME';
export const RESET_MODAL = 'RESET_MODAL';
export const DELETE_ENTITY = 'DELETE_ENTITY';
export const DELETE_ENTITIES = 'DELETE_ENTITIES';
export const UPDATE_ENTITY_ERROR = 'UPDATE_ENTITY_ERROR';

// Offers
export const UPDATE_SELECTED_OFFERS = 'UPDATE_SELECTED_OFFERS';
export const UPDATE_ADDITIONAL_OFFERS = 'UPDATE_ADDITIONAL_OFFERS';
export const UPDATE_OFFER_STATE = 'UPDATE_OFFER_STATE';
export const SAVE_OFFER = 'SAVE_OFFER';
export const GET_OFFER_DATA = 'GET_OFFER_DATA';
export const GET_OFFERS_BY_ACTION_ID = 'GET_OFFERS_BY_ACTION_ID';

// Offer Actions
export const GET_OFFER_ACTION = 'GET_OFFER_ACTION';
export const GET_ALL_OFFER_ACTIONS = 'GET_ALL_OFFER_ACTIONS';
export const GET_OFFER_ACTIONS = 'GET_OFFER_ACTIONS';
export const GET_ENTITIES_BY_ACTION_ID = 'GET_ENTITIES_BY_ACTION_ID';
export const SET_OFFER_ACTION = 'SET_OFFER_ACTION';
export const SET_OFFER_ACTIONS = 'SET_OFFER_ACTIONS';
export const UPDATE_OFFER_ACTIONS = 'UPDATE_OFFER_ACTIONS';
export const GET_OFFER_ACTION_SUBTYPE = 'GET_OFFER_ACTION_SUBTYPE';
export const GET_OFFER_ACTION_POSTS = 'GET_OFFER_ACTION_POSTS';
export const GET_OFFER_ACTION_LINKOUTS = 'GET_OFFER_ACTION_LINKOUTS';
export const UPDATE_OFFER_ACTION_POSTS = 'UPDATE_OFFER_ACTION_POSTS';
export const UPDATE_OFFER_ACTION_LINKOUTS = 'UPDATE_OFFER_ACTION_LINKOUTS';
export const UPDATE_SELECTED_POSTS = 'UPDATE_SELECTED_POSTS';
export const UPDATE_SELECTED_SLOTS = 'UPDATE_SELECTED_SLOTS';
export const ADD_OFFER_ACTION = 'ADD_OFFER_ACTION';
export const EDIT_OFFER_ACTION = 'EDIT_OFFER_ACTION';

// Sites
export const GET_SITES_BY_OFFER_ID = 'GET_SITES_BY_OFFER_ID';

// Criteria
export const GET_OFFER_ACTION_CRITERIA = 'GET_OFFER_ACTION_CRITERIA';
export const ADD_OFFER_ACTION_CRITERIA = 'ADD_OFFER_ACTION_CRITERIA';
export const GET_USER_ATTRIBUTES = 'GET_USER_ATTRIBUTES';
export const UPDATE_CRITERIA_TYPE = 'UPDATE_CRITERIA_TYPE';
export const GET_CRITERIA_QUESTIONS = 'GET_CRITERIA_QUESTIONS';
export const SAVE_INIT_DATA = 'SAVE_INIT_DATA';
export const GET_DOMAINS = 'GET_DOMAINS';
export const GET_AFFILIATES = 'GET_AFFILIATES';

// Form Selector
export const UPDATE_SELECTOR = 'UPDATE_SELECTOR';

// Metadata
export const UPDATE_ADVERTISER = 'UPDATE_ADVERTISER';
export const UPDATE_ADVERTISER_FORM = 'UPDATE_ADVERTISER_FORM';
export const UPDATE_ADVERTISER_STATUS = 'UPDATE_ADVERTISER_STATUS';
export const UPDATE_TAG_FORM = 'UPDATE_TAG_FORM';
export const UPDATE_TAG_STATUS = 'UPDATE_TAG_STATUS';
export const UPDATE_TAGS = 'UPDATE_TAGS';
export const FETCH_ALL_ENTITIES = 'FETCH_ALL_ENTITIES';
export const FETCH_ALL_ACTIONS_TABLE = 'FETCH_ALL_ACTIONS_TABLE';

// Offer Triggers
export const GET_OFFER_TRIGGERS_BY_OFFER_ID = 'GET_OFFER_TRIGGERS_BY_OFFER_ID';
export const GET_OFFER_ACTION_TRIGGER_BY_ID = 'GET_OFFER_ACTION_TRIGGER_BY_ID';
export const SAVE_AS_OFFER_NAME = 'SAVE_AS_OFFER_NAME';
export const ADD_OFFER_TRIGGER = 'ADD_OFFER_TRIGGER';
export const UPDATE_OFFER_TRIGGERS = 'UPDATE_OFFER_TRIGGERS';
export const DELETE_OFFER_TRIGGERS = 'DELETE_OFFER_TRIGGERS';
export const REMOVE_OFFER_TRIGGER_STATE = 'REMOVE_OFFER_TRIGGER_STATE';
export const ADD_OFFER_ACTION_TRIGGER = 'ADD_OFFER_ACTION_TRIGGER';

// Question Answers
export const ADD_QUESTION_ANSWER = 'ADD_QUESTION_ANSWER';
export const UPDATE_QUESTION_ANSWERS = 'UPDATE_QUESTION_ANSWERS';
export const DELETE_QUESTION_ANSWERS = 'DELETE_QUESTION_ANSWERS';
export const GET_QUESTION_ANSWERS = 'GET_QUESTION_ANSWERS';

// Wall Offers
export const GET_OFFER_WALL_OFFER = 'GET_OFFER_WALL_OFFER';
export const GET_WALL_OFFERS = 'GET_WALL_OFFERS';
export const SWAP_OFFER_WALL_SLOTS = 'SWAP_OFFER_WALL_SLOTS';
export const UPDATE_WALL_OFFERS = 'UPDATE_WALL_OFFERS';

// Marketing Partners Offer Actions
export const GET_MARKETING_PARTNERS_OFFER_ACTIONS = 'GET_MARKETING_PARTNERS_OFFER_ACTIONS';
export const UPDATE_MARKETING_PARTNERS_OFFER_ACTIONS = 'UPDATE_MARKETING_PARTNERS_OFFER_ACTIONS';
