import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Col, Input, Label, Row } from 'reactstrap';
import { Field } from 'redux-form';
import { useOfferBlockByOfferId } from '../../../OfferBlocks/queries';
import { renderComboBox } from '../../../Offers/OfferTitle/renderConsts';

const filterContainersByOfferType = (containers, offerType) => {
  return containers.filter((container) => offerType && offerType.id === container.offerTypeHint);
};
const OfferProperties = ({ selectedElement, containers, layouts }) => {
  const { data: offerBlock } = useOfferBlockByOfferId(!!selectedElement?.offer?.id, selectedElement?.offer?.id);
  const { id, offerType, name, offer, offersSequence } = selectedElement ?? {};
  if (!id) return null;
  const filteredContainers = filterContainersByOfferType(containers, offerType);
  if (offerType?.name === 'Offer Block') {
    return (
      <>
        <Row>
          <Col xs={9}>
            <h3>{name}</h3>
            <h6>{`Offer Block ID: ${offerBlock?.id}`}</h6>
          </Col>
          <Col xs={2}>
            <Link to={`/campaigns/offerblocks/${offerBlock?.id}`} target='_blank'>
              <Button color='info' type='button' title='Visit Offer Details'>
                <i className='fa fa-search' />
              </Button>
            </Link>
          </Col>
        </Row>
        {offerBlock?.offerBlockOffers?.map((offerBlockOffer) => {
          const { offer } = offerBlockOffer;
          return (
            <Link
              to={`/campaigns/offertable/offer/${offer?.id}`}
              target='_blank'
              style={{ color: 'white' }}
              key={offer?.id}
            >
              <Row
                style={{
                  border: '1px solid #23282c',
                  borderRadius: '5px',
                  padding: '1rem',
                  margin: '.75rem .25rem .75rem .25rem',
                }}
              >
                <Col>
                  <Row>
                    <h5>{`${offer?.id} : ${offer?.name}`}</h5>
                  </Row>
                  <Row style={{ paddingTop: '.25rem' }}>
                    <Col>
                      <Row>
                        <h6>Offer Type</h6>
                      </Row>
                      <Row style={{ paddingRight: '.25rem' }}>
                        <Input type='text' value={offer?.offerType?.name} disabled />
                      </Row>
                    </Col>
                    <Col>
                      <Row>
                        <h6>Advertiser</h6>
                      </Row>
                      <Row>
                        <Input type='text' value={offer?.advertiser?.name} disabled />
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Link>
          );
        })}
      </>
    );
  }
  return (
    <>
      <Row>
        <Col lg={9} md={12}>
          <h3>{name}</h3>
        </Col>
        <Col lg={2} md={12}>
          <Link to={`/campaigns/offertable/offer/${offer?.id}`} target='_blank'>
            <Button color='info' type='button' title='Visit Offer Details'>
              <i className='fa fa-search' />
            </Button>
          </Link>
        </Col>
      </Row>
      <br />
      <Row>
        <Col lg={6}>
          <h6>
            <em>Offer ID: </em>
            {offer?.id}
          </h6>
        </Col>
        <Col lg={6}>
          <h6>
            <em>Offer Sequence ID: </em>
            {offersSequence?.id ? offersSequence.id : `This OS hasn't been created yet`}
          </h6>
        </Col>
      </Row>
      <br />
      <Row>
        <Col lg={6}>
          <Label>Offer Type</Label>
          <div style={{ border: '1px solid #23282c', padding: '0.375rem 0.75rem', borderRadius: '0.25rem' }}>
            {offerType?.name}
          </div>
        </Col>
        <Col lg={6}>
          <Label>Advertiser</Label>
          <div style={{ border: '1px solid #23282c', padding: '0.375rem 0.75rem', borderRadius: '0.25rem' }}>
            {offer?.advertiser?.name ? offer.advertiser.name : 'N/A'}
          </div>
        </Col>
      </Row>
      <br />
      <>
        <Row>
          <Col lg={12}>
            <Label>Layout</Label>
            <Field
              name={`${id}.layout`}
              component={renderComboBox}
              data={layouts}
              disabled={!id}
              valueField='id'
              textField='name'
              link='/content/layouts'
            />
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <Label>Container</Label>
            <Field
              name={`${id}.container`}
              component={renderComboBox}
              data={filteredContainers}
              disabled={!id}
              valueField='id'
              textField='name'
              link='/content/containers'
            />
          </Col>
        </Row>
      </>
    </>
  );
};

export default OfferProperties;
