export function handleInitializeActions(entityTriggers) {
  let entityActionsByTriggers = [];
  entityTriggers.forEach((entityTrigger) => {
    if (entityTrigger.triggerType.name !== 'ON_ANSWER') {
      // If actions present,
      if (entityTrigger.offerActionTriggers) {
        let offerActionsForTrigger = [];
        // Create trigger and actions objects
        entityTrigger.offerActionTriggers.forEach(
          (offerActionTrigger) => {
            if (typeof offerActionTrigger === 'object') {
              if(offerActionTrigger.offerAction) {
                offerActionsForTrigger.push({
                  name: offerActionTrigger.offerAction.title,
                  offerActionType: offerActionTrigger.offerAction.offerActionType,
                  action_advertiser: getActionAdvertiser(offerActionTrigger.offerAction),
                  action_id: offerActionTrigger.offerAction.id,
                  actionStatus: offerActionTrigger.offerAction.active || '',
                  shouldShowCriteria: offerActionTrigger.showOfferByOfferActionCriteria,
                });
              }
            }
          }
        );

        if (offerActionsForTrigger.length > 0) {
          entityActionsByTriggers.push({
            actions: offerActionsForTrigger,
            triggerType:
              entityTrigger.triggerType &&
              entityTrigger.triggerType.name,
            actionCriteriaAndOr: entityTrigger.showOfferAndOrLogic,
            paramName: entityTrigger.paramName,
            paramValue: entityTrigger.paramValue
          });
        }
      }
    }
  });

  return entityActionsByTriggers;
};

export function getActionAdvertiser(offerAction) {
  let advertiserName = '';
  if (offerAction.offerActionLinkout) {
    advertiserName =
      offerAction.offerActionLinkout.advertiser &&
      offerAction.offerActionLinkout.advertiser.name;
  } else if (offerAction.offerActionPost) {
    advertiserName =
      offerAction.offerActionPost.advertiser &&
      offerAction.offerActionPost.advertiser.name;
  }
  return advertiserName;
};
