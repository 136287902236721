import React, { useMemo } from 'react';
import { Col, Input, Label, Row } from 'reactstrap';
import { MultiselectSummary, SwitchRow } from './SummaryPaneComponents';
import { usePrepingSelectors } from '../hooks';
import { useOfferSummaries } from '../../../views/Offers/queries';
import { useOfferActionsTable } from '../../../views/OfferActions/queries';

const PrepingSummary = () => {
  const prepingSelectors = usePrepingSelectors();
  const { data: offers, isLoading: offersLoading } = useOfferSummaries();
  const { data: offerActions, isLoading: offerActionsLoading } = useOfferActionsTable();

  const [prePingOffers] = useMemo(() => {
    if (prepingSelectors?.suppressionRulesAdditionalOfferIds) {
      return [offers?.filter(({ id }) => prepingSelectors.suppressionRulesAdditionalOfferIds.includes(id))];
    }
    return [[]];
  }, [offers, prepingSelectors?.suppressionRulesAdditionalOfferIds]);

  const [prePingOfferActions] = useMemo(() => {
    if (prepingSelectors?.suppressionRulesAdditionalOfferActionIds) {
      return [offerActions?.filter(({ id }) => prepingSelectors.suppressionRulesAdditionalOfferActionIds.includes(id))];
    }
    return [[]];
  }, [offerActions, prepingSelectors?.suppressionRulesAdditionalOfferActionIds]);

  return (
    <>
      {prepingSelectors.isAnySelectorNotNullOrEmpty && (
        <>
          <h4>Pre-ping Summary</h4>
          <Row>
            <Col className='ppLeft'>
              <Row>
                <Col>
                  <h5>Internal Pre-Ping</h5>
                </Col>
              </Row>
              <hr />
              {prepingSelectors.suppressionRulesDurationType && (
                <Row>
                  <Col>
                    <h6>
                      <em>Suppression Duration</em>
                    </h6>
                  </Col>
                </Row>
              )}
              <Row>
                {prepingSelectors.suppressionRulesDurationType && (
                  <>
                    <Col>
                      <Label>Type</Label>
                      <Input value={prepingSelectors.suppressionRulesDurationType} readOnly disabled />
                    </Col>
                  </>
                )}
                {prepingSelectors.suppressionRulesDurationAmount && (
                  <Col>
                    <Label>Amount</Label>
                    <Input value={prepingSelectors.suppressionRulesDurationAmount} readOnly disabled />
                  </Col>
                )}
                {prepingSelectors.suppressionRulesSuppressionType && (
                  <Col>
                    <Label>Level</Label>
                    <Input value={prepingSelectors.suppressionRulesSuppressionType} readOnly disabled />
                  </Col>
                )}
                {prepingSelectors.suppressionRuleAnswer && (
                  <Col>
                    <Label>Answer</Label>
                    <Input value={prepingSelectors.suppressionRuleAnswer} readOnly disabled />
                  </Col>
                )}
                {prepingSelectors.suppressionRulesDurationMaxLimit && (
                  <Col>
                    <Label>Max Limit</Label>
                    <Input value={prepingSelectors.suppressionRulesDurationMaxLimit} readOnly disabled />
                  </Col>
                )}
              </Row>
              {prepingSelectors.suppressionRulesAdditionalOfferIds &&
                prepingSelectors.suppressionRulesAdditionalOfferIds.length > 0 && (
                  <Row className='row-margin-top'>
                    <Col>
                      <Label>Additional Offers</Label>
                      <MultiselectSummary value={prePingOffers} busy={offersLoading} />
                    </Col>
                  </Row>
                )}
              {prepingSelectors.suppressionRulesAdditionalOfferActionIds &&
                prepingSelectors.suppressionRulesAdditionalOfferActionIds.length > 0 && (
                  <Row className='row-margin-top'>
                    <Col>
                      <Label>Additional Offer Actions</Label>
                      <MultiselectSummary value={prePingOfferActions} textField='title' busy={offerActionsLoading} />
                    </Col>
                  </Row>
                )}
            </Col>
            <Col>
              <Row>
                <Col>
                  <h5>External Pre-Ping</h5>
                </Col>
              </Row>
              <hr />
              <SwitchRow label='Pre-Ping Result Stored' value={prepingSelectors.prePingResultSessionCapture} />
              <Row>
                {prepingSelectors.prePingMethod && (
                  <Col>
                    <Label>Pre Ping Method</Label>
                    <Input value={prepingSelectors.prePingMethod} readOnly disabled />
                  </Col>
                )}
                {prepingSelectors.prePingHandlerId?.name && (
                  <Col>
                    <Label>Handler</Label>
                    <Input value={prepingSelectors.prePingHandlerId?.name} readOnly disabled />
                  </Col>
                )}
              </Row>
              {prepingSelectors.prePing && (
                <Row className='row-margin-top'>
                  <Col xs={12}>
                    <Label>URL</Label>
                    <Input value={prepingSelectors.prePing} readOnly disabled />
                  </Col>
                </Row>
              )}
              {prepingSelectors.prePingBody && (
                <Row className='row-margin-top'>
                  <Col xs={12}>
                    <Label>Body</Label>
                    <Input value={prepingSelectors.prePingBody} readOnly disabled type='textarea' />
                  </Col>
                </Row>
              )}
              {prepingSelectors.prePingTimeout && (
                <Row className='row-margin-top'>
                  <Col>
                    <Label>Timeout</Label>
                    <Input value={prepingSelectors.prePingTimeout} readOnly disabled />
                  </Col>
                </Row>
              )}
              <Row className='row-margin-top'>
                {prepingSelectors.prePingHeader1 && (
                  <Col>
                    <Label>Header 1</Label>
                    <Input value={prepingSelectors.prePingHeader1} readOnly disabled />
                  </Col>
                )}
                {prepingSelectors.prePingHeader2 && (
                  <Col>
                    <Label>Header 2</Label>
                    <Input value={prepingSelectors.prePingHeader2} readOnly disabled />
                  </Col>
                )}
                {prepingSelectors.prePingHeader3 && (
                  <Col>
                    <Label>Header 3</Label>
                    <Input value={prepingSelectors.prePingHeader3} readOnly disabled />
                  </Col>
                )}
              </Row>
              {prepingSelectors.prePingCustomHttpStatus && (
                <Row className='row-margin-top'>
                  <Col xs={12}>
                    <Label>Other HTTP Status</Label>
                    <Input value={prepingSelectors.prePingCustomHttpStatus} readOnly disabled />
                  </Col>
                </Row>
              )}
            </Col>
          </Row>
        </>
      )}
    </>
  );
};
export default PrepingSummary;
