export const parseAdvPPFormValues = (advertiserPrePing) => {
  const initialValues = {
    name: advertiserPrePing.name,
    emailAddressHashed: advertiserPrePing.emailAddressHashed,
    url: advertiserPrePing.url,
    method: advertiserPrePing.method,
    body: advertiserPrePing.body,
    header1: advertiserPrePing.header1,
    header2: advertiserPrePing.header2,
    header3: advertiserPrePing.header3,
    customHttpStatus: advertiserPrePing.customHttpStatus,
    handler: advertiserPrePing.handler,
    otherHttpHandler: advertiserPrePing.otherHttpHandler,
    updatedAt: advertiserPrePing.updatedAt,
    createdAt: advertiserPrePing.createdAt,
    advertiser: advertiserPrePing.advertiser,
    active: advertiserPrePing.active,
  };
  if (advertiserPrePing.id) initialValues.id = advertiserPrePing.id;
  return initialValues;
};
