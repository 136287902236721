import React, { useEffect, useState } from 'react';
import { Card, CardBody, CardHeader, Collapse } from 'reactstrap';
import CustomVariablesForm from './CustomVariablesForm';

export default function CustomVariablesCard({
  fields,
  customVarFlag,
  meta: { touched, error },
  showVerify,
  formValues,
}) {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (fields.length > 0) {
      setIsOpen(true);
    }
  }, [fields]);

  const toggle = () => setIsOpen(!isOpen);

  return (
    <Card>
      <CardHeader onClick={toggle}>
        <h4>
          Layout/Container Variables
          <i className={`fa ${isOpen ? 'fa-chevron-up opened' : 'fa-chevron-down closed'}`} />
        </h4>
      </CardHeader>
      <Collapse isOpen={isOpen}>
        <CardBody>
          <CustomVariablesForm
            fields={fields}
            customVarFlag={customVarFlag}
            meta={{ touched, error }}
            showVerify={showVerify}
            formValues={formValues}
          />
        </CardBody>
      </Collapse>
    </Card>
  );
}
