import { processCustomVariables } from '../../../components/CustomVariables/utils';

/**
 * Makes api version of landing page actions from the form actions. Landing page actions are Offer Triggers
 * @param formActions
 * @returns {*}
 */
export const transformFormActionsToLandingPageActions = (formActions = []) => {
  const createOfferAction = ({ action_id: actionId, offerActionType, shouldShowCriteria }) => ({
    actionId,
    offerActionType,
    showOfferByCriteria: shouldShowCriteria,
  });

  const createTrigger = ({
    triggerType = 'ON_IMPRESSION',
    actions,
    paramName,
    paramValue,
    actionCriteriaAndOr = 'AND',
  }) => ({
    triggerType,
    showOfferLogic: actionCriteriaAndOr,
    actions: actions.map(createOfferAction),
    paramName,
    paramValue,
  });

  return formActions.map(createTrigger);
};

/**
 * Creates a new offer object based on an old offer object and additional parameters.
 *
 * @param {object} oldOffer - The old offer object.
 * @param {string} id - The ID of the new offer.
 * @param {string} name - The name of the new offer.
 * @param {string} advertiser - The advertiser of the new offer.
 * @param {boolean} isActive - The active status of the new offer.
 * @param {object} formValues - Additional form values for the new offer.
 * @returns {object} - The new offer object.
 */
export const createOfferFromOldOffer = (oldOffer, id, name, advertiser, isActive, formValues) => {
  const {
    verifyWithBriteverify = false,
    scrubIsActive = false,
    triggersRegistration = false,
    customVariables = [],
    offer,
  } = formValues;

  const doNotResize = offer?.shouldResizeImage ? false : true;
  let image = null;

  // Check if 'values.image1' exists and is not null.
  if (offer?.image1) {
    if (Array.isArray(offer.image1) && offer.image1.length > 0) {
      // If 'offer.image1' is a non-empty array, clone the first element and add 'doNotResize'.
      image = { ...offer.image1[0], doNotResize };
    } else if (!Array.isArray(offer.image1)) {
      // If 'offer.image1' is not an array, clone it and add 'doNotResize'.
      image = { ...offer.image1, doNotResize };
    }
  }

  return {
    ...oldOffer,
    id,
    name,
    advertiser,
    isActive,
    formType: 'Registration',
    status: 1,
    verifyWithBriteverify,
    scrubIsActive,
    triggersRegistration,
    customVariables:
      customVariables && customVariables.length > 0 ? JSON.stringify(processCustomVariables(customVariables)) : null,
    image1: image,
  };
};
