import {
  defaultColumnFormatter,
  dateColumnFormatter,
  sortCaretFn,
  revDiffColumnFormatter,
  columnFormatter
} from '../../utils/tableFunctions';

const seqRecoDetailUrl = '/offerreordering/details/';
const revDiffPath = 'additionalFields.revPerSessionDifference';

const getColorClassName = value => {
  if (value === 'REJECTED') {
    return `negative-value`;
  }

  if (value === 'APPROVED') {
    return `positive-value`;
  }

  return undefined;
};

const statusColumnFormatter = url => (cell, row) => {
  return columnFormatter({
    cell,
    row,
    computedURL: url + row.id,
    colorClassName: getColorClassName(cell)
  });
};

export default [
  {
    dataField: 'id',
    text: 'ID',
    formatter: defaultColumnFormatter(seqRecoDetailUrl),
    sort: true,
    sortCaret: sortCaretFn
  },
  {
    dataField: 'sequence.id',
    text: 'Sequence ID',
    formatter: defaultColumnFormatter(seqRecoDetailUrl),
    sort: true,
    sortCaret: sortCaretFn
  },
  {
    dataField: 'sequence.name',
    text: 'Sequence Name',
    formatter: defaultColumnFormatter(seqRecoDetailUrl),
    sort: true,
    sortCaret: sortCaretFn
  },
  {
    dataField: 'sequence.site.id',
    text: 'Site ID',
    formatter: defaultColumnFormatter(seqRecoDetailUrl),
    sort: true,
    sortCaret: sortCaretFn
  },
  {
    dataField: 'sequence.site.domain',
    text: 'Site Name',
    formatter: defaultColumnFormatter(seqRecoDetailUrl),
    sort: true,
    sortCaret: sortCaretFn
  },
  {
    dataField: 'status',
    text: 'Status',
    formatter: statusColumnFormatter(seqRecoDetailUrl),
    sort: true,
    sortCaret: sortCaretFn
  },
  {
    dataField: 'createdAt',
    text: 'Created At',
    formatter: dateColumnFormatter(seqRecoDetailUrl),
    sort: true,
    sortCaret: sortCaretFn
  },
  {
    dataField: revDiffPath,
    text: 'Rev Per Session Difference',
    formatter: revDiffColumnFormatter(seqRecoDetailUrl),
    sort: true,
    sortCaret: sortCaretFn
  }
];

export { revDiffPath };
