import { GET_ALL_ADVERTISERS } from './types';
import { find } from 'lodash';
const initialState = {
  advertisers: [],
  whatIfAdvertiser: null,
};
export default function (state = initialState, action) {
  switch (action.type) {
    case GET_ALL_ADVERTISERS:
      const whatIfAdvertiser = find(action.payload, (advertiser) => {
        return advertiser.name === 'What If';
      });
      return { ...state, advertisers: action.payload, whatIfAdvertiser };
    default:
      return state;
  }
}
