import React from 'react';
import { Field } from 'react-final-form';
import { Button, Col, Row, Label, Card, CardBody, CardHeader } from 'reactstrap';
import { MultiselectField, SelectField } from '../../FinalForm';
import { FieldArray } from 'react-final-form-arrays';

export function DomainAffiliate({ domains, affiliateNames, isLoading }) {
  return (
    <Row
      style={{
        marginTop: '1rem',
      }}
    >
      <Col lg={3}>
        <h5>Domain-specific Affiliates</h5>
      </Col>
      <Col lg={10}>
        <FieldArray name='offer.domainAffiliateConfiguration.domainAffiliateEntries'>
          {({ fields }) => {
            return (
              <>
                {fields.map((name, index) => (
                  <div key={name}>
                    <Card>
                      <CardHeader>
                        <Button
                          color='danger'
                          className=''
                          type='button'
                          title='Remove Domain-specific Affiliates'
                          onClick={() => fields.remove(index)}
                        >
                          <i className='fa fa-close' />
                        </Button>
                      </CardHeader>
                      <CardBody>
                        <Row>
                          <Col>
                            <Label>Domains</Label>
                            <Field name={`${name}.domains`}>
                              {({ input, meta }) => (
                                <>
                                  <MultiselectField
                                    {...input}
                                    data={domains}
                                    textField={(item) => `${item.id}: ${item.name}`}
                                    dataKey='name'
                                    placeholder={'Select Domain'}
                                    busy={isLoading}
                                  />
                                </>
                              )}
                            </Field>
                          </Col>
                        </Row>
                        {fields.value[index].domains?.length !== 0 && (
                          <Row style={{ paddingTop: '1rem' }}>
                            <Col lg={3}>
                              <Label>Include/Exclude</Label>
                              <SelectField
                                name={`${name}.includeExcludeAction`}
                                options={[
                                  {
                                    value: null,
                                    option: 'None',
                                  },
                                  {
                                    value: 'include',
                                    option: 'Include',
                                  },
                                  {
                                    value: 'exclude',
                                    option: 'Exclude',
                                  },
                                ]}
                              />
                            </Col>
                            <Col>
                              <Label>Affiliate</Label>
                              <Field name={`${name}.affiliates`}>
                                {({ input, meta }) => (
                                  <>
                                    <MultiselectField
                                      {...input}
                                      data={affiliateNames}
                                      textField={(item) => `${item.id}: ${item.name}`}
                                      dataKey='name'
                                      placeholder={'Select affiliate'}
                                      busy={isLoading}
                                    />
                                  </>
                                )}
                              </Field>
                            </Col>
                          </Row>
                        )}
                      </CardBody>
                    </Card>
                  </div>
                ))}
                <Row>
                  <Col lg={{ size: 2 }}>
                    <Button
                      color='success'
                      onClick={() => fields.push({ domains: [], affiliates: [], includeExcludeAction: null })}
                      className='domain-affiliate-add'
                      style={{ float: 'left', height: '3em', width: '3em' }}
                    >
                      <i className='fa fa-plus' />
                    </Button>
                  </Col>
                </Row>
              </>
            );
          }}
        </FieldArray>
      </Col>
    </Row>
  );
}
