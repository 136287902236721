import React, { useEffect } from 'react';
import { Alert, Card, CardBody, CardHeader, Col, Input, Label, Row } from 'reactstrap';
import { useHistory, useParams } from 'react-router-dom';
import { useCreateSite, useDeleteSite, useSites, useUpdateSite } from '../queries';
import { useLandingPageGroups, useUpdateLandingPageGroup } from '../../LandingPageGroups/queries';
import { useLayoutsTable } from '../../OfferBlocks/queries';
import DropdownList from 'react-widgets/DropdownList';
import { Field, Form } from 'react-final-form';
import SaveModalButton from '../Modals/SaveModalButton';
import DeleteModalButton from '../Modals/DeleteModalButton';
import { TextAreaField } from '../../../components/FinalForm';
import CollapsibleCard from '../Modals/CollapsibleCard';
import toast from 'react-hot-toast';
import NumberPicker from 'react-widgets/NumberPicker';
import { ZuulToaster } from '../../../components/Toaster/ZuulToaster';

const SiteDetail = () => {
  const { siteId } = useParams();
  const { data: site, isLoading: sitesLoading, isError: sitesError } = useSites(siteId, siteId !== 'create');
  const { data: layouts, isLoading: layoutsLoading, isError: layoutsError } = useLayoutsTable();
  const {
    data: landingPageGroups,
    isLoading: landingPageGroupsLoading,
    isError: landingPageGroupsError,
  } = useLandingPageGroups();
  const { mutate: createSite, isSuccess: createSuccess } = useCreateSite();
  const { mutate: updateSite, isSuccess: updateSuccess } = useUpdateSite();
  const { mutate: deleteSite } = useDeleteSite();
  const { mutate: updateLandingPageGroup } = useUpdateLandingPageGroup();
  const history = useHistory();

  useEffect(() => {
    if (createSuccess || updateSuccess) {
      toast.success('Site Saved!');
      history.push('/campaigns/sites');
    }
  }, [createSuccess, updateSuccess]);

  if (layoutsLoading || landingPageGroupsLoading) {
    return <div>Loading...</div>;
  }

  if (sitesError || layoutsError || landingPageGroupsError) {
    return <div>Error...</div>;
  }

  const currentDefaultLPG = landingPageGroups.find(
    (landingPageGroup) => landingPageGroup.site?.id === parseInt(siteId) && landingPageGroup.isDefault
  ) || { id: 0, name: 'Select Default Landing Page Group', isDefault: false };

  const handleDelete = async () => {
    await deleteSite({ id: parseInt(siteId) });
    history.push('/campaigns/sites');
  };

  const updateDefaultLPG = async (newDefault, oldDefault) => {
    if (newDefault?.id !== 0) {
      await updateLandingPageGroup({ ...newDefault, isDefault: true });
    }

    if (oldDefault?.id !== 0) {
      await updateLandingPageGroup({ ...oldDefault, isDefault: false });
    }
  };

  const onSubmit = async (values) => {
    const clonedValues = { ...values };

    if (clonedValues.defaultLPG !== currentDefaultLPG) {
      await updateDefaultLPG(clonedValues.defaultLPG, currentDefaultLPG);
    } else if (clonedValues?.defaultLPG?.id === 0 && currentDefaultLPG?.id !== 0) {
      await updateDefaultLPG(null, currentDefaultLPG);
    }

    delete clonedValues.defaultLPG;
    // Handle Site Creation or Update
    if (siteId === 'create') {
      await createSite({ ...clonedValues, status: 1 });
    } else {
      await updateSite({ ...clonedValues, id: parseInt(siteId), status: 1 });
    }
  };

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={siteId === 'create' ? { hasScrubOffer: false } : { ...site, defaultLPG: currentDefaultLPG }}
      validate={(values) => {
        const errors = {};
        console.log('validate', values);
        return errors;
      }}
      render={({ handleSubmit, pristine, form, submitting, values }) => {
        return (
          <form id='siteForm' onSubmit={handleSubmit}>
            <Card>
              <CardHeader>{siteId === 'create' ? 'Add Site' : 'Edit Site'}</CardHeader>
              <CardBody>
                <Row>
                  <Col xs='3'>
                    <strong>Domain:</strong>
                  </Col>
                  <Col xs='9'>
                    <Field name='domain'>
                      {({ input, meta }) => <Input {...input} placeholder='* Required Field *' />}
                    </Field>
                  </Col>
                </Row>
                <TextAreaField name='description' label='Description' />
                <TextAreaField name='returnUrl' label='Return URL' />
                <TextAreaField name='gtmContainerId' label='GTM Container ID' />
                <Row>
                  <Col md='3'>
                    <strong>Layout:</strong>
                  </Col>
                  <Col md={'9'}>
                    <Field name='layouts'>
                      {({ input, meta }) => (
                        <DropdownList
                          {...input}
                          data={layouts}
                          value={input ? input.value.name : ''}
                          dataKey={'id'}
                          textField={'name'}
                          minLength={3}
                          filter={'contains'}
                        />
                      )}
                    </Field>
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col md='3'>
                    <strong>Default Landing Page Group:</strong>
                  </Col>
                  <Col md='7'>
                    <Field name='defaultLPG'>
                      {({ input, meta }) => (
                        <DropdownList
                          {...input}
                          data={[
                            { id: 0, name: 'Select Default Landing Page Group', isDefault: false },
                            ...landingPageGroups.filter(
                              (landingPageGroup) => landingPageGroup.site?.id === parseInt(siteId)
                            ),
                          ]}
                          dataKey='id'
                          textField='name'
                          minLength={3}
                          filter='contains'
                        />
                      )}
                    </Field>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <CollapsibleCard title='Scrubs'>
              <Field name="hasScrubOffer" type="checkbox">
                {({ input, meta }) => (
                  <Row>
                    <Col xs="3">
                      Has Pixel Offer:
                    </Col>
                    <Col xs="9">
                        <Input style={{ marginLeft: 0 }}
                          {...input}
                        />
                    </Col>
                  </Row>
                )}
              </Field>
              <br />
              <Field name='scrubDefaultPercentage' format={(value) => parseInt(value)}>
                {({ input, meta }) => (
                  <>
                    <Row>
                      <Col xs='3'>
                        <Label>Scrub Default Percentage</Label>
                      </Col>
                      <Col xs='9'>
                        <NumberPicker min={0} {...input} placeholder='Scrub Default Percentage' />
                        {meta.error && meta.touched && <Alert color='danger'>{meta.error}</Alert>}
                      </Col>
                    </Row>
                  </>
                )}
              </Field>
              <TextAreaField name='scrubName' label='Scrub Name' />
              <TextAreaField name='scrubTrackbackUrl' label='Scrub Trackback URL' />
            </CollapsibleCard>
            <CollapsibleCard title='Scripts'>
              <TextAreaField name='scriptsHead' label='Scripts Head' />
              <TextAreaField name='scriptsBodyTop' label='Scripts Body Top' />
              <TextAreaField name='scriptsBodyBottom' label='Scripts Body Bottom' />
            </CollapsibleCard>
            <Row className='justify-content-end'>
              <div className='mr-2'>
                <SaveModalButton disabled={submitting || pristine} onClick={handleSubmit} />
              </div>
              <DeleteModalButton onClick={handleDelete}></DeleteModalButton>
            </Row>
            <ZuulToaster />
          </form>
        );
      }}
    />
  );
};
export default SiteDetail;
