import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Input, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { saveAsOfferName, saveOffer } from '../../../actions';
import './SaveOfferModal.css';

function OfferVariationModalButton({ onClickSubmit, getErrors, linkouts = [], posts = [], content }) {
  const [success, setSuccess] = useState(false);
  const [newOfferName, setNewOfferName] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const isOfferSaved = useSelector((state) => state.offerState.isOfferSaved);
  const dispatch = useDispatch();

  const toggleSuccess = useCallback(() => setSuccess((prev) => !prev), []);

  const handleSaveSuccess = useCallback(() => {
    setSubmitting(true);
    onClickSubmit();
  }, [onClickSubmit]);

  useEffect(() => {
    if (isOfferSaved && submitting) {
      setTimeout(() => dispatch(saveOffer(false)), 4000);
      toggleSuccess();
      setSubmitting(false);
    }

    if (getErrors().invalid) {
      setSuccess(false);
      setSubmitting(false);
    }
  }, [isOfferSaved, submitting, dispatch, getErrors, toggleSuccess]);

  const handleInputChange = useCallback(
    (event) => {
      const value = event.target.value;
      setNewOfferName(value);
      dispatch(saveAsOfferName(value));
    },
    [dispatch]
  );

  const isDisabled = linkouts.length === 0 || posts.length === 0 || getErrors().invalid;

  return (
    <div className='animated'>
      <Button color='success' onClick={toggleSuccess} disabled={isDisabled}>
        Add Variation
      </Button>
      <Modal isOpen={success} toggle={toggleSuccess} className={'modal-warning'}>
        <ModalHeader toggle={toggleSuccess} color='warning' style={{ color: 'black' }}>
          New Offer Variation
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col xs='auto'>
              <i className='fa fa-warning fa-3x' style={{ color: '#FFC107' }} />
            </Col>
            <Col>
              Any changes made without saving will not be copied to the new offer variation until you click save offer.
            </Col>
          </Row>
          <br />
          <Row>
            <Col lg={12}>
              <Input
                name='newOfferName'
                placeholder='New Offer Name'
                type='text'
                onChange={handleInputChange}
                value={newOfferName}
              />
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color='warning' disabled={submitting} onClick={handleSaveSuccess}>
            {submitting ? 'Processing' : 'Add'}
          </Button>
          <Button color='secondary' disabled={submitting} onClick={toggleSuccess}>
            {submitting ? 'Processing' : 'Cancel'}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default OfferVariationModalButton;
