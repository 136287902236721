import React, { useCallback, useMemo } from 'react';

const DraggableOffer = ({ offer }) => {
  const onDragStart = useCallback(
    (event) => {
      console.log('dragstart', offer);
      event.dataTransfer.setData('application/reactflow', JSON.stringify(offer));
      event.dataTransfer.effectAllowed = 'move';
    },
    [offer]
  );

  const draggableOffer = useMemo(
    () => (
      <div className='dndnode input' onDragStart={onDragStart} draggable>
        {offer.id} : {offer ? offer.name : ''}
      </div>
    ),
    [offer, onDragStart]
  );

  return draggableOffer;
};

export default DraggableOffer;
