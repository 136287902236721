import React, { Component } from 'react';
import api from '../../services';
import { columnDefaults } from '../../utils/tableFunctions';
import { Button, Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import SearchLabel from '../../components/SearchLabel/SearchLabel';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Link } from 'react-router-dom';

class SiteScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sites: [],
    };
    this.refreshSites = this.refreshSites.bind(this);
  }

  async componentDidMount() {
    await this.refreshSites();
  }

  async refreshSites() {
    await api
      .get(`sites`)
      .then((response) => {
        console.log(response);
        const activeStates = response.data.filter((site) => site.status === 1);
        this.setState({ sites: activeStates });
        console.log(response.data);
      })
      .catch((error) => console.log(error));
  }

  render() {
    function linkFormatter(cell, row) {
      return (
        <Link to={`/campaigns/sites/${row.id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
          {cell}
        </Link>
      );
    }

    const options = {
      sizePerPageDropDown: this.renderSizePerPageDropDown,
    };
    const { SearchBar, ClearSearchButton } = Search;
    const columns = [
      {
        dataField: 'id',
        text: 'ID',
        headerStyle: {
          backgroundColor: '#2f353a',
        },
        formatter: linkFormatter,
        ...columnDefaults,
      },
      {
        dataField: 'domain',
        text: 'Site Name',
        headerStyle: {
          backgroundColor: '#2f353a',
        },
        formatter: linkFormatter,
        ...columnDefaults,
      },
    ];
    return (
      <div>
        <Card>
          <CardHeader>
            <Row>
              <Col lg={9}>
                <i className='icon-menu' />
                &nbsp; Site Names
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <ToolkitProvider keyField='id' data={this.state.sites} columns={columns} search bootstrap4={true}>
              {(props) => (
                <div>
                  <Row>
                    <Col>
                      <span className='display-4'>Sites</span>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col lg='12' className='d-flex align-content-center justify-content-between'>
                      <div>
                        <SearchLabel />
                        <br />
                        <SearchBar {...props.searchProps} />
                      </div>
                      <div>
                        <br></br>
                        <Link to='/campaigns/sites/create' style={{ textDecoration: 'none', color: 'inherit' }}>
                          <Button color='primary'>
                            <i className='fa fa-plus' />
                            &nbsp; Add Site
                          </Button>
                        </Link>
                      </div>
                    </Col>
                  </Row>
                  <br />
                  <BootstrapTable hover {...props.baseProps} pagination={paginationFactory()} />
                </div>
              )}
            </ToolkitProvider>
          </CardBody>
        </Card>
      </div>
    );
  }
}

export default SiteScreen;
