const validate = (values, props) => {
  console.log('values', values);
  const errors = {};

  if (!values.name) {
    errors.name = 'Please enter a name for this Advertiser Pre Ping.';
  }
  if (!values.method || values.method === 'null') {
    errors.method = 'Please select a pre ping method.';
  }

  if (!values.handler) {
    errors.handler = 'Please select a handler.';
  }
  if (!values.url) {
    errors.url = 'Please enter a url.';
  }
  if (!values.advertiser) {
    errors.advertiser = 'Please select an advertiser.';
  }

  return errors;
};

export default validate;
