import { useState, useMemo, useEffect } from 'react';
import { Collapse, Button, Modal, ModalBody, Table, Badge, Card, CardHeader, CardBody, Row, Col } from 'reactstrap';
import { useGetAuditRecordsInfinite } from './queries';
import Diff from 'react-stylable-diff';
import './ChangelogModal.css';
import ZuulLoader from '../ZuulLoader/ZuulLoader';
import InfiniteScroll from 'react-infinite-scroll-component';
import { ZuulToaster } from '../Toaster/ZuulToaster';
import RevertWrapper from './RevertWrapper';

const ChangelogModal = ({ typeName, apiPath, id }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div id='changelog-modal' className='animated' style={{ textAlign: 'center' }}>
      <Button
        color='secondary'
        id='locations-button'
        onClick={() => {
          setIsOpen(true);
        }}
      >
        <i className='fa fa-file-text-o' />
      </Button>
      <Modal isOpen={isOpen} toggle={() => setIsOpen(false)} style={{ maxWidth: '85vw' }}>
        <ModalBody id='audit-table' style={{ overflowY: 'scroll', height: '88vh', padding: 0 }}>
          {isOpen && <AuditTable apiPath={apiPath} id={id} typeName={typeName} />}
        </ModalBody>
      </Modal>
    </div>
  );
};

const AuditTable = ({ apiPath, id, typeName }) => {
  const { data, fetchNextPage, hasNextPage, isLoading: loadingAudit } = useGetAuditRecordsInfinite({ apiPath, id });
  const auditRecords = useMemo(() => (data ? data?.pages.flatMap((item) => item.results) : []), [data]);

  return (
    <>
      {loadingAudit && loadingAudit ? (
        <ZuulLoader text='Loading Audit Records...' />
      ) : (
        <>
          <ZuulToaster
            position={'top-right'}
            style={{ minWidth: '250px', fontSize: '1.1em' }}
            customContainerStyle={{
              position: 'fixed',
              left: -40,
            }}
          />
          <InfiniteScroll
            dataLength={auditRecords && auditRecords.length}
            next={fetchNextPage}
            hasMore={!!hasNextPage}
            loader={<ZuulLoader />}
            data-testid='audit-table'
            scrollableTarget='audit-table'
            endMessage={
              <p style={{ textAlign: 'center' }}>
                <b>Yay! You have seen it all</b>
              </p>
            }
          >
            <Table bordered className='fixed-header table-dark'>
              <thead>
                <tr>
                  <th style={{ width: '10em' }}>Operation Type</th>
                  <th style={{ width: '16em' }}>Operation Date</th>
                  <th style={{ width: '10em' }}>User</th>
                  <th>Changes</th>
                </tr>
              </thead>
              <tbody style={{ wordBreak: 'break-word' }}>
                {auditRecords.map((record, index) => (
                  <tr key={index}>
                    <td>{record.operationType}</td>
                    <td>{record.operationDate}</td>
                    <td>{record.operationUser}</td>
                    <td>
                      {record.operationType === 'Edit' && record.changes?.length === 0 && (
                        <span>{typeName} saved - no changes made</span>
                      )}
                      {record.operationType === 'Create' && <span>{`${typeName}[${id}] was created`}</span>}
                      {record.changes?.map((change, index) => (
                        <div key={index}>
                          <div>
                            <Changes changes={change} typeName={typeName} id={id} />
                          </div>
                        </div>
                      ))}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </InfiniteScroll>
        </>
      )}
    </>
  );
};

const Changes = ({ changes, typeName, id }) => {
  const [isOpen, setIsOpen] = useState(true);
  const [showRevert, setShowRevert] = useState(false);

  useEffect(() => {
    if (typeName === 'Offer' || typeName === 'Layout' || typeName === 'Container') {
      setShowRevert(true);
    }
  }, [typeName]);

  return (
    <Card>
      <CardHeader className='cursor-pointer' onClick={() => setIsOpen(!isOpen)}>
        <Row className='changes-header'>
          <>
            <Badge color='secondary' className='field-name'>
              {changes.fieldName}
            </Badge>
            <div className='d-flex justify-content-center align-items-center'>
              {showRevert && <RevertWrapper change={changes} typeName={typeName} id={id} />}
              {isOpen ? (
                <i className='fa fa-chevron-up opened px-4' />
              ) : (
                <i className='fa fa-chevron-down closed px-4' />
              )}
            </div>
          </>
        </Row>
      </CardHeader>
      <Collapse isOpen={isOpen}>
        <CardBody>
          <Diff type='words' inputA={changes.oldValue} inputB={changes.newValue}></Diff>
        </CardBody>
      </Collapse>
    </Card>
  );
};

export default ChangelogModal;
