import React, { useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';
import { change, Field } from 'redux-form';
import { renderCriteriaOptions, renderMultiSelect } from '../../views/Offers/OfferTitle/renderConsts';
import { useDispatch, useSelector } from 'react-redux';
import { useLandingPageGroups } from '../../views/LandingPageGroups/queries';
import { useLandingPages } from '../../views/LandingPages/queries';

const CriteriaLandingPageIncludes = (props) => {
  const { data: landingPageGroups } = useLandingPageGroups();
  const { data: landingPages } = useLandingPages();
  const criteriaLandingPageGroupIncludeExclude = useSelector((state) =>
    state.offerState.selector(state, 'criteriaLandingPageGroupIncludeExclude')
  );
  const criteriaLandingPageIncludeExclude = useSelector((state) =>
    state.offerState.selector(state, 'criteriaLandingPageIncludeExclude')
  );

  return (
    <div>
      <Row>
        <Col lg={2}>
          <h5>Landing Page Groups</h5>
        </Col>
        <Col lg={2}>
          <Field name='criteriaLandingPageGroupIncludeExclude' component={renderCriteriaOptions} type='select' />
        </Col>
        {(criteriaLandingPageGroupIncludeExclude === 'Include' ||
          criteriaLandingPageGroupIncludeExclude === 'Exclude') && (
          <Col lg='4'>
            <FieldMultiSelectWithInitFC
              fieldName={'criteriaLandingPageGroupIds'}
              values={landingPageGroups}
              fieldResolver={(val) => {
                return { name: val.name, id: val.id };
              }}
            />
          </Col>
        )}
      </Row>
      <br />
      <Row>
        <Col lg={2}>
          <h5>Landing Pages</h5>
        </Col>
        <Col lg={2}>
          <Field name='criteriaLandingPageIncludeExclude' component={renderCriteriaOptions} type='select' />
        </Col>
        {(criteriaLandingPageIncludeExclude === 'Include' || criteriaLandingPageIncludeExclude === 'Exclude') && (
          <Col lg='4'>
            <FieldMultiSelectWithInitFC
              fieldName={'criteriaLandingPageIds'}
              values={landingPages}
              fieldResolver={(val) => {
                return { name: val.offer.name, id: val.id };
              }}
            />
          </Col>
        )}
      </Row>
    </div>
  );
};

const FieldMultiSelectWithInitFC = ({ values, fieldName, fieldResolver }) => {
  const [sortedValues, setSortedValues] = useState();
  const dispatch = useDispatch();
  const curForm = useSelector((state) => state.formSelector.formName);
  const curFieldVal = useSelector((state) => state.offerState.selector(state, fieldName));

  useEffect(() => {
    resolveInitialValues();
    setSortedValues(sortValues(values));
  }, [values]);

  function findValueById(id) {
    return values.find((val) => val.id === id);
  }

  function resolveInitialValues() {
    if (!values || values.length < 1 || !curFieldVal || curFieldVal.length < 1) return;

    const init = curFieldVal.map((val) => {
      const foundVal = findValueById(val);
      return foundVal ? fieldResolver(foundVal) : null;
    });

    dispatch(change(curForm, fieldName, init));
  }

  const sortValues = (values) => {
    if (!values || values.length < 1) return [];

    const sorted = values
      .map((val) => {
        return fieldResolver(val);
      })
      .sort((a, b) => (a.name > b.name ? 1 : -1));
    return sorted;
  };

  return (
    <Field name={fieldName} component={renderMultiSelect} valueField={'id'} textField={'name'} data={sortedValues} />
  );
};

export default CriteriaLandingPageIncludes;
