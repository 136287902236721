import React from 'react';
import Multiselect from 'react-widgets/Multiselect';
import NumberPicker from 'react-widgets/NumberPicker';
import { Alert, Button, Col, Input, Label, Row } from 'reactstrap';
import { Field } from 'redux-form';
import { renderComboBox, renderToggleSwitch, renderTrueOrFalse } from '../../views/Offers/OfferTitle/renderConsts';

export const ToggleSwitchRow = ({ label, name }) => (
  <Row>
    <Col>
      <label>{label}</label>
    </Col>
    <Col>
      <Field name={name} component={renderToggleSwitch} />
    </Col>
  </Row>
);

export function MultiselectField({ input, meta, data, textField, busy = false }) {
  return (
    <>
      <Multiselect
        {...input}
        data={data}
        placeholder='Select'
        value={input.value || []} // requires value to be an array
        onBlur={() => input.onBlur(input.value)}
        textField={textField || 'name'}
        dataKey='id'
        filter='contains'
        busy={busy}
      />
      {meta.error && meta.touched && <Alert color='danger'>{meta.error}</Alert>}
    </>
  );
}

export const SelectField = ({ input, meta, options }) => (
  <div>
    <Input {...input} type='select' placeholder='Select'>
      {options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.option}
        </option>
      ))}
    </Input>
    {meta.error && meta.touched && <Alert color='danger'>{meta.error}</Alert>}
  </div>
);

export function SelectRow({
  name,
  label,
  options = [
    { value: '', option: 'None' },
    { value: 'Include', option: 'Include' },
    { value: 'Exclude', option: 'Exclude' },
  ],
  children,
}) {
  return (
    <Row
      style={{
        marginTop: '1rem',
      }}
    >
      <Col lg={2}>
        <h5>{label}</h5>
      </Col>
      <Col lg={2} xs={3}>
        <Field name={name} component={SelectField} options={options} />
      </Col>
      {children}
    </Row>
  );
}

export const Condition = ({ when, is, children }) => (
  <Field name={when} subscription={{ value: true }}>
    {({ input: { value } }) => (is.includes(value) ? children : null)}
  </Field>
);

export const ColCondition = ({ when, children }) => (
  <Col>
    <Field name={when} subscription={{ value: true }}>
      {({ input: { value } }) => (value ? children : null)}
    </Field>
  </Col>
);

//TODO: Had to remove the format because it wasn't working with the redux-form Field component

export const NumberField = ({ input, meta, label, min = 0 }) => {
  return (
    <>
      <Label>{label}</Label>
      <NumberPicker
        min={min}
        placeholder={label}
        value={parseInt(input.value)}
        onChange={(value) => input.onChange(value)}
      />
      <br />
      {meta.error && meta.touched && <Alert color='danger'>{meta.error}</Alert>}
    </>
  );
};

const normalizeBoolean = (value) => {
  if (value === 'true') return true;
  if (value === 'false') return false;
  return value;
};

export const UserAttributes = ({ fields, data }) => (
  <div>
    <Button color='success' className='float-right' onClick={() => fields.push({ user_attribute_value: true })}>
      Add User Attribute
    </Button>
    <Col lg={9}>
      {fields.map((criteriaUserAttributes, index) => (
        <div key={index}>
          <Row>
            <Col lg={7}>
              <Field
                name={`${criteriaUserAttributes}.user_attribute`}
                component={renderComboBox}
                textField='name'
                data={data?.map((item) => item?.name)}
              />
            </Col>
            <Col lg={3}>
              <Field
                name={`${criteriaUserAttributes}.user_attribute_value`}
                component={renderTrueOrFalse}
                normalize={normalizeBoolean}
                type='select'
              />
            </Col>
            <Col lg={1}>
              <Button
                color='danger'
                className='removeofferInputParent'
                type='button'
                title='Remove Parent Attribute'
                onClick={() => fields.remove(index)}
              >
                <i className='fa fa-close' />
              </Button>
            </Col>
          </Row>
          <br />
        </div>
      ))}
    </Col>
  </div>
);
