import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import api from "../../services";
import { fetchAll } from "../../services/helpers";

// Custom hooks
export const useOfferBlocks = () =>
  useQuery({
    queryKey: ['offerBlocks'],
    queryFn: () => fetchAll('offerblocks'),
  });

const fetchOfferBlock = async (id) => {
  if (id === 'create') {
    return {
      active: true,
      name: '',
      offer_block_checkout_status: false,
      offerBlockOffers: [],
      offersPublished: {
        offer_block: [],
      },
      published: true,
      zuulUser: JSON.parse(localStorage.getItem('userData')),
    };
  }
  return api.get(`offerblocks/${id}`).then((res) => res.data);
};

function parseDayHourRestriction(obj) {
  return Object.entries(obj).map(([restrictionDay, properties]) => ({
    restrictionDay,
    ...properties,
  }));
}

export const useOfferBlock = (id) =>
  useQuery({
    queryKey: ['offerBlocks', id],
    queryFn: () => fetchOfferBlock(id),
    onSuccess: (data) => {
      if (data.offer) {
        data.offer.criteriaUserAttributes = JSON.parse(data.offer.criteriaUserAttributes);
        data.offer.suppressionRules = JSON.parse(data.offer.suppressionRules);
        data.offer.criteriaDayHourRestrictions = data.offer.criteriaDayHourRestrictions
          ? parseDayHourRestriction(JSON.parse(data.offer.criteriaDayHourRestrictions))
          : [];
      }
    },
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });

export const useOfferBlockByOfferId = (enabled, offerId) =>
  useQuery({
    queryKey: ['offerBlockOfferId', offerId],
    queryFn: () => api.get(`offerblocks/offer/${offerId}`).then((res) => res.data),
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    enabled,
  });

export const useCreateOfferBlock = () => {
  const queryClient = useQueryClient();
  return useMutation((data) => api.post('offerblocks', data), {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['offerBlocks'] });
    },
  });
};

export const useUpdateOfferBlock = () => {
  const queryClient = useQueryClient();
  return useMutation((data) => api.put(`offerblocks/${data.id}`, data), {
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ['offerBlocks', data.data.id.toString()] });
      queryClient.invalidateQueries({ queryKey: ['offerBlocks'] });
      queryClient.invalidateQueries({ queryKey: ['offersTable'] });
    },
  });
};

export const useUpdateOffer = () => {
  const queryClient = useQueryClient();
  return useMutation((data) => api.put(`offers/${data.id}`, data), {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['offersTable'] });
    },
  });
};

export const useLayoutsTable = (select) =>
  useQuery({
    queryKey: ['layoutsTable'],
    queryFn: () => api.get('layouts/table').then((res) => res.data),
    select,
  });

export const useContainersTable = (select) =>
  useQuery({
    queryKey: ['containersTable'],
    queryFn: () => api.get('containers/table').then((res) => res.data),
    select,
  });

export const useDomains = (enabled = true, selectFn) =>
  useQuery({
    queryKey: ['domains'],
    queryFn: () => api.get('domains').then((res) => res.data),
    enabled,
    select: selectFn,
  });

export const useDevices = (enabled, selectFn) =>
  useQuery({
    queryKey: ['devices'],
    queryFn: () => api.get('devices').then((res) => res.data),
    enabled,
    select: selectFn,
  });

export const useAibList = (enabled, selectFn) =>
  useQuery({
    queryKey: ['aibList'],
    queryFn: () => api.get('aibList').then((res) => res.data),
    enabled,
    select: selectFn,
  });

export const useOperatingSystems = (enabled, selectFn) =>
  useQuery({
    queryKey: ['operatingSystems'],
    queryFn: () => api.get('operatingsystems').then((res) => res.data),
    enabled,
    select: selectFn,
  });

export const useBrowsers = (enabled, selectFn) =>
  useQuery({
    queryKey: ['browsers'],
    queryFn: () => api.get('browsers').then((res) => res.data),
    enabled,
    select: selectFn,
  });

export const useUserAttributes = (enabled, selectFn) =>
  useQuery({
    queryKey: ['userAttributes'],
    queryFn: () => api.get('user-attribute').then((res) => res.data),
    enabled,
    select: selectFn,
  });

export const useAffiliates = (enabled, selectFn) =>
  useQuery({
    queryKey: ['affiliates'],
    queryFn: () => api.get('affiliates').then((res) => res.data),
    enabled,
    select: selectFn,
  });

export const useHandlers = (enabled, selectFn) =>
  useQuery({
    queryKey: ['handlers'],
    queryFn: () => api.get('handlers').then((res) => res.data),
    enabled,
    select: selectFn,
  });

export const useCriteriaAnswers = (enabled, id, selectFn) =>
  useQuery({
    queryKey: ['criteriaAnswers', id],
    queryFn: () =>
      api
        .get(`offerquestionanswer/question/${id}`)
        .then((res) => res.data)
        .catch(() => []),
    enabled,
    select: selectFn,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });
