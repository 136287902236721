import React, { Component } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { Button, Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import '../../../node_modules/react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import SearchLabel from '../../components/SearchLabel/SearchLabel';
import api from '../../services';
import './Domain.css';
import DomainDetails from './Modals/DomainDetails';
import UpdateStatus from './Modals/UpdateStatus';

class Domains extends Component {
  constructor(props) {
    super(props);
    this.state = {
      domains: [],
      showComponent: false,
      record: null,
      sites: [],
      domainVerticals: [],
    };

    this.activeToggle = this.activeToggle.bind(this);
    this.refreshDomains = this.refreshDomains.bind(this);
  }

  renderSizePerPageDropDown = (props) => {
    return (
      <div className='btn-group'>
        {[10, 25, 30, 50].map((n, idx) => {
          const isActive = n === props.currSizePerPage ? 'active' : null;
          return (
            <button
              key={idx}
              type='button'
              className={`btn btn-info ${isActive}`}
              onClick={() => props.changeSizePerPage(n)}
            >
              {n}
            </button>
          );
        })}
      </div>
    );
  };

  async componentDidMount() {
    this.refreshDomains();
    this.fetchAllsites();
    this.fetchDomainVerticals();
  }

  fetchDomainVerticals = () => {
    api.get('domain-verticals')
      .then(response => {
        console.log("domainVerticalData");
        console.log(response.data);
        this.setState({ domainVerticals: response.data });
      })
      .catch(error => {
        console.error('Error fetching domain verticals:', error);
      });
  };

  fetchAllsites() {
    api
      .get(`sites`)
      .then((response) => {
        console.log("siteData");
        console.log(response.data);
        this.setState({ sites: response.data });
      })
      .catch((error) => console.log(error));
  }

  refreshDomains() {
    api
      .get(`domains`)
      .then((response) => {
        console.log("domain");
        console.log(response.data);
        this.setState({ domains: response.data });
      })
      .catch((error) => console.log(error));
  }

  activeToggle = (cell, row) => {
    return (
      <UpdateStatus
        isActive={row.isActive}
        content={row}
        onClickSubmit={(activeStatus) =>
          api
            .put(`domains/${row.id}`, {
              ...row,
              isActive: activeStatus,
            })
            .then((result) => {
              this.refreshDomains();
            })
        }
        isEditMode={true}
      />
    );
  };

  modalToggle(row) {
    this.setState({
      showComponent: !this.state.showComponent,
      record: row,
    });
  }

  render() {
    const { SearchBar, ClearSearchButton } = Search;
    const columns = [
      {
        dataField: 'id',
        text: 'ID',
        sort: true,
        headerStyle: {
          backgroundColor: '#2f353a',
        },
      },
      {
        dataField: 'name',
        text: 'Name',
        sort: true,
        headerStyle: {
          backgroundColor: '#2f353a',
        },
      },
      {
        dataField: 'domainVertical.name',
        text: 'Vertical Name',
        sort: true,
        headerStyle: {
          backgroundColor: '#2f353a',
        },
      },
      {
        dataField: 'site.id',
        text: 'Site ID',
        sort: true,
        headerStyle: {
          backgroundColor: '#2f353a',
        },
      },
      {
        dataField: 'site.domain',
        text: 'Site Name',
        sort: true,
        headerStyle: {
          backgroundColor: '#2f353a',
        },
      },
      {
        dataField: 'gtmId',
        text: 'GTM ID',
        sort: true,
        headerStyle: {
          backgroundColor: '#2f353a',
        },
      },
      {
        dataField: 'isActive',
        text: 'Status',
        sort: true,
        isDummyField: true,
        formatter: this.activeToggle,
        headerStyle: {
          backgroundColor: '#2f353a',
          width: '5%',
        },
      },
      {
        dataField: 'active',
        text: 'Action',
        isDummyField: true,
        formatter: (cell, row) => (
          <div>
            <Button color='success' onClick={this.modalToggle.bind(this, row)}>
              <i className='fa fa-edit ' />
              &nbsp; Edit
            </Button>
          </div>
        ),
        headerStyle: {
          backgroundColor: '#2f353a',
          width: '6%',
        },
      },
    ];
    return (
      <div className='animated fadeIn'>
        {this.state.showComponent ? (
          <DomainDetails
            action={this.modalToggle.bind(this, false)}
            record={this.state}
            refreshCallBack={this.refreshDomains}
            domains={this.state.domains}
          />
        ) : null}
        <Card>
          <CardHeader>
            <Row>
              <Col lg={9}>
                <i className='icon-menu' />
                &nbsp; Domains
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <ToolkitProvider keyField='id' data={this.state.domains} columns={columns} search bootstrap4={true}>
              {(props) => (
                <div>
                  <Row>
                    <Col lg={5}>
                      <span className='display-4'>Domains</span>
                    </Col>
                    <Col lg={7}>
                      <Button color='primary' onClick={this.modalToggle.bind(this)} className='to-right'>
                        <i className='fa fa-plus' /> Add Domain
                      </Button>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col lg={9}>
                      <SearchLabel />
                      <br />
                      <SearchBar {...props.searchProps} />
                    </Col>
                  </Row>
                  <br />
                  <BootstrapTable hover {...props.baseProps} pagination={paginationFactory()} />
                </div>
              )}
            </ToolkitProvider>
          </CardBody>
        </Card>
      </div>
    );
  }
}

export default Domains;
