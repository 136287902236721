import { format } from 'date-fns';
const columnDefaults = {
  sort: true,
  headerStyle: {
    backgroundColor: '#2f353a',
  },
};

const columns = [
  {
    dataField: 'id',
    text: 'ID',
    ...columnDefaults,
  },
  {
    dataField: 'affiliateName',
    text: 'Affiliate Name',
    ...columnDefaults,
  },
  {
    dataField: 'affiliateId',
    text: 'Affiliate ID',
    ...columnDefaults,
  },
  {
    dataField: 'revSharePercentage',
    text: 'Revshare %',
    formatter: (percentage) => {
      let formattedPercent = new Intl.NumberFormat({ style: 'percent ' }).format(percentage);
      return <span>{formattedPercent} %</span>;
    },
    ...columnDefaults,
  },
  {
    dataField: 'startDate',
    text: 'Start Date',
    formatter: (date) => <span>{format(new Date(date), 'MM/dd/yy hh:mm a')}</span>,
    headerAttrs: { width: '150px' },
    ...columnDefaults,
  },
  {
    dataField: 'endDate',
    text: 'End Date',
    formatter: (date) => {
      if (date) {
        return <span>{format(new Date(date), 'MM/dd/yy hh:mm a')}</span>;
      }
    },
    headerAttrs: { width: '150px' },
    ...columnDefaults,
  },
  {
    dataField: 'webDomain',
    text: 'Domain',
    ...columnDefaults,
  },
  {
    dataField: 'subId',
    text: 'Sub ID',
    ...columnDefaults,
  },
  {
    dataField: 'aibListId',
    text: 'AIB List ID',
    ...columnDefaults,
  },
  {
    dataField: 'aibTypeId',
    text: 'AIB Type ID',
    ...columnDefaults,
  },
  {
    dataField: 'landingPageGroupId',
    text: 'LPG ID',
    ...columnDefaults,
  },
  {
    dataField: 'default',
    text: 'Default',
    ...columnDefaults,
  },
];

export const columnsModal = [
  {
    dataField: 'sl.no',
    text: '#',
    formatter: (cell, row, rowIndex, formatExtraData) => {
      return rowIndex + 1;
    },
    headerAttrs: { width: '50px' },
    headerStyle: {
      backgroundColor: '#2f353a',
    },
    style: {
      backgroundColor: '#2f353a',
    },
  },
  ...columns,
];

export default columns;
