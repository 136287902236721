import React from 'react';
import { Input } from 'reactstrap';
import { useCurrentCapAmountByDomains } from './queries';

export default function CurrentCapAmountByDomains({ id, domains }) {
  const { data } = useCurrentCapAmountByDomains(id, domains);

  return (
    <div>
      <label>Current Segment Amount</label>
      <Input disabled={true} type='number' value={data} />
      <br />
    </div>
  );
}
