import React from 'react';
import { Col, Row } from 'reactstrap';
import './ZuulLoader.css';

const ZuulLoader = ({ size, color, text }) => {
  return (
    <div className='text-center'>
      <Row>
        <Col lg={12}>
          <div style={{ display: 'inline-flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <div className='zuul-loader'>
              <div className='zuul-loader-inner one'></div>
              <div className='zuul-loader-inner two'></div>
              <div className='zuul-loader-inner three'></div>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col lg={12}>
          <p style={{ marginTop: '64px' }}>{text || 'Loading ...'}</p>
        </Col>
      </Row>
    </div>
  );
};

export default ZuulLoader;
