import {
  GET_ALL_HANDLERS,
  GET_ALL_CONTAINERS,
  GET_ALL_LAYOUTS,
  GET_ALL_OFFER_TYPES,
  GET_SITES,
  GET_SITES_BY_OFFER_ID,
  GET_OFFER_BLOCK_SUMMARY_BY_OFFER_ID
} from './types';
import { GET_ALL_OFFER_ACTIONS } from '../../../actions/types';

const initialState = {
  handlers: [],
  layouts: [],
  sites: [],
  offerTypes: {},
  siteLocations: {},
  offerBlockLocations: {},
  containers: [],
};
export default function (state = initialState, action) {
  switch (action.type) {
    case GET_ALL_HANDLERS:
      return { ...state, handlers: action.payload };
    case GET_ALL_CONTAINERS:
      return { ...state, containers: action.payload };
    case GET_ALL_LAYOUTS:
      return { ...state, layouts: action.payload };
    case GET_ALL_OFFER_TYPES:
      return { ...state, offerTypes: action.payload };
    case GET_ALL_OFFER_ACTIONS:
      return { ...state, allOfferActions: action.payload };
    case GET_SITES:
      return { ...state, sites: action.payload };
    case GET_SITES_BY_OFFER_ID:
      const [id, sitesByOfferId] = action.payload;
      const siteLocations = { ...state.siteLocations, [id]: sitesByOfferId };
      return { ...state, siteLocations };
    case GET_OFFER_BLOCK_SUMMARY_BY_OFFER_ID:
      const {offerId, offerBlockSummary} = action.payload;
      const offerBlockLocations = { ...state.offerBlockLocations, [offerId]: offerBlockSummary };
      return { ...state, offerBlockLocations };
    default:
      return state;
  }
}
