import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Row, Col } from 'reactstrap';
import _ from 'lodash';
import { Field, FieldArray } from 'redux-form';

import { updateSelectedLPs } from '../actions';
import './LPGDetails.css';
import { renderField } from '../../Offers/OfferTitle/renderConsts';
import Slot from './LPSlot';

class ReturnUserSegment extends Component {
  normalizeWeight = (value) => {
    const str = value + '';
    if (value < 1) {
      if (str.length === 1) {
        return 0;
      } else if (str.length > 1) {
        return str.split('').slice(-1).join('');
      } else {
        return 0;
      }
    } else if (str.length > 1 && str[0] === '0') {
      for (let i = 0; i < str.length; i++) {
        if (str[i] !== '0') {
          return str.split('').splice(i).join('');
        }
      }
      return 1;
    } else if (str.includes('.')) {
      return Math.round(value);
    }
    console.log(value);
    return value;
  };

  renderSlots = ({ fields, returnUserSegmentIndex, returnUserSegment }) => {
    return (
      <>
        <Row>
          <Col sm={10} lg={10}>
            <Field
              name={`${returnUserSegment}.returnValue`}
              normalize={this.normalizeWeight}
              component={renderField}
              type='number'
              label='Return Value'
            />
          </Col>
          <Col sm={2} lg={2}>
            <Button
              className='add-slot-button'
              onClick={() =>
                fields.push({
                  flex: 1,
                  id: null,
                  slot_lp: null,
                })
              }
              color='success'
            >
              <i className='fa fa-plus' />
            </Button>
          </Col>
        </Row>
        {fields.map((slot, rowIndex) => {
          return (
            <Slot
              name={slot}
              slot={slot}
              key={slot}
              returnUserSegmentIndex={returnUserSegmentIndex}
              slotType={rowIndex === 0 ? 'Control' : `LP ${rowIndex}`}
              fields={fields}
              index={rowIndex}
            />
          );
        })}
      </>
    );
  };

  handlereturnUserSegmentRemoval = (fields, index) => {
    this.props.updateSelectedLPs(index, null);
    fields.remove(index);
  };

  render() {
    const { returnUserSegment, index, fields } = this.props;
    return (
      <>
        <Row>
          <Col sm={10} lg={10}>
            <strong>Return User Segment {index + 1}</strong>{' '}
          </Col>
          <Col sm={2} lg={2}>
            {fields.length > 1 && (
              <Button
                className='delete-offerWall-slot-button'
                onClick={() =>
                  this.handlereturnUserSegmentRemoval(fields, index)
                }
                color='danger'
              >
                <i className='fa fa-minus' />
              </Button>
            )}
          </Col>
        </Row>
        <FieldArray
          name={`${returnUserSegment}.slots`}
          slot={`${returnUserSegment}.slots`}
          returnUserSegment={returnUserSegment}
          returnUserSegmentIndex={index}
          component={this.renderSlots}
        />
      </>
    );
  }
}

function mapStateToProps(state) {
  const { selector } = state.landingPageGroup;
  return {
    returnUserSegments: selector(state, 'returnUserSegments'),
  };
}

export default connect(mapStateToProps, { updateSelectedLPs })(
  ReturnUserSegment
);
