import React from 'react';
import { Alert, Button, Input, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';
import { Field } from 'redux-form';

const Value = ({ input }) => (
  <>
    <Input {...input} placeholder='Value' type='textarea' rows={1} />
    {!input.value && (
      <div style={{ color: '#e4e7ea', padding: 'inherit', marginTop: '10px', gap: '5px' }}>
        <i className='icon-info' />
        <span style={{ fontSize: '12px' }}>
          If the value is left empty, the Layout/Container must have a failsafe configured.
        </span>
      </div>
    )}
  </>
);

const Key = ({ input, meta, customVarFlag }) => {
  const handleChange = (e) => {
    input.onChange(customVarFlag + e.target.value);
  };

  const handleBlur = (e) => {
    const currentValue = e.target.value;
    if (!currentValue.startsWith(customVarFlag)) {
      input.onBlur(customVarFlag + currentValue);
    } else {
      input.onBlur(currentValue);
    }
  };

  const displayValue = input.value.startsWith(customVarFlag) ? input.value.slice(customVarFlag.length) : input.value;

  return (
    <div>
      <InputGroup>
        <InputGroupAddon addonType='prepend'>
          <InputGroupText style={{ fontSize: '0.875rem' }}>{customVarFlag}</InputGroupText>
        </InputGroupAddon>
        <Input {...input} value={displayValue} onChange={handleChange} onBlur={handleBlur} placeholder='Key' />
      </InputGroup>
      {meta.touched && meta.error && <Alert color='danger'>{meta.error}</Alert>}
    </div>
  );
};

export default function CustomVariableItem({ customVariablesItem, index, fields, customVarFlag }) {
  return (
    <div style={{ background: '#2f353a', padding: '.5rem', borderRadius: '0.25rem', marginBottom: '.5rem' }}>
      <div style={{ marginBottom: '.5rem' }}>
        <Button
          color='danger'
          type='button'
          title='Remove Parent Attribute'
          onClick={() => fields.remove(index)}
          size='sm'
        >
          <i className='fa fa-close' />
        </Button>
      </div>
      <div style={{ marginBottom: '.5rem' }}>
        <Field name={`${customVariablesItem}.key`} type='text' component={Key} customVarFlag={customVarFlag} />
      </div>
      <div>
        <Field name={`${customVariablesItem}.value`} type='text' component={Value} />
      </div>
    </div>
  );
}
