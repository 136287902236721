import React, { Component } from 'react';
import { Button, Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import './PublisherPrePings.css';
import { columnFormatter } from '../../utils/tableFunctions';
import api from '../../services';
import _ from 'lodash';
import PublisherPrePingTable from '../../components/Table/hookTable';
import ActiveStatusModal from '../Offers/OfferTitle/ActiveStatusModal';
import { Link } from 'react-router-dom';

class PublisherPrePings extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.idleTimer = null;
    this.state = {
      publisherPrePing: [],
      filteredPublisherPrePing: [],
      active: null,
      publisherPrePingSelection: [],
      render: false,
      toOfferWallAdsPage: false,
      toId: null,
      page: 1,
      searchTerm: '',
      sizePerPage: 100,
      paginationSize: 10,
      totalElements: 10,
      idEditMode: false,
      offerType: 'Question',
      loading: false,
    };

    this.getData = this.getData.bind(this);
  }

  renderSizePerPageDropDown = (props) => {
    return (
      <div className='btn-group'>
        {[10, 25, 30, 50].map((n, idx) => {
          const isActive = n === props.currSizePerPage ? 'active' : '';
          return (
            <button
              key={idx}
              type='button'
              className={`btn btn-info ${isActive}`}
              onClick={() => props.changeSizePerPage(n)}
            >
              {n}
            </button>
          );
        })}
      </div>
    );
  };

  renderPublisherPrePings(response) {
    return response.data.content.map((prePing) => {
      return {
        id: prePing.id !== null && prePing.id !== undefined ? prePing.id : '',
        name: prePing.name !== null ? prePing.name : '',
        vanityUrl: prePing.vanityUrl !== null ? prePing.vanityUrl : '',
        active: prePing.active,
      };
    });
  }

  getAdditionalData = () => {
    const publisherPromises = [];

    for (let i = 1; i <= this.state.totalPages; i++) {
      const publisherPromise = api.get(`publisher-pre-pings?limit=${100}&offset=${i}`);
      publisherPromises.push(publisherPromise);
    }

    return publisherPromises;
  };

  getData(page = this.state.page, limit = this.state.sizePerPage > 0 ? this.state.sizePerPage : 100) {
    let offset = page - 1;
    api
      .get(`publisher-pre-pings?limit=${limit}&offset=${offset}`)
      .then((response) => {
        const existingPublisherPrePing = this.renderPublisherPrePings(response);
        const totalPages = response.data.totalPages;
        const total = response.data.totalElements;
        const newState = Object.assign({}, this.state, {
          publisherPrePing: existingPublisherPrePing,
          totalElements: total,
          totalPages,
          loading: true,
        });

        this.setState(newState, () => {
          Promise.all(this.getAdditionalData()).then((responses) => {
            const parsedPublisherPrePings = _.flatten(
              responses.map((response) => {
                return this.renderPublisherPrePings(response);
              })
            );

            this.setState((prevState) => {
              let totalSortedPublisherPrePings = [...prevState.publisherPrePing, ...parsedPublisherPrePings];
              return {
                publisherPrePing: totalSortedPublisherPrePings,
                loading: false,
                render: true,
              };
            });
          });
        });
      })
      .catch((error) => console.log(error));
  }

  updateData(page = this.state.page, limit = this.state.sizePerPage) {
    let offset = page - 1;
    let existingPublisherPrePings = this.state.publisherPrePing;
    api
      .get(`publisher-pre-pings?limit=${limit}&offset=${offset}`)
      .then((response) => {
        const newPrePings = this.renderPublisherPrePings(response);
        const first = response.data.pageable.offset;
        const num = response.data.numberOfElements;
        for (let i = first, j = 0; j < num; i++, j++) {
          existingPublisherPrePings[i] = newPrePings[j];
        }

        const newState = Object.assign({}, this.state, {
          publisherPrePing: existingPublisherPrePings,
        });

        this.setState(newState);
      })
      .catch((error) => console.log(error));
  }

  componentDidMount() {
    this.getData();
  }

  filterPublisherPrePing = (searchTerm, unfilteredPublisherPrePing) => {
    // Split the term by space and remove empty spaces
    let splitSearchTerm = searchTerm.split(/[ ,]+/).filter(Boolean);
    const filteredPublisherPrePing = unfilteredPublisherPrePing.filter((prePing) => {
      const parsedPrePing = {
        id: prePing.id + '' || '',
        name: prePing.name || '',
        vanityUrl: prePing.vanityUrl || '',
      };
      let doesMatchTerms = true;
      for (let i = 0; i < splitSearchTerm.length; i++) {
        if (
          !Object.keys(parsedPrePing).some((key) =>
            parsedPrePing[key].toLowerCase().includes(splitSearchTerm[i].toLowerCase())
          )
        ) {
          doesMatchTerms = false;
        }
      }
      return doesMatchTerms;
    });
    this.setState({ filteredPublisherPrePing });
  };

  handleInputChange = (event) => {
    const searchTerm = event.target.value;
    // This prevents the user from calling the search function too many times in less than a second, helps with performance
    const throttledSearch = _.throttle(this.filterPublisherPrePing, 500);
    this.setState({ searchTerm });
    return throttledSearch(searchTerm, this.state.publisherPrePing);
  };

  updateStatus = (row, activeStatus, endpoint) => {
    console.log(row);
    if (row.id) {
      let saveData = {
        id: row.id,
        active: activeStatus,
      };
      api.put(`${endpoint}/active/${row.id}`, saveData).then((result) => {
        console.log(JSON.stringify(result));
      });
    }
  };

  activeStatusSwitch = (row, entity) => {
    return (
      <ActiveStatusModal
        content={row}
        isActive={row.active}
        modalType={entity.modalType}
        getErrors={() => this.state.errors || null}
        onClickSubmit={(activeStatus) => this.updateStatus(row, activeStatus, entity.endpoint)}
      />
    );
  };

  actionFormatter = (cell, row) => {
    return (
      <Row>
        <Col lg={12}>
          {this.activeStatusSwitch(row, {
            modalType: ' Publisher Preping',
            endpoint: 'publisher-pre-pings',
          })}
        </Col>
      </Row>
    );
  };

  render() {
    const URL = '/publisher-pre-pings/';

    const columns = [
      {
        dataField: 'id',
        text: 'ID',
        formatter: (cell, row) => {
          return columnFormatter({
            cell,
            row,
            computedURL: URL + row.id,
          });
        },
        sort: true,
      },
      {
        dataField: 'name',
        text: 'Publisher Pre-Ping',
        formatter: (cell, row) => {
          return columnFormatter({
            cell,
            row,
            computedURL: URL + row.id,
          });
        },
        sort: true,
        sortCaret: (order) => {
          if (!order)
            return (
              <span>
                &nbsp;&nbsp;&nbsp;
                <i className='fa fa-sort' />
              </span>
            );
          else if (order === 'asc')
            return (
              <span>
                &nbsp;&nbsp;
                <i className='fa fa-sort' />
              </span>
            );
          else if (order === 'desc')
            return (
              <span>
                &nbsp;&nbsp;
                <i className='fa fa-sort' />
              </span>
            );
          return null;
        },
      },
      {
        dataField: 'vanityUrl',
        text: 'Vanity Path',
        formatter: (cell, row) => {
          return columnFormatter({
            cell,
            row,
            computedURL: URL + row.id,
          });
        },
        sort: true,
        sortCaret: (order) => {
          if (!order)
            return (
              <span>
                &nbsp;&nbsp;&nbsp;
                <i className='fa fa-sort' />
              </span>
            );
          else if (order === 'asc')
            return (
              <span>
                &nbsp;&nbsp;
                <i className='fa fa-sort' />
              </span>
            );
          else if (order === 'desc')
            return (
              <span>
                &nbsp;&nbsp;
                <i className='fa fa-sort' />
              </span>
            );
          return null;
        },
      },
      {
        dataField: 'active',
        text: 'Active',
        // This formatter is different from the other column formatter implementations because the endpoint to update the status doesn't follow the same pattern as well as the active vs. isActive boolean field
        formatter: this.actionFormatter,
        sort: true,
        headerClasses: 'active-status',
        sortCaret: (order) => {
          if (!order)
            return (
              <span>
                &nbsp;&nbsp;&nbsp;
                <i className='fa fa-sort' />
              </span>
            );
          else if (order === 'asc')
            return (
              <span>
                &nbsp;&nbsp;
                <i className='fa fa-sort' />
              </span>
            );
          else if (order === 'desc')
            return (
              <span>
                &nbsp;&nbsp;
                <i className='fa fa-sort' />
              </span>
            );
          return null;
        },
      },
    ];

    return (
      <div>
        <div className='animated fadeIn'>
          <Card>
            <CardHeader>
              <i className='icon-menu' />
              Publisher Pre-Ping
            </CardHeader>
            <CardBody>
              <Row>
                <Col lg={6}>
                  <span className='display-4'>Publisher Pre-Ping</span>
                </Col>
                <Col lg={6}>
                  <Link to={'/publisher-pre-pings/create'}>
                    <Button color='primary' className='float-right'>
                      <i className='fa fa=plus' />
                      &nbsp; Create New Publisher Pre-Ping
                    </Button>
                  </Link>
                </Col>
              </Row>
              <br />
              <PublisherPrePingTable
                entity={{
                  entityName: 'Publisher Pre-Ping',
                  columns,
                  data:
                    this.state.searchTerm.length > 0
                      ? this.state.filteredPublisherPrePing
                      : this.state.publisherPrePing,
                }}
                handleSearchChange={this.handleInputChange}
                loading={this.state.loading}
                searchTerm={this.state.searchTerm}
              />
            </CardBody>
          </Card>
        </div>
      </div>
    );
  }
}

export default PublisherPrePings;
