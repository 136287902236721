import { Card, CardBody, CardHeader, Col, Collapse, Row } from 'reactstrap';
import { FieldArray } from 'redux-form';
import OfferActionsForm from '../../OfferActions/OfferActionsForm/OfferActionsForm';
import { renderLandingPageTriggerTypes } from '../../Offers/OfferTitle/renderConsts';
import React, { useState } from 'react';

export function LPActionsCard(props) {
  const [collapsed, setCollapsed] = useState(false);
  return (
    <Card>
      <CardHeader>
        <Row>
          <Col onClick={() => setCollapsed(!collapsed)} id='click' lg={10}>
            {collapsed ? (
              <div>
                <h4>
                  Actions&nbsp;&nbsp; <i className='fa fa-chevron-up opened' />
                </h4>
              </div>
            ) : (
              <div>
                <h4>
                  Actions&nbsp;&nbsp; <i className='fa fa-chevron-down closed' />
                </h4>
              </div>
            )}
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <Collapse isOpen={collapsed}>
          <Col lg={12}>
            <FieldArray
              name='landingPageActions'
              component={OfferActionsForm}
              entityName={'Landing Page'}
              triggerTypes={renderLandingPageTriggerTypes}
              shouldShowSuppression={false}
            />
          </Col>
        </Collapse>
      </CardBody>
    </Card>
  );
}
