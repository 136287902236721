import { validateCriteria } from '../../../utils/CriteriaHelpers';

const validate = (values) => {
  if (!values || !values.offer) return;
  const errors = {
    offer: {},
  };

  if (!values.offer.name || values.offer.name === '') {
    errors.offer.name = 'Name is required.';
  }

  errors.offer = { ...errors.offer, ...validateCriteria(values.offer) };
  return errors;
};

export default validate;
