import React from 'react';
import {
  MultiselectSummary,
  MultiselectSummaryRow,
  SelectInput,
  SelectRow,
  SelectSummary,
  SwitchRow,
} from './SummaryPaneComponents';
import { Col, Input, Label, Row } from 'reactstrap';
import { useUserSelectors } from '../hooks';

const UserSummary = () => {
  const userSelectors = useUserSelectors();

  return (
    <>
      {userSelectors.isAnySelectorNotNullOrEmpty && (
        <>
          <h4>Users Summary</h4>
          <SwitchRow label='TCPA Opt-in Required' value={userSelectors.criteriaOfferRequiresOptIn} />
          <SwitchRow
            label='TCPA Opt-in Required Over Last Interval'
            value={userSelectors.criteriaTcpaLastOptInInterval}
          />
          <SwitchRow
            label='Brite Verify Phone Validated'
            value={userSelectors.criteriaPhoneHasBeenVerifiedWithBriteverify}
          />
          <SwitchRow
            label='Brite Verify Email Validated'
            value={userSelectors.criteriaEmailHasBeenVerifiedWithBriteverify}
          />
          <SwitchRow label='Include users in the phone BlackList' value={userSelectors.criteriaPhoneBlacklist} />
          <Row>
            {userSelectors.criteriaMinAge && (
              <Col xs={2}>
                <Label>Min Age</Label>
                <Input xs={1} readOnly disabled value={userSelectors.criteriaMinAge} />
              </Col>
            )}
            {userSelectors.criteriaMaxAge && (
              <Col xs={2}>
                <Label>Max Age</Label>
                <Input xs={1} readOnly disabled value={userSelectors.criteriaMaxAge} />
              </Col>
            )}
          </Row>
          <SelectSummary
            value={userSelectors.browserType}
            label='Exclude Browser Type'
            options={[
              { value: '', option: 'Select' },
              { value: 'Desktop', option: 'Desktop' },
              { value: 'Mobile', option: 'Mobile' },
            ]}
          />
          <SelectSummary
            value={userSelectors.criteriaExcludeGender}
            label='Exclude Gender'
            options={[
              { value: '', option: 'Select' },
              { value: 'female', option: 'Female' },
              { value: 'male', option: 'Male' },
            ]}
          />
          {userSelectors.criteriaUserDataIncludeExclude && (
            <Row className='row-margin-top'>
              <Col xs={2}>
                <h5>Required User Data</h5>
              </Col>
              <Col xs={2}>
                <SelectInput value={userSelectors.criteriaUserDataIncludeExclude} />
              </Col>
              <Col xs={8}>
                <MultiselectSummary value={userSelectors.criteriaRequiredUserData} />
              </Col>
            </Row>
          )}
          {userSelectors.criteriaAibListIncludeExclude && (
            <Row className='row-margin-top'>
              <Col xs={2}>
                <h5>AIB List</h5>
              </Col>
              <Col xs={2}>
                <SelectInput value={userSelectors.criteriaAibListIncludeExclude} />
              </Col>
              <Col xs={8}>
                <MultiselectSummary value={userSelectors.criteriaAibList} />
              </Col>
            </Row>
          )}
          {userSelectors.previousRegistrationConfiguration?.andOrLogic && (
            <Row>
              <Col xs={2}>
                <h5>Previous Registration Required</h5>
              </Col>
              <Col xs={2}>
                <Input
                  value={
                    userSelectors.previousRegistrationConfiguration?.andOrLogic === 'AND'
                      ? 'All entries must pass'
                      : 'Any of these entries can pass'
                  }
                  readOnly
                  disabled
                />
              </Col>
              <Col>
                {userSelectors.previousRegistrationConfiguration?.previousRegistrations?.map((item, index) => (
                  <Row key={index}>
                    <Col xs={3}>
                      <Input value={item?.includeExcludeAction} readOnly disabled />
                    </Col>
                    <Col xs={3}>
                      <MultiselectSummary value={item?.domains} />
                    </Col>
                    <Col xs={2}>
                      <Input value={item?.previousRegistrationsMin} readOnly disabled />
                    </Col>
                    <Col xs={2}>
                      <Input value={item?.previousRegistrationsMax} readOnly disabled />
                    </Col>
                  </Row>
                ))}
              </Col>
            </Row>
          )}
          <SelectSummary value={userSelectors.criteriaNullSourceNewUserIncludeExclude} label='Null Source New Users' />
          {userSelectors.userIdLastDigitIncludeExclude && (
            <SelectRow label='User_ID last digit' value={userSelectors.userIdLastDigitIncludeExclude}>
              <Col xs='4'>
                <MultiselectSummary value={userSelectors.criteriaUserIdLastDigit} />
              </Col>
            </SelectRow>
          )}
          {userSelectors.criteriaIncludeOrExcludeDevices && (
            <SelectRow label='Device' value={userSelectors.criteriaIncludeOrExcludeDevices}>
              <Col xs='4'>
                <MultiselectSummary value={userSelectors.criteriaDevices} />
              </Col>
            </SelectRow>
          )}
          {userSelectors.criteriaIncludeOrExcludeBrowsers && (
            <SelectRow label='Browsers' value={userSelectors.criteriaIncludeOrExcludeBrowsers}>
              <Col xs='4'>
                <MultiselectSummary value={userSelectors.criteriaBrowsers} />
              </Col>
            </SelectRow>
          )}
          {userSelectors.criteriaIncludeOrExcludeDomains && (
            <SelectRow label='What If Domain' value={userSelectors.criteriaIncludeOrExcludeDomains}>
              <Col xs='4'>
                <MultiselectSummary value={userSelectors.criteriaDomains} />
              </Col>
            </SelectRow>
          )}
          {userSelectors.criteriaIncludeOrExcludeOperatingSystems && (
            <SelectRow label='Operating System' value={userSelectors.criteriaIncludeOrExcludeOperatingSystems}>
              {(userSelectors.criteriaIncludeOrExcludeOperatingSystems === 'Include' ||
                userSelectors.criteriaIncludeOrExcludeOperatingSystems === 'Exclude') && (
                <Col xs='4'>
                  <MultiselectSummary value={userSelectors.criteriaOperatingSystems} />
                </Col>
              )}
            </SelectRow>
          )}
          {userSelectors.criteriaIncludeOrExcludeStates && (
            <SelectRow label='States' value={userSelectors.criteriaIncludeOrExcludeStates}>
              {(userSelectors.criteriaIncludeOrExcludeStates === 'Include' ||
                userSelectors.criteriaIncludeOrExcludeStates === 'Exclude') && (
                <Col xs='4'>
                  <MultiselectSummary value={userSelectors.criteriaStates} />
                </Col>
              )}
            </SelectRow>
          )}
          {userSelectors.criteriaIncludeOrExcludeZips && (
            <SelectRow label='Zip Code' value={userSelectors.criteriaIncludeOrExcludeZips}>
              {(userSelectors.criteriaIncludeOrExcludeZips === 'Include' ||
                userSelectors.criteriaIncludeOrExcludeZips === 'Exclude') && (
                <>
                  <Col xs='4'>
                    <Input type='textarea' value={userSelectors.criteriaZips} disabled />
                  </Col>
                  <Col lg='2'>
                    Count:{' '}
                    {
                      userSelectors.criteriaZips?.split(/\s+/).filter((zip) => zip.trim().length === 5 && !isNaN(zip))
                        .length
                    }
                  </Col>
                </>
              )}
            </SelectRow>
          )}
        </>
      )}
    </>
  );
};

export default UserSummary;
