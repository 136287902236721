import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Label, Row } from 'reactstrap';
import { change, Field, FieldArray } from 'redux-form';
import {
  CapIntervals,
  renderActionSelect,
  RenderCapSegmentConfiguration,
  renderComboBox,
  renderField,
} from '../OfferTitle/renderConsts';
import { validateWholeNums, validateWholeNumsWithDecimals } from '../OfferTitle/valildation';
import CurrentCapAmount from './CurrentCapAmount';

export default function Linkout(props) {
  const dispatch = useDispatch();
  const { capAmount, capSegmentConfiguration, payoutType } = useSelector((state) => {
    const { selector } = state.offerAction;
    const capAmount = state?.form?.offerActionDetail?.values?.capAmount;
    const capSegmentConfiguration = state.form.offerActionDetail?.values?.capSegmentConfiguration || [];
    return {
      selector,
      capAmount,
      capSegmentConfiguration,
      payoutType: state?.form?.offerActionDetail?.values?.payoutType,
      isEditMode: state?.form?.offerActionDetail?.isEditMode,
    };
  });

  const handlePayoutChange = (payoutType) => {
    dispatch(change('offerActionDetail', 'payoutAmount', ''));
  };

  return (
    <div className='colorTest'>
      <br />
      <Row>
        <Col lg={4}>
          <Field
            required
            type='text'
            component={renderField}
            id='title'
            name='title'
            onChange={props.handleTitleChange}
            placeholder='Linkout Name'
            label='Linkout Name'
          />
        </Col>
        {!props.isEditMode && renderActionSelect(2)}
        <Col lg={4}>
          <Label>Advertiser</Label>
          <Field
            type='select'
            name='advertiser'
            component={renderComboBox}
            data={props.advertisers}
            textField='name'
            valueField='id'
          />
        </Col>
      </Row>
      <br />
      <Row>
        <Col lg={8}>
          <Field required type='text' component={renderField} id='url' name='url' label='URL' />
        </Col>
      </Row>
      <br />
      <h4>Payout Scheme and Cap</h4>
      <div>
        <Row>
          <Col lg={2}>
            <Label>Payout Type</Label>
            <Field name='payoutType' component='select' className='form-control' onChange={handlePayoutChange}>
              <option value={null}>No Payout Scheme</option>
              <option value={4}>Rev Share</option>
              <option value={5}>Advertiser CPC</option>
              <option value={2}>CPC</option>
            </Field>
          </Col>
          {payoutType && payoutType !== 4 && payoutType !== '4' && payoutType !== 'No Payout Scheme' && (
            <Col lg={2}>
              <Field
                name='payoutAmount'
                component={renderField}
                type='number'
                label='Payout Amount'
                normalize={validateWholeNumsWithDecimals}
              />
            </Col>
          )}
        </Row>
        <br />
        <Row>
          <Col>
            <Label>Cap Interval</Label>
            <Field name='capInterval' type='select' component={CapIntervals} />
          </Col>
          <Col>
            <Field
              name='capAmount'
              component={renderField}
              type='number'
              label='Cap Amount'
              normalize={validateWholeNums}
            />
          </Col>
          <Col>
            <CurrentCapAmount id={props.offerCriteriaId} />
          </Col>
        </Row>
        <FieldArray
          name='capSegmentConfiguration.capSegments'
          component={RenderCapSegmentConfiguration}
          domains={props?.domains}
          capAmount={capAmount}
          capSegmentConfiguration={capSegmentConfiguration}
          offerCriteriaId={props.offerCriteriaId}
        />
      </div>
      <br />
    </div>
  );
}
