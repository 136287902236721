const validate = (values) => {
  console.log('validate', values);
  const errors = {};
  const defaultLayoutFieldNames = [
    'defaultFormLayout',
    'defaultBannerLayout',
    'defaultQuestionLayout',
    'defaultOfferWallLayout',
    'defaultJavascriptLayout',
    'defaultIframeLayout',
  ];
  const defaultContainerFieldNames = [
    'defaultFormContainer',
    'defaultBannerContainer',
    'defaultQuestionContainer',
    'defaultOfferWallContainer',
    'defaultJavascriptContainer',
    'defaultIframeContainer',
  ];
  // Error Checks
  if (!values.sequenceName) {
    errors.sequenceName = 'Please add a sequence name.';
  }

  if (!values.site || typeof values.site !== 'object') {
    errors.site = 'Please select a site.';
  }

  if (!values.flex || values.flex < 1) {
    errors.flex = 'Please select a flex greater than 0.';
  }

  if (!values.returnVisits || values.returnVisits < 1) {
    errors.returnVisits = 'Please enter a value greater than 0';
  }

  defaultLayoutFieldNames.forEach((fieldName) => {
    if (!values[fieldName] || typeof values[fieldName] !== 'object') {
      errors[fieldName] = 'Please select a default layout.';
    }
  });

  defaultContainerFieldNames.forEach((fieldName) => {
    if (!values[fieldName] || typeof values[fieldName] !== 'object') {
      errors[fieldName] = 'Please select a default container.';
    }
  });

  if (values.customVariables) {
    errors.customVariables = [];
    values.customVariables.forEach((customVariable, index) => {
      if (customVariable.key === 'SUD_' || customVariable.key === '') {
        errors.customVariables[index] = { key: 'Please enter a key.' };
      }
    });
  }
  return errors;
};

export default validate;
