const ALT_QUESTION_TAG_NAME = 'tas-question-aggregate'

/**
 * Takes tags and returns a function that takes an error obj and produces either the passed in error or a new error
 * object with a new error message if the tags have more than one question aggregate tag. This is to prevent the
 * user from adding more than one question aggregate tag that would not be picked up in the TAS Content site.
 *
 * Error: {
 *   hasTagError: Bool,
 *   _error: String,
 *   invalid: Bool
 * }
 *
 * hasTagError tells SaveOfferModal to show an error
 * _error is the error message. ReduxForms picks up on this.
 * invalid tells SaveOfferModal if an error has occurred
 *
 * @param tags: Tags[]
 * @returns error: Error => Error
 */
const noMoreThanOneQuestionAggregateTag = tags => error => {
  if(error.hasTagError) {
    return error
  }

  const questionAggregateTags = tags && tags.filter(tag => tag.type.name === ALT_QUESTION_TAG_NAME)
  if(questionAggregateTags.length > 1) {
    return { hasTagError: true, _error: 'Cannot have more than one question aggregate tag.', invalid: true }
  }

  return error
}

const mustHaveAdvertiser = advertiser => error => {
  if(error.isAdvertiserMissing) {
    return error
  }

  if(!advertiser) {
    return { isAdvertiserMissing: true, _error: 'This offer is missing an advertiser.', invalid: true }
  }

  return error
}

export {
  noMoreThanOneQuestionAggregateTag,
  mustHaveAdvertiser
}
