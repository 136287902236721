import { combineReducers } from 'redux';
import { reducer as reduxFormReducer } from 'redux-form';
import reducer from './account';
import ComponentsReducer from './ComponentsReducer';
import OfferWallReducer from './OfferWallReducer';
import OfferTitleReducer from './OfferTitleReducer';
import CriteriaReducer from './CriteriaReducer';
import OfferActionReducer from './OfferActionReducer';
import AdvertisersReducer from './AdvertisersReducer';
import TagsReducer from './TagsReducer';
import OfferWallAdReducer from '../views/OfferWallAds/actions/reducers';
import LandingPageReducer from '../views/LandingPages/actions/reducers';
import LandingPageGroupReducer from '../views/LandingPageGroups/actions/reducers';
import AdvertiserReducer from '../views/Advertisers/actions/reducers';
import MetadataReducer from '../views/Metadata/actions/reducers';
import FormSelectorReducer from './FormSelectorReducer';
import AdvertiserPrePingReducer from '../views/AdvPrePing/actions/reducers';
import SequenceReducer from '../views/Sequences/actions/reducers';

const rootReducer = combineReducers({
  account: reducer,
  form: reduxFormReducer,
  advertiser: AdvertiserReducer,
  advertisers: AdvertisersReducer,
  components: ComponentsReducer,
  landingPage: LandingPageReducer,
  landingPageGroup: LandingPageGroupReducer,
  offerWall: OfferWallReducer,
  offerState: OfferTitleReducer,
  offerAction: OfferActionReducer,
  offerWallAd: OfferWallAdReducer,
  advertiserPrePing: AdvertiserPrePingReducer,
  criteria: CriteriaReducer,
  metadata: MetadataReducer,
  sequence: SequenceReducer,
  tags: TagsReducer,
  formSelector: FormSelectorReducer,
});

export default rootReducer;
