import { Button, Col, Row } from 'reactstrap';
import { Field, FieldArray } from 'redux-form';
import { renderActionCriteriaAndOr, renderField, renderOfferPostAction } from '../../Offers/OfferTitle/renderConsts';
import React, { Component } from 'react';
import OfferActionsForTrigger from './OfferActionsForTrigger';
import { connect } from 'react-redux';

class OfferActionsByTrigger extends Component {
  render() {
    const {
      entityName,
      offerActionsByTrigger,
      offerTriggerIndex,
      fields,
      triggerTypes,
      curTriggerType,
      shouldShowSuppression,
    } = this.props;

    return (
      <div key={offerTriggerIndex} className='colorTest'>
        <br />
        <Row>
          <Col lg={1} style={{ marginTop: '2em' }}>
            <Button
              color='danger'
              className='removeofferInputParent'
              type='button'
              title='Remove Parent Attribute'
              onClick={() => fields.remove(offerTriggerIndex)}
            >
              <i className='fa fa-close' />
            </Button>
          </Col>
          <Col lg={2}>
            <Field
              name={`${offerActionsByTrigger}.triggerType`}
              type='select'
              component={triggerTypes}
              label='Action happens on:'
            />
          </Col>
          <Col lg={9}>
            <FieldArray
              name={`${offerActionsByTrigger}.actions`}
              component={OfferActionsForTrigger}
              entityName={entityName}
              offerActionsForTrigger={offerActionsByTrigger}
              shouldShowSuppression={shouldShowSuppression}
            />
          </Col>
        </Row>
        {curTriggerType === 'SUBMIT+PARAMS' && (
          <Row>
            <Col lg={1}></Col>
            <Col lg={3}>
              <Field
                name={`${offerActionsByTrigger}.paramName`}
                type='input'
                component={renderField}
                label='Param Name:'
              />
            </Col>

            <Col lg={3}>
              <Field
                name={`${offerActionsByTrigger}.paramValue`}
                type='input'
                component={renderField}
                label='Param Value:'
              />
            </Col>
          </Row>
        )}
        <br />
      </div>
    );
  }
}

function mapStateToProps(state, props) {
  const { selector } = state.formSelector;
  return {
    curTriggerType: selector(state, `${props.offerActionsByTrigger}.triggerType`),
  };
}

export default connect(mapStateToProps)(OfferActionsByTrigger);
