import { useQuery } from '@tanstack/react-query';
import { fetchAll } from '../../services/helpers';

export const useOfferActions = () =>
  useQuery({
    queryKey: ['offerActions'],
    queryFn: () => fetchAll('offer-action'),
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });

export const useOfferActionsTable = () =>
  useQuery({
    queryKey: ['offerActionsTable'],
    queryFn: () => fetchAll('offer-action/table'),
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });
