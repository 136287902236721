import _ from 'lodash/fp';

/**
 * Have to do this to use the old map that uses index
 * https://github.com/lodash/lodash/wiki/FP-Guide#convert
 */
const map = _.map.convert({ cap: false });

/**
 * Creates a new and active question answer ready to be submitted to the api. Meant to be used with _.map()
 */
export const makeQuestionAnswer = (offerQuestion) => (questionAnswer, index) => ({
  answer: questionAnswer.text,
  current: true,
  offerQuestion: offerQuestion,
  orderNumber: index + 1,
  countAsAccepted: questionAnswer.countAsAccepted || false,
});

/**
 * Filters out any duplicate answers if text is equivalent and creates a question answer that's submittable to
 * the api.
 */
export const prepareQuestionAnswersForSubmission = ({ questionAnswers, offerQuestion }) => {
  return _.pipe(_.uniqBy('text'), map(makeQuestionAnswer(offerQuestion)))(questionAnswers);
};

/**
 * Gets offer question answers from a list of offer triggers
 */
export const getExistingQuestionAnswers = (offerTriggers) => {
  const getOfferQuestionAnswer = (offerTrigger) => offerTrigger.offerQuestionAnswer;
  return offerTriggers.filter(getOfferQuestionAnswer).map(getOfferQuestionAnswer);
};

/**
 * Checks if two question answers have answers and are equivalent by answer text.
 */
const eqByAnswer = (qa1) => (qa2) => qa1.answer && qa2.answer && qa1.answer === qa2.answer;

/**
 * Checks if two question answers have answers and are equivalent by answer text and countAsAccepted.
 */

/**
 * Separates new answers (from user input) and existing answers (from the db) into answers that should be created and
 * answers that should be deactivated.
 *
 * This is meant to be used with user input question answers and question answers from the db.
 */
export const partitionToBeCreatedAndDeactivated = ({ newOfferQuestionAnswers, oldOfferQuestionAnswers }) => {
  const isNotNil = _.negate(_.isNil);
  const userAddedAnswer = ({ existingOqa, userInputOqa }) => _.isNil(existingOqa) && isNotNil(userInputOqa);
  const userRemovedAnswer = ({ existingOqa, userInputOqa }) => isNotNil(existingOqa) && _.isNil(userInputOqa);
  const userRearrangedAnswers = (qa1, qa2) => !_.isNil(qa1) && !_.isNil(qa2) && !eqByAnswer(qa1)(qa2);
  const sortByOrderNumber = _.sortBy('orderNumber');
  const zipped = _.zip(sortByOrderNumber(newOfferQuestionAnswers), sortByOrderNumber(oldOfferQuestionAnswers));

  const accumCreateAndDeactivate = (acc, [userInputOqa, existingOqa]) => {
    if (userAddedAnswer({ existingOqa, userInputOqa })) {
      return {
        ...acc,
        toBeCreated: [...acc.toBeCreated, userInputOqa],
      };
    } else if (userRemovedAnswer({ existingOqa, userInputOqa })) {
      return {
        ...acc,
        toBeDeactivated: [...acc.toBeDeactivated, existingOqa],
      };
    } else if (userRearrangedAnswers(existingOqa, userInputOqa)) {
      return {
        toBeCreated: [...acc.toBeCreated, userInputOqa],
        toBeDeactivated: [...acc.toBeDeactivated, existingOqa],
      };
    } else {
      return acc;
    }
  };
  return _.reduce(accumCreateAndDeactivate, { toBeCreated: [], toBeDeactivated: [] })(zipped);
};

export const deactivateAnswer = (answer) => ({ ...answer, current: false });

const activateAnswer = (answer) => ({ ...answer, current: true });

export const updateOfferQuestion = ({ offer, question }) => (answer) => ({
  ...answer,
  offerQuestion: {
    id: answer.offerQuestion.id,
    offer,
    question,
    countAsAccepted: answer.offerQuestion.countAsAccepted,
  },
});

/**
 * Updates offer_question_id and question_id in an offer's content object.
 * Using snake case here for legacy reasons.
 */
const updateOfferQuestionInContent = (questionAnswers) => (content) => {
  return {
    ...content,
    offer_question_id: _.isEmpty(questionAnswers) ? null : questionAnswers[0].offerQuestion.id,
    question_id: _.isEmpty(questionAnswers) ? null : questionAnswers[0].offerQuestion.question.id,
  };
};

/**
 * Updates offer_question_answer_id in each answer of the content's question_answers in an offer's content object.
 * Using snake case here for legacy reasons.
 */
const updateQuestionAnswersInContent = (questionAnswers) => (content) => {
  const setOfferQuestionAnswerId = (answer) => {
    const newQuestionAnswer = _.find((qAns) => qAns.answer === answer.text, questionAnswers);
    return { ...answer, offer_question_answer_id: newQuestionAnswer ? newQuestionAnswer.id : null };
  };

  return { ...content, question_answers: _.map(setOfferQuestionAnswerId)(content.question_answers) };
};

/**
 * Updates the offer question and question answer ids in an offer's content object.
 * Using snake case here for legacy reasons.
 */
export const updateOfferQuestionAndQuestionAnswersInContent = ({ questionAnswers = [], content }) => {
  return _.pipe(
    updateOfferQuestionInContent(questionAnswers),
    updateQuestionAnswersInContent(questionAnswers)
  )(content);
};

/**
 * Creates offer triggers for offer actions that have an id and are equal by answer to the question answer provided
 */
export const createOfferActionTriggers = ({ questionAnswer, offerActions }) => {
  const idIsNotNil = ({ id }) => !_.isNil(id);
  const eqByAnswer = (questionAnswer) => (offerAction) => offerAction.answer === questionAnswer.answer;
  const makeOfferActionTrigger = (offerAction) => ({
    offerAction: { id: parseInt(offerAction.id) },
    offerActionType: offerAction.actionType,
    showOfferByOfferActionCriteria: offerAction.showOfferByOfferActionCriteria,
  });

  return _.pipe(
    _.filter(idIsNotNil),
    _.filter(eqByAnswer(questionAnswer)),
    _.map(makeOfferActionTrigger)
  )(offerActions);
};

/**
 * Separates offer answers into ones to reactivate and ones to create triggers for.
 *
 * This is a fix for the possibility of an offer trigger having answers with current = false. Any answer tied to
 * an offer trigger should have current = true. At some point, answer.current for answers attached to offer triggers
 * are being set to or left as-is when they should have been true.
 */
export const partitionToReactivateOrCreateTriggersFor = ({
  deactivatedQuestionAnswers,
  addedQuestionAnswers,
  existingOfferQuestionAnswers,
}) => {
  const needsReactivation = ({ deactivatedQa, existingQa }) => _.isNil(deactivatedQa) && !existingQa.current;
  const needsTriggerCreated = (deactivatedQa) => _.isNil(deactivatedQa);
  const reducer = (acc, existingQa) => {
    const deactivatedQa = _.find(eqByAnswer(existingQa), deactivatedQuestionAnswers);

    if (needsReactivation({ deactivatedQa, existingQa })) {
      return { ...acc, toReactivate: [...acc.toReactivate, activateAnswer(existingQa)] };
    } else if (needsTriggerCreated(deactivatedQa)) {
      return { ...acc, toCreateTriggersFor: [...acc.toCreateTriggersFor, existingQa] };
    } else {
      return acc;
    }
  };
  const initAccum = { toReactivate: [], toCreateTriggersFor: addedQuestionAnswers };

  return _.reduce(reducer, initAccum)(existingOfferQuestionAnswers);
};

const activateOffer = (data) => ({ ...data, isActive: true });

/**
 * Parses the content in data, nulls out the offer question id and question id, and adds it back to data as a string.
 * Returns the data. Data is likely to be user input from the offer form.
 */
const clearQuestionFromContent = (data) => {
  if (data.content) {
    const oldContent = JSON.parse(data.content);
    const newContent = { ...oldContent, offer_question_id: null, question_id: null };
    return { ...data, content: JSON.stringify(newContent) };
  } else {
    return data;
  }
};

const setNewOfferName = (newOfferName) => (data) => {
  return { ...data, name: newOfferName };
};

/**
 * Decides which function to use based on if a new offer name is present.
 */
export const prepareOfferToCopy = ({ formData, newOfferName }) => {
  const saveFn = newOfferName
    ? _.flow(activateOffer, setNewOfferName(newOfferName), clearQuestionFromContent)
    : activateOffer;

  return saveFn(formData);
};

export const getActionStatus = (offerActionTrigger) => {
  const offerAction = offerActionTrigger ? offerActionTrigger.offerAction : null;
  let actionStatus = offerAction ? offerAction.active : false;
  return actionStatus;
};

export const getActionAdvertiser = (offerActionTrigger) => {
  const offerAction = offerActionTrigger ? offerActionTrigger.offerAction : null;
  let advertiserName = '';
  if (offerAction && offerAction.offerActionLinkout) {
    advertiserName = offerAction.offerActionLinkout.advertiser && offerAction.offerActionLinkout.advertiser.name;
  } else if (offerAction && offerAction.offerActionPost) {
    advertiserName = offerAction.offerActionPost.advertiser && offerAction.offerActionPost.advertiser.name;
  }
  return advertiserName;
};

/**
 * Initializes offer-level triggers that aren't of type on_answer (Question and Form offers are the only entities that have both types).
 */

export const initializeOfferTriggers = (offerTriggers) => {
  let offerActions = [];
  offerTriggers.forEach((offerTrigger) => {
    if (offerTrigger.triggerType && offerTrigger.triggerType.name !== 'ON_ANSWER') {
      console.log(offerTrigger);
      // Each offer level trigger will only have one offerActionTrigger[0] at the 0th index
      const offerActionTrigger = offerTrigger.offerActionTriggers[0];
      offerActions.push({
        post_type: offerTrigger.triggerType && offerTrigger.triggerType.name,
        name: offerActionTrigger && offerActionTrigger.offerAction.title,
        question_actions: offerActionTrigger && offerActionTrigger.offerAction.offerActionType,
        action_advertiser: getActionAdvertiser(offerActionTrigger),
        action_id: offerActionTrigger && offerActionTrigger.offerAction.id,
        actionStatus: getActionStatus(offerActionTrigger),
        shouldShowCriteria: offerActionTrigger && offerActionTrigger.showOfferByOfferActionCriteria,
        param_name: offerTrigger.paramName || null,
        param_value: offerTrigger.paramValue || null,
      });
    }
  });
  return offerActions;
};
