import { validateCriteria } from '../../utils/CriteriaHelpers';

const validate = (values, props) => {
  const errors = {};
  if (!values) return errors;
  // OfferActions
  if (values.hasOwnProperty('offerActionType')) {
    if (!values.title) {
      errors.title = 'Please enter a title for this Offer Action';
    }
    if (values.offerActionType === 'POST_WATERFALL') {
      errors.offerActionPostWaterfallEntries = [];
      const { offerActionPostWaterfallEntries } = values;
      if (offerActionPostWaterfallEntries && Array.isArray(offerActionPostWaterfallEntries)) {
        const shouldRenderLimitError = offerActionPostWaterfallEntries.length > 20;
        offerActionPostWaterfallEntries.forEach((postWaterfallEntry, index) => {
          const currentPWEntry = {};
          errors.offerActionPostWaterfallEntries[index] = currentPWEntry;
          if (!postWaterfallEntry.flex) {
            currentPWEntry.flex = 'Please select a flex for this post.';
          }
          if (!postWaterfallEntry.offerActionPost) {
            currentPWEntry.offerActionPost = 'Please select a post.';
          }
          if (shouldRenderLimitError) {
            currentPWEntry.offerActionPost = `Number of posts exceeded. ${
              offerActionPostWaterfallEntries.length
            }/20 Please remove ${offerActionPostWaterfallEntries.length - 20} to continue.`;
          }
        });
      }
    }

    if (values.offerActionType === 'LINKOUT_WATERFALL') {
      errors.offerActionLinkoutWaterfallEntries = [];
      const { offerActionLinkoutWaterfallEntries } = values;
      if (offerActionLinkoutWaterfallEntries && Array.isArray(offerActionLinkoutWaterfallEntries)) {
        const shouldRenderLimitError = offerActionLinkoutWaterfallEntries.length > 20;
        offerActionLinkoutWaterfallEntries.forEach((linkoutWaterfallEntry, index) => {
          const currentLWEntry = {};
          errors.offerActionLinkoutWaterfallEntries[index] = currentLWEntry;
          if (!linkoutWaterfallEntry.flex) {
            currentLWEntry.flex = 'Please select a flex for this linkout.';
          }
          if (
            !linkoutWaterfallEntry.offerActionLinkout ||
            typeof linkoutWaterfallEntry.offerActionLinkout === 'string'
          ) {
            currentLWEntry.offerActionLinkout = 'Please select a linkout.';
          }
          if (shouldRenderLimitError) {
            currentLWEntry.offerActionLinkout = `Number of linkouts exceeded. ${
              offerActionLinkoutWaterfallEntries.length
            }/20 Please remove ${offerActionLinkoutWaterfallEntries.length - 20} to continue.`;
          }
        });
      }
    }

    if (values.offerActionType === 'POST') {
      if (!values.advertiser) {
        errors.advertiser = 'Please add an Advertiser';
      }
      if (
        values.delayedPostingType === 'days' ||
        values.delayedPostingType === 'minutes' ||
        values.delayedPostingType === 'hours'
      ) {
        if (!values.delayedPostingAmount) {
          errors.delayedPostingAmount = 'Please enter an amount.';
        }
      }
      if (!values.delayedPostingType || values.delayedPostingType === 'none') {
        if (values.delayedPostingAmount > 1) {
          errors.delayedPostingAmount = 'Please delete amount or enter an interval';
        }
      }

      if (values.isTcpaCompliant && (values.marketingPartners == null || values.marketingPartners.length === 0)) {
        errors.marketingPartners = 'Please select at least one marketing partner.';
      }
    }
    if (values.offerActionType === 'USER_ATTRIBUTE') {
      if (!values.attributeName) {
        errors.attributeName = 'Please add an attribute.';
      }
    }
  }

  // Offer Question
  if (
    values.criteriaDailyLimitAnswers &&
    values.criteriaDailyLimitAnswers !== null &&
    values.criteriaDailyLimitAnswers.length > 0
  ) {
    const { criteriaDailyLimitAnswers = [] } = values;
    const currentAnswers = values.questionAnswers
      ? values.questionAnswers
          .map((questionAnswer) => {
            if (questionAnswer && questionAnswer.text) {
              return questionAnswer.text;
            }
            return null;
          })
          .filter((answer) => answer !== null)
      : [];
    criteriaDailyLimitAnswers.forEach((answer) => {
      if (!currentAnswers.includes(answer)) {
        errors.criteriaDailyLimitAnswers = 'One or more of these answers are no longer associated with this offer.';
      }
    });
  }

  //Necessary for TinyMCE doesn't hurt to leave it on in the meantime
  if (!values.questionText || values.questionText === '') {
    errors.questionText = 'Please enter a question.';
  }

  // Offer Wall

  if (values.offerSlots && Array.isArray(values.offerSlots)) {
    errors.offerSlots = [];
    // console.log(values.offerSlots);
    values.offerSlots.forEach((offerSlot, i) => {
      errors.offerSlots[i] = [];
      (offerSlot || []).forEach((slot, j) => {
        errors.offerSlots[i][j] = {};
        if (!slot.slot_ad || typeof slot.slot_ad === 'string') {
          errors.offerSlots[i][j].slot_ad = 'Please select an offer wall ad for this slot.';
        }
        if (!slot.flex) {
          errors.offerSlots[i][j].flex = 'Please select a flex for this ad slot.';
        }
      });
    });
  }

  if (values.hasOwnProperty('displayedSlots') && (values.displayedSlots < 1 || values.displayedSlots > 12)) {
    errors.displayedSlots = 'Displayed slot value must be between 1 and 12.';
  }

  if (values.customVariables) {
    errors.customVariables = [];
    values.customVariables.forEach((customVariable, index) => {
      if (customVariable.key === 'UD_') {
        errors.customVariables[index] = { key: 'Please enter a key.' };
      }
    });
  }

  let criteriaErrors = validateCriteria(values);
  return { ...errors, ...criteriaErrors };
};

export default validate;
