import React, { useState } from 'react';
import Switch from 'react-switch';
import { Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { useUpdateStatus } from '../../../../utils/queries';

const ToggleActiveModal = ({ isActive: initialIsActive, modalType, content, endpoint }) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [isActiveStatus, setActiveStatus] = useState(initialIsActive);
  const updateStatusMutation = useUpdateStatus(endpoint);

  const toggleActive = () => setActiveStatus((prevState) => !prevState);

  const handleSave = async () => {
    try {
      const saveData = {
        id: content.id,
        isActive: isActiveStatus,
        zuulUser: JSON.parse(localStorage.getItem('userData')),
      };

      await updateStatusMutation.mutate(saveData);
      setModalOpen(false);
    } catch (error) {
      console.error('Error updating status:', error);
    }
  };

  const handleCancel = () => {
    setModalOpen(false);
    setActiveStatus(initialIsActive);
  };

  const ToggleLabel = ({ children }) => (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '96%', fontSize: 11 }}>
      {children}
    </div>
  );

  return (
    <div className='animated'>
      <Switch
        data-testid='toggle-switch'
        onChange={() => {
          toggleActive();
          setModalOpen(true);
        }}
        checked={isActiveStatus}
        onColor='#4dbd74'
        offColor='#f86c6b'
        width={70}
        checkedIcon={<ToggleLabel>Active</ToggleLabel>}
        uncheckedIcon={<ToggleLabel>Inactive</ToggleLabel>}
      />
      <Modal isOpen={isModalOpen} toggle={() => setModalOpen(false)} className='modal-danger'>
        <ModalHeader toggle={() => setModalOpen(false)}>Active Status</ModalHeader>
        <ModalBody>
          <Row>
            <Col lg='auto' style={{ display: 'flex', alignItems: 'center', color: '#f86c6b' }}>
              <i className='fa fa-warning fa-3x' />
            </Col>
            <Col>
              <h3>{content?.name}</h3>
              Are you sure you want to {!isActiveStatus ? 'deactivate ' : 'activate '} this {modalType}?
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color='danger' onClick={handleSave}>
            {!isActiveStatus ? 'Deactivate ' : 'Activate '}
          </Button>
          <Button color='secondary' onClick={handleCancel}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ToggleActiveModal;
