import { GET_OFFER_WALL_AD, UPDATE_OFFER_WALL_AD, CREATE_OFFER_WALL_AD, UPDATE_OFFER_WALL_AD_ERROR } from './types';

import { RESET_MODAL } from '../../../actions/types';

const initialState = {
  curOfferWallAd: {},
  offerWallAdAdvertiser: null,
  title: 'Offer Wall Ad',
  saveError: false,
  saveErrorMessage: null,
  isOfferWallAdSaved: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_OFFER_WALL_AD:
      return { ...state, curOfferWallAd: action.payload, isOfferWallAdSaved: false, saveError: false };
    case UPDATE_OFFER_WALL_AD:
      return { ...state, curOfferWallAd: action.payload, isOfferWallAdSaved: true, saveError: false };
    case CREATE_OFFER_WALL_AD:
      return { ...state, curOfferWallAd: action.payload, isOfferWallAdSaved: true, saveError: false };
    case UPDATE_OFFER_WALL_AD_ERROR:
      return { ...state, saveError: true, saveErrorMessage: action.payload };
    case RESET_MODAL:
      return {
        ...state,
        saveError: false,
        saveErrorMessage: null
      };
    default:
      return state;
  }
}
