import React, { useState } from 'react';
import { Col, Label, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import { Field, FieldArray } from 'redux-form';
import { normalizeWeight } from '../../../../utils/conversionFunctions';
import CustomVariablesForm from '../../../../components/CustomVariables/CustomVariablesForm';
import { renderComboBox, renderField, renderToggleSwitch, textarea } from '../../../Offers/OfferTitle/renderConsts';
import OfferProperties from './OfferProperties';

const filterContainersByOfferType = (containers, offerType) => {
  return containers.filter((container) => offerType && offerType.id === container.offerTypeHint);
};

const RightAside = ({
  selectedElement,
  containers,
  layouts,
  isPublished,
  renderAppSwitch,
  renderSite,
  targetReturningUsers,
  layoutNames,
  offerTypeNames,
  containerNames,
  offerTypes,
}) => {
  const [tab, setTab] = useState('sequence');
  const [offerTab, setOfferTab] = useState('properties');
  const [sequenceTab, setSequenceTab] = useState('properties');

  return (
    <>
      <Nav tabs>
        <NavItem>
          <NavLink active={tab === 'offer'} onClick={() => setTab('offer')}>
            Offer
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink active={tab === 'sequence'} onClick={() => setTab('sequence')}>
            Sequence
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={tab}>
        <TabPane tabId='offer'>
          <Nav tabs>
            <NavItem>
              <NavLink active={offerTab === 'properties'} onClick={() => setOfferTab('properties')}>
                Properties
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent id='offerSubSection' activeTab={offerTab}>
            <TabPane tabId='properties'>
              <OfferProperties selectedElement={selectedElement} containers={containers} layouts={layouts} />
            </TabPane>
          </TabContent>
        </TabPane>
        <TabPane tabId='sequence'>
          <Nav tabs>
            <NavItem>
              <NavLink active={sequenceTab === 'properties'} onClick={() => setSequenceTab('properties')}>
                Properties
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink active={sequenceTab === 'design'} onClick={() => setSequenceTab('design')}>
                Design
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink active={sequenceTab === 'customVariables'} onClick={() => setSequenceTab('customVariables')}>
                Custom Variables
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent id='sequenceSubSection' activeTab={sequenceTab}>
            <TabPane tabId='properties'>
              <Row>
                <Col lg={12}>
                  <span>Properties </span>
                  {isPublished && renderAppSwitch()}
                </Col>
              </Row>
              <br />
              {renderSite}
              <Row>
                <Col lg={12}>
                  <span>Flex & Targeting </span>
                </Col>
              </Row>
              <br />
              <Row>
                <Col lg={8}>
                  <label
                    htmlFor='targetReturningUsers'
                    style={{
                      verticalAlign: 'super',
                    }}
                  >
                    Target Return Users
                  </label>
                  &nbsp;&nbsp;&nbsp;
                </Col>
                <Col lg={4}>
                  <Field name='targetReturningUsers' component={renderToggleSwitch} />
                </Col>
              </Row>
              <br />
              <Row>
                {targetReturningUsers && (
                  <Col lg={6}>
                    <Field
                      name={'returnVisits'}
                      normalize={normalizeWeight}
                      component={renderField}
                      type='number'
                      label='Return Visits'
                    />
                  </Col>
                )}
                <Col lg={6}>
                  <Field name={'flex'} normalize={normalizeWeight} component={renderField} type='number' label='Flex' />
                </Col>
              </Row>
              <Row>
                <Col lg={12}>
                  <span>Sequence Description </span>
                </Col>
              </Row>
              <br />
              <Row>
                <Col lg={12}>
                  <Field name={'sequenceDescription'} component={textarea} />
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId='design'>
              <Row>
                <Col lg={12}>
                  <span>Design</span>
                </Col>
              </Row>
              <br />
              <p>Default Layouts</p>
              {layoutNames.map((layoutName, i) => (
                <Row key={layoutName}>
                  <Col lg={12}>
                    <Label>{offerTypeNames[i]}</Label>
                    <Field
                      name={layoutName}
                      component={renderComboBox}
                      data={layouts}
                      valueField='id'
                      textField='name'
                      link='/content/layouts'
                    />
                  </Col>
                </Row>
              ))}
              <br />
              <p>Default Containers</p>
              {containerNames.map((containerName, i) => {
                const filteredContainers = filterContainersByOfferType(containers, offerTypes[offerTypeNames[i]]);
                return (
                  <Row key={containerName}>
                    <Col lg={12}>
                      <Label>{offerTypeNames[i]}</Label>
                      <Field
                        name={containerName}
                        id={containerName}
                        component={renderComboBox}
                        data={filteredContainers}
                        valueField='id'
                        textField='name'
                        link='/content/containers'
                      />
                    </Col>
                  </Row>
                );
              })}
            </TabPane>
            <TabPane tabId='customVariables'>
              <>
                <FieldArray name='customVariables' component={CustomVariablesForm} customVarFlag='SUD_' />
              </>
            </TabPane>
          </TabContent>
        </TabPane>
      </TabContent>
    </>
  );
};

export default RightAside;
