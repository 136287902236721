import arrayMutators from 'final-form-arrays';
import React, { Component } from 'react';
import { Field, Form } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import Switch from 'react-switch';
import { Button, Col, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import RenderOfferActionsList from '../../../components/ListOfOfferActions/renderListOfOfferActions';
import api from '../../../services';
import { renderComboBox, renderField } from '../../Offers/OfferTitle/renderConsts';

class ActionMarketingPartner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.record.record.id,
      name: this.props.record.record.name,
      advertiser: this.props.record.record.advertiser != null ? this.props.record.record.advertiser : null,
      advertiserComboBoxIsActive: this.props.record.record.advertiser != null ? true : false,
      modal: true,
      data: null,
      advertisers: this.props.record.advertisers,
      offerActions: this.props.record.record.offerActions || [],
    };

    this.toggleAdvertiser = this.toggleAdvertiser.bind(this);
    this.saveOnEdit = this.saveOnEdit.bind(this);
    this.saveOnCreate = this.saveOnCreate.bind(this);
  }

  toggleAdvertiser = (advertiserActive) => {
    this.setState({
      advertiserComboBoxIsActive: advertiserActive == true ? true : false,
    });
  };

  saveOnEdit = (saveData) => {
    try {
      api.put(`marketing_partner/${this.state.id}`, saveData).then((result) => {
        this.props.refreshCallBack();
        this.props.action();
        console.log(JSON.stringify(result));
      });
    } catch (error) {
      console.log(error);
    }
  };

  saveOnCreate = (saveData) => {
    try {
      api.post('marketing_partner', saveData).then((result) => {
        this.props.refreshCallBack();
        this.props.action();
        console.log(JSON.stringify(result));
      });
    } catch (error) {
      console.log(error);
    }
  };

  mapTitleFieldToOfferAction = (offerActions) => {
    if (!offerActions) return [{ offerActionType: null, title: '' }];
    return offerActions.map((offerAction) => (typeof offerAction.title === 'string' ? offerAction : offerAction.title));
  };
  onSubmit = (form) => {
    // transforms the title object from new actions into the parent offerAction
    form.offerActions = this.mapTitleFieldToOfferAction(form.offerActions);
    let saveData = {
      name: form.name,
      advertiser: form.advertiser,
      status: this.state.advertiserComboBoxIsActive,
      offerActions: form.offerActions,
    };
    try {
      if (this.state.id) {
        saveData.id = this.state.id;
        this.saveOnEdit(saveData);
      } else {
        this.saveOnCreate(saveData);
      }
    } catch (error) {
      console.log(error);
      console.error(error);
    }
  };

  renderSaveButton = (handleSubmit) => {
    return (
      <Button color='success' onClick={handleSubmit}>
        Save
      </Button>
    );
  };

  // TODO: We might need this later but right now the offerActions are lazily fetched
  async getMarketingPartnerById(id) {
    await api
      .get(`marketing_partner/${id}`)
      .then((response) => {
        this.setState({ offerActions: response.data.offerActions });
        console.log(response.data);
      })
      .catch((error) => console.log(error));
  }

  componentDidMount() {
    this.getMarketingPartnerById(this.state.id);
  }

  validateForm = (values) => {
    const errors = {};
    values.offerActions = this.mapTitleFieldToOfferAction(values.offerActions);
    if (!values.name) {
      errors.name = 'Required';
    }
    if (!values.offerActions || !values.offerActions.length) {
      errors.offerActions = 'Please add at least one action';
    }
    if (values.offerActions && values.offerActions.length) {
      const offerActions = [];
      const offerActionDupeCheck = {};
      values.offerActions.forEach((offerAction, i) => {
        let offerActionError = {};
        if (offerAction && JSON.stringify(offerAction) !== '{}') {
          if (!offerAction.title) {
            offerActionError.title = 'Required';
          }
          if (offerActionDupeCheck[offerAction.id]) {
            offerActionError.title = 'Duplicate Action Not Allowed';
          } else if (offerAction.id) {
            offerActionDupeCheck[offerAction.id] = true;
          }
        } else if (!offerAction || typeof offerAction == 'string' || JSON.stringify(offerAction) === '{}') {
          offerActionError.title = 'Required';
        }
        offerActions[i] = offerActionError;
      });
      errors.offerActions = offerActions;
    }

    return errors;
  };

  render() {
    let title = this.state.id ? 'Edit Marketing Partner' : 'Create New Marketing Partner';
    let comboBox;
    if (this.state.advertiserComboBoxIsActive) {
      comboBox = (
        <>
          <Label>Advertiser</Label>
          <Field
            type='select'
            name='advertiser'
            data={this.state.advertisers}
            component={renderComboBox}
            allData={this.state.advertisers}
            textField='name'
            valueField='id'
          />
        </>
      );
    }
    let advertiserSwitch = (
      <div>
        <span>Advertiser: </span>
        <Switch
          onChange={this.toggleAdvertiser}
          checked={this.state.advertiserComboBoxIsActive}
          onColor={'#4dbd74'}
          offColor={'#f86c6b'}
          width={70}
          checkedIcon={
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '96%',
                fontSize: 11,
              }}
            >
              Active
            </div>
          }
          uncheckedIcon={
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '96%',
                fontSize: 11,
              }}
            >
              Inactive
            </div>
          }
        />
      </div>
    );
    return (
      <>
        <Form
          onSubmit={this.onSubmit}
          validate={(values) => {
            return this.validateForm(values);
          }}
          initialValues={{
            offerActions: this.state.offerActions,
            name: this.state.name,
            advertiser: this.state.advertiser,
          }}
          mutators={{
            ...arrayMutators,
            setValue: ([field, value], state, { changeValue }) => {
              changeValue(state, field, () => value);
            },
          }}
          render={({
            handleSubmit,
            form: {
              mutators: { push, pop, setValue },
            },
            values,
          }) => {
            return (
              <form onSubmit={handleSubmit}>
                <Modal size='lg' isOpen={this.state.modal}>
                  <ModalHeader>{title}</ModalHeader>
                  <ModalBody>
                    <Row>
                      <Col lg={12}>
                        <Field placeholder='Name' name='name' component={renderField} label='Name' />
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={12}>{comboBox}</Col>
                    </Row>
                    <br />
                    <Row>
                      <Col>
                        <FieldArray
                          name='offerActions'
                          component={RenderOfferActionsList}
                          offerActions={this.props.allOfferActions}
                          offerActionSubtypes={this.props.offerActionSubtypes}
                          marketingPartnerId={this.state.id}
                          pop={pop}
                          push={push}
                          setValue={setValue}
                        />
                      </Col>
                    </Row>
                  </ModalBody>
                  <ModalFooter>
                    {advertiserSwitch}
                    <Button color='success' onClick={handleSubmit}>
                      Save
                    </Button>
                    <Button color='secondary' onClick={this.props.action}>
                      Cancel
                    </Button>
                  </ModalFooter>
                </Modal>
              </form>
            );
          }}
        />
      </>
    );
  }
}

export default ActionMarketingPartner;
