import { ActionElement, Field, QueryBuilder, RuleGroupTypeIC } from "react-querybuilder";
import { QueryBuilderDnD } from "@react-querybuilder/dnd";
import { useState } from "react";
import * as ReactDnD from "react-dnd";
import * as ReactDndHtml5Backend from "react-dnd-html5-backend";
import DropdownList from "react-widgets/DropdownList";
import "./styles.scss";

interface UserAttribute {
  id: number;
  name: string;
  valueStringTypeHint: string | null;
  userAttributesValueString: { id: number; valueString: string }[] | null;
}

function transformArray(objects: UserAttribute[]): Field[] {
  return objects?.map((obj) => {
    let values;
    if (obj.userAttributesValueString && obj.userAttributesValueString.length > 0) {
      values = obj.userAttributesValueString.map((attr) => ({
        name: attr.valueString,
        label: attr.valueString,
      }));
    } else {
      values = [
        { name: 'true', label: 'true' },
        { name: 'false', label: 'false' },
        { name: 'not_set', label: 'not_set' },
      ];
    }

    return {
      name: obj.name || 'unknown_name',
      label: obj.name || 'unknown_name',
      valueEditorType: 'select',
      values: values,
    };
  });
}

const CustomInputEditor = (props: any) => {
  return (
    <DropdownList
      value={props.value}
      data={
        props.options ? props.options.map((v: { name: any }) => v.name) : props.values.map((v: { name: any }) => v.name)
      }
      onChange={props.handleOnChange}
      onBlur={props.onBlur}
    />
  );
};

const CustomCombinatorSelector = (props: any) => {
  const options = [
    { name: 'AND', label: 'AND' },
    { name: 'OR', label: 'OR' },
  ];

  return (
    <DropdownList
      value={props.value.toUpperCase()}
      data={options.map((option) => option.label)}
      onChange={(value) => props.handleOnChange(value)}
      onBlur={props.onBlur}
      style={{ width: '100px' }}
    />
  );
};

export const UserAttribute = (props: any) => {
  const [query, setQuery] = useState<RuleGroupTypeIC>(props?.input?.value);
  const { onChange } = props.input;

  return (
    <>
      <QueryBuilderDnD dnd={{ ...ReactDnD, ...ReactDndHtml5Backend }}>
        <QueryBuilder
          fields={transformArray(props.userAttributes)}
          query={query}
          onQueryChange={(newQuery) => {
            onChange(newQuery);
            return setQuery(newQuery);
          }}
          independentCombinators={true}
          controlElements={{
            valueEditor: CustomInputEditor,
            fieldSelector: CustomInputEditor,
            combinatorSelector: CustomCombinatorSelector,
            addGroupAction: (props) => (props.level === 0 ? <ActionElement {...props} /> : null),
          }}
          addRuleToNewGroups
        />
      </QueryBuilderDnD>
    </>
  );
};
