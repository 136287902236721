import React, { useState, useEffect } from 'react';
import { differenceBy } from 'lodash';
import { connect, useDispatch } from 'react-redux';
import { change, Field } from 'redux-form';
import { Link } from 'react-router-dom';
import { Button, Col, Label, Row } from 'reactstrap';
import { getIsActiveTrafficLight, renderComboBox, renderField } from '../OfferTitle/renderConsts';
import { normalizeWeight } from '../../../utils/conversionFunctions';

const WaterfallEntry = (props) => {
  const [offerActionSubTypeName, setOfferActionSubTypeName] = useState(
    props.offerActionType === 'POST_WATERFALL' ? 'offerActionPost' : 'offerActionLinkout'
  );
  const [actionSubType, setActionSubType] = useState(props.offerActionType === 'POST_WATERFALL' ? 'Post' : 'Linkout');

  const dispatch = useDispatch();

  useEffect(() => {
    setOfferActionSubTypeName(props.offerActionType === 'POST_WATERFALL' ? 'offerActionPost' : 'offerActionLinkout');
    setActionSubType(props.offerActionType === 'POST_WATERFALL' ? 'Post' : 'Linkout');
  }, [props.offerActionType]);

  const handleOfferActionNameChange = (offerAction = {}) => {
    const advertiser = offerAction.advertiser ? offerAction.advertiser.name : null;
    dispatch(change('offerActionDetail', `${props.waterFallEntry}.advertiser`, advertiser));
  };

  const {
    fields,
    filteredActions,
    index,
    waterFallEntry,
    offerActionSubtype,
    offerActionPosts,
    offerActionLinkouts,
  } = props;
  const { offerAction } = offerActionSubtype;

  return (
    <>
      <Row>
        <Col sm={8} lg={8}>
          <Label>{actionSubType} &nbsp;{actionSubType === 'Linkout' ? `(${index + 1}).` : null} &nbsp;</Label>
          {offerAction && getIsActiveTrafficLight(offerAction.active)}
          <Field
            name={`${waterFallEntry}.${offerActionSubTypeName}`}
            onChange={(offerActionSubtype) => handleOfferActionNameChange(offerActionSubtype, waterFallEntry)}
            component={renderComboBox}
            data={filteredActions}
            textField='name'
            valueField='id'
            type='select'
            label={actionSubType}
            busy={
              (props.offerActionType === 'POST_WATERFALL' && offerActionPosts.length === 0) ||
              (props.offerActionType === 'LINKOUT_WATERFALL' && offerActionLinkouts.length === 0)
            }
          />
        </Col>
        <Col sm={2} lg={2}>
          {!offerAction ? (
            <Button
              color='info'
              type='button'
              title={`Visit ${actionSubType.toLowerCase()} details`}
              disabled={true}
              className={`${actionSubType.toLowerCase()}-link`}
            >
              <i className='fa fa-search' />
            </Button>
          ) : (
            <Link to={`/campaigns/offeractions/${offerAction.id}`} target='_blank'>
              <Button
                color='info'
                type='button'
                title={`Visit ${actionSubType.toLowerCase()} details`}
                className={`${actionSubType.toLowerCase()}-link`}
              >
                <i className='fa fa-search' />
              </Button>
            </Link>
          )}
        </Col>
        <Col sm={2} lg={2}>
          {fields.length > 1 && (
            <Button
              className='delete-waterfall-entry-button'
              onClick={() => props.handleWaterfallEntryRemoval(index, fields)}
              color='danger'
            >
              <i className='fa fa-minus' />
            </Button>
          )}
        </Col>
      </Row>
      <Row>
        <Col sm={3} lg={3}>
          <Field
            name={`${waterFallEntry}.flex`}
            normalize={normalizeWeight}
            onChange={(event, weight) => props.handleWeightChange(event, weight, index)}
            component={renderField}
            type='number'
            label='Flex'
          />
        </Col>
        <Col sm={3} lg={3}>
          <Field name={`percent-${index}`} component={renderField} type='number' label='Weight %' disabled />
        </Col>
        <Col sm={6} lg={6}>
          <Field
            name={`${waterFallEntry}.advertiser`}
            component={renderField}
            type='text'
            label='Advertiser'
            disabled
          />
        </Col>
      </Row>
    </>
  );
};

const mapStateToProps = (state, props) => {
  const { offerActionPosts, offerActionLinkouts, selector } = state.offerAction;
  const { offerActionType } = props;
  const offerActionSubTypeName = offerActionType === 'POST_WATERFALL' ? 'offerActionPost' : 'offerActionLinkout';
  const offerActionSubtype = selector(state, `${props.waterFallEntry}.${offerActionSubTypeName}`) || {};

  const advertiser = offerActionSubtype.advertiser;

  let selectedActions = selector(state, `${offerActionSubTypeName}WaterfallEntries`);
  selectedActions = (selectedActions || [])
    .map((wfe) => {
      return wfe[offerActionSubTypeName];
    })
    .filter((action) => action);

  const filteredActions =
    offerActionType === 'POST_WATERFALL'
      ? differenceBy(offerActionPosts, selectedActions, 'id')
      : differenceBy(offerActionLinkouts, selectedActions, 'id');

  return {
    advertiser,
    offerActionSubtype,
    offerActionPosts,
    offerActionLinkouts,
    filteredActions,
  };
};

export default connect(mapStateToProps)(WaterfallEntry);
