import { format } from 'date-fns';
import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import SummaryCard from '../../components/SummaryCard/SummaryCard';
import { columnDefaults } from '../../utils/tableFunctions';
import ActiveStatusModal from '../../components/ActiveStatusModal/ActiveStatusModal';
import { useLandingPages } from './queries';
import api from '../../services';
import ChangelogModal from '../../components/ChangelogModal/ChangelogModal';

function linkFormatter(cell, row) {
  return (
    <Link to={`/campaigns/landingpages/${row.id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
      {cell}
    </Link>
  );
}

const updateStatus = (row, activeStatus) => {
  if (row.id) {
    api
      .put(`landing-pages/active/${row.id}`, {
        isActive: activeStatus,
        zuulUser: JSON.parse(localStorage.getItem('userData')),
      })
      .then((result) => console.log(JSON.stringify(result)));
  }
};

const columns = [
  {
    dataField: 'id',
    text: 'ID',
    formatter: linkFormatter,
    ...columnDefaults,
  },
  {
    dataField: 'offer.name',
    text: 'Landing Page Name',
    formatter: linkFormatter,
    ...columnDefaults,
  },
  {
    dataField: 'lastPublishedBy',
    text: 'Last Published',
    formatter: linkFormatter,
    sortValue: (cell, row) => new Date(row.offer.lastPublished),
    ...columnDefaults,
  },
  {
    dataField: 'active',
    text: 'Status',
    formatter: (_cell, row) => (
      <ActiveStatusModal
        content={row.offer}
        isActive={row.offer.isActive}
        modalType=' Landing Page'
        getErrors={() => null}
        onClickSubmit={(activeStatus) => updateStatus(row, activeStatus)}
        isEditMode={true}
      />
    ),
    ...columnDefaults,
  },
  {
    dataField: 'changelog',
    text: 'Audit',
    isDummyField: true,
    formatter: (_cell, row) => (
      <Row>
        <Col lg={12}>
          <ChangelogModal typeName='Landing Page' apiPath='landing-pages' id={row.id} />
        </Col>
      </Row>
    ),
    headerStyle: {
      backgroundColor: '#2f353a',
      textAlign: 'center',
    },
    searchable: false,
  },
];

const LandingPages = () => {
  const { data, isLoading, error } = useLandingPages();
  const dataWithFormattedDate = data?.map((lp) => {
    const formattedDate = lp.offer?.lastPublished ? format(new Date(lp.offer.lastPublished), 'Pp') : '';
    const lastPublishedBy =
      lp.offer.zuulUser && formattedDate ? formattedDate + ' by ' + lp.offer.zuulUser.realname : formattedDate;
    return { ...lp, lastPublishedBy };
  });

  return (
    <>
      <SummaryCard
        entity='Landing Pages'
        createComponent={
          <Link to='/campaigns/landingpages/landingpage-detail' style={{ textDecoration: 'none', color: 'inherit' }}>
            <Button color='primary' className='float-right'>
              &nbsp; Add Landing Page
            </Button>
          </Link>
        }
        columns={columns}
        defaultSorted={[{ dataField: 'lastPublishedBy', order: 'desc' }]}
        data={dataWithFormattedDate}
        loading={isLoading}
        error={error}
      />
    </>
  );
};

export default LandingPages;
