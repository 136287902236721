import { Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import React from 'react';

function MockInput(props) {
  return (
    <div
      style={{
        display: 'block',
        width: '100%',
        height: 'calc(1.5em + 0.75rem + 2px)',
        padding: '0.375rem 0.75rem',
        fontSize: '0.875rem',
        fontWeight: '400',
        lineHeight: '1.5',
        color: '#e4e7ea',
        backgroundColor: '#515b65',
        backgroundClip: 'padding-box',
        border: '1px solid #23282c',
        borderRadius: '0.25rem',
        marginTop: '0.5rem',
      }}
    >
      {props.text}
    </div>
  );
}

export function DetailsModal(props) {
  return (
    <Modal isOpen={props.open} toggle={props.toggle}>
      <ModalHeader toggle={props.toggle}>User Attribute Details</ModalHeader>
      <ModalBody>
        <Row>
          <Col>
            <div>Type:</div>
          </Col>
        </Row>
        <Row>
          <Col>
            <MockInput text={props.row?.valueStringTypeHint ? 'List' : 'True/False (Legacy)'} />
          </Col>
        </Row>
        <Row>
          <Col>
            <div>Name:</div>
          </Col>
        </Row>
        <Row>
          <Col>
            <MockInput text={props.row?.name} />
          </Col>
        </Row>
        {props.row?.valueStringTypeHint && (
          <>
            <Row>
              <Col>
                <div>Type Hint:</div>
              </Col>
            </Row>
            <Row>
              <Col>
                <MockInput text={props.row.valueStringTypeHint} />
              </Col>
            </Row>
          </>
        )}
        {props.row?.userAttributesValueString?.map(({ id, valueString }) => (
          <Row key={id}>
            <Col
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <div>Value:</div>
            </Col>
            <Col>
              <MockInput text={valueString} />
            </Col>
          </Row>
        ))}
      </ModalBody>
    </Modal>
  );
}
