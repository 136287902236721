import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Alert, Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { saveOffer } from '../../../actions';
import './SaveOfferModal.css';

class SaveOfferModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      submitting: false,
      isOpen: false,
      alertStyle: { float: 'left' },
    };

    this.trySave = this.trySave.bind(this);
    this.toggleOpen = this.toggleOpen.bind(this);
    this.getErrors = this.getErrors.bind(this);
    this.renderAlert = this.renderAlert.bind(this);
    this.renderErrorAlert = this.renderErrorAlert.bind(this);
    this.renderSuccessAlert = this.renderSuccessAlert.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.isOfferSaved && this.state.submitting) {
      setTimeout(() => this.props.saveOffer(false), 4000);
      this.setState({ submitting: false, isOpen: false });
    }
    // if form submission failed due to validation
    const errors = this.props.getErrors();
    if (errors.invalid) {
      this.setState({ submitting: false });
    }
  }

  trySave() {
    this.setState({ submitting: true });
    const shouldClose = this.props.onClickSubmit();
    if (shouldClose) {
      this.setState({ isOpen: false, submitting: false });
    }
  }

  toggleOpen() {
    if (!this.state.submitting) {
      this.props.saveOffer(false);
      this.setState((state) => ({ isOpen: !state.isOpen, submitting: false }));
    }
  }

  getErrors() {
    const errors = this.props.getErrors();
    errors._error = errors.invalid ? 'One or more of the fields you entered are invalid.' : errors._error;

    return errors;
  }

  renderErrorAlert(errors) {
    return (
      <Alert style={this.state.alertStyle} color='danger'>
        {errors._error}
      </Alert>
    );
  }

  renderSuccessAlert() {
    return (
      <Alert style={this.state.alertStyle} color='success'>
        Offer Saved Successfully
      </Alert>
    );
  }

  renderAlert(errors) {
    const { isAdvertiserMissing, areUserAttributesMissing, hasTagError } = errors;
    const shouldRenderError =
      Object.keys(errors).some((x) => errors[x]) || isAdvertiserMissing || areUserAttributesMissing || hasTagError;

    return shouldRenderError
      ? this.renderErrorAlert(errors)
      : this.props.isOfferSaved
      ? this.renderSuccessAlert()
      : null;
  }

  render() {
    const { submitting, isOpen } = this.state;
    const errors = this.getErrors();
    const links = this.props.linkouts || [];
    const posts = this.props.posts || [];
    const isDisabled = links.length === 0 || posts.length === 0 || errors.invalid;
    const modalClassName = 'modal-success ' + this.props.className;
    const checkContainerStyle = {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: '#4dbd74',
    };

    return (
      <>
        {this.renderAlert(errors)}
        <Col xs='auto'>
          <Button color='success' onClick={this.toggleOpen} disabled={isDisabled}>
            Save Offer
          </Button>
        </Col>
        <div className='animated'>
          <Modal isOpen={isOpen} toggle={this.toggleOpen} className={modalClassName}>
            <ModalHeader toggle={this.toggleOpen}>Save Offer</ModalHeader>
            <ModalBody>
              <Row>
                <Col lg={2} style={checkContainerStyle}>
                  <i className='fa fa-check-circle-o fa-3x'></i>
                </Col>
                <Col lg={10}>
                  You are about to save the offer:
                  <br />
                  <h3>{this.props.offerName}</h3>
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter>
              <Button color='success' disabled={submitting} onClick={this.trySave}>
                {submitting ? 'Processing' : 'Save'}
              </Button>
              <Button color='secondary' disabled={submitting} onClick={this.toggleOpen}>
                {submitting ? 'Processing' : 'Cancel'}
              </Button>
            </ModalFooter>
          </Modal>
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  const { advertiser, selector, isOfferSaved } = state.offerState;
  const criteriaUserAttributes = selector(state, 'criteriaUserAttributes');

  return { advertiser, criteriaUserAttributes, isOfferSaved };
}

export default connect(mapStateToProps, { saveOffer })(SaveOfferModal);
