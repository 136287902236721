import {
  AppAside,
  AppBreadcrumb,
  AppFooter,
  AppHeader,
  AppSidebar,
  AppSidebarFooter,
  AppSidebarForm,
  AppSidebarHeader,
  AppSidebarMinimizer,
  AppSidebarNav,
} from '@coreui/react';
import React, { Suspense, lazy, useEffect, useState } from 'react';
import { Redirect, Switch, useHistory } from 'react-router-dom';
import { Container } from 'reactstrap';
import api from '../../services';

// sidebar nav config
import navigation from '../../_nav';
// routes config
import { ApmRoute } from '@elastic/apm-rum-react';
import { useOktaAuth } from '@okta/okta-react';
import routes from '../../routes';
import './DefaultLayout.css';

const DefaultAside = lazy(() => import('./DefaultAside'));
const DefaultFooter = lazy(() => import('./DefaultFooter'));
const DefaultHeader = lazy(() => import('./DefaultHeader'));

const DefaultLayout = (props) => {
  const [zuulrole, setZuulrole] = useState('');
  const [redirect, setRedirect] = useState(false);
  const history = useHistory();
  const { authState, oktaAuth } = useOktaAuth();
  const [userInfo, setUserInfo] = useState(null);

  const loading = () => <div className='animated fadeIn pt-1 text-center'>Loading...</div>;

  const signOut = (e) => {
    typeof e === 'function' && e.preventDefault();

    oktaAuth.signOut();
    if (localStorage.getItem('userData')) {
      localStorage.removeItem('userData');
    }
    history.push('/login');
  };

  const getInitials = (name) => {
    return name
      .split(' ')
      .map((name) => name.charAt(0).toUpperCase())
      .join('');
  };

  useEffect(() => {
    let isMounted = true; // track whether component is mounted

    if (!authState || !authState.isAuthenticated) {
      // When user isn't authenticated, forget any user info
      isMounted && setUserInfo(null);
    } else {
      api.defaults.headers.common['Authorization'] = `Bearer ${authState.accessToken.accessToken}`;

      // get user information from the `/userinfo` endpoint
      oktaAuth.getUser().then((info) => {
        if (isMounted) {
          setUserInfo(info);

          let reqUserData = {
            email: info.email,
            realname: info.name,
            lastLogin: new Date(),
          };

          api
            .put('zuulusers/login', reqUserData)
            .then((response) => {
              let responseJson = response.data;
              let isSignedInNow = responseJson?.zuulUser?.online;

              localStorage.setItem('userData', JSON.stringify(responseJson?.zuulUser));

              isMounted && setZuulrole(responseJson?.zuulUser?.zuulUserRole?.name);
              return isSignedInNow;
            })
            .catch((error) => {
              console.log('LOGIN ERROR');
              console.log(error);
            });
        }
      });
    }

    return () => {
      isMounted = false; // update the flag when component unmounts
    };
  }, [authState, oktaAuth]); // Update if authState changes

  if (!authState?.isAuthenticated || redirect) {
    return <Redirect to={'/login'} />;
  }

  return (
    <div className='app'>
      <AppHeader fixed>
        <Suspense fallback={loading()}>
          <DefaultHeader onLogout={(e) => signOut(e)} />
        </Suspense>
      </AppHeader>
      <div className='app-body'>
        <AppSidebar fixed display='lg'>
          <AppSidebarHeader />
          <AppSidebarForm />
          <Suspense fallback={loading()}>
            <Container
              style={{
                display: 'flex',
                boxSizing: 'border-box',
                flexDirection: 'column',
                alignItems: 'center',
                backgroundColor: '#2f353a',
                justifyContent: 'flex-start',
                width: '100%',
                height: '136px',
                minHeight: '136px',
                maxHeight: '136px',
                padding: '29px 0 64px',
              }}
            >
              <div>
                <h5>{userInfo?.name || ''}</h5>
              </div>
              <div>
                <p style={{ color: '#8f9ba6' }}>
                  <i>{zuulrole}</i>
                </p>
              </div>
              <div
                style={{
                  position: 'absolute',
                  top: '92px',
                  borderRadius: '50%',
                  padding: '8px',
                  transform: 'translateX(-50%)',
                  left: '50%',
                  backgroundColor: '#3a4149',
                }}
              >
                <span className='avatar'>{userInfo && getInitials(userInfo?.name)}</span>
              </div>
            </Container>
            <br />
            <AppSidebarNav navConfig={navigation} {...props} />
          </Suspense>
          <AppSidebarFooter />
          <AppSidebarMinimizer />
        </AppSidebar>
        <main className='main'>
          <AppBreadcrumb appRoutes={routes} />
          <Container fluid>
            <Suspense fallback={loading()}>
              <Switch>
                <Redirect exact from='/' to='/dashboard' />
                {routes.map((route, idx) => {
                  return route.component ? (
                    <ApmRoute
                      key={idx}
                      path={route.path}
                      exact={route.exact}
                      name={route.name}
                      component={route.component}
                    />
                  ) : null;
                })}
              </Switch>
            </Suspense>
          </Container>
        </main>
        <AppAside fixed>
          <Suspense fallback={loading()}>
            <DefaultAside />
          </Suspense>
        </AppAside>
      </div>
      <AppFooter>
        <Suspense fallback={loading()}>
          <DefaultFooter />
        </Suspense>
      </AppFooter>
    </div>
  );
};

export default DefaultLayout;
