import React from 'react';
import {
  Button,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';

export default function VerifyModal({
  isOpen,
  toggle,
  customVarFlag,
  layoutMissingKeys,
  containerMissingKeys,
  customVariablesNotInContainerLayout,
  fields,
}) {
  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Missing Keys</ModalHeader>
      <ModalBody>
        {layoutMissingKeys.length > 0 && (
          <>
            <h5>Layout Missing Keys</h5>
            {layoutMissingKeys.map((key, index) => (
              <InputGroup key={index} style={{ padding: '.5rem' }}>
                <InputGroupAddon addonType='prepend'>
                  <InputGroupText>{customVarFlag}</InputGroupText>
                </InputGroupAddon>
                <Input value={key.key.slice(customVarFlag.length)} disabled />
              </InputGroup>
            ))}
          </>
        )}
        {containerMissingKeys.length > 0 && (
          <>
            <h5>Container Missing Keys</h5>
            {containerMissingKeys.map((key, index) => (
              <InputGroup key={index} style={{ padding: '.5rem' }}>
                <InputGroupAddon addonType='prepend'>
                  <InputGroupText>{customVarFlag}</InputGroupText>
                </InputGroupAddon>
                <Input value={key.key?.slice(customVarFlag.length)} disabled />
              </InputGroup>
            ))}
          </>
        )}
        {customVariablesNotInContainerLayout?.length > 0 && (
          <>
            <h5>Custom Variables are not in container or layout</h5>
            {customVariablesNotInContainerLayout.map((key, index) => (
              <InputGroup key={index} style={{ padding: '.5rem', color: 'red' }}>
                <InputGroupAddon addonType='prepend'>
                  <InputGroupText style={{ background: 'red' }}>{customVarFlag}</InputGroupText>
                </InputGroupAddon>
                <Input style={{ background: 'red' }} value={key.key?.slice(customVarFlag.length)} disabled />
              </InputGroup>
            ))}
          </>
        )}
      </ModalBody>
      <ModalFooter>
        {containerMissingKeys.length || layoutMissingKeys.length ? (
          <Button
            color='primary'
            onClick={() => {
              const existingKeys = fields.getAll().map((field) => field.key);
              const combinedKeys = [...containerMissingKeys, ...layoutMissingKeys];
              const uniqueKeys = combinedKeys.filter(
                (key, index, self) =>
                  index === self.findIndex((k) => k.key === key.key && !existingKeys.includes(key.key))
              );

              uniqueKeys.forEach((missingKey) => fields.push(missingKey));
              toggle();
            }}
          >
            Apply
          </Button>
        ) : null}
        <Button color='secondary' onClick={toggle}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
}
