import React, { lazy } from 'react';
import OfferDetails from './views/Offers/OfferDetails';

const DefaultLayout = lazy(() => import('./containers/DefaultLayout'));
const PermissionPage = lazy(() => import('./views/Permissions/Permissions'));
const MarketingPartnerPage = lazy(() => import('./views/MarketingPartners/MarketingPartners'));
const Domain = lazy(() => import('./views/Domains/Domain'));
const RevSharePercentage = lazy(() => import('./views/RevSharePercentage/RevSharePercentage'));
const PublisherPrePings = lazy(() => import('./views/PublisherPrePings/PublisherPrePings'));
const PublisherPrePingsDetails = lazy(() =>
  import('./views/PublisherPrePings/PublisherPrePingsDetails/PublisherPrePingDetails')
);
const OfferReordering = lazy(() => import('./views/OfferReordering/OfferReordering'));
const OfferReorderingDetails = lazy(() =>
  import('./views/OfferReordering/OfferReorderingDetails/OfferReorderingDetails')
);
const Dashboard = lazy(() => import('./views/Dashboard/Dashboard'));
const Navbars = lazy(() => import('./views/Base/Navbars/Navbars'));
const LandingPages = lazy(() => import('./views/LandingPages/LandingPages'));
const LandingPageDetails = lazy(() => import('./views/LandingPages/LandingPageDetails/LandingPageDetails'));
const LandingPageGroups = lazy(() => import('./views/LandingPageGroups/LandingPageGroups'));
const LandingPageGroupDetails = lazy(() => import('./views/LandingPageGroups/LPGDetails/LPGDetails'));
const OfferTable = lazy(() => import('./views/Offers/OfferTable'));
const OfferActions = lazy(() => import('./views/Offers/OfferActions/OfferActions'));
const OfferActionDetail = lazy(() => import('./views/Offers/OfferActions/OfferActionDetail'));
const OfferWallAds = lazy(() => import('./views/OfferWallAds/OfferWallAds'));
const OfferWallAdDetails = lazy(() => import('./views/OfferWallAds/OfferWallAdDetails/OfferWallAdDetails'));
const AdvertiserPrePing = lazy(() => import('./views/AdvPrePing/AdvPrePing'));
const AdvertiserPrePingDetails = lazy(() => import('./views/AdvPrePing/AdvPrePingDetails/AdvPrePingDetails'));
const Advertisers = lazy(() => import('./views/Advertisers/Advertisers'));
const Users = lazy(() => import('./views/Users/Users'));
const Sequences = lazy(() => import('./views/Sequences/Sequences'));
const Tags = lazy(() => import('./views/Tags/Tags'));
const LayoutsList = lazy(() => import('./views/Layouts/LayoutsList'));
const LayoutsDetails = lazy(() => import('./views/Layouts/LayoutsDetails'));
const TemplateEditor = lazy(() => import('./views/TemplateEditor/TemplateEditor'));
const ContainersList = lazy(() => import('./views/Containers/ContainersList'));
const ContainersDetails = lazy(() => import('./views/Containers/ContainersDetails'));
const UserDetails = lazy(() => import('./views/Users/UserDetails'));
const OfferBlocksSummary = lazy(() => import('./views/OfferBlocks/OfferBlocksSummary'));
const HandlersSummary = lazy(() => import('./views/Handlers/HandlersSummary'));
const AffiliatesSummary = lazy(() => import('./views/Affiliates/AffiliatesSummary'));
const UserAttribute = lazy(() => import('./views/UserAttributes/UserAttributeTable'));
const FeatureToggles = lazy(() => import('./views/FeatureToggles/FeatureToggles'));
const CustomVariables = lazy(() => import('./views/CustomVariables/CustomVariables'));

const SiteScreen = lazy(() => import('./views/Sites/SiteScreen'));
const SiteDetail = lazy(() => import('./views/Sites/SiteDetail/SiteDetail'));

const routes = [
  { path: '/', exact: true, name: 'Home', component: DefaultLayout },
  { path: '/dashboard', name: 'Dashboard', component: Dashboard },
  {
    path: '/permissions',
    exact: true,
    name: 'Permissions',
    component: PermissionPage,
  },
  {
    path: '/advertisers',
    exact: true,
    name: 'Advertisers',
    component: Advertisers,
  },
  {
    path: '/marketingpartners',
    exact: true,
    name: 'Marketing Partners',
    component: MarketingPartnerPage,
  },
  {
    path: '/domains',
    exact: true,
    name: 'Domains',
    component: Domain,
  },
  {
    path: '/publisher-pre-pings',
    exact: true,
    name: 'Publisher Pre-Pings',
    component: PublisherPrePings,
  },
  {
    path: '/affiliates',
    name: 'Affilates',
    component: AffiliatesSummary,
    exact: true,
  },
  {
    path: '/publisher-pre-pings/create',
    exact: true,
    name: 'Publisher Pre-Pings Details',
    component: PublisherPrePingsDetails,
  },
  {
    path: '/publisher-pre-pings/:publisherPrePingId',
    exact: true,
    name: 'Publisher Pre-Pings Details',
    component: PublisherPrePingsDetails,
  },
  {
    path: '/advertiser-pre-pings',
    exact: true,
    name: 'Advertiser PrePing',
    component: AdvertiserPrePing,
  },
  {
    path: '/advertiser-pre-pings/create',
    exact: true,
    name: 'Advertiser PrePing Detail',
    component: AdvertiserPrePingDetails,
  },
  {
    path: '/advertiser-pre-pings/:advPrePingId',
    exact: true,
    name: 'Advertiser PrePing Detail',
    component: AdvertiserPrePingDetails,
  },
  {
    path: '/custom-variables',
    name: 'Custom Variables',
    component: CustomVariables,
  },
  {
    path: '/revshare-percentage',
    exact: true,
    name: 'Revshare Percentage',
    component: RevSharePercentage,
  },
  {
    path: '/offerreordering',
    exact: true,
    name: 'Offer Reordering',
    component: OfferReordering,
  },
  {
    path: '/offerreordering/details/:id',
    exact: true,
    name: 'Offer Reordering Details',
    component: OfferReorderingDetails,
  },
  {
    path: '/campaigns/landingpages',
    exact: true,
    name: 'Landing Pages',
    component: LandingPages,
  },
  {
    path: '/campaigns/landingpages/landingpage-detail',
    exact: true,
    name: 'Landing Page Detail',
    component: LandingPageDetails,
  },
  {
    path: '/campaigns/landingpages/:landingPageId',
    exact: true,
    name: 'Landing Page Detail',
    component: LandingPageDetails,
  },
  {
    path: '/campaigns/landingpagegroups',
    exact: true,
    name: 'Landing Page Groups',
    component: LandingPageGroups,
  },
  {
    path: '/campaigns/landingpagegroups/create',
    exact: true,
    name: 'Landing Page Group Details',
    component: LandingPageGroupDetails,
  },
  {
    path: '/campaigns/landingpagegroups/:lpgId',
    exact: true,
    name: 'Landing Page Group Details',
    component: LandingPageGroupDetails,
  },
  {
    path: '/campaigns/offerblocks',
    exact: true,
    name: 'Offer Blocks',
    component: OfferBlocksSummary,
  },
  {
    path: '/campaigns/offertable/offer/:offerId',
    name: 'Offer Detail',
    exact: true,
    component: OfferDetails,
  },
  {
    path: '/campaigns/offertable/offer/:offerId?selectedOffer=:selectedOffer',
    name: 'Offer Detail',
    exact: true,
    component: OfferDetails,
  },
  {
    path: '/campaigns/offertable/offer-detail',
    exact: true,
    name: 'Offer Detail',
    component: OfferDetails,
  },
  {
    path: '/campaigns/offertable',
    name: 'Offer Table',
    component: OfferTable,
    exact: true,
  },
  {
    path: '/campaigns/offerwallads/create',
    exact: true,
    name: 'Offer Wall Ad Detail',
    component: OfferWallAdDetails,
  },
  {
    path: '/campaigns/offerwallads/:offerWallAdId',
    exact: true,
    name: 'Offer Wall Ad Detail',
    component: OfferWallAdDetails,
  },
  {
    path: '/campaigns/offerwallads',
    exact: true,
    name: 'Offer Wall Ads',
    component: OfferWallAds,
  },
  { path: '/campaigns', name: 'Campaigns', exact: true, component: OfferTable },
  { path: '/base/navbars', name: 'Navbars', component: Navbars },
  {
    path: '/campaigns/offeractions',
    exact: true,
    name: 'Offer Actions',
    component: OfferActions,
  },
  {
    path: '/campaigns/offeractions/offer-action-detail',
    exact: true,
    target: '_blank',
    name: 'Offer Action Detail',
    component: OfferActionDetail,
  },
  {
    path: '/campaigns/offeractions/:actionId',
    target: '_blank',
    name: 'Offer Action Detail',
    component: OfferActionDetail,
  },
  {
    path: '/users',
    exact: true,
    name: 'Users',
    component: Users,
  },
  {
    path: '/userdetails/:userId',
    exact: true,
    target: '_blank',
    name: 'User Detail',
    component: UserDetails,
  },
  {
    path: '/campaigns/sequences',
    exact: true,
    name: 'Sequences',
    component: Sequences,
  },
  {
    path: '/campaigns/tags',
    name: 'Tags',
    component: Tags,
    exact: true,
  },
  {
    path: '/content/layouts',
    name: 'Layouts',
    component: LayoutsList,
    exact: true,
  },
  {
    path: '/content/layouts/:layoutId',
    name: 'Layout Detail',
    component: LayoutsDetails,
    exact: true,
  },
  {
    path: '/content/layouts/create',
    name: 'Layout Detail',
    component: LayoutsDetails,
    exact: true,
  },
  {
    path: '/content/containers',
    name: 'Containers',
    component: ContainersList,
    exact: true,
  },
  {
    path: '/content/containers/:containerId',
    name: 'Container Detail',
    component: ContainersDetails,
    exact: true,
  },
  {
    path: '/features',
    name: 'Feature Toggles',
    component: FeatureToggles,
    exact: true,
  },
  {
    path: '/content/containers/create',
    name: 'Create Container',
    component: ContainersDetails,
    exact: true,
  },
  {
    path: '/content/handlers',
    name: 'Handlers',
    component: HandlersSummary,
    exact: true,
  },
  {
    path: '/content/template-editor',
    name: 'Template Editor',
    component: TemplateEditor,
    exact: true,
  },
  {
    path: '/content/userAttributes',
    name: 'User Attributes',
    component: UserAttribute,
    exact: true,
  },
  {
    path: '/campaigns/sites',
    name: 'Sites',
    component: SiteScreen,
    exact: true,
  },
  {
    path: '/campaigns/sites/:siteId',
    name: 'Site Detail',
    component: SiteDetail,
    exact: true,
  },
];

export default routes;
