import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const GenericModal = ({
  // [button]: Object with button attributes
  button,
  // [Main Content]: Displayed as the body of the modal
  children,
  // [string]: Styles to apply to modal
  className,
  // [boolean]: Footer Content
  footer = false,
  // [String | Input]: Header Content
  header,
  // [boolean]: Modal opens when true
  isOpen,
  // [string]: Redirect url after button click
  redirectLink,
  // [string]: Text for second button
  secondaryButtonText,
  // [func]: Onclick fuction for second button
  secondaryButtonOnClick,
  // [func] : Toggles isOpen state
  toggleModal,
}) => {
  const modalFooter = () => {
    const { text } = button || '';
    delete button.text;

    return (
      <>
        <Button {...button}>{text}</Button>
        <Button
          aria-label={(secondaryButtonText ? secondaryButtonText : 'Secondary') + ' Modal Button'}
          color='secondary'
          id='modal-secondary-button'
          onClick={secondaryButtonOnClick || toggleModal}
          value={secondaryButtonText || 'cancel'}
        >
          {secondaryButtonText || 'Cancel'}
        </Button>
      </>
    );
  };

  const confirmHandler = (event) => event.preventDefault();
  
  return (
    <Modal isOpen={isOpen} toggle={toggleModal} className={className || 'modal-info'}>
      <ModalHeader toggle={toggleModal}>{header}</ModalHeader>
      <form onSubmit={confirmHandler}>
        <ModalBody>{children}</ModalBody>
        {footer && <ModalFooter>{modalFooter()}</ModalFooter>}
      </form>
    </Modal>
  );
};

export default GenericModal;
