import React, { useEffect, useState } from 'react';
import { Button, Col, Input, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { setNewLandingPageName } from '../actions';
import { resetModal } from '../../../actions/';

const colors = {
  warning: '#FFC107',
  error: '#FF485D',
};

const modalBodyStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

export default function SaveAsLandingPageModal({ getErrors, onClickSubmit, content, isEditMode }) {
  const dispatch = useDispatch();
  const isLandingPageSaved = useSelector((state) => state.landingPage.isLandingPageSaved);
  const newLandingPageName = useSelector((state) => state.landingPage.newLandingPageName);
  const saveError = useSelector((state) => state.landingPage.saveError);

  const [isOpen, setIsOpen] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [validationError, setValidationError] = useState(null);

  useEffect(() => {
    if (isLandingPageSaved && isSaving) {
      handleClose();
    }

    const errors = getErrors();
    if (errors.invalid || (saveError && saveError.response)) {
      setValidationError(saveError?.response?.data?.message || 'Validation error');
    }
  }, [isLandingPageSaved, saveError, getErrors]);

  const handleClose = () => {
    setIsOpen(false);
    setIsSaving(false);
    setValidationError(null);
    dispatch(resetModal());
  };

  const handleSave = async () => {
    if (newLandingPageName === '') {
      setValidationError('Must provide a name');
      return;
    }

    setIsSaving(true);
    setValidationError(null);

    const isSuccessful = await onClickSubmit();
    if (isSuccessful) {
      handleClose();
    } else {
      setValidationError('Failed to save');
      setIsSaving(false);
    }
  };

  const handleNameChange = (event) => {
    dispatch(setNewLandingPageName(event.target.value));
    if (validationError) {
      setValidationError(null);
    }
  };

  const errors = getErrors();
  const isDisabled = errors.invalid || !isEditMode;
  const inputStyle = validationError === 'Must provide a name' ? { borderColor: colors.error } : {};

  return (
    <div className='animated'>
      <Button
        color='success'
        onClick={() => setIsOpen(true)}
        disabled={isDisabled}
        style={{
          marginBottom: '2%',
          marginLeft: '2%',
          float: 'right',
        }}
      >
        Copy Landing Page
      </Button>
      <Modal
        isOpen={isOpen}
        toggle={isSaving ? undefined : handleClose}
        onClosed={handleClose}
        className={'modal-warning'}
      >
        <ModalHeader toggle={handleClose}>Copy</ModalHeader>

        {saveError ? (
          <ModalBody>
            <Row>
              <Col lg={2} style={modalBodyStyle}>
                <i className='fa fa-exclamation-triangle fa-3x' style={{ color: colors.error }} />
              </Col>
              <Col lg={10}>
                <span>Error copying landing page. Please close and try again.</span>
                <br />
                <span>Message: {saveError.response?.data?.message || 'Unknown error'}</span>
                <br />
              </Col>
            </Row>
          </ModalBody>
        ) : (
          <ModalBody>
            <Row>
              <Col lg={2} style={modalBodyStyle}>
                <i className='fa fa-warning fa-3x' style={{ color: colors.warning }} />
              </Col>
              <Col lg={10}>
                <h3>{content && content.name}</h3>
                Any changes made without saving will be <strong>copied</strong> to the new offer but not reflected in
                the <strong>current</strong> offer until you click save offer.
              </Col>
            </Row>
            <br />
            <Row>
              <Col lg={12}>
                {validationError === 'Must provide a name' && (
                  <span style={{ color: colors.error }}>{validationError}</span>
                )}
                <Input
                  name='newLandingPageName'
                  placeholder='New Landing Page Name'
                  type='text'
                  onChange={handleNameChange}
                  value={newLandingPageName}
                  style={inputStyle}
                />
              </Col>
            </Row>
          </ModalBody>
        )}
        <ModalFooter>
          {saveError ? (
            <Button color='secondary' onClick={handleClose}>
              Close
            </Button>
          ) : (
            <>
              <Button color='warning' disabled={isSaving} onClick={handleSave}>
                {isSaving ? 'Processing' : 'Copy'}
              </Button>
              <Button color='secondary' disabled={isSaving} onClick={handleClose}>
                {isSaving ? 'Processing' : 'Cancel'}
              </Button>
            </>
          )}
        </ModalFooter>
      </Modal>
    </div>
  );
}
