import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Alert, Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { resetModal } from '../../actions';

const initialData = {
  isOpen: false,
  formValidationError: false,
  formErrorMessage: null,
  saveErrorMessage: null,
  saveError: false,
  saveWarning: false,
  saveWarningMessage: null
};
const states = {
  open: 'OPEN',
  closed: 'CLOSED',
  saving: 'SAVING',
  updating: 'UPDATING',
  saveError: 'SAVE_ERROR',
  saveWarning: 'SAVE_WARNING',
  formValidationError: 'FORM_VALIDATION_ERROR'
};
const colors = {
  success: '#4DBD74',
  error: '#FF485D',
  warning: '#FFC107'
};

const modalBodyStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
};

class SaveEntityModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      state: states.closed,
      data: initialData
    };
  }

  componentWillReceiveProps(nextProps, nextContext) {
    // toggle success state if stuck
    const isStuck = this.state.state === states.submitting;
    if (isStuck) {
      this.moveToState(states.closed);
    }

    // if form submission failed due to validation
    const errors = this.props.getErrors();
    if (errors.invalid) {
      this.moveToState(states.formValidationError);
    }
  }

  moveToState = (state, payload = {}) => {
    const { open, closed, saving, updating, saveError, saveWarning, formValidationError } = states;

    switch (state) {
      case open:
        this.setState(prevState => ({
          state: open,
          data: { ...prevState.data, isOpen: true, formValidationError: false }
        }));
        break;
      case closed:
        setTimeout(() => {
          this.props.resetModal();
        }, 5000);
        this.setState(prevState => ({
          state: closed,
          data: { ...prevState.data, ...initialData }
        }));
        break;
      case saving:
        const afterSetLocal = async () => {
          try {
            const isSuccessful = await this.props.onClickSubmit();
            if (isSuccessful) {
              this.moveToState(closed);
            } else {
              const errors = this.props.getErrors();
              this.moveToState(saveError, { saveError: errors.saveError, saveErrorMessage: errors._error });
              this.moveToState(saveWarning, { saveWarning: errors.saveWarning, saveWarningMessage: errors._warning })
            }
          } catch (error) {
            this.moveToState(saveError, { saveError: true, saveErrorMessage: error.message });
          }
        };

        this.setState(
          prevState => ({
            state: saving,
            data: { ...prevState.data, formErrorMessage: null }
          }),
          afterSetLocal
        );

        break;
      case updating:
        this.setState(
          prevState => ({
            state: updating,
            data: {
              ...prevState.data,
              formErrorMessage: null
            }
          }),
          () => {
            this.moveToState(open);
          }
        );
        break;
      case saveError:
        this.setState(prevState => ({
          state: saveError,
          data: { ...prevState.data, ...payload }
        }));
        break;
      case saveWarning:
        this.setState(prevState => ({
          state: saveWarning,
          data: { ...prevState.data, ...payload }
        }));
        break;
      case formValidationError:
        this.setState(prevState => ({
          state: formValidationError,
          data: { ...prevState.data }
        }));
        break;
      default:
        console.log(`<${state}> is not a valid state for ${this.props.entityType} modal`);
    }
  };

  save = () => {
    this.moveToState(states.saving);
  };

  closeModal = () => {
    this.moveToState(states.closed);
  };

  openModal = () => {
    this.moveToState(states.open);
  };

  toggleFunc = () => {
    const { state } = this.state;

    if (state !== states.saving) {
      return this.moveToState(states.closed);
    }

    return () => {};
  };

  /**
   * Need this to share what an error means to rendering the body and footer in the modal.
   * This differs from this.props.getErrors in that it is used to tell if the button should be shown or not.
   */
  shouldRenderError = () => {
    return this.state.data.saveError || null;
  };

  shouldRenderWarning = () => {
    return this.state.data.saveWarning || null;
  };

  renderErrorBody = () => {
    const errorMessage = this.state.data.saveErrorMessage || '';

    return (
      <ModalBody>
        <Row>
          <Col lg={2} style={modalBodyStyle}>
            <i className='fa fa-exclamation-triangle fa-3x' style={{ color: colors.error }} />
          </Col>
          <Col lg={10}>
            <span>Error saving {`${this.props.entityType}`}. Please close and try again.</span>
            <br />
            <br />
            <span>Message:</span>
            <br />
            <br />
            <Alert color='danger'>{errorMessage}</Alert>
            <br />
          </Col>
        </Row>
      </ModalBody>
    );
  };

  renderWarningBody = () => {
    return (
      <ModalBody>
        <Row>
          <Col lg={2} style={modalBodyStyle}>
            <i className='fa fa-exclamation-triangle fa-3x' style={{ color: colors.warning }} />
          </Col>
          <Col lg={10}>
            <span>Unexpected behavior while saving {this.props.entityType}.</span>
            <br />
            <br />
            <Alert color='warning'>{this.state.data.saveWarningMessage || ''}</Alert>
          </Col>
        </Row>
      </ModalBody>
    );
  }

  renderNormalBody = () => {
    return (
      <ModalBody>
        <Row>
          <Col lg={2} style={modalBodyStyle}>
            <i className='fa fa-check-circle-o fa-3x' style={{ color: colors.success }} />
          </Col>
          <Col lg={10}>
            <h3>{this.props.content && this.props.content.name}</h3>
            You are about to {this.props.isEditMode ? 'edit this ' : 'create a new '}
            {this.props.entityType}:
            <br />
            <h3>{this.props.entityName}</h3>
          </Col>
        </Row>
        <br />
        <hr />
        <Row>
          <Col lg={12}>
            <em>This may take a moment to complete.</em>
          </Col>
        </Row>
        <br />
      </ModalBody>
    );
  };

  renderBody = () => {
    if (this.shouldRenderError()) {
      return this.renderErrorBody();
    }
    if (this.shouldRenderWarning()) {
      return this.renderWarningBody();
    }
    return this.renderNormalBody();
  };

  getModalType = () => {
    if (this.shouldRenderError()) {
      return 'modal-danger';
    }
    if (this.shouldRenderWarning()) {
      return 'modal-warning';
    }
    return 'modal-info';
  }

  renderErrorFooter = () => {
    return (
      <ModalFooter>
        <Button color='secondary' onClick={this.closeModal}>
          Close
        </Button>
      </ModalFooter>
    );
  };

  renderNormalFooter = () => {
    const { state } = this.state;
    const isSaving = state === states.saving;

    return (
      <ModalFooter>
        <Button color='info' style={{ color: 'white' }} disabled={isSaving} onClick={this.save}>
          {isSaving ? 'Processing' : 'Publish'}
        </Button>
        <Button color='secondary' disabled={isSaving} onClick={this.closeModal}>
          {isSaving ? 'Processing' : 'Cancel'}
        </Button>
      </ModalFooter>
    );
  };

  renderFooter = () => {
    if (this.shouldRenderError() || this.shouldRenderWarning()) {
      return this.renderErrorFooter();
    } else {
      return this.renderNormalFooter();
    }
  };

  render() {
    const errors = this.props.getErrors();
    const isDisabled = errors.invalid;

    return (
      <div className='animated'>
        <Button
          color='info'
          onClick={this.openModal}
          disabled={isDisabled}
          style={{
            color: 'white',
            marginBottom: '2%',
            marginLeft: '2%',
            float: 'right'
          }}
          className={this.props.className}
        >
          Publish {`${this.props.entityType}`}
        </Button>
        <Modal
          isOpen={this.state.data.isOpen}
          toggle={this.toggleFunc}
          onClosed={this.closeModal}
          className={this.getModalType()}
        >
          <ModalHeader toggle={this.closeModal}>Publish {`${this.props.entityType}`}</ModalHeader>
          {this.renderBody()}
          {this.renderFooter()}
        </Modal>
      </div>
    );
  }
}

const mapState = state => {
  return {};
};

export default connect(mapState, { resetModal })(SaveEntityModal);
