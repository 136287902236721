import { useQueryClient } from '@tanstack/react-query';
import React, { useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import { CSVReader } from 'react-papaparse';
import { Button, Col, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import api from '../../services';
import './RevSharePercentage.css';
import { columnsModal } from './columns';
export const UploadCSV = (props) => {
  const [csvUpload, setCsvUpload] = useState([]);
  const [csvSummaryModalOpen, setCsvSummaryModalOpen] = useState(false);
  const [revSharePercentageRulesCSV, setRevSharePercentageRulesCSV] = useState([]);
  const [errors, setErrors] = useState([]);
  const [warnings, setWarnings] = useState([]);
  const [rulesToSkip, setRulesToSkip] = useState({});
  const [warningsConfirmed, setWarningsConfirmed] = useState(false);
  const queryClient = useQueryClient();

  const GREY = '#CCC';
  const GREY_ZUUL = '#73818f';

  const formatDate = (dataObj) => {
    dataObj = typeof dataObj === 'string' ? new Date(dataObj) : dataObj;

    if (dataObj === undefined) {
      return '';
    } else {
      return dataObj.toISOString();
    }
  };

  const rowClasses = (row, rowIndex) => {
    let classes = null;
    if (row.id === null && row.default) {
      classes = 'new-rule';
    }
    if (rulesToSkip[rowIndex + 1]) {
      classes = 'rule-skip';
    }
    if (errors.find((error) => error.row === rowIndex + 1)) {
      classes = 'rule-error';
    }
    return classes;
  };

  const findRuleInConflict = (newRule, id) =>
    props.revSharePercentageRules.find((rule) => {
      if (
        (!id || rule.id !== id) &&
        datesOverlap(rule, newRule) &&
        rule.affiliateId === newRule.affiliateId &&
        rule.default === newRule.default &&
        rule.webDomain === newRule.webDomain &&
        rule.subId == newRule.subId &&
        rule.aibListId == newRule.aibListId &&
        rule.aibTypeId == newRule.aibTypeId &&
        rule.landingPageGroupId == newRule.landingPageGroupId
      ) {
        return true;
      }
      return false;
    });

  const datesOverlap = (rule, newRule) => {
    if (rule.endDate && newRule.endDate) {
      const ruleStartDate = new Date(rule.startDate);
      const ruleEndDate = new Date(rule.endDate);
      const newRuleStartDate = new Date(newRule.startDate);
      const newRuleEndDate = new Date(newRule.endDate);
      if (
        (newRuleStartDate.getTime() >= ruleStartDate.getTime() && newRuleStartDate.getTime() < ruleEndDate.getTime()) ||
        (ruleStartDate.getTime() >= newRuleStartDate.getTime() && ruleStartDate.getTime() < newRuleEndDate.getTime())
      ) {
        return true;
      }
    }
    return false;
  };

  let defaultRulesFromCSV2 = [];
  const getDefaultRuleForAffiliateId = (affiliateId) =>
    props.revSharePercentageRules.find((el) => {
      return el.default && el.affiliateId === affiliateId;
    }) ||
    defaultRulesFromCSV2.find((el) => {
      return el.default && el.affiliateId === affiliateId;
    });

  const validateRule = (rule, row) => {
    const defaultRule = getDefaultRuleForAffiliateId(rule.affiliateId);
    let validationFailed = false;
    if (rule.endDate != null && Date.parse(rule.endDate) < Date.parse(rule.startDate)) {
      addError(`End date is before startDate`, row);
      validationFailed = true;
    }
    if (!props.allAffiliates.some((affiliate) => affiliate.id === rule.affiliateId)) {
      addError(`No affiliate exists for Id: ${rule.affiliateId}`, row);
      validationFailed = true;
    }
    if (!defaultRule) {
      if (rule.default) {
        defaultRulesFromCSV2 = [...defaultRulesFromCSV2, rule];
      } else {
        addError(`No default rule exists for AffiliateId: ${rule.affiliateId}`, row);
        validationFailed = true;
      }
    } else {
      if (rule.default && defaultRule.id !== rule?.id) {
        addWarning(`Affiliate already has a default rule, see rule with Id: ${defaultRule.id}`, row, defaultRule);
        validationFailed = true;
      } else {
        const oldRuleFromDBId = props.revSharePercentageRules.find((oldRules) => oldRules.id === rule.id)?.id;
        const ruleInConflict = findRuleInConflict(rule, oldRuleFromDBId);
        if (ruleInConflict) {
          addWarning(`Rule parameters conflict with rule with Id: ${ruleInConflict.id}`, row, ruleInConflict);
          validationFailed = true;
        }
      }
    }
    if (validationFailed) {
      setRulesToSkip((existing) => ({ ...existing, [row]: true }));
    } else {
      setRulesToSkip((existing) => ({ ...existing, [row]: false }));
    }
  };

  const onSubmit = (form, oldRule) => {
    console.log('🚀 ~ file: UploadCSV.js ~ line 35 ~ onSubmit ~ form', form);
    console.log('Hit Summary Modal onSubmit');
    if (typeof form.startDate === 'string') {
      var startDate = new Date(form.startDate);
      startDate.setDate(startDate.getDate() + 1);
      form.startDate = formatDate(startDate);
    }
    if (typeof form.endDate === 'string') {
      if (form.endDate !== '') {
        var endDate = new Date(form.endDate);
        endDate.setDate(endDate.getDate() + 1);
        form.endDate = formatDate(endDate);
      }
    }
    if (form.default) {
      delete form.endDate;
      delete form.webDomain;
      delete form.subId;
      delete form.landingPageGroupId;
    }
    delete form.affiliateName;

    console.log('Saving RevShare Record:', form);
    if (form.id) {
      api
        .put(`revshare-percentage-rules/${form.id}`, form, {
          params: {
            override: true,
          },
        })
        .finally(() => {
          setCsvSummaryModalOpen(false);
          queryClient.invalidateQueries('revSharePercentageRules');
        });
    } else {
      api
        .post(`revshare-percentage-rules`, form, {
          params: {
            override: true,
          },
        })
        .finally(() => {
          setCsvSummaryModalOpen(false);
          queryClient.invalidateQueries('revSharePercentageRules');
        });
    }
  };

  const handleOnDrop = (data) => {
    setErrors([]);
    setWarnings([]);
    setRulesToSkip({});
    setWarningsConfirmed(false);
    const revShares = data.map((csvData) => csvData.data).sort((a, b) => (a.default ? -1 : b.default ? 1 : 0));
    revShares.map((element) => {
      element.affiliateName = props?.allAffiliates?.find(
        (el) => el.networkAffiliateId === parseInt(element.affiliateId)
      )
        ? props?.allAffiliates?.find((el) => el.networkAffiliateId === parseInt(element.affiliateId))?.name
        : '';
      return element;
    });
    setCsvUpload(revShares);
    setRevSharePercentageRulesCSV([...revShares]);
    revShares.forEach((rule, row) => {
      validateRule(rule, row + 1);
    });
    setCsvSummaryModalOpen(true);
  };

  const handleOnRemoveFile = (data) => {
    setErrors([]);
    setWarnings([]);
    setRulesToSkip({});
    setWarningsConfirmed(false);
  };

  const addError = (message, row) => {
    setErrors((existing) => [...existing, { row, message: `Row ${row}: ${message}` }]);
  };

  const addWarning = (message, row, ruleInConflict) => {
    setWarnings((existing) => [...existing, { row, message: `Row ${row}: ${message}`, ruleInConflict }]);
  };

  const handleChangeCheckWarning = (event) => {
    setRulesToSkip({ ...rulesToSkip, [event.target.name]: !event.target.checked });
  };

  return (
    <div>
      <Modal isOpen={csvSummaryModalOpen} size='xl'>
        <ModalHeader>Upload Revshare</ModalHeader>
        <ModalBody>
          <ToolkitProvider bootstrap4 keyField='affiliateId' data={csvUpload} columns={columnsModal} search>
            {(props) => (
              <>
                <BootstrapTable rowClasses={rowClasses} {...props.baseProps} />
              </>
            )}
          </ToolkitProvider>
          {errors.length !== 0 && (
            <div class='alert alert-danger' role='alert'>
              <h4 class='alert-heading'>Error</h4>
              <hr />
              <p class='alert-heading'>Please fix the following errors before submitting:</p>
              <ul>
                {errors.map((error) => (
                  <li>{error.message}</li>
                ))}
              </ul>
            </div>
          )}
          {warnings.length !== 0 && (
            <div class={`alert ${warningsConfirmed ? 'alert-dark' : 'alert-warning'}`} role='alert'>
              <h4 class='alert-heading'>Warning</h4>
              <hr />
              <p class='alert-heading'>
                Please review<sup>*</sup> the following conflicts before submitting:
              </p>
              <Col className='checkboxes-warnigns'>
                {warnings.map((warning) => (
                  <Row>
                    <Input
                      type='checkbox'
                      name={warning.row}
                      onChange={handleChangeCheckWarning}
                      disabled={warningsConfirmed}
                    />
                    <Label check>{warning.message}</Label>
                  </Row>
                ))}
              </Col>
              <Row hidden={warningsConfirmed}>
                <Button color='success' className='warning-buttons' onClick={() => setWarningsConfirmed(true)}>
                  Confirm
                </Button>
                <Button
                  color='primary'
                  className='warning-buttons'
                  onClick={() => {
                    warnings.forEach((warning) => {
                      setRulesToSkip((existing) => ({ ...existing, [warning.row]: true }));
                    });
                    setWarningsConfirmed(true);
                  }}
                >
                  Skip All
                </Button>
                <Button
                  color='primary'
                  className='warning-buttons'
                  onClick={() => {
                    warnings.forEach((warning) => {
                      setRulesToSkip((existing) => ({ ...existing, [warning.row]: false }));
                    });
                    setWarningsConfirmed(true);
                  }}
                >
                  Override All
                </Button>
              </Row>
              <Row hidden={!warningsConfirmed}>
                <Button color='primary' className='warning-buttons' onClick={() => setWarningsConfirmed(false)}>
                  Edit
                </Button>
              </Row>
              <sub>
                (*) Selected rules will override existing rules by changing the end date of the conflicted rule to the
                new rule's start date, otherwise if left unchecked the new conflicting rules will be skipped.
              </sub>
            </div>
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            type='submit'
            color='success'
            onClick={() => {
              revSharePercentageRulesCSV.forEach((rules, row) => {
                if (rulesToSkip[row + 1] === false) {
                  let oldRuleFromDB = props.revSharePercentageRules.find((oldRules) => oldRules.id === rules.id);
                  onSubmit(rules, oldRuleFromDB, row);
                }
              });
            }}
            disabled={errors.length !== 0 || (warnings.length !== 0 && !warningsConfirmed)}
          >
            Submit
          </Button>
          <Button color='primary' onClick={() => setCsvSummaryModalOpen(false)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
      <CSVReader
        onDrop={handleOnDrop}
        addRemoveButton={true}
        noDrag={false}
        onRemoveFile={handleOnRemoveFile}
        config={{
          header: true,
          skipEmptyLines: true,
          dynamicTyping: true,
        }}
        isReset={true}
        style={{
          dropArea: {
            alignItems: 'center',
            borderColor: `2px dashed ${GREY}`,
            borderRadius: 10,
            display: 'flex',
            height: '100px',
            textAlign: 'center',
          },
          dropFile: {
            background: GREY_ZUUL,
            color: 'white',
            borderRadius: 10,
            display: 'flex',
            height: '80px',
            width: '80%',
            position: 'relative',
            zIndex: 10,
            flexDirection: 'column',
            justifyContent: 'center',
            fontFamily: 'Roboto, sans-serif',
          },
          dropAreaActive: {
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            padding: '0 10',
          },
          fileSizeInfo: {
            backgroundColor: GREY_ZUUL,
            borderRadius: 3,
            marginBottom: '0.5em',
            justifyContent: 'center',
            display: 'flex',
            fontSize: 14,
          },
          fileNameInfo: {
            backgroundColor: GREY_ZUUL,
            borderRadius: 3,
            fontSize: 14,
            marginBottom: '0.5em',
          },
          progressBar: {
            // bottom: 1,
            position: 'absolute',
            width: '100%',
            padding: '0 10',
          },
          zoneHover: {
            borderColor: GREY, // backgroundColor: GREY_ZUUL_BACK,
          },
          default: {
            borderColor: GREY,
          },
          removeButton: {
            height: 23,
            position: 'absolute',
            right: 6,
            top: 6,
            width: 23,
          },
        }}
      >
        Upload New Revshare Percentage Rule
      </CSVReader>
    </div>
  );
};
