import React, { useState } from 'react';
import { Field } from 'react-final-form';
import { Alert, Button, Col, Input, InputGroup, InputGroupAddon, Label, Row } from 'reactstrap';
import SearchLabel from '../../../components/SearchLabel/SearchLabel';
import DraggableOffer from './DraggableOffer';

const LeftAside = (props) => {
  const [searchTerm, setSearchTerm] = useState('');

  const filteredOffers = props.offers?.filter((offer) => {
    const searchTermLower = searchTerm.toLowerCase();

    const name = offer.name.toLowerCase();
    const id = offer.id.toString();
    return name.includes(searchTermLower) || id.includes(searchTermLower);
  });

  return (
    <>
      <Row>
        <Col>
          <Label>Offer Block Name</Label>
          <Field name='offer.name'>
            {({ input, meta: { touched, error } }) => (
              <>
                <InputGroup>
                  <Input {...input} placeholder='Offer Block Name' type='text' />
                  {props.isDraft && (
                    <InputGroupAddon addonType='append'>
                      <Button color='primary' className='draftBtn' disabled onClick={(e) => e.preventDefault()}>
                        Draft
                      </Button>
                    </InputGroupAddon>
                  )}
                </InputGroup>
                {touched && error && <Alert color='danger'>{error}</Alert>}
              </>
            )}
          </Field>
        </Col>
      </Row>
      <Row>
        <Col>
          <SearchLabel />
          <Input
            type='text'
            placeholder='Use spaces to separate search terms'
            onChange={(e) => setSearchTerm(e.target.value)}
            value={searchTerm}
          />
        </Col>
      </Row>
      <Row>
        <div className='draggableOffersPool'>
          {filteredOffers?.map((offer) => {
            return <DraggableOffer offer={offer} key={offer.id} />;
          })}
        </div>
      </Row>
    </>
  );
};

export default LeftAside;
