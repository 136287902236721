import React from 'react';
import { Badge } from 'reactstrap';
import './SaveOfferModal.css';

const ActiveStatusBadge = ({ isActive }) => {
  return (
    <div className='animated'>
      <Badge className={'float-right'} color={isActive ? 'success' : 'danger'}>
        {isActive ? 'Active' : 'Inactive'}
      </Badge>
    </div>
  );
};

export default ActiveStatusBadge;
