import React, { useState } from 'react';
import { Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { Field, Form } from 'react-final-form';
import { renderField, textarea } from '../Offers/OfferTitle/renderConsts';
import { SelectField } from '../../components/FinalForm';
import ValueInputSelector from './ValueInputSelector';
import { validateDropdownValues } from './utils';

const required = (value) => (value ? undefined : 'Required');

const headerTitle = (id) => `${id ? 'Edit' : 'Create'} Custom Variable Modal`;

const CustomVariablesModal = ({ isModalOpen, onSubmit, row, toggleModal }) => {
  const [dropdownValues, setDropdownValues] = useState([{ id: 0, value: '' }]);
  const [validateState, setValidateState] = useState({
    isDuplicate: {},
    isEmpty: {},
  });

  const onFormSubmit = (values) => {
    const { isDuplicate, isEmpty, isValid } = validateDropdownValues(dropdownValues);
    setValidateState({ isDuplicate, isEmpty });

    if (values.inputMethod === 'DROPDOWN' && isValid) {
      const formattedValues = {
        ...values,
        inputValues: dropdownValues.map((item) => item.value.trim()).filter((value) => value !== ''),
      };
      onSubmit(formattedValues);
    } else {
      onSubmit(values);
    }
  };

  return (
    <Form
      initialValues={row}
      onSubmit={onFormSubmit}
      render={({ handleSubmit, submitting, values }) => (
        <form onSubmit={handleSubmit}>
          <Modal isOpen={isModalOpen} toggle={toggleModal} className={'modal-info'}>
            <ModalHeader toggle={toggleModal}>{headerTitle(row?.id)}</ModalHeader>
            <ModalBody>
              <label>Name:</label>
              <div>
                <Field
                  name='name'
                  component={renderField}
                  type='text'
                  placeholder='Custom Variable Name'
                  validate={required}
                />
              </div>
              <label>Key:</label>
              <div>
                <Field name='key' component={renderField} type='text' placeholder='key' validate={required} />
              </div>
              <label>Scope:</label>
              <div>
                <SelectField
                  name='scope'
                  options={[
                    { value: '', option: 'Select Scope...' },
                    { value: 'DUD', option: 'Domain (DUD)' },
                    { value: 'SUD', option: 'Sequence (SUD)' },
                    { value: 'UD', option: 'Offer/LP (UD)' },
                    { value: 'GLOBAL', option: 'Global' },
                  ]}
                />
              </div>
              <br />
              <label>Value Type:</label>
              <div>
                <SelectField
                  name='valueType'
                  options={[
                    { value: '', option: 'Select Value type...' },
                    { value: 'BOOLEAN', option: 'Boolean' },
                    { value: 'NUMBER', option: 'Number' },
                    { value: 'STRING', option: 'String' },
                  ]}
                />
              </div>
              <ValueInputSelector
                dropdownValues={dropdownValues}
                isModalOpen={isModalOpen}
                row={row}
                setResults={setDropdownValues}
                setDropdownValues={setDropdownValues}
                setValidateState={setValidateState}
                values={values}
                validateState={validateState}
              />
              <br />
              <label>Description:</label>
              <div>
                <Field name='description' component={textarea} type='text' />
              </div>
            </ModalBody>
            <ModalFooter>
              <Row>
                <Col>
                  <Button color='primary' type='submit' disabled={submitting} onClick={handleSubmit}>
                    Submit
                  </Button>
                </Col>
                <Col>
                  <Button color='secondary' onClick={toggleModal}>
                    Cancel
                  </Button>
                </Col>
              </Row>
            </ModalFooter>
          </Modal>
        </form>
      )}
    />
  );
};

export default CustomVariablesModal;
