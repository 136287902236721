import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import api from '../../services';

export const useCustomVariables = () =>
  useQuery({
    queryKey: ['customVariables'],
    queryFn: () => api.get('customVariables').then((res) => res.data),
  });

export const useSaveCustomVariable = () => {
  const queryClient = useQueryClient();

  const mutationFn = async (data) => await (data?.id ? useUpdateCustomVariable(data) : useCreateCustomVariable(data));

  return useMutation({
    mutationFn: mutationFn,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['customVariables'] });
    },
  });
};

const useCreateCustomVariable = (data) => api.post(`customVariables`, data);

const useUpdateCustomVariable = (data) => api.put(`customVariables/${data.id}`, data);
