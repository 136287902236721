import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import 'react-widgets/styles.css';
import { Button, Card, CardBody, CardHeader, Col, Collapse, Row } from 'reactstrap';
import { Field, FieldArray, reduxForm } from 'redux-form';
import { getOfferTriggersByOfferId, removeOfferTriggerState, saveInitData } from '../../../../actions';
import Criteria from '../../../../components/Criteria/Criteria';
import PreviewOfferModal from '../../../../components/PreviewOfferModal/PreviewOfferModal';
import api from '../../../../services';
import { handleCriteriaAffNames } from '../../../../utils/CriteriaHelpers';
import { filterActionTypesByLinkoutCount } from '../../../../utils/OfferActionHelper';
import {
  convertBase64FileToImage,
  convertDailyRestrictionToArray,
  convertImageFileToBase64,
} from '../../../../utils/conversionFunctions';
import validate from '../../validate';
import CancelOfferModal from '../CancelOfferModal';
import CopyButton from '../CopyButton';
import FormEditor from '../OfferQuestion/FormEditor';
import '../OfferQuestion/OfferQuestion.css';
import OfferVariationModalButton from '../OfferVariationModalButton';
import SaveOfferModal from '../SaveOfferModal';
import { initializeOfferTriggers } from '../offerTitleHelper/offerTitleHelper';
import {
  renderActionTypeDropdown,
  renderField,
  renderOfferPostAction,
  renderSelectedOfferAction,
  renderToggleSwitch,
} from '../renderConsts';
import CustomVariablesCard from '../../../../components/CustomVariables/CustomVariablesCard';
import { processCustomVariables } from '../../../../components/CustomVariables/utils';

class OfferJavascript extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: '1',
      action: '',
      selectValue: 'Monday',
      value: [],
      imageFile: [],
      isQuestionAction: false,
      isEditMode: false,
      userAttribute: 'select',
      questionActions: [],
      questionActionChildren: 'select',
      advertisers: [],
      initialState: 'select',
      offerContent: {},
      collapseJavascript: true,
      collapseHeaderFooter: false,
      offerAction: '',
      prePingOption: '',
      httpIsHidden: true,
      errors: {
        disable: false,
        questionText: false,
        questionType: false,
        invalidYesNo: false,
      },
      answers: [],
      critQuestionAnswers: [],
      // offersSelect: []
    };
    this.toggle = this.toggle.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.handleInitialize = this.handleInitialize.bind(this);
    this.handleChangeQuestionText = this.handleChangeQuestionText.bind(this);
    this.handleChangeForUserAttribute = this.handleChangeForUserAttribute.bind(this);
    this.handleChangeForActions = this.handleChangeForActions.bind(this);
    this.handleChangeForActionsChildren = this.handleChangeForActionsChildren.bind(this);
    this.toggleHeaderFooter = this.toggleHeaderFooter.bind(this);
    this.handlePrePing = this.handlePrePing.bind(this);
    this.handleHttpStatusToggle = this.handleHttpStatusToggle.bind(this);
    this.getErrors = this.getErrors.bind(this);
    this.handleAnswers = this.handleAnswers.bind(this);
  }

  getErrors = () => {
    let errors = {
      ...this.props.errors,
      ...this.state.errors,
      invalid: this.props.submitFailed ? this.props.invalid : false,
    };
    return errors;
  };

  ageLimit = (value) => {
    return value && (value < 10 || value > 99) ? 'Age must be between 10 and 99' : undefined;
  };

  componentDidMount() {
    // const offerData = this.props.offers.map(o => {
    //   return {
    //     id: o.id !== null && o.id !== undefined ? o.id : '',
    //     name: o.name !== null ? o.name : ''
    //   };
    // });

    const { offer } = this.props;

    if (offer === null || (offer && !offer.id)) {
      this.setState(
        {
          offerContent: this.props.initData,
          isEditMode: false,
        },
        this.handleInitialize
      );
    } else if (offer.id) {
      this.props.getOfferTriggersByOfferId(offer.id).then((offerTriggers) => {
        console.log(offerTriggers);
        this.setState(
          {
            offerContent: offer,
            isEditMode: true,
          },
          this.handleInitialize
        );
      });
    }

    // this.props
    //   .offerCallBack()
    //   .then(c => {
    //     if (!c.id) {
    //       this.setState(
    //         {
    //           offerContent: this.props.initData
    //         },
    //         this.handleInitialize
    //       );
    //     } else {
    //       this.props.getOfferTriggersByOfferId(c.id).then(() => {
    //         this.setState(
    //           {
    //             offerContent: c,
    //             // offersSelect: offerData
    //           },
    //           this.handleInitialize
    //         );
    //       });
    //     }
    //   })
    //   .catch(error => {
    //     console.log(error);
    //   });
  }

  componentWillUnmount() {
    // console.log(this.props);
    if (!this.state.isEditMode) {
      this.props.saveInitData('offerjavascript');
    }
    this.props.destroy('offerjavascript');
    this.props.removeOfferTriggerState();
  }

  handleAnswers(event) {
    let ans = this.state.answers;
    if (this.state.answers.indexOf(event.target.value) <= -1) ans.push(event.target.value);
    this.setState({
      answers: ans,
      isQuestionAction: true,
    });
  }

  onSubmit(values) {
    if (!this.props.advertiser) {
      this.setState((prevState) => {
        return {
          ...prevState,
          errors: {
            ...prevState.errors,
            isAdvertiserMissing: true,
            _error: 'This offer is missing an advertiser.',
          },
        };
      });
      setTimeout(() => {
        this.setState({
          errors: {
            ...this.state.errors,
            isAdvertiserMissing: false,
            _error: null,
          },
        });
      }, 2000);
    }

    console.log('ON SUBMIT' + JSON.stringify(values));

    let contentValue = {};
    const questionText = values.questionText === null ? null : values.questionText;
    let questionType = values.questionType === null ? null : values.questionType;
    const questionAnswers = values.questionAnswers == null ? null : values.questionAnswers;
    const offerPosts = values.offerQuestionPosts == null ? null : values.offerQuestionPosts;
    let payoutType = null;
    let criteriaQuestionValue = null;
    if (values.criteriaQuestion !== undefined && values.criteriaQuestion !== null) {
      if (values.criteriaQuestion.name !== undefined && values.criteriaQuestion.name !== null) {
        criteriaQuestionValue = values.criteriaQuestion.name === null ? null : values.criteriaQuestion.name;
      } else {
        criteriaQuestionValue = values.criteriaQuestion === null ? null : values.criteriaQuestion;
      }
    }
    if (questionText !== null) {
      contentValue = { question_text: questionText };
    }

    if (questionAnswers != null && questionAnswers.length > 0) {
      contentValue = Object.assign({}, contentValue, {
        question_answers: questionAnswers,
      });
    }

    if (questionType !== null) {
      contentValue = Object.assign({}, contentValue, {
        question_type: questionType,
      });
    }

    const offerActionArray = [];
    if (offerPosts != null && offerPosts.length > 0) {
      contentValue = Object.assign({}, contentValue, {
        offer_posts: offerPosts,
      });
      offerPosts.forEach((action) => {
        offerActionArray.push({
          name: action.name,
          triggerType: action.post_type || 'ON_IMPRESSION',
          actionType: action.question_actions || 'POST',
          showOfferByOfferActionCriteria: action.shouldShowCriteria || false,
        });
      });
    }

    let questionData = Object.assign({}, values, {
      questionText: undefined,
      offerPosts: undefined,
      questionAnswers: undefined,
      questionType: undefined,
      offerQuestionPosts: undefined,
      offerActions: offerActionArray,
      criteriaQuestion: criteriaQuestionValue,
      content: JSON.stringify(contentValue) === '{}' ? '{}' : JSON.stringify(contentValue),
      customVariables:
        values.customVariables && values.customVariables.length > 0
          ? JSON.stringify(processCustomVariables(values.customVariables))
          : null,
    });

    this.props.saveOffer(questionData);
    this.setState({ isEditMode: true });
  }

  handleInitialize() {
    const offer = this.state.offerContent;
    const { offerTriggers } = this.props;
    const offerContent = this.state.offerContent.content == null ? null : JSON.parse(this.state.offerContent.content);
    let questionText = null,
      questionType = null,
      questionAnswers = null,
      suppressionRules = null,
      offerQuestionPosts = null;
    let initData;

    if (offer !== null) {
      const dayHourRestrictions =
        offer.criteriaDayHourRestrictions && offer.criteriaDayHourRestrictions !== null
          ? convertDailyRestrictionToArray(offer.criteriaDayHourRestrictions)
          : [];
      const offerUserAttributes =
        offer.criteriaUserAttributes && offer.criteriaUserAttributes !== null
          ? JSON.parse(offer.criteriaUserAttributes)
          : null;
      const suppressionRulesOffer =
        offer.suppressionRules && offer.suppressionRules !== null
          ? JSON.parse(offer.suppressionRules, function(k, v) {
              return typeof v === 'object' || isNaN(v) ? v : parseInt(v, 10);
            })
          : null;

      if (offerTriggers.length > 0) {
        offerQuestionPosts = offerTriggers === null ? null : initializeOfferTriggers(offerTriggers);
      }

      if (offerContent && offerContent !== null) {
        questionText = offerContent.question_text === null ? null : offerContent.question_text;
        questionType = offerContent.question_type === null ? null : offerContent.question_type;
        questionAnswers = offerContent.question_answers === null ? null : offerContent.question_answers;
        let answerArray = [];

        if (questionAnswers !== undefined && questionAnswers !== null) {
          for (let ans of questionAnswers) {
            if (answerArray.indexOf(ans) <= -1) {
              answerArray.push(ans.text);
            }
          }
        }
        answerArray.length > 0 && this.setState({ answers: answerArray });
      }

      initData = {
        offerQuestionPosts: offerQuestionPosts === null ? null : offerQuestionPosts,
        questionText: questionText === null ? 'Enter Question Text' : questionText,
        questionType: questionType === null ? 'Yes/No' : questionType,
        questionAnswers: questionAnswers === null ? null : questionAnswers,
        footer: this.state.offerContent.footer === null ? null : this.state.offerContent.footer,
        customVariables: offer.customVariables ?? null,
        //Offer Criteria
        //Users
        payoutType:
          offer.payoutType && offer.payoutType !== null && offer.payoutType.id !== null ? offer.payoutType.id : null,
        payoutAmount: offer.payoutAmount && offer.payoutAmount !== null ? offer.payoutAmount : 0,
        criteriaOfferRequiresOptIn:
          offer.criteriaOfferRequiresOptIn && offer.criteriaOfferRequiresOptIn === null
            ? false
            : offer.criteriaOfferRequiresOptIn,
        criteriaPhoneHasBeenVerifiedWithBriteverify:
          offer.criteriaPhoneHasBeenVerifiedWithBriteverify &&
          offer.criteriaPhoneHasBeenVerifiedWithBriteverify === null
            ? false
            : offer.criteriaPhoneHasBeenVerifiedWithBriteverify,
        criteriaEmailHasBeenVerifiedWithBriteverify:
          offer.criteriaEmailHasBeenVerifiedWithBriteverify === null
            ? false
            : offer.criteriaEmailHasBeenVerifiedWithBriteverify,
        criteriaPhoneBlacklist: offer.criteriaPhoneBlacklist === null ? false : offer.criteriaPhoneBlacklist,
        criteriaMaxAge: offer.criteriaMaxAge === null ? null : offer.criteriaMaxAge,
        criteriaMinAge: offer.criteriaMinAge === null ? null : offer.criteriaMinAge,
        criteriaExcludeBrowserType: offer.criteriaExcludeBrowserType === null ? null : offer.criteriaExcludeBrowserType,
        criteriaAibListIncludeExclude:
          offer.criteriaAibListIncludeExclude === null ? null : offer.criteriaAibListIncludeExclude,
        criteriaIncludeOrExcludeBrowsers:
          offer.criteriaIncludeOrExcludeBrowsers === null ? null : offer.criteriaIncludeOrExcludeBrowsers,
        criteriaIncludeOrExcludeDomains:
          offer.criteriaIncludeOrExcludeDomains === null ? null : offer.criteriaIncludeOrExcludeDomains,
        criteriaIncludeOrExcludeDevices:
          offer.criteriaIncludeOrExcludeDevices === null ? null : offer.criteriaIncludeOrExcludeDevices,
        criteriaIncludeOrExcludeOperatingSystems:
          offer.criteriaIncludeOrExcludeOperatingSystems === null
            ? null
            : offer.criteriaIncludeOrExcludeOperatingSystems,
        criteriaNullSourceNewUserIncludeExclude:
          offer.criteriaNullSourceNewUserIncludeExclude === null ? null : offer.criteriaNullSourceNewUserIncludeExclude,
        criteriaIncludeOrExcludeUserIdLastDigit:
          offer.criteriaIncludeOrExcludeUserIdLastDigit === null ? null : offer.criteriaIncludeOrExcludeUserIdLastDigit,
        criteriaUserIdLastDigit: offer.criteriaUserIdLastDigit === null ? [] : offer.criteriaUserIdLastDigit,
        criteriaAibList: offer.criteriaAibList === null ? null : offer.criteriaAibList,
        criteriaBrowsers: offer.criteriaBrowsers === null ? null : offer.criteriaBrowsers,
        criteriaDomains: offer.criteriaDomains === null ? null : offer.criteriaDomains,
        domainAffiliateConfiguration:
          offer.domainAffiliateConfiguration === null ||
          JSON.stringify(offer.domainAffiliateConfiguration?.domainAffiliateEntries) === '[]'
            ? null
            : offer.domainAffiliateConfiguration,
        criteriaDevices: offer.criteriaDevices === null ? null : offer.criteriaDevices,
        criteriaOperatingSystems: offer.criteriaOperatingSystems === null ? null : offer.criteriaOperatingSystems,
        criteriaExcludeGender: offer.criteriaExcludeGender === null ? null : offer.criteriaExcludeGender,
        criteriaIncludeOrExcludeStates:
          offer.criteriaIncludeOrExcludeStates === null ? null : offer.criteriaIncludeOrExcludeStates,
        criteriaIncludeOrExcludeZips:
          offer.criteriaIncludeOrExcludeZips === null ? null : offer.criteriaIncludeOrExcludeZips,
        criteriaZips: offer.criteriaZips === null ? null : offer.criteriaZips,
        criteriaRequiredUserData:
          !offer.criteriaRequiredUserData || offer.criteriaRequiredUserData === null
            ? null
            : offer.criteriaRequiredUserData.split(','),
        criteriaReturnFrequencyIncludeExclude: offer?.criteriaReturnFrequencyIncludeExclude,
        criteriaReturnFrequencyDomains: offer?.criteriaReturnFrequencyDomains,
        criteriaReturnFrequencyMax: offer?.criteriaReturnFrequencyMax,
        criteriaReturnFrequencyMin: offer?.criteriaReturnFrequencyMin,
        criteriaEmailDomains:
          !offer.criteriaEmailDomains || offer.criteriaEmailDomains === null
            ? null
            : offer.criteriaEmailDomains.split(','),
        criteriaLandingPageGroupIncludeExclude: offer.criteriaLandingPageGroupIncludeExclude ?? null,
        criteriaLandingPageGroupIds: offer.criteriaLandingPageGroupIds ?? null,
        criteriaLandingPageIncludeExclude: offer.criteriaLandingPageIncludeExclude ?? null,
        criteriaLandingPageIds: offer.criteriaLandingPageIds ?? null,
        //User Criteria
        criteriaQuestion: offer.criteriaQuestion === null ? null : offer.criteriaQuestion,
        criteriaQuestionAnswers: offer.criteriaQuestionAnswers === null ? null : offer.criteriaQuestionAnswers,
        criteriaIncludeOrExcludeUserAttributes:
          offer.criteriaIncludeOrExcludeUserAttributes === null ? null : offer.criteriaIncludeOrExcludeUserAttributes,
        criteriaUserAttributes: offerUserAttributes === null ? null : offerUserAttributes,
        criteriaUserAttributesAndOr:
          offer.criteriaUserAttributesAndOr === null ? null : offer.criteriaUserAttributesAndOr,
        criteriaIncludeOrExcludeAffNames:
          offer.criteriaIncludeOrExcludeAffNames === null ? null : offer.criteriaIncludeOrExcludeAffNames,
        criteriaAffNames:
          !offer.criteriaAffNames || offer.criteriaIncludeOrExcludeAffNames === 'None'
            ? []
            : handleCriteriaAffNames(offer.criteriaAffNames),
        criteriaStates: offer.criteriaStates === null ? null : offer.criteriaStates,
        criteriaIncludeOrExcludeEmailDomains:
          offer.criteriaIncludeOrExcludeEmailDomains === null ? null : offer.criteriaIncludeOrExcludeEmailDomains,
        //pre ping
        prePing: offer.prePing === null ? null : offer.prePing,
        prePingResultSessionCapture:
          offer.prePingResultSessionCapture === null ? null : offer.prePingResultSessionCapture,
        prePingMethod: offer.prePingMethod === null ? null : offer.prePingMethod,
        prePingHandlerId: offer.prePingHandlerId === null ? null : offer.prePingHandlerId,
        prePingCustomHttpStatus: offer.prePingCustomHttpStatus === null ? null : offer.prePingCustomHttpStatus,
        prePingBody: offer.prePingBody === null ? null : offer.prePingBody,
        isActive: offer.isActive === null ? true : offer.isActive,
        offerUrl: offer.offerUrl === null ? null : offer.offerUrl,
        prePingTimeout: offer.prePingTimeout === null ? null : offer.prePingTimeout,
        prePingHeader1: offer.prePingHeader1 === null ? null : offer.prePingHeader1,
        prePingHeader2: offer.prePingHeader2 === null ? null : offer.prePingHeader2,
        prePingHeader3: offer.prePingHeader3 === null ? null : offer.prePingHeader3,

        // Image Upload
        // image1: offer.image1 === null ? null : offer.image1,
        //Pre-ping
        criteriaDailyLimit: offer.criteriaDailyLimit === null ? null : offer.criteriaDailyLimit,
        capInterval: offer.capInterval === null ? null : offer.capInterval,
        capAmount: offer.capAmount === null ? 0 : offer.capAmount,
        capSegmentConfiguration:
          offer.capSegmentConfiguration === null || JSON.stringify(offer.capSegmentConfiguration?.capSegments) === '[]'
            ? null
            : offer.capSegmentConfiguration,
        criteriaDailyLimitAnswers: offer.criteriaDailyLimitAnswers === null ? null : offer.criteriaDailyLimitAnswers,
        criteriaDayHourRestrictions: dayHourRestrictions,
        // Registration
        triggersRegistration: offer.triggersRegistration === null ? false : offer.triggersRegistration,
        // TCPA
        requiresOptInBeforeSend: offer.requiresOptInBeforeSend === null ? false : offer.requiresOptInBeforeSend,
        criteriaTcpaLastOptInInterval:
          offer.criteriaTcpaLastOptInInterval === null ? false : offer.criteriaTcpaLastOptInInterval,
        // Suppression
        suppressionRules: suppressionRulesOffer === null ? null : suppressionRulesOffer,
      };
    }
    this.props.initialize(initData);
  }

  toggleJavascriptContent = () => {
    this.setState({ collapseJavascript: !this.state.collapseJavascript });
  };

  toggleHeaderFooter() {
    this.setState({ collapseHeaderFooter: !this.state.collapseHeaderFooter });
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  handleChangeForUserAttribute = (e) => {
    this.setState({ userAttribute: e.target.value });
  };
  handleChangeForActions = (e) => {
    if (e.target.value === 'GET') {
      this.setState({ questionAction: 'GET' });
    }
    if (e.target.value === 'POST') {
      this.setState({
        questionAction: 'POST',
      });
    }
  };
  handleChangeForActionsChildren = (e) => {
    this.setState({ questionActionChildren: e.target.value });
  };
  handlePrePing = (e) => {
    this.setState({
      prePingOption: e.target.value,
    });
  };
  handleChangeQuestionText = (event) => {
    this.setState({
      questionText: event.target.value,
      errors: Object.assign({}, this.state.errors, {
        questionText: event.target.value.length === 0,
      }),
    });
  };

  handleHttpStatusToggle = () => {
    this.setState({
      httpIsHidden: !this.state.httpIsHidden,
    });
  };

  handleOnDrop = (newImageFile, onChange) => {
    convertImageFileToBase64(newImageFile[0])
      .then((base) => {
        convertBase64FileToImage(base).then((image) => {
          let height = null,
            width = null;
          let strip = base !== null && base !== undefined ? base.split(',') : null;
          let imageData = strip !== null && strip !== undefined && strip.length === 2 ? strip[1] : null;
          if (image.width > 600 || image.height > 400) {
            height = image.height;
            width = image.width;
          }
          const imageFile = {
            file: newImageFile[0],
            encodedImageData: imageData,
            name: newImageFile[0].name,
            preview: URL.createObjectURL(newImageFile[0]),
            targetWidth: width,
            targetHeight: height,
            size: newImageFile[0].size,
          };
          this.setState({ imageFile: [imageFile] }, () => onChange(imageFile));
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  handleOfferActionChange = (selector) => {
    this.props.change(`${selector}.question_actions`, null);
    this.props.change(`${selector}.name`, null);
    this.props.change(`${selector}.action_advertiser`, null);
    this.props.change(`${selector}.action_id`, null);
    this.props.change(`${selector}.actionStatus`, null);
  };

  handleOfferActionNameChange = (action, selector) => {
    const active = action.active ? action.active : null;
    const advertiser = action.advertiser ? action.advertiser : null;
    const action_id = action.offerActionId ? action.offerActionId : '';
    this.props.change(`${selector}.action_advertiser`, advertiser);
    this.props.change(`${selector}.action_id`, action_id);
    this.props.change(`${selector}.actionStatus`, active);
  };

  resetForm = () => this.setState({ imageFile: [] }, () => this.props.reset());

  getPreview = async (formValues) => {
    const offerID = this.props.offer.id;
    const containerID = formValues.container.id;
    const layoutID = formValues.layout.id;
    const siteID = formValues.site.id;

    return new Promise((resolve) => {
      api.get(`offers/offer/preview/${offerID}/${containerID}/${layoutID}/${siteID}`).then((response) => {
        const myWindow = window.open();
        let html = response.data.replace(/{{{nr_script_header}}}/g, '').replace(/{{{nr_script_footer}}}/g, '');
        myWindow.document.write(html);
        resolve(html);
      });
    });
  };

  render() {
    const { selector } = this.props.offerState;
    const advertiserList = this.props.advertisers;
    const { attributes, posts, postWaterfalls, gtmEvents, linkouts } = this.props;
    const disabled = posts.length === 0;

    /***************** Render Offer Actions ********************/

    let OfferActions = ({
      action,
      action_id,
      actionStatus,
      offer_posts,
      index,
      fields,
      hasLastName,
      actionType,
      has_revenue,
      linkout_has_revenue,
      offerTriggers,
    }) => {
      action_id = typeof action === 'object' && action !== null ? action.offerActionId : action_id;
      const actionData =
        actionType === 'POST'
          ? posts
          : actionType === 'USER_ATTRIBUTE'
          ? attributes
          : actionType === 'POST_WATERFALL'
          ? postWaterfalls
          : actionType === 'GTM_EVENT'
          ? gtmEvents
          : actionType === 'LINKOUT'
          ? linkouts
          : [];
      return (
        <div key={index} className='colorTest'>
          <br />

          <Row>
            <Col lg={1}>
              <br />
              <Button
                color='danger'
                className='removeofferInputParent'
                type='button'
                title='Remove Parent Attribute'
                onClick={() => fields.remove(index)}
              >
                <i className='fa fa-close' />
              </Button>
            </Col>
            <Col lg={1}>
              <br />
              {!action_id ? (
                <Button color='info' type='button' title='Visit action details' disabled={true}>
                  <i className='fa fa-search' />
                </Button>
              ) : (
                <Link to={`/campaigns/offeractions/${action_id}`} target='_blank'>
                  <Button color='info' type='button' title='Visit action details'>
                    <i className='fa fa-search' />
                  </Button>
                </Link>
              )}
            </Col>
            <Col lg={2}>
              <Field
                name={`${offer_posts}.post_type`}
                type='select'
                component={renderOfferPostAction}
                label='Action happens on:'
              />
            </Col>
            {renderActionTypeDropdown({
              data: filterActionTypesByLinkoutCount(offerTriggers),
              handleOfferActionChange: this.handleOfferActionChange,
              fieldName: `${offer_posts}.question_actions`,
              offerAction: offer_posts,
            })}
            {renderSelectedOfferAction({
              actionStatus,
              entityName: 'Offer',
              handleOfferActionNameChange: this.handleOfferActionNameChange,
              offerAction: offer_posts,
              actionType,
              data: actionData,
              disabled,
            })}
            {actionType && actionType === 'POST' && (
              <Col lg={3}>
                <Field
                  name={`${offer_posts}.action_advertiser`}
                  component={renderField}
                  type='text'
                  disabled
                  label='Advertiser Name'
                />
              </Col>
            )}
          </Row>

          <br />
        </div>
      );
    };
    OfferActions = connect((state, props) => {
      const offerTriggers = selector(state, `offerQuestionPosts`) || [];
      return {
        action: selector(state, `${props.offer_posts}.name`),
        action_id: selector(state, `${props.offer_posts}.action_id`),
        actionStatus: selector(state, `${props.offer_posts}.actionStatus`),
        has_revenue: !!selector(state, `${props.offer_posts}.has_revenue`),
        actionType: selector(state, `${props.offer_posts}.question_actions`),
        linkout_has_revenue: !!selector(state, `${props.offer_posts}.linkout_has_revenue`),
        offerTriggers,
      };
    })(OfferActions);

    /********************** Start of Offer Actions *************************/
    const renderOfferActions = ({ fields, meta: { touched, error } }) => (
      <div>
        <Row>
          <Col lg={3}>
            <h4>Offer Actions</h4>
          </Col>
          <Col lg={9}>
            <Button color='success' className='float-right' onClick={() => fields.push({})}>
              Add Offer Action
            </Button>
            {touched && error && <span>{error}</span>}
          </Col>
        </Row>
        <br />
        {fields.map((questionAnswers, index) => (
          <OfferActions offer_posts={questionAnswers} fields={fields} index={index} key={index} />
        ))}
      </div>
    );

    /********************* End of Offer Actions ************************/

    return (
      <div className='animated fadeIn'>
        <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
          <Card>
            <CardHeader>
              <Row>
                <Col lg='10' onClick={this.toggleJavascriptContent}>
                  <h4>
                    Javascript Input &nbsp;&nbsp;
                    {this.state.collapseJavascript ? (
                      <i className='fa fa-chevron-up opened' />
                    ) : (
                      <i className='fa fa-chevron-down closed' />
                    )}
                  </h4>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <Collapse isOpen={this.state.collapseJavascript}>
                <Row>
                  <Col lg={12}>
                    <Field name='offerUrl' type='text' id='offerUrl' component={renderField} label='Offer URL' />
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col lg={12}>
                    <label htmlFor='triggersRegistration' style={{ verticalAlign: 'super' }}>
                      Count as Registration
                    </label>
                    &nbsp;&nbsp;&nbsp;
                    <Field name='triggersRegistration' id='triggersRegistration' component={renderToggleSwitch} />
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col lg={12}>
                    <FieldArray name='offerQuestionPosts' component={renderOfferActions} />
                  </Col>
                </Row>
              </Collapse>
            </CardBody>
          </Card>
          <Card>
            <CardHeader onClick={this.toggleHeaderFooter} id='clickHeader'>
              {this.state.collapseHeaderFooter ? (
                <div>
                  <h4>
                    Header/Footer&nbsp;&nbsp; <i className='fa fa-chevron-up opened' />
                  </h4>
                </div>
              ) : (
                <div>
                  <h4>
                    Header/Footer&nbsp;&nbsp; <i className='fa fa-chevron-down closed' />
                  </h4>
                </div>
              )}
            </CardHeader>
            <CardBody>
              <Collapse isOpen={this.state.collapseHeaderFooter}>
                {/*<Field name='footer' component={ReactMDE} placeholder='' />*/}
                <Row>
                  <Col>
                    <Field name='footer' type='text' id='footer' component={FormEditor} />
                  </Col>
                </Row>
              </Collapse>
            </CardBody>
          </Card>
          <FieldArray name='customVariables' component={CustomVariablesCard} customVarFlag='UD_' />
          <Criteria
            limitAnswers={this.state.answers}
            capAmount={this.props.capAmount}
            // capSegmentConfiguration={this.props.capSegmentConfiguration}
          />
          <Row className='justify-content-end'>
            <Col xs='auto'>
              <PreviewOfferModal
                isLP={false}
                isEditMode={this.state.isEditMode}
                getErrors={this.getErrors}
                onClickSubmit={this.props.handleSubmit(this.getPreview)}
              />
            </Col>
            {this.state.isEditMode && (
              <Col xs='auto'>
                <OfferVariationModalButton
                  getErrors={this.getErrors}
                  isDisabled={this.getErrors().invalid}
                  onClickSubmit={this.props.handleSubmit((values) => {
                    this.props.setCopyToVariation(true);
                    this.onSubmit(values);
                  })}
                  linkouts={this.props.linkouts}
                  posts={this.props.posts}
                />
              </Col>
            )}
            {this.state.isEditMode && (
              <Col xs='auto'>
                <CopyButton
                  getErrors={this.getErrors}
                  isDisabled={this.getErrors().invalid}
                  onClickSubmit={this.props.handleSubmit(this.onSubmit)}
                  linkouts={this.props.linkouts}
                  posts={this.props.posts}
                />
              </Col>
            )}
            <SaveOfferModal
              isDisabled={this.getErrors().invalid}
              offerName={this.props.offerName}
              getErrors={this.getErrors}
              onClickSubmit={this.props.handleSubmit(this.onSubmit)}
              linkouts={this.props.linkouts}
              posts={this.props.posts}
            />
            <Col xs='auto'>
              <CancelOfferModal linkouts={this.props.linkouts} posts={this.props.posts} getErrors={this.getErrors} />
            </Col>
          </Row>
        </form>
      </div>
    );
  }
}

const form = reduxForm({
  form: 'offerjavascript',
  destroyOnUnmount: false,
  touchOnChange: true,
  validate,
});

function mapStateToProps(state) {
  const { advertiser, offerTriggers, offerActionTriggers } = state.offerState;
  const { initData } = state.criteria;
  const capAmount = state.form.offerjavascript?.values?.capAmount;
  // const capSegmentConfiguration = state.form.offerjavascript?.values?.capSegmentConfiguration || [];
  return {
    advertiser,
    capAmount,
    // capSegmentConfiguration,
    initData,
    offerTriggers,
    offerActionTriggers,
    offerState: state.offerState,
    offerContent: state.offerContent,
  };
}

export default connect(mapStateToProps, {
  getOfferTriggersByOfferId,
  saveInitData,
  removeOfferTriggerState,
})(form(OfferJavascript));
