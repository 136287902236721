export const sortByName = (dataArr) => {
  if (!dataArr.length) return [];
  return dataArr.sort((a, b) => {
    return (a.name || '').toLowerCase() > (b.name || '').toLowerCase() ? 1 : -1;
  });
};

export const sortByDomain = (dataArr) => {
  if (!dataArr.length) return [];
  return dataArr.sort((a, b) => {
    return (a.domain || '').toLowerCase() > (b.domain || '').toLowerCase() ? 1 : -1;
  });
};

export const sortByDate = (a, b, order) => {
  var dateA = new Date(a.lastEdited).getTime();
  var dateB = new Date(b.lastEdited).getTime();
  if (order === 'asc') {
    return dateA > dateB ? 1 : -1;
  }
  return dateA > dateB ? -1 : 1;
};

export const sortByPublishedDate = (a, b, order) => {
  var dateA = new Date(a.publishedDate).getTime();
  var dateB = new Date(b.publishedDate).getTime();
  if (order === 'desc') {
    return dateA > dateB ? -1 : 1;
  }
  return dateA > dateB ? 1 : -1;
};