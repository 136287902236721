import { useMutation, useQueryClient, useInfiniteQuery } from '@tanstack/react-query';
import api from '../../services';

export const useGetAuditRecordsById = () => {
  const queryClient = useQueryClient();

  return useMutation({
    queryKey: ['auditRecords'],
    mutationFn: ({ path, id }) => api.get(`${path}/audit/${id}`).then((res) => res.data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['auditRecords'] });
    },
  });
};

export const useGetAuditRecordsInfinite = ({ apiPath, id, limit = 10 }) => {
  const queryClient = useQueryClient();

  return useInfiniteQuery({
    queryKey: ['auditRecordsInfinite'],
    queryFn: ({ pageParam = 0 }) =>
      api.get(`${apiPath}/audit/${id}?limit=${limit}&offset=${pageParam}`).then((res) => {
        return { results: res.data, offset: limit + pageParam };
      }),
    getNextPageParam: (lastPage) => (lastPage.results.length + 1 < limit ? undefined : lastPage.offset),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['auditRecords'] });
    },
  });
};
