import axios from 'axios';
import { getAllAdditionalRequests } from '../../../actions';
import api from '../../../services';
import {
  CREATE_LANDING_PAGE,
  GET_ALL_LANDING_PAGES,
  GET_CONTAINER_FOR_PREVIEW,
  GET_LANDING_PAGE,
  GET_LANDING_PAGE_BY_OFFER_ID,
  GET_LAYOUT_FOR_PREVIEW,
  GET_SITE_FOR_PREVIEW,
  SET_NEW_LANDING_PAGE_NAME,
  UPDATE_LANDING_PAGE,
  UPDATE_LANDING_PAGE_ERROR,
} from './types';

export const getLandingPage = (landingPage) => {
  return {
    type: GET_LANDING_PAGE,
    payload: landingPage,
  };
};

export const getLandingPageByOfferId = (offerId) => (dispatch) => {
  return new Promise((resolve) => {
    api.get(`landing-pages/offer/${offerId}`).then((response) => {
      console.log(response);

      dispatch({
        type: GET_LANDING_PAGE_BY_OFFER_ID,
        payload: response.data,
      });
      return resolve(response.data);
    });
  });
};

export const getAllLandingPages = (limit = 100) => (dispatch) => {
  return new Promise((resolve) => {
    api
      .get(`landing-pages?limit=${limit}&offset=${0}`)
      .then((response) => {
        // Set initial data
        let allLandingPageData = Array.from(response.data.content);

        // Retrieve remaining pages
        const { totalPages } = response.data;
        let restOfLandingPages = getAllAdditionalRequests(totalPages, limit, 'landing-pages');

        // Concurrently fire all page requests
        axios.all(restOfLandingPages).then((responses) => {
          // Add remaining response data
          responses.forEach((response) => {
            let { content } = response.data;
            allLandingPageData = [...allLandingPageData, ...content];
          });
          dispatch({
            type: GET_ALL_LANDING_PAGES,
            payload: allLandingPageData,
          });
          resolve(allLandingPageData);
          // })
        });
      })
      .catch((err) => console.log(err));
  });
};

export const updateLandingPage = (landingPage) => {
  return {
    type: UPDATE_LANDING_PAGE,
    payload: landingPage,
  };
};

export const updateLandingPageError = (e) => {
  const errorMessage =
    e.response.status === 504
      ? 'This landing page group has a large amount of landing page placements. As a result, the template update process will take a little longer than usual. Please check for your template changes in about 5 or more minutes.'
      : e.response.data.message;
  return {
    type: UPDATE_LANDING_PAGE_ERROR,
    payload: errorMessage,
  };
};

export const createLandingPage = (landingPage) => {
  return {
    type: CREATE_LANDING_PAGE,
    payload: landingPage,
  };
};

export const setNewLandingPageName = (name) => {
  return {
    type: SET_NEW_LANDING_PAGE_NAME,
    payload: name,
  };
};

export const getContainerForPreview = (container) => {
  return {
    type: GET_CONTAINER_FOR_PREVIEW,
    payload: container,
  };
};

export const getLayoutForPreview = (layout) => {
  return {
    type: GET_LAYOUT_FOR_PREVIEW,
    payload: layout,
  };
};

export const getSiteForPreview = (site) => {
  return {
    type: GET_SITE_FOR_PREVIEW,
    payload: site,
  };
};

export const editLandingPage = (landingPage, triggersWithActions, id) => (dispatch) => {
  return api
    .put(`landing-pages/${id}/withActions`, { entity: landingPage, triggersWithActions })
    .then((landingPageResponse) => {
      const updatedLandingPage = landingPageResponse.data;
      console.log('Saved landing page: ' + JSON.stringify(updatedLandingPage));
      dispatch(updateLandingPage(updatedLandingPage));
      return updatedLandingPage;
    })
    .catch((savedLandingPageError) => {
      dispatch(updateLandingPageError(savedLandingPageError));
    });
};

export const createLandingPageApi = (landingPage, triggersWithActions) => (dispatch) => {
  const saveToStore = (savedLp) => {
    console.log('Created landing page: ' + JSON.stringify(savedLp));
    dispatch(createLandingPage(savedLp));
    return savedLp;
  };

  return api
    .post(`landing-pages/withActions`, { entity: landingPage, triggersWithActions })
    .then(saveToStore)
    .catch((savedLandingPageError) => {
      dispatch(updateLandingPageError(savedLandingPageError));
    });
};
