import { format } from 'date-fns';
import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import ActiveStatusModal from '../../components/ActiveStatusModal/ActiveStatusModal';
import LocationsModal from '../../components/LocationsModal/LocationsModal';
import SummaryCard from '../../components/SummaryCard/SummaryCard';
import api from '../../services';
import { columnDefaults } from '../../utils/tableFunctions';
import { useOffersTable } from './queries';
import ChangelogModal from '../../components/ChangelogModal/ChangelogModal';

const updateStatus = (row, activeStatus, endpoint) => {
  if (row.id) {
    let isActive = activeStatus;
    const zuulUser = JSON.parse(localStorage.getItem('userData'));
    let saveData = {
      id: row.id,
      isActive,
      active: activeStatus,
      zuulUser,
    };
    api.put(`${endpoint}/active/${row.id}`, saveData).then((result) => {
      console.log(JSON.stringify(result));
    });
  }
};

const OfferTable = () => {
  const { data, isLoading, error } = useOffersTable();

  function linkFormatter(cell, row) {
    return (
      <Link to={`/campaigns/offertable/offer/${row.id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
        {cell}
      </Link>
    );
  }

  const columns = [
    {
      dataField: 'id',
      text: 'ID',
      formatter: linkFormatter,
      ...columnDefaults,
    },
    {
      dataField: 'name',
      text: 'Offer Name',
      formatter: linkFormatter,
      ...columnDefaults,
    },
    {
      dataField: 'advertiser.name',
      text: 'Advertiser',
      formatter: linkFormatter,
      ...columnDefaults,
    },
    {
      dataField: 'offerType.name',
      text: 'Offer Type',
      formatter: linkFormatter,
      ...columnDefaults,
    },
    {
      dataField: 'lastPublishedBy',
      text: 'Last Published',
      formatter: linkFormatter,
      sortValue: (cell, row) => new Date(row.lastPublished),
      ...columnDefaults,
    },
    {
      dataField: 'sites',
      text: 'Site Locations',
      isDummyField: true,
      formatter: (_cell, row) => (
        <Row>
          <Col lg={12}>
            <LocationsModal content={row} />
          </Col>
        </Row>
      ),
      headerStyle: {
        backgroundColor: '#2f353a',
      },
      searchable: false,
    },
    {
      dataField: 'isActive',
      text: 'Status',
      formatter: (_cell, row) => {
        return (
          <ActiveStatusModal
            content={row}
            isActive={row.isActive}
            modalType=' Offer'
            getErrors={() => null}
            onClickSubmit={(activeStatus) => updateStatus(row, activeStatus, 'offers')}
            isEditMode={true}
          />
        );
      },
      ...columnDefaults,
    },
    {
      dataField: 'changelog',
      text: 'Audit',
      isDummyField: true,
      formatter: (_cell, row) => (
        <Row>
          <Col lg={12}>
            <ChangelogModal typeName='Offer' apiPath='offers' id={row.id} />
          </Col>
        </Row>
      ),
      headerStyle: {
        backgroundColor: '#2f353a',
        textAlign: 'center'
      },
      searchable: false,
    },
  ];

  return (
    <SummaryCard
      entity='Offers'
      createComponent={
        <Link to='/campaigns/offertable/offer/create' style={{ textDecoration: 'none', color: 'inherit' }}>
          <Button color='primary' className='float-right'>
            <i className='fa fa-plus' />
            &nbsp; Add Offer
          </Button>
        </Link>
      }
      columns={columns}
      defaultSorted={[{ dataField: 'lastPublishedBy', order: 'desc' }]}
      data={data?.map((offer) => ({
        ...offer,
        lastPublishedBy: offer.zuulUser
          ? format(new Date(offer.lastPublished), 'Pp') + ' by ' + offer.zuulUser.realname
          : format(new Date(offer.lastPublished), 'Pp'),
      }))}
      loading={isLoading}
      error={error}
    />
  );
};

export default OfferTable;
