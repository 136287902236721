import React from 'react';
import { Badge } from 'reactstrap';

export default function(isActive) {
  if (isActive) {
    return (
      <Badge className='float-right' color='success'>
        Active
      </Badge>
    );
  } else {
    return (
      <Badge className='float-right' color='danger'>
        Inactive
      </Badge>
    );
  }
}
