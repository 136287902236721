import React from 'react';
import { Field } from 'react-final-form';
import Multiselect from 'react-widgets/Multiselect';
import NumberPicker from 'react-widgets/NumberPicker';
import { Alert, Col, Input, Label, Row } from 'reactstrap';
import { renderToggleSwitch } from '../../views/Offers/OfferTitle/renderConsts';

export const ToggleSwitchRow = ({ label, name }) => (
  <Row>
    <Col>
      <label>{label}</label>
    </Col>
    <Col>
      <Field name={name} component={renderToggleSwitch} />
    </Col>
  </Row>
);

export function MultiselectField({
  data,
  name,
  busy = false,
  parse = (value) => value,
  format = (value) => value,
  textField,
  dataKey,
}) {
  return (
    <Field name={name} parse={parse} format={format}>
      {({ input, meta }) => (
        <>
          <Multiselect
            {...input}
            data={data}
            placeholder='Select'
            busy={busy}
            value={input.value || []} // requires value to be an array
            textField={textField}
            dataKey={dataKey ? dataKey : 'name'}
            filter='contains'
          />
          {meta.error && meta.touched && <Alert color='danger'>{meta.error}</Alert>}
        </>
      )}
    </Field>
  );
}

export function SelectField({
  name,
  options = [{ value: '', option: 'Select' }],
  parse = (value) => value,
  format = (value) => value,
}) {
  return (
    <Field name={name} parse={parse} format={format}>
      {({ input, meta }) => (
        <div>
          <Input {...input} type='select' placeholder='Select'>
            {options.map((option) => (
              <option key={option.value} value={option.value}>
                {option.option}
              </option>
            ))}
          </Input>
          {meta.error && meta.touched && <Alert color='danger'>{meta.error}</Alert>}
        </div>
      )}
    </Field>
  );
}

export function SelectRow({
  name,
  label,
  options = [
    { value: '', option: 'None' },
    { value: 'Include', option: 'Include' },
    { value: 'Exclude', option: 'Exclude' },
  ],
  children,
}) {
  return (
    <Row
      style={{
        marginTop: '1rem',
      }}
    >
      <Col lg={2}>
        <h5>{label}</h5>
      </Col>
      <Col lg={4} xs={3}>
        <SelectField name={name} options={options}></SelectField>
      </Col>
      {children}
    </Row>
  );
}

export const Condition = ({ when, is, children }) => (
  <Field name={when} subscription={{ value: true }}>
    {({ input: { value } }) => (is.includes(value) ? children : null)}
  </Field>
);

export const ColCondition = ({ when, children }) => (
  <Col>
    <Field name={when} subscription={{ value: true }}>
      {({ input: { value } }) => (value ? children : null)}
    </Field>
  </Col>
);

export function NumberField({ name, label, min = 0, noLabel = false }) {
  return (
    <Field name={name} format={(value) => parseInt(value)}>
      {({ input, meta }) => {
        return (
          <>
            {!noLabel && <Label>{label}</Label>}
            <NumberPicker min={min} {...input} placeholder={label} />
            {meta.error && meta.touched && <Alert color='danger'>{meta.error}</Alert>}
          </>
        );
      }}
    </Field>
  );
}

export function TextAreaField({ name, label }) {
  return (
    <Field name={name}>
      {({ input, meta }) => {
        return (
          <>
            <Row>
              <Col xs='3'>
                <Label>{label}</Label>
              </Col>
              <Col xs='9'>
                <Input type='textarea' {...input} placeholder={label} />
                {meta.error && meta.touched && <Alert color='danger'>{meta.error}</Alert>}
              </Col>
            </Row>
          </>
        );
      }}
    </Field>
  );
}
