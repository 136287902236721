import { Card, CardBody, CardHeader } from 'reactstrap';
import { OFFER_CDN_URL } from '../../utils/constants';

const HtmlPreview = ({ html }) => {

  return (
    <Card style={{ height: '97%' }}>
      <CardHeader>
        <i className='fa fa-align-justify' /> HTML Preview
      </CardHeader>
      <CardBody style={{ padding: 0 }}>
        <iframe
          srcDoc={html.replaceAll('[{offer_cdn_url}]', OFFER_CDN_URL)}
          title='html-preview'
          style={{
            width: '100%',
            height: '100%',
            border: 'none',
          }}
        />
      </CardBody>
    </Card>
  );
};

export default HtmlPreview;
