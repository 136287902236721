import React, { Component } from 'react';
import { connect } from 'react-redux';
import Switch from 'react-switch';
import Combobox from 'react-widgets/Combobox';
import CreatableSelect from 'react-select/creatable';
import DropdownList from 'react-widgets/DropdownList';
import Multiselect from 'react-widgets/Multiselect';
import NumberPicker from 'react-widgets/NumberPicker';
import { Alert, Button, Col, Input, Label, Row } from 'reactstrap';
import { Field } from 'redux-form';
import { useDailyRestrictionSelectors } from '../../../components/Criteria/hooks';
import ToggleButton from '../../../components/ToggleButton/ToggleButton';
import CapSegmentRemainder from '../OfferActions/CapSegmentRemainder';
import './renderConsts.css';
import CurrentCapAmountByDomains from '../OfferActions/CurrentCapAmountByDomains';
import { Link } from 'react-router-dom';

const customStyles = {
  container: (provided) => ({
    ...provided,
    flex: 1,
  }),
  control: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? '#515b65' : '#515b65', // Background color change
    borderColor: state.isFocused ? '#23282c' : '#23282c', // Border color change
    '&:hover': {
      borderColor: '#ffffff',
    },
    boxShadow: state.isFocused ? '0 0 0 1px #80deea' : null, // Box shadow change
  }),
  input: (provided) => ({
    ...provided,
    color: '#ffffff', // Text color change
  }),
  placeholder: (provided) => ({
    ...provided,
    color: '#ffffff', // Placeholder text color change
  }),
  singleValue: (provided) => ({
    ...provided,
    color: '#ffffff', // Selected value text color change
  }),
  menu: (provided) => ({
    ...provided,
    backgroundColor: '#515b65', // Menu background color change
  }),
  option: (provided, state) => {
    const isCreateOption = state.data.__isNew__;

    return {
      ...provided,
      backgroundColor: isCreateOption
        ? '#ffc107'
        : state.isSelected
        ? '#007bff'
        : state.isFocused
        ? '#757a7f'
        : '#ffffff', // Background color for create option
      color: isCreateOption ? '#000000' : state.isSelected ? '#ffffff' : '#000000', // Text color for create option
      '&:hover': {
        backgroundColor: isCreateOption ? '#ffca28' : '#e9ecef', // Hover background color for create option
        color: isCreateOption ? '#000000' : '#000000', // Hover text color for create option
      },
      '&:active': {
        backgroundColor: isCreateOption ? '#ffb300' : '#80deea', // Active background color for create option
      },
    };
  },
};

export const getIsActiveTrafficLight = (status) =>
  status ? (
    <i className='fa fa-circle' style={{ color: '#4dbd74' }} />
  ) : (
    <i className='fa fa-circle' style={{ color: '#f86c6b' }} />
  );

export const renderField = ({
  input,
  maxLength,
  disabled,
  label,
  type,
  className,
  placeholder,
  readOnly, 
  meta: { touched, error },
}) => {
  return (
    <>
      {label && <label>{label}</label>}
      <div>
        <Input
          {...input}
          disabled={disabled}
          placeholder={placeholder}
          maxLength={maxLength}
          type={type}
          className={className}
          readOnly={readOnly} 
        />
        <br />
        {touched && error && <Alert color='danger'>{error}</Alert>}
      </div>
    </>
  );
};

export const textarea = ({ rows, input, meta: { touched, error } }) => {
  return (
    <div>
      <Input type='textarea' rows={rows || '3'} {...input} placeholder='Add text here...' />
      <br />
      {touched && error && <Alert color='danger'>{error}</Alert>}
    </div>
  );
};
export const required = (value) => (value ? undefined : 'Required');

export const renderTrueOrFalse = ({ input, type }) => (
  <div>
    <Input {...input} type={type}>
      <option value='not_set'>Not set</option>
      <option value='true'>True</option>
      <option value='false'>False</option>
    </Input>
  </div>
);

export const renderActionCriteriaAndOr = ({ className, name, input, type, meta: { touched, error } }) => {
  return (
    <div className={className}>
      <Input {...input} type={type}>
        <option value='AND'>AND - Suppress this offer if ALL checked actions' criteria fail</option>
        <option value='OR'>OR - Suppress this offer if ANY checked actions' criteria fail</option>
      </Input>
      <br />
      {touched && error && <Alert color='danger'>{error}</Alert>}
    </div>
  );
};

export const renderFormTypeDropdown = ({ input, label, type }) => (
  <div>
    <label>{label}</label>
    <Input {...input} type={type}>
      <option value=''>Select Form Type</option>
      <option value='Question'>Question</option>
      <option value='Registration'>Registration</option>
    </Input>
  </div>
);

export const renderToggleSwitch = (field) => {
  return (
    <Switch
      checked={field.input.value || false}
      onChange={field.input.onChange}
      disabled={field.disabled}
      onColor='#4dbd74'
      offColor='#f86c6b'
      onHandleColor='#ffffff'
      uncheckedIcon={false}
      checkedIcon={false}
      boxShadow='0px 1px 5px rgba(0, 0, 0, 0.6)'
      activeBoxShadow='0px 0px 1px 10px rgba(0, 0, 0, 0.2)'
      height={18}
      width={42}
      className='react-switch'
    />
  );
};

export const renderActiveToggleSwitch = ({ input }) => {
  return (
    <Switch
      {...input}
      onChange={input.onChange}
      checked={input.value}
      onColor='#4dbd74'
      offColor='#f86c6b'
      width={80}
      checkedIcon={
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '96%',
            fontSize: 12,
          }}
        >
          Active
        </div>
      }
      uncheckedIcon={
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '96%',
            fontSize: 11,
          }}
        >
          Inactive
        </div>
      }
    />
  );
};

export const renderCreatableSelect = ({
  input,
  id,
  data,
  disabled,
  onChange,
  onCreate,
  valueField,
  textField,
  meta: { touched, error },
  busy,
  allData,
  style,
  link,
}) => {
  const { value, onBlur, ...restInput } = input;

  const handleChange = (selectedOption) => {
    input.onChange(selectedOption ? { id: selectedOption.value, name: selectedOption.label } : null);
  };

  const handleCreate = (inputValue) => {
    onCreate(inputValue);
  };

  const options = (allData && allData.length > 0 ? allData : data).map((item) => ({
    value: item[valueField],
    label: item[textField],
  }));

  const selectedValue = value ? { value: value.id || value.value, label: value.name || value.label } : null;

  return (
    <>
      <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
        {link && selectedValue && (
          <Link to={`${link}/${selectedValue.value}`} target='_blank'>
            <Button color='success' type='button' title='Visit element'>
              <i className='fa fa-external-link' />
            </Button>
          </Link>
        )}
        <CreatableSelect
          id={id}
          value={selectedValue}
          isClearable // Allow clearing the selection
          onChange={handleChange}
          onCreateOption={handleCreate}
          options={options}
          isDisabled={disabled}
          isLoading={busy}
          styles={customStyles}
          {...restInput}
        />
      </div>
      <br />
      {touched && error && <Alert color='danger'>{error}</Alert>}
    </>
  );
};

export const renderMultiSelect = ({
  input,
  id,
  data,
  disabled,
  valueField,
  textField,
  placeholder,
  allowCreate = false,
  dataKey,
  busy,
  meta: { touched, error },
}) => {
  return (
    <>
      <Multiselect
        {...input}
        id={id}
        onBlur={() => input.onBlur()}
        value={input.value || []} // requires value to be an array
        data={data}
        dataKey={dataKey}
        disabled={disabled}
        valueField={valueField}
        textField={textField}
        placeholder={placeholder}
        allowCreate={allowCreate}
        onCreate={(entity) => {
          input.onChange([...input.value, entity]);
        }}
        focusFirstItem
        filter='contains'
        busy={busy}
      />
      <br />
      {touched && error && <Alert color='danger'>{error}</Alert>}
    </>
  );
};

export const renderActionSelect = (colSize) => {
  return (
    <Col lg={colSize}>
      <Field name='offerActionType' component={renderOfferActionDropdown} type='select' label='Offer Action Type' />
    </Col>
  );
};

export const renderOfferActionDropdown = ({ input, label, type, disabled, meta: { touched, error } }) => (
  <div>
    <div>
      <label>{label}</label>
      <Input {...input} disabled={disabled} type={type}>
        <option value='POST'>Post</option>
        <option value='LINKOUT'>Linkout</option>
        <option value='USER_ATTRIBUTE'>Set Attribute</option>
        <option value='POST_WATERFALL'>Post Waterfall</option>
        <option value='LINKOUT_WATERFALL'>Linkout Waterfall</option>
      </Input>
      {touched && error && <span>{error}</span>}
    </div>
  </div>
);

export const renderActionTypeDropdown = ({ handleOfferActionChange, fieldName, offerAction, data }) => (
  <Col lg={2}>
    <Label>Action Type</Label>
    <Field
      name={fieldName}
      component={renderDropdownList}
      onChange={() => handleOfferActionChange(offerAction)}
      type='select'
      data={data}
    />
  </Col>
);

export const renderSelectedOfferAction = ({
  actionStatus,
  entityName,
  handleOfferActionNameChange,
  offerAction,
  actionType,
  data,
  disabled,
  shouldShowSuppression,
}) => {
  return (
    <Col lg={3}>
      <Label>Action Name</Label>&nbsp;&nbsp;
      {getIsActiveTrafficLight(actionStatus)}
      <Field
        name={`${offerAction}.name`}
        component={renderComboBox}
        onChange={(selectedOfferAction) => handleOfferActionNameChange(selectedOfferAction, offerAction)}
        type='select'
        data={data}
        textField='name'
        busy={disabled}
      />
      {shouldShowSuppression !== false && (
        <>
          <Label style={{ display: 'initial ' }}>Suppress {entityName} if action criteria fail</Label> &nbsp;&nbsp;
          <Field
            name={`${offerAction}.shouldShowCriteria`}
            className='shouldShowCriteria'
            component='input'
            type='checkbox'
          />
        </>
      )}
    </Col>
  );
};

export const renderOfferPostAction = ({ input, label, defaultValue, disabled, type, meta: { touched, error } }) => (
  <div>
    <div>
      <label>{label}</label>
      <Input {...input} disabled={disabled} value={defaultValue ? defaultValue : input.value} type={type}>
        <option value='ON_IMPRESSION'>Impression</option>
        <option value='ON_CLICK'>Clicked</option>
        <option value='ON_CLICK_OR_SKIP'>Clicked or skipped</option>
      </Input>
      {touched && error && <span>{error}</span>}
    </div>
  </div>
);

export const renderLandingPageTriggerTypes = ({
  input,
  label,
  defaultValue,
  disabled,
  type,
  meta: { touched, error },
}) => (
  <div>
    <div>
      <label>{label}</label>
      <Input {...input} disabled={disabled} value={defaultValue ? defaultValue : input.value} type={type}>
        <option value='ON_IMPRESSION'>Impression</option>
        <option value='SUBMIT'>Submit</option>
        <option value='SUBMIT+PARAMS'>Submit + Params</option>
        <option value='SUBMIT+CERTIFIED_LOOKUP'>Submit + Certified Lookup</option>
      </Input>
      {touched && error && <span>{error}</span>}
    </div>
  </div>
);

export const renderOfferPostMethod = ({ input, label, isOfferAction, type, meta: { touched, error } }) => {
  return (
    <div>
      <div>
        <label>{label}</label>
        <Input {...input} type={type}>
          {!isOfferAction && <option value='NONE'>NONE</option>}
          <option value='GET'>GET</option>
          <option value='POST'>POST</option>
          <option value='JSON'>JSON</option>
          <option value='XML'>XML</option>
        </Input>
        {touched && error && <span>{error}</span>}
      </div>
    </div>
  );
};

export class PrePingComponent extends Component {
  state = {
    showAdvancedFields: false,
  };

  handleHttpStatusToggle = () => {
    this.setState({
      showAdvancedFields: !this.state.showAdvancedFields,
    });
  };

  render() {
    const { active, emailAddressHashed, handlers, prePingMethod, name, isAdvertiserPrePing } = this.props;
    return (
      <>
        <br />
        <Row>
          <Col lg={3}>
            <Field
              name={`${name}.prePingMethod`}
              component={renderPrePingMethod}
              type='select'
              label='Pre Ping Method'
            />
            <br />
          </Col>

          <Col lg={4}>
            <Label>Handler</Label>
            <Field
              name={`${name}.prePingHandlerId`}
              component={renderComboBox}
              data={handlers}
              valueField='id'
              textField='name'
            />
          </Col>
          {isAdvertiserPrePing && (
            <>
              <Col className='advertiserPrePingSwitches' lg={2}>
                <ToggleButton id={`${name}.active`} fieldName={`${name}.active`}>
                  {active ? 'Active' : 'Inactive'}
                </ToggleButton>
              </Col>
              <Col className='advertiserPrePingSwitches' lg={3}>
                <ToggleButton id={`${name}.emailAddressHashed`} fieldName={`${name}.emailAddressHashed`}>
                  {emailAddressHashed ? 'Accepts Hashed Emails' : "Doesn't Accept Hashed Emails"}
                </ToggleButton>
              </Col>
            </>
          )}
        </Row>

        {prePingMethod && prePingMethod !== 'NONE' && (
          <div>
            <br />
            <Row>
              <Col lg={12}>
                <Field name={`${name}.prePing`} type='text' component={renderField} label='URL' />
              </Col>
            </Row>
            <br />
            {prePingMethod !== 'GET' && (
              <>
                <Row>
                  <Col lg={12}>
                    <Label>Body</Label>
                    <Field name={`${name}.prePingBody`} component={textarea} type='text' />
                  </Col>
                </Row>
                <br />
              </>
            )}
            <Row>
              <Col lg={12}>
                <Button className='float-right' onClick={this.handleHttpStatusToggle}>
                  <em>advanced settings</em>
                </Button>
              </Col>
            </Row>
            <br />
            {this.state.showAdvancedFields && (
              <div>
                <br />
                <Row>
                  <Col lg={3}>
                    <Field name={`${name}.prePingTimeout`} component={renderField} type='number' label='Timeout (ms)' />
                  </Col>
                </Row>
                <Row>
                  <Col lg={4}>
                    <Field name={`${name}.prePingHeader1`} component={renderField} type='text' label='Header 1' />
                  </Col>
                  <Col lg={4}>
                    <Field name={`${name}.prePingHeader2`} component={renderField} type='text' label='Header 2' />
                  </Col>
                  <Col lg={4}>
                    <Field name={`${name}.prePingHeader3`} component={renderField} type='text' label='Header 3' />
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col lg={12}>
                    <Field
                      name={`${name}.prePingCustomHttpStatus`}
                      component={renderField}
                      type='text'
                      label='Other HTTP Status'
                    />
                  </Col>
                </Row>
              </div>
            )}
          </div>
        )}
      </>
    );
  }
}

function mapStateToProps(state, props) {
  const { selector } = state.formSelector;

  return {
    active: selector(state, `${props.name}.active`),
    emailAddressHashed: selector(state, `${props.name}.emailAddressHashed`),
    prePingMethod: selector(state, `${props.name}.prePingMethod`),
  };
}

PrePingComponent = connect(mapStateToProps)(PrePingComponent);

export const renderPrePingMethod = ({ input, label, type, meta: { touched, error } }) => (
  <div>
    <label>{label}</label>
    <Input {...input} type={type} value={input.value}>
      <option value='NONE'>NONE</option>
      <option value='GET'>GET</option>
      <option value='POST'>POST</option>
      <option value='JSON'>JSON</option>
      <option value='XML'>XML</option>
    </Input>
    <br />
    {touched && error && <Alert color='danger'>{error}</Alert>}
  </div>
);

export const renderOnRevenue = ({ input, label, type, meta: { touched, error } }) => (
  <div>
    <div>
      <label>{label}</label>
      <Input {...input} type={type}>
        <option value={null}>None</option>
        <option value='Success'>Success</option>
        <option value='Gross'>Gross</option>
      </Input>
      {touched && error && <span>{error}</span>}
    </div>
  </div>
);

export const renderTriggerDropdown = ({ input, label, type, meta: { touched, error } }) => (
  <div>
    <div>
      <label>{label}</label>
      <Input {...input} type={type}>
        {/* <option value='null'>Select method...</option> */}
        <option value='SUBMIT'>Submit</option>
        <option value='SUBMIT+PARAMS'>Submit+Params</option>
        <option value='SUBMIT+CERTIFIED_LOOKUP'>Submit+Certified Lookup</option>
        <option value='ON_IMPRESSION'>Impression</option>
      </Input>
      {touched && error && <span>{error}</span>}
    </div>
  </div>
);

export const renderDailyRestrictionDay = ({ input, label, type }) => (
  <div>
    <div className='row'>
      <div className='col-lg-12'>
        <label>{label}</label>
        <Input {...input} type={type}>
          <option value=''>Select Day</option>
          <option value='sunday'>Sunday</option>
          <option value='monday'>Monday</option>
          <option value='tuesday'>Tuesday</option>
          <option value='wednesday'>Wednesday</option>
          <option value='thursday'>Thursday</option>
          <option value='friday'>Friday</option>
          <option value='saturday'>Saturday</option>
          <option value='weekdays'>Weekdays</option>
          <option value='weekends'>Weekends</option>
        </Input>
      </div>
    </div>
  </div>
);

export const renderRestrictions = ({ input, label, type, meta: { touched, error } }) => {
  return (
    <div className='row'>
      <div className='col-lg-12'>
        <label>{label}</label>
        <Input {...input} type={type}>
          <option value=''>Select Restriction</option>
          <option value='Available'>Available</option>
          <option value='Unavailable'>Unavailable</option>
          <option value='Partially Restricted'>Partially Restricted</option>
        </Input>
        <br />
        {touched && error && <Alert color='danger'>{error}</Alert>}
      </div>
    </div>
  );
};

export const renderDropdownList = ({
  input,
  data,
  disabled,
  valueField,
  defaultValue,
  textField,
  placeholder,
  meta: { touched, error },
}) => {
  const { value, name, onChange } = input;
  return (
    <>
      <DropdownList
        // {...input}
        name={name}
        value={value ? value : defaultValue}
        data={data}
        disabled={disabled}
        defaultValue={defaultValue}
        valueField={valueField}
        textField={textField}
        placeholder={placeholder}
        onChange={onChange}
        filter={'contains'}
      />
      <br />
      {touched && error && <Alert color='danger'>{error}</Alert>}
    </>
  );
};

export const renderComboBoxSecond = ({ input, data, valueField, textField, onSelect }) => {
  // console.log(valueField, textField);
  return (
    <Combobox
      {...input}
      onChange={(e) => input.onChange(typeof e === 'string' ? e : e[valueField])}
      onBlur={(_) => input.onBlur()} // <-- also required,
      onSelect={onSelect}
      data={data}
      textField={textField}
      valueField={valueField}
      caseSensitive={false}
      minLength={3}
      filter='contains'
    />
  );
};

export const renderComboBox = ({
  input,
  id,
  data,
  disabled,
  onChange,
  onSelect,
  valueField,
  textField,
  meta: { touched, error },
  busy,
  allData,
  style,
  link,
}) => {
  const emptyFn = () => {};
  return (
    <>
      <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
        {link && (
          <Link to={`${link}/${input.value.id}`} target='_blank'>
            <Button color='success' type='button' title='Visit element'>
              <i className='fa fa-external-link' />
            </Button>
          </Link>
        )}
        <Combobox
          {...input}
          id={id}
          onChange={onChange || input.onChange}
          onBlur={(_) => input.onBlur()} // <-- also required
          onSelect={onSelect || input.onSelect || emptyFn}
          data={allData && allData.length > 0 ? allData : data}
          disabled={disabled}
          textField={textField}
          valueField={valueField}
          style={{ flex: 1 }} // <-- modified to fill remaining width
          minLength={3}
          filter='contains'
          busy={busy}
          busySpinner={<span className='fa fa-spinner fa-spin' style={{ color: 'white', marginBottom: '8px' }} />}
        />
      </div>
      <br />
      {touched && error && <Alert color='danger'>{error}</Alert>}
    </>
  );
};

export const renderCriteriaOptions = ({ input, type, meta: { touched, error } }) => (
  <div>
    <Input {...input} type={type}>
      <option value='None'>None</option>
      <option value='Include'>Include</option>
      <option value='Exclude'>Exclude</option>
    </Input>
    <br />
    {touched && error && <Alert color='danger'>{error}</Alert>}
  </div>
);

export const renderDailyHourRestrictions = ({ input, label, type, meta: { error, touched } }) => {
  return (
    <div>
      <label>{label}</label>
      <Input {...input} type={type}>
        <option value=''>Select a time restriction...</option>
        <option value='12:00 AM'>12:00 AM</option>
        <option value='12:15 AM'>12:15 AM</option>
        <option value='12:30 AM'>12:30 AM</option>
        <option value='12:45 AM'>12:45 AM</option>
        <option value='1:00 AM'>1:00 AM</option>
        <option value='1:15 AM'>1:15 AM</option>
        <option value='1:30 AM'>1:30 AM</option>
        <option value='1:45 AM'>1:45 AM</option>
        <option value='2:00 AM'>2:00 AM</option>
        <option value='2:15 AM'>2:15 AM</option>
        <option value='2:30 AM'>2:30 AM</option>
        <option value='2:45 AM'>2:45 AM</option>
        <option value='3:00 AM'>3:00 AM</option>
        <option value='3:15 AM'>3:15 AM</option>
        <option value='3:30 AM'>3:30 AM</option>
        <option value='3:45 AM'>3:45 AM</option>
        <option value='4:00 AM'>4:00 AM</option>
        <option value='4:15 AM'>4:15 AM</option>
        <option value='4:30 AM'>4:30 AM</option>
        <option value='4:45 AM'>4:45 AM</option>
        <option value='5:00 AM'>5:00 AM</option>
        <option value='5:15 AM'>5:15 AM</option>
        <option value='5:30 AM'>5:30 AM</option>
        <option value='5:45 AM'>5:45 AM</option>
        <option value='6:00 AM'>6:00 AM</option>
        <option value='6:15 AM'>6:15 AM</option>
        <option value='6:30 AM'>6:30 AM</option>
        <option value='6:45 AM'>6:45 AM</option>
        <option value='7:00 AM'>7:00 AM</option>
        <option value='7:15 AM'>7:15 AM</option>
        <option value='7:30 AM'>7:30 AM</option>
        <option value='7:45 AM'>7:45 AM</option>
        <option value='8:00 AM'>8:00 AM</option>
        <option value='8:15 AM'>8:15 AM</option>
        <option value='8:30 AM'>8:30 AM</option>
        <option value='8:45 AM'>8:45 AM</option>
        <option value='9:00 AM'>9:00 AM</option>
        <option value='9:15 AM'>9:15 AM</option>
        <option value='9:30 AM'>9:30 AM</option>
        <option value='9:45 AM'>9:45 AM</option>
        <option value='10:00 AM'>10:00 AM</option>
        <option value='10:15 AM'>10:15 AM</option>
        <option value='10:30 AM'>10:30 AM</option>
        <option value='10:45 AM'>10:45 AM</option>
        <option value='11:00 AM'>11:00 AM</option>
        <option value='11:15 AM'>11:15 AM</option>
        <option value='11:30 AM'>11:30 AM</option>
        <option value='11:45 AM'>11:45 AM</option>
        <option value='12:00 PM'>12:00 PM</option>
        <option value='12:15 PM'>12:15 PM</option>
        <option value='12:30 PM'>12:30 PM</option>
        <option value='12:45 PM'>12:45 PM</option>
        <option value='1:00 PM'>1:00 PM</option>
        <option value='1:15 PM'>1:15 PM</option>
        <option value='1:30 PM'>1:30 PM</option>
        <option value='1:45 PM'>1:45 PM</option>
        <option value='2:00 PM'>2:00 PM</option>
        <option value='2:15 PM'>2:15 PM</option>
        <option value='2:30 PM'>2:30 PM</option>
        <option value='2:45 PM'>2:45 PM</option>
        <option value='3:00 PM'>3:00 PM</option>
        <option value='3:15 PM'>3:15 PM</option>
        <option value='3:30 PM'>3:30 PM</option>
        <option value='3:45 PM'>3:45 PM</option>
        <option value='4:00 PM'>4:00 PM</option>
        <option value='4:15 PM'>4:15 PM</option>
        <option value='4:30 PM'>4:30 PM</option>
        <option value='4:45 PM'>4:45 PM</option>
        <option value='5:00 PM'>5:00 PM</option>
        <option value='5:15 PM'>5:15 PM</option>
        <option value='5:30 PM'>5:30 PM</option>
        <option value='5:45 PM'>5:45 PM</option>
        <option value='6:00 PM'>6:00 PM</option>
        <option value='6:15 PM'>6:15 PM</option>
        <option value='6:30 PM'>6:30 PM</option>
        <option value='6:45 PM'>6:45 PM</option>
        <option value='7:00 PM'>7:00 PM</option>
        <option value='7:15 PM'>7:15 PM</option>
        <option value='7:30 PM'>7:30 PM</option>
        <option value='7:45 PM'>7:45 PM</option>
        <option value='8:00 PM'>8:00 PM</option>
        <option value='8:15 PM'>8:15 PM</option>
        <option value='8:30 PM'>8:30 PM</option>
        <option value='8:45 PM'>8:45 PM</option>
        <option value='9:00 PM'>9:00 PM</option>
        <option value='9:15 PM'>9:15 PM</option>
        <option value='9:30 PM'>9:30 PM</option>
        <option value='9:45 PM'>9:45 PM</option>
        <option value='10:00 PM'>10:00 PM</option>
        <option value='10:15 PM'>10:15 PM</option>
        <option value='10:30 PM'>10:30 PM</option>
        <option value='10:45 PM'>10:45 PM</option>
        <option value='11:00 PM'>11:00 PM</option>
        <option value='11:15 PM'>11:15 PM</option>
        <option value='11:30 PM'>11:30 PM</option>
        <option value='11:45 PM'>11:45 PM</option>
      </Input>
      <br />
      {touched && error && <Alert color='danger'>{error}</Alert>}
    </div>
  );
};

export const renderQuestionType = ({ input, label, type }) => (
  <div>
    <label>{label}</label>
    <Input {...input} type={type}>
      <option value=''>Select</option>
      <option value='Yes/No'>Yes/No</option>
      <option value='Multi-option'>Multi-option</option>
      <option value='Multi-select'>Multi-select</option>
    </Input>
  </div>
);

export const handleChangeQuestionType = (fields, event) => {
  if (event.target.value === 'Yes/No') {
    switch (fields.length) {
      case 0:
        fields.push({});
        fields.push({});
        break;
      case 1:
        fields.push({});
        break;
      case 2:
        break;
      default:
        //Remove extra
        for (let i = 2; i < fields.length; i++) {
          fields.remove(2);
        }
        break;
    }
  } else if (event.target.value === 'Multi-option') {
    switch (fields.length) {
      case 0:
        fields.push({});
        break;
      default:
        break;
    }
  } else if (event.target.value === 'Multi-select') {
    switch (fields.length) {
      case 0:
        fields.push({});
        fields.push({});
        break;
      default:
        break;
    }
  } else {
    for (let i = 0; i < fields.length; i++) {
      fields.remove(0);
    }
  }
};

export const renderKVEditor = ({ fields, touch }) => {
  const keyColumnSize = 3;
  const addMetadataRow = (e) => {
    e.preventDefault();
    fields.push({ key: '', value: '' });
    touch(fields.name);
  };
  const removeMetadataRow = (idx) => (e) => {
    e.preventDefault();
    fields.remove(idx);
    touch(fields.name);
  };

  return (
    <div className='kv-editor'>
      <Label>Metadata</Label>
      <Row>
        <Col md={1}>
          <Button color='secondary' onClick={addMetadataRow}>
            <i className='fa fa-plus' aria-hidden='true' />
          </Button>
        </Col>
        <Col className='edit-tag-form__metadata-header' md={keyColumnSize}>
          Key
        </Col>
        <Col className='edit-tag-form__metadata-header'>Value</Col>
      </Row>
      {fields.map((tuple, idx) => (
        <Row key={idx}>
          <Col md={1}>
            <Button color='secondary' onClick={removeMetadataRow(idx)}>
              <i className='fa fa-minus' aria-hidden='true' />
            </Button>
          </Col>
          <Col md={keyColumnSize}>
            <Field name={`${tuple}.key`} type='text' component={renderField} />
          </Col>
          <Col>
            <Field name={`${tuple}.value`} type='text' component={renderField} />
          </Col>
        </Row>
      ))}
    </div>
  );
};

function abbreviateText(text) {
  const abbreviationMap = {
    Question: 'Q',
    Banner: 'B',
    Form: 'F',
    'Offer Block': 'OB',
    'Offer Wall': 'OW',
    Iframe: 'I',
    Javascript: 'JS',
  };

  return abbreviationMap[text] || '?';
}

export const renderOffersSequenceNode = (offer, i, dbOrderBy) => {
  return !offer.isActive && offer.isHiddenPlaceholder ? (
    <>
      <div
        className={`offerName topHalfDiv ${offer.isActive ? 'active' : 'inactive'}`}
        id={`offersSequence-${offer.id}-${i}`}
        name={`offersSequence-${offer.id}-${i}`}
      >
        <p>{`${offer.hiddenOfferName}`}</p>
      </div>
      <div className='bottomHalfDiv' />
    </>
  ) : (
    <>
      <div
        className={`offerName topHalfDiv ${offer.isActive ? 'active' : 'inactive'}`}
        id={`offersSequence-${offer.id}-${i}`}
        name={`offersSequence-${offer.id}-${i}`}
      >
        <span title={offer ? offer.name : null}>
          {offer ? offer?.name?.substring(0, 30) : null}
          {offer && offer.name.length > 30 ? '...' : ''}
        </span>
      </div>
      <div className='bottomHalfDiv'>
        <span>{dbOrderBy}</span>
        <span className='offerTypeName'>
          {abbreviateText(offer?.offerType?.name ? offer.offerType.name : offer.offerType)}
        </span>
      </div>
    </>
  );
};

export const renderOrphanedOffers = (orphanedOffers, handleOrphanedOfferClick) => {
  return orphanedOffers.map((offerSequence, i) => {
    return (
      <span key={offerSequence.id}>
        <a href={`#offersSequence-${offerSequence.id}`} onClick={handleOrphanedOfferClick} data-id={offerSequence.id}>
          {offerSequence.name}
        </a>
        {orphanedOffers.length > 1 && i < orphanedOffers.length - 1 ? ' | ' : ''}
      </span>
    );
  });
};

export const RenderNumberPicker = ({ input, meta, placeholder, disabled, precision, step, defaultValue }) => {
  return (
    <div>
      <NumberPicker
        precision={precision}
        placeholder={placeholder}
        value={input.value || defaultValue}
        onChange={input.onChange}
        disabled={disabled}
        step={step}
        min={0}
      />
      <br />
      {meta.error && meta.touched && <Alert color='danger'>{meta.error}</Alert>}
    </div>
  );
};

export const RenderCapSegmentConfiguration = ({
  fields,
  domains,
  capAmount,
  capSegmentConfiguration,
  offerCriteriaId,
}) => {
  const dailyRestrictionSelectors = useDailyRestrictionSelectors();
  const selectedDomains = dailyRestrictionSelectors?.capSegmentConfiguration?.capSegments.flatMap(
    (segment) => segment?.domains || []
  );

  const updateCapSegmentRemainder = (newRemainder) => {
    if (newRemainder !== capSegmentConfiguration.capSegmentRemainder) {
      capSegmentConfiguration.capSegmentRemainder = newRemainder;
    }
  };

  const handleSegmentRemove = (index) => {
    fields.remove(index);
  };

  return (
    <div>
      {fields.map((capSegment, index) => (
        <div key={index}>
          <Row>
            <Col lg={1}>
              <br />
              <Button
                color='danger'
                className='removeofferInputParent'
                type='button'
                title='Remove Parent Attribute'
                // onClick={() => fields.remove(index)}
                onClick={() => handleSegmentRemove(index)}
              >
                <i className='fa fa-close' />
              </Button>
            </Col>
            <Col>
              <label>Domains</label>
              <Field
                name={`${capSegment}.domains`}
                component={renderMultiSelect}
                data={domains ? domains.filter((domain) => !selectedDomains.includes(domain)) : []}
              />
            </Col>
            <Col>
              <label>Segment Amount</label>
              <Field name={`${capSegment}.segmentAmount`} component={RenderNumberPicker} defaultValue={0} />
            </Col>
            <Col>
              <CurrentCapAmountByDomains domains={fields.get(index)?.domains} id={offerCriteriaId} />
            </Col>
          </Row>
        </div>
      ))}
      {capSegmentConfiguration?.capSegments?.length > 0 && (
        <Row>
          <Col lg={1} />
          <Col />
          <Col>
            <label>Segment Remainder</label>
          </Col>
          <Col />
        </Row>
      )}
      <Row>
        <Col lg={1} />
        <Col />
        <Col>
          <CapSegmentRemainder
            capAmount={capAmount}
            capSegments={capSegmentConfiguration?.capSegments}
            capSegmentRemainder={capSegmentConfiguration?.capSegmentRemainder}
            updateCapSegmentRemainder={updateCapSegmentRemainder}
          />
        </Col>
        <Col>
          <Button
            color='success'
            onClick={() =>
              fields.push({
                domains: [],
                segmentAmount: 0,
              })
            }
          >
            Add Cap Segments
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export const CapIntervals = ({ input, type, meta: { touched, error } }) => (
  <div>
    <Input {...input} type={type}>
      <option value=''>None</option>
      <option value='Daily'>Daily</option>
    </Input>
    <br />
    {touched && error && <Alert color='danger'>{error}</Alert>}
  </div>
);
