import React, { Component } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Switch from 'react-switch';
class UpdateStatus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      success: false,
      isActive: this.props.isActive
    };

    this.toggleSuccess = this.toggleSuccess.bind(this);
    this.saveSuccess = this.saveSuccess.bind(this);
  }

  saveSuccess() {
    this.setState({
      success: !this.state.success
    });
    this.props.onClickSubmit(this.state.isActive);
  }

  renderAppSwitch() {
    return (
      <Switch
        onChange={this.toggleSuccess}
        checked={this.state.isActive}
        onColor={'#4dbd74'}
        offColor={'#f86c6b'}
        width={70}
        checkedIcon={
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '96%',
              fontSize: 11
            }}
          >
            Active
          </div>
        }
        uncheckedIcon={
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '96%',
              fontSize: 11
            }}
          >
            Inactive
          </div>
        }
      />
    );
  }

  toggleSuccess = isCancel => {
    return this.setState({
      success: !this.state.success,
      isActive: !this.state.isActive
    });
    // }
  };

  // toggleSuccess() {
  //   this.setState(prevState => {
  //     return this.props.isEditMode
  //       ? {
  //           success: !this.state.success,
  //           isActive: !prevState.isActive
  //         }
  //       : {
  //           isActive: !prevState.isActive
  //         };
  //   });
  // }

  // componentDidMount() {
  //   const isActive = this.props.content.online;
  //   this.setState({
  //     isActive
  //   });
  // }

  render() {
    return (
      <div className='animated'>
        {this.renderAppSwitch()}
        <Modal isOpen={this.state.success} toggle={this.toggleSuccess}>
          <ModalHeader toggle={this.toggleSuccess}>
            Change User Status
          </ModalHeader>
          <ModalBody>
            You are about to update the status of this user:
            <br />
            <br />
            <h3>{this.props.content.name}</h3>
          </ModalBody>
          <ModalFooter>
            <Button color='success' onClick={this.saveSuccess}>
              Update
            </Button>{' '}
            <Button color='secondary' onClick={this.toggleSuccess}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default UpdateStatus;
