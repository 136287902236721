import { UPDATE_SELECTOR } from '../actions/types';
import { formValueSelector } from 'redux-form';

const initialState = {
  selector: formValueSelector('default'),
  formName: 'default',
};
export default function (state = initialState, action) {
  switch (action.type) {
    case UPDATE_SELECTOR:
      return {
        ...state,
        selector: formValueSelector(action.payload),
        formName: action.payload,
      };
    default:
      return state;
  }
}
