export const handleOfferActionSubtypeInit = (offerAction) => {
  let initData = { title: offerAction.title, offerActionType: offerAction.offerActionType, offerAction };
  if (offerAction.offerActionType?.includes('WATERFALL')) {
    const offerActionTypeName =
      offerAction.offerActionType === 'POST_WATERFALL' ? 'offerActionPostWaterfall' : 'offerActionLinkoutWaterfall';
    initData = { ...initData, ...handleWaterfallInit(offerAction[offerActionTypeName]) };
  }

  return initData;
};

export const handleWaterfallInit = (actionWaterfall) => {
  const waterfall = {
    id: actionWaterfall.id,
    waterfallLogic: actionWaterfall.waterfallLogic ?? 'FLEX',
  };
  console.log(actionWaterfall);
  if (actionWaterfall.offerActionPostWaterfallEntries) {
    waterfall.offerActionPostWaterfallEntries = mapWaterfallEntries(actionWaterfall.offerActionPostWaterfallEntries);
  } else if (actionWaterfall.offerActionLinkoutWaterfallEntries) {
    waterfall.offerActionLinkoutWaterfallEntries = mapWaterfallEntries(
      actionWaterfall.offerActionLinkoutWaterfallEntries
    );
  }
  console.log(waterfall);
  return waterfall;
};

const mapWaterfallEntries = (waterfallEntries) => {
  return (
    waterfallEntries
      .map((entry) => {
        const actionSubtype = entry.offerActionPost ? 'offerActionPost' : 'offerActionLinkout';
        return {
          id: entry.id,
          flex: parseInt(entry.flex),
          [actionSubtype]: entry[actionSubtype],
          advertiser: entry[actionSubtype]
            ? entry[actionSubtype].advertiser && entry[actionSubtype].advertiser?.name
            : null,
          orderBy: entry.orderBy,
        };
      })
      // only sort if order_by exists on the object (Post Waterfall entries vs Linkout Waterfall entries)
      // sort in ascending order

      .sort((a, b) => a.orderBy - b.orderBy)
  );
};

export const parseOfferActionSubtype = (offerAction) => {
  let parsedOfferActionSubtype;
  console.log(offerAction);
  switch (offerAction.offerActionType) {
    case 'POST_WATERFALL':
      parsedOfferActionSubtype = handleWaterfallSubmit(offerAction);
      break;
    case 'LINKOUT_WATERFALL':
      parsedOfferActionSubtype = handleWaterfallSubmit(offerAction);
      break;
    default:
      break;
  }
  console.log(parsedOfferActionSubtype);
  return parsedOfferActionSubtype;
};

const handleWaterfallSubmit = (values) => {
  const actionSubtypeEntries = values.offerActionPostWaterfallEntries
    ? 'offerActionPostWaterfallEntries'
    : 'offerActionLinkoutWaterfallEntries';
  const actionSubtype =
    actionSubtypeEntries === 'offerActionPostWaterfallEntries' ? 'offerActionPost' : 'offerActionLinkout';
  const waterfall = {
    [actionSubtypeEntries]: values[actionSubtypeEntries].map((entry, i) => {
      const actionSubtypeEntry = {
        flex: parseInt(entry.flex),
        id: entry.id,
        [actionSubtype]: entry[actionSubtype],
        orderBy: i,
      };
      return actionSubtypeEntry;
    }),
    waterfallLogic: values.waterfallLogic || 'FLEX',
  };

  if (values.id) {
    waterfall.id = values.id;
  }
  if (values?.offerAction?.offerActionPostWaterfall) {
    waterfall.isPreprocessed = values.offerAction.offerActionPostWaterfall.isPreprocessed;
  }
  return waterfall;
};

export const calculatePercentages = (weights) => {
  const percentages = {};
  let totalWeights = weights.reduce((acc, weight) => acc + weight, 0);

  weights.forEach((weight, index) => {
    const weightedFlex = parseFloat((weight / totalWeights) * 100).toFixed(2);
    percentages[`percent-${index}`] = weightedFlex;
  });

  return percentages;
};
