import {
  Card,
  CardBody,
  CardHeader,
  Row,
  Col, Button,
} from 'reactstrap';
import { columnDefaults, columnFormatter } from '../../utils/tableFunctions';
import React, { useCallback, useState } from 'react';
import ActiveStatusModal from '../../components/ActiveStatusModal/ActiveStatusModal';
import { useFeatureStrategies, useFeatures, useFeatureGroups, useUpdateFeature } from './queires';
import SummaryCard from '../../components/SummaryCard/SummaryCard';
import DropdownList from 'react-widgets/DropdownList';
import toast from 'react-hot-toast';
import FeatureModal from './modal/FeatureModal';
import { ZuulToaster } from '../../components/Toaster/ZuulToaster';
import FeatureModalButton from './modal/FeatureModal';


const FeatureToggles = () => {
  const zuulUser = localStorage.getItem('userData');

  if (!zuulUser || JSON.parse(zuulUser).zuulUserRole.name !== 'Admin' ) {
    return (
      <Card>
        <CardHeader>
          <Row>
            <Col lg={12}>
              <h4>Feature Toggles</h4>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <Row>
            <Col lg={12}>
              <h5>You must be admin to view this page.</h5>
            </Col>
          </Row>
        </CardBody>
      </Card>
    );
  }


  const { data: zuulFeatures, isLoading: loadingFeatures } = useFeatures();
  const { data: featureStrategies, isLoading: loadingStrategies } = useFeatureStrategies();
  const { data: featureGroups, isLoading: loadingGroups } = useFeatureGroups();
  const { mutateAsync: updateFeature, isLoading: updatingFeature } = useUpdateFeature();
  const [groupFilter, setGroupFilter] = useState("All");

  const features = filteredFeatures(zuulFeatures?.map(feature => ({
    ...feature,
    strategyDescription: resolveStrategyDescription(featureStrategies, feature.strategyId),
  })), groupFilter);

  const handleUpdateFeature = useCallback((feature) => {
    toast.promise(updateFeature(feature), {
      loading: 'Updating feature...',
      success: (data) => (data.status === 200
        ? 'Feature updated successfully!'
        : 'Could not update feature.'),
      error: (err) => 'Error updating feature:' +  err.message || err?.response?.data?.status,
    });
  }, [updateFeature]);

  const handleUpdateFeatureStatus = useCallback((feature, activeStatus) => {
    toast.promise(updateFeature({ ...feature, enabled: activeStatus }), {
      loading: 'Updating feature...',
      success: (data) => (data.status === 200
        ? `${feature.name} has been ${activeStatus ? 'enabled' : 'disabled'}`
        : `Failed to ${activeStatus ? 'enabled' : 'disabled'} update feature.`),
      error: (err) => err?.response?.data?.status || err.message,
    });
  }, [updateFeature]);

  const columns = [
    {
      dataField: 'name',
      text: 'Name',
      sort: true,
      headerStyle: {
        backgroundColor: '#2f353a',
      },
    },
    {
      dataField: 'isActive',
      text: 'Status',
      formatter: (_cell, row) => {
        return (
          <ActiveStatusModal
            content={row}
            isActive={row.enabled}
            modalType=" Feature"
            getErrors={() => null}
            onClickSubmit={(activeStatus) => handleUpdateFeatureStatus(row, activeStatus)}
            isEditMode={true}
          />
        );
      },
      style: { width: '0em' },
      ...columnDefaults,
    },
    {
      dataField: 'description',
      text: 'Description',
      sort: true,
      headerStyle: {
        backgroundColor: '#2f353a',
      },
    },
    {
      dataField: 'strategyDescription',
      text: 'Strategy',
      sort: true,
      headerStyle: {
        backgroundColor: '#2f353a',
      },
    },
    {
      dataField: '',
      text: '',
      isDummyField: true,
      formatter: (_cell, row) => (
        <Row>
          <Col lg={12}>
            <FeatureModalButton feature={row} updateFeature={handleUpdateFeature} strategies={featureStrategies} />
          </Col>
        </Row>
      ),
      headerStyle: {
        backgroundColor: '#2f353a',
        textAlign: 'center',
      },
      style: { width: '0em' },
      searchable: false,
    },
  ];

  return (
    <SummaryCard
      entity="Feature Toggles"
      columns={columns}
      data={features}
      defaultSorted={[{ dataField: 'name', order: 'asc' }]}
      loading={loadingFeatures || loadingStrategies || loadingGroups}
    >
      <ZuulToaster />
      <DropdownList
        defaultValue={'All'}
        data={featureGroups}
        onChange={(value) => setGroupFilter(value)}
      />
    </SummaryCard>
  );
};

const resolveStrategyDescription = (strategies, strategyId) => {
  return strategies.find((strategy) => strategy.id === strategyId)?.name || '';
};

const filteredFeatures = (features, filter) => {
  if (!features || filter === 'All')
    return features;
  console.log('Filtering features by group:', filter);
  return features.filter((feature) => feature.groups.includes(filter));
}

export default FeatureToggles;
