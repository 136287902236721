import { GET_ALL_OFFER_TYPES } from '../../actions/types';
import _ from 'lodash';
import api from '../../../../services';

export const getOfferTypes = () => (dispatch) => {
  return new Promise((resolve) => {
    api
      .get('offertypes')
      .then((response) => {
        console.log(response);
        const offerTypesHashMap = _.mapKeys(response.data, 'name');
        console.log(offerTypesHashMap);
        dispatch({
          type: GET_ALL_OFFER_TYPES,
          payload: offerTypesHashMap,
        });

        resolve(offerTypesHashMap);
      })
      .catch((error) => console.log(error));
  });
};
