import { useQuery } from '@tanstack/react-query';
import format from 'date-fns/format';
import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import LocationsModal from '../../../components/LocationsModal/LocationsModal';
import SummaryCard from '../../../components/SummaryCard/SummaryCard';
import { fetchAll } from '../../../services/helpers';
import { columnDefaults, columnFormatter } from '../../../utils/tableFunctions';
import ChangelogModal from '../../../components/ChangelogModal/ChangelogModal';

const OfferActions = () => {
  const { data, isLoading: loading, error } = useQuery({
    queryKey: ['offerActionsTable'],
    queryFn: () => fetchAll('offer-action/table'),
  });

  function linkFormatter(cell, row) {
    return (
      <Link to={`/campaigns/offeractions/${row.id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
        {cell}
      </Link>
    );
  }

  const columns = [
    {
      dataField: 'id',
      text: 'ID',
      formatter: linkFormatter,
      ...columnDefaults,
    },
    {
      dataField: 'title',
      text: 'Action Name',
      formatter: linkFormatter,
      ...columnDefaults,
    },
    {
      dataField: 'advertiser',
      text: 'Advertiser Name',
      formatter: linkFormatter,
      ...columnDefaults,
    },
    {
      dataField: 'offerActionType',
      text: 'Action Type',
      formatter: linkFormatter,
      ...columnDefaults,
    },
    {
      dataField: 'lastEditedBy',
      text: 'Last Published',
      formatter: linkFormatter,
      sortValue: (cell, row) => new Date(row.lastEdited),
      ...columnDefaults,
    },
    {
      dataField: 'locations',
      text: 'Trigger Locations',
      isDummyField: true,
      formatter: (_cell, row) => (
        <Row>
          <Col lg={12}>
            <LocationsModal content={row} />
          </Col>
        </Row>
      ),
      headerStyle: {
        backgroundColor: '#2f353a',
      },
    },
    {
      dataField: 'active',
      text: 'Status',
      formatter: (cell, row) => {
        return columnFormatter({
          cell,
          row,
          computedURL: URL + row.id,
          activeStatusType: 'switch',
          entity: {
            modalType: ' Offer Action',
            endpoint: 'offer-action',
          },
        });
      },
      ...columnDefaults,
    },
    {
      dataField: 'changelog',
      text: 'Audit',
      isDummyField: true,
      formatter: (_cell, row) => (
        <Row>
          <Col lg={12}>
            <ChangelogModal typeName='Offer Action' apiPath='offer-action' id={row.id} />
          </Col>
        </Row>
      ),
      headerStyle: {
        backgroundColor: '#2f353a',
        textAlign: 'center',
      },
      searchable: false,
    },
    {
      dataField: 'changelog-criteria',
      text: 'Criteria Audit',
      isDummyField: true,
      formatter: (_cell, row) => (
        <Row>
          <Col lg={12}>
            <ChangelogModal typeName='Offer Criteria' apiPath='offer-action-criteria' id={row.offerCriteriaId} />
          </Col>
        </Row>
      ),
      headerStyle: {
        backgroundColor: '#2f353a',
        textAlign: 'center',
      },
      searchable: false,
    },
  ];

  return (
    <>
      <SummaryCard
        entity='Offer Actions'
        createComponent={
          <Link to='/campaigns/offeractions/offer-action-detail'>
            <Button color='primary' className='float-right'>
              <i className='fa fa-plus' />
              &nbsp; Add Offer Action
            </Button>
          </Link>
        }
        columns={columns}
        defaultSorted={[{ dataField: 'lastEditedBy', order: 'desc' }]}
        data={data
          ?.filter((offerAction) => offerAction.offerActionType !== 'GTM_EVENT')
          .map((offerAction) => ({
            ...offerAction,
            lastEditedBy: offerAction.zuulUser
              ? format(new Date(offerAction.lastEdited), 'Pp') + ' by ' + offerAction.zuulUser.realname
              : format(new Date(offerAction.lastEdited), 'Pp'),
          }))}
        loading={loading}
        error={error}
      />
    </>
  );
};

export default OfferActions;
