import { useState, useMemo } from 'react';
import SimpleLayoutEditor from './SimpleLayoutEditor';
import SimpleContainerEditor from './SimpleContainerEditor';
import { Col, Row } from 'reactstrap';
import HtmlPreview from './HtmlPreview';
import { ZuulToaster } from '../../components/Toaster/ZuulToaster';

const TemplateEditor = () => {
  const [layoutData, setLayoutData] = useState({ id: null, name: '', content: ''});
  const [containerData, setContainerData] = useState({ id: null, name: '', content: ''});

  const html = useMemo(() => {
    return layoutData.content.replace('{{{container_content}}}', containerData.content);
  }
  , [layoutData, containerData]);

  return (
    <div className='animated fadeIn'>
      <ZuulToaster position={'top-center'} style={{fontSize: '1.2em'}}/>
      <Row>
        <Col lg={6}>
          <SimpleLayoutEditor layoutData={layoutData} setLayoutData={setLayoutData} />
          <SimpleContainerEditor containerData={containerData} setContainerData={setContainerData} />
        </Col>
        <Col lg={6}>
          <HtmlPreview html={html} />
        </Col>
      </Row>
    </div>
  );
};

export default TemplateEditor;
