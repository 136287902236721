import React, { Component } from 'react';
import { sortByName } from '../../actions';
import { Row, Col, Card, CardHeader, CardBody, Label } from 'reactstrap';
import api from '../../services';
import 'react-widgets/styles.css';
import './Advertisers.css';
import EditAdvertiserModal from './EditAdvertiserModal';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import _ from 'lodash';
import AddAdvertiserModal from './AddAdvertiserModal';
import SearchLabel from '../../components/SearchLabel/SearchLabel';

class Advertisers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeRow: null,
      advertisers: [],
      advertiserValue: null,
      isModalOpen: false,
      sortedAdvertisers: [],
      // clickedAdvertiser: {},
      selectedAdvertiser: {
        address1: '',
        address2: '',
        city: '',
        contactEmail: '',
        contactFirstName: '',
        contactLastName: '',
        contactPhone: '',
        country: '',
        id: null,
        name: '',
        phone1: '',
        phone2: '',
        phone3: '',
        state: '',
        url: '',
        zip: '',
      },
    };
    this.onSubmit = this.onSubmit.bind(this);
  }

  toggleModal = () => {
    this.setState({
      isModalOpen: !this.state.isModalOpen,
    });
  };

  // deleteAdvertiser = values => {
  //   return api
  //     .delete(`advertisers/${this.props.selectedAdvertiserID}`)
  //     .then(result => {
  //       console.log(JSON.stringify(result));
  //       this.resetForm();
  //       this.getAdvertisers();
  //     });
  // };

  onSubmit(values, isNewAdvertiser) {
    if (isNewAdvertiser) {
      return api.post('advertisers', values).then((result) => {
        console.log(JSON.stringify(result));
        this.getAdvertisers();
      });
    } else {
      console.log(this.state.selectedAdvertiser);
      return api.put(`advertisers/${this.state.selectedAdvertiser.id}`, values).then((result) => {
        console.log(JSON.stringify(result));
        this.getAdvertisers();
        this.setState({ selectedAdvertiser: {} });
      });
    }
  }

  renderAdvertiserList = () => {
    const { SearchBar, ClearSearchButton } = Search;
    const columns = [
      {
        dataField: 'name',
        text: 'Advertiser Name',
        sort: true,
        sortCaret: (order, column) => {
          if (!order)
            return (
              <span>
                &nbsp;&nbsp;&nbsp;
                <i className='fa fa-sort' />
              </span>
            );
          else if (order === 'asc')
            return (
              <span>
                &nbsp;&nbsp;
                <i className='fa fa-sort' />
              </span>
            );
          else if (order === 'desc')
            return (
              <span>
                &nbsp;&nbsp;
                <i className='fa fa-sort' />
              </span>
            );
          return null;
        },
        headerStyle: {
          backgroundColor: '#2f353a',
        },
      },
    ];
    const rowEvents = {
      onClick: (e, row, rowIndex) => {
        const selectedAdvertiser = this.state.advertisers[row.id];
        this.setState({ selectedAdvertiser, isModalOpen: true });
      },
      onMouseEnter: (e, row, rowIndex) => {
        this.setState({ activeRow: rowIndex });
      },
    };
    const rowStyle = (row, rowIndex) => {
      const style = {};
      if (rowIndex === this.state.activeRow) {
        style.backgroundColor = '#2f353a';
        style.cursor = 'pointer';
      }
      return style;
    };
    return (
      <ToolkitProvider
        keyField='id'
        data={this.state.sortedAdvertisers}
        columns={columns}
        dataSort
        search
        bootstrap4={true}
      >
        {(props) => (
          <div>
            <Row>
              <Col lg={6}>
                <span className='display-4'>Advertisers</span>
              </Col>
              <Col lg={6}>
                <AddAdvertiserModal onClickSubmit={this.onSubmit} />
              </Col>
            </Row>
            <br />
            <Row>
              <Col lg={9}>
                <SearchLabel />
                <br />
                <SearchBar {...props.searchProps} tableId='Advertisers' />
              </Col>
            </Row>
            <br />
            <BootstrapTable
              filter={filterFactory()}
              {...props.baseProps}
              pagination={paginationFactory()}
              rowEvents={rowEvents}
              rowStyle={rowStyle}
            />
          </div>
        )}
      </ToolkitProvider>
    );
  };

  getAdvertisers() {
    return new Promise((resolve) => {
      api
        .get('advertisers')
        .then((response) => {
          // this will map the array of advertisers to an object for easy access when you select them from the combobox
          const advertisers = _.mapKeys(response.data, 'id');
          const sortedAdvertisers = sortByName(response.data);
          const newState = Object.assign({}, this.state, {
            sortedAdvertisers,
            advertisers,
          });
          this.setState(newState);
          resolve(newState);
        })
        .catch((error) => console.log(error));
    });
  }

  componentDidMount() {
    this.getAdvertisers();
  }

  resetForm = () => this.props.reset();

  render() {
    return (
      <div className='animated fadeIn'>
        <Card>
          <CardHeader>
            <i className='icon-menu' /> Advertisers
          </CardHeader>
          <CardBody>
            <Row>
              <Col lg='12'>{this.renderAdvertiserList()}</Col>
            </Row>
            <Row>
              <Col lg='12'>
                {this.state.isModalOpen && (
                  <EditAdvertiserModal
                    advertiser={this.state.selectedAdvertiser}
                    isModalOpen={this.state.isModalOpen}
                    toggleModal={this.toggleModal}
                    onClickSubmit={this.onSubmit}
                  />
                )}
              </Col>
            </Row>
          </CardBody>
        </Card>
      </div>
    );
  }
}

export default Advertisers;
