import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Col, Label, Row } from 'reactstrap';
import { Field, FieldArray } from 'redux-form';
import { createNumberMask } from 'redux-form-input-masks';
import ToggleButton from '../../../components/ToggleButton/ToggleButton';
import api from '../../../services/';
import { sortByName } from '../../../utils/sorting';
import {
  CapIntervals,
  renderActionSelect,
  RenderCapSegmentConfiguration,
  renderComboBox,
  renderDropdownList,
  renderField,
  renderMultiSelect,
  RenderNumberPicker,
  renderOfferPostMethod,
  renderOnRevenue,
  textarea,
} from '../OfferTitle/renderConsts';
import { validateWholeNums, validateWholeNumsWithDecimals } from '../OfferTitle/valildation';
import CurrentCapAmount from './CurrentCapAmount';

// import {getDispatch} from '../../../actions';

const Post = (props) => {
  const [isHovering, setIsHovering] = useState(false);
  const [marketingPartners, setMarketingPartners] = useState([]);
  const [loadingMarketingPartners, setLoadingMarketingPartners] = useState(true);
  const {
    advertisers,
    advancedSettingsIsHidden,
    handleAdvancedSettingsToggle,
    handlers,
    postMethod,
    handleTitleChange,
    isEditMode,
    capInterval,
    capAmount,
    capSegmentConfiguration,
    offerCriteriaId,
  } = props;

  const handleAngleHover = () => {
    setIsHovering(!isHovering);
  };

  const currencyMask = () => {
    return createNumberMask({
      prefix: '$ ',
      decimalPlaces: 3,
      locale: 'en-US',
    });
  };

  useEffect(() => {
    const getMarketingPartnersWithActions = () => {
      setLoadingMarketingPartners(true);
      api
        .get(`marketing_partner/withActions`)
        .then((response) => {
          setMarketingPartners(sortByName(response.data));
          setLoadingMarketingPartners(false);
        })
        .catch((error) => console.log(error));
    };
    getMarketingPartnersWithActions();
  }, []);

  const renderMarketingPartners = () => {
    return props.isTcpaCompliant ? (
      <>
        <br />
        <Row>
          <Col lg={4}>
            <Label>Marketing Partners</Label>
            <Field
              name='marketingPartners'
              component={renderMultiSelect}
              type='select'
              textField='name'
              valueField='id'
              data={marketingPartners}
              busy={loadingMarketingPartners}
            />
          </Col>
        </Row>
      </>
    ) : (
      <></>
    );
  };

  return (
    <div className='colorTest'>
      <br />
      <Row>
        <Col lg={4}>
          <div>
            <Field
              required
              type='text'
              component={renderField}
              id='title'
              name='title'
              onChange={handleTitleChange}
              placeholder='Post Name'
              label='Post Name'
            />
          </div>
        </Col>
        {!isEditMode && renderActionSelect(2)}
        <Col lg={2}>
          <Field
            name='postMethod'
            type='select'
            component={renderOfferPostMethod}
            label='Post Method'
            isOfferAction={true}
          />
        </Col>
        <Col lg={2} className='d-flex align-items-center'>
          <ToggleButton id='isTcpaCompliant' fieldName='isTcpaCompliant' style={{ marginTop: '15px' }}>
            TCPA Post &nbsp;
          </ToggleButton>
        </Col>
        <Col lg={2} className='d-flex align-items-center'>
          <ToggleButton id='claimTrustedForm' fieldName='claimTrustedForm' style={{ marginTop: '15px' }}>
            Claim Trusted Form
          </ToggleButton>
        </Col>
      </Row>
      {marketingPartners && renderMarketingPartners()}
      <br />
      <Row>
        <Col lg={4}>
          <Label>Advertiser</Label>
          <Field
            type='select'
            name='advertiser'
            component={renderComboBox}
            data={advertisers}
            textField='name'
            valueField='id'
          />
        </Col>
        <Col lg={4}>
          <Label>Success handler</Label>
          <Field
            name='httpSuccessHandler'
            type='select'
            component={renderComboBox}
            data={handlers}
            textField='name'
            valueField='id'
          />
        </Col>
      </Row>
      <br />
      <Row>
        <Col lg={8}>
          <Field required type='text' component={textarea} name='url' label='URL' rows={1} />
        </Col>
      </Row>
      <br />
      {postMethod && postMethod !== 'GET' && (
        <>
          <Row>
            <Col lg={8}>
              <Label>Body</Label>
              <Field name='body' type='text' component={textarea} />
            </Col>
          </Row>
          <br />
        </>
      )}
      <Row>
        <Col
          style={{
            cursor: `${isHovering ? 'pointer' : ''}`,
          }}
          onClick={handleAdvancedSettingsToggle}
          onMouseEnter={handleAngleHover}
          onMouseLeave={handleAngleHover}
          lg={3}
        >
          <h5>
            {!advancedSettingsIsHidden ? <i className='fa fa-angle-down' /> : <i className='fa fa-angle-right' />}
            &nbsp;&nbsp;&nbsp; <em>Advanced Settings</em> &nbsp;
            {`(${props.numOfAdvancedFields})`}
          </h5>
        </Col>
      </Row>
      <br />
      {!advancedSettingsIsHidden && (
        <div>
          <Row>
            <Col lg={4}>
              <Field name='httpHeader1' type='text' component={renderField} label='HTTP Header 1' />
            </Col>
            <Col lg={4}>
              <Field name='httpHeader2' type='text' component={renderField} label='HTTP Header 2' />
            </Col>
            <Col lg={4}>
              <Field name='httpHeader3' type='text' component={renderField} label='HTTP Header 3' />
            </Col>
          </Row>
          <br />
          <Row>
            <Col lg={4}>
              <Field name='basicAuthUsername' type='text' component={renderField} label='Basic Auth Username' />
            </Col>
            <Col lg={4}>
              <Field name='basicAuthPassword' type='text' component={renderField} label='Basic Auth Password' />
            </Col>
            <Col lg={4}>
              <Field
                name='otherSuccessfulHttpStatus'
                type='text'
                component={renderField}
                label='Other Successful HTTP Status'
              />
            </Col>
          </Row>
          <br />
          <Row>
            <Col lg={4}>
              <Label>Post Delay Interval</Label>
              <Field
                name='delayedPostingType'
                component={renderDropdownList}
                type='select'
                defaultValue='none'
                data={['none', 'days', 'hours', 'minutes']}
              />
            </Col>
            <Col lg={4}>
              <Field
                name='delayedPostingAmount'
                type='number'
                component={renderField}
                normalize={validateWholeNums}
                label='Amount'
              />
            </Col>
          </Row>
          <br />
          <Row>
            <Col lg={4}>
              <Label>Kill Switch Handler</Label>
              <Field
                name='httpKillSwitchHandler'
                type='select'
                component={renderComboBox}
                data={handlers}
                textField='name'
                valueField='id'
              />
            </Col>
            <Col lg={4}>
              <Field
                name='otherKillSwitchStatus'
                type='text'
                component={renderField}
                label='Other Kill Switch Status'
              />
            </Col>
          </Row>
        </div>
      )}
      <br />
      <h5>Revenue and Cap</h5>
      <hr />
      <div>
        <Row>
          <Col lg={2}>
            <Field name='revenueOn' component={renderOnRevenue} type='select' label='Revenue On: ' />
          </Col>
          <Col lg={2}>
            <Field
              name='revenue'
              component={renderField}
              type='text'
              label='Revenue'
              normalize={validateWholeNumsWithDecimals}
              {...currencyMask()}
            />
          </Col>
        </Row>
      </div>
      {props.delayedPostingType && props.delayedPostingType !== 'none' && (
        <Row style={{ backgroundColor: '#1C2A41', padding: '10px', margin: '0', borderRadius: '5px' }}>
          <Col xs='auto'>
            <i className='fa fa-exclamation-circle fa-2x' style={{ color: '#0dcaf0' }}></i>
          </Col>
          <Col
            style={{
              fontSize: '16px',
            }}
          >
            Because this post has a delay, every post will count towards the cap, regardless of success/failure of the
            post!
          </Col>
        </Row>
      )}
      <br />
      <Row>
        <Col>
          <Label>Cap Interval</Label>
          <Field name='capInterval' type='select' component={CapIntervals} />
        </Col>
        <Col>
          <Label>Cap Amount</Label>
          <Field name='capAmount' component={RenderNumberPicker} />
        </Col>
        <Col>
          <CurrentCapAmount id={offerCriteriaId} />
        </Col>
      </Row>
      <FieldArray
        name='capSegmentConfiguration.capSegments'
        component={RenderCapSegmentConfiguration}
        domains={props.domains}
        capAmount={props.capAmount}
        capSegmentConfiguration={props.capSegmentConfiguration}
        offerCriteriaId={props.offerCriteriaId}
      />
      <br />
    </div>
  );
};

function mapStateToProps(state) {
  const { dispatch } = state.criteria;
  const { offerAction } = state.offerAction;
  const offerCriteriaId = offerAction ? offerAction.offerCriteria && offerAction.offerCriteria.id : null;
  const capAmount = state.form.offerActionDetail?.values?.capAmount;
  const capSegmentConfiguration = state.form.offerActionDetail?.values?.capSegmentConfiguration || [];

  return {
    dispatch: state.criteria.dispatch,
    offerAction,
    offerCriteriaId,
    capInterval: state.offerState.selector(state, 'capInterval'),
    capAmount,
    capSegmentConfiguration,
    isTcpaCompliant: state.offerState.selector(state, 'isTcpaCompliant'),
  };
}

export default connect(mapStateToProps)(Post);
