import React, { useState, useEffect, useCallback } from 'react';
import toast from 'react-hot-toast';
import { Card, CardBody, CardHeader, Col, Row, Label, Button } from 'reactstrap';
import { renderComboBox } from '../Offers/OfferTitle/renderConsts';
import { Field, reduxForm } from 'redux-form';
import Editor from '@monaco-editor/react';
import { useGetLayoutById, useUpdateLayout, useLayoutsTable } from '../Layouts/queries';
import { ZuulEditor } from '../../components/ZuulEditor/ZuulEditor';

const SimpleLayoutEditor = ({ layoutData, setLayoutData }) => {
  const { data: layouts } = useLayoutsTable();
  const { mutateAsync: updateLayout, isLoading } = useUpdateLayout();
  const { data: layout, mutate: getLayoutById } = useGetLayoutById();
  const [validationErrors, setValidationErrors] = useState(false);

  const handleEditorChange = (value, errors) => {
    setValidationErrors(errors);
    if (errors) {
      toast.dismiss();
      toast.error('Invalid HTML detected, please fix the highlighted errors!', { duration: Infinity });
    } else {
      toast.dismiss();
    }
    setLayoutData({ ...layout, content: value });
  };

  const handleSave = useCallback(() => {
    toast.promise(updateLayout(layoutData), {
      loading: 'Updating layout...',
      success: (data) => (data.status === 200 ? 'Layout updated successfully!' : 'No content changes detected.'),
      error: (err) => err?.response?.data?.message || err.message,
    });
  }, [layoutData, updateLayout]);

  useEffect(() => {
    if (layout) setLayoutData(layout);
  }, [layout]);

  return (
    <>
      {layouts && (
        <Card>
          <CardHeader>
            <Row>
              <Col lg={9} style={{ display: 'flex', alignItems: 'baseline' }}>
                <Label for='layout' style={{ width: '5em' }}>
                  Layout:
                </Label>
                <Field
                  required
                  type='select'
                  component={renderComboBox}
                  id='layout'
                  name='layout'
                  placeholder='Layout'
                  label='Landing Page Layout'
                  textField='name'
                  valueField='id'
                  data={layouts}
                  onChange={(value) => value.id && getLayoutById(value.id)}
                  style={{ width: '100%' }}
                />
              </Col>
              <Col
                lg={3}
                style={{ display: 'flex', alignItems: 'baseline', justifyContent: 'flex-end', marginLeft: 'auto' }}
              >
                <Button color='success' onClick={handleSave} disabled={isLoading || validationErrors}>
                  Save
                </Button>
              </Col>
            </Row>
          </CardHeader>
          <CardBody style={{ padding: 0 }}>
            <Row>
              <Col lg='12'>
                <ZuulEditor
                  height='36.4vh'
                  content={layoutData.content}
                  handleEditorChange={handleEditorChange}
                  minimapOn
                />
              </Col>
            </Row>
          </CardBody>
        </Card>
      )}
    </>
  );
};

const form = reduxForm({
  form: 'simpleLayoutEditor',
});

export default form(SimpleLayoutEditor);
