import React, { Component } from 'react';
import { change, Field, FieldArray } from 'redux-form';
import {
  getDispatch,
  getOfferActionPosts,
  getOfferActionLinkouts,
  updateOfferActionPosts,
  updateOfferActionLinkouts,
} from '../../../actions';
import { renderField, renderActionSelect, renderDropdownList, renderToggleSwitch } from '../OfferTitle/renderConsts';
import { Button, Row, Col, Label } from 'reactstrap';
import { connect } from 'react-redux';
import 'react-widgets/styles.css';
import './Waterfall.css';
import WaterfallEntry from './WaterfallEntry';
import { calculatePercentages } from './OfferActionsHelpers';
class Waterfall extends Component {
  state = {
    weights: [],
  };

  updatePercentages = (percentages) => {
    for (let key in percentages) {
      this.props.dispatch(change('offerActionDetail', key, percentages[key]));
    }
  };

  handleWeightChange = (event, weight, index) => {
    const weightParsed = parseInt(weight) || 1;
    const { weights } = this.state;
    weights[index] = weightParsed;
    const percentages = calculatePercentages(weights);
    this.updatePercentages(percentages);
  };

  handleWaterfallEntryRemoval = (index, fields) => {
    const { weights } = this.state;
    weights.splice(index, 1);
    const percentages = calculatePercentages(weights);
    this.updatePercentages(percentages);
    fields.remove(index);
  };

  renderWaterfallMeta = () => {
    const actionSubType = this.props.offerActionType === 'POST_WATERFALL' ? 'Post' : 'Linkout';
    return (
      <>
        <Row>
          <Col lg='12'>
            <div>
              <Field
                required
                type='text'
                component={renderField}
                id='title'
                name='title'
                placeholder={`${actionSubType} Waterfall Name`}
                label={`${actionSubType} Waterfall Name`}
              />
            </div>
            {this.props.offerActionType === 'LINKOUT_WATERFALL' && (
              <div style={{ color: 'grey', padding: 'inherit', marginTop: '10px' }}>
                <i className='icon-info' /> You must ensure that all users qualify for at least one linkout action, or
                they will be linked out to an error page
              </div>
            )}
            {this.props.offerActionType === 'POST_WATERFALL' && (
              <div
                style={{
                  display: 'flex',
                  //space-between
                  justifyContent: 'space-between',
                }}
              >
                <Label>Preprocess</Label>
                <Field
                  name='offerAction.offerActionPostWaterfall.isPreprocessed'
                  component={renderToggleSwitch}
                  label='Pre proccess'
                  defaultValue={false}
                />
              </div>
            )}
          </Col>
        </Row>
        <br />
        <Row>
          {!this.props.isEditMode && renderActionSelect(6)}
          <Col sm={6} lg={6}>
            <Label>Waterfall Logic</Label>
            <Field
              name='waterfallLogic'
              component={renderDropdownList}
              type='select'
              defaultValue='FLEX'
              data={['FLEX', 'STEP']}
            />
          </Col>
        </Row>
      </>
    );
  };

  addEmptyEntry(fields) {
    fields.push({
      flex: 1,
      id: null,
      offerActionPost: null,
      offerActionLinkout: null,
    });
  }

  renderWaterfall = ({ fields }) => {
    const actionSubType = this.props.offerActionType === 'POST_WATERFALL' ? 'Post' : 'Linkout';
    if (fields.length === 0) {
      this.addEmptyEntry(fields);
      this.handleWeightChange({}, 1, 0);
    }
    return (
      <Row>
        <Col sm={8} lg={8}>
          {fields.map((waterFallEntry, index) => {
            return (
              <WaterfallEntry
                name={waterFallEntry}
                waterFallEntry={waterFallEntry}
                key={index}
                fields={fields}
                index={index}
                handleWeightChange={this.handleWeightChange}
                handleWaterfallEntryRemoval={this.handleWaterfallEntryRemoval}
                offerActionType={this.props.offerActionType}
              />
            );
          })}
        </Col>

        <Col sm={2} lg={2}>
          {fields.length < 20 && (
            <Button
              color='success'
              id='addWaterFallEntry'
              onClick={() => {
                this.addEmptyEntry(fields);
                this.handleWeightChange({}, 1, fields.length);
              }}
            >
              Add {actionSubType.toLowerCase()}
            </Button>
          )}
        </Col>
      </Row>
    );
  };

  async componentDidMount() {
    await this.props.getDispatch();
    this.props.getOfferActionPosts();
    this.props.getOfferActionLinkouts();
    if (this.props.offerActionPostWaterfall || this.props.offerActionLinkoutWaterfall) {
      const actionSubTypeEntries = this.props.offerActionPostWaterfall
        ? this.props.offerActionPostWaterfall.offerActionPostWaterfallEntries
        : this.props.offerActionLinkoutWaterfall
        ? this.props.offerActionLinkoutWaterfall.offerActionLinkoutWaterfallEntries
        : [];
      // Because the percentages/weights aren't stored on the data model, we have to initialize the data within this component and in state
      const weights = actionSubTypeEntries.map((entry) => entry.flex);
      const percentages = calculatePercentages(weights);
      this.updatePercentages(percentages);
      this.setState({ weights });
    }
  }

  componentWillUnmount() {
    this.props.updateOfferActionPosts([]);
    this.props.updateOfferActionLinkouts([]);
  }

  render() {
    return (
      <div className='animated fadeIn'>
        <Row>
          <Col lg='4' className='waterfall-metadata'>
            {this.renderWaterfallMeta()}
          </Col>
          <Col lg='8' className='waterfallEntries'>
            {/* Fetching the dispatch function from redux is asynchronous and this component will render before it finishes fetch the function */}
            {this.props.dispatch && (
              <FieldArray
                name={`${
                  this.props.offerActionType === 'POST_WATERFALL'
                    ? 'offerActionPostWaterfallEntries'
                    : 'offerActionLinkoutWaterfallEntries'
                }`}
                component={this.renderWaterfall}
              />
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { offerAction } = state;
  const { dispatch } = state.criteria;
  return {
    dispatch,
    offerAction,
    offerActionPostWaterfall:
      offerAction && offerAction.offerAction ? offerAction.offerAction.offerActionPostWaterfall : null,
    offerActionLinkoutWaterfall:
      offerAction && offerAction.offerAction ? offerAction.offerAction.offerActionLinkoutWaterfall : null,
    offerActionPosts: offerAction.offerActionPosts,
    offerActionLinkouts: offerAction.offerActionLinkouts,
  };
}
export default connect(mapStateToProps, {
  getDispatch,
  getOfferActionPosts,
  getOfferActionLinkouts,
  updateOfferActionPosts,
  updateOfferActionLinkouts,
})(Waterfall);
