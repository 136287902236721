import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import GenericModal from '../../../components/GenericModal/GenericModal';
import {
  approveOffersSequenceRecommendations,
  rejectOffersSequenceRecommendations,
} from '../../../services/sequenceRecommendations/api';
import OfferReorderingAlertBanner from '../OfferReorderingAlertBanner';

const OfferReorderingDetailsModal = ({ isApprove, isModalOpen, setIsHidden, setIsModalOpen, toggleModal }) => {
  const [errors, setErrors] = useState({});
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [sequenceName, setSequenceName] = useState('');
  const history = useHistory();
  const { id } = useParams();

  const toggleAlert = (prevState) => setIsAlertOpen(!prevState);

  const onApproval = async (name) => {
    setIsHidden(true);
    setIsModalOpen(false);
    toggleAlert();
    try {
      await approveOffersSequenceRecommendations(id, name);
    } catch (error) {
      setErrors({ ...errors, onApproval: error });
      setIsModalOpen(false);
    }
  };

  const onReject = async () => {
    setIsHidden(true);
    setIsModalOpen(false);
    try {
      await rejectOffersSequenceRecommendations(id);
      history.goBack();
    } catch (error) {
      setErrors({ ...errors, onApproval: error.response });
      toggleAlert();
    }
  };

  const approvalHandler = async () => (isApprove ? await onApproval(sequenceName) : await onReject());

  const modalButton = {
    'aria-label': 'Confirm Button',
    color: 'primary',
    id: 'scorecard-confirm-btn',
    disabled: sequenceName.trim().length === 0 && isApprove,
    text: 'Confirm',
    onClick: approvalHandler,
    type: 'submit',
    value: 'confirm',
  };

  const displayModalBody = () =>
    isApprove ? (
      <label htmlFor='offerReorderName'>
        Sequence Name
        <input
          className='form-control'
          id='offerReorderName'
          name='offerReorderName'
          onChange={(e) => setSequenceName(e.target.value)}
          placeholder='My Sequence Name'
          type='text'
          value={sequenceName}
        />
      </label>
    ) : (
      <p>Are you sure you want to reject the recommended offer reording?</p>
    );

  const alertContent = errors?.onApproval
    ? {
        color: 'danger',
        headline: 'There was an error in the sequence recommendation process',
        subtext: errors.onApproval.toString() || '',
      }
    : {
        color: 'success',
        headline: 'Sequence Recommendation was successfully submitted',
        subtext: 'Creation process may take some time. Please refresh page after a few minutes.',
      };

  return (
    <>
      {isAlertOpen && <OfferReorderingAlertBanner details={alertContent} isOpen={isAlertOpen} toggle={toggleAlert} />}
      {isModalOpen && (
        <GenericModal
          button={modalButton}
          footer
          header='Offer Reordering'
          isOpen={isModalOpen}
          toggleModal={toggleModal}
        >
          {displayModalBody()}
        </GenericModal>
      )}
    </>
  );
};

export default OfferReorderingDetailsModal;
