import React, { useState } from 'react';
import './UserAttributeTable.css';
import { columnDefaults } from '../../utils/tableFunctions';
import { useUserAttributesTable } from './queries';
import SummaryCard from '../../components/SummaryCard/SummaryCard';
import DeleteModal from '../../components/DeleteModal/DeleteModal';
import ActiveUserAttributeModal from './Modal/ActiveUserAttributeModal';
import { DetailsModal } from './Modal/DetailsModal';

const isDeleted = (name) => {
  if(name?.includes("DELETED_")) {
    return true;
  }
  return false;
}

const columns = [
  {
    dataField: 'id',
    text: 'ID',
    headerStyle: {
      backgroundColor: '#2f353a',
    },
    ...columnDefaults,
  },
  {
    dataField: 'name',
    text: 'User Attribute',
    sort: true,
    headerStyle: {
      backgroundColor: '#2f353a',
    },
    ...columnDefaults,
  },
  {
    dataField: 'delete',
    text: 'Delete',
    isDummyField: true,
    formatter: (cell, row) => {
      if(!isDeleted(row?.name)) {
        return <DeleteModal modalType={' user attribute'} id={row.id} entity={'user-attribute'} />
      }
        return null;
    },
    headerStyle: {
      backgroundColor: '#2f353a',
      width: '9%',
    },
  },
];

const UserAttributeTable = () => {
  const { data: users, refetch: refreshUserAttributes, isLoading, isError, error } = useUserAttributesTable();
  const [detailsModal, setDetailsModal] = useState(false);
  const [row, setRow] = useState();

  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      if(!isDeleted(row?.name)) {
        setDetailsModal(true);
        setRow(row);
      }
    },
  };

  return (
    <>
      <DetailsModal open={detailsModal} toggle={() => setDetailsModal(!detailsModal)} row={row} />
      <SummaryCard
        entity='User Attributes'
        columns={columns}
        createComponent={<ActiveUserAttributeModal refreshTable={refreshUserAttributes} />}
        data={users || []}
        defaultSorted={[{ dataField: 'id', order: 'desc' }]}
        loading={isLoading}
        error={isError}
        rowEvents={rowEvents}
      />
    </>
  );
};

export default UserAttributeTable;
