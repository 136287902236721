import { useQuery } from '@tanstack/react-query';
import format from 'date-fns/format';
import _, { set } from 'lodash';
import React, { useState } from 'react';
import { selectFilter } from 'react-bootstrap-table2-filter';
import { Link } from 'react-router-dom';
import DropdownList from 'react-widgets/DropdownList';
import { Button, Col, Row } from 'reactstrap';
import ActiveStatusModal from '../../components/ActiveStatusModal/ActiveStatusModal';
import DeleteModal from '../../components/DeleteModal/DeleteModal';
import SummaryCard from '../../components/SummaryCard/SummaryCard';
import api from '../../services';
import { fetchAll } from '../../services/helpers';
import { columnDefaults } from '../../utils/tableFunctions';

let siteFilter;
const updateStatus = (row, activeStatus, endpoint) => {
  if (row.id) {
    let isActive = activeStatus;
    let saveData = {
      id: row.id,
      isActive,
      active: activeStatus,
    };
    api.put(`${endpoint}/active/${row.id}`, saveData).then((result) => {
      console.log(JSON.stringify(result));
    });
  }
};

const Sequences = () => {
  const [allSitesForFilter, setAllSitesForFilter] = useState([{ value: '', label: 'All Sites' }]);
  const [selectedRows, setSelectedRows] = useState([]);

  const handleOnSelect = (row, isSelect) => {
    isSelect ? setSelectedRows([...selectedRows, row.id]) : setSelectedRows(selectedRows.filter((id) => id !== row.id));
  };

  const handleOnSelectAll = (isSelect, rows) => {
    const ids = rows.map((r) => r.id);
    isSelect
      ? setSelectedRows([...selectedRows, ...ids])
      : setSelectedRows(selectedRows.filter((id) => !ids.includes(id)));
  };

  const { data, isLoading, error } = useQuery({
    queryKey: ['sequencesTable'],
    queryFn: () => fetchAll('sequences/table'),
    onSuccess: (data) => {
      const sitesForFilter = data
        ? _.uniqBy(
            data.map((obj) => {
              return { value: obj.site, label: obj.site };
            }),
            'value'
          ).sort((a, b) => (a.label > b.label ? 1 : -1))
        : [];
      setAllSitesForFilter([...allSitesForFilter, ...sitesForFilter]);
    },
  });

  function linkFormatter(cell, row) {
    return (
      <Link to={`/campaigns/sequences/${row.id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
        {cell}
      </Link>
    );
  }

  const columns = [
    {
      dataField: 'id',
      text: 'ID',
      formatter: linkFormatter,
      ...columnDefaults,
    },
    {
      dataField: 'name',
      text: 'Sequence Name',
      formatter: (cell, row) => {
        return (
          <Link to={`/campaigns/sequences/${row.id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
            <Row>
              <Col>{cell}</Col>
              <Col xs='auto' style={{ textAlign: 'right' }}>
                {!row.isPublished && (
                  <Button className='draftBtn' color='primary'>
                    Draft
                  </Button>
                )}
              </Col>
            </Row>
          </Link>
        );
      },
      filterValue: (cell, row) => cell,
      ...columnDefaults,
    },
    {
      dataField: 'isPublished',
      text: 'isPublished',
      hidden: true,
    },
    {
      dataField: 'site',
      text: 'Site Name',
      formatter: (cell, row) => {
        return (
          <Link to={`/campaigns/sequences/${row.id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
            {allSitesForFilter.filter((opt) => opt.value === cell)[0]?.label}
          </Link>
        );
      },
      filter: selectFilter({
        options: allSitesForFilter,
        style: {
          backgroundColor: 'pink',
          display: 'none',
        },
        getFilter: (filter) => {
          // siteFilter was assigned once the component has been mounted.
          siteFilter = filter;
        },
      }),
      ...columnDefaults,
    },
    {
      dataField: 'returnVisits',
      text: 'Min Previous Regs',
      formatter: linkFormatter,
      ...columnDefaults,
    },
    {
      dataField: 'flex',
      text: 'Flex',
      formatter: linkFormatter,
      ...columnDefaults,
    },
    {
      dataField: 'publishedDateBy',
      text: 'Last Published',
      formatter: linkFormatter,
      sortValue: (cell, row) => {
        return new Date(row.publishedDate);
      },
      ...columnDefaults,
    },
    {
      dataField: 'isActive',
      text: 'Status',
      formatter: (_cell, row) => {
        return (
          <Link to={`/campaigns/sequences/${row.id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
            <ActiveStatusModal
              content={row}
              isActive={row.isActive}
              modalType=' Sequence'
              getErrors={() => null || null}
              onClickSubmit={(activeStatus) => updateStatus(row, activeStatus, 'sequences')}
              isEditMode={true}
            />
          </Link>
        );
      },
      ...columnDefaults,
    },
    {
      dataField: 'delete',
      text: 'Delete',
      isDummyField: true,
      formatter: (cell, row) => {
        return <DeleteModal modalType={' Sequence'} id={row.id} entity={'sequences'} />;
      },
      headerStyle: {
        backgroundColor: '#2f353a',
        width: '5%',
      },
    },
  ];

  const selectRow = {
    mode: 'checkbox',
    clickToSelect: true,
    selected: selectedRows,
    onSelect: handleOnSelect,
    onSelectAll: handleOnSelectAll,
    style: { backgroundColor: '#e65958' },
  };

  return (
    <>
      <SummaryCard
        entity='Sequences'
        createComponent={
          <Link to='/campaigns/sequences/create' style={{ textDecoration: 'none', color: 'inherit' }}>
            <Button color='primary' className='float-right'>
              <i className='fa fa-plus' />
              &nbsp; Add Sequence
            </Button>
          </Link>
        }
        columns={columns}
        selectRow={selectRow}
        selectedRows={selectedRows}
        defaultSorted={[{ dataField: 'publishedDateBy', order: 'desc' }]}
        data={data?.map((seq) => ({
          ...seq,
          publishedDateBy: seq.publishedBy
            ? format(new Date(seq.publishedDate), 'Pp') + ' by ' + seq.publishedBy
            : format(new Date(seq.publishedDate), 'Pp'),
        }))}
        loading={isLoading}
        error={error}
      >
        <DropdownList
          dataKey='value'
          textField='label'
          defaultValue={allSitesForFilter[0].value}
          data={allSitesForFilter}
          onChange={(value) => siteFilter(value.value)}
        />
      </SummaryCard>
    </>
  );
};

export default Sequences;
