import { GET_ALL_CONTAINERS } from '../../actions/types';
import api from '../../../../services';
import { sortByName } from '../../../../utils/sorting';

export const getAllContainers = (containers) => {
  return {
    type: GET_ALL_CONTAINERS,
    payload: containers,
  };
};

export const fetchAllContainers = () => {
  return function (dispatch) {
    return getContainersApi().then(
      (normalizedContainers) => dispatch(getAllContainers(normalizedContainers)),
      (error) => console.log(error)
    );
  };
};

export const getContainersApi = (dispatch) => {
  return new Promise((resolve) => {
    api
      .get('containers/raw')
      .then((response) => {
        let sortedContainers = sortByName(response.data);
        resolve(sortedContainers);
      })
      .catch((error) => console.log(error));
  });
};

export const getContainersTableApi = (dispatch) => {
  return new Promise((resolve) => {
    api
      .get('containers/table')
      .then((response) => {
        let sortedContainers = sortByName(response.data);
        resolve(sortedContainers);
      })
      .catch((error) => console.log(error));
  });
};
