import { formValueSelector } from 'redux-form';
import { FETCH_ALL_ENTITIES, RESET_MODAL } from '../../../actions/types';
import {
  CREATE_SEQUENCE,
  CREATE_SEQUENCE_CHECKOUT,
  CREATE_SEQUENCE_VIEW,
  GET_SDTS,
  GET_SEQUENCE,
  GET_SEQUENCES,
  GET_SEQUENCES_TABLE,
  GET_SEQUENCE_CHECKOUT,
  GET_SEQUENCE_VIEW,
  PUBLISH_SEQUENCE,
  PUBLISH_SEQUENCE_BY_ID,
  SET_REACT_FLOW_INSTANCE,
  SET_SEQUENCE,
  UPDATE_ORPHANED_OFFERS,
  UPDATE_SELECTED_ELEMENT,
  UPDATE_SEQUENCE_CHECKOUT,
  UPDATE_SEQUENCE_ERROR,
  UPDATE_SEQUENCE_VIEW,
  UPDATE_SEQUENCE_WARNING,
} from './types';

const initialState = {
  selector: formValueSelector('sequenceDetails'),
  sequence: {},
  sequenceCheckout: {},
  sequencesPublished: {},
  sequenceView: {
    sequence: {},
    sequenceDefaultTemplateSettings: [],
    offersSequences: [],
  },
  sdts: [],
  offers: [],
  orphanedOffers: [],
  hasMoreThanOneOSHead: false,
  sequences: [],
  isSequenceSaved: false,
  saveError: false,
  saveErrorMessage: null,
  saveWarning: false,
  saveWarningMessage: null,
  reactFlowInstance: null,
  selectedElement: {},
  zuulUser: {
    zuulUserRole: {
      zuulPermissions: {},
    },
  },
};

const reducers = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ALL_ENTITIES:
      return {
        ...state,
        // filter return urls & landing pages
        offers: action.payload[0].filter(
          (offer) => offer.offerType.id !== 6 && offer.offerType.id !== 8 && offer.offerType.id !== 9
        ),
      };
    case GET_SEQUENCE:
      return {
        ...state,
        sequence: action.payload,
        isSequenceSaved: false,
        saveError: false,
        saveErrorStatus: null,
      };
    case GET_SEQUENCE_VIEW:
      return {
        ...state,
        sequenceView: action.payload,
        isSequenceSaved: false,
        saveError: false,
        saveErrorStatus: null,
      };
    case GET_SEQUENCE_CHECKOUT:
      return {
        ...state,
        sequenceCheckout: action.payload,
        isSequenceSaved: false,
        saveError: false,
        saveErrorStatus: null,
      };
    case GET_SDTS:
      return {
        ...state,
        sdts: action.payload,
      };
    case GET_SEQUENCES:
      return {
        ...state,
        sequences: action.payload,
      };
    case GET_SEQUENCES_TABLE:
      return {
        ...state,
        sequences: action.payload,
      };
    case UPDATE_SEQUENCE_VIEW:
      return {
        ...state,
        sequence: action.payload,
        saveError: false,
        saveErrorMessage: null,
        saveErrorStatus: null,
        isSequenceSaved: true,
      };
    case UPDATE_SEQUENCE_CHECKOUT:
      return {
        ...state,
        sequenceCheckout: action.payload,
        saveError: false,
        saveErrorStatus: null,
        isSequenceSaved: true,
      };
    case PUBLISH_SEQUENCE:
      return {
        ...state,
        sequencesPublished: action.payload,
        saveError: false,
        saveErrorStatus: null,
        isSequenceSaved: true,
      };
    case PUBLISH_SEQUENCE_BY_ID:
      return {
        ...state,
        sequencesPublished: action.payload,
        saveError: false,
        saveErrorStatus: null,
        saveWarning: false,
        isSequenceSaved: true,
      };
    case SET_SEQUENCE:
      return {
        ...state,
        sequence: action.payload,
      };
    case CREATE_SEQUENCE:
      return {
        ...state,
        sequence: action.payload,
        isSequenceSaved: true,
      };
    case UPDATE_SEQUENCE_ERROR:
      return {
        ...state,
        saveError: true,
        saveErrorMessage: action.payload.message,
        saveErrorStatus: action.payload.status,
      };
    case UPDATE_SEQUENCE_WARNING:
      return {
        ...state,
        saveWarning: true,
        saveWarningMessage: action.payload.message,
        saveWarningStatus: action.payload.status,
      };
    case CREATE_SEQUENCE_VIEW:
      return {
        ...state,
        sequence: action.payload,
        saveError: false,
        saveErrorMessage: null,
        saveErrorStatus: null,
      };
    case CREATE_SEQUENCE_CHECKOUT:
      return {
        ...state,
        sequenceCheckout: action.payload,
        saveError: false,
        saveErrorStatus: null,
      };
    case RESET_MODAL:
      return {
        ...state,
        saveError: false,
        saveErrorMessage: null,
        saveErrorStatus: null,
      };
    case SET_REACT_FLOW_INSTANCE:
      return {
        ...state,
        reactFlowInstance: action.payload,
      };
    case UPDATE_SELECTED_ELEMENT:
      return {
        ...state,
        selectedElement: action.payload,
      };
    case UPDATE_ORPHANED_OFFERS:
      const [orphanedOffers, hasMoreThanOneOSHead] = action.payload;
      return {
        ...state,
        orphanedOffers: orphanedOffers,
        hasMoreThanOneOSHead: hasMoreThanOneOSHead ? hasMoreThanOneOSHead : false,
      };
    default:
      return state;
  }
};

export default reducers;
