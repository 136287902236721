import { CreatableAriaOfferSelectComponent } from '@aria/aria-mapping-ui';
import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import DropdownList from 'react-widgets/DropdownList';
import Multiselect from 'react-widgets/Multiselect';
import NumberPicker from 'react-widgets/NumberPicker';
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
  Row,
} from 'reactstrap';
import {
  addOfferQuestionAnswer,
  addOfferTrigger,
  deleteOfferQuestionAnswers,
  getOfferActionsById,
  getOfferTriggersByOfferId,
  saveAsOfferName,
  saveOffer,
  setOfferActions,
  sortByName,
  updateAdvertiser,
  updateCriteriaType,
  updateOfferQuestionAnswers,
  updateOfferState,
  updateOfferTriggers,
  updateSelector,
} from '../../../actions';
import LocationsModal from '../../../components/LocationsModal/LocationsModal';
import api from '../../../services';
import { saveQuestionIfChanged } from '../../../services/offers';
import * as tagService from '../../../services/tags';
import {
  handleCriteriaSubmit
} from '../../../utils/CriteriaHelpers';
import { ARIA_API_BASE_URL, ARIA_API_KEY } from '../../../utils/ariaApiHelper';
import { DB_TRIGGER_TYPES, TRIGGER_TYPES } from '../../../utils/constants';
import OfferJavascript from '../OfferTitle/OfferJavascript/OfferJavascript';
import OfferQuestion from '../OfferTitle/OfferQuestion/OfferQuestion';
import '../OfferTitle/OfferTitle.css';
import './ARIAOfferOverrides.css';
import ActiveStatusBadge from './ActiveStatusBadge';
import ActiveStatusModal from './ActiveStatusModal';
import OfferBanner from './OfferBanner/OfferBanner';
import OfferForm from './OfferForm/OfferForm';
import OfferWall from './OfferWall/OfferWall';
import OfferiFrame from './OfferiFrame/OfferiFrame';
import {
  createOfferActionTriggers,
  deactivateAnswer,
  getExistingQuestionAnswers,
  partitionToBeCreatedAndDeactivated,
  partitionToReactivateOrCreateTriggersFor,
  prepareOfferToCopy,
  prepareQuestionAnswersForSubmission,
  updateOfferQuestion,
  updateOfferQuestionAndQuestionAnswersInContent,
} from './offerTitleHelper/offerTitleHelper';

class OfferTitle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.id,
      offerType: this.props.offerType,
      offerName: '',
      offerActions: this.props.offerActionsData,
      advertisers: [],
      advertiser: null,
      advertiserName: '',
      ariaAdvertiserId: null,
      tags: [],
      selectedTags: [],
      tagValue: null,
      tagName: '',
      wallOffers: [],
      loading: true,
      content: null,
      isEditMode: false,
      criteriaquestions: [],
      isOfferFetched: false,
      errors: {
        offerName: false,
      },
      actions: [],
      linkouts: this.props.linkouts,
      posts: this.props.posts,
      attributes: this.props.attributes,
      postWaterfalls: this.props.postWaterfalls,
      linkoutWaterfalls: this.props.linkoutWaterfalls,
      gtmEvents: this.props.gtmEvents,
      isActive: false,
      ariaOfferId: '',
      flex: this.props.flex,
      copyToVariation: false,
    };

    this.handleOfferTypeChange = this.handleOfferTypeChange.bind(this);
    this.handleAdvertiserChange = this.handleAdvertiserChange.bind(this);
    this.getOfferData = this.getOfferData.bind(this);
    this.saveOfferData = this.saveOfferData.bind(this);
    this.getAdvertisers = this.getAdvertisers.bind(this);
    this.getTags = this.getTags.bind(this);
    this.saveQuestion = this.saveQuestion.bind(this);
    this.updateOfferTriggers = this.updateOfferTriggers.bind(this);
    this.addOfferTriggers = this.addOfferTriggers.bind(this);
  }

  handleOfferTypeChange(event) {
    this.props.updateSelector(event.target.value);
    this.setState({
      offerType: event.target.value,
    });
  }

  setAriaOfferId = (value) => {
    this.setState({
      ariaOfferId: value,
    });
  };

  handleAdvertiserChange({ name, id }) {
    // find advertiser obj with selected id, to retrieve ariaAdvertiserId
    let curAdv = this.state.advertisers.find((ad) => ad.id === id);
    const advertiser = id
      ? {
          name,
          id,
        }
      : null;
    this.setState({
      advertiser,
      advertiserName: name,
      ariaAdvertiserId: curAdv ? curAdv.ariaAdvertiserId : null,
    });
    this.props.updateAdvertiser(advertiser);
  }

  toggleActiveStatus = (activeStatus) => {
    const id = this.state.content?.id;
    const isActive = activeStatus ?? this.state.content?.isActive;

    api
      .put(`offers/active/${id}`, {
        id,
        isActive,
        zuulUser: JSON.parse(localStorage.getItem('userData')),
      })
      .then(() => {
        this.setState({ isActive });
      });
  };

  deriveIdFromName(array, name) {
    const item = array.find((element) => element.title === name);
    return item ? item.id : undefined;
  }

  handleOfferWallAd = (offerAction) => (offerAction?.offerWallAd ? { id: offerAction.offerWallAd } : null);

  async saveQuestion({ questionText, offer }) {
    const oldQuestionText = (this.state.offerContent && this.state.offerContent.question_text) || null;
    const saveQuestionPayload = { newQuestionText: questionText, oldQuestionText, offer };
    const resp = await saveQuestionIfChanged(saveQuestionPayload);
    console.log('After saveQuestionIfChanged', resp);
    this.setState({ offerContent: JSON.parse(offer.content) });

    return resp;
  }

  /**
   * Adds information to user input answers so they can be submitted to the api.
   * Decides which answers from user input and from the db should be created or deactivated and saves.
   * Activates any answers that existed before and were not just deactivated.
   * Adds new info to content and saves offer.
   * Decides if triggers need to be created or update and saves.
   */
  async handleOfferTriggersWithQuestionAnswers(
    offer,
    unNormalizedQuestionAnswers,
    questionText,
    offerActionWithIds,
    offerQuestion,
    question
  ) {
    console.log('UNORMALIZED: ', unNormalizedQuestionAnswers);
    console.log('CREATING QUESTION ANSWERS');
    const getOrDefaultOfferTriggers = async (offerId) => {
      const offerTriggers = await this.props.getOfferTriggersByOfferId(offerId);
      return Array.isArray(offerTriggers) ? offerTriggers : [];
    };

    const offerId = offer.id;
    const offerQuestionAnswers = prepareQuestionAnswersForSubmission({
      questionAnswers: unNormalizedQuestionAnswers,
      offerQuestion,
    });
    const offerTriggers = await getOrDefaultOfferTriggers(offerId);
    const existingOfferQuestionAnswers = getExistingQuestionAnswers(offerTriggers);
    console.log('offerQuestionAnswers from user input: ', offerQuestionAnswers);
    console.log('existingOfferQuestionAnswers from db: ', existingOfferQuestionAnswers);

    const { toBeCreated, toBeDeactivated } = partitionToBeCreatedAndDeactivated({
      newOfferQuestionAnswers: offerQuestionAnswers,
      oldOfferQuestionAnswers: existingOfferQuestionAnswers,
    });
    const inActiveAnswers = toBeDeactivated.map(deactivateAnswer);
    console.log('answers to deactivate: ', inActiveAnswers);

    const deactivatedQuestionAnswers = await this.props.updateOfferQuestionAnswers(inActiveAnswers);

    const normalizedAnswers = toBeCreated.map(updateOfferQuestion({ offer, question }));
    console.log('Adding offerQuestion answers', normalizedAnswers);
    const addedQuestionAnswers = await this.props.addOfferQuestionAnswer(normalizedAnswers);

    console.log('Updating existing existingOfferQuestionAnswers for updates to countAsAccepted');
    const toUpdateCountAsAccepted = [];
    existingOfferQuestionAnswers.forEach((questionAnswer) => {
      // If questionAnswer existed in the existingOfferQuestionAnswers array and its countAsAccepted was updated, we should update/swap the existing OQA to reflect the new value in the POST for triggers and additional logging
      const requiresCountAsAcceptedUpdate = _.find(offerQuestionAnswers, (updatedQuestionAnswer) => {
        return (
          updatedQuestionAnswer.answer === questionAnswer.answer &&
          updatedQuestionAnswer.countAsAccepted !== questionAnswer.countAsAccepted
        );
      });

      if (requiresCountAsAcceptedUpdate) {
        const coaUpdated = {
          id: questionAnswer.id,
          answer: questionAnswer.answer,
          current: questionAnswer.current,
          orderNumber: questionAnswer.orderNumber,
          offerQuestion: questionAnswer.offerQuestion,
          countAsAccepted: !questionAnswer.countAsAccepted,
        };

        console.log('countAsAccepted: Flipping question answer value', coaUpdated);
        toUpdateCountAsAccepted.push(coaUpdated);
        // Flips existing OQA to reflect value in the UI, prior to sending data through POST offerTriggers
        questionAnswer.countAsAccepted = !questionAnswer.countAsAccepted;
      }
    });

    console.log('NEWLY DEACTIVATED QUESTION ANSWERS ', deactivatedQuestionAnswers);
    console.log('NEWLY ADDED QUESTION ANSWERS ', addedQuestionAnswers);
    console.log('QUESTION ANSWERS BEFORE ADDED AND DEACTIVATED', existingOfferQuestionAnswers);
    console.log('QUESTION ANSWERS EXISTING COUNT AS ACCEPTED UPDATE', toUpdateCountAsAccepted);

    const { toReactivate, toCreateTriggersFor } = partitionToReactivateOrCreateTriggersFor({
      deactivatedQuestionAnswers,
      addedQuestionAnswers,
      existingOfferQuestionAnswers,
    });

    console.log('toReactivate and toCreateTriggersFor: ', {
      toReactivate,
      toCreateTriggersFor,
    });

    const countAsAcceptedAnswers = await this.props.updateOfferQuestionAnswers(toUpdateCountAsAccepted);
    console.log('TOGGLED COUNT AS ACCEPTED OFFER QUESTION ANSWER', countAsAcceptedAnswers);

    const reactivatedAnswers = await this.props.updateOfferQuestionAnswers(toReactivate);
    console.log('REACTIVATED OFFER QUESTION ANSWER', reactivatedAnswers);
    console.log('Updating offer content...');
    const allAnswersToCreateTriggersFor = [...toCreateTriggersFor, ...reactivatedAnswers];
    const oldContent = JSON.parse(offer.content);
    const newContent = updateOfferQuestionAndQuestionAnswersInContent({
      questionAnswers: allAnswersToCreateTriggersFor,
      content: oldContent,
    });
    const offerWithNewContent = { ...offer, content: JSON.stringify(newContent) };
    const savedOfferResult = await api.put(`offers/${offer.id}`, offerWithNewContent);
    const savedOffer = savedOfferResult.data;
    console.log('Saved new offer content');
    this.setState({ offerContent: JSON.parse(savedOffer.content) });

    console.log('QUESTION ANSWERS TO BE MADE INTO TRIGGERS ', allAnswersToCreateTriggersFor);

    console.log('QUESTION ANSWERS TO BE MADE INTO TRIGGERS ', allAnswersToCreateTriggersFor);

    let newOfferTriggers = allAnswersToCreateTriggersFor.map((questionAnswer) => {
      const unNormalized = _.find(unNormalizedQuestionAnswers, (unNormalizedQuestionAnswer) => {
        return unNormalizedQuestionAnswer.text === questionAnswer.answer;
      });

      return {
        offer: {
          id: offerId,
        },
        offerActionTriggers: createOfferActionTriggers({
          questionAnswer,
          offerActions: offerActionWithIds,
        }),
        offerQuestionAnswer: {
          id: questionAnswer.id,
          countAsAccepted: questionAnswer.countAsAccepted,
        },
        showOfferAndOrLogic: unNormalized.hasOwnProperty('actionCriteriaAndOr')
          ? unNormalized.actionCriteriaAndOr
          : unNormalized.hasOwnProperty('shouldShowCriteria')
          ? unNormalized.shouldShowCriteria
          : null,
        triggerType: {
          id: TRIGGER_TYPES.ON_ANSWER.id,
        },
      };
    });

    console.log('NEW OFFER TRIGGERS ', newOfferTriggers);

    offerActionWithIds.forEach((offerAction) => {
      if (!offerAction.answer) {
        newOfferTriggers.push({
          offer: {
            id: offerId,
          },
          offerActionTriggers: [
            {
              offerAction: {
                id: parseInt(offerAction.id),
              },

              offerActionType: offerAction.actionType,
              showOfferByOfferActionCriteria: offerAction.showOfferByOfferActionCriteria,
            },
          ],
          triggerType: {
            id: TRIGGER_TYPES[offerAction.triggerType]
              ? TRIGGER_TYPES[offerAction.triggerType].id
              : DB_TRIGGER_TYPES[offerAction.triggerType].id,
          },
          paramName: offerAction.paramName ? offerAction.paramName : null,
          paramValue: offerAction.paramValue ? offerAction.paramValue : null,
        });
      }
    });
    newOfferTriggers = _.flatten(newOfferTriggers).filter((offerTrigger) => offerTrigger !== false);

    console.log('OFFER TRIGGERS', JSON.stringify(newOfferTriggers));
    if (this.state.isEditMode) {
      this.props.updateOfferTriggers(newOfferTriggers, offerTriggers).then((offerTriggers) => {
        console.log('UPDATED OFFER TRIGGERS', offerTriggers);
        this.props.saveOffer(true);
        return offerTriggers;
      });
    } else {
      this.props.addOfferTrigger(newOfferTriggers).then((offerTriggers) => {
        console.log('ADDED OFFER TRIGGERS', offerTriggers);
        this.props.saveOffer(true);
        this.props.history.push('/campaigns/offertable');
        return offerTriggers;
      });
    }
  }

  async updateOfferTriggers({ offerId, offerActionWithIds }) {
    let offerTriggers = [];
    offerActionWithIds.forEach((offerAction) => {
      if (!offerAction.answer) {
        const getTriggerTypeId = (offerAction) => {
          return TRIGGER_TYPES[offerAction.triggerType]
            ? TRIGGER_TYPES[offerAction.triggerType].id
            : DB_TRIGGER_TYPES[offerAction.triggerType].id;
        };
        offerTriggers.push({
          offer: { id: offerId },
          offerActionTriggers: [
            {
              offerAction: { id: parseInt(offerAction.id) },
              offerActionType: offerAction.actionType,
              showOfferByOfferActionCriteria: offerAction.showOfferByOfferActionCriteria,
            },
          ],
          offerWallAd: this.handleOfferWallAd(offerAction),
          triggerType: { id: getTriggerTypeId(offerAction) },
          paramName: offerAction.paramName ? offerAction.paramName : null,
          paramValue: offerAction.paramValue ? offerAction.paramValue : null,
        });
      }
    });

    console.log('OFFER TRIGGERS', offerTriggers);
    console.log('OFFER TRIGGERS', JSON.stringify(offerTriggers));
    const offerTriggersWithoutQuestionAnswers = this.props.offerTriggers.filter((offerTrigger) => {
      return offerTrigger.offerQuestionAnswer === null || !offerTrigger.offerQuestionAnswer;
    });
    const updatedOfferTriggers = await this.props.updateOfferTriggers(
      offerTriggers,
      offerTriggersWithoutQuestionAnswers
    );
    console.log('UPDATED OFFER TRIGGERS', updatedOfferTriggers);
    this.props.saveOffer(true);
    return updatedOfferTriggers;
  }

  async addOfferTriggers({ offerId, offerActionWithIds }) {
    let offerTriggers = [];
    offerActionWithIds.forEach((offerAction) => {
      if (!offerAction.answer) {
        offerTriggers.push({
          offer: {
            id: offerId,
          },
          offerActionTriggers: [
            {
              offerAction: {
                id: offerAction.id,
              },
              offerActionType: offerAction.actionType,
            },
          ],
          offerWallAd: this.handleOfferWallAd(offerAction),
          triggerType: {
            id: TRIGGER_TYPES[offerAction.triggerType]
              ? TRIGGER_TYPES[offerAction.triggerType].id
              : DB_TRIGGER_TYPES[offerAction.triggerType].id,
          },
          paramName: offerAction.paramName ? offerAction.paramName : null,
          paramValue: offerAction.paramValue ? offerAction.paramValue : null,
        });
      }
    });

    offerTriggers = _.flatten(offerTriggers).filter((offerTrigger) => offerTrigger !== false);

    console.log('OFFER TRIGGERS', offerTriggers);
    const savedOfferTriggers = await this.props.addOfferTrigger(offerTriggers);
    console.log('SAVED OFFER TRIGGERS', savedOfferTriggers);
    this.props.saveOffer(true);
    return savedOfferTriggers;
  }

  handleAdditionalAnswers = (additionalAnswerActions, refetchedActions) => {
    return additionalAnswerActions.map((answerAction) => {
      console.log(answerAction);
      let offerAction =
        answerAction.question_actions === 'POST' ||
        answerAction.question_actions === 'LINKOUT' ||
        answerAction.question_actions === 'LINKOUT_WATERFALL'
          ? _.find(refetchedActions, (offerAction) => offerAction.offerActionId === answerAction.action_id) || {}
          : {};
      console.log('======OFFER ACTION ======', offerAction);

      let postMethod =
        offerAction && offerAction !== null && offerAction.postMethod
          ? offerAction.postMethod
          : answerAction.question_actions === 'LINKOUT'
          ? 'LINKOUT'
          : answerAction.question_actions === 'LINKOUT_WATERFALL'
          ? 'LINKOUT_WATERFALL'
          : null;

      const {
        linkout_has_revenue = null,
        linkout_revenue = null,
        associated_user_attributes = null,
        url = null,
        body = null,
        handlerId,
        killswitch_handler_id = null,
        custom_http_status = null,
        killswitch_http_status = null,
        header_1 = null,
        header_2 = null,
        header_3 = null,
        http_auth_username = null,
        http_auth_password = null,
        advertiser = null,
        tag = null,
        required_user_data = null,
        has_revenue = null,
        brite_verify_required = null,
        revenue = null,
        revenue_on_gross_or_success = null,
      } = offerAction;
      return {
        isNONE: !postMethod || postMethod === null || postMethod === 'NONE',
        isGET: postMethod && postMethod === 'GET' ? true : false,
        isPOST: postMethod && (postMethod === 'POST' || postMethod === 'JSON' || postMethod === 'XML') ? true : false,
        isLINKOUT: postMethod && (postMethod === 'LINKOUT' || postMethod === 'LINKOUT_WATERFALL') ? true : false,
        linkout_has_revenue,
        linkout_revenue,
        associated_user_attributes,
        url,
        body,
        handler_id: handlerId,
        killswitch_handler_id,
        custom_http_status,
        killswitch_http_status,
        header_1,
        header_2,
        header_3,
        http_auth_username,
        http_auth_password,
        advertiser_id: advertiser && advertiser !== null ? advertiser.id : null,
        tag_id: tag && tag !== null ? tag.id : null,
        post_method: postMethod,
        required_user_data,
        post_success_response: null,
        has_revenue,
        brite_verify_required,
        revenue,
        revenue_on_gross_or_success,
      };
    });
  };

  handleContentQuestionAnswers = (questionAnswers, refetchedActions) => {
    const questionAnswersClone = _.cloneDeep(questionAnswers);
    return questionAnswersClone.map((questionAnswer) => {
      if (
        questionAnswer.question_actions !== 'LINKOUT' &&
        questionAnswer.question_actions !== 'LINKOUT_WATERFALL' &&
        questionAnswer.additionalAnswerAction &&
        questionAnswer.additionalAnswerAction.length > 0
      ) {
        let linkoutIndex;
        let linkoutAction;
        for (let i = 0; i < questionAnswer.additionalAnswerAction.length; i++) {
          if (
            questionAnswer.additionalAnswerAction[i].question_actions === 'LINKOUT' ||
            questionAnswer.additionalAnswerAction[i].question_actions === 'LINKOUT_WATERFALL'
          ) {
            linkoutIndex = i;
            linkoutAction = questionAnswer.additionalAnswerAction[i];
            break;
          }
        }
        if (linkoutAction) {
          console.log('SWAPPING');
          let temp = _.cloneDeep(questionAnswer);
          linkoutAction.additionalAnswerAction = temp.additionalAnswerAction;
          linkoutAction.additionalAnswerAction[linkoutIndex] = temp;
          linkoutAction.text = temp.text;
          linkoutAction.orderNumber = temp.orderNumber;
          questionAnswer = linkoutAction;
          delete linkoutAction.additionalAnswerAction[linkoutIndex].additionalAnswerAction;
          delete linkoutAction.additionalAnswerAction[linkoutIndex].orderNumber;
          delete linkoutAction.additionalAnswerAction[linkoutIndex].text;
        }
      }

      let offerAction =
        questionAnswer.question_actions === 'POST' ||
        questionAnswer.question_actions === 'LINKOUT' ||
        questionAnswer.question_actions === 'LINKOUT_WATERFALL'
          ? _.find(refetchedActions, (offerAction) => offerAction.offerActionId === questionAnswer.action_id) || {}
          : {};
      console.log('======OFFER ACTION ======', offerAction);

      let postMethod =
        offerAction && offerAction !== null && offerAction.postMethod
          ? offerAction.postMethod
          : questionAnswer.question_actions === 'LINKOUT'
          ? 'LINKOUT'
          : questionAnswer.question_actions === 'LINKOUT_WATERFALL'
          ? 'LINKOUT_WATERFALL'
          : null;

      const {
        linkout_has_revenue = null,
        linkout_revenue = null,
        associated_user_attributes = null,
        url = null,
        body = null,
        handlerId,
        killswitch_handler_id = null,
        custom_http_status = null,
        killswitch_http_status = null,
        header_1 = null,
        header_2 = null,
        header_3 = null,
        http_auth_username = null,
        http_auth_password = null,
        advertiser = null,
        required_user_data = null,
        has_revenue = null,
        brite_verify_required = null,
        revenue = null,
        revenue_on_gross_or_success = null,
      } = offerAction;

      return {
        text: questionAnswer.text,
        url,
        body,
        handler_id: handlerId,
        killswitch_handler_id,
        custom_http_status,
        killswitch_http_status,
        header_1,
        header_2,
        header_3,
        http_auth_username,
        http_auth_password,
        advertiser_id: advertiser && advertiser !== null ? offerAction.advertiser.id : null,
        post_method: postMethod,
        required_user_data,
        post_success_response: null,
        has_revenue,
        brite_verify_required,
        revenue,
        revenue_on_gross_or_success,
        isNONE: !postMethod || postMethod === null || postMethod === 'NONE',
        isGET: postMethod && postMethod === 'GET' ? true : false,
        isPOST: postMethod && (postMethod === 'POST' || postMethod === 'JSON' || postMethod === 'XML') ? true : false,
        isLINKOUT: postMethod && (postMethod === 'LINKOUT' || postMethod === 'LINKOUT_WATERFALL') ? true : false,
        additional_gets_posts:
          questionAnswer.additionalAnswerAction &&
          this.handleAdditionalAnswers(questionAnswer.additionalAnswerAction, refetchedActions),
        linkout_has_revenue: linkout_has_revenue,
        linkout_revenue: linkout_revenue,
        associated_user_attributes: associated_user_attributes,
      };
    });
  };

  updateOfferQuestion = (question, offer, offerContent) => {
    const offerQuestionId = offerContent ? offerContent.offer_question_id : null;
    const offerQuestion = {
      offer,
      question,
    };

    if (offerQuestionId && offerQuestionId !== null) {
      offerQuestion.id = offerQuestionId;
      try {
        return api.put('offerquestions', offerQuestion);
      } catch (error) {
        return console.log(error);
      }
    } else {
      try {
        return api.post('offerquestions', offerQuestion);
      } catch (error) {
        return console.log(error);
      }
    }
  };

  updateQuestion = (questionText, offerContent) => {
    console.log(questionText);
    const questionId = offerContent ? offerContent.question_id : null;
    const question = {
      createdAt: new Date(),
      question: questionText,
    };

    if (questionId && questionId !== null) {
      question.id = questionId;
      try {
        return api.put('questions', question);
      } catch (error) {
        return console.log(error);
      }
    } else {
      try {
        return api.post('questions', question);
      } catch (error) {
        return console.log(error);
      }
    }
  };

  handleQuestionText = async (questionText, offer) => {
    const offerContent = JSON.parse(offer.content);
    // Just need to check if the old question_text is not equal to the new question_text
    const preExistingContent = this.state.offerContent;
    const question_text = preExistingContent ? preExistingContent.question_text : null;
    const hasExistingQuestionId = offerContent && offerContent.offer_question_id != null;

    // Need to update question text
    if ((questionText && questionText !== question_text) || !hasExistingQuestionId) {
      const updatedQuestionResult = await this.updateQuestion(questionText, offerContent);
      const updatedQuestion = updatedQuestionResult.data;
      const updatedOfferQuestionResult = await this.updateOfferQuestion(updatedQuestion, offer, offerContent);
      const updatedOfferQuestion = updatedOfferQuestionResult.data;
      return [updatedOfferQuestion, updatedQuestion];
    } else {
      return [offerContent.offer_question_id, offerContent.question_id];
    }
  };

  saveOfferData = async (values) => {
    let { offerActions = [], unNormalizedQuestionAnswers = [], questionText, formType } = values;

    const offerActionWithIds = offerActions.map((action) => {
      // If offer is saved to quickly, the actions won't load in time, will probably need to cached offerActions
      const actionName = typeof action.name === 'object' && action.name !== null ? action.name.name : action.name;
      let offerActionId =
        this.state.offerActions.length > 0 ? this.deriveIdFromName(this.state.offerActions, actionName) : null;
      return {
        id: parseInt(offerActionId) || null,
        ...action,
        name: actionName,
      };
    });

    const staleActionIds = offerActionWithIds.filter((action) => action.id !== null).map((action) => action.id);
    console.log('Action Ids to be refetched!', staleActionIds);
    const refetchedActions = await this.props.getOfferActionsById(staleActionIds);
    let normalizedActions = [];
    if (refetchedActions && refetchedActions !== null) {
      normalizedActions = this.handleRefetchedOfferActions(refetchedActions);
    }

    console.log('Normalized ====> : ', normalizedActions);
    const questionAnswers =
      (this.state.offerType === 'Form' && formType !== 'Question') ||
      !values.questionAnswers ||
      values.questionAnswers === null
        ? []
        : this.handleContentQuestionAnswers(values.questionAnswers, normalizedActions);

    const content = JSON.parse(values.content);
    content.question_answers = questionAnswers;
    content.question_type = questionAnswers.length === 0 ? null : content.question_type;
    console.log('Content ', content);
    values.content = JSON.stringify(content);
    const { newOfferName } = this.props;
    let oType;
    switch (this.state.offerType) {
      case 'Banner':
        oType = 1;
        break;
      case 'Iframe':
        oType = 2;
        break;
      case 'Javascript':
        oType = 3;
        break;
      case 'Question':
        oType = 4;
        break;
      case 'Form':
        oType = 5;
        break;
      case 'Offer Wall':
        oType = 7;
        break;
      case 'Dynamicbanner':
        oType = 8;
        break;
      default:
        oType = '4';
    }

    const offerCriteria = handleCriteriaSubmit(values, this.props.handlers);

    // If they select to resize, then doNotResize is false
    const doNotResize = !values.shouldResizeImage;

    // Need to handle case where image1 is in an array, or not
    let image = null;
    if (Array.isArray(values.image1) && values.image1.length > 0) {
      image = values.image1[0];
      image = Object.assign(image, { doNotResize });
    } else if (values.image1 && values.image1 !== null && !Array.isArray(values.image1)) {
      image = Object.assign({}, values.image1, { doNotResize });
    }

    const zuulUser = JSON.parse(localStorage.getItem('userData'));
    const formatData = Object.assign({}, values, {
      zuulUser,
      offerType: {
        id: oType,
      },
      isActive: this.state.isActive,
      status: 1,
      name: this.state.offerName,
      tags:
        this.state.selectedTags != null && this.state.selectedTags.length > 0
          ? JSON.stringify(this.state.selectedTags)
          : null,
      triggersRegistration: !values.triggersRegistration || values.triggersRegistration === null ? false : true,
      image1: image,
      ariaOfferId: this.state.ariaOfferId,
      ...offerCriteria
    });
    if (this.state.advertiser) {
      formatData.advertiser = this.state.advertiser;
    }

    const isEditMode = this.state.isEditMode === true;
    const isNotCopyMode = !newOfferName;

    if (this.state.copyToVariation) {
      console.log('COPY TO VARIATION');
      const saveData = prepareOfferToCopy({ formData: formatData, newOfferName });
      saveData.isPrimaryVariant = false;
      saveData.isActive = false;
      console.log('CREATE Variation: ' + saveData);
      api.post('offers', saveData).then(async (result) => {
        const offer = result.data;
        const offerId = offer.id;
        this.props.saveAsOfferName('');

        const { offerQuestion, question } = await this.saveQuestion({ questionText, offer: offer });

        /*  Once a user has submitted an offer, we'll have access to that offer ID. With the ID and questions, we'll make a POST to questionAnswers, creating simultaneously a question and its associated answer. Once that POST is finished, we'll have access to the newly created questionAnswer, which we'll need to attach to each offerTrigger. Finally, we can create all the offerTriggers with their appropriate offerActionId. On the backend, the POST to offerTriggers handles offerTrigger creation and offerActionTrigger creation                                                                                                                                                                                                                                                                                                                                                                                                                                        */
        if (unNormalizedQuestionAnswers.length > 0) {
          await this.handleOfferTriggersWithQuestionAnswers(
            offer,
            unNormalizedQuestionAnswers,
            questionText,
            offerActionWithIds,
            offerQuestion,
            question
          );
        } else {
          await this.addOfferTriggers({ offerId, offerActionWithIds });
          if (isNotCopyMode) {
            this.props.history.push('/campaigns/offertable');
          }
        }

        if (this.state.copyToVariation) {
          api
            .post(
              `offervariations/create-for-two-offers?primaryOfferId=${this.props.match.params.offerId}&secondaryOfferId=${offerId}`
            )
            .then(() => {
              this.setState({ copyToVariation: false });
              return this.props.refetchOfferVariations();
            });
        }
      });
    } else if (isEditMode && isNotCopyMode) {
      const saveData = Object.assign({}, formatData, {
        id: this.state.id,
        isPrimaryVariant: this.props?.currentOffer?.isPrimaryVariant,
      });

      console.log('EDIT: ', saveData);

      const savedOfferResult = await api.put(`offers/${this.state.id}`, saveData);
      const savedOffer = savedOfferResult.data;
      if (this.state?.flex) {
        await api.put(`offervariations/save-flex/${this.state.id}?flex=${this.state.flex}`);
      }

      try {
        const { offerQuestion, question } = await this.saveQuestion({ questionText, offer: savedOffer });
        const offerTriggersToDelete = this.props.offerTriggers.map((offerTrigger) => offerTrigger.id);
        console.log('offerTriggersToDelete : ', offerTriggersToDelete);
        const existingOfferQuestionAnswers = this.props.offerTriggers.filter((offerTrigger) => {
          return offerTrigger.offerQuestionAnswer;
        });

        const isQuestionOrFormOffer = unNormalizedQuestionAnswers.length > 0 || existingOfferQuestionAnswers.length > 0;

        if (isQuestionOrFormOffer) {
          await this.handleOfferTriggersWithQuestionAnswers(
            savedOffer,
            unNormalizedQuestionAnswers,
            questionText,
            offerActionWithIds,
            offerQuestion,
            question
          );
        } else {
          await this.updateOfferTriggers({ offerId: savedOffer.id, offerActionWithIds });
        }
      } catch (e) {
        // Jim 09/14/20 TODO: Propagate this error to the SaveOfferModal and let the user close the modal.
        console.error(e);
      }
      this.props.invalidateFlexAndOffer();
    } else {
      const saveData = prepareOfferToCopy({ formData: formatData, newOfferName });
      console.log('CREATE: ' + JSON.stringify(saveData));
      api.post('offers', saveData).then(async (result) => {
        const offer = result.data;
        const offerId = offer.id;
        this.props.saveAsOfferName('');

        const { offerQuestion, question } = await this.saveQuestion({ questionText, offer: offer });

        /*  Once a user has submitted an offer, we'll have access to that offer ID. With the ID and questions, we'll make a POST to questionAnswers, creating simultaneously a question and its associated answer. Once that POST is finished, we'll have access to the newly created questionAnswer, which we'll need to attach to each offerTrigger. Finally, we can create all the offerTriggers with their appropriate offerActionId. On the backend, the POST to offerTriggers handles offerTrigger creation and offerActionTrigger creation                                                                                                                                                                                                                                                                                                                                                                                                                                        */
        if (unNormalizedQuestionAnswers.length > 0) {
          await this.handleOfferTriggersWithQuestionAnswers(
            offer,
            unNormalizedQuestionAnswers,
            questionText,
            offerActionWithIds,
            offerQuestion,
            question
          );
        } else {
          await this.addOfferTriggers({ offerId, offerActionWithIds });
          if (isNotCopyMode) {
            this.props.history.push('/campaigns/offertable');
          }
        }
      });
    }
  };

  getAdvertisers() {
    return new Promise((resolve) => {
      api
        .get(`advertisers`)
        .then((response) => {
          const listAdvertisers = response.data.map((a) => {
            return {
              id: a.id,
              name: a.name,
              ariaAdvertiserId: a.ariaAdvertiserId,
            };
          });
          const newState = Object.assign({}, this.state, {
            advertisers: listAdvertisers,
          });

          this.setState(newState);
          resolve(newState);
        })
        .catch((error) => console.log(error));
    });
  }

  async getTags() {
    try {
      const tags = await tagService.getTags();
      const newState = { ...this.state, tags };
      this.setState(newState);
      return newState;
    } catch (e) {
      console.error(e);
    }
  }

  handleRefetchedOfferActions = (offerActions) => {
    const offerActionPosts = [];
    const offerActionLinkouts = [];
    let offerActionAttributes = [];
    const offerActionPostWaterfalls = [];
    const offerActionLinkoutWaterfalls = [];
    const offerActionGtmEvents = [];
    offerActions.forEach((offerAction) => {
      const {
        offerActionPost,
        offerActionLinkout,
        offerActionUserAttributes,
        offerActionPostWaterfall,
        offerActionLinkoutWaterfall,
        offerActionGtmEvent,
        offerActionType,
      } = offerAction;
      if (offerActionPost) {
        offerActionPosts.push({
          active: offerAction.active,
          id: offerActionPost.id,
          offerActionId: offerAction.id,
          name: offerAction.title,
          advertiser: offerActionPost.advertiser,
          url: offerActionPost.url,
          postMethod: offerActionPost.postMethod,
          http_auth_username: offerActionPost.answerHttpUsername,
          http_auth_password: offerActionPost.answerHttpPassword,
          body: offerActionPost.body,
          has_revenue: offerActionPost.revenue || offerActionPost.revenueAmount ? true : false,
          header_1: offerActionPost.httpHeader1,
          header_2: offerActionPost.httpHeader2,
          header_3: offerActionPost.httpHeader3,
          killswitch_handler_id:
            typeof offerActionPost.httpKillSwitchHandler === 'object' && offerActionPost.httpKillSwitchHandler !== null
              ? offerActionPost.httpKillSwitchHandler.id
              : null,
          killswitch_http_status: offerActionPost.otherKillSwitchStatus,
          handlerId:
            typeof offerActionPost.httpSuccessHandler === 'object' && offerActionPost.httpSuccessHandler !== null
              ? offerActionPost.httpSuccessHandler.id
              : null,
          revenue_on_gross_or_success: offerActionPost.revenueOn,
          revenue: offerActionPost.revenueAmount,
          custom_http_status: offerActionPost.otherSuccessfulHttpStatus,
        });
      } else if (offerActionLinkout) {
        offerActionLinkouts.push({
          active: offerAction.active,
          id: offerActionLinkout.id,
          offerActionId: offerAction.id,
          name: offerAction.title,
          advertiser: offerActionLinkout.advertiser,
          url: offerActionLinkout.url,
          linkout_has_revenue: offerActionLinkout.hasRevenue,
          linkout_revenue: offerActionLinkout.revenue,
          killswitch_handler_id:
            typeof offerActionLinkout.httpKillSwitchHandler === 'object' &&
            offerActionLinkout.httpKillSwitchHandler !== null
              ? offerActionLinkout.httpKillSwitchHandler.id
              : null,
          killswitch_http_status: offerActionLinkout.httpKillSwitchHandler,
          handlerId:
            typeof offerActionLinkout.httpSuccessHandler === 'object' && offerActionLinkout.httpSuccessHandler !== null
              ? offerActionLinkout.httpSuccessHandler.id
              : null,
        });
      } else if (offerActionUserAttributes && offerActionUserAttributes.length > 0) {
        offerActionAttributes.push({
          active: offerAction.active,
          id: offerActionUserAttributes[0].id,
          offerActionId: offerAction.id,
          name: offerAction.title,
        });
      } else if (offerActionPostWaterfall) {
        offerActionPostWaterfalls.push({
          active: offerAction.active,
          id: offerActionPostWaterfall.id,
          offerActionId: offerAction.id,
          name: offerAction.title,
        });
      } else if (offerActionLinkoutWaterfall) {
        offerActionLinkoutWaterfalls.push({
          active: offerAction.active,
          id: offerActionLinkoutWaterfall.id,
          offerActionId: offerAction.id,
          name: offerAction.title,
        });
      } else if (offerActionType === 'GTM_EVENT') {
        offerActionGtmEvents.push({
          active: offerAction.active,
          // id: offerActionGtmEvent.id,
          offerActionId: offerAction.id,
          name: offerAction.title,
        });
      }
    });

    return [
      ...offerActionPosts,
      ...offerActionLinkouts,
      ...offerActionAttributes,
      ...offerActionPostWaterfalls,
      ...offerActionLinkoutWaterfalls,
      ...offerActionGtmEvents,
    ];
  };

  safeParse = (data, def = []) => {
    try {
      return JSON.parse(data);
    } catch {
      return def;
    }
  };

  async getOfferData() {
    if (!this.state.isEditMode) {
      this.props.updateSelector('Question');
      this.setState({ isOfferFetched: true });
      return { isActive: true };
    }

    const {
      name = '',
      advertiser = {},
      tags,
      content,
      isActive,
      ariaOfferId,
      offerType: { name: offerType } = { name: '' },
      customVariables,
    } = this.props?.currentOffer || {};

    const parsedAdvertiser = advertiser?.id
      ? { id: advertiser.id, name: advertiser.name, ariaAdvertiserId: advertiser.ariaAdvertiserId }
      : null;

    this.setState(
      (prev) => ({
        content: {
          ...this.props.currentOffer,
          wallOffers: this.state.wallOffers,
          customVariables: this.safeParse(customVariables),
        },
        isActive,
        offerContent: this.safeParse(content, {}),
        offerName: name,
        offerType,
        advertiser: parsedAdvertiser,
        advertiserName: parsedAdvertiser?.name,
        ariaAdvertiserId: parsedAdvertiser?.ariaAdvertiserId,
        errors: {
          ...prev.errors,
          offerName: !name.length,
          _error: !name.length ? 'Enter offer name.' : null,
        },
        isOfferFetched: true,
        ariaOfferId,
        selectedTags: this.safeParse(tags),
      }),
      () => {
        this.props.updateOfferState({
          advertiser: this.state?.advertiser,
          tagValue: this.state?.tagValue,
          id: this.state?.id,
          offers: this.state?.offers,
          offerName: this.state?.offerName,
          isEditMode: this.state?.isEditMode,
          browsers: this.state?.browsers,
          offerContent: this.state?.offerContent,
          errors: this.state?.errors,
          actions: this.state?.actions,
          linkouts: this.state?.linkouts,
          posts: this.state?.posts,
          attributes: this.state?.attributes,
          postWaterfalls: this.state?.postWaterfalls,
          linkoutWaterfalls: this.state?.linkoutWaterfalls,
        });
      }
    );

    this.props.updateAdvertiser(parsedAdvertiser);
    this.props.updateSelector(offerType);

    return this.props.currentOffer;
  }

  setCopyToVariation = (value) => {
    return new Promise((resolve, reject) => {
      try {
        console.log('In OfferTitle');
        this.setState({ copyToVariation: value }, () => {
          resolve('State updated successfully');
        });
      } catch (error) {
        reject(error);
      }
    });
  };

  getOptionItems = () => {
    const advertisers = sortByName(this.state.advertisers);
    return advertisers.map(({ name, id }) => ({ name, id }));
  };

  componentDidMount() {
    console.log('mounted', { props: this.props });
    const offerId = this.props.selectedOfferId;
    if (offerId === 'create') {
      this.setState({ isEditMode: false, id: null }, this.getOfferData);
    } else {
      this.setState({ isEditMode: true, id: parseInt(offerId) }, this.getOfferData);
    }

    this.props.updateCriteriaType('Offer Criteria');
    this.props.updateAdvertiser(null);
    this.getAdvertisers();
    this.getTags();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.offerActions !== this.props.offerActions) {
      if (this.state.loading) {
        console.log('Done loading offer actions');
        this.setState({ loading: false });
      }
    }
  }

  componentWillUnmount() {
    this.props.setOfferActions([]);
  }

  render() {
    const props = {
      id: this.state.id,
      offer: this.state.content,
      offerContent: this.state.offerContent,
      offerName: this.state.offerName,
      isEditMode: this.state.isEditMode,
      advertisers: this.state.advertisers,
      tags: this.state.tags,
      errors: this.state.errors,
      saveOffer: this.saveOfferData,
      actions: this.state.actions,
      linkouts: this.state.linkouts,
      posts: this.state.posts,
      attributes: this.state.attributes,
      postWaterfalls: this.state.postWaterfalls,
      linkoutWaterfalls: this.state.linkoutWaterfalls,
      gtmEvents: this.state.gtmEvents,
      isActive: this.state.isActive,
    };

    const offerComponents = {
      Question: OfferQuestion,
      Form: OfferForm,
      Banner: OfferBanner,
      Iframe: OfferiFrame,
      Javascript: OfferJavascript,
      'Offer Wall': OfferWall,
    };

    const OfferComponent = offerComponents[this.state.offerType] || OfferQuestion;

    return (
      <div className='animated fadeIn'>
        <Card>
          <CardHeader>
            <Row>
              <Col lg='6'>
                <span style={{ display: 'flex', alignItems: 'baseline' }}>
                  <ActiveStatusBadge isActive={this.state.isActive} />
                  <h3>{this.state.offerName}</h3>&nbsp;&nbsp;
                  <h6>
                    <em>Offer ID: </em>
                    {this.state.id}
                  </h6>
                </span>
                {this.props.flex ? (
                  <>
                    <Row style={{ alignItems: 'flex-end' }}>
                      <Col xs='auto'>
                        <h5>Flex </h5>
                      </Col>
                      <Col xs='3'>
                        <NumberPicker
                          min={1}
                          max={100}
                          defaultValue={this.props.flex}
                          value={this.state.flex}
                          onChange={(value) => {
                            this.setState({ flex: value });
                          }}
                        />
                      </Col>
                    </Row>
                  </>
                ) : null}
              </Col>
              <Col lg={3}>
                <Label>ARIA Offer</Label>
                <CreatableAriaOfferSelectComponent
                  setParentIdValue={this.setAriaOfferId}
                  apiBaseUrl={ARIA_API_BASE_URL}
                  apiKey={ARIA_API_KEY}
                  advertiserId={this.state.ariaAdvertiserId}
                  initialValue={this.state.ariaOfferId}
                />
              </Col>
              <Col lg='2' id='action-detail-locations-modal'>
                <Row>
                  <Col>
                    <LocationsModal content={this.state.content ? this.state.content : {}} />
                  </Col>
                </Row>
              </Col>
              <Col lg='1' id='action-detail-locations-modal'>
                <Row>
                  <Col>
                    <ActiveStatusModal
                      content={this.state.content}
                      isActive={this.state.isActive}
                      getErrors={() => this.state.errors}
                      onClickSubmit={this.toggleActiveStatus}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <Row>
              <Col lg={3}>
                <FormGroup className='offerTitle'>
                  <Label>Offer Name</Label>
                  <InputGroup>
                    <InputGroupAddon addonType='prepend'>
                      <InputGroupText>
                        <i className='fa fa-edit' />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      required
                      type='text'
                      className={this.state.errors.offerName === true ? 'error' : ''}
                      id='offer-title'
                      name='offer-title'
                      defaultValue={this.state.offerName || (this.props.offerName && this.props.offerName)}
                      onChange={(e) =>
                        this.setState({
                          offerName: e.target.value,
                          errors: Object.assign({}, this.state.errors, {
                            offerName: e.target.value.length === 0,
                            _error: e.target.value.length === 0 ? 'Please enter an offer name.' : null,
                          }),
                        })
                      }
                      placeholder='Offer Title'
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col lg={2}>
                <Label>Offer Type</Label>
                <Input type='select' value={this.state.offerType} onChange={this.handleOfferTypeChange}>
                  <option value='Question'>Question</option>
                  <option value='Banner'>Banner</option>
                  <option value='Iframe'>Iframe</option>
                  <option value='Form'>Form</option>
                  <option value='Javascript'>Javascript</option>
                  <option value='Offer Wall'>Offer Wall</option>
                </Input>
              </Col>
              <Col lg='3'>
                <Label>Advertiser</Label>
                <DropdownList
                  data={this.getOptionItems()}
                  onChange={this.handleAdvertiserChange}
                  value={this.state.advertiser}
                  dataKey='id'
                  textField='name'
                  caseSensitive={false}
                  minLength={3}
                  filter='contains'
                />
              </Col>
              <Col lg={3}>
                <Label>Tags</Label>
                <Multiselect
                  data={sortByName(this.state.tags)}
                  value={this.state.selectedTags ? this.state.selectedTags : []}
                  onChange={(e) => this.setState({ selectedTags: e })}
                  valueField={'id'}
                  textField={'name'}
                  filter='contains'
                />
              </Col>
            </Row>
          </CardBody>
        </Card>
        {this.state.isOfferFetched ? <OfferComponent {...props} setCopyToVariation={this.setCopyToVariation} refetchOfferVariations={this.props.refetchOfferVariations} /> : <></>}
      </div>
    );
  }
}

function mapStateToProps(state, props) {
  const { content, offerTriggers, newOfferName } = state.offerState;
  const { offerActions } = state.offerAction;
  const { handlers } = state.criteria;
  return {
    content: state.offerState,
    handlers,
    offerName: content ? content.name : '',
    offerActions,
    offerTriggers,
    newOfferName,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    updateAdvertiser,
    getOfferTriggersByOfferId,
    getOfferActionsById,
    addOfferTrigger,
    updateOfferTriggers,
    addOfferQuestionAnswer,
    updateOfferQuestionAnswers,
    deleteOfferQuestionAnswers,
    saveOffer,
    saveAsOfferName,
    setOfferActions,
    updateOfferState,
    updateSelector,
    updateCriteriaType,
  })(OfferTitle)
);
