import React, { useEffect, useState } from 'react';
import { Button, Input, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

const DeleteConfirmationModal = ({ isOpen, type, onConfirm, closeModal }) => {
  const [isDeleteConfirmed, setIsDeleteConfirmed] = useState(false);

  const handleInputChange = (event) => {
    setIsDeleteConfirmed(event.target.value.toLowerCase() === 'delete');
  };

  useEffect(() => {
    setIsDeleteConfirmed(false);
  },[isOpen]);

  return (
    <Modal isOpen={isOpen} toggle={closeModal} className='modal-danger'>
      <ModalHeader toggle={closeModal}>Delete</ModalHeader>
      <ModalBody>
        <h4>Are you sure you want to delete this {type}?</h4>
        <br />
        <Input
          type='text'
          name='delete'
          id='delete'
          placeholder='Type "delete" to confirm'
          onChange={handleInputChange}
        />
      </ModalBody>
      <ModalFooter>
        <Button className='btn btn-danger' type='button' onClick={onConfirm} disabled={!isDeleteConfirmed}>
          Delete
        </Button>
        <Button className='btn btn-secondary' onClick={closeModal}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default DeleteConfirmationModal;
