import React, { useCallback, useState } from 'react';
import toast from 'react-hot-toast';
import { Button } from 'reactstrap';
import { ZuulToaster } from '../../components/Toaster/ZuulToaster';
import SummaryCard from '../../components/SummaryCard/SummaryCard';
import CustomVariablesModal from './CustomVariablesModal';
import { useCustomVariables, useSaveCustomVariable } from './queries';
import { columns } from './utils';

const CustomVariables = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { isLoading, data } = useCustomVariables();
  const { mutateAsync: createCustomVariable } = useSaveCustomVariable();
  const [row, setRow] = useState();

  const zuulUser = JSON.parse(localStorage.getItem('userData'));

  const toggleModal = () => {
    setIsModalOpen((prevModal) => !prevModal);
  };

  const onCreateCustomVariable = useCallback(
    (formData) => {
      toast.promise(createCustomVariable({ ...formData, zuulUser }), {
        loading: 'Updating custom variable...',
        success: (data) => (data.status === 200 ? 'Custom variable updated successfully!' : 'Could not create.'),
        error: (err) => 'Error updating feature:' + err.message || err?.response?.data?.status,
      });
      toggleModal();
    },
    [createCustomVariable]
  );

  const rowEvents = {
    onClick: (_, row) => {
      setRow(row);
      setIsModalOpen(true);
    },
  };

  const onAddHandler = () => {
    toggleModal();
    setRow(null);
  };

  return (
    <>
      {isModalOpen && (
        <CustomVariablesModal
          isModalOpen={isModalOpen}
          onSubmit={onCreateCustomVariable}
          row={row}
          toggleModal={toggleModal}
        />
      )}

      <SummaryCard
        entity='Custom Variables'
        columns={columns}
        createComponent={
          <Button color='primary' onClick={onAddHandler} className={'float-right'}>
            Add Custom Variable
          </Button>
        }
        data={data}
        defaultSorted={[{ dataField: 'updatedAt', order: 'desc' }]}
        loading={isLoading}
        rowEvents={rowEvents}
      >
        <ZuulToaster />
      </SummaryCard>
    </>
  );
};

export default CustomVariables;
