const DraggableOffer = ({ offer }) => {
  const { name: offerTypeName, offerBlock } = offer?.offerType;

  if (!offer) {
    return <div className='dndnode input'>Invalid offer data</div>;
  }

  const onDragStart = (event) => {
    event.dataTransfer.setData('text/plain', offer?.id);

    const offerBlockOfferIds = offer?.offerBlock?.offerBlockOffers.map((offerBlockOffer) => offerBlockOffer?.offer?.id);
    offerBlockOfferIds && event.dataTransfer.setData('application/json', JSON.stringify(offerBlockOfferIds));

    event.dataTransfer.effectAllowed = 'move';
  };

  const displayId = offerTypeName === 'Offer Block' && offerBlock ? offerBlock.id : offer.id;

  return (
    <div className='dndnode input' draggable onDragStart={onDragStart}>
      {displayId} : {offer.name}
    </div>
  );
};

export default DraggableOffer;
