import classnames from 'classnames';
import React from 'react';
import { Label } from 'reactstrap';
import './SearchLabel.scss';

const SearchLabel = (props) => (
  <Label className={classnames(props.className, 'search-label')}>
    <i className='fa fa-search' /> Search
  </Label>
);

export default SearchLabel;
