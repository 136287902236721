import { UPDATE_SELECTOR, GET_ALL_OFFER_ACTIONS, RESET_MODAL } from '../../../actions/types';
import {
  GET_SITES,
  GET_LANDING_PAGE_GROUP,
  GET_ALL_LANDING_PAGE_GROUPS,
  UPDATE_LANDING_PAGE_GROUP,
  UPDATE_SELECTED_LANDING_PAGES,
  CREATE_LANDING_PAGE_GROUP,
  SET_LANDING_PAGE_GROUP,
  UPDATE_LANDING_PAGE_GROUP_ERROR,
  UPDATE_LANDING_PAGE_GROUP_WARNING
} from './types';

import { formValueSelector } from 'redux-form';

const initialState = {
  selector: formValueSelector('LPGDetails'),
  landingPageGroup: {},
  allOfferActions: [],
  selectedLPs: [],
  title: 'Landing Page',
  saveError: false,
  saveErrorMessage: null,
  saveWarning: false,
  saveWarningMessage: null,
  isLPGSaved: false,
  sites: []
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_LANDING_PAGE_GROUP:
      return {
        ...state,
        landingPageGroup: action.payload,
        isLPGSaved: false
      };
    case GET_ALL_LANDING_PAGE_GROUPS:
      return {
        ...state,
        landingPageGroups: action.payload
      };
    case GET_SITES:
      return {
        ...state,
        sites: action.payload
      };
    case GET_ALL_OFFER_ACTIONS:
      return { ...state, allOfferActions: action.payload };
    case UPDATE_LANDING_PAGE_GROUP:
      return {
        ...state,
        landingPageGroup: action.payload,
        isLPGSaved: true,
        saveError: false
      };
    case SET_LANDING_PAGE_GROUP:
      return {
        ...state,
        landingPageGroup: action.payload
      };
    case UPDATE_SELECTED_LANDING_PAGES:
      const [index, selectedLPArr, isUnmount] = action.payload;
      let selectedLPs = [...state.selectedLPs];
      if (!selectedLPArr) {
        // user removed a return user segment completely
        selectedLPs.splice(index, 1);
        return { ...state, selectedLPs };
      } else if (isUnmount) {
        return { ...state, selectedLPs: [] };
      }
      selectedLPs[index] = selectedLPArr;
      return { ...state, selectedLPs };

    case CREATE_LANDING_PAGE_GROUP:
      return {
        ...state,
        landingPageGroup: action.payload,
        isLPGSaved: true,
        saveError: false
      };
    case UPDATE_LANDING_PAGE_GROUP_ERROR:
      return {
        ...state,
        saveError: true,
        saveErrorMessage: action.payload
      };
    case UPDATE_LANDING_PAGE_GROUP_WARNING:
      return {
        ...state,
        saveWarning: true,
        saveWarningMessage: action.payload
      };
    case RESET_MODAL:
      return {
        ...state,
        saveError: false,
        saveErrorMessage: null,
        saveWarning: false,
        saveWarningMessage: null
      };
    default:
      return state;
  }
}
