import React from 'react';
import { Field } from 'redux-form';
import { renderToggleSwitch } from '../../views/Offers/OfferTitle/renderConsts';

const ToggleButton = (props) => {
  const { id, fieldName, style } = props;
  const padding = '10px';
  const labelStyle = {
    verticalAlign: 'super',
    paddingRight: padding,
  };
  const fieldStyle = { paddingLeft: padding };

  return (
    <div style={style}>
      <label htmlFor={id} style={labelStyle}>
        {props.children}
      </label>
      <Field name={fieldName} id={id} style={fieldStyle} component={renderToggleSwitch} />
    </div>
  );
};

export default ToggleButton;
