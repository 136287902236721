import React, { useCallback, useEffect, useState } from 'react';
import { Field, Form } from 'react-final-form';
import toast from 'react-hot-toast';
import 'react-widgets/styles.css';
import { Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import SummaryCard from '../../components/SummaryCard/SummaryCard';
import { columnDefaults } from '../../utils/tableFunctions';
import { renderField, textarea } from '../Offers/OfferTitle/renderConsts';
import { useCreateHandler, useDeleteHandler, useHandlers, useUpdateHandler } from './queries';
import { ZuulToaster } from '../../components/Toaster/ZuulToaster';

const columns = [
  {
    dataField: 'id',
    text: 'ID',
    ...columnDefaults,
  },
  {
    dataField: 'name',
    text: 'Name',
    ...columnDefaults,
  },
];

const HandlersSummary = () => {
  const { data, isLoading, error } = useHandlers();
  const [modal, setModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [row, setRow] = useState({ name: '', code: '' });
  const { mutate: updateHandler, isSuccess: updateSuccess, isError: updateError } = useUpdateHandler();
  const { mutate: createHandler, isSuccess: createSuccess, isError: createError } = useCreateHandler();
  const { mutate: deleteHandler, isSuccess: deleteSuccess, isError: deleteError } = useDeleteHandler();

  useEffect(() => {
    if (deleteSuccess) {
      toast.success('Handler Deleted!');
    }
    if (deleteError) {
      toast.error('Error Deleting Handler!');
    }
  }, [deleteSuccess, deleteError]);

  useEffect(() => {
    if (createSuccess || updateSuccess) {
      toast.success('Handler Saved!');
    }
    if (updateError || createError) {
      toast.error('Error Saving Handler!');
    }
  }, [createSuccess, updateSuccess, updateError, createError]);

  const handleRowClick = useCallback((row) => {
    setModal(true);
    setRow(row);
  }, []);

  const handleFormSubmit = useCallback(
    (values) => {
      if (row?.id) {
        updateHandler(values);
      } else {
        createHandler(values);
      }
      setModal((prevModal) => !prevModal);
    },
    [row, updateHandler, createHandler]
  );

  const handleDelete = useCallback(() => {
    setModal(false);
    setDeleteModal(true);
  }, []);

  const confirmDelete = useCallback(() => {
    deleteHandler(row?.id);
    setDeleteModal(false);
  }, [deleteHandler, row]);

  return (
    <>
      <Form
        onSubmit={handleFormSubmit}
        initialValues={row}
        render={({ handleSubmit, submitting }) => (
          <form onSubmit={handleSubmit}>
            <Modal isOpen={modal} toggle={() => setModal((prevModal) => !prevModal)} className={'modal-info'}>
              <ModalHeader toggle={() => setModal((prevModal) => !prevModal)}>{`${
                row?.id ? 'Edit' : 'Add'
              } Handler`}</ModalHeader>
              <ModalBody>
                <Row>
                  <Col xs={'auto'}>Name</Col>
                  <Col>
                    <Field name='name' component={renderField} type='text' placeholder='Name' />
                  </Col>
                </Row>
                <Row>
                  <Col xs={'auto'}>Code</Col>
                  <Col>
                    <Field name='code' component={textarea} placeholder='Code' />
                  </Col>
                </Row>
              </ModalBody>
              <ModalFooter
                style={{
                  justifyContent: 'space-between',
                }}
              >
                {row?.id && (
                  <button className='btn btn-danger' type='button' onClick={handleDelete}>
                    <i className='fa fa-trash'></i>
                  </button>
                )}
                <Row>
                  <Col>
                    <button className='btn btn-primary' type='submit' disabled={submitting} onClick={handleSubmit}>
                      Submit
                    </button>
                  </Col>
                  <Col>
                    <button className='btn btn-secondary' onClick={() => setModal((prevModal) => !prevModal)}>
                      Cancel
                    </button>
                  </Col>
                </Row>
              </ModalFooter>
            </Modal>
          </form>
        )}
      />
      <Modal isOpen={deleteModal} toggle={() => setDeleteModal((prevModal) => !prevModal)} className='modal-danger'>
        <ModalHeader toggle={() => setDeleteModal((prevModal) => !prevModal)}>Delete Handler</ModalHeader>
        <ModalBody>Are you sure you want to delete this handler?</ModalBody>
        <ModalFooter>
          <button className='btn btn-danger' type='button' onClick={confirmDelete}>
            Delete
          </button>
          <button className='btn btn-secondary' onClick={() => setDeleteModal((prevModal) => !prevModal)}>
            Cancel
          </button>
        </ModalFooter>
      </Modal>
      <SummaryCard
        entity='Handlers'
        createComponent={
          <Button
            color='primary'
            onClick={() => {
              setModal(true);
              setRow({ name: '', code: '' });
            }}
            className={'float-right'}
          >
            Create Handler
          </Button>
        }
        columns={columns}
        defaultSorted={[{ dataField: 'id', order: 'desc' }]}
        data={data}
        loading={isLoading}
        error={error}
        rowEvents={{
          onClick: (e, row, rowIndex) => {
            handleRowClick(row);
          },
        }}
      />
      <ZuulToaster />
    </>
  );
};

export default HandlersSummary;
