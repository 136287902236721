import React, { useState } from 'react';
import { Button } from 'reactstrap';
import CustomVariableItem from './CustomVariableItem';
import VerifyModal from './VerifyModal';
import { extractUDKeys } from './utils';

export default function CustomVariablesForm({
  fields,
  customVarFlag,
  meta: { touched, error },
  formValues = {},
  showVerify = false,
}) {
  const [modal, setModal] = useState(false);
  const toggleModal = () => setModal(!modal);

  const verifyCustomVariables = () => {
    const customVariables = fields.getAll() || [];
    const containerUDKeys = extractUDKeys(formValues?.container?.content || '');
    const layoutUDKeys = extractUDKeys(formValues?.layout?.content || '');

    const containerMissingKeys = containerUDKeys.filter(
      (containerUDKey) => !customVariables.some((customVariable) => customVariable.key === containerUDKey.key)
    );
    const layoutMissingKeys = layoutUDKeys.filter(
      (layoutUDKey) => !customVariables.some((customVariable) => customVariable.key === layoutUDKey.key)
    );

    const containerLayoutUDKeys = [...containerUDKeys, ...layoutUDKeys];

    const customVariablesNotInContainerLayout = customVariables.filter(
      (customVariable) =>
        !containerLayoutUDKeys.some((containerLayoutUDKey) => containerLayoutUDKey.key === customVariable.key)
    );

    return { customVariablesNotInContainerLayout, containerMissingKeys, layoutMissingKeys };
  };

  const { customVariablesNotInContainerLayout, containerMissingKeys, layoutMissingKeys } = verifyCustomVariables();

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '1rem' }}>
        <div>
          <h4>Custom Variables</h4>
          {fields.getAll() ? <h6>Total {fields.getAll().length}</h6> : null}
        </div>
        <div>
          {showVerify && (
            <Button color='success' onClick={toggleModal} style={{ marginRight: '0.5rem' }}>
              Verify Custom Variables
            </Button>
          )}
          <Button
            color='success'
            onClick={() =>
              fields.push({
                key: customVarFlag,
              })
            }
          >
            +
          </Button>
        </div>
      </div>
      {touched && error && <span>{error}</span>}
      {fields.map((customVariablesItem, index) => (
        <CustomVariableItem
          key={index}
          customVariablesItem={customVariablesItem}
          index={index}
          fields={fields}
          customVarFlag={customVarFlag}
        />
      ))}
      <VerifyModal
        isOpen={modal}
        toggle={toggleModal}
        customVarFlag={customVarFlag}
        layoutMissingKeys={layoutMissingKeys}
        containerMissingKeys={containerMissingKeys}
        customVariablesNotInContainerLayout={customVariablesNotInContainerLayout}
        fields={fields}
      />
    </div>
  );
}
