import { Switch, useHistory, withRouter } from 'react-router-dom';
import { LoginCallback, Security } from '@okta/okta-react';
import { ApmRoute } from '@elastic/apm-rum-react';
import React from 'react';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { OktaLogin } from './views/Pages/OktaLogin/OktaLogin';

const oktaAuth = new OktaAuth({
  issuer: 'https://whatifmediagroup.okta.com/oauth2/default',
  clientId: `${import.meta.env.VITE_OKTA_CLIENT_ID}`,
  redirectUri: window.location.origin + '/login/callback',
  tokenManager: {
    autoRenew: true,
  },
});

const OktaApp = () => {
  const history = useHistory();

  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    const preLoginUrl = sessionStorage.getItem('preLoginUrl');
    // If there's a stored URL, redirect to it, otherwise redirect to home
    sessionStorage.removeItem('preLoginUrl');
    history.replace(toRelativeUrl(preLoginUrl || originalUri || '/', window.location.origin));
  };

  const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));
  const SequenceDetails = React.lazy(() => import('./views/Sequences/SequenceDetails/SequenceDetails'));
  const OfferBlockDetails = React.lazy(() => import('./views/OfferBlocks/OfferBlockDetails/OfferBlockDetails'));

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <Switch>
        <ApmRoute exact path='/campaigns/sequences/create' name='Sequence Details' component={SequenceDetails} />
        <ApmRoute
          path='/campaigns/sequences/:sequenceId/:checkIn'
          name='Sequence Details'
          component={SequenceDetails}
        />
        <ApmRoute path='/campaigns/sequences/:sequenceId' name='Sequence Details' component={SequenceDetails} />
        <ApmRoute
          path='/campaigns/offerblocks/:offerBlockId'
          name='Offer Block Details'
          component={OfferBlockDetails}
        />
        <ApmRoute exact path='/login' name='Login Page' component={OktaLogin} />
        <ApmRoute path='/login/callback' component={LoginCallback} />
        <ApmRoute path='/' name='Home' component={DefaultLayout} />
      </Switch>
    </Security>
  );
};

export default withRouter(OktaApp);
