import _fp from 'lodash/fp';
import React from 'react';
import { Link } from 'react-router-dom';
import { Badge, Col, Row } from 'reactstrap';
import ActiveStatusModal from '../components/ActiveStatusModal/ActiveStatusModal';
import api from '../services';
import { formatDate } from './conversionFunctions';
import { addDifferenceCurrencyFormatting, getColorClassName, getNilCellDefault } from './currencyDisplay';

export const activeStatusIcon = (row) => {
  if (row.id) {
    if (row.active) {
      return (
        <div>
          {row.id}
          <i className='fa fa-circle positive-value'></i>
        </div>
      );
    } else {
      return (
        <div>
          {row.id}
          <i className='fa fa-circle negative-value'></i>
        </div>
      );
    }
  }
};

export const activeStatusIconAndText = (row) => {
  if (row.id) {
    if (row.active) {
      return (
        <Badge className='mr-1' color='success'>
          Active
        </Badge>
      );
    } else {
      return (
        <Badge className='mr-1' color='danger'>
          Inactive
        </Badge>
      );
    }
  }
};

const updateStatus = (row, activeStatus, endpoint) => {
  console.log(activeStatus);
  if (row.id) {
    let isActive = activeStatus;
    const zuulUser = JSON.parse(localStorage.getItem('userData'));
    let saveData = {
      id: row.id,
      isActive,
      active: activeStatus,
      zuulUser,
    };
    api.put(`${endpoint}/active/${row.id}`, saveData).then((result) => {
      console.log(JSON.stringify(result));
    });
  }
};

export const activeStatusSwitch = (row, entity, errors) => {
  return (
    <ActiveStatusModal
      content={row}
      isActive={row.active}
      modalType={entity.modalType}
      getErrors={() => errors || null}
      onClickSubmit={(activeStatus) => updateStatus(row, activeStatus, entity.endpoint)}
    />
  );
};

const handleActiveStatus = (activeStatusType, row, entity, errors) => {
  let activeStatus;
  switch (activeStatusType) {
    case 'icon':
      activeStatus = activeStatusIcon(row);
      break;
    case 'iconAndText':
      activeStatus = activeStatusIconAndText(row);
      break;
    case 'switch':
      activeStatus = activeStatusSwitch(row, entity, errors);
      break;
    default:
      activeStatus = activeStatusSwitch(row, entity, errors);
  }
  return activeStatus;
};

export const columnFormatter = ({ cell, row, computedURL, activeStatusType, entity, errors, colorClassName }) => {
  let isReturnUrl = row.offerType === 'Return Url';
  if (isReturnUrl) {
    computedURL = false;
  }
  const className = `${colorClassName ? colorClassName : 'inherit-color'}`;

  return (
    <Link to={computedURL} className={className}>
      <Row>
        <Col lg={12}>{activeStatusType ? handleActiveStatus(activeStatusType, row, entity, errors) : cell}</Col>
      </Row>
    </Link>
  );
};

export const defaultColumnFormatter = (url) => (cell, row) => {
  return columnFormatter({
    cell,
    row,
    computedURL: url + row.id,
  });
};

export const dateColumnFormatter = (url) => (cell, row) => {
  return defaultColumnFormatter(url)(formatDate(cell), row);
};

export const revDiffColumnFormatter = (url) => (cell, row) => {
  const computedURL = url + row.id;
  const colorClassName = getColorClassName(cell);
  const modifiedCell = _fp.pipe(getNilCellDefault, addDifferenceCurrencyFormatting)(cell);

  return columnFormatter({ cell: modifiedCell, row, computedURL, colorClassName });
};

export const sortCaretFn = (order) => {
  const Wrapper = ({ children }) => {
    return (
      <span>
        &nbsp;&nbsp;
        {children}
      </span>
    );
  };
  const ascComponent = (
    <>
      <Wrapper>
        <i className='fa fa-caret-up' />
      </Wrapper>
    </>
  );
  const descComponent = (
    <>
      <Wrapper>
        <i className='fa fa-caret-down' />
      </Wrapper>
    </>
  );
  const defaultComponent = (
    <span>
      &nbsp;&nbsp;&nbsp;
      <i className='fa fa-sort' />
    </span>
  );

  switch (order) {
    case 'asc':
      return ascComponent;
    case 'desc':
      return descComponent;
    default:
      return defaultComponent;
  }
};

/**
 * Functional way to test regex that works with lodash/fp.
 * Takes you regex and text and returns a boolean if the text matches the regex.
 *
 * @param regexp
 * @returns {function(*=): *}
 */
export const testRegex = (regexp) => (text) => regexp.test(text);

/**
 * Filters entities to support search in entity tables.
 * Takes a list of entities, a function that transforms the entity into a list of terms to compare against the search
 * term, and the search term.
 *
 * Example entity transforming function:
 * (entity) => ([entity.id, entity.name, entity.status])
 *
 * @param entities
 * @param transformEntityForSearching
 * @param searchTerm
 * @returns {any[]|*}
 */
export const filterForSearch = (transformEntityForSearching, searchTerm) => (entities) => {
  if (searchTerm && entities.length > 0) {
    const likeSearchRegexp = new RegExp(`.*${_fp.toLower(searchTerm)}.*`);
    const mapLower = _fp.map(_fp.toLower);
    const regexpMatchesSome = _fp.some(testRegex(likeSearchRegexp));
    const searchTermIsInAnyValue = _fp.pipe(transformEntityForSearching, mapLower, regexpMatchesSome);

    return _fp.filter(searchTermIsInAnyValue)(entities);
  } else {
    return entities;
  }
};
export const filterDataBySearchTerm = (searchTerm, keysToSearchBy, unfilteredData) => {
  // Split the term by space and remove empty spaces
  let splitSearchTerm = searchTerm.split(/[ ,]+/).filter(Boolean);
  const filteredData = unfilteredData.filter((entity) => {
    const entityToSearchBy = {};
    keysToSearchBy.forEach((key) => {
      // WIP entity[key] could be an object i.e. site = {}
      entityToSearchBy[key] = entity[key] + '' || '';
      if (entity[key] && typeof entity[key] === 'object' && entity[key].name) {
        entityToSearchBy[key] = entity[key].name + '' || '';
      }
    });
    let doesMatchTerms = true;
    for (let i = 0; i < splitSearchTerm.length; i++) {
      if (
        !Object.keys(entityToSearchBy).some((key) =>
          entityToSearchBy[key].toLowerCase().includes(splitSearchTerm[i].toLowerCase())
        )
      ) {
        doesMatchTerms = false;
      }
    }
    return doesMatchTerms;
  });
  return filteredData;
};

export const columnDefaults = {
  sort: true,
  sortCaret: (order) => {
    if (!order)
      return (
        <span>
          &nbsp;&nbsp;&nbsp;
          <i className='fa fa-sort' />
        </span>
      );
    else if (order === 'asc')
      return (
        <span>
          &nbsp;&nbsp;
          <i className='fa fa-sort-asc' />
        </span>
      );
    else if (order === 'desc')
      return (
        <span>
          &nbsp;&nbsp;
          <i className='fa fa-sort-desc' />
        </span>
      );
    return null;
  },
  headerStyle: {
    backgroundColor: '#2f353a',
  },
};

export const simplifyDate = (dateString) => {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};
