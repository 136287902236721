import React, { useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import { CSVDownloader } from 'react-papaparse';
import { Button, Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import { useAffiliates } from '../OfferBlocks/queries';
import './RevSharePercentage.css';
import RevSharePercentageDetails from './RevSharePercentageDetails';
import { UploadCSV } from './UploadCSV';
import columns from './columns';
import { useRevSharePercentageRules } from './queries';
import ZuulLoader from '../../components/ZuulLoader/ZuulLoader';

const RevSharePercentage = () => {
  const { SearchBar, ClearSearchButton } = Search;
  const [detailModalOpen, setDetailModalOpen] = useState(false);
  const [rowSelection, setRowSelection] = useState({});
  const { data: affiliates, isLoading: affiliatesLoading } = useAffiliates();
  const { data: revSharePercentageRules, isLoading: rulesLoading } = useRevSharePercentageRules((data) =>
    data.map((rule) => {
      const foundAffiliate = affiliates?.find((el) => el.networkAffiliateId === rule.affiliateId);
      return {
        ...rule,
        affiliateName: foundAffiliate ? foundAffiliate.name : '',
      };
    })
  );

  const pagination = paginationFactory({
    page: 1,
    sizePerPage: 10,
  });

  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      setRowSelection(row);
      setDetailModalOpen(true);
    },
  };

  if (rulesLoading || affiliatesLoading) return <ZuulLoader />;

  return (
    <Card>
      <RevSharePercentageDetails
        allAffiliates={affiliates}
        isOpen={detailModalOpen}
        closeDetailModal={() => {
          setDetailModalOpen(false);
        }}
        revSharePercentageRules={revSharePercentageRules}
        rowSelection={rowSelection}
      />

      <CardHeader>
        <i className='icon-menu' />
        Revshare Percentage Rule
      </CardHeader>
      <CardBody>
        <Row>
          <Col lg={6}>
            <span className='display-4'>Revshare Percentage Rule</span>
          </Col>
          <Col lg={6}>
            <Row>
              <Col lg={6}>
                <UploadCSV revSharePercentageRules={revSharePercentageRules} allAffiliates={affiliates} />
              </Col>

              <Col lg={6}>
                <Row>
                  <Col lg={12}>
                    <Button
                      className='float-right'
                      onClick={() => {
                        setRowSelection({ startDate: new Date(), default: false });
                        setDetailModalOpen(true);
                      }}
                    >
                      <i className='fa fa-plus' /> Create New Revshare Percentage Rule
                    </Button>
                  </Col>

                  <Col lg={12}>
                    <CSVDownloader
                      type={Button}
                      className='float-right btn btn-secondary'
                      style={{ marginTop: 10 }}
                      filename='Revshare_CSV_Template'
                      data={[
                        {
                          id: 'Leave blank unless updating a previous record',
                          affiliateId: 'Required affiliate id. Must be valid id',
                          revSharePercentage: 'Required, can be whole number or decimal',
                          startDate: 'Required Date as MM/DD/YYYY. TimeStamp (HH:MM AM/PM) is optional.',
                          endDate:
                            'Optional & has to be after Start Date. TimeStamp (HH:MM AM/PM) is optional. Only used for non-default rules',
                          webDomain: 'Optional',
                          subId: 'Optional & can be sourced from tracking.user_session.aff_sub',
                          aibListId: 'Optional & only available for Affiliate ID: 1006',
                          aibTypeId: 'Optional & only available for Affiliate ID: 1006',
                          landingPageGroupId: 'Optional',
                          default: 'Default to true. Must have a default rule before creating a non-default rule',
                        },
                        {
                          id: '',
                          affiliateId: '1',
                          revSharePercentage: '2.03',
                          startDate: '03/27/2023 3:13 PM',
                          endDate: '',
                          webDomain: '',
                          subId: '',
                          aibListId: '',
                          aibTypeId: '',
                          landingPageGroupId: '',
                          default: 'true',
                        },
                        {
                          id: '920384',
                          affiliateId: '1',
                          revSharePercentage: '4.786543',
                          startDate: '04/01/2023',
                          endDate: '05/22/2023',
                          webDomain: 'www.test.com',
                          subId: '',
                          aibListId: '',
                          aibTypeId: '',
                          landingPageGroupId: '4',
                          default: 'false',
                        },
                      ]}
                    >
                      <i className='fa fa-download' /> Download Revshare CSV Template
                    </CSVDownloader>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
        <ToolkitProvider bootstrap4 keyField='id' data={revSharePercentageRules} columns={columns} search>
          {(props) => (
            <>
              <SearchBar {...props.searchProps} />
              <ClearSearchButton {...props.searchProps} />
              <hr />
              <BootstrapTable
                pagination={pagination}
                rowStyle={{ cursor: 'pointer' }}
                rowEvents={rowEvents}
                {...props.baseProps}
              />
            </>
          )}
        </ToolkitProvider>
      </CardBody>
    </Card>
  );
};

export default RevSharePercentage;
