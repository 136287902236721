import { Card, CardBody, CardHeader, Col, Collapse, Label, Row } from 'reactstrap';
import { Field } from 'redux-form';
import { textarea } from '../../Offers/OfferTitle/renderConsts';
import React, { useState } from 'react';

export function ScriptsCard(props) {
  const [collapsed, setCollapsed] = useState(false);
  return (
    <Card>
      <CardHeader>
        <Row>
          <Col onClick={() => setCollapsed(!collapsed)} id='click' lg={10}>
            <div>
              <h4>
                Scripts&nbsp;&nbsp;{' '}
                {collapsed ? <i className='fa fa-chevron-up opened' /> : <i className='fa fa-chevron-down closed' />}
              </h4>
            </div>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <Collapse isOpen={collapsed}>
          <Row>
            <Col lg='4'>
              <Label>Head</Label>
              <Field
                required
                type='text'
                component={textarea}
                id='scriptsHead'
                name='scriptsHead'
                placeholder='Head Scripts'
              />
            </Col>

            <Col lg='4'>
              <Label>Body Top</Label>
              <Field
                required
                type='text'
                component={textarea}
                id='scriptsBodyTop'
                name='scriptsBodyTop'
                placeholder='Body Top Scripts'
              />
            </Col>
            <Col lg='4'>
              <Label>Body Bottom</Label>
              <Field
                required
                type='text'
                component={textarea}
                id='scriptsBodyBottom'
                name='scriptsBodyBottom'
                placeholder='Body Bottom Scripts'
              />
            </Col>
          </Row>
        </Collapse>
      </CardBody>
    </Card>
  );
}
