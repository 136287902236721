import arrayMutators from 'final-form-arrays';
import React, { useEffect, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { useHistory, useParams } from 'react-router-dom';
import { Alert, Card, CardBody, CardHeader, Col, Collapse, Row } from 'reactstrap';
import renderActiveStatusBadge from '../../../components/ActiveStatusBadge/ActiveStatusBadge';
import ActiveStatusModal from '../../../components/ActiveStatusModal/ActiveStatusModal';
import SelectAdvertiserPrePings from '../../../components/PrePing/SelectAdvertiserPrePings';
import renderInput from '../../../components/PrePing/renderInput';
import SaveEntityModal from '../../../components/SaveEntityModal/SaveEntityModal';
import {
  createPublisherPrePing,
  getAllAdvertiserPrePings,
  getAllDomains,
  getPublisherPrePing,
  updatePublisherPrePing,
  updatePublisherPrePingActiveStatus,
} from '../../../services/publisherPrePings/api';
import { parsePPPFormValues } from '../publisherPrePingHelper';
import '../PublisherPrePings.css';
import validate from '../validate';
import { handleCriteriaInitialize } from '../../../utils/CriteriaHelpers';
import Criteria from '../../../components/Criteria/CriteriaFinal/Criteria';
import { ToggleSwitchRow } from '../../../components/FinalForm';
import ZuulLoader from '../../../components/ZuulLoader/ZuulLoader';

export default function PublisherPrePingDetails() {
  const [isLoading, setIsLoading] = useState(true);
  const [allAdvertiserPrePings, setAllAdvertiserPrePings] = useState([]);
  const [allDomains, setAllDomains] = useState([]);
  const [isPublisherPrePingSaved, setIsPublisherPrePingSaved] = useState(false);
  const { publisherPrePingId } = useParams();
  const [currPubPrePing, setCurrPubPrePing] = useState({
    active: true,
    name: '',
    vanityUrl: '',
    advertiserPrePings: [],
    publisherPrePingCriteria: {},
    acceptAllNewUserEmails: true,
  });
  const [loading, setLoading] = useState(true);

  const [isEditMode, setIsEditMode] = useState(!!publisherPrePingId);
  const [collapse, setCollapse] = useState(true);
  const [errorsInState, setErrorsInState] = useState({ saveError: false, _error: null });
  const history = useHistory();

  const updateActiveStatus = async (activeStatus) => {
    if (!isEditMode) {
      setIsEditMode(activeStatus);
      return;
    }
    let active = activeStatus !== undefined ? activeStatus : currPubPrePing.active;
    const update = {
      id: publisherPrePingId,
      active,
    };

    const updatedPublisherPrePing = await updatePublisherPrePingActiveStatus(update);
    setCurrPubPrePing(updatedPublisherPrePing);
  };

  const onSubmit = async (formValues) => {
    const publisherPrePing = parsePPPFormValues(formValues, publisherPrePingId);

    if (publisherPrePingId) {
      const _updatePublisherPrePingStatus = await updatePublisherPrePing(publisherPrePing);
      if (typeof _updatePublisherPrePingStatus !== 'boolean') {
        // If _updatePublisherPrePingStatus is not a boolean, it's an error message that we will display to the user
        return setErrorsInState({ saveError: true, _error: _updatePublisherPrePingStatus });
      }
      setIsPublisherPrePingSaved(_updatePublisherPrePingStatus);
      return _updatePublisherPrePingStatus;
    } else {
      const _createPublisherPrePingStatus = await createPublisherPrePing(publisherPrePing);
      if (typeof _createPublisherPrePingStatus !== 'boolean') {
        return setErrorsInState({ saveError: true, _error: _createPublisherPrePingStatus });
      }
      setIsPublisherPrePingSaved(_createPublisherPrePingStatus);
      history.push('/publisher-pre-pings');
      return _createPublisherPrePingStatus;
    }
  };

  useEffect(() => {
    (async () => {
      try {
        const _advertiserPrePings = await getAllAdvertiserPrePings();

        setAllAdvertiserPrePings(_advertiserPrePings);

        const _domains = await getAllDomains();
        let domains = allDomains;
        _domains.forEach((domain) => {
          domains.push(domain.name);
        });
        domains.unshift('All Domains');
        setAllDomains(domains);
        if (publisherPrePingId) {
          const _publisherPrePing = await getPublisherPrePing(publisherPrePingId);
          if (!_publisherPrePing.publisherPrePingCriteria) {
            _publisherPrePing.publisherPrePingCriteria = {};
          } else if (_publisherPrePing.publisherPrePingCriteria) {
            _publisherPrePing.publisherPrePingCriteria = handleCriteriaInitialize(
              _publisherPrePing.publisherPrePingCriteria
            );
          }
          if (!_publisherPrePing.publisherPrePingCriteria.previousRegistrationConfiguration) {
            _publisherPrePing.publisherPrePingCriteria.previousRegistrationConfiguration = handlePreviousRegistrationInit();
          }

          setCurrPubPrePing(_publisherPrePing);
        }
        setLoading(false);
      } catch (error) {
        console.error(error);
      }
      setIsLoading(false);
    })();
  }, [allDomains, publisherPrePingId]);

  function handlePreviousRegistrationInit() {
    return {
      andOrLogic: null,
      previousRegistrations: [
        { domains: [], includeExcludeAction: null, previousRegistrationsMin: null, previousRegistrationsMax: null },
      ],
    };
  }

  if (isLoading) {
    return <ZuulLoader text={'Loading Publisher Pre-Ping...'} />;
  }

  return (
    <>
      {loading ? (
        <ZuulLoader text='Loading Publisher Pre-Ping...' />
      ) : (
        <Form
          onSubmit={onSubmit}
          initialValues={currPubPrePing}
          validate={validate}
          mutators={{
            ...arrayMutators,
          }}
          render={({
            handleSubmit,
            form: {
              mutators: { push, pop },
            },
            submitting,
            submitFailed,
            invalid,
            form,
            values,
          }) => {
            const errors = {
              invalid: submitFailed ? invalid : false,
              _error: errorsInState._error
                ? errorsInState._error
                : invalid
                ? 'One or more of the fields you entered are invalid.'
                : null,
            };
            const shouldErrorRender =
              (submitFailed || errorsInState.saveError) && Object.keys(errors).some((x) => errors[x]);
            return (
              <form onSubmit={handleSubmit}>
                <Card>
                  <CardHeader>
                    <Row>
                      <Col lg='11'>
                        <span style={{ display: 'flex', alignItems: 'baseline' }}>
                          {renderActiveStatusBadge(currPubPrePing.active)}&nbsp;&nbsp;
                          <h3>{currPubPrePing.name}</h3>&nbsp;&nbsp;
                          <h6>
                            <em>Publisher Pre-Ping ID: </em>
                            {publisherPrePingId}
                          </h6>
                        </span>
                      </Col>
                      <Col lg='1' className='float-left'>
                        <ActiveStatusModal
                          content={currPubPrePing}
                          isActive={currPubPrePing.active || false}
                          modalType={' Publisher Pre Ping'}
                          getErrors={() => errorsInState}
                          onClickSubmit={updateActiveStatus}
                          isEditMode={isEditMode}
                        />
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col>
                        <Field
                          name='name'
                          component={renderInput}
                          type='text'
                          placeholder='Publisher Pre-Ping Name'
                          label='Name'
                          validate={(value) => (value ? undefined : 'Please enter a name for this Publisher Pre Ping.')}
                        />
                        <br />
                        <Field
                          name='vanityUrl'
                          component={renderInput}
                          type='text'
                          placeholder='Vanity URL'
                          label='Vanity URL'
                        />
                        <br />
                        <hr />
                        <br />
                        <Col lg='4'>
                          <ToggleSwitchRow name='acceptAllNewUserEmails' label='Accept all new email addresses' />
                        </Col>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
                <Card>
                  <CardHeader onClick={() => setCollapse(!collapse)}>
                    <h4>
                      Advertiser Pre-Pings
                      {collapse ? (
                        <i className='fa fa-chevron-up opened' />
                      ) : (
                        <i className='fa fa-chevron-down closed' />
                      )}
                    </h4>
                  </CardHeader>
                  <CardBody>
                    <Collapse isOpen={collapse}>
                      <Row>
                        <Col>
                          <FieldArray
                            name='advertiserPrePings'
                            component={SelectAdvertiserPrePings}
                            allAdvertiserPrePings={allAdvertiserPrePings}
                            pop={pop}
                            push={push}
                          />
                        </Col>
                      </Row>
                    </Collapse>
                  </CardBody>
                </Card>
                <Card>
                  <Criteria push={push} pop={pop} allDomains={allDomains} source={'publisherPrePingCriteria'} />
                </Card>
                <Row>
                  <Col lg='6' md='6' sm='6'>
                    {shouldErrorRender ? (
                      <Alert color='danger'>{errors._error}</Alert>
                    ) : isPublisherPrePingSaved ? (
                      <Alert color='success'>Publisher Pre-Ping Saved Successfully</Alert>
                    ) : (
                      <></>
                    )}
                  </Col>
                  <Col lg='6' md='6' sm='6'>
                    <SaveEntityModal
                      getErrors={() => errors}
                      onClickSubmit={handleSubmit}
                      isEditMode={isEditMode}
                      entityName={currPubPrePing.name}
                      entityType={'Publisher Pre-Ping'}
                      submitting={submitting}
                    />
                  </Col>
                </Row>
              </form>
            );
          }}
        />
      )}
    </>
  );
}
