import _ from 'lodash';
import React, { useState } from 'react';
import { Field } from 'react-final-form';
import { Combobox } from 'react-widgets';
import { Alert, Button, Col, Label, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import { useContainersTable, useLayoutsTable } from '../queries';

import ToggleActiveModal from './ToggleActiveModal';

const openInNewTab = (url) => {
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
  if (newWindow) newWindow.opener = null;
};

const RightAside = (props) => {
  const [activeTab, setActiveTab] = useState('offerBlock');
  const [activeOfferSubsectionTab, setActiveOfferSubsectionTab] = useState('properties');
  const [activeSequenceSubSectionTab, setActiveSequenceSubSectionTab] = useState('properties');
  const { data: layouts, isLoading: isLayoutsLoading, isError: isLayoutsError } = useLayoutsTable((data) =>
    _.orderBy(data, ['name'], ['asc'])
  );
  const { data: containers, isLoading: isContainersLoading, isError: isContainersError } = useContainersTable((data) =>
    _.orderBy(data, ['name'], ['asc'])
  );

  const filteredContainers = containers?.filter(
    (container) => props.selectedElement?.offerType?.id === container?.offerTypeHint
  );

  const renderOfferMetaData = () => {
    if (!props.selectedElement.offer) {
      return <></>;
    }
    return (
      <>
        <Row>
          <Col lg={9}>
            <h3>{props.selectedElement?.offer?.name}</h3>
          </Col>
          <Col lg={2}>
            <Button
              color='info'
              title={`Open Offer ${props.selectedElement?.offer?.id} in a new tab`}
              onClick={() => openInNewTab(`/campaigns/offertable/offer/${props.selectedElement?.offer?.id}`)}
            >
              <i className='fa fa-search' />
            </Button>
          </Col>
        </Row>
        <br />
        <Row>
          <Col lg={6}>
            <h6>
              <em>Offer ID: </em>
              {props.selectedElement.offer?.id}
            </h6>
          </Col>
          <Col lg={6}>
            <Field required name={`offerBlockOffers[${props.selectedElementIndex}].id`}>
              {({ input, meta: { touched, error } }) => (
                <h6>
                  <em>Offer Block ID: </em>
                  {input.value}
                </h6>
              )}
            </Field>
          </Col>
        </Row>
        <br />
        <Row>
          <Col lg={6}>
            <Label>Offer Type</Label>
            <div style={{ border: '1px solid #23282c', borderRadius: '0.25rem', padding: '0.375rem 0.75rem' }}>
              {props.selectedElement.offer?.offerType.name}
            </div>
          </Col>
          <Col lg={6}>
            <Label>Advertiser</Label>
            <div style={{ border: '1px solid #23282c', borderRadius: '0.25rem', padding: '0.375rem 0.75rem' }}>
              {props.selectedElement.offer?.advertiser?.name}
            </div>
          </Col>
        </Row>
        <br />
        <Row>
          <Col lg={12}>
            <Label>Layout</Label>
            <Field name={`offersPublished.offer_block[${props.selectedElementIndex}].layout_id`}>
              {({ input, meta: { touched, error } }) => {
                return (
                  <>
                    <Combobox
                      {...input}
                      data={layouts}
                      busy={isLayoutsLoading}
                      dataKey='id'
                      textField='name'
                      disabled={isLayoutsError || isLayoutsLoading}
                    />
                    {touched && error && <Alert color='danger'>{error}</Alert>}
                  </>
                );
              }}
            </Field>
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <Label>Container</Label>
            <Field name={`offersPublished.offer_block[${props.selectedElementIndex}].container_id`}>
              {({ input, meta: { touched, error } }) => {
                return (
                  <>
                    <Combobox
                      {...input}
                      data={filteredContainers}
                      busy={isContainersLoading}
                      onBlur={() => input.onBlur()}
                      dataKey='id'
                      textField='name'
                      disabled={isContainersError || isContainersLoading}
                    />
                    {touched && error && <Alert color='danger'>{error}</Alert>}
                  </>
                );
              }}
            </Field>
          </Col>
        </Row>
      </>
    );
  };

  return (
    <>
      <Nav tabs>
        <NavItem>
          <NavLink
            active={activeTab === 'offer'}
            onClick={() => {
              setActiveTab('offer');
            }}
          >
            Offer
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            active={activeTab === 'offerBlock'}
            onClick={() => {
              setActiveTab('offerBlock');
            }}
          >
            Offer Block
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId='offer'>
          <Nav tabs>
            <NavItem>
              <NavLink
                active={activeOfferSubsectionTab === 'properties'}
                onClick={() => {
                  setActiveOfferSubsectionTab('properties');
                }}
              >
                Properties
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent id='offerSubSection' activeTab={activeOfferSubsectionTab}>
            <TabPane tabId='properties'>{renderOfferMetaData()}</TabPane>
          </TabContent>
        </TabPane>
        <TabPane tabId='offerBlock'>
          <Nav tabs>
            <NavItem>
              <NavLink
                active={activeSequenceSubSectionTab === 'properties'}
                onClick={() => {
                  setActiveSequenceSubSectionTab('properties');
                }}
              >
                Properties
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent id='sequenceSubSection' activeTab={activeSequenceSubSectionTab}>
            <TabPane tabId='properties'>
              <Row>
                <Col>
                  <span>Properties </span>
                </Col>
                <Col>
                  <Field name='offer'>
                    {({ input, meta: { touched, error } }) => {
                      return (
                        <ToggleActiveModal
                          isActive={input.value.isActive}
                          modalType={'offerBlock'}
                          content={input.value}
                          endpoint={`offers/active/${input.value.id}`}
                        />
                      );
                    }}
                  </Field>
                </Col>
              </Row>
            </TabPane>
          </TabContent>
        </TabPane>
      </TabContent>
    </>
  );
};
export default RightAside;
