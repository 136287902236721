import { useSelector } from 'react-redux';

/**
 * Checks if the provided value is not null, not an empty string, not an empty array, 'None', 'AND',
 * and not an object with all null or empty values. It recursively checks arrays and objects.
 * @param {any} value - The value to check for emptiness.
 * @returns {boolean} Returns `true` if the value is non-empty, otherwise `false`.
 */
const isValueNotNullOrEmpty = (value) => {
  // Check for boolean true, non-empty string not equal to "None" or "AND", and non-null non-zero number
  if (
    value === true ||
    (typeof value === 'string' && value.trim() !== '' && value.toLowerCase() !== 'none' && value !== 'AND') ||
    (typeof value === 'number' && value !== 0)
  ) {
    return true;
  }
  // Check for non-empty array with at least one non-null element
  if (Array.isArray(value)) {
    return value.some(isValueNotNullOrEmpty);
  }
  // Check for non-null object with at least one non-null value
  if (typeof value === 'object' && value !== null) {
    return Object.values(value).some(isValueNotNullOrEmpty);
  }
  // Return false if none of the above conditions are met
  return false;
};

export const useUserSelectors = () => {
  const selectors = useSelector((state) => ({
    criteriaOfferRequiresOptIn: state.formSelector.selector(state, 'criteriaOfferRequiresOptIn'),
    criteriaTcpaLastOptInInterval: state.formSelector.selector(state, 'criteriaTcpaLastOptInInterval'),
    criteriaPhoneHasBeenVerifiedWithBriteverify: state.formSelector.selector(
      state,
      'criteriaPhoneHasBeenVerifiedWithBriteverify'
    ),
    criteriaEmailHasBeenVerifiedWithBriteverify: state.formSelector.selector(
      state,
      'criteriaEmailHasBeenVerifiedWithBriteverify'
    ),
    criteriaPhoneBlacklist: state.formSelector.selector(state, 'criteriaPhoneBlacklist'),
    criteriaMinAge: state.formSelector.selector(state, 'criteriaMinAge'),
    criteriaMaxAge: state.formSelector.selector(state, 'criteriaMaxAge'),
    browserType: state.formSelector.selector(state, 'criteriaExcludeBrowserType'),
    criteriaExcludeGender: state.formSelector.selector(state, 'criteriaExcludeGender'),
    criteriaUserDataIncludeExclude: state.formSelector.selector(state, 'criteriaUserDataIncludeExclude'),
    criteriaRequiredUserData: state.formSelector.selector(state, 'criteriaRequiredUserData'),
    criteriaAibListIncludeExclude: state.formSelector.selector(state, 'criteriaAibListIncludeExclude'),
    criteriaAibList: state.formSelector.selector(state, 'criteriaAibList'),
    previousRegistrationConfiguration: state.formSelector.selector(state, 'previousRegistrationConfiguration'),
    criteriaNullSourceNewUserIncludeExclude: state.formSelector.selector(
      state,
      'criteriaNullSourceNewUserIncludeExclude'
    ),
    userIdLastDigitIncludeExclude: state.formSelector.selector(state, 'criteriaIncludeOrExcludeUserIdLastDigit'),
    criteriaUserIdLastDigit: state.formSelector.selector(state, 'criteriaUserIdLastDigit'),
    criteriaIncludeOrExcludeDevices: state.formSelector.selector(state, 'criteriaIncludeOrExcludeDevices'),
    criteriaDevices: state.formSelector.selector(state, 'criteriaDevices'),
    criteriaIncludeOrExcludeBrowsers: state.formSelector.selector(state, 'criteriaIncludeOrExcludeBrowsers'),
    criteriaBrowsers: state.formSelector.selector(state, 'criteriaBrowsers'),
    criteriaIncludeOrExcludeDomains: state.formSelector.selector(state, 'criteriaIncludeOrExcludeDomains'),
    criteriaDomains: state.formSelector.selector(state, 'criteriaDomains'),
    criteriaIncludeOrExcludeOperatingSystems: state.formSelector.selector(
      state,
      'criteriaIncludeOrExcludeOperatingSystems'
    ),
    criteriaOperatingSystems: state.formSelector.selector(state, 'criteriaOperatingSystems'),
    criteriaIncludeOrExcludeStates: state.formSelector.selector(state, 'criteriaIncludeOrExcludeStates'),
    criteriaStates: state.formSelector.selector(state, 'criteriaStates'),
    criteriaIncludeOrExcludeZips: state.formSelector.selector(state, 'criteriaIncludeOrExcludeZips'),
    criteriaZips: state.formSelector.selector(state, 'criteriaZips'),
  }));
  // Check if every value in the selectors object is not null, not an empty string, and not an empty array
  const isAnySelectorNotNullOrEmpty = Object.values(selectors).some(isValueNotNullOrEmpty);

  return {
    ...selectors,
    isAnySelectorNotNullOrEmpty,
  };
};

export const useAttributeSelectors = () => {
  const selectors = useSelector((state) => ({
    criteriaAffiliate: state.formSelector.selector(state, 'criteriaIncludeOrExcludeAffNames'),
    criteriaEmail: state.formSelector.selector(state, 'criteriaIncludeOrExcludeEmailDomains'),
    criteriaIncludeOrExcludeAffNames: state.formSelector.selector(state, 'criteriaIncludeOrExcludeAffNames'),
    criteriaAffNames: state.formSelector.selector(state, 'criteriaAffNames'),
    domainAffiliateConfiguration: state.formSelector.selector(state, 'domainAffiliateConfiguration'),
    criteriaLandingPageGroupIncludeExclude: state.formSelector.selector(
      state,
      'criteriaLandingPageGroupIncludeExclude'
    ),
    criteriaLandingPageGroupIds: state.formSelector.selector(state, 'criteriaLandingPageGroupIds'),
    criteriaLandingPageIncludeExclude: state.formSelector.selector(state, 'criteriaLandingPageIncludeExclude'),
    criteriaLandingPageIds: state.formSelector.selector(state, 'criteriaLandingPageIds'),
    criteriaIncludeOrExcludeEmailDomains: state.formSelector.selector(state, 'criteriaIncludeOrExcludeEmailDomains'),
    criteriaEmailDomains: state.formSelector.selector(state, 'criteriaEmailDomains'),
    selectedQuestion: state.formSelector.selector(state, 'criteriaQuestion'),
    criteriaQuestionAnswers: state.formSelector.selector(state, 'criteriaQuestionAnswers'),
    userAttributeRules: state.formSelector.selector(state, 'userAttributeRules'),
    userAttributeSelect: state.formSelector.selector(state, 'criteriaIncludeOrExcludeUserAttributes'),
  }));

  const isAnySelectorNotNullOrEmpty = Object.values(selectors).some(isValueNotNullOrEmpty);

  return {
    ...selectors,
    isAnySelectorNotNullOrEmpty,
  };
};

export const usePrepingSelectors = () => {
  const selectors = useSelector((state) => ({
    suppressionRulesDurationType: state.formSelector.selector(state, 'suppressionRules.duration.type'),
    suppressionRulesDurationAmount: state.formSelector.selector(state, 'suppressionRules.duration.amount'),
    suppressionRulesSuppressionType: state.formSelector.selector(state, 'suppressionRules.suppression_type'),
    suppressionRulesDurationMaxLimit: state.formSelector.selector(state, 'suppressionRules.duration.max_limit'),
    suppressionRulesAdditionalOfferIds: state.formSelector.selector(state, 'suppressionRules.additional_offer_ids'),
    suppressionRuleAnswer: state.formSelector.selector(state, 'suppressionRules.suppression_answer'),
    suppressionRulesAdditionalOfferActionIds: state.formSelector.selector(
      state,
      'suppressionRules.additional_offer_action_ids'
    ),
    prePingResultSessionCapture: state.formSelector.selector(state, 'prePingResultSessionCapture'),
    prePingMethod: state.formSelector.selector(state, 'prePingMethod'),
    prePingHandlerId: state.formSelector.selector(state, 'prePingHandlerId'),
    prePing: state.formSelector.selector(state, 'prePing'),
    prePingBody: state.formSelector.selector(state, 'prePingBody'),
    prePingTimeout: state.formSelector.selector(state, 'prePingTimeout'),
    prePingHeader1: state.formSelector.selector(state, 'prePingHeader1'),
    prePingHeader2: state.formSelector.selector(state, 'prePingHeader2'),
    prePingHeader3: state.formSelector.selector(state, 'prePingHeader3'),
    prePingCustomHttpStatus: state.formSelector.selector(state, 'prePingCustomHttpStatus'),
  }));

  const isAnySelectorNotNullOrEmpty = Object.values(selectors).some(isValueNotNullOrEmpty);

  return {
    ...selectors,
    isAnySelectorNotNullOrEmpty,
  };
};

export const useDailyRestrictionSelectors = () => {
  const selectors = useSelector((state) => ({
    criteriaDailyLimit: state.formSelector.selector(state, 'criteriaDailyLimit'),
    criteriaDailyLimitAnswers: state.formSelector.selector(state, 'criteriaDailyLimitAnswers'),
    capInterval: state.formSelector.selector(state, 'capInterval'),
    capAmount: state.formSelector.selector(state, 'capAmount'),
    capSegmentConfiguration: state.formSelector.selector(state, 'capSegmentConfiguration'),
    criteriaDayHourRestrictions: state.formSelector.selector(state, 'criteriaDayHourRestrictions'),
    offerCriteriaId: state.formSelector.selector(state, 'offerCriteriaId'),
  }));

  const isAnySelectorNotNullOrEmpty = Object.values(selectors).some(isValueNotNullOrEmpty);

  return {
    ...selectors,
    isAnySelectorNotNullOrEmpty,
  };
};
