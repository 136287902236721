import React, { useCallback, useEffect } from 'react';
import { Button, Card, Col, Row } from 'reactstrap';
import { SelectField } from '../../components/FinalForm';
import DropdownValueItem from './DropdownValueItem';
import { valueInputOptions } from './utils';

const ValueInputSelector = ({
  dropdownValues,
  isModalOpen,
  setDropdownValues,
  setResults,
  setValidateState,
  row,
  validateState,
  values,
}) => {
  // Initialize dropdown values when editing
  useEffect(() => {
    if (row && row.inputMethod === 'DROPDOWN' && Array.isArray(row.inputValues)) {
      const initialValues = row.inputValues.map((value, index) => ({
        id: index,
        value: value,
      }));
      setDropdownValues(initialValues.length > 0 ? initialValues : [{ id: 0, value: '' }]);
    } else {
      setDropdownValues([{ id: 0, value: '' }]);
    }
  }, [row]);

  // Reset dropdown values when modal closes
  useEffect(() => {
    if (!isModalOpen) {
      setDropdownValues([{ id: 0, value: '' }]);
      setValidateState({ isDuplicate: {}, isEmpty: {} });
    }
  }, [isModalOpen]);

  const handleDropdownValueChange = useCallback(
    (e, id) => {
      const value = e.target.value;
      const updatedValues = [...dropdownValues];
      const valueIndex = updatedValues.findIndex((item) => item.id === id);
      if (valueIndex !== -1) {
        updatedValues[valueIndex] = { ...updatedValues[valueIndex], value };
        setDropdownValues(updatedValues);
        setResults(updatedValues);
      }
      setValidateState({
        isDuplicate: {},
        isEmpty: {},
      });
    },
    [dropdownValues]
  );

  const addDropdownValue = () => {
    let newId = dropdownValues.length;
    while (dropdownValues.find((item) => item.id === newId)) {
      newId++;
    }
    setDropdownValues([...dropdownValues, { id: newId, value: '' }]);
  };

  const removeDropdownValue = useCallback(
    (id) => {
      if (dropdownValues.length > 1) {
        const newList = dropdownValues.filter((item) => item.id !== id);
        setDropdownValues(newList);
        setResults(newList);
      }
    },
    [dropdownValues]
  );

  return (
    <div className='mt-3'>
      <Row className='align-items-center mb-3'>
        <Col md={6}>
          <h5>Value Input Method</h5>
        </Col>
        <Col md={6}>
          <SelectField name='inputMethod' options={valueInputOptions} />
        </Col>
      </Row>
      {values.inputMethod === 'DROPDOWN' && (
        <Card style={{ height: '300px', overflowY: 'scroll', margin: '10px 0', padding: '0 1rem' }}>
          {dropdownValues.map((item) => (
            <DropdownValueItem
              key={item.id}
              item={item}
              handleDropdownValueChange={handleDropdownValueChange}
              removeDropdownValue={removeDropdownValue}
              dropdownValuesLength={dropdownValues.length}
              validateState={validateState}
            />
          ))}
          <Row style={{ display: 'flex', padding: '10px 0' }}>
            <Col lg={12} style={{ textAlign: 'right' }}>
              <Button color='success' onClick={addDropdownValue}>
                +
              </Button>
            </Col>
          </Row>
        </Card>
      )}
    </div>
  );
};

export default ValueInputSelector;
