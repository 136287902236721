import React, { useState, useEffect } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { useLayout, useUpdateLayout } from '../../views/Layouts/queries';
import { useContainer, useUpdateContainer } from '../../views/Containers/queries';
import { useOffer, useUpdateOffer } from '../../views/Offers/queries';
import toast from 'react-hot-toast';

const RevertWrapper = ({ change, id, typeName }) => {
  const [showModal, setShowModal] = useState(false);

  function getWrapperComponent(typeName) {
    switch (typeName) {
      case 'Layout':
        return LayoutWrapper;
      case 'Container':
        return ContainerWrapper;
      case 'Offer':
        return OfferWrapper;
      default:
        return LayoutWrapper;
    }
  }

  const TypeWrapper = getWrapperComponent(typeName);

  return (
    <div className='d-flex justify-items-between'>
      <Button
        color='secondary'
        id='save-version'
        className='my-2 md-my-0'
        size='sm'
        onClick={(e) => {
          e.stopPropagation();
          setShowModal(true);
        }}
      >
        Revert this change
      </Button>
      <TypeWrapper id={id}>
        <RevertConfirmation
          isOpen={showModal}
          change={change}
          id={id}
          typeName={typeName}
          onClose={() => setShowModal(false)}
        />
      </TypeWrapper>
    </div>
  );
};

const RevertConfirmation = ({ isOpen, change, onClose, updateFunction, templateData, id, typeName }) => {
  const zuulUser = JSON.parse(localStorage.getItem('userData'));
  const [visible, setVisible] = useState(isOpen || false);
  const jsonMap = change.jsonMap;
  const [changeDataKey, changeDataValue] = Object.entries(jsonMap)[0];
  console.log('jsonMap', jsonMap);
  console.log('changeDataKey', changeDataKey);
  console.log('changeDataValue', changeDataValue);

  if (typeName === 'Layout' || typeName === 'Container') templateData = { ...templateData, lastPublishedBy: zuulUser };

  const saveChanges = () => {
    if (updateFunction && templateData && changeDataKey && changeDataValue) {
      toast.promise(updateFunction({ ...templateData, id, [changeDataKey]: changeDataValue }), {
        loading: `Updating ${typeName.toLowerCase()}...`,
        success: (data) => (data.status === 200 ? `${typeName} updated successfully!` : 'Updated successfully!'),
        error: (err) => err?.response?.data?.message || err.message,
      });
    }
  };

  useEffect(() => {
    setVisible(isOpen);
  }, [isOpen]);

  return (
    <Modal isOpen={visible} className='modal-info'>
      <ModalHeader>Save Revert Comfirmation</ModalHeader>
      <ModalBody>Are you sure you want to revert this change?</ModalBody>
      <ModalFooter>
        <Button
          onClick={() => {
            saveChanges();
            onClose();
          }}
          color='primary'
        >
          Confirm
        </Button>
        <Button onClick={onClose}>Cancel</Button>
      </ModalFooter>
    </Modal>
  );
};

const LayoutWrapper = ({ id, children }) => {
  const { data: templateData } = useLayout(id);
  const { mutateAsync: updateFunction } = useUpdateLayout();

  return (
    <div>
      {React.Children.map(children, (child) =>
        React.isValidElement(child) ? React.cloneElement(child, { templateData, updateFunction }) : child
      )}
    </div>
  );
};

const ContainerWrapper = ({ id, children }) => {
  const { data: templateData } = useContainer(id);
  const updateFunction = useUpdateContainer().mutateAsync;

  return (
    <div>
      {React.Children.map(children, (child) =>
        React.isValidElement(child) ? React.cloneElement(child, { templateData, updateFunction }) : child
      )}
    </div>
  );
};

const OfferWrapper = ({ id, children }) => {
  const { data: templateData } = useOffer(id);
  const updateFunction = useUpdateOffer().mutateAsync;
  console.log('offerdata', templateData);
  return (
    <div>
      {React.Children.map(children, (child) =>
        React.isValidElement(child) ? React.cloneElement(child, { templateData, updateFunction }) : child
      )}
    </div>
  );
};

export default RevertWrapper;
