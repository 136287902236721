import { validateCriteria } from '../../utils/CriteriaHelpers';

export default function validate(values) {
  const errors = {};
  const { publisherPrePingCriteria } = values;
  errors.publisherPrePingCriteria = {};

  const criteriaErrors = { publisherPrePingCriteria: validateCriteria(publisherPrePingCriteria) };
  return { ...errors, ...criteriaErrors };
}
