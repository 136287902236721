import {
  GET_WALL_OFFERS,
  SWAP_OFFER_WALL_SLOTS,
  GET_OFFER_WALL_OFFER,
  UPDATE_SELECTED_SLOTS,
  UPDATE_WALL_OFFERS,
  UPDATE_SELECTED_OFFERS
} from '../actions/types';
const initialState = {
  wallOffers: [],
  WALLOFFERS: [],
  selectedOffers: [],
  critQuestionAnswers: []
};
export default function(state = initialState, action) {
  switch (action.type) {
    case GET_OFFER_WALL_OFFER:
      return { ...state, offerWallOffer: action.payload };
    case GET_WALL_OFFERS:
      const WALLOFFERS = action.payload;
      return { ...state, WALLOFFERS };
    case SWAP_OFFER_WALL_SLOTS:
      const newOfferWallSlots = Array.from(state.selectedOffers);
      const [sourceIndex, destinationIndex] = action.payload;
      const sourceOfferWallSlot = state.selectedOffers[sourceIndex];
      newOfferWallSlots.splice(sourceIndex, 1);
      newOfferWallSlots.splice(destinationIndex, 0, sourceOfferWallSlot);
      return { ...state, selectedOffers: newOfferWallSlots };
    case UPDATE_SELECTED_OFFERS:
      const [index, selectedOfferSlotArr] = action.payload;
      const selectedOffers = [...state.selectedOffers];
      selectedOffers[index] = selectedOfferSlotArr;
      return { ...state, selectedOffers };
    default:
      return state;
  }
}
