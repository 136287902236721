import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';

import { Button, Col, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { renderDropdownList, renderField, textarea } from '../Offers/OfferTitle/renderConsts';
import states from '../../utils/states';
import { updateSelector } from '../../actions';
import './Advertisers.css';
import './ARIAAdvertiserOverrides.css';
import { CreatableAriaAdvertiserSelectComponent } from '@aria/aria-mapping-ui';
import { ARIA_API_BASE_URL, ARIA_API_KEY } from '../../utils/ariaApiHelper';

class AddAdvertiserModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      success: false,
      areAdditionalFieldsHidden: true,
    };
    this.toggleSuccess = this.toggleSuccess.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  setAriaAdvertiserId = (value) => {
    this.setState({
      ariaAdvertiserId: value,
    });
  };

  onSubmit(values) {
    this.setState({
      success: !this.state.success,
    });

    const ariaAdvertiserId = this.state.ariaAdvertiserId;
    values = { ...values, ariaAdvertiserId };

    this.props.onClickSubmit(values, true).then(() => this.props.reset());
  }

  toggleSuccess() {
    this.setState({
      success: !this.state.success,
      // This hides additionalFields if user clicked addt'l fields then closed modal without toggling addt'l fields again
      areAdditionalFieldsHidden: !(this.state.success === true && !this.state.areAdditionalFieldsHidden),
    });
  }

  handleAdditionalFieldsToggle = () => {
    this.setState({
      areAdditionalFieldsHidden: !this.state.areAdditionalFieldsHidden,
    });
  };

  renderAdvertiserForm = () => {
    const { toggleSuccess, onSubmit } = this;
    const { dirty, advertiserName, handleSubmit } = this.props;
    const { ariaAdvertiserId } = this.state;
    return (
      <form onSubmit={handleSubmit(onSubmit)}>
        <ModalBody>
          <div className='colorTest'>
            <Row>
              <Col lg={6}>
                <br />
                <Label>Advertiser Name</Label>
                <Field name='name' component={renderField} placeholder='Advertiser' />
              </Col>
              <Col lg={6}>
                <br />
                <Label>URL</Label>
                <Field required type='text' component={renderField} name='url' placeholder='URL' />
              </Col>
            </Row>
            <Row>
              <Col lg={12}>
                <Label>ARIA Advertiser</Label>
                <CreatableAriaAdvertiserSelectComponent
                  apiBaseUrl={ARIA_API_BASE_URL}
                  apiKey={ARIA_API_KEY}
                  setParentIdValue={this.setAriaAdvertiserId}
                />
              </Col>
            </Row>
            <br />
            <Row>
              <Col lg={12}>
                <Label>Notes</Label>
                <Field name='notes' component={textarea} rows='10' type='text' />
              </Col>
            </Row>
            <br />
            <Row>
              <Col lg={12}>
                <Button className='float-left' onClick={this.handleAdditionalFieldsToggle}>
                  <em>Additional Fields</em>
                </Button>
              </Col>
            </Row>
            <br />
            {!this.state.areAdditionalFieldsHidden && (
              <>
                <Row>
                  <Col lg={6}>
                    <div>
                      <Field
                        required
                        type='text'
                        component={renderField}
                        name='contactFirstName'
                        placeholder='Contact First Name'
                        label='Contact First Name'
                      />
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div>
                      <Field
                        required
                        type='text'
                        component={renderField}
                        name='contactLastName'
                        placeholder='Contact Last Name'
                        label='Contact Last Name'
                      />
                    </div>
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col lg={6}>
                    <div>
                      <Field
                        required
                        type='email'
                        component={renderField}
                        name='contactEmail'
                        placeholder='Email'
                        label='Contact Email'
                      />
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div>
                      <Field
                        required
                        type='text'
                        component={renderField}
                        name='contactPhone'
                        placeholder=' Phone'
                        label='Contact Phone'
                      />
                    </div>
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col lg={6}>
                    <div>
                      <Field
                        required
                        type='text'
                        component={renderField}
                        name='address1'
                        placeholder='Address 1'
                        label='Address 1'
                      />
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div>
                      <Field
                        required
                        type='text'
                        component={renderField}
                        name='address2'
                        placeholder='Address 2'
                        label='Address 2'
                      />
                    </div>
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col lg={3}>
                    <div>
                      <Field required type='text' component={renderField} name='city' placeholder='City' label='City' />
                    </div>
                  </Col>
                  <Col lg={3}>
                    <div>
                      <Label>State</Label>
                      <Field
                        required
                        type='select'
                        component={renderDropdownList}
                        data={states}
                        name='state'
                        placeholder='State'
                      />
                    </div>
                  </Col>
                  <Col lg={3}>
                    <div>
                      <Field required type='text' component={renderField} name='zip' placeholder='Zip' label='Zip' />
                    </div>
                  </Col>
                  <Col lg={3}>
                    <div>
                      <Field
                        required
                        type='text'
                        component={renderField}
                        name='country'
                        placeholder='Country'
                        label='Country'
                      />
                    </div>
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col lg={4}>
                    <div>
                      <Field
                        required
                        type='text'
                        component={renderField}
                        name='phone1'
                        placeholder='Phone 1'
                        label='Phone 1'
                      />
                    </div>
                  </Col>
                  <Col lg={4}>
                    <div>
                      <Field
                        required
                        type='text'
                        component={renderField}
                        name='phone2'
                        placeholder='Phone 2'
                        label='Phone 2'
                      />
                    </div>
                  </Col>
                  <Col lg={4}>
                    <div>
                      <Field
                        required
                        type='text'
                        component={renderField}
                        name='phone3'
                        placeholder='Phone 3'
                        label='Phone 3'
                      />
                    </div>
                  </Col>
                </Row>
              </>
            )}
            <br />
          </div>
        </ModalBody>
        <ModalFooter>
          {dirty && advertiserName && ariaAdvertiserId && (
            <Button color='success' onClick={this.toggleSubmit}>
              Save
            </Button>
          )}
          <Button color='secondary' onClick={toggleSuccess}>
            Cancel
          </Button>
        </ModalFooter>
      </form>
    );
  };

  componentDidMount() {
    this.props.updateSelector('advertiserForm');
  }

  render() {
    const { toggleSuccess } = this;
    let { action } = this.props;

    return (
      <div id='add-advertiser-modal' className='animated'>
        <Button color='primary' onClick={toggleSuccess}>
          <i className='fa fa-plus' /> Add Advertiser
        </Button>
        &nbsp;&nbsp;&nbsp;&nbsp;
        <Modal isOpen={this.state.success} toggle={toggleSuccess} className='modal-info'>
          <ModalHeader toggle={toggleSuccess}>{action} Add Advertiser</ModalHeader>
          {this.renderAdvertiserForm()}
        </Modal>
      </div>
    );
  }
}

const form = reduxForm({
  form: 'advertiserForm',
});

function mapStateToProps(state) {
  const { selector } = state.advertisers;
  const advertiserName = selector(state, 'name');
  return {
    advertiserName,
  };
}

export default connect(mapStateToProps, {
  updateSelector,
})(form(AddAdvertiserModal));
