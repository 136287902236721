import {
  UPDATE_SELECTOR,
  GET_OFFER_ACTION,
  GET_ENTITIES_BY_ACTION_ID,
  GET_OFFER_ACTIONS,
  GET_OFFER_ACTION_SUBTYPE,
  GET_OFFER_ACTION_POSTS,
  GET_OFFER_ACTION_LINKOUTS,
  GET_MARKETING_PARTNERS_OFFER_ACTIONS,
  SET_OFFER_ACTION,
  SET_OFFER_ACTIONS,
  UPDATE_OFFER_ACTIONS,
  UPDATE_OFFER_ACTION_POSTS,
  UPDATE_SELECTED_POSTS,
  UPDATE_MARKETING_PARTNERS_OFFER_ACTIONS,
  GET_OFFER_ACTION_CRITERIA,
  ADD_OFFER_ACTION_CRITERIA,
  ADD_OFFER_ACTION,
  EDIT_OFFER_ACTION,
  FETCH_ALL_ACTIONS_TABLE,
  UPDATE_OFFER_ACTION_LINKOUTS,
} from '../actions/types';
import { formValueSelector } from 'redux-form';

const initialState = {
  selector: formValueSelector('offerActionDetail'),
  offerActionSubtype: {},
  offerActionSubtypes: {},
  offerActions: [],
  offerActionPosts: [],
  offerActionLinkouts: [],
  locations: {},
  selectedPosts: [],
  marketingPartners: [],
  updatedMarketingPartners: {},
  title: 'Offer Action Title',
};

export default function(state = initialState, action) {
  switch (action.type) {
    case UPDATE_SELECTOR:
      return { ...state, selector: formValueSelector(action.payload) };
    case GET_OFFER_ACTION:
      return { ...state, offerAction: action.payload };
    case GET_ENTITIES_BY_ACTION_ID:
      const [id, entities] = action.payload;
      const locations = { ...state.locations, [id]: entities };
      return { ...state, locations };
    case GET_OFFER_ACTIONS:
      return { ...state, refetchedOfferActions: action.payload };
    case GET_OFFER_ACTION_SUBTYPE:
      return { ...state, offerActionSubtype: action.payload };
    case GET_OFFER_ACTION_POSTS:
      return { ...state, offerActionPosts: action.payload };

    case GET_OFFER_ACTION_LINKOUTS:
      return { ...state, offerActionLinkouts: action.payload };
    case SET_OFFER_ACTION:
      return {
        ...state,
        offerAction: action.payload,
      };
    case SET_OFFER_ACTIONS:
      return {
        ...state,
        offerActions: action.payload,
      };
    case UPDATE_OFFER_ACTIONS:
      const offerActions = [...state.offerActions, ...action.payload];
      return {
        ...state,
        offerActions,
      };
    case UPDATE_OFFER_ACTION_POSTS:
      return { ...state, offerActionPosts: action.payload };
    case UPDATE_OFFER_ACTION_LINKOUTS:
      return { ...state, offerActionLinkouts: action.payload };
    case UPDATE_SELECTED_POSTS:
      return { ...state, selectedPosts: action.payload };
    case GET_OFFER_ACTION_CRITERIA:
      return { ...state, offerActionCriteria: action.payload };
    case ADD_OFFER_ACTION_CRITERIA:
      return { ...state, offerActionCriteria: action.payload };
    case ADD_OFFER_ACTION:
      return { ...state, savedOfferAction: action.payload };
    case EDIT_OFFER_ACTION:
      return { ...state, savedOfferAction: action.payload };
    case FETCH_ALL_ACTIONS_TABLE:
      console.log(action.payload);
      return { ...state, offerActions: action.payload[0], offerActionSubtypes: action.payload[1] };
    case GET_MARKETING_PARTNERS_OFFER_ACTIONS:
      return { ...state, marketingPartners: action.payload };
    case UPDATE_MARKETING_PARTNERS_OFFER_ACTIONS:
      return {
        ...state,
        updatedMarketingPartners: { ...state.updatedMarketingPartners, [action.payload.id]: action.payload },
      };

    default:
      return state;
  }
}
