import React from 'react';
import { Input } from 'reactstrap';
import { useCurrentCapAmount } from './queries';

export default function CurrentCapAmount({ id }) {
  const { data } = useCurrentCapAmount(id);

  return (
    <div>
      <label>Current Cap Amount</label>
      <Input disabled={true} type='number' value={data} />
      <br />
    </div>
  );
}
