import React, { useState } from 'react';
import { Button, Card, Col, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import api from '../../../services';

const ActiveUserAttributeModal = (props) => {
  let modalTitle = 'Create a New User Attribute';
  const [modal, setModal] = useState(false);
  const [userAttributeType, setUserAttributeType] = useState('');
  const [userAttributeTypeHint, setUserAttributeTypeHint] = useState('');
  const [attributeName, setAttributeName] = useState('');
  const [userAttributes, setUserAttributes] = useState([{ id: 0, value: '' }]);
  const isValidName = (name) => !!name && name.length > 0 && /^[a-zA-Z0-9_]+$/i.test(name);
  const [validateState, setValidateState] = useState({
    isDuplicate: [],
    isEmpty: [],
    nameIsInvalid: false,
  });
  const affiliateTypeList = [
    { value: '', option: 'Select' },
    { value: 'list', option: 'List' },
    { value: 'boolean', option: 'True/False (Legacy)' },
  ];
  const affiliateTypeHintsList = [
    { value: '', option: 'Select' },
    { value: 'STRING', option: 'String' },
    { value: 'INTEGER', option: 'Number' },
    { value: 'FLOAT', option: 'Money' },
    { value: 'DATE', option: 'Date' },
  ];

  const toggle = () => {
    resetValues();
    setValidateState({
      ...validateState,
    });
    setModal(!modal);
  };

  const handleTypeChange = (event) => {
    setValidateState({ isDuplicate: [], isEmpty: [], nameIsInvalid: false });
    setUserAttributeType(event.target.value);
    setUserAttributes([{ id: 0, value: '' }]);
  };

  const resetValues = () => {
    setUserAttributes([{ id: 0, name: '' }]);
    setUserAttributeTypeHint('');
    setUserAttributeType('');
    setAttributeName('');
  };

  const validateFields = () => {
    let isValid = true;
    let isDuplicate = [];
    let isEmpty = [];
    let nameIsInvalid = false;
    if (attributeName === '') {
      isEmpty = { ...isEmpty, name: true };
      isValid = false;
    }

    if (!isValidName(attributeName)) {
      nameIsInvalid = true;
      isValid = false;
    }

    if (userAttributeType === 'list') {
      if (userAttributeTypeHint === '') {
        isEmpty = { ...isEmpty, type: true };
        isValid = false;
      }
      for (let i = 0; i < userAttributes.length; i++) {
        if (userAttributes[i].value === '') {
          isEmpty = {
            ...isEmpty,
            [userAttributes[i].id]: true,
          };
          isValid = false;
        }
        if (userAttributes.filter((attribute) => attribute.value === userAttributes[i].value).length > 1) {
          isDuplicate = { ...isDuplicate, [userAttributes[i].id]: true };
          isValid = false;
        }
      }
    }
    if (userAttributes.length === 0) {
      isEmpty = { ...isEmpty, hasNoAttributes: true };
      isValid = false;
    }
    setValidateState({ isDuplicate, isEmpty, nameIsInvalid });
    return isValid;
  };

  const handleSave = () => {
    if (!validateFields()) return;

    if (validateFields()) {
      if (userAttributeType === 'list') {
        const attributeListValues = userAttributes.map((attribute) => attribute.value);
        const valueStrings = [];

        for (let i = 0; i < attributeListValues.length; i++) {
          const object = { valueString: attributeListValues[i] };
          valueStrings.push(object);
        }

        const userAttributeValueStrings = { userAttributesValueString: valueStrings };
        const userAttribute = {
          name: attributeName,
          valueStringTypeHint: userAttributeTypeHint,
        };
        const saveData = { ...userAttribute, ...userAttributeValueStrings };
        
        
        api.post('user-attributes-value-string', saveData).then((result) => {
          props.refreshTable();
        }).catch((error) => {
          console.error('Error saving user attribute: ', error);
        });
        

        toggle();
      } else {
        let saveData = {
          id: 0,
          name: attributeName,
        };


        api.post('user-attribute', saveData).then((result) => {
          props.refreshTable();
        }).catch((error) => {
          console.error('Error saving user attribute: ', error);
        });
        toggle();

      }
    }
  };

  const handleValueChange = (e, id) => {
    const value = e.target.value;
    const updatedAttributes = [...userAttributes];
    for (let i = 0; i < updatedAttributes.length; i++) {
      if (updatedAttributes[i].id === id) {
        updatedAttributes[i] = { ...updatedAttributes[i], value };
        break;
      }
    }
    setUserAttributes(updatedAttributes);
  };

  const AttributeType = ({ affiliateTypeList }) => {
    return (
      <Row className='my-2'>
        <Col lg={12}>
          <Label>User Attribute Type:</Label>
          <Input type='select' placeholder='Type' value={userAttributeType} onChange={handleTypeChange}>
            {affiliateTypeList &&
              affiliateTypeList.map((type) => (
                <option key={type.value} value={type.value}>
                  {type.option}
                </option>
              ))}
          </Input>
        </Col>
      </Row>
    );
  };

  return (
    <span>
      <Button color='primary' onClick={toggle} className='float-right'>
        <i className='fa fa-plus'></i>&nbsp; Add User Attribute
      </Button>
      <Modal isOpen={modal} className={props.className}>
        <ModalHeader toggle={toggle}>{modalTitle}</ModalHeader>
        <ModalBody>
          <h5>
            <i>Enter user attribute:</i>
          </h5>
          <Row>
            <Col lg={12}>
              <AttributeType affiliateTypeList={affiliateTypeList} />
              {userAttributeType !== '' && (
                <Row>
                  <Col lg={12}>
                    <Label>User Attribute Name:</Label>
                    <Input type={'text'} placeholder={'Name'} onChange={(e) => setAttributeName(e.target.value)} />
                    {validateState.isEmpty.name && <ErrorMessage type={'empty'} />}
                    {!validateState.isEmpty.name && validateState.nameIsInvalid && <ErrorMessage type={'invalidName'} />}
                    {userAttributeType === 'list' && (
                      <div className={'my-2'}>
                        <Label>User Attribute ValueTypeHint:</Label>
                        <Input
                          type='select'
                          value={userAttributeTypeHint}
                          onChange={(e) => setUserAttributeTypeHint(e.target.value)}
                        >
                          {affiliateTypeHintsList &&
                            affiliateTypeHintsList.map((type) => (
                              <option key={type.value} value={type.value}>
                                {type.option}
                              </option>
                            ))}
                        </Input>
                        {validateState.isEmpty.type && <ErrorMessage type={'empty'} />}
                      </div>
                    )}
                  </Col>
                </Row>
              )}
              {userAttributeType === 'list' && (
                <Card style={{ height: '300px', overflowY: 'scroll', margin: '10px 0', padding: '0 1rem' }}>
                  {validateState.isEmpty.hasNoAttributes && (
                    <Row>
                      <Col lg={12}>
                        <ErrorMessage type={'empty'} message={'At least one attribute is required.'} />
                      </Col>
                    </Row>
                  )}
                  {userAttributes.map((item) => {
                    return (
                      <AttributeItem
                        key={item.id}
                        item={item}
                        handleValueChange={handleValueChange}
                        state={validateState}
                        setUserAttributes={setUserAttributes}
                        userAttributes={userAttributes}
                      />
                    );
                  })}
                  <Row style={{ display: 'flex', padding: '10px 0' }}>
                    <Col lg={12} style={{ textAlign: 'right' }}>
                      <Button
                        color={'success'}
                        onClick={() => {
                          let newId = userAttributes.length;
                          while (userAttributes.find((attribute) => attribute.id === newId)) {
                            newId++;
                          }
                          setUserAttributes([...userAttributes, { id: newId, value: '' }]);
                        }}
                      >
                        +
                      </Button>
                    </Col>
                  </Row>
                </Card>
              )}
            </Col>
          </Row>
          <br />
        </ModalBody>
        <ModalFooter>
          {userAttributeType !== '' && (
            <Button color='success' onClick={handleSave}>
              Save
            </Button>
          )}
          <Button color='secondary' onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </span>
  );
};

const AttributeItem = ({ item, handleValueChange, state, setUserAttributes, userAttributes }) => {
  return (
    <Row className='d-flex align-items-end my-2'>
      <Col lg={2}>
        <Button
          className='ml-2'
          color={'danger'}
          onClick={() => setUserAttributes(userAttributes.filter((attribute) => attribute.id !== item.id))}
        >
          <i className='fa fa-trash'></i>
        </Button>
      </Col>
      <Col lg={10}>
        <Label>Attribute Value:</Label>
        <Input placeholder='Attribute Value' onChange={(e) => handleValueChange(e, item.id)} />
        {state.isDuplicate[item.id] && <ErrorMessage type={'duplicate'} />}
        {state.isEmpty[item.id] && <ErrorMessage type={'empty'} />}
      </Col>
    </Row>
  );
};

const ErrorMessage = ({ type, message }) => {
  let errorMessage = '';
  switch (type) {
    case 'empty':
      errorMessage = message ? message : 'This Field Is Required';
      break;
    case 'duplicate':
      errorMessage = message ? message : 'User Attribute Already Exist';
      break;
    case 'invalidName':
      errorMessage = message ? message : 'Name is invalid. Please only use letters and numbers, separated by underscores.';
      break;
    default:
      return null;
  }

  return (
    <Col lg={12} style={{ color: '#f86c6b', fontSize: '12px', padding: '5px' }}>
      {errorMessage}
    </Col>
  );
};

export default ActiveUserAttributeModal;
