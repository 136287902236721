export default function validate(values, props) {
  const errors = {};
  // Error Checks
  if (!values.landingPageName) {
    errors.landingPageName = 'Please add a landing page name.';
  }

  if (!values.layout || typeof values.layout !== 'object') {
    errors.layout = 'Please select a layout.';
  }

  if (!values.container || typeof values.container !== 'object') {
    errors.container = 'Please select a container.';
  }

  if (values.customVariables) {
    errors.customVariables = [];
    values.customVariables.forEach((customVariable, index) => {
      if (customVariable.key === 'UD_' || customVariable.key === '') {
        errors.customVariables[index] = { key: 'Please enter a key.' };
      }
    });
  }

  return errors;
}
