import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { fetchAll } from '../../services/helpers';
import api from '../../services';

const EXCLUDED_OFFER_TYPES = [6, 8, 9];

const fetchOfferVariations = async (id) => {
  try {
    const response = await api.get(`offervariations/by-offer/${id}`);
    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};

const fetchOffer = async (id) => {
  try {
    const response = await api.get(`offers/${id}`);
    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};

const fetchFlexByOfferID = async (id) => {
  try {
    const response = await api.get(`offervariations/get-flex/${id}`);
    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};

const createOfferVariation = async ({ offerId, name, flex }) => {
  try {
    const params = new URLSearchParams({
      offerId: offerId,
      name: name,
      flex: flex,
    }).toString();

    const response = await api.post(`/offervariations/create-from-existing?${params}`);
    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const useOffersTable = () =>
  useQuery({
    queryKey: ['offersTable'],
    queryFn: () => fetchAll('offers/table'),
    select: (data) => data.filter((item) => !EXCLUDED_OFFER_TYPES.includes(item.offerType.id)),
    refetchOnWindowFocus: false,
  });

export const useOfferSummaries = () =>
  useQuery({
    queryKey: ['offerSummaries'],
    queryFn: () => fetchAll('offers/summaries'),
    refetchOnWindowFocus: false,
  });

export const useOfferVariantsByPrimaryOfferId = (primaryOfferId, enabled = false) =>
  useQuery({
    queryKey: ['offerVariants', primaryOfferId],
    queryFn: () => fetchOfferVariations(primaryOfferId),
    enabled,
  });

export const useOffer = (id, enabled = true) =>
  useQuery({
    queryKey: ['offer', id],
    queryFn: () => fetchOffer(id),
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    enabled,
  });

export const useUpdateOffer = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data) => api.put(`offers/${data.id}`, data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['offer'] });
    },
  });
};

export const useFlexByOfferID = (id, enabled = false) =>
  useQuery({
    queryKey: ['flex', id],
    queryFn: () => fetchFlexByOfferID(id),
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    enabled,
  });

export const useCreateOfferVariation = (onSuccess) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: createOfferVariation,
    onSuccess: (...args) => {
      queryClient.invalidateQueries('offerVariants');
      if (onSuccess) {
        onSuccess(...args);
      }
    },
  });
};

export const useDeleteOfferVariationByOfferId = (onSuccess) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (id) => api.delete(`offervariations/by-offer/${id}`),
    onSuccess: (...args) => {
      queryClient.invalidateQueries('offerVariants');
      if (onSuccess) {
        onSuccess(...args);
      }
    },
  });
};

export const useOfferQuestions = () =>
  useQuery({
    queryKey: ['offerQuestions'],
    queryFn: () =>
      api.get('offerquestions').then((res) => {
        return res.data.map((a) => {
          return {
            id: a.question.id,
            name: a.offer.name,
            question: a.question.question,
          };
        });
      }),
  });

export const useQuestionAnswers = (questionId) =>
  useQuery({
    queryKey: ['questionAnswers', questionId],
    queryFn: () => api.get(`offerquestionanswer/question/${questionId}`).then((res) => res.data),
    enabled: !!questionId,
  });
