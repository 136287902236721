import React from 'react';
import ModalButton from './ModalButton';

const SaveModalButton = ({ disabled, onClick }) => (
  <ModalButton
    buttonLabel='Save'
    className='modal-success'
    modalHeader='Save'
    modalBody='Are you sure you want to save these changes?'
    disabled={disabled}
    onClick={onClick}
  />
);
export default SaveModalButton;
