import React from 'react';
import { Input, Alert } from 'reactstrap';

const renderInput = ({ input, meta, label, ...rest }) => {
  return (
    <>
      {label && <label>{label}</label>}

      <Input {...input} {...rest} />

      {meta.error && meta.touched && <Alert color='danger'>{meta.error}</Alert>}
    </>
  );
};

export default renderInput;
