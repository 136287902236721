import React from 'react';
import { Card, CardHeader, CardBody } from 'reactstrap';

const ViewContainer = (props) => {
  const { children, entity, isError } = props;

  if (isError) throw Error;

  return (
    <Card className='animated fadeIn'>
      <CardHeader>
        <i className={entity.iconClassName} />
        {entity.label}
      </CardHeader>
      <CardBody>{children}</CardBody>
    </Card>
  );
};

export default ViewContainer;
