export const GET_LANDING_PAGE = 'GET_LANDING_PAGE';
export const GET_LANDING_PAGE_BY_OFFER_ID = 'GET_LANDING_PAGE_BY_OFFER_ID';
export const GET_ALL_LANDING_PAGES = 'GET_ALL_LANDING_PAGES';
export const UPDATE_LANDING_PAGE = 'UPDATE_LANDING_PAGE';
export const UPDATE_LANDING_PAGE_ERROR = 'UPDATE_LANDING_PAGE_ERROR';
export const CREATE_LANDING_PAGE = 'CREATE_LANDING_PAGE';
export const SET_NEW_LANDING_PAGE_NAME = 'SET_NEW_LANDING_PAGE_NAME';
export const GET_CONTAINER_FOR_PREVIEW = 'GET_CONTAINER_FOR_PREVIEW';
export const GET_LAYOUT_FOR_PREVIEW = 'GET_LAYOUT_FOR_PREVIEW';
export const GET_SITE_FOR_PREVIEW = 'GET_SITE_FOR_PREVIEW';

