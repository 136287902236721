//TODO: Modify Conversion function - parse restriction
export function convertDailyRestrictionToArray(dailyRestrictionJson) {
  let arrayFormat = null;
  if (JSON.stringify(dailyRestrictionJson) === '[]') {
    return dailyRestrictionJson;
  }
  if (dailyRestrictionJson !== null) {
    let myObj = JSON.parse(dailyRestrictionJson);
    arrayFormat = [];
    console.log('DAILY RESTRICTIONS', myObj);
    if (myObj.weekdays) {
      let restriction = myObj.weekdays.restriction;
      let dayObj = {
        restrictionDay: 'weekdays',
        restriction,
      };
      if (restriction === 'Partially Restricted') {
        dayObj.restrictionStart = `${myObj.weekdays.restrictionStart}`;
        dayObj.restrictionEnd = `${myObj.weekdays.restrictionEnd}`;
      }
      arrayFormat.push(dayObj);
    }
    if (myObj.weekends) {
      let restriction = myObj.weekends.restriction;
      let dayObj = {
        restrictionDay: 'weekends',
        restriction,
      };
      if (restriction === 'Partially Restricted') {
        dayObj.restrictionStart = `${myObj.weekends.restrictionStart}`;
        dayObj.restrictionEnd = `${myObj.weekends.restrictionEnd}`;
      }
      arrayFormat.push(dayObj);
    }
    let shouldSpecifyWeekDay = !myObj.weekdays;
    let shouldSpecifyWeekEnds = !myObj.weekends;
    if (shouldSpecifyWeekDay && myObj.monday && myObj.monday.restriction !== undefined) {
      arrayFormat.push({
        restrictionDay: 'monday',
        restriction: myObj.monday.restriction,
        restrictionStart: `${myObj.monday.restrictionStart}`,
        restrictionEnd: `${myObj.monday.restrictionEnd}`,
      });
    }

    if (shouldSpecifyWeekDay && myObj.tuesday && myObj.tuesday.restriction !== undefined) {
      arrayFormat.push({
        restrictionDay: 'tuesday',
        restriction: myObj.tuesday.restriction,
        restrictionStart: `${myObj.tuesday.restrictionStart}`,
        restrictionEnd: `${myObj.tuesday.restrictionEnd}`,
      });
    }
    if (shouldSpecifyWeekDay && myObj.wednesday && myObj.wednesday.restriction !== undefined) {
      arrayFormat.push({
        restrictionDay: 'wednesday',
        restriction: myObj.wednesday.restriction,
        restrictionStart: `${myObj.wednesday.restrictionStart}`,
        restrictionEnd: `${myObj.wednesday.restrictionEnd}`,
      });
    }
    if (shouldSpecifyWeekDay && myObj.thursday && myObj.thursday.restriction !== undefined) {
      arrayFormat.push({
        restrictionDay: 'thursday',
        restriction: myObj.thursday.restriction,
        restrictionStart: `${myObj.thursday.restrictionStart}`,
        restrictionEnd: `${myObj.thursday.restrictionEnd}`,
      });
    }

    if (shouldSpecifyWeekDay && myObj.friday && myObj.friday.restriction !== undefined) {
      arrayFormat.push({
        restrictionDay: 'friday',
        restriction: myObj.friday.restriction,
        restrictionStart: `${myObj.friday.restrictionStart}`,
        restrictionEnd: `${myObj.friday.restrictionEnd}`,
      });
    }

    if (shouldSpecifyWeekEnds && myObj.saturday && myObj.saturday.restriction !== undefined) {
      arrayFormat.push({
        restrictionDay: 'saturday',
        restriction: myObj.saturday.restriction,
        restrictionStart: `${myObj.saturday.restrictionStart}`,
        restrictionEnd: `${myObj.saturday.restrictionEnd}`,
      });
    }
    if (shouldSpecifyWeekEnds && myObj.sunday && myObj.sunday.restriction !== undefined) {
      arrayFormat.push({
        restrictionDay: 'sunday',
        restriction: myObj.sunday.restriction,
        restrictionStart: `${myObj.sunday.restrictionStart}`,
        restrictionEnd: `${myObj.sunday.restrictionEnd}`,
      });
    }

    // if (myObj.weekdays && myObj.weekdays.restriction !== undefined) {
    //   arrayFormat.push({
    //     restrictionDay: 'weekdays',
    //     restriction: myObj.weekdays.restriction,
    //     restrictionStart: `${myObj.weekdays.restrictionStart}`,
    //     restrictionEnd: `${myObj.weekdays.restrictionEnd}`
    //   });
    // }
    // if (myObj.weekends && myObj.weekends.restriction !== undefined) {
    //   arrayFormat.push({
    //     restrictionDay: 'weekends',
    //     restriction: myObj.weekends.restriction,
    //     restrictionStart: `${myObj.weekends.restrictionStart}`,
    //     restrictionEnd: `${myObj.weekends.restrictionEnd}`
    //   });
    // }
  }
  return arrayFormat;
}

export function convertDailyRestrictionToJson(dailyRestrictionArray) {
  let jsonFormat = {
    monday: {
      restriction: 'Available',
    },
    tuesday: {
      restriction: 'Available',
    },
    wednesday: {
      restriction: 'Available',
    },
    thursday: {
      restriction: 'Available',
    },
    friday: {
      restriction: 'Available',
    },
    saturday: {
      restriction: 'Available',
    },
    sunday: {
      restriction: 'Available',
    },
  };

  if (dailyRestrictionArray !== null && Array.isArray(dailyRestrictionArray)) {
    let restrictedDays = [];
    for (let dayObject of dailyRestrictionArray) {
      // console.log(dayObject);
      let restrictionDay = dayObject['restrictionDay'];
      if (restrictionDay && restrictionDay !== null) {
        let restrictionStart = dayObject['restrictionStart'];
        let restrictionEnd = dayObject['restrictionEnd'];
        let restriction = dayObject['restriction'];
        if (restrictionDay === 'weekdays' || restrictionDay === 'weekends') {
          let dayArray;
          if (restrictionDay === 'weekends') {
            jsonFormat.weekends = {
              restriction: null,
            };
            dayArray = ['sunday', 'saturday', 'weekends'];
            restrictedDays = [...restrictedDays, ...dayArray];
          } else {
            jsonFormat.weekdays = {
              restriction: null,
            };
            dayArray = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'weekdays'];
            restrictedDays = [...restrictedDays, ...dayArray];
          }
          for (let day of dayArray) {
            jsonFormat[day].restriction = restriction;
            if (restriction === 'Partially Restricted') {
              jsonFormat[day].restrictionStart = restrictionStart;
              jsonFormat[day].restrictionEnd = restrictionEnd;
            } else if (restriction === 'Available' || restriction === 'Unvailable') {
              delete jsonFormat[day].restrictionStart;
              delete jsonFormat[day].restrictionEnd;
            }
          }
        } else if (!restrictedDays.includes(restrictionDay)) {
          jsonFormat[restrictionDay].restriction = restriction;
          if (restriction === 'Partially Restricted') {
            jsonFormat[restrictionDay].restrictionStart = restrictionStart;
            jsonFormat[restrictionDay].restrictionEnd = restrictionEnd;
          } else if (restriction === 'Available' || restriction === 'Unvailable') {
            delete jsonFormat[restrictionDay].restrictionStart;
            delete jsonFormat[restrictionDay].restrictionEnd;
          }
        }
      }
    }
  }

  return jsonFormat;

  // const selectedDays = {};
  // if (dailyRestrictionArray !== null && Array.isArray(dailyRestrictionArray)) {
  //   for (let dayObject of dailyRestrictionArray) {
  //     let restrictionDay =
  //       dayObject['restrictionDay'] === null
  //         ? null
  //         : dayObject['restrictionDay'];
  //     if (
  //       restrictionDay !== null &&
  //       restrictionDay !== undefined &&
  //       restrictionDay !== ''
  //     ) {
  //       let restrictionStart = '' + dayObject['restrictionStart'] + '';
  //       let restrictionEnd = '' + dayObject['restrictionEnd'] + '';
  //       let restriction = '' + dayObject['restriction'] + '';
  //       //   if (restrictionDay === 'weekdays' || restrictionDay === 'weekends') {
  //       //     let dayArray;
  //       //     if (restrictionDay === 'weekends') {
  //       //       dayArray = ['sunday', 'saturday'];
  //       //     } else {
  //       //       dayArray = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday'];
  //       //     }
  //       //     for (let day of dayArray) {
  //       //       jsonFormat[day].restriction = restriction;
  //       //       jsonFormat[day].restrictionStart = restrictionStart;
  //       //       jsonFormat[day].restrictionEnd = restrictionEnd;
  //       //     }
  //       //   } else {
  //       //   console.log(jsonFormat);
  //       //   console.log(restrictionDay);
  //       //   console.log(restriction);
  //       selectedDays[restrictionDay] = { restriction };
  //       if (restriction === 'Partially Restricted') {
  //         selectedDays[restrictionDay].restrictionStart = restrictionStart;
  //         selectedDays[restrictionDay].restrictionEnd = restrictionEnd;
  //       }
  //       //   }
  //     }
  //   }
  // }
  // return selectedDays;
}

export function convertImageFileToBase64(file) {
  return new Promise((resolve) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      resolve(reader.result);
    };
    reader.onerror = function (error) {
      console.log(error);
    };
  });
}

export function convertBase64FileToImage(base64File) {
  return new Promise((resolve) => {
    let image = new Image();
    image.onload = function () {
      resolve(image);
    };
    image.src = base64File;
  });
}

export function formatDate(str) {
  if (!str) return;
  const fixDateForAllBrowsers = (dateString) => dateString.replace(/[^0-9]+/g, '/');
  let date = fixDateForAllBrowsers(str).split('/');
  date = new Date(Date.UTC(date[0], date[1] - 1, date[2], date[3], date[4], date[5])); // yyyy, mm-1, dd, hh, mm, ss

  const options = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    // second: 'numeric',
    timeZoneName: 'short',
  };
  return new Intl.DateTimeFormat('en-US', options).format(date);
}

export function normalizeWeight(value) {
  const str = value + '';
  if (value < 1) {
    if (str.length === 1) {
      return 0;
    } else if (str.length > 1) {
      return str.split('').slice(-1).join('');
    } else {
      return 0;
    }
  } else if (value > 100) {
    return str.split('').slice(0, -1).join('');
  } else if (str.length > 1 && str[0] === '0') {
    for (let i = 0; i < str.length; i++) {
      if (str[i] !== '0') {
        return str.split('').splice(i).join('');
      }
    }
    return 1;
  } else if (str.includes('.')) {
    return Math.round(value);
  }
  return value;
}
