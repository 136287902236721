let env;

switch (window.env) {
  case 'staging':
    env = '-stage';
    break;
  case 'prod_test':
    env = '';
    break;
  case 'prod':
    env = '';
    break;
  default:
    env = '-dev';
    break;
}

const _nav = {
  items: [
    {
      title: true,
      name: 'Functions',
      wrapper: {
        element: '',
        attributes: {},
      },
    },
    {
      name: 'Dashboard',
      url: '/dashboard',
      icon: 'icon-speedometer',
      attributes: { id: 'dashboard' },
    },
    {
      name: 'Campaigns',
      url: '/campaigns',
      icon: 'fa fa-clone',
      children: [
        {
          name: 'Landing Pages',
          url: '/campaigns/landingpages',
          icon: 'icon-list',
          attributes: { id: 'landingPages' },
        },
        {
          name: 'Landing Page Groups',
          url: '/campaigns/landingpagegroups',
          icon: 'icon-list',
          attributes: { id: 'landingPageGroups' },
        },
        {
          name: 'Offers',
          url: '/campaigns/offertable',
          icon: 'icon-list',
          attributes: { id: 'offers' },
        },
        {
          name: 'Offer Actions',
          url: '/campaigns/offeractions',
          icon: 'fa fa-clone',
          attributes: { id: 'offerActions' },
        },
        {
          name: 'Offer Wall Ads',
          url: '/campaigns/offerwallads',
          icon: 'fa fa-th-large',
          attributes: { id: 'offerWallAds' },
        },
        {
          name: 'Offer Blocks',
          url: '/campaigns/offerblocks',
          icon: 'fa fa-cube',
          attributes: { id: 'offerBlocks' },
        },
        {
          name: 'Sequences',
          url: '/campaigns/sequences',
          icon: 'fa fa-clone',
          attributes: { id: 'sequences' },
        },
        {
          name: 'Tags',
          url: '/campaigns/tags',
          icon: 'fa fa-hashtag',
          attributes: { id: 'tags' },
        },
        {
          name: 'Sites',
          url: '/campaigns/sites',
          icon: 'icon-list',
          attributes: { id: 'sites'},
        }
      ],
    },
    {
      name: 'Advertisers',
      url: '/advertisers',
      icon: 'fa fa-user-o',
      attributes: { id: 'advertisers' },
    },
    {
      name: 'Custom Variables',
      url: '/custom-variables',
      icon: 'fa fa-plus-circle',
      attributes: { id: 'customVariables' },
    },
    {
      name: 'Marketing Partners',
      url: '/marketingpartners',
      icon: 'fa fa-user-o',
      attributes: { id: 'marketingPartners' },
    },
    {
      name: 'Affiliates',
      url: '/affiliates',
      icon: 'fa fa-user-o',
      attributes: { id: 'affiliates' },
    },
    {
      name: 'Publisher Pre-Pings',
      url: '/publisher-pre-pings',
      icon: 'fa fa-user-o',
      attributes: { id: 'publisher-pre-pings' },
    },
    {
      name: 'Advertiser Pre-Ping',
      url: '/advertiser-pre-pings',
      icon: 'fa fa-user-o',
      attributes: { id: 'advertiser-pre-pings' },
    },
    {
      name: 'Revshare Percentage',
      url: '/revshare-percentage',
      icon: 'fa fa-percent',
      attributes: { id: 'revshare-percentage' },
    },
    {
      name: 'Domains',
      url: '/domains',
      icon: 'fa fa-globe',
      attributes: { id: 'domains' },
    },
    {
      name: 'Content',
      icon: 'cui-file',
      children: [
        {
          name: 'Layouts',
          url: `/content/layouts`,
          icon: 'icon-grid',
          attributes: { id: 'layouts' },
        },
        {
          name: 'Containers',
          url: `/content/containers`,
          icon: 'icon-drawer',
          attributes: { id: 'containers' },
        },
        {
          name: 'Template Editor',
          url: `/content/template-editor`,
          icon: 'fa fa-code',
          attributes: { id: 'templateEditor' },
        },
        {
          name: 'Handlers',
          url: `/content/handlers`,
          icon: 'icon-layers',
          attributes: { id: 'handlers' },
        },
        {
          name: 'User Attributes',
          url: '/content/userAttributes',
          icon: 'icon-grid',
          attributes: { id: 'userAttributes'},
        },
      ],
    },
    {
      name: 'Staff Permissions',
      url: '/permissions',
      icon: 'icon-list',
      attributes: { id: 'staffPermissions' },
    },
    {
      name: 'Features',
      url: '/features',
      icon: 'fa fa-cogs',
      attributes: { id: 'features' },
    },
    {
      name: 'Users',
      url: '/users',
      icon: 'fa fa-users',
      attributes: { id: 'users' },
    },
    {
      divider: true,
      class: 'm-2',
    },
  ],
};
export default _nav;
