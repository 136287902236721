import React, { Component } from 'react';
import { Alert, Button, Col, NavLink, Row } from 'reactstrap';
import DeleteModal from '../../../../components/DeleteModal/DeleteModal';
import SingleButtonModal from '../../../../components/GenericModal/SingleButtonModal';
import SaveAsEntityModal from '../../../../components/SaveAsEntityModal/SaveAsEntityModal';
import SaveEntityDraftModal from '../../../../components/SaveEntityDraftModal/SaveEntityDraftModal';
import SaveEntityModal from '../../../../components/SaveEntityModal/SaveEntityModal';
import SimpleModal from '../../../../components/SimpleModal/SimpleModal';
import { formatDate } from '../../../../utils/conversionFunctions';

class SequenceNav extends Component {
  constructor(props) {
    super(props);
  }

  renderSimpleModalHeader = () => {
    return (
      <p>
        Warning: <i className='fa fa-exclamation-triangle fa-2x' style={{ color: '#FF485D' }} />
      </p>
    );
  };

  renderTopHeader = (shouldErrorRender) => {
    const errors = this.props.getErrors();
    if (shouldErrorRender && errors._error) return <Alert color='danger'>{errors._error}</Alert>;
    if (this.props.publishedSequence)
      return (
        <div className='singleLine'>
          {'Last Published at ' +
            formatDate(this.props.publishedSequence?.updatedAt) +
            ' by: ' +
            this.props.publishedSequence.zuulUser.realname}
        </div>
      );
  };

  render() {
    return (
      <Row id='sequenceNav'>
        <Col>
          <NavLink href='/campaigns/sequences' onClick={this.props.setConfirmedExitModalTrue}>
            <i className='fa fa-chevron-left' /> &nbsp;&nbsp; Back to Zuul
          </NavLink>
        </Col>
        <Col className='center' xs='auto'>
          {this.props.leftAside ? (
            <i
              onClick={() => this.props.toggleAside('leftAside')}
              className='fa fa-angle-double-down closed toggleBtn'
            />
          ) : (
            <i onClick={() => this.props.toggleAside('leftAside')} className='fa fa-angle-double-up opened toggleBtn' />
          )}
        </Col>
        <Col className='vertCenter'>{this.renderTopHeader(this.props.shouldErrorRender)}</Col>
        <Col xs='auto'>
          <SaveEntityDraftModal
            getErrors={this.props.getErrors}
            setIsDraft={(isDraft) => this.props.setIsDraft(isDraft)}
            onClickSubmit={this.props.handleSubmit(this.props.onSubmitForm)}
            isEditMode={this.props.isEditMode}
            disabled={this.props.offers.length === 0}
            entityName={this.props.sequenceName}
            entityType={'Sequence'}
            submitting={this.props.submitting}
            saveAndContinue={(value) => this.props.saveAndContinue(value)}
            className='navButton singleLine'
          />
        </Col>
        <Col xs='auto'>
          <SaveAsEntityModal
            getErrors={this.props.getErrors}
            onClickSubmit={this.props.handleSubmit(this.props.onSubmitForm)}
            isEditMode={this.props.isEditMode}
            disabled={this.props.offers.length === 0}
            entityType={'Sequence'}
            submitting={this.props.submitting}
            className='navButton'
          >
            {this.props.renderSite}
          </SaveAsEntityModal>
        </Col>
        <Col xs='auto'>
          <SaveEntityModal
            getErrors={this.props.getErrors}
            onClickSubmit={this.props.handleSubmit(this.props.onSubmitForm)}
            isEditMode={this.props.isEditMode}
            disabled={this.props.offers.length === 0}
            entityName={this.props.sequenceName}
            entityType={'Sequence'}
            submitting={this.props.submitting}
            saveAndContinue={(value) => this.props.saveAndContinue(false)}
            className='navButton'
          />
        </Col>
        <Col className='center' xs='auto'>
          {this.props.rightAside ? (
            <i
              onClick={() => this.props.toggleAside('rightAside')}
              className='fa fa-angle-double-down closed toggleBtn'
            />
          ) : (
            <i
              onClick={() => this.props.toggleAside('rightAside')}
              className='fa fa-angle-double-up opened toggleBtn'
            />
          )}
        </Col>
        <Col className='revertSeqButton'>
          {this.props.isEditMode &&
            (!this.props.isPublished ? (
              <Button onClick={() => this.props.revertSequence()} className='singleLine' color='danger'>
                Revert Sequence Draft
              </Button>
            ) : (
              <DeleteModal modalType={' Sequence'} id={this.props.id} entity={'sequences'} />
            ))}
        </Col>
        {this.props.shouldWarnUser && (
          <SimpleModal
            toggleUserActivity={this.props.onUserActivity}
            header={this.renderSimpleModalHeader()}
            body={'You have 1 minute to resume activity or the sequence will be checked in without saving any changes.'}
          />
        )}
        <SingleButtonModal
          isOpen={this.props.isCheckedOut}
          onPrimaryClick={() => this.props.push('/campaigns/sequences')}
          headerText='Sequence is checked out'
          bodyText='Sequence is currently checked out, please go back to the sequences table'
          primaryBtnText='Go back to all sequences'
        />
      </Row>
    );
  }
}

export default SequenceNav;
