import { Label } from 'reactstrap';
import { renderComboBox } from '../../views/Offers/OfferTitle/renderConsts';
import React from 'react';

/**
 * Produces a SiteNameFilter component. Meant to be used in table headers.
 *
 * Style is set to 62px so the original component does not look weird in the table headers
 * next to other components with input boxes.
 *
 * onBlur is set to an empty fn since it's more cleanly handled by onChange handling an empty string input
 * and onSelect.
 * @param siteNames
 * @param onChange
 * @param opts
 * @returns {function()}
 */
const makeSiteNameFilterComponent = (siteNames, onChange, opts = {}) => {
  const comboBoxProps = {
    input: {
      onBlur: () => {}
    },
    data: siteNames,
    onChange,
    textField: opts.textField || undefined,
    meta: {
      touched: false,
      error: false
    }
  };
  const style = {
    height: '62px'
  };

  return () => (
    <div className='site-name-filter' style={style}>
      <Label>Site</Label>
      {renderComboBox(comboBoxProps)}
    </div>
  );
};

export { makeSiteNameFilterComponent };
