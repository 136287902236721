export default function validate(values, props) {
  const errors = {};
  // Error Checks
  if (!values.landingPageGroupName) {
    errors.landingPageGroupName = 'Please enter a name for this LP group.';
  }
  if (!values.site || typeof values.site !== 'object') {
    errors.site = 'Please select a site for this LP group.';
  }

  if (values.returnUserSegments && Array.isArray(values.returnUserSegments)) {
    errors.returnUserSegments = [];
    // console.log(values.offerSlots);
    values.returnUserSegments.forEach((userFlow, i) => {
      errors.returnUserSegments[i] = {};
      errors.returnUserSegments[i].slots = [];
      (userFlow.slots || []).forEach((slot, j) => {
        errors.returnUserSegments[i].slots[j] = {};
        if (!slot.slot_lp || typeof slot.slot_lp === 'string') {
          errors.returnUserSegments[i].slots[j].slot_lp = 'Please select an LP for this slot.';
        }
        if (!slot.flex) {
          errors.returnUserSegments[i].slots[j].flex = 'Please select a flex for this LP slot.';
        }
      });
    });
  }
  return errors;
}
