import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useOktaAuth } from "@okta/okta-react";
import api from "../../services";

// Custom Hook for Okta Authentication
export function useAuth() {
  const { authState, oktaAuth } = useOktaAuth();
  const history = useHistory();

  useEffect(() => {
    if (authState && authState.isAuthenticated && authState.accessToken && authState.accessToken.accessToken) {
      // Set the Authorization header for your API requests
      api.defaults.headers.common['Authorization'] = `Bearer ${authState.accessToken.accessToken}`;

      // Get user information and perform any additional actions
      oktaAuth.getUser().then((info) => {
        let reqUserData = {
          email: info.email,
          realname: info.name,
          lastLogin: new Date(),
        };

        api
          .put('zuulusers/login', reqUserData)
          .then((response) => {
            let responseJson = response.data;
            localStorage.setItem('userData', JSON.stringify(responseJson?.zuulUser));
          })
          .catch((error) => {
            console.log('LOGIN ERROR', error);
          });
      });
    } else {
      // Redirect to login if not authenticated
      history.push('/login');
    }
  }, [authState, oktaAuth, history]);

  return {
    isAuthenticated: authState?.isAuthenticated,
    user: authState?.idToken?.claims,
  };
}
