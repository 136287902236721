import React, { Component } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { Button, Col, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Field } from 'redux-form';
import {
  renderField,
  renderComboBox,
} from '../../Offers/OfferTitle/renderConsts';
import { updateSelectedLPs } from '../actions';
import { normalizeWeight } from '../../../utils/conversionFunctions';
import './LPGDetails.css';
class Slot extends Component {
  getLandingPageId = (selectedLPStr) => {
    console.log(selectedLPStr);
    if (typeof selectedLPStr === 'number') return selectedLPStr;
    let id = null;
    let name =
      typeof selectedLPStr === 'object' && selectedLPStr !== null
        ? selectedLPStr.name
        : selectedLPStr;
    const { landingPages } = this.props;
    for (let i = 0; i < landingPages; i++) {
      if (landingPages[i].name === name) {
        id = landingPages[i].id;
        break;
      }
    }
    return id;
  };

  handleSelectedChanged = (selected, newValue, previousValue) => {
    const { returnUserSegmentIndex } = this.props;
    let selectedLPs =
      this.props.landingPageGroup.selectedLPs[returnUserSegmentIndex] || [];

    console.log(selectedLPs);
    // case where user removed the wall ad and it was an object/selected ad
    if (
      typeof newValue !== 'object' &&
      previousValue &&
      typeof previousValue === 'object'
    ) {
      const newLandingPages = selectedLPs.filter((offer) => {
        const id = previousValue.id
          ? previousValue.id
          : this.getLandingPageId(previousValue);
        return offer !== id;
      });
      console.log(newLandingPages);
      selectedLPs = newLandingPages;
    }
    // case where there was a previously selected ad and user selected a new dropdown option, we swap the previous with the new value
    else if (
      newValue &&
      typeof newValue === 'object' &&
      previousValue &&
      typeof previousValue === 'object' &&
      selectedLPs.indexOf(previousValue.id) > -1
    ) {
      console.log(newValue);
      console.log(previousValue);
      const previousIdx = selectedLPs.indexOf(previousValue.id);
      selectedLPs[previousIdx] = newValue.id;
    }
    // case where user typed and selected an offer wall ad or just selected one from the dropdown
    else if (typeof newValue === 'object') {
      const landingPageId = newValue.id
        ? newValue.id
        : this.getLandingPageId(newValue);
      selectedLPs.push(landingPageId);
    }
    console.log(selectedLPs);
    this.props.updateSelectedLPs(returnUserSegmentIndex, selectedLPs);
  };

  handleSlotRemoval = (
    returnUserSegment,
    returnUserSegmentIndex,
    fields,
    index
  ) => {
    console.log(this.props.slotLP);

    if (this.props.slotLP && this.props.slotLP.id) {
      const indexOfSlotLP = returnUserSegment.indexOf(this.props.slotLP.id);
      returnUserSegment.splice(indexOfSlotLP, 1);
      this.props.updateSelectedLPs(returnUserSegmentIndex, returnUserSegment);
    }
    fields.remove(index);
  };

  render() {
    const {
      landingPageGroup,
      landingPages,
      slot,
      slotLP,
      slotType,
      fields,
      returnUserSegmentIndex,
      index,
    } = this.props;
    const returnUserSegment =
      landingPageGroup.selectedLPs[returnUserSegmentIndex];
    const filteredSlots = landingPages.filter((landingPage) => {
      if (returnUserSegment) {
        return !returnUserSegment.includes(landingPage.id);
      }
      return true;
    });
    
    return (
      <>
        <Row className='slot-lp'>
          <Col xs={10} sm={10} lg={9}>
            <label htmlFor={`${slot}.slot_lp`}>{slotType}</label>
            <Field
              onChange={this.handleSelectedChanged}
              name={`${slot}.slot_lp`}
              type='text'
              id={slot}
              component={renderComboBox}
              textField={(landingPage) => {
                return typeof landingPage === 'object' && landingPage !== null
                  ? landingPage.offer && landingPage.offer.name
                  : landingPage;
              }}
              valueField='id'
              data={filteredSlots}
            />
          </Col>
          <Col xs={1} sm={1} lg={1}>
            {!slotLP.id ? (
              <Button
                color='info'
                type='button'
                title='Visit landing page details'
                disabled={true}
                className='lp-link'
              >
                <i className='fa fa-search' />
              </Button>
            ) : (
              <Link to={`/campaigns/landingpages/${slotLP.id}`} target='_blank'>
                <Button
                  color='info'
                  type='button'
                  title='Visit landing page details'
                  className='lp-link'
                >
                  <i className='fa fa-search' />
                </Button>
              </Link>
            )}
          </Col>
        </Row>
        <Row className='flex'>
          <Col xs={8} sm={8} lg={8}>
            <Field
              name={`${slot}.flex`}
              normalize={normalizeWeight}
              component={renderField}
              type='number'
              label='Flex'
            />
          </Col>
          <Col xs={4} sm={4} lg={4}>
            {fields.length > 1 && (
              <Button
                className='delete-slot-button'
                onClick={() =>
                  this.handleSlotRemoval(
                    returnUserSegment,
                    returnUserSegmentIndex,
                    fields,
                    index
                  )
                }
                color='danger'
              >
                <i className='fa fa-minus' />
              </Button>
            )}
          </Col>
          <br />
        </Row>
      </>
    );
  }
}

function mapStateToProps(state, props) {
  const { selector } = state.landingPageGroup;
  const { landingPages } = state.landingPage;
  const { landingPageGroup } = state;
  return {
    action: selector(state, `${props.slot}.name`),
    offerWall: state.offerWall,
    landingPages,
    landingPageGroup,
    slotLP: selector(state, `${props.slot}.slot_lp`) || {},
  };
}

export default connect(mapStateToProps, { updateSelectedLPs })(Slot);
