import { GET_ALL_LAYOUTS } from '../../actions/types';
import api from '../../../../services';
import { sortByName } from '../../../../utils/sorting';

export const getAllLayouts = (layouts) => {
  return {
    type: GET_ALL_LAYOUTS,
    payload: layouts,
  };
};

export const fetchAllLayouts = () => {
  return function (dispatch) {
    return getLayoutsApi().then(
      (normalizedLayouts) => dispatch(getAllLayouts(normalizedLayouts)),
      (error) => console.log(error)
    );
  };
};

export const getLayoutsApi = (dispatch) => {
  return new Promise((resolve) => {
    api
      .get('layouts')
      .then((response) => {
        let sortedLayouts = sortByName(response.data);

        resolve(sortedLayouts);
      })
      .catch((error) => console.log(error));
  });
};

export const getLayoutsTableApi = (dispatch) => {
  return new Promise((resolve) => {
    api
      .get('layouts/table')
      .then((response) => {
        let sortedLayouts = sortByName(response.data);
        resolve(sortedLayouts);
      })
      .catch((error) => console.log(error));
  });
};
