const getColorClassName = (value) => {
  const currencyClassName = 'currency';

  if (value < 0) {
    return `${currencyClassName} negative-value`;
  }

  if (value > 0) {
    return `${currencyClassName} positive-value`;
  }

  if (value === 0) {
    return `${currencyClassName} no-change-value`;
  }

  return undefined;
};


const getNilCellDefault = (value) => value || 'N/A';

const addDifferenceCurrencyFormatting = (value) => {
  if(isNaN(value) || value == null) return "N/A"
  if (value < 0) {
    return `-$${Math.abs(value).toLocaleString()}`;
  }

  if (value > 0) {
    return `+$${value.toLocaleString()}`;
  }

  if (value === 0) {
    return `±$${value.toLocaleString()}`;
  }
};

export { getColorClassName, getNilCellDefault, addDifferenceCurrencyFormatting };
