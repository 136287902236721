export const extractUDKeys = (html) => {
  if (!html) return [];
  const udKeys = [];
  const regex = /\{\{\{(UD_[a-zA-Z0-9]+)\}\}\}/g;
  let match;

  while ((match = regex.exec(html)) !== null) {
    udKeys.push({ key: match[1], value: null });
  }

  return udKeys;
};

export const processCustomVariables = (customVariables) => {
  return customVariables?.map((customVariable) => {
    const { key, value } = customVariable;
    if (!value) return { key };
    return { key, value };
  });
};
