import React from 'react';
import { Card, CardBody, CardHeader, Col, Label, Row } from 'reactstrap';
import { Field } from 'redux-form';
import ActiveStatusBadge from '../../Offers/OfferTitle/ActiveStatusBadge';
import { renderComboBox, renderField, renderToggleSwitch } from '../../Offers/OfferTitle/renderConsts';
import { useLayouts } from '../../Layouts/queries';
import { useContainers } from '../../Containers/queries';

export function LPCard({ active, landingPageName, id, children }) {
  const { data: layouts } = useLayouts();
  const { data: containers } = useContainers();

  return (
    <Card>
      <CardHeader>
        <Row>
          <Col lg='11'>
            <span style={{ display: 'flex', alignItems: 'baseline' }}>
              <ActiveStatusBadge isActive={active} />
              &nbsp;&nbsp;
              <h3>{landingPageName}</h3>&nbsp;&nbsp;
              <h6>
                <em>Landing Page ID: </em>
                {id}
              </h6>
            </span>
          </Col>
          <Col lg='1' className='float-left'>
            {children}
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <Row>
          <Col lg={3}>
            <Field
              required
              type='text'
              component={renderField}
              id='landingPageName'
              name='landingPageName'
              placeholder='Landing Page Name'
              label='Landing Page Name'
            />
          </Col>
          <Col lg={3}>
            <Label>Layout</Label>
            <Field
              required
              type='select'
              component={renderComboBox}
              id='layout'
              name='layout'
              placeholder='Layout'
              label='Landing Page Layout'
              textField='name'
              valueField='id'
              data={layouts}
              link='/content/layouts'
            />
          </Col>
          <Col lg={3}>
            <Label>Container</Label>
            <Field
              required
              type='select'
              component={renderComboBox}
              id='container'
              name='container'
              placeholder='Container'
              label='Container'
              textField='name'
              valueField='id'
              data={containers}
              link='/content/containers'
            />
          </Col>
        </Row>
        <Row>
          <Col lg='3'>
            <label htmlFor='verifyWithBriteverify' style={{ verticalAlign: 'super' }}>
              Validate with BriteVerify
            </label>
            &nbsp;&nbsp;&nbsp;
            <Field name='verifyWithBriteverify' id='verifyWithBriteverify' component={renderToggleSwitch} />
          </Col>
          <Col lg='3'>
            <label htmlFor='scrubIsActive' style={{ verticalAlign: 'super' }}>
              Pixel is Active
            </label>
            &nbsp;&nbsp;&nbsp;
            <Field name='scrubIsActive' id='scrubIsActive' component={renderToggleSwitch} />
          </Col>
          <Col lg='3'>
            <label htmlFor='triggersRegistration' style={{ verticalAlign: 'super' }}>
              Count as Registration
            </label>
            &nbsp;&nbsp;&nbsp;
            <Field name='triggersRegistration' id='triggersRegistration' component={renderToggleSwitch} />
          </Col>
          <Col lg={3} />
        </Row>
      </CardBody>
    </Card>
  );
}
