import React from 'react';
import { useSelector } from 'react-redux';
import { Button, Col, Input, Row, TabPane } from 'reactstrap';
import { Field, FieldArray } from 'redux-form';
import {
  CapIntervals,
  // RenderCapSegmentConfiguration,
  renderDailyHourRestrictions,
  renderDailyRestrictionDay,
  renderField,
  renderMultiSelect,
  RenderNumberPicker,
  renderRestrictions,
} from '../../views/Offers/OfferTitle/renderConsts';
import { validateWholeNums } from '../../views/Offers/OfferTitle/valildation';
import { useDomains } from '../../views/OfferBlocks/queries';

const RenderDayParting = ({ fields, meta: { touched, error } }) => {
  const restrictions = useSelector((state) => {
    return fields.map((f) => state.offerState.selector(state, `${f}.restriction`));
  });
  return (
    <div>
      <hr />
      <Row>
        <Col lg={12}>
          <Button
            color='success'
            className='float-right'
            onClick={() => fields.push({ restrictionDay: null, restriction: null })}
          >
            Add Day Partings
          </Button>
          {touched && error && <span>{error}</span>}
        </Col>
      </Row>
      <br />
      {fields.map((dailyRestriction, index) => {
        const restrictionType = restrictions[index];

        return (
          <div key={index} id='dailyRestriction'>
            <Row>
              <Col lg={1}>
                <br />
                <Button
                  color='danger'
                  className='removeofferInputParent'
                  type='button'
                  title='Remove Parent Attribute'
                  onClick={() => fields.remove(index)}
                >
                  <i className='fa fa-close' />
                </Button>
              </Col>
              <Col lg={2}>
                <Field
                  name={`${dailyRestriction}.restrictionDay`}
                  type='select'
                  component={renderDailyRestrictionDay}
                  label='Day'
                />
              </Col>
              <Col lg={2}>
                <Field
                  name={`${dailyRestriction}.restriction`}
                  type='select'
                  component={renderRestrictions}
                  label='Restriction'
                />
              </Col>
              <Col lg={2}>
                <div>
                  {restrictionType === 'Partially Restricted' && (
                    <Field
                      name={`${dailyRestriction}.restrictionStart`}
                      type='select'
                      component={renderDailyHourRestrictions}
                      label='Available Start'
                    />
                  )}
                </div>
              </Col>
              <Col lg={2}>
                <div>
                  {restrictionType === 'Partially Restricted' && (
                    <Field
                      name={`${dailyRestriction}.restrictionEnd`}
                      type='select'
                      component={renderDailyHourRestrictions}
                      label='Available End'
                    />
                  )}
                </div>
              </Col>
            </Row>
            <hr />
          </div>
        );
      })}
    </div>
  );
};

export const DailyRestrictionPane = (props) => {
  const { data: domains, isLoading: domainsLoading } = useDomains(true, (data) =>
    data.map((domain) => domain.name).sort((a, b) => (a > b ? 1 : -1))
  );

  return (
    <TabPane tabId='Daily Restriction'>
      <Row>
        <Col lg={6}>
          <Field
            name='criteriaDailyLimit'
            component={renderField}
            type='number'
            label='Daily Limit'
            normalize={validateWholeNums}
          />
        </Col>
        {props.offerType !== 'offerbanner' && (
          <Col lg={6}>
            <label>Answers</label>
            <Field
              name='criteriaDailyLimitAnswers'
              component={renderMultiSelect}
              type='select'
              data={props.limitAnswers || []}
            />
          </Col>
        )}
      </Row>
      {props.criteriaType === 'Offer Criteria' && (
        <>
          <Row>
            <Col>
              <label>Cap Interval</label>
              <Field name='capInterval' type='select' component={CapIntervals} />
            </Col>
            <Col>
              <label>Cap Amount</label>
              <Field name='capAmount' component={RenderNumberPicker} />
            </Col>
            <Col>
              <label>Current Cap Amount</label>
              <Input type='text' value={props.currentCapAmount} disabled={true} />
            </Col>
          </Row>
          {/*<FieldArray*/}
          {/*  name='capSegmentConfiguration.capSegments'*/}
          {/*  component={RenderCapSegmentConfiguration}*/}
          {/*  domains={domains}*/}
          {/*  capAmount={props.capAmount}*/}
          {/*  capSegmentConfiguration={props.capSegmentConfiguration}*/}
          {/*/>*/}
        </>
      )}

      <FieldArray name='criteriaDayHourRestrictions' component={RenderDayParting} />
    </TabPane>
  );
};
