import classNames from 'classnames';
import React, { useEffect, useMemo, useReducer, useRef, useState } from 'react';
import useKeyPress from '../../../hooks/useKeyPress';
import './NavigationModal.css';

const shortcuts = {
  lp: '/campaigns/landingpages',
  lpg: '/campaigns/landingpagegroups',
  o: '/campaigns/offertable/offer',
  oa: '/campaigns/offeractions',
  owa: '/campaigns/offerwallads',
  ob: '/campaigns/offerblocks',
  seq: '/campaigns/sequences',
  sites: '/campaigns/sites',
  ppp: '/publisher-pre-pings',
  app: '/advertiser-pre-pings',
  l: '/content/layouts',
  c: '/content/containers',
};

const InfoModal = ({ isOpen, onClose }) => {
  return (
    isOpen && (
      <div className='modal-overlay' onClick={onClose}>
        <div className='modal-box modal-box-small' onClick={(e) => e.stopPropagation()}>
          <div className='modal-box-header'>
            <h3>Available Shortcuts</h3>
          </div>
          <div className='modal-box-body'>
            <ul>
              {Object.entries(shortcuts).map(([key, value]) => (
                <li key={key}>
                  <strong>{key}</strong>: {value}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    )
  );
};

const NavigationModal = ({ data = [], isOpen = false, toggle = () => {}, perSectionLimit }) => {
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [list, setList] = useState(
    data.map((group) => {
      return {
        sectionName: group.sectionName,
        items: perSectionLimit
          ? group.items.slice(0, perSectionLimit).map((item) => {
              return {
                icon: item.icon,
                title: item.title,
                url: item.url,
              };
            })
          : group.items.map((item) => {
              return {
                icon: item.icon,
                title: item.title,
                url: item.url,
              };
            }),
      };
    }) || []
  );

  const filteredItemsCount = useMemo(() => {
    return list.map((item) => item.items).flat().length;
  }, [list]);

  const inputRef = useRef(null);
  const arrowUpPressed = useKeyPress('ArrowUp');
  const arrowDownPressed = useKeyPress('ArrowDown');
  const initialState = { selectedIndex: 0 };

  const reducer = (state, action) => {
    switch (action.type) {
      case 'arrowUp': {
        const button = document.getElementsByClassName(`modal-box-body-items-${state.selectedIndex - 2}`)[0];

        if (button) {
          setTimeout(() => {
            button.focus();
          }, 0);
        }

        // focusing on input if up arrow is pressed on first item
        if (state.selectedIndex === 1) {
          if (inputRef.current) {
            setTimeout(() => {
              inputRef.current.focus();
            }, 0);
          }
        }

        return {
          selectedIndex: state.selectedIndex !== 0 ? state.selectedIndex - 1 : 0,
        };
      }
      case 'arrowDown': {
        const button = document.getElementsByClassName(`modal-box-body-items-${state.selectedIndex}`)[0];

        if (button) {
          setTimeout(() => {
            button.focus();
          }, 0);
        }

        return {
          selectedIndex: state.selectedIndex !== filteredItemsCount - 1 ? state.selectedIndex + 1 : 0,
        };
      }
      case 'select':
        return { selectedIndex: action.payload };
      default:
        throw new Error();
    }
  };
  const [, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    if (inputRef.current) {
      // avoiding scroll to top when focusing on input
      const x = window.scrollX;
      const y = window.scrollY;
      inputRef.current.focus({
        preventScroll: true,
      });
      window.scrollTo(x, y);
    }
  }, []);

  useEffect(() => {
    if (arrowUpPressed) {
      dispatch({ type: 'arrowUp' });
    }
  }, [arrowUpPressed]);

  useEffect(() => {
    if (arrowDownPressed) {
      dispatch({ type: 'arrowDown' });
    }
  }, [arrowDownPressed]);

  let trackItemindex = -1;

  const getUrlFromPattern = (keyword, number) => {
    const lowercaseKeyword = keyword.toLowerCase();
    const baseUrl = shortcuts[lowercaseKeyword];
    return number ? `${baseUrl}/${number}` : baseUrl;
  };
  const filterItems = (list, title) => {
    const result = [];

    const [keyword, number] = title.split(' ');

    if (shortcuts[keyword]) {
      const url = number ? `${shortcuts[keyword]}/${number}` : shortcuts[keyword];
      const matchingItem = {
        sectionName: 'Jump to',
        items: [
          {
            icon: '🔍',
            title: `Jump to ${url}`,
            url: url,
          },
        ],
      };

      result.push(matchingItem);
    } else {
      list.forEach((obj) => {
        const items = obj.items.filter((item) => item.title.toLowerCase().includes(title.toLowerCase()));

        if (items.length > 0) {
          result.push({
            ...obj,
            items,
          });
        }
      });
    }
    setList(result);
  };
  return (
    <>
      {isOpen && (
        <div
          data-testid='modal-overlay'
          className='modal-overlay'
          onClick={() => {
            toggle();
            // enable scrolling when modal is closed
            document.body.style.overflow = 'initial';
          }}
        >
          <div data-testid='modal' onClick={(e) => e.stopPropagation()} className='modal-box modal-box-medium'>
            <div className='modal-box-header'>
              <div className='modal-box-header-search'>
                <div className='modal-box-header-search-left'>
                  <div className='modal-box-header-search-icon'>
                    <i className='fa fa-search'></i>
                  </div>
                  <input
                    data-testid='search-input'
                    onChange={(e) => filterItems(data, e.target.value)}
                    ref={inputRef}
                    className='modal-box-header-search-input'
                    type='text'
                    placeholder='Search or jump to'
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        const [keyword, number] = e.target.value.split(' ');
                        const url = getUrlFromPattern(keyword, number);
                        window.location.href = url || list[0]?.items[0]?.url;
                      }
                    }}
                  />
                </div>
                <div className='modal-box-header-search-right'>
                  <div className='modal-box-header-search-right-nav'>
                    <span data-testid='press-text' className='modal-box-header-search-right-text-start'>
                      Press
                    </span>
                    <div className='modal-box-header-search-right-icon'>
                      <i className='fa fa-arrow-down'></i>
                    </div>
                    <span className='modal-box-header-search-right-text'>to Navigate</span>
                  </div>
                  <div>
                    <span
                      className='modal-box-header-shortcuts'
                      onClick={(e) => {
                        e.stopPropagation();
                        setShowInfoModal(true);
                      }}
                    >
                      Shortcuts
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className='modal-box-body'>
              {list &&
                list.map((item, index) => {
                  return (
                    <div key={index} className='modal-box-body-item'>
                      <div className='modal-box-body-item-title'>{item.sectionName}</div>
                      {item.items.map((subItem) => {
                        const listItemIndex = trackItemindex++;
                        return (
                          <div
                            role='button'
                            tabIndex={0}
                            key={trackItemindex}
                            onKeyDown={(e) => {
                              if (e.key === 'Enter') {
                                window.location.href = `${subItem.url}`;
                              }
                            }}
                            onClick={() => {
                              window.location.href = `${subItem.url}`;
                            }}
                            className={classNames('modal-box-body-items', {
                              [`modal-box-body-items-${listItemIndex + 1}`]: true,
                            })}
                            data-testid='modal-box-body-item'
                          >
                            <div className='modal-box-body-items-left'>
                              <div data-testid='modal-box-body-items-icon' className='modal-box-body-items-icon'>
                                {subItem.icon}
                              </div>
                              <div className='modal-box-body-items-title'>
                                {subItem.title.length > 48 ? `${subItem.title.substring(0, 45)}...` : subItem.title}
                              </div>
                            </div>
                            <div className='modal-box-body-items-goto'>
                              <span className='modal-box-body-items-goto-text'>Press Enter to Jump</span>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
            </div>
          </div>
          <InfoModal
            isOpen={showInfoModal}
            onClose={(e) => {
              e.stopPropagation();
              setShowInfoModal(false);
            }}
          />
        </div>
      )}
    </>
  );
};

export default NavigationModal;
