import React, { Component } from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Row,
  Col
} from 'reactstrap';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import './SaveOfferActionModal.css';
import { saveAsOfferName } from '../../../actions';
class SaveAsOfferActionModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      success: false,
      newOfferName: ''
    };

    this.toggleSuccess = this.toggleSuccess.bind(this);
    this.saveSuccess = this.saveSuccess.bind(this);
  }

  saveSuccess = () => {
    this.setState({
      success: !this.state.success
    });
    return this.props.onClickSubmit();
  };

  toggleSuccess() {
    this.setState({
      success: !this.state.success
    });
  }

  // componentWillReceiveProps(nextProps) {
  //   nextProps.isOfferSaved &&
  //     setTimeout(() => {
  //       this.props.saveOffer(false);
  //     }, 3000);
  // }

  handleInputChange = event => {
    const { value, name } = event.target;
    this.setState({
      [name]: value
    });
    this.props.saveAsOfferName(value);
  };

  render() {
    // const isDisabled = Object.keys(errors).some(x => errors[x]);
    // const { isOfferSaved } = this.props;
    // const { isAdvertiserMissing, areUserAttributesMissing } = errors;
    // const shouldErrorRender =
    //   Object.keys(errors).some(x => errors[x]) ||
    //   isAdvertiserMissing ||
    //   areUserAttributesMissing;
    return (
      <div className='animated'>
        {/* {shouldErrorRender && <Alert color='danger'>{errors._error}</Alert>} */}
        <Link to='/campaigns/offeractions'>
          {' '}
          <Button
            //  disabled={isDisabled}
            className='saveAsBtn'
          >
            Cancel
          </Button>
        </Link>
        <Button
          color='success'
          onClick={this.toggleSuccess}
          // disabled={isDisabled}
          className='saveAsBtn'
        >
          Copy Action
        </Button>
        <Modal
          isOpen={this.state.success}
          toggle={this.toggleSuccess}
          className={'modal-warning ' + this.props.className}
        >
          {/* {errors.advertiser && <strong>{errors.advertiser}</strong>} */}
          <ModalHeader toggle={this.toggleSuccess}>Copy</ModalHeader>
          <ModalBody>
            <Row>
              <Col
                lg={2}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  color: '#f86c6b'
                }}
              >
                <i className='fa fa-warning fa-3x'></i>
              </Col>
              <Col lg={10}>
                <h3>{this.props.content && this.props.content.name}</h3>
                Any changes made without saving will be <strong>
                  copied
                </strong>{' '}
                to the new offer action but not reflected in the{' '}
                <strong>current</strong> offer until you click save offer
                action.
              </Col>
            </Row>
            <br />
            <Input
              name='newOfferName'
              placeholder='New Offer Action Name'
              type='text'
              onChange={this.handleInputChange}
              value={this.state.newOfferName}
            />
          </ModalBody>
          <ModalFooter>
            <Button color='success' onClick={this.saveSuccess}>
              Copy
            </Button>
            <Button color='secondary' onClick={this.toggleSuccess}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { advertiser, isOfferSaved } = state.offerState;
  // const criteriaUserAttributes = selector(state, 'criteriaUserAttributes');

  return {
    advertiser,
    // criteriaUserAttributes,
    isOfferSaved
  };
}
export default connect(mapStateToProps, { saveAsOfferName })(
  SaveAsOfferActionModal
);
