import { useParams, useHistory } from 'react-router-dom';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  Label,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Button,
  FormFeedback,
} from 'reactstrap';
import CopyContainerModal from './modals/CopyContainerModal';
import DeleteConfirmationModal from '../../components/DeleteConfirmationModal/DeleteConfirmationModal';
import './ContainerDetails.css';
import { useCreateContainer, useUpdateContainer, useContainer, useOfferTypes } from './queries';
import { useDeleteContainer } from './queries';
import { ZuulToaster } from '../../components/Toaster/ZuulToaster';
import ZuulLoader from '../../components/ZuulLoader/ZuulLoader';
import { ZuulEditor } from '../../components/ZuulEditor/ZuulEditor';

export const isValidName = (name) => !!name && name.length > 0 && /^[^<>:"/\\|?*\s]+$/i.test(name);

const ContainersDetails = () => {
  const { containerId } = useParams();
  const history = useHistory();
  const { data: container, isContainerLoading, error } = useContainer(containerId);
  const { data: offerTypes, isOfferTylesLoading } = useOfferTypes();
  const [containerData, setContainerData] = useState();
  const [showCopyModal, setShowCopyModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [validationErrors, setValidationErrors] = useState(false);
  const { mutateAsync: updateContainer, isLoading: isUpdating } = useUpdateContainer();
  const { mutateAsync: createContainer, isLoading: isCreating } = useCreateContainer();
  const { mutateAsync: deleteContainer, isLoading: isDeleting } = useDeleteContainer();

  const isProcessing = isUpdating || isCreating || isDeleting;
  const zuulUser = JSON.parse(localStorage.getItem('userData'));

  const handleSave = useCallback(() => {
    const updatedContainerData = { ...containerData, zuulUser };
    if (containerData.id) {
      toast.promise(updateContainer(updatedContainerData), {
        loading: 'Updating container...',
        success: (data) => (data.status === 200 ? 'Container updated successfully!' : 'No content changes detected.'),
        error: (err) => err?.response?.data?.message || err.message,
      });
    } else {
      toast.promise(createContainer(updatedContainerData), {
        loading: 'Creating container...',
        success: 'Container created successfully!',
        error: (err) => err?.response?.data?.message || err.message,
      });
    }
  }, [containerData, updateContainer, createContainer]);

  const handleDelete = useCallback(() => {
    toast.promise(deleteContainer(containerData.id), {
      loading: 'Deleting container...',
      success: 'Container deleted successfully!',
      error: (err) => err?.response?.data?.message || err.message,
    });
  }, [containerData, deleteContainer]);

  const handleOfferTypeChange = (event) => {
    console.log(event.target.value);
    setContainerData({ ...containerData, offerTypeHint: event.target.value });
  };

  const handleEditorChange = (value, errors) => {
    setValidationErrors(errors);
    if (errors) {
      toast.dismiss();
      toast.error('Invalid HTML detected, please fix the highlighted errors!', {duration: Infinity});
    } else {
      toast.dismiss();
    }
    setContainerData({ ...containerData, content: value });
  };

  const handleNameChange = (event) => {
    setContainerData({ ...containerData, name: event.target.value });
  };

  useEffect(() => {
    if (isNaN(containerId)) {
      setContainerData({ id: null, name: '', offerTypeHint: '', content: '' });
    }
  }, [containerId]);

  useEffect(() => {
    if (!isContainerLoading && container) {
      setContainerData(container);
    }
    if (!isContainerLoading && error) {
      toast.error('Error Loading Container!');
    }
  }, [isContainerLoading, container, error]);

  if (isContainerLoading || isOfferTylesLoading) {
    return <ZuulLoader text={'Loading container...'} />;
  }

  return (
    <div className='animated fadeIn'>
      {offerTypes && containerData && (
        <>
          <Card>
            <CardHeader>
              <Row>
                <Col lg={4} style={{ display: 'flex', alignItems: 'baseline' }}>
                  <Label for='container-name' style={{ width: '4em' }}>
                    Name:
                  </Label>
                  <InputGroup>
                    <InputGroupAddon addonType='prepend'>
                      <InputGroupText>
                        <i className='fa fa-edit' />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      required
                      type='text'
                      id='container-name'
                      name='container-name'
                      value={containerData.name}
                      onChange={handleNameChange}
                      placeholder='Container name'
                      style={{ justifyContent: 'center' }}
                      invalid={!isValidName(containerData.name)}
                    />
                    <FormFeedback>
                      {'The name cannot be empty or contain spaces nor any of the following characters: <>:"/\\|?*'}
                    </FormFeedback>
                  </InputGroup>
                </Col>
                <Col lg={3} style={{ display: 'flex', alignItems: 'baseline' }}>
                  <Label for='offer-type-hint' style={{ width: '7em' }}>
                    Offer Type:
                  </Label>
                  <Input
                    type='select'
                    value={containerData.offerTypeHint}
                    id='offer-type-hint'
                    onChange={handleOfferTypeChange}
                    style={{ justifyContent: 'center' }}
                  >
                    {!isOfferTylesLoading && renderOptionTypes(offerTypes, !!containerData.id)}
                  </Input>
                </Col>
                {!!containerData.id && (
                  <Col lg={2} style={{ display: 'flex', alignItems: 'center' }}>
                    Id: {containerData.id}
                  </Col>
                )}
                <Col
                  lg={3}
                  style={{ display: 'flex', alignItems: 'baseline', justifyContent: 'flex-end', marginLeft: 'auto' }}
                >
                  <Button
                    color='success'
                    onClick={handleSave}
                    disabled={!isValidName(containerData.name) || isProcessing || validationErrors}
                  >
                    {!!containerData.id ? 'Save' : 'Create'}
                  </Button>
                  {!!containerData.id && (
                    <Button
                      color='primary'
                      onClick={() => setShowCopyModal(true)}
                      style={{ marginLeft: '1em' }}
                      disabled={isProcessing || validationErrors}
                    >
                      Copy
                    </Button>
                  )}
                  <Button
                    color='secondary'
                    className='text-nowrap'
                    style={{ marginLeft: '1em' }}
                    onClick={() => history.push('/content/containers')}
                    disabled={isProcessing}
                  >
                    Cancel
                  </Button>
                  {!!containerData.id && (
                    <Button
                      color='danger'
                      style={{ marginLeft: '1em' }}
                      onClick={() => setShowDeleteModal(true)}
                      disabled={isProcessing || validationErrors}
                    >
                      <i className='fa fa-trash' />
                    </Button>
                  )}
                </Col>
              </Row>
            </CardHeader>
            <CardBody style={{ padding: 0 }}>
              <Row>
                <Col lg='12'>
                  <ZuulToaster
                    position={'top-right'}
                    style={{ minWidth: '250px', fontSize: '1.1em' }}
                    customContainerStyle={{
                      position: 'relative',
                      left: -40,
                    }}
                  />
                  <ZuulEditor
                    content={containerData.content}
                    handleEditorChange={handleEditorChange}
                    minimapOn />
                </Col>
              </Row>
            </CardBody>
          </Card>
          {container && (
            <>
              <CopyContainerModal
                isOpen={showCopyModal}
                container={containerData}
                closeModal={() => setShowCopyModal(false)}
                onCopy={createContainer}
              />
              <DeleteConfirmationModal
                type='container'
                isOpen={showDeleteModal}
                closeModal={() => setShowDeleteModal(false)}
                onConfirm={handleDelete}
              />
            </>
          )}
        </>
      )}
    </div>
  );
};

const renderOptionTypes = (offerTypes) => {
  return (
    <>
      <option key='None' value=''>
        None
      </option>
      {offerTypes.map((ot) => (
        <option key={ot.id} value={ot.id}>
          {ot.name}
        </option>
      ))}
    </>
  );
};

export default ContainersDetails;
