import _fp from 'lodash/fp';
import { addDifferenceCurrencyFormatting, getColorClassName } from '../../../utils/currencyDisplay';

/********************** Details Comparison Function **********************/
const sortOrderByFn = (os1, os2) => os1.orderBy - os2.orderBy;

const hasOfferAndPositiveOrderBy = offerSequence => !_fp.isNil(offerSequence.offer) && offerSequence.orderBy > 0;

const isReturnUrlType = offerSequence =>
  !_fp.isNil(offerSequence.offer) &&
  !_fp.isNil(offerSequence.offer.offerType) &&
  !_fp.eq(offerSequence.offer.offerType.name)('Return Url');

const makeControlTableData = ({ id, offer }) => ({
  id,
  offerId: offer?.id,
  name: offer?.name || 'N/A',
  advertiser: offer?.advertiser?.name || 'N/A',
  offerType: offer?.offerType?.name || 'N/A'
});

const makeControlSeqsTableData = controlSeqs =>
  controlSeqs
    .filter(hasOfferAndPositiveOrderBy)
    .filter(isReturnUrlType)
    .map(makeControlTableData);

const makeRecommendedSeqsTableData = (controlSeqsTableData, recommendedSeqs) => {
  const addToMapByOfferId = (obj, td) => ({ ...obj, [td.offerId]: td });
  const rowDataMappedByOfferId = _fp.reduce(addToMapByOfferId, {})(controlSeqsTableData);
  return recommendedSeqs
    .map(rs => ({
      ...rs,
      ...rowDataMappedByOfferId[rs.offerId],
      highlighted: rs.controlOfferSequenceId || rs.controlOfferSequenceId === 0
    }))
    .sort(sortOrderByFn);
};

/********************** Details Scorecard Functions **********************/

// Difference displays any values (in dollars) that should have +/-
// Revenue displays total value (in dollars). This does not have +/-
// Basic displays total amount, which is unitless. This does not have $ or +/-
const valueType = {
  REVENUE: 'revenue',
  DIFFERENCE: 'difference',
  BASIC: 'basic'
};

const createScorecardTableData = ({
  totalImpressions,
  revTotalControl,
  revTotalTest,
  revTotalDifference,
  revPerSessionControl,
  revPerSessionTest,
  revPerSessionDifference
}) => [
  {
    label: 'Total Impressions',
    value: totalImpressions,
    valueType: valueType.BASIC
  },
  {
    label: 'Revenue Per Session Control',
    value: revPerSessionControl,
    valueType: valueType.REVENUE
  },
  {
    label: 'Revenue Per Session Test',
    value: revPerSessionTest,
    valueType: valueType.REVENUE
  },
  {
    label: 'Revenue Per Session Difference',
    value: revPerSessionDifference,
    valueType: valueType.DIFFERENCE
  },
  {
    label: 'Revenue Total Control',
    value: revTotalControl,
    valueType: valueType.REVENUE
  },
  {
    label: 'Revenue Total Test',
    value: revTotalTest,
    valueType: valueType.REVENUE
  },
  {
    label: 'Revenue Total Difference',
    value: revTotalDifference,
    valueType: valueType.DIFFERENCE
  }
];

const createSubHeadlines = ({id, name, site } = {}) => ([
    {
      label: 'Site Id',
      value: site?.id
    },
    {
      label: 'Site Name',
      value: site?.domain
    },
    {
      label: 'Sequence Id',
      value: id
    },
    {
      label: 'Sequence Name',
      value: name
    }
  ])

  const getDifferenceColorClassName = (value, type) => {
    if (type === valueType.DIFFERENCE) return getColorClassName(value);
  };
  
  const formatTableBodyRow = ({ value, valueType: type }) => {
    if (!value) return;
    if (type === valueType.DIFFERENCE) return addDifferenceCurrencyFormatting(value);
    if (type === valueType.REVENUE) return `$${value.toLocaleString()}`;
    if (type === valueType.BASIC) return value.toLocaleString();
  };

  

export { createScorecardTableData, createSubHeadlines, getDifferenceColorClassName, formatTableBodyRow, makeControlSeqsTableData, makeRecommendedSeqsTableData };
