import React, { Component } from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col
} from 'reactstrap';
import { Link } from 'react-router-dom';
import './SaveOfferModal.css';
class CancelOfferModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      success: false
    };

    this.toggleSuccess = this.toggleSuccess.bind(this);
    this.saveSuccess = this.saveSuccess.bind(this);
  }

  saveSuccess = () => {
    this.setState({
      success: !this.state.success
    });
  };

  toggleSuccess() {
    this.setState({
      success: !this.state.success
    });
  }

  render() {
    return (
      <div className='animated'>
        <Button onClick={this.toggleSuccess} className='saveOfferBtn'>
          Cancel
        </Button>

        {/* &nbsp;&nbsp;&nbsp;&nbsp; */}
        <Modal
          isOpen={this.state.success}
          toggle={this.toggleSuccess}
          className={'modal-danger ' + this.props.className}
        >
          <ModalHeader toggle={this.toggleSuccess}>Cancel</ModalHeader>
          <ModalBody>
            <Row>
              <Col
                lg={2}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  color: '#f86c6b'
                }}
              >
                <i className='fa fa-warning fa-3x'></i>
              </Col>
              <Col lg={10} style={{ paddingTop: '2%' }}>
                Are you sure you want to <strong>cancel</strong>? All changes
                will be lost.
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            {/* <Link to='/campaigns/offertable'> */}
            <Button color='danger' onClick={this.toggleSuccess}>
              Nevermind
            </Button>
            {/* </Link> */}
            <Link to='/campaigns/offertable'>
              <Button color='secondary'>Yes, cancel</Button>
            </Link>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default CancelOfferModal;
