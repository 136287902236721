import React, { Component } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import { withRouter } from 'react-router-dom';
import { Badge, Col, Row } from 'reactstrap';
import { sortByName } from '../../actions';
import api from '../../services';
import { formatDate } from '../../utils/conversionFunctions';
import { columnFormatter } from '../../utils/tableFunctions';
import './RecentOffersTable.css';
import ZuulLoader from '../../components/ZuulLoader/ZuulLoader';

class RecentOfferActions extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.idleTimer = null;
    this.state = {
      offerActions: [],
      isActive: null,
      offerSelection: [],
      actionType: 'offer_action_linkout',
      render: false,
      toOfferActionDetail: false,
      toId: null,
      page: 1,
      sizePerPage: 10,
      paginationSize: 10,
      totalElements: 10,
      isEditMode: false,
      offerType: 'Question',
      loading: true,
      errors: {},
    };
    this.options = {
      onPageChange: this.onPageChange.bind(this),
      onSizePerPageList: this.sizePerPageListChange.bind(this),
      page: this.state.page,
      sizePerPage: this.state.sizePerPage,
      paginationSize: this.state.paginationSize,
      sizePerPageDropDown: this.renderSizePerPageDropDown,
      // defaultSortName: 'lastPublished',
      // defaultSortOrder: 'desc',
      prePage: 'Prev',
      nextPage: 'Next',
      firstPage: 'First',
      lastPage: 'Last',
      paginationShowsTotal: true,
      paginationPosition: 'bottom',
      alwaysShowAllBtns: true,
      withFirstAndLast: true,
    };

    this.getData = this.getData.bind(this);
  }

  renderSizePerPageDropDown = (props) => {
    return (
      <div className='btn-group'>
        {[10, 25, 30, 50].map((n, idx) => {
          const isActive = n === props.currSizePerPage ? 'active' : null;
          return (
            <button
              key={idx}
              type='button'
              className={`btn btn-info ${isActive}`}
              onClick={() => props.changeSizePerPage(n)}
            >
              {n}
            </button>
          );
        })}
      </div>
    );
  };

  parseAdvertiser(offer) {
    // console.log(offer);
    if (offer.offerActionLinkout) {
      return offer.offerActionLinkout.advertiser ? offer.offerActionLinkout.advertiser.name : 'No Advertiser';
    } else if (offer.offerActionPost) {
      return offer.offerActionPost.advertiser ? offer.offerActionPost.advertiser.name : 'No Advertiser';
    } else if (Array.isArray(offer.offerActionUserAttributes) && offer.offerActionUserAttributes) {
      return 'No Advertiser';
    }
  }
  renderOffers(response) {
    return response.data.content.map((offer) => {
      const advertsier = this.parseAdvertiser(offer);
      const offerActionSubtypeId = offer.offerActionPost
        ? offer.offerActionPost.id
        : offer.offerActionLinkout
        ? offer.offerActionLinkout.id
        : null;
      const offerActionSubtype = offer.offerActionPost
        ? offer.offerActionPost
        : offer.offerActionLinkout
        ? offer.offerActionLinkout
        : offer.offerActionUserAttributes && offer.offerActionUserAttributes.length > 0
        ? offer.offerActionUserAttributes[0]
        : null;
      return {
        id: offer.id !== null && offer.id !== undefined ? offer.id : '',
        offerActionSubtypeId,
        offerActionSubtype,
        title: offer.title !== null && offer.title !== undefined ? offer.title : '',
        offerActionCriteria: offer.offerActionCriteria,
        offerActionType: offer.offerActionType !== null && offer.offerActionType !== null ? offer.offerActionType : '',
        active: offer.active !== null ? offer.active : false,
        advertiserName: advertsier,
        lastEdited: offer.lastEdited !== null ? formatDate(offer.lastEdited) : '',
      };
    });
  }

  parseOffers(response) {
    const offers = response.data.content.map((offer) => {
      return { id: offer.id, name: offer.name };
    });
    return sortByName(offers);
  }

  getData(page = this.state.page, limit = this.state.sizePerPage) {
    let offset = page - 1;
    api
      .get(`offer-action?limit=${limit}&offset=${offset}`)
      .then((response) => {
        if (this._isMounted) {
          console.log(response);
          const existingOffer = this.renderOffers(response);
          const totalPages = response.data.totalPages;
          const sortedOfferActions = existingOffer.sort((a, b) => this.sortDates(a, b, 'asc'));
          const total = response.data.totalElements;
          // const newState = Object.assign({}, this.state, {
          //   offerActions: sortedOfferActions,
          //   totalElements: total,
          //   totalPages,
          //   loading: false,
          //   render: true
          // });
          // console.log(newState);
          this.setState({
            offerActions: sortedOfferActions,
            totalElements: total,
            totalPages,
            loading: false,
            render: true,
          });
        }
      })
      .catch((error) => console.log(error));
  }

  updateData(page = this.state.page, limit = this.state.sizePerPage) {
    let offset = page - 1;
    let existingOffer = this.state.offer;
    api
      .get(`offer-action?limit=${limit}&offset=${offset}`)
      .then((response) => {
        if (this._isMounted) {
          const newOffers = this.renderOffers(response);
          const first = response.data.pageable.offset;
          const num = response.data.numberOfElements;
          for (let i = first, j = 0; j < num; i++, j++) {
            existingOffer[i] = newOffers[j];
          }

          const newState = Object.assign({}, this.state, {
            offerActions: existingOffer,
          });
          this.setState(newState);
        }
      })
      .catch((error) => console.log(error));
  }

  componentDidMount() {
    this._isMounted = true;
    this.setState({ loading: true });
    this.getData();
    // setTimeout(
    //   function() {
    //     //Start the timer
    //     this.setState({ render: true }); //After 1 second, set render to true
    //     this.getData(1, this.state.totalElements);
    //   }.bind(this),
    //   500
    // );
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  activeStatusIcon(row) {
    if (row.id) {
      let isActive = !row.active;

      if (isActive) {
        return (
          <Badge className='mr-1' color='danger'>
            Inactive
          </Badge>
        );
      } else {
        return (
          <Badge className='mr-1' color='success'>
            Active
          </Badge>
        );
      }
    }
  }

  sizePerPageListChange(sizePerPage) {
    this.updateData(1, sizePerPage);
  }

  onPageChange(page, sizePerPage) {
    this.updateData(page, sizePerPage);
  }

  sortDates(a, b, order) {
    var dateA = new Date(a.lastPublished).getTime();
    var dateB = new Date(b.lastPublished).getTime();
    if (order === 'asc') {
      return dateA > dateB ? -1 : 1;
    }
    return dateA > dateB ? 1 : -1;
  }

  render() {
    const URL = '/campaigns/offeractions/';
    const columns = [
      {
        dataField: 'title',
        text: 'Action Name',
        formatter: (cell, row) => {
          return columnFormatter({
            cell,
            row,
            computedURL: URL + row.id,
          });
        },
        headerStyle: {
          backgroundColor: '#2f353a',
        },
      },
      {
        dataField: 'advertiserName',
        text: 'Advertiser Name',
        formatter: (cell, row) => {
          return columnFormatter({
            cell,
            row,
            computedURL: URL + row.id,
          });
        },
        headerStyle: {
          backgroundColor: '#2f353a',
        },
      },
      {
        dataField: 'offerActionType',
        text: 'Action Type',
        formatter: (cell, row) => {
          return columnFormatter({
            cell,
            row,
            computedURL: URL + row.id,
          });
        },
        headerStyle: {
          backgroundColor: '#2f353a',
        },
      },
      {
        dataField: 'lastEdited',
        text: 'Last Published',
        formatter: (cell, row) => {
          return columnFormatter({
            cell,
            row,
            computedURL: URL + row.id,
          });
        },
        headerStyle: {
          backgroundColor: '#2f353a',
        },
      },
      {
        dataField: 'action',
        text: 'Status',
        isDummyField: true,
        headerStyle: {
          backgroundColor: '#2f353a',
        },
        formatter: (cell, row) => {
          return columnFormatter({
            cell,
            row,
            computedURL: URL + row.id,
            activeStatusType: 'iconAndText',
          });
        },
      },
    ];
    const rowEvents = {
      onMouseEnter: (e, row, rowIndex) => {
        this.setState({ activeRow: rowIndex });
      },
    };

    const rowStyle = (row, rowIndex) => {
      const style = {};
      if (rowIndex === this.state.activeRow) {
        style.backgroundColor = '#2f353a';
        style.cursor = 'pointer';
      }
      return style;
    };

    return (
      <div className='animated fadeIn'>
        <ToolkitProvider keyField='id' data={this.state.offerActions} columns={columns} search>
          {(props) => (
            <div>
              {this.state.loading === true && (
                <ZuulLoader text={`Loading Offer Actions...`} />
              )}
              {this.state.loading === false && (
                <div id='recent-offer-actions'>
                  <br />
                  <BootstrapTable bordered={false} {...props.baseProps} rowEvents={rowEvents} rowStyle={rowStyle} />
                </div>
              )}
            </div>
          )}
        </ToolkitProvider>
      </div>
    );
  }
}

export default withRouter(RecentOfferActions);
