import React, { Component } from 'react';
import { Card, CardHeader, CardBody, Button, Label, Row, Col } from 'reactstrap';
import { connect } from 'react-redux';
import './MarketingPartners.css';
import ActionMarketingPartner from '../MarketingPartners/Modals/ActionMarketingPartner';
import '../../../node_modules/react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import api from '../../services';
import UpdateStatus from './Modals/UpdateStatus';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { fetchAllAdvertisers } from '../Advertisers/actions';
import SearchLabel from '../../components/SearchLabel/SearchLabel';
import { fetchAllEntities } from '../../actions';

class MarketingPartners extends Component {
  constructor(props) {
    super(props);
    this.state = {
      marketingpartner: [],
      timeRemaining: null,
      timer: { m: 0, s: 0 },
      showComponent: false,
      record: null,
      advertisers: [],
      offerActions: [],
    };

    // this.switchFormatter = this.switchFormatter.bind(this);
    this.switchHandler = this.switchHandler.bind(this);
    this.refreshPartners = this.refreshPartners.bind(this);
    //this.buttonFormatter = this.buttonFormatter.bind(this);
  }
  interval = null;

  renderSizePerPageDropDown = (props) => {
    return (
      <div className='btn-group'>
        {[10, 25, 30, 50].map((n, idx) => {
          const isActive = n === props.currSizePerPage ? 'active' : null;
          return (
            <button
              key={idx}
              type='button'
              className={`btn btn-info ${isActive}`}
              onClick={() => props.changeSizePerPage(n)}
            >
              {n}
            </button>
          );
        })}
      </div>
    );
  };

  getTimeRemaining = () => {
    api
      .get(`timer/time_remaining/marketing_partners`)
      .then((response) => {
        this.setState(
          {
            timeRemaining: response.data,
            timer: this.convertMiliseconds(response.data),
          },
          this.setCountdown
        );
      })
      .catch((error) => console.log(error));
  };

  setCountdown() {
    // set an interval that will increment the idleTime property by 1000 for every second that passes. If idleTime is greater than the allowed idle time, log user out.
    this.interval = setInterval(() => {
      const shouldSync = this.state.timeRemaining <= 0;
      if (shouldSync) {
        clearInterval(this.interval);
        return this.setState({
          timeRemaining: 0,
          timer: this.convertMiliseconds(300000),
        });
      }
      let updatedTime = this.state.timeRemaining - 1000;
      this.setState({
        timeRemaining: updatedTime,
        timer: this.convertMiliseconds(updatedTime),
      });
    }, 1000);
  }

  async componentDidMount() {
    await this.refreshPartners();
    this.getTimeRemaining();
    this.props.fetchAllEntities('offer-action/table');
    await this.props.fetchAllAdvertisers();
    this.setState({ advertisers: this.props.advertisers });
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  handleSync = () => {
    api.put(`marketing_partner/sync/5`).then((response) => {
      clearInterval(this.interval);
      this.setState({ timeRemaining: response.data }, this.setCountdown);
    });
  };

  // updateTimer = () => {};

  async refreshPartners() {
    await api
      .get(`marketing_partner`)
      .then((response) => {
        this.setState({ marketingpartner: response.data });
      })
      .catch((error) => console.log(error));
  }

  switchHandler(row) {
    if (row.id) {
      row.status = row.status ? false : true;
      let saveData;
      saveData = {
        id: row.id,
        name: row.name,
        status: row.status,
      };
      api.put(`marketing_partner/status/${row.id}`, saveData).then((result) => {
        this.refreshPartners();
      });
    }
  }

  switchFormatter = (cell, row) => {
    return (
      <UpdateStatus
        isActive={row.status}
        content={row}
        onClickSubmit={(activeStatus) => this.switchHandler(row, activeStatus)}
        isEditMode={true}
      />
    );
  };

  modalToggle(row) {
    this.setState({
      showComponent: this.state.showComponent ? false : true,
      record: row,
    });
  }

  convertMiliseconds(miliseconds) {
    var minutes, seconds, total_minutes, total_seconds;

    total_seconds = parseInt(Math.floor(miliseconds / 1000));
    total_minutes = parseInt(Math.floor(total_seconds / 60));
    seconds = parseInt(total_seconds % 60);
    seconds = seconds < 10 ? '0' + seconds : seconds;
    minutes = parseInt(total_minutes % 60);
    return { m: minutes, s: seconds };
  }

  render() {
    const options = {
      sizePerPageDropDown: this.renderSizePerPageDropDown,
    };
    const { SearchBar, ClearSearchButton } = Search;
    const columns = [
      {
        dataField: 'id',
        text: 'ID',
        headerStyle: {
          backgroundColor: '#2f353a',
        },
      },
      {
        dataField: 'name',
        text: 'Name',
        sort: true,
        headerStyle: {
          backgroundColor: '#2f353a',
        },
      },
      {
        dataField: 'advertiser.id',
        text: 'AdvertiserID',
        sort: true,
        headerStyle: {
          backgroundColor: '#2f353a',
        },
      },
      {
        dataField: 'status',
        text: 'Status',
        sort: true,
        isDummyField: true,
        formatter: this.switchFormatter,
        headerStyle: {
          backgroundColor: '#2f353a',
          width: '5%',
        },
      },
      {
        dataField: 'active',
        text: 'Action',
        isDummyField: true,
        formatter: (cell, row) => (
          <div>
            <Button color='success' onClick={this.modalToggle.bind(this, row)}>
              <i className='fa fa-edit ' />
              &nbsp; Edit
            </Button>
          </div>
        ),
        headerStyle: {
          backgroundColor: '#2f353a',
          width: '9%',
        },
      },
    ];
    return (
      <div className='animated fadeIn'>
        {this.state.showComponent ? (
          <ActionMarketingPartner
            action={this.modalToggle.bind(this, false)}
            record={this.state}
            refreshCallBack={this.refreshPartners}
            allOfferActions={this.props.offerActions}
            offerActionSubtypes={this.props.offerActionSubtypes}
          />
        ) : null}
        <Card>
          <CardHeader>
            <Row>
              <Col lg={9}>
                <i className='icon-menu' />
                &nbsp; Marketing Partners
              </Col>
              {this.state.timeRemaining > 0 ? (
                <Col lg={3}>
                  <p className='sync-alert' color='warning' style={{ marginBottom: '0px', fontStyle: 'italic' }}>
                    Time until next available sync : {`${this.state.timer.m} : ${this.state.timer.s}`}
                  </p>
                </Col>
              ) : (
                <Col lg={3} />
              )}
            </Row>
          </CardHeader>
          <CardBody>
            <ToolkitProvider
              keyField='id'
              data={this.state.marketingpartner}
              columns={columns}
              search
              bootstrap4={true}
            >
              {(props) => (
                <div>
                  <Row>
                    <Col lg={5}>
                      <span className='display-4'>Marketing Partners</span>
                    </Col>
                    <Col lg={7}>
                      <Button color='primary' onClick={this.modalToggle.bind(this)} className='to-right'>
                        <i className='fa fa-plus' /> Add Marketing Partner
                      </Button>
                      <Button
                        color='primary'
                        onClick={this.handleSync}
                        disabled={this.state.timeRemaining > 0}
                        className='to-right'
                      >
                        <i className='fa fa-check' /> Sync Marketing Partners
                      </Button>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col lg={9}>
                      <SearchLabel />
                      <br />
                      <SearchBar {...props.searchProps} />
                    </Col>
                  </Row>

                  <br />
                  <BootstrapTable hover {...props.baseProps} pagination={paginationFactory()} />
                </div>
              )}
            </ToolkitProvider>
          </CardBody>
        </Card>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { advertisers } = state.advertiser;
  return {
    advertisers,
    offerActions: state.offerAction.offerActions,
    offerActionSubtypes: state.offerAction.offerActionSubtypes,
  };
}
export default connect(mapStateToProps, { fetchAllAdvertisers, fetchAllEntities })(MarketingPartners);
