import { getAllAdditionalRequests } from '../../../actions';
import {
  GET_SITES,
  GET_LANDING_PAGE_GROUP,
  GET_ALL_LANDING_PAGE_GROUPS,
  SET_LANDING_PAGE_GROUP,
  UPDATE_LANDING_PAGE_GROUP,
  UPDATE_SELECTED_LANDING_PAGES,
  CREATE_LANDING_PAGE_GROUP,
  UPDATE_LANDING_PAGE_GROUP_ERROR,
  UPDATE_LANDING_PAGE_GROUP_WARNING,
} from './types';
import axios from 'axios';
import api from '../../../services';
export const fetchAllSites = () => (dispatch) => {
  try {
    return api.get(`sites`).then((response) => {
      const parsedSites = response.data.map((site) => {
        return {
          id: site.id,
          domain: site.domain,
        };
      });
      dispatch({
        type: GET_SITES,
        payload: parsedSites,
      });
      return parsedSites;
    });
  } catch (error) {
    console.log(error);
  }
};

export const getLandingPageGroup = (landingPageGroupId) => (dispatch) => {
  if (landingPageGroupId) {
    return new Promise((resolve) => {
      api.get(`landing-page-groups/${landingPageGroupId}`).then((response) => {
        dispatch({
          type: GET_LANDING_PAGE_GROUP,
          payload: response.data,
        });
        resolve(response.data);
      });
    });
  }
  dispatch({
    type: GET_LANDING_PAGE_GROUP,
    payload: {},
  });
};

export const getAllLandingPageGroups = (limit = 100) => (dispatch) => {
  return new Promise((resolve) => {
    api
      .get(`landing-page-groups?limit=${limit}&offset=${0}`)
      .then((response) => {
        // Set initial data
        let allLandingPageGroupData = Array.from(response.data.content);

        // Retrieve remaining pages
        const { totalPages } = response.data;
        let restOfLandingPages = getAllAdditionalRequests(totalPages, limit, 'landing-page-groups');

        // Concurrently fire all page requests
        axios.all(restOfLandingPages).then((responses) => {
          console.log(responses);
          // Add remaining response data
          responses.forEach((response) => {
            let { content } = response.data;
            allLandingPageGroupData = [...allLandingPageGroupData, ...content];
          });

          dispatch({
            type: GET_ALL_LANDING_PAGE_GROUPS,
            payload: allLandingPageGroupData,
          });
          resolve(allLandingPageGroupData);
          // })
        });
      })
      .catch((err) => console.log(err));
  });
};

export const setLandingPageGroup = (landingPageGroup) => {
  return {
    type: SET_LANDING_PAGE_GROUP,
    payload: landingPageGroup,
  };
};

export const updateLandingPageGroup = (landingPageGroup) => (dispatch) => {
  dispatch({
    type: UPDATE_LANDING_PAGE_GROUP,
    payload: landingPageGroup,
  });
};

export const updateSelectedLPs = (index, selectedLPs, isUnmount) => {
  return {
    type: UPDATE_SELECTED_LANDING_PAGES,
    payload: [index, selectedLPs, isUnmount],
  };
};

export const updateLandingPageGroupError = (e) => {
  if (e.response.status === 504) {
    return {
      type: UPDATE_LANDING_PAGE_GROUP_WARNING,
      payload:
        'This landing page group has a large amount of landing page placements. As a result, the template update process will take a little longer than usual. Please check for your template changes in about 5 or more minutes.',
    };
  }
  return {
    type: UPDATE_LANDING_PAGE_GROUP_ERROR,
    payload: e.response.data.message,
  };
};

export const createLandingPageGroup = (landingPageGroup) => (dispatch) => {
  dispatch({
    type: CREATE_LANDING_PAGE_GROUP,
    payload: landingPageGroup,
  });
};

export const createLandingPageGroupApi = (landingPageGroup) => (dispatch) => {
  console.log(landingPageGroup);
  try {
    console.log(JSON.stringify(landingPageGroup));
    return api
      .post('landing-page-groups', landingPageGroup)
      .then((savedLandingPageGroup) => {
        console.log(savedLandingPageGroup);
        console.log('Created landing page group: ' + JSON.stringify(savedLandingPageGroup));
        createLandingPageGroup(savedLandingPageGroup)(dispatch);
      })
      .catch((savedLandingPageGroupError) => {
        dispatch(updateLandingPageGroupError(savedLandingPageGroupError));
      });
  } catch (error) {
    console.log(error);
  }
};

export const updateLandingPageGroupApi = (landingPageGroup) => (dispatch) => {
  console.log(landingPageGroup);
  try {
    console.log(JSON.stringify(landingPageGroup));
    return api
      .put(`landing-page-groups/${landingPageGroup.id}`, landingPageGroup)
      .then((savedLandingPageGroup) => {
        console.log(savedLandingPageGroup);
        console.log('Saved landing page group: ' + JSON.stringify(savedLandingPageGroup));
        updateLandingPageGroup(savedLandingPageGroup)(dispatch);
      })
      .catch((savedLandingPageGroupError) => {
        dispatch(updateLandingPageGroupError(savedLandingPageGroupError));
      });
  } catch (error) {
    console.log(error);
  }
};
