import React, { useState } from 'react';
import { FieldArray } from 'react-final-form-arrays';
import { Button, Col, Row, TabPane } from 'reactstrap';
import { useTimeIntervals } from '../../../views/OfferBlocks/hooks';
import { Condition, NumberField, SelectField } from '../../FinalForm';

const DailyRestrictionPane = ({ push, pop }) => {
  const [currentCapAmount, setCurrentCapAmount] = useState(0);
  const timeIntervals = useTimeIntervals(15, 96);

  return (
    <TabPane tabId='Daily Restriction'>
      <Row>
        <Col>
          <NumberField name='offer.criteriaDailyLimit' label='Daily Limit' />
        </Col>
      </Row>
      <Row>
        <Col>
          <label>Current Cap Amount: {currentCapAmount}</label>
        </Col>
      </Row>
      <Row>
        <Col lg={12}>
          <Button
            color='success'
            className='float-right'
            onClick={() =>
              push('offer.criteriaDayHourRestrictions', {
                restrictionDay: '',
                restriction: '',
              })
            }
          >
            Add Day Partings
          </Button>
        </Col>
      </Row>
      <FieldArray name='offer.criteriaDayHourRestrictions'>
        {({ fields }) =>
          fields.map((name, index) => (
            <Row key={name} style={{ marginTop: '1rem' }}>
              <Col lg='auto'>
                <Button
                  color='danger'
                  onClick={() => {
                    pop('offer.criteriaDayHourRestrictions', index);
                  }}
                >
                  <i className='fa fa-close' />
                </Button>
              </Col>
              <Col>
                <SelectField
                  name={`${name}.restrictionDay`}
                  options={[
                    { value: '', option: 'Select Day' },
                    { value: 'sunday', option: 'Sunday' },
                    { value: 'monday', option: 'Monday' },
                    { value: 'tuesday', option: 'Tuesday' },
                    { value: 'wednesday', option: 'Wednesday' },
                    { value: 'thursday', option: 'Thursday' },
                    { value: 'friday', option: 'Friday' },
                    { value: 'saturday', option: 'Saturday' },
                    { value: 'weekdays', option: 'Weekdays' },
                    { value: 'weekends', option: 'Weekends' },
                  ]}
                />
              </Col>
              <Col>
                <SelectField
                  name={`${name}.restriction`}
                  options={[
                    { value: '', option: 'Select Restriction' },
                    { value: 'Available', option: 'Available' },
                    { value: 'Unavailable', option: 'Unavailable' },
                    { value: 'Partially Restricted', option: 'Partially Restricted' },
                  ]}
                />
              </Col>
              <Col>
                <Condition when={`${name}.restriction`} is={['Partially Restricted']}>
                  <SelectField
                    name={`${name}.restrictionStart`}
                    options={[{ value: '', option: 'Select Start' }, ...timeIntervals]}
                  />
                </Condition>
              </Col>
              <Col>
                <Condition when={`${name}.restriction`} is={['Partially Restricted']}>
                  <SelectField
                    name={`${name}.restrictionEnd`}
                    options={[{ value: '', option: 'Select End' }, ...timeIntervals]}
                  />
                </Condition>
              </Col>
            </Row>
          ))
        }
      </FieldArray>
    </TabPane>
  );
};

export default DailyRestrictionPane;
