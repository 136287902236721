import React, { useState } from 'react';
import { Card, CardBody, CardHeader, Collapse, Nav, NavItem, NavLink, TabContent } from 'reactstrap';
import AttributePane from './AttributePane';
import DailyRestrictionPane from './DailyRestrictionPane';
import PrePingPane from './PrePingPane';
import UsersPane from './UsersPane';

const Criteria = ({ push, pop, values, allDomains, source }) => {
  const [activeTab, setActiveTab] = useState('Users');
  const [collapse, setCollapse] = useState(source === 'publisherPrePingCriteria');

  const NavTabs = ({ tab }) => (
    <NavItem>
      <NavLink active={activeTab === tab} onClick={() => setActiveTab(tab)}>
        {tab}
      </NavLink>
    </NavItem>
  );

  return (
      <Card style={source === 'publisherPrePingCriteria' ? {} : { width: '95%', position: 'absolute', zIndex: 10, left: 0, right: 0, margin: '40px auto auto auto' }}>
      <CardHeader onClick={() => setCollapse(!collapse)}>
        <h4>
          Criteria &nbsp;&nbsp;
          {collapse ? <i className='fa fa-chevron-up opened' /> : <i className='fa fa-chevron-down closed' />}
        </h4>
      </CardHeader>
      <Collapse isOpen={collapse}>
        <CardBody>
          <Nav tabs>
            <NavTabs tab='Users' />
            <NavTabs tab='Attribute' />
            <NavTabs tab='Pre-ping' />
            {source !== 'publisherPrePingCriteria' && (
              <NavTabs tab='Daily Restriction' />
            )}
          </Nav>
          <TabContent activeTab={activeTab}>
            <UsersPane collapse={collapse} allDomains={allDomains} source={source} />
            <AttributePane collapse={collapse} push={push} source={source} />
            <PrePingPane collapse={collapse} source={source} />
            {source !== 'publisherPrePingCriteria' && (
                <DailyRestrictionPane push={push} pop={pop} />
            )}
          </TabContent>
        </CardBody>
      </Collapse>
    </Card>
  );
};

export default Criteria;
