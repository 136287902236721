import React, { useState } from 'react';
import { Button, Col, Label, Row, TabPane } from 'reactstrap';
import { useOfferSummaries } from '../../views/Offers/queries';
import { useOfferActionsTable } from '../../views/OfferActions/queries';
import _ from 'lodash';
import { useHandlers } from '../../views/OfferBlocks/queries';
import { useSelector } from 'react-redux';
import { Field } from 'redux-form';
import {
  renderComboBox,
  renderDropdownList,
  renderField,
  renderPrePingMethod,
  renderToggleSwitch,
  textarea,
} from '../../views/Offers/OfferTitle/renderConsts';
import { MultiselectField, NumberField } from './index';
import InternalSuppressionItem from './InternalSuppressionItem';


export const PrePingPane = ({ criteriaType }) => {
  const [httpIsHidden, setHttpIsHidden] = useState(true);

  const { data: offers, loading, error } = useOfferSummaries();
  const { data: offerActions, loadingAction, errorAction } = useOfferActionsTable();
  const { data: handlers } = useHandlers(true, (data) => _.orderBy(data, ['name'], ['asc']));

  const prePingMethod = useSelector((state) => state.offerState.selector(state, 'prePingMethod'));
  const suppressionRules = useSelector((state) => state.offerState.selector(state, 'suppressionRules'));

  const shouldRenderSuppression = suppressionRules?.duration?.type !== 'none';
  const alphabitizedOffers = _.orderBy(offers, ['name'], ['asc']);
  const alphabitizedOfferActions = _.orderBy(offerActions, ['title'], ['asc']);

  return (
    <TabPane tabId='Pre-ping'>
      <Row>
        <Col sm={6} className="ppLeft">
          <Row>
            <Col>
              <h5>Internal Pre-Ping</h5>
            </Col>
          </Row>
          <hr />
          <div className="py-2">
            <InternalSuppressionItem shouldRenderSuppression={shouldRenderSuppression} criteriaType={criteriaType} />
          </div>
          <Row>
            {shouldRenderSuppression && (
              <Col>
                <Label>Additional {criteriaType !== 'Offer Action Criteria' ? 'Offers' : 'Offer Actions'}</Label>
                <Field
                  name={
                    criteriaType !== 'Offer Action Criteria'
                      ? 'suppressionRules.additional_offer_ids'
                      : 'suppressionRules.additional_offer_action_ids'
                  }
                  component={MultiselectField}
                  loading={loading || loadingAction}
                  busy={criteriaType !== 'Offer Action Criteria' ? loading : loadingAction}
                  data={criteriaType !== 'Offer Action Criteria' ? alphabitizedOffers : alphabitizedOfferActions}
                  valueField="id"
                  textField={criteriaType !== 'Offer Action Criteria' ? 'name' : 'title'}
                />
              </Col>
            )}
          </Row>
        </Col>
        <Col>
          <div>
            <Row>
              <Col>
                <h5>External Pre-Ping</h5>
              </Col>
            </Row>
            <hr />
              <Row className='py-2'>
                <Col lg={12}>
                  <Label for='prePingResult' style={{ verticalAlign: 'super' }}>
                    Pre-Ping Result Stored&nbsp;
                  </Label>
                  <Field name='prePingResultSessionCapture' component={renderToggleSwitch} id='prePingResult' />
                </Col>
              </Row>
            <Row>
              <Col>
                <Field name='prePingMethod' component={renderPrePingMethod} type='select' label='Pre Ping Method' />
              </Col>
              <Col>
                <Label>Handler</Label>
                <Field
                  name='prePingHandlerId'
                  component={renderComboBox}
                  data={handlers}
                  valueField='id'
                  textField='name'
                />
              </Col>
            </Row>
            {prePingMethod !== 'NONE' && (
              <div>
                <br />
                <Row>
                  <Col lg={12}>
                    <Field name='prePing' type='text' component={renderField} label='URL' />
                  </Col>
                </Row>
                {(prePingMethod === 'POST' || prePingMethod === 'JSON' || prePingMethod === 'XML') && (
                  <Row>
                    <Col lg={12}>
                      <Label>Body</Label>
                      <Field name='prePingBody' component={textarea} type='text' />
                    </Col>
                  </Row>
                )}
                <br />
                <Row>
                  <Col lg={12}>
                    <Button className='float-right' onClick={() => setHttpIsHidden(!httpIsHidden)}>
                      <em>advanced settings</em>
                    </Button>
                  </Col>
                </Row>
                <br />
                {!httpIsHidden && (
                  <div>
                    <br />
                    <Row>
                      <Col lg={3}>
                        <Field name='prePingTimeout' component={NumberField} label='Timeout (ms)' />
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={4}>
                        <Field name='prePingHeader1' component={renderField} type='text' label='Header 1' />
                      </Col>
                      <Col lg={4}>
                        <Field name='prePingHeader2' component={renderField} type='text' label='Header 2' />
                      </Col>
                      <Col lg={4}>
                        <Field name='prePingHeader3' component={renderField} type='text' label='Header 3' />
                      </Col>
                    </Row>
                    <br />
                    <Row>
                      <Col lg={12}>
                        <Field
                          name='prePingCustomHttpStatus'
                          component={renderField}
                          type='text'
                          label='Other HTTP Status'
                        />
                      </Col>
                    </Row>
                  </div>
                )}
              </div>
            )}
          </div>
        </Col>
      </Row>
    </TabPane>
  );
};
