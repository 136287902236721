import {
  SAVE_AS_OFFER_NAME,
  GET_OFFER_TRIGGERS_BY_OFFER_ID,
  GET_OFFER_ACTION_TRIGGER_BY_ID,
  ADD_OFFER_TRIGGER,
  UPDATE_OFFER_TRIGGERS,
  DELETE_OFFER_TRIGGERS,
  REMOVE_OFFER_TRIGGER_STATE,
  ADD_QUESTION_ANSWER,
  UPDATE_QUESTION_ANSWERS,
  DELETE_QUESTION_ANSWERS,
  ADD_OFFER_ACTION_TRIGGER,
  UPDATE_OFFER_STATE,
  SAVE_OFFER,
  GET_OFFER_DATA,
  UPDATE_SELECTOR,
  UPDATE_CRITERIA_TYPE,
  UPDATE_ADVERTISER,
  UPDATE_TAGS,
} from '../actions/types';
import { formValueSelector } from 'redux-form';

const initialState = {
  selector: formValueSelector('offerquestion'),
  isSaved: false,
  offerTriggers: [],
  newOfferName: '',
};
export default function (state = initialState, action) {
  switch (action.type) {
    case SAVE_AS_OFFER_NAME:
      return { ...state, newOfferName: action.payload };
    case GET_OFFER_TRIGGERS_BY_OFFER_ID:
      const [offerTriggers, offerActionTriggers] = action.payload;
      return { ...state, offerTriggers, offerActionTriggers };
    case GET_OFFER_ACTION_TRIGGER_BY_ID:
      return { ...state, offerActionTrigger: action.payload };
    case ADD_OFFER_TRIGGER:
      return { ...state, offerTriggers: action.payload };
    case UPDATE_ADVERTISER:
      return { ...state, advertiser: action.payload };
    case UPDATE_TAGS:
      return { ...state, tags: action.payload };
    case UPDATE_OFFER_TRIGGERS:
      return { ...state, offerTriggers: action.payload };
    case REMOVE_OFFER_TRIGGER_STATE:
      return {
        ...state,
        offerTriggers: action.payload,
        questionAnswers: action.payload,
        updatedQuestionAnswers: action.payload,
        offerActionTriggers: action.payload,
      };
    case DELETE_OFFER_TRIGGERS:
      return { ...state, deletedOfferTriggers: action.payload };
    case ADD_QUESTION_ANSWER:
      return { ...state, questionAnswers: action.payload };
    case UPDATE_QUESTION_ANSWERS:
      return { ...state, updatedQuestionAnswers: action.payload };
    case DELETE_QUESTION_ANSWERS:
      return { ...state, deleteQuestionAnswers: action.payload };
    case ADD_OFFER_ACTION_TRIGGER:
      return { ...state, offerActionTriggers: action.payload };
    case SAVE_OFFER:
      return { ...state, isOfferSaved: action.payload };
    case UPDATE_OFFER_STATE:
      return { ...state, ...action.payload };
    case UPDATE_SELECTOR:
      return {
        ...state,
        selector: formValueSelector(action.payload),
        form: action.payload,
      };
    case GET_OFFER_DATA:
      return { ...state, ...action.payload };
    case UPDATE_CRITERIA_TYPE:
      return { ...state, criteriaType: action.payload };
    default:
      return state;
  }
}
