import React, { useEffect, useState } from 'react';
import { Alert, Button, Col, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import ActiveStatusSwitch from '../../../components/ActiveStatusSwitch/ActiveStatusSwitch';
import DropdownList from 'react-widgets/DropdownList';

const FeatureModalButton = ({ feature, strategies, updateFeature }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Button
      color="secondary"
      id="locations-button"
      onClick={() => {
        setIsOpen(true);
      }}
    >
      {isOpen &&
        <FeatureModal feature={feature} strategies={strategies} updateFeature={updateFeature} isOpen={isOpen}
                      setIsOpen={setIsOpen} />}
      <i className="fa fa-file-text-o" />
    </Button>
  );
};

const FeatureModal = ({ feature, strategies, updateFeature, isOpen, setIsOpen }) => {
  const [featureDraft, setFeatureDraft] = useState(feature);
  const [selectedStrategyId, setSelectedStrategyId] = useState(feature.strategyId);
  const [error, setError] = useState(false);
  const onStrategyChange = (value) => {
    setSelectedStrategyId(value.id);
    setFeatureDraft({ ...featureDraft, strategyId: value.id });
    setError(false);
  };

  const selectedStrategy = strategies.find((strategy) => strategy.id === selectedStrategyId);

  const closeModal = () => setIsOpen(false);

  const saveFeatureChanges = () => {
    const errors = featureErrors(featureDraft, selectedStrategy);
    if (errors) {
      setError(errors);
    } else {
      updateFeature(featureDraft);
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      setError(false);
      setFeatureDraft(feature);
      setSelectedStrategyId(feature?.strategyId);
    } else {
      setError(false);
      setFeatureDraft(null);
      setSelectedStrategyId(null);
    }
  }, [isOpen]);

  if (!feature || !strategies || !isOpen) {
    return null;
  }

  return (
    <div id="feature-modal" className="animated" style={{ textAlign: 'center' }}>
      <Modal isOpen={isOpen} toggle={closeModal} className={'modal-info'}>
        <ModalHeader toggle={closeModal}>Edit Feature</ModalHeader>
        <ModalBody>
          <Row>
            <Col lg={12}>
              <Row style={{ justifyContent: 'space-between', margin: 0 }}>
                <h3>{feature.name}</h3>
                <ActiveStatusSwitch
                  isActive={featureDraft.enabled}
                  handleToggleSwitch={(status) => setFeatureDraft({ ...featureDraft, enabled: status })}
                />
              </Row>
              <Alert color={'warning'}>{feature.description}</Alert>
              <Label>Strategy:</Label>
              <Row style={{ alignItems: 'center' }}>
                <Col>
                  <DropdownList
                    data={strategies}
                    dataKey="id"
                    textField="name"
                    value={selectedStrategyId}
                    onChange={onStrategyChange}
                    placeholder={'Select a strategy...'}
                  />
                </Col>
                <Col lg={2}>
                  <Button color={'secondary'} onClick={() => {
                    setSelectedStrategyId(null);
                    setFeatureDraft({ ...featureDraft, strategyId: null });
                    setError(false);
                  }}>
                    Clear
                  </Button>
                </Col>
              </Row>

              <br />
              <StrategyParameters featureDraft={featureDraft}
                                  strategy={selectedStrategy}
                                  setFeatureDraft={setFeatureDraft}
                                  setError={setError} />
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          {error && <Alert color="danger" isOpen={featureDraft.error} fade={true}
                           style={{ margin: 0, marginRight: 15, padding: '0.35em' }}>{error}</Alert>}
          <Button color="primary" disabled={error} onClick={saveFeatureChanges}>
            Save
          </Button>{' '}
          <Button color="secondary" onClick={closeModal}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

const StrategyParameters = ({ featureDraft, strategy, setFeatureDraft, setError }) => {
  return (
    <>
      {strategy?.parameters?.map((param) => (
        <Row key={param.id}>
          <Col lg={12}>
            <Label>{param.label}:</Label>
            <Input type={'textarea'}
                   value={featureDraft.strategyParams[param.name]}
                   onChange={(e) => {
                     const strategyParamError = strategyParamErrors(param.label, e.target.value, strategy.validationPattern);
                     if (strategyParamError) {
                       setError(strategyParamError);
                     } else {
                       setError(false);
                     }
                     setFeatureDraft({
                       ...featureDraft,
                       strategyParams: { ...featureDraft.strategyParams, [param.name]: e.target.value },
                     });
                   }} />
            <span style={{ fontSize: '12px', color: '#afafaf' }}>{param.description}</span>
          </Col>
        </Row>
      ))}
    </>
  );
};

const featureErrors = (feature, selectedStrategy) => {
  if (!selectedStrategy) {
    return;
  }

  for (const param of selectedStrategy.parameters) {
    const error = strategyParamErrors(param.label, feature.strategyParams[param.name], selectedStrategy.validationPattern);
    if (error) {
      return error;
    }
  }

};

const strategyParamErrors = (strategyParamLabel, strategyParamValue, strategyParamPattern) => {
  if (!strategyParamValue) {
    return `${strategyParamLabel} cannot be empty`;
  }
  if (strategyParamPattern && !strategyParamValue.match(strategyParamPattern)) {
    return `Invalid value for ${strategyParamLabel}`;
  }
};

export default FeatureModalButton;

