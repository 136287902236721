import _ from 'lodash';
import React, { Component } from 'react';

import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import { withRouter } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import api from '../../services';
import { formatDate } from '../../utils/conversionFunctions';
import { columnFormatter } from '../../utils/tableFunctions';
import './RecentOffersTable.css';
import ZuulLoader from '../../components/ZuulLoader/ZuulLoader';

class RecentOffersTable extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.idleTimer = null;
    this.state = {
      offer: [],
      isActive: null,
      offerSelection: [],
      render: false,
      loading: true,
      toId: null,
      page: 1,
      sizePerPage: 100,
      paginationSize: 10,
      totalElements: 10,
      isEditMode: false,
      offerType: 'Question',
    };
    this.options = {
      onPageChange: this.onPageChange.bind(this),
      onSizePerPageList: this.sizePerPageListChange.bind(this),
      page: this.state.page,
      sizePerPage: this.state.sizePerPage,
      paginationSize: this.state.paginationSize,
      sizePerPageDropDown: this.renderSizePerPageDropDown,
      // defaultSortName: 'lastPublished',
      // defaultSortOrder: 'desc',
      prePage: 'Prev',
      nextPage: 'Next',
      firstPage: 'First',
      lastPage: 'Last',
      paginationShowsTotal: true,
      paginationPosition: 'bottom',
      alwaysShowAllBtns: true,
      withFirstAndLast: true,
    };

    this.getData = this.getData.bind(this);
  }

  renderSizePerPageDropDown = (props) => {
    return (
      <div className='btn-group'>
        {[10, 25, 30, 50].map((n, idx) => {
          const isActive = n === props.currSizePerPage ? 'active' : null;
          return (
            <button
              key={idx}
              type='button'
              className={`btn btn-info ${isActive}`}
              onClick={() => props.changeSizePerPage(n)}
            >
              {n}
            </button>
          );
        })}
      </div>
    );
  };

  renderOffers(response) {
    // console.log(response);
    return response.data.content.map((offer) => {
      return {
        id: offer.id !== null && offer.id !== undefined ? offer.id : '',
        name: offer.name !== null ? offer.name : '',
        offerType: offer.offerType !== null && offer.offerType.name !== null ? offer.offerType.name : '',
        active: offer.isActive !== null ? offer.isActive : false,
        advertiserName: offer.advertiser !== null ? offer.advertiser.name : '',
        lastPublished:
          offer.lastPublished !== null ? formatDate(offer.lastPublished) : formatDate('2019-01-01T12:00:00'),
        status: offer.status,
      };
    });
  }

  getAdditionalData = () => {
    const offerPromises = [];

    for (let i = 1; i <= this.state.totalPages; i++) {
      const offerPromise = api.get(`offers?limit=${100}&offset=${i}`);
      offerPromises.push(offerPromise);
    }

    return offerPromises;
  };

  getData(page = this.state.page, limit = this.state.sizePerPage) {
    let offset = page - 1;
    api
      .get(`offers?limit=${limit}&offset=${offset}`)
      .then((response) => {
        console.log(response);
        const existingOffers = this.renderOffers(response);
        console.log(existingOffers);
        const sortedOffers = existingOffers.sort((a, b) => this.sortDates(a, b, 'asc'));

        console.log(sortedOffers);
        const totalPages = response.data.totalPages;
        const total = response.data.totalElements;
        const newState = Object.assign({}, this.state, {
          offer: sortedOffers,
          totalElements: total,
          totalPages,
          loading: true,
        });

        this.setState(newState, () => {
          Promise.all(this.getAdditionalData()).then((responses) => {
            const parsedOffers = _.flatten(
              responses.map((response) => {
                return this.renderOffers(response);
              })
            );

            this.setState((prevState) => {
              let totalSortedOffers = [...prevState.offer, ...parsedOffers];
              totalSortedOffers = totalSortedOffers
                .sort((a, b) => this.sortDates(a, b, 'asc'))
                .filter((offer) => {
                  return offer.status === 1 && offer.offerType !== 'Landing Page' && offer.offerType !== 'Offer Block';
                })
                .slice(0, 10);
              console.log(totalSortedOffers);
              return {
                offer: totalSortedOffers,
                loading: false,
                render: true,
              };
            });
          });
        });
      })
      .catch((error) => console.log(error));
  }

  updateData(page = this.state.page, limit = this.state.sizePerPage) {
    let offset = page - 1;
    let existingOffer = this.state.offer;
    api
      .get(`offers?limit=${10}&offset=${0}`)
      .then((response) => {
        const newOffers = this.renderOffers(response);
        console.log(response.data);
        const first = response.data.pageable.offset;
        const num = response.data.numberOfElements;
        for (let i = first, j = 0; j < num; i++, j++) {
          existingOffer[i] = newOffers[j];
        }

        const newState = Object.assign({}, this.state, {
          offer: existingOffer,
        });

        this.setState(newState);
      })
      .catch((error) => console.log(error));
  }

  componentDidMount() {
    this._isMounted = true;
    this.getData();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  sizePerPageListChange(sizePerPage) {
    this.updateData(1, sizePerPage);
  }

  onPageChange(page, sizePerPage) {
    this.updateData(page, sizePerPage);
  }

  sortDates(a, b, order) {
    var dateA = new Date(a.lastPublished).getTime();
    var dateB = new Date(b.lastPublished).getTime();
    if (order === 'asc') {
      return dateA > dateB ? -1 : 1;
    }
    return dateA > dateB ? 1 : -1;
  }

  render() {
    const URL = '/campaigns/offertable/offer/';
    const columns = [
      {
        dataField: 'name',
        text: 'Offer Name',
        formatter: (cell, row) => {
          return columnFormatter({
            cell,
            row,
            computedURL: URL + row.id,
          });
        },
        headerStyle: {
          backgroundColor: '#2f353a',
        },
      },
      {
        dataField: 'offerType',
        text: 'Offer Type',
        formatter: (cell, row) => {
          return columnFormatter({
            cell,
            row,
            computedURL: URL + row.id,
          });
        },
        headerStyle: {
          backgroundColor: '#2f353a',
        },
      },
      {
        dataField: 'lastPublished',
        text: 'Last Published',
        formatter: (cell, row) => {
          return columnFormatter({
            cell,
            row,
            computedURL: URL + row.id,
          });
        },
        headerStyle: {
          backgroundColor: '#2f353a',
        },
      },
      {
        dataField: 'active',
        text: 'Status',
        formatter: (cell, row) => {
          return columnFormatter({
            cell,
            row,
            computedURL: URL + row.id,
            activeStatusType: 'iconAndText',
          });
        },
        headerStyle: {
          backgroundColor: '#2f353a',
        },
      },
    ];
    const rowEvents = {
      onMouseEnter: (e, row, rowIndex) => {
        this.setState({ activeRow: rowIndex });
      },
    };

    const rowStyle = (row, rowIndex) => {
      const style = {};
      if (rowIndex === this.state.activeRow) {
        style.backgroundColor = '#2f353a';
        style.cursor = 'pointer';
      }
      return style;
    };

    return (
      <div>
        {' '}
        <div className='animated fadeIn'>
          <ToolkitProvider keyField='id' data={this.state.offer} columns={columns} search>
            {(props) => (
              <div>
                <Row>
                  <Col lg={6}></Col>
                </Row>
                <br />
                {this.state.loading === true && (
                  <ZuulLoader text={`Loading offers...`} />
                )}
                {this.state.loading === false && (
                  <div>
                    <BootstrapTable
                      hover
                      bordered={false}
                      {...props.baseProps}
                      // pagination={paginationFactory()}
                      rowEvents={rowEvents}
                      rowStyle={rowStyle}
                    />
                  </div>
                )}
              </div>
            )}
          </ToolkitProvider>
        </div>
      </div>
    );
  }
}

export default withRouter(RecentOffersTable);
