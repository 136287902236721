import React, { useEffect, useState } from 'react';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
// import Moment from 'react-moment';
// import Clock from 'react-live-clock';
import RecentOffersTable from './RecentOfferTable';
import { Link } from 'react-router-dom';
import RecentOfferActions from './RecentOfferActions';
import { useOktaAuth } from '@okta/okta-react';
import getRandomFact from './facts';

const Dashboard = () => {
  const { authState, oktaAuth } = useOktaAuth();
  const [userInfo, setUserInfo] = useState(null);
  const [randomFact, setRandomFact] = useState('');

  useEffect(() => {
    if (authState && authState.isAuthenticated) {
      oktaAuth.getUser().then((info) => {
        setUserInfo(info);
      });
    }
  }, [authState, oktaAuth]);

  useEffect(() => {
    setRandomFact(getRandomFact());
  }, []);

  const now = new Date();
  return (
    <div className='animated fadeIn'>
      <Row>
        <Col lg='5'>
          <Card className='element'>
            <CardHeader>Welcome to the Zuul Lair</CardHeader>
            <CardBody>
              <div>
                <h1>{`Welcome, ${userInfo?.name || ''}`}</h1>
                <h4>{/*Today is <Moment date={now} format='MM/DD/YYYY' />*/}</h4>
                <h1>{/*<Clock format={'h:mm A'} ticking={true} />*/}</h1>
              </div>
            </CardBody>
          </Card>
        </Col>
        {randomFact && (
          <Col lg='7'>
            <Card>
              <CardHeader>Daily Trivia </CardHeader>
              <CardBody>
                <h5>{randomFact}</h5>
              </CardBody>
            </Card>
          </Col>
        )}
      </Row>
      <Row>
        <Col lg={6}>
          <Card className='element'>
            <Link to='/campaigns/offertable' style={{ textDecoration: 'none', color: '#ffffff' }}>
              <CardHeader>Offer Analytics</CardHeader>
            </Link>
            <CardBody>
              <h5>Most Recently Edited Offers</h5>
              {userInfo && <RecentOffersTable />}
            </CardBody>
          </Card>
        </Col>
        <Col lg={6}>
          <Card className='element'>
            <Link to='/campaigns/offeractions' style={{ textDecoration: 'none', color: '#ffffff' }}>
              <CardHeader>Offer Action Analytics</CardHeader>
            </Link>
            <CardBody>
              <h5>Most Recently Edited Actions</h5>
              {userInfo && <RecentOfferActions />}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Dashboard;
