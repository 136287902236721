import api from '../index';
import _ from 'lodash/fp';

const createQuestion = async (question) => {
  const resp = await api.post('questions', question);
  return resp.data;
};

const createOfferQuestion = async (offerQuestion) => {
  if (_.isNil(offerQuestion.question) || _.isNil(offerQuestion.offer))
    throw Error('Question and offer required');

  const resp = await api.post('offerquestions', offerQuestion);
  return resp.data;
};

const updateOfferQuestion = async (offerQuestion) => {
  if (
    _.isNil(offerQuestion.id) ||
    _.isNil(offerQuestion.question) ||
    _.isNil(offerQuestion.offer)
  )
    throw Error('Id, question, and offer required');

  const resp = await api.put('offerquestions', offerQuestion);
  return resp.data;
};

export { createQuestion, createOfferQuestion, updateOfferQuestion };
