import React, { useEffect, useCallback, useState, useRef } from 'react';
import {
  Badge,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  ListGroup,
  ListGroupItem,
  TabPane,
} from 'reactstrap';
import { useOffersTable } from '../../views/Offers/queries';
import ZuulLoader from '../../components/ZuulLoader/ZuulLoader';
import { Link } from 'react-router-dom';
import DynamicList from '../../components/Virtualization/DynamicList';

const OfferItem = React.memo(({ index, style, data }) => {
  const offer = data[index];

  return (
    <Link to={`/campaigns/offertable/offer/${offer.id}?selectedOffer=${offer.id}`} className='text-light'>
      <ListGroupItem style={style} key={offer?.id}>
        <div className='pb-1 d-flex justify-content-between'>
          <small
            className='font-weight-bold'
            style={{
              width: '70%',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {offer?.name}
          </small>
          <Badge className='mr-1' color='primary' pill>
            {offer?.id}
          </Badge>
        </div>
        <div className='d-flex pt-2'>
          <Badge className='mr-1 text-wrap' color='secondary' pill>
            {offer?.advertiser?.name}
          </Badge>
        </div>
      </ListGroupItem>
    </Link>
  );
});

const OffersPane = () => {
  const { data: offers, isLoading: loadingOffers, error: offerLoadingError } = useOffersTable();
  const [filteredOffers, setFilteredOffers] = useState([]);

  const handleInputChange = useCallback(
    (e) => {
      const term = e.target.value.toLowerCase();
      const filtered = offers.filter((offer) => {
        const offerName = offer.name.toLowerCase();
        const offerId = offer.id.toString();
        return offerName.includes(term) || offerId.includes(term);
      });
      setFilteredOffers(filtered);
    },
    [offers]
  );

  useEffect(() => {
    setFilteredOffers(offers);
  }, [offers]);

  return (
    <TabPane tabId='1' className='h-100 overflow-hidden'>
      {offerLoadingError && <div>Error loading offers</div>}
      {loadingOffers ? (
        <ZuulLoader text={`Loading Offers...`} />
      ) : (
        <>
          <div className='sticky-top'>
            <ListGroup className='list-group-accent'>
              <ListGroupItem className='list-group-item-accent-secondary bg-light text-center font-weight-bold text-muted text-uppercase small m-0'>
                Offers
              </ListGroupItem>
            </ListGroup>
            <InputGroup>
              <InputGroupAddon addonType='prepend'>
                <InputGroupText>
                  <i className='fa fa-search' />
                </InputGroupText>
              </InputGroupAddon>
              <Input
                type='text'
                id='search'
                className='search-aside'
                name='offersSearch'
                placeholder='Search by offer name or ID...'
                onChange={handleInputChange}
                aria-label='Search'
              />
            </InputGroup>
          </div>
          <div className='position-relative h-100'>
            {filteredOffers && <DynamicList items={filteredOffers}>{(props) => <OfferItem {...props} />}</DynamicList>}
          </div>
        </>
      )}
    </TabPane>
  );
};

export default OffersPane;
