import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Alert, Button, Col, Input, Modal, ModalBody, ModalFooter, ModalHeader, NavLink, Row } from 'reactstrap';
import { Field } from 'react-final-form';
import { formatDate } from '../../../utils/conversionFunctions';

const ToggleIcon = ({ isOpen, onClick }) => (
  <i onClick={onClick} className={`fa ${isOpen ? 'fa-angle-double-up opened' : 'fa-angle-double-down closed'}`} />
);

const ModalComponent = ({ isOpen, setIsOpen, title, children, handleConfirm, confirmText }) => (
  <Modal isOpen={isOpen} toggle={() => setIsOpen(false)} className={`modal-${title.toLowerCase()}`}>
    <ModalHeader toggle={() => setIsOpen(false)}>{confirmText} Offer Block</ModalHeader>
    <ModalBody>{children}</ModalBody>
    <ModalFooter>
      <Button
        color={title}
        onClick={handleConfirm}
        style={{
          color: 'white',
        }}
      >
        {confirmText}
      </Button>
      <Button onClick={() => setIsOpen(false)}>Cancel</Button>
    </ModalFooter>
  </Modal>
);

const Navbar = ({
  leftAside,
  setLeftAside,
  shouldErrorRender,
  publishedSequence,
  rightAside,
  setRightAside,
  handleSubmit,
  setIsCopy,
}) => {
  const [isPublishedModalOpen, setIsPublishedModalOpen] = useState(false);
  const [isCopyModalOpen, setIsCopyModalOpen] = useState(false);
  const { offerBlockId } = useParams();

  return (
    <Row id='sequenceNav'>
      <Col className='center' xs='auto'>
        <NavLink href='/campaigns/offerblocks'>
          <i className='fa fa-chevron-left' /> &nbsp;&nbsp; Back to Zuul
        </NavLink>
      </Col>
      <Col className='center' xs='auto'>
        <ToggleIcon isOpen={!leftAside} onClick={setLeftAside} />
      </Col>
      <Col className='center'>
        {shouldErrorRender ? (
          <Alert color='danger'>One or more of the fields you entered are invalid.</Alert>
        ) : publishedSequence.publishedAt ? (
          <div className='singleLine'>
            {'Last Published at ' +
              formatDate(publishedSequence.publishedAt) +
              ' by: ' +
              publishedSequence?.zuulUser?.realname}
          </div>
        ) : null}
      </Col>
      <Col xs='auto'></Col>
      {offerBlockId !== 'create' && (
        <Col xs='auto'>
          <Button
            color='success'
            onClick={() => setIsCopyModalOpen(true)}
            style={{
              marginLeft: '10px',
            }}
          >
            Copy Offer Block
          </Button>
        </Col>
      )}
      <Col xs='auto'>
        <Button
          color='info'
          onClick={() => setIsPublishedModalOpen(true)}
          style={{
            color: 'white',
          }}
        >
          Publish Offer Block
        </Button>
      </Col>
      <Col className='center' xs='auto'>
        <ToggleIcon isOpen={!rightAside} onClick={setRightAside} />
      </Col>
      <ModalComponent
        isOpen={isPublishedModalOpen}
        setIsOpen={setIsPublishedModalOpen}
        title='info'
        handleConfirm={() => {
          handleSubmit();
          setIsPublishedModalOpen(false);
        }}
        confirmText='Publish'
      >
        <p>Are you sure you want to publish this Offer Block?</p>
      </ModalComponent>
      <ModalComponent
        isOpen={isCopyModalOpen}
        setIsOpen={setIsCopyModalOpen}
        title='warning'
        handleConfirm={() => {
          setIsCopy(true);
          handleSubmit();
          setIsCopyModalOpen(false);
        }}
        confirmText='Copy'
      >
        <Row>
          <Col xs='auto'>
            <i className='fa fa-warning fa-3x' style={{ color: '#FFC107' }} />
          </Col>
          <Col>
            <p>
              Any changes made without saving will be copied to the new Offer Block but not reflected in the current
              Offer Block.
            </p>
          </Col>
        </Row>
        <label>Offer Block Name:</label>
        <Field required name='name' validate={(value) => (!value ? 'Please add an Offer Block name' : undefined)}>
          {({ input, meta: { touched, error } }) => (
            <>
              <Input {...input} placeholder='Offer Block Name' type='text' />
              {touched && error && <Alert color='danger'>{error}</Alert>}
            </>
          )}
        </Field>
      </ModalComponent>
    </Row>
  );
};

export default Navbar;
