import arrayMutators from 'final-form-arrays';
import React, { useState } from 'react';
import { Field, Form } from 'react-final-form';
import { useHistory, useParams } from 'react-router-dom';
import 'react-widgets/styles.css';
import { Alert, Col, Row } from 'reactstrap';
import { useToggle } from '../hooks';
import { useCreateOfferBlock, useOfferBlock, useUpdateOffer, useUpdateOfferBlock } from '../queries';
import { useOffersTable } from '../../Offers/queries';
import DnDFlow from '../components/DnDFlow';
import LeftAside from '../components/LeftAside';
import Navbar from '../components/Navbar';
import RightAside from '../components/RightAside';
import Criteria from '../../../components/Criteria/CriteriaFinal/Criteria';
import './dnd.css';
import { getSequenceFlowOffersAndEdges, handleFlowOffersInit, handleOrphanedOffers } from './flowUtils';
import './OfferBlockDetails.css';
import validate from './validate';
import { handleCriteriaSubmit } from '../../../utils/CriteriaHelpers';
import { useAuth } from '../../../services/oktaAuth/useAuth';
import ZuulLoader from '../../../components/ZuulLoader/ZuulLoader';

function parseOfferBlock(arr) {
  return arr.map((obj) => {
    const newObj = {};
    for (const key in obj) {
      if (obj[key] !== null && obj[key] !== '') {
        newObj[key] = typeof obj[key] === 'object' && obj[key].hasOwnProperty('id') ? obj[key].id : obj[key];
      }
    }
    return newObj;
  });
}

function handleDomainAffiliateInit() {
  return { domainAffiliateEntries: [] };
}

function handlePreviousRegistrationInit() {
  return {
    andOrLogic: null,
    previousRegistrations: [
      { domains: [], includeExcludeAction: null, previousRegistrationsMin: null, previousRegistrationsMax: null },
    ],
  };
}

function handleCapSegmentInit() {
  return {
    capSegments: [{ domains: [], segmentAmount: null }],
  };
}

const OfferBlockDetails = () => {
  useAuth();
  const { offerBlockId } = useParams();
  const history = useHistory();
  const [leftAside, setLeftAside] = useToggle(true);
  const [rightAside, setRightAside] = useToggle(true);
  const [orphanedOffers, setOrphanedOffers] = useState([]);
  const [selectedElement, setSelectedElement] = useState({});
  const [reactFlowInstance, setReactFlowInstance] = useState(null);
  const { data: offers, loading, error } = useOffersTable();
  let {
    data: publishedSequence,
    isLoading: publishedSequenceLoading,
    isError: publishedSequenceError,
    isFetching: publishedSequenceFetching,
  } = useOfferBlock(offerBlockId);
  const { mutate: updateOfferBlock } = useUpdateOfferBlock();
  const { mutate: updateOffer } = useUpdateOffer();
  const { mutate: createOfferBlock } = useCreateOfferBlock();
  let isCopy = false;

  if (
    !publishedSequence ||
    !publishedSequence.offer ||
    !publishedSequence.offer.domainAffiliateConfiguration ||
    !publishedSequence.offer.previousRegistrationConfiguration ||
    !publishedSequence.offer.capSegmentConfiguration
  ) {
    const pubSeq = publishedSequence || {};
    const offer = pubSeq.offer || {};
    if (!offer.previousRegistrationConfiguration) {
      offer.previousRegistrationConfiguration = handlePreviousRegistrationInit();
    }
    if (!offer.capSegmentConfiguration) {
      offer.capSegmentConfiguration = handleCapSegmentInit();
    }
    pubSeq.offer = offer;
    publishedSequence = pubSeq;
  }

  if (publishedSequence && publishedSequence?.offer?.domainAffiliateConfiguration == null) {
    publishedSequence.offer.domainAffiliateConfiguration = handleDomainAffiliateInit();
  }

  if (publishedSequenceLoading || loading || publishedSequenceFetching) {
    return <ZuulLoader text={`Loading offer block...`} />;
  }

  const onSubmit = async (values) => {
    // Validate the sequence flow
    const sequenceFlow = reactFlowInstance.toObject().elements;
    const isSequenceFlowValid = validateOffersSequences(sequenceFlow);
    if (!isSequenceFlowValid) return false;
    // Extract offers from sequenceFlow
    const offers = sequenceFlow.filter((item) => item.offerType && item.offer).map((item) => item.offer);
    // Construct the offer_block array
    const offer_block = offers
      .map((offer) => values.offersPublished.offer_block.find((item) => item.offer_id === offer.id))
      .filter((found) => found !== undefined);
    if (values.offer) {
      values.offer = {
        ...values.offer,
        ...handleCriteriaSubmit(values.offer),
      };
    }
    // Common finalSequence properties
    let finalSequence = {
      active: true,
      offer_block_checkout_status: false,
      offersDraft: {},
      offersPublished: {
        offer_block: parseOfferBlock(offer_block),
      },
      offer: values.offer,
      published: true,
      publishedAt: new Date(),
      zuulUser: JSON.parse(localStorage.getItem('userData')),
    };

    if (offerBlockId === 'create' || isCopy) {
      finalSequence.createdAt = new Date();
      finalSequence.offer = {
        ...values.offer,
        isActive: true,
        zuulUser: JSON.parse(localStorage.getItem('userData')),
      };
      if (finalSequence?.id) delete finalSequence.id;
      if (finalSequence?.offer?.id)  delete finalSequence.offer.id;
      if (isCopy && values.name) finalSequence.offer.name = values.name;

      createOfferBlock(finalSequence);
      history.push('/campaigns/offerblocks');
    } else {
      // Add properties for updating an existing offer block
      finalSequence.id = parseInt(offerBlockId);
      updateOffer(finalSequence.offer);
      updateOfferBlock(finalSequence);
      history.push('/campaigns/offerblocks');
    }
  };

  const validateOffersSequences = (sequenceFlow) => {
    let [edges, offers] = getSequenceFlowOffersAndEdges(sequenceFlow.elements);
    let updatedOrphanedOffers = [];
    if (offers.length > 1) {
      updatedOrphanedOffers = handleOrphanedOffers(sequenceFlow.elements);
      setOrphanedOffers(updatedOrphanedOffers);
    }

    return updatedOrphanedOffers.length === 0;
  };

  return (
    <div className='animated fadeIn'>
      <Form
        initialValues={publishedSequence}
        mutators={{
          ...arrayMutators,
        }}
        onSubmit={onSubmit}
        validate={validate}
        subscription={{
          submitting: true,
          submitFailed: true,
        }}
      >
        {({
          handleSubmit,
          form: {
            mutators: { push, remove, concat, pop },
          },
          submitting,
          submitFailed,
        }) => (
          <form onSubmit={handleSubmit} autoComplete='off'>
            <Navbar
              leftAside={leftAside}
              rightAside={rightAside}
              setLeftAside={setLeftAside}
              setRightAside={setRightAside}
              submitting={submitting}
              publishedSequence={publishedSequence}
              handleSubmit={handleSubmit}
              shouldErrorRender={submitFailed}
              setIsCopy={() => (isCopy = true)}
            />
            <Row className='sequence-section'>
              <Col id='leftAside' className={`toggled-${leftAside ? 'show' : 'hide'}`} md={2}>
                <LeftAside isDraft={!publishedSequence.published} offers={offers} />
              </Col>
              <Col style={{ padding: '0' }}>
                <Criteria push={push} pop={pop} source='offer' />
                <Row>
                  <Col lg={12}>
                    {orphanedOffers.length ? (
                      <Alert id='invalid-offers-alert'>
                        <></>
                      </Alert>
                    ) : null}
                  </Col>
                </Row>
                <DnDFlow
                  initialElements={handleFlowOffersInit(publishedSequence.offerBlockOffers)}
                  offers={offers}
                  selectedElement={selectedElement}
                  setSelectedElement={(selectedElement) => setSelectedElement(selectedElement)}
                  orphanedOffers={orphanedOffers}
                  reactFlowInstance={reactFlowInstance}
                  updateOrphanedOffers={(orphanedOffers) => {
                    setOrphanedOffers(orphanedOffers);
                  }}
                  setReactFlowInstance={(reactFlowInstance) => {
                    setReactFlowInstance(reactFlowInstance);
                  }}
                  formPush={push}
                  formRemove={remove}
                  formPop={pop}
                  formConcat={concat}
                />
              </Col>
              <Col id='sequenceOfferMetadata' className={`toggled-${rightAside ? 'show' : 'hide'}`} md={3}>
                <Field required name='offersPublished.offer_block'>
                  {({ input, meta: { touched, error } }) => (
                    <RightAside
                      selectedElement={selectedElement}
                      selectedElementIndex={
                        input ? input.value?.findIndex((offer) => offer.offer_id === selectedElement?.offer?.id) : null
                      }
                    />
                  )}
                </Field>
              </Col>
            </Row>
          </form>
        )}
      </Form>
    </div>
  );
};

export default OfferBlockDetails;
