import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col } from 'reactstrap';
import { deleteEntity, resetModal } from '../../actions';

class DeleteModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }

  toggleModal = async () => {
    if (this.state.isOpen) {
      this.props.resetModal();
    }
    await this.setState({ isOpen: !this.state.isOpen });
  };

  renderDeleteButton() {
    return (
      <Button color='danger' onClick={this.toggleModal}>
        <i className='fa fa-trash'></i>
      </Button>
    );
  }

  deleteEntity = async () => {
    await this.props.deleteEntity(this.props.id, this.props.entity);

    if (this.props.saveError) return;
    this.toggleModal();
    if (Object.keys(this.props.match.params).length > 0) {
      return this.props.history.push(`/campaigns/${this.props.entity}/`);
    }
    return window.location.reload();
  };

  render() {
    return (
      <div>
        {this.renderDeleteButton()}
        <Modal isOpen={this.state.isOpen} toggle={this.toggleModal} className={'modal-danger'}>
          <ModalHeader toggle={this.toggleModal}>Delete</ModalHeader>
          <ModalBody>
            <Row>
              <Col
                lg={2}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  color: '#f86c6b',
                }}
              >
                <i className='fa fa-warning fa-3x'></i>
              </Col>
              {this.props.saveError ? (
                <Col lg={10}>
                  <h3>Error:</h3>
                  <br />
                  <p>{this.props.error.message}</p>
                </Col>
              ) : (
                <Col lg={10}>
                  <h3>{this.props.content && this.props.content.name}</h3>
                  Are you sure you want to <strong>delete</strong> this
                  {this.props.modalType}? This action is <strong>irreversible.</strong>
                </Col>
              )}
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button color='danger' onClick={this.deleteEntity}>
              Delete
            </Button>{' '}
            <Button color='secondary' onClick={this.toggleModal}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}
function mapStateToProps(state) {
  const { saveError, error } = state.components;
  return { saveError, error };
}
export default withRouter(connect(mapStateToProps, { deleteEntity, resetModal })(DeleteModal));
