import { createNumberMask } from 'redux-form-input-masks';

export const validateWholeNumsWithDecimals = value => {
  if (!value) {
    return value;
  }
  const onlyNums = value.replace(/^\\d+$/, '');
  if (parseFloat(onlyNums) < 0) {
    return 0;
  }
  return onlyNums;
};

export const validateWholeNums = value => {
  if (!value) {
    return value;
  }
  const onlyNums = value.toString().replace(/\D/g, '');
  if (parseFloat(onlyNums) < 0) {
    return 0;
  }
  return onlyNums;
};


export const currencyMask = createNumberMask({
  prefix: '$ ',
  decimalPlaces: 3,
  locale: 'en-US'
});
