import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const SingleButtonModal = ({ isOpen, headerText, bodyText, primaryBtnText, onPrimaryClick }) => {
  return (
    <Modal isOpen={isOpen} className='modal-danger'>
      <ModalHeader>{headerText}</ModalHeader>
      <ModalBody>{bodyText}</ModalBody>
      <ModalFooter>
        <Button onClick={onPrimaryClick}>{primaryBtnText}</Button>
      </ModalFooter>
    </Modal>
  );
};

export default SingleButtonModal;
