import axios from 'axios';
import _, { flatten } from 'lodash';
import api from '../services';
import { formatDate } from '../utils/conversionFunctions';
import { getLandingPageByOfferId } from '../views/LandingPages/actions';
import {
  ADD_OFFER_ACTION,
  ADD_OFFER_ACTION_CRITERIA,
  ADD_OFFER_TRIGGER,
  ADD_QUESTION_ANSWER,
  DELETE_ENTITY,
  DELETE_ENTITIES,
  DELETE_OFFER_TRIGGERS,
  DELETE_QUESTION_ANSWERS,
  EDIT_OFFER_ACTION,
  FETCH_ALL_ACTIONS_TABLE,
  FETCH_ALL_ENTITIES,
  GET_AFFILIATES,
  GET_ALL_OFFER_ACTIONS,
  GET_CRITERIA_QUESTIONS,
  GET_DISPATCH,
  GET_DOMAINS,
  GET_ENTITIES_BY_ACTION_ID,
  GET_MARKETING_PARTNERS_OFFER_ACTIONS,
  GET_OFFER_ACTION,
  GET_OFFER_ACTION_CRITERIA,
  GET_OFFER_ACTION_POSTS,
  GET_OFFER_ACTION_LINKOUTS,
  GET_OFFER_ACTION_SUBTYPE,
  GET_OFFER_ACTION_TRIGGER_BY_ID,
  GET_OFFER_ACTIONS,
  GET_OFFER_DATA,
  GET_OFFER_TRIGGERS_BY_OFFER_ID,
  GET_OFFER_WALL_OFFER,
  GET_QUESTION_ANSWERS,
  GET_USER_ATTRIBUTES,
  GET_WALL_OFFERS,
  REMOVE_OFFER_TRIGGER_STATE,
  RESET_MODAL,
  SAVE_AS_ENTITY_NAME,
  SAVE_AS_OFFER_NAME,
  SAVE_INIT_DATA,
  SAVE_OFFER,
  SET_OFFER_ACTION,
  SET_OFFER_ACTIONS,
  SWAP_OFFER_WALL_SLOTS,
  UPDATE_ADDITIONAL_OFFERS,
  UPDATE_ADVERTISER,
  UPDATE_ADVERTISER_FORM,
  UPDATE_ADVERTISER_STATUS,
  UPDATE_CRITERIA_TYPE,
  UPDATE_ENTITY_ERROR,
  UPDATE_MARKETING_PARTNERS_OFFER_ACTIONS,
  UPDATE_OFFER_ACTION_POSTS,
  UPDATE_OFFER_ACTIONS,
  UPDATE_OFFER_STATE,
  UPDATE_OFFER_TRIGGERS,
  UPDATE_QUESTION_ANSWERS,
  UPDATE_SELECTED_OFFERS,
  UPDATE_SELECTED_POSTS,
  UPDATE_SELECTOR,
  UPDATE_TAG_FORM,
  UPDATE_TAG_STATUS,
  UPDATE_TAGS,
  UPDATE_OFFER_ACTION_LINKOUTS,
} from './types';

export const getDispatch = () => (dispatch) => {
  dispatch({
    type: GET_DISPATCH,
    payload: dispatch,
  });
};

export const getUserAttributes = () => (dispatch) => {
  api
    .get('user-attribute')
    .then((response) => {
      const sortedUserAttributes = sortByName(response.data);
      dispatch({
        type: GET_USER_ATTRIBUTES,
        payload: sortedUserAttributes,
      });
    })
    .catch((error) => console.log(error));
};

export const getAffliates = () => (dispatch) => {
  api
    .get('affiliates')
    .then((response) => {
      const affNames = response.data
        .map((affiliates) => {
          return affiliates.name;
        })
        .sort((a, b) => (a > b ? 1 : -1));
      affNames.unshift('[None]');
      dispatch({
        type: GET_AFFILIATES,
        payload: affNames,
      });
    })
    .catch((error) => console.log(error));
};

export const getDomains = () => (dispatch) => {
  api
    .get('domains')
    .then((response) => {
      const domains = response.data.map((a) => a.name);
      const domainsForPreviousRegReq = response.data.map((a) => a.name);
      domainsForPreviousRegReq.unshift('Current Domain');
      domainsForPreviousRegReq.unshift('All Domains');
      dispatch({
        type: GET_DOMAINS,
        payload: [domains, domainsForPreviousRegReq],
      });
    })
    .catch((error) => console.log(error));
};

export const getCriteriaQuestions = () => (dispatch) => {
  api.get('offerquestions').then((response) => {
    const offerQuestionsArr = [];
    const criteriaQuestions = response.data.map((a) => {
      offerQuestionsArr.push(a.question.question);
      return {
        id: a.question.id,
        name: a.offer.name,
        question: a.question.question,
      };
    });

    dispatch({
      type: GET_CRITERIA_QUESTIONS,
      payload: [criteriaQuestions, dispatch],
    });
  });
};

export const saveAsOfferName = (newOfferName) => {
  return {
    type: SAVE_AS_OFFER_NAME,
    payload: newOfferName,
  };
};

export const saveAsEntityName = (newEntityName) => {
  return {
    type: SAVE_AS_ENTITY_NAME,
    payload: newEntityName,
  };
};

export const handleError = (e) => {
  console.log(e);
  return {
    type: UPDATE_ENTITY_ERROR,
    payload: e,
  };
};

export const deleteEntity = (id, entity) => async (dispatch) => {
  await api
    .delete(`${entity}/${id}`)
    .then((result) => {
      dispatch({
        type: DELETE_ENTITY,
        payload: entity,
      });
    })
    .catch((error) => {
      dispatch(handleError(error.response.data));
    });
};

export const deleteEntities = (ids, entity) => async (dispatch) => {
  await api
    .delete(`${entity}`, { data: ids })
    .then((result) => {
      dispatch({
        type: DELETE_ENTITIES,
        payload: entity,
      });
    })
    .catch((error) => {
      dispatch(handleError(error.response.data));
    });
};

export const resetModal = () => {
  return {
    type: RESET_MODAL,
  };
};

export const getQuestionAnswers = (id) => (dispatch) => {
  if (!id) return;
  /* For whatever reason, the input Field doesn't display the selected field unless I take this api request out of the promise. Might need to refactor */
  try {
    api
      .get(`offerquestionanswer/question/${id}`)
      .then((response) => {
        const criteriaQuestionAnswers = response.data.map(({ answer }) => answer);
        dispatch({
          type: GET_QUESTION_ANSWERS,
          payload: criteriaQuestionAnswers,
        });
        return criteriaQuestionAnswers;
      })
      .catch((error) => console.log(error));
  } catch (error) {
    console.log(error);
  }
};

export const getOfferWallOffer = (offerId) => (dispatch) => {
  try {
    return new Promise((resolve) => {
      api
        .get(`offerwalloffers/offer/${offerId}`)
        .then((response) => {
          dispatch({
            type: GET_OFFER_WALL_OFFER,
            payload: response.data,
          });
          resolve(response.data);
        })
        .catch((error) => console.log(error));
    });
  } catch (err) {
    console.log(err);
  }
};

const handleOfferWallAds = (offerWallAds) => {
  let wallOffers = offerWallAds.map(({ id, name, status }) => {
    return {
      id,
      name,
      status,
    };
  });
  wallOffers = wallOffers.filter((offer) => offer.status).sort((a, b) => (a.name > b.name ? 1 : -1));
  return wallOffers;
};

export const getWallOffers = () => (dispatch) => {
  try {
    return new Promise((resolve) => {
      const limit = 100;
      const offset = 0;
      api
        .get(`offerwallads?limit=${limit}&offset=${offset}`)
        .then((response) => {
          let allOfferWallAds = [...response.data.content];

          const { totalPages } = response.data;
          let allRequests = getAllAdditionalRequests(totalPages, limit, 'offerwallads');

          // Concurrently fire all page requests
          axios.all(allRequests).then((responses) => {
            console.log(responses);
            // Add remaining response data
            responses.forEach((response) => {
              let { content } = response.data;
              allOfferWallAds = [...allOfferWallAds, ...content];
            });

            let normalizedWallAds = handleOfferWallAds(allOfferWallAds);
            dispatch({
              type: GET_WALL_OFFERS,
              payload: normalizedWallAds,
            });
            resolve(normalizedWallAds);
            // })
          });
        })

        .catch((error) => console.log(error));
    });
  } catch (err) {
    console.log(err);
  }
};

export const swapOfferWallSlots = (sourceIndex, destinationIndex) => {
  return {
    type: SWAP_OFFER_WALL_SLOTS,
    payload: [sourceIndex, destinationIndex],
  };
};

export const getOfferData = (isEditMode) => async (dispatch, getState) => {
  // console.log(getState());
  let id = getState().offerState.id;
  let theOffer;
  try {
    return new Promise((resolve) => {
      if (!isEditMode) {
        theOffer = {
          isActive: true,
        };
        resolve(theOffer);
      }
      api
        .get(`offers/${id}`)
        .then(async (response) => {
          let theOffer = await response.data;
          console.log(theOffer);
          const { name = '' } = theOffer;
          const advertiser = !theOffer.advertiser ? 1 : theOffer.advertiser.id;
          const data = {
            content: theOffer,
            offerName: name,
            advertiserValue: advertiser,
            advertiserName: theOffer.advertiser.name,
          };
          dispatch({
            type: GET_OFFER_DATA,
            payload: data,
          });
          resolve(data);
        })
        .catch((error) => console.log(error));
    });
  } catch (err) {
    console.log(err);
  }
};

export const getOffersByActionId = (actionId) => {
  return api.get(`offers/offeraction/${actionId}`);
};

export const getOfferWallAdsByActionId = (actionId) => {
  return api.get(`offerwallads/action-id/${actionId}`);
};

export const getPostWaterFallsByActionId = (actionId) => {
  return api.get(`offer-action-post-waterfall/offeraction/${actionId}`);
};

export const getEntitiesByActionId = (id) => async (dispatch) => {
  const offersResponse = await getOffersByActionId(id);
  const offersWithLPs = offersResponse.data.map(async (offer) => {
    if ((offer.offerType && offer.offerType.id === 8) || offer.offerType.name === 'Landing Page') {
      const landingPage = await getLandingPageByOfferId(offer.id)(dispatch);
      offer.landingPageId = landingPage.id;
    }
    return offer;
  });
  const offerWallAdResponse = await getOfferWallAdsByActionId(id);
  const offerWallAds = _.uniqBy(offerWallAdResponse.data, 'id');

  const postWaterFalls = await (await getPostWaterFallsByActionId(id)).data.map((postWaterFall) => {
    return {
      id: postWaterFall.offerAction && postWaterFall.offerAction.id,
      offerAction: postWaterFall.offerAction,
      postWaterFallId: postWaterFall.id,
      name: postWaterFall.offerAction && postWaterFall.offerAction.title,
    };
  });

  axios.all(offersWithLPs).then((parsedOffers) => {
    dispatch({
      type: GET_ENTITIES_BY_ACTION_ID,
      payload: [id, [...parsedOffers, ...offerWallAds, ...postWaterFalls]],
    });
    return [...parsedOffers, ...offerWallAds, ...postWaterFalls];
  });
};

export const updateAdditionalOffers = (additionalOffers, isLoading) => {
  return {
    type: UPDATE_ADDITIONAL_OFFERS,
    payload: [additionalOffers, isLoading],
  };
};
export const updateSelectedWallOffers = (index, selectedOffers) => {
  return {
    type: UPDATE_SELECTED_OFFERS,
    payload: [index, selectedOffers],
  };
};

export const updateOfferState = (props) => {
  return {
    type: UPDATE_OFFER_STATE,
    payload: props,
  };
};

export const updateSelector = (offerType) => {
  let formSelector;
  switch (offerType) {
    case 'Question':
      formSelector = 'offerquestion';
      break;
    case 'Banner':
      formSelector = 'offerbanner';
      break;
    case 'Iframe':
      formSelector = 'offeriframe';
      break;
    case 'Form':
      formSelector = 'offerform';
      break;
    case 'Javascript':
      formSelector = 'offerjavascript';
      break;
    case 'Offer Wall':
      formSelector = 'offerwall';
      break;
    case 'advertisers':
      formSelector = 'advertisers';
      break;
    case 'advertiserForm':
      formSelector = 'advertiserForm';
      break;
    case 'tagForm':
      formSelector = 'tagForm';
      break;
    case 'Offer Wall Ad Detail':
      formSelector = 'offerWallAdDetails';
      break;
    case 'Landing Page Detail':
      formSelector = 'landingPageDetails';
      break;
    case 'Publisher Pre Ping':
      formSelector = 'publisherPrePing';
      break;
    default:
      formSelector = 'offerActionDetail';
  }
  return {
    type: UPDATE_SELECTOR,
    payload: formSelector,
  };
};

export const updateCriteriaType = (criteriaType) => {
  return {
    type: UPDATE_CRITERIA_TYPE,
    payload: criteriaType,
  };
};

const getCriteriaValues = (formValues = {}) => {
  const criteriaFields = [
    'capAmount',
    'capSegmentConfiguration',
    'criteriaAffNames',
    'criteriaAibList',
    'criteriaBrowsers',
    'criteriaDomains',
    'criteriaDailyLimit',
    'criteriaDailyLimitAnswers',
    'criteriaDayHourRestrictions',
    'criteriaDevices',
    'criteriaEmailDomains',
    'criteriaEmailHasBeenVerifiedWithBriteverify',
    'criteriaExcludeBrowserType',
    'criteriaExcludeGender',
    'criteriaIncludeOrExcludeAffNames',
    'criteriaAibListIncludeExclude',
    'criteriaIncludeOrExcludeBrowsers',
    'criteriaIncludeOrExcludeDomains',
    'criteriaIncludeOrExcludeDevices',
    'criteriaIncludeOrExcludeEmailDomains',
    'criteriaIncludeOrExcludeOperatingSystems',
    'criteriaIncludeOrExcludeStates',
    'criteriaIncludeOrExcludeUserAttributes',
    'criteriaIncludeOrExcludeUserIdLastDigit',
    'criteriaIncludeOrExcludeZips',
    'criteriaMaxAge',
    'criteriaMinAge',
    'criteriaOfferRequiresOptIn',
    'criteriaOperatingSystems',
    'criteriaPhoneHasBeenVerifiedWithBriteverify',
    'criteriaQuestion',
    'criteriaQuestionAnswers',
    'criteriaRequiredUserData',
    'criteriaStates',
    'criteriaUserAttributes',
    'criteriaUserAttributesAndOr',
    'criteriaUserIdLastDigit',
    'criteriaZips',
    'prePing',
    'prePingCustomHttpStatus',
    'prePingHandlerId',
    'prePingTimeout',
    'prePingHeader1',
    'prePingHeader2',
    'prePingMethod',
    'suppressionRules',
  ];

  const formValKeys = Object.keys(formValues);
  const criteriaValues = {};

  _.forEach(formValKeys, (key) => {
    if (criteriaFields.includes(key)) {
      criteriaValues[key] = formValues[key];
    }
  });
  return criteriaValues;
};

export const saveInitData = (formName) => async (dispatch, getState) => {
  console.log(formName, 'Getting crit vals');
  const formValues = getState().form[formName].values;
  const criteriaValues = getCriteriaValues(formValues);
  console.log(criteriaValues);

  dispatch({
    type: SAVE_INIT_DATA,
    payload: criteriaValues,
  });
};

export const updateAdvertiserForm = (formValues) => {
  return {
    type: UPDATE_ADVERTISER_FORM,
    payload: formValues,
  };
};

export const isNewAdvertiser = (isNewAdvertiser) => {
  return {
    type: UPDATE_ADVERTISER_STATUS,
    payload: isNewAdvertiser,
  };
};

export const updateTagForm = (formValues) => {
  return {
    type: UPDATE_TAG_FORM,
    payload: formValues,
  };
};

export const isNewTag = (isNewAdvertiser) => {
  return {
    type: UPDATE_TAG_STATUS,
    payload: isNewAdvertiser,
  };
};

export const sortByName = (dataArr) => {
  if (!dataArr.length) return [];
  return dataArr.sort((a, b) => {
    return (a.name || '').toLowerCase() > (b.name || '').toLowerCase() ? 1 : -1;
  });
};

export const sortByDate = (a, b, order) => {
  var dateA = new Date(a.lastEdited).getTime();
  var dateB = new Date(b.lastEdited).getTime();
  if (order === 'asc') {
    return dateA > dateB ? 1 : -1;
  }
  return dateA > dateB ? -1 : 1;
};

export const normalizeAdvertisers = (advertisers) => {
  if (!advertisers.length) return;
  return advertisers.map((advertiser) => ({
    ...advertiser,
    name: advertiser.name.replace(/\s+$/, ''),
  }));
};

export const getOfferActionsById = (offerActionIds) => (dispatch) => {
  try {
    return new Promise((resolve) => {
      const staleOfferActions = offerActionIds.map((id) => {
        return getOfferAction(id)(dispatch);
      });
      Promise.all(staleOfferActions).then((offerActions) => {
        dispatch({
          type: GET_OFFER_ACTIONS,
          payload: offerActions,
        });
        resolve(offerActions);
        // return offerTriggers;
      });
    });
  } catch (error) {
    console.log(error);
  }
};

export const getOfferAction = (id) => (dispatch) => {
  try {
    return api.get(`offer-action/${id}`).then((response) => {
      dispatch({
        type: GET_OFFER_ACTION,
        payload: response.data,
      });
      return response.data;
    });
  } catch (error) {
    console.log(error);
  }
};

export const getOfferActionSubtype = (id, offerActionType) => (dispatch) => {
  const actionType = offerActionType === 'POST' ? 'post' : 'linkout';
  try {
    return api.get(`offer-action-${actionType}/${id}`).then((response) => {
      dispatch({
        type: GET_OFFER_ACTION_SUBTYPE,
        payload: response.data,
      });
      return response.data;
    });
  } catch (error) {
    console.log(error);
  }
};

export const getOfferActionPosts = () => (dispatch) => {
  try {
    return api.get('offer-action-post').then((response) => {
      console.log(response);
      const posts = response.data.content || response.data;
      const postsWithLastEdited = posts.map((post) => {
        const { offerAction } = post;
        const lastEdited = offerAction ? offerAction.lastEdited && formatDate(offerAction.lastEdited) : '';
        return { ...post, lastEdited };
      });

      const sortedActionPosts = postsWithLastEdited.sort((a, b) => (a.lastEdited > b.lastEdited ? -1 : 1));
      dispatch({
        type: GET_OFFER_ACTION_POSTS,
        payload: posts,
      });
      return posts;
    });
  } catch (error) {
    console.log(error);
  }
};

export const getOfferActionLinkouts = () => (dispatch) => {
  try {
    return api.get('offer-action-linkout').then((response) => {
      console.log(response);
      const linkouts = response.data.content || response.data;
      const linkoutsWithLastEdited = linkouts.map((linkout) => {
        const { offerAction } = linkout;
        const lastEdited = offerAction ? offerAction.lastEdited && formatDate(offerAction.lastEdited) : '';
        return { ...linkout, lastEdited };
      });

      const sortedActionLinkouts = linkoutsWithLastEdited.sort((a, b) => (a.lastEdited > b.lastEdited ? -1 : 1));
      dispatch({
        type: GET_OFFER_ACTION_LINKOUTS,
        payload: sortedActionLinkouts,
      });
      return sortedActionLinkouts;
    });
  } catch (error) {
    console.log(error);
  }
};

export const updateAdvertiser = (advertiser) => {
  return {
    type: UPDATE_ADVERTISER,
    payload: advertiser,
  };
};

export const updateTags = (tags) => {
  return {
    type: UPDATE_TAGS,
    payload: tags,
  };
};

export const updateOfferActions = (offerActions) => {
  return {
    type: UPDATE_OFFER_ACTIONS,
    payload: offerActions,
  };
};

export const setOfferAction = (offerAction) => {
  return {
    type: SET_OFFER_ACTION,
    payload: offerAction,
  };
};

export const setOfferActions = (offerActions) => {
  return {
    type: SET_OFFER_ACTIONS,
    payload: offerActions.sort(sortByDate),
  };
};

export const updateOfferActionPosts = (posts) => {
  return {
    type: UPDATE_OFFER_ACTION_POSTS,
    payload: posts,
  };
};

export const updateOfferActionLinkouts = (linkouts) => {
  return {
    type: UPDATE_OFFER_ACTION_LINKOUTS,
    payload: linkouts,
  };
};

export const updateSelectedPosts = (posts) => {
  return {
    type: UPDATE_SELECTED_POSTS,
    payload: posts,
  };
};

export const saveOffer = (isSaved) => {
  return {
    type: SAVE_OFFER,
    payload: isSaved,
  };
};

export const getOfferActionCriteria = (id) => (dispatch) => {
  try {
    return api.get(`offer-action-criteria/${id}`).then((response) => {
      dispatch({
        type: GET_OFFER_ACTION_CRITERIA,
        payload: response.data,
      });
      return response.data;
    });
  } catch (error) {
    console.log(error);
  }
};

export const getOfferActionTriggerById = (id) => (dispatch) => {
  if (!id) return;
  try {
    return new Promise((resolve) => {
      return api.get(`offer-action-trigger/${id}`).then((response) => {
        const offerActionTrigger = response.data;
        dispatch({
          type: GET_OFFER_ACTION_TRIGGER_BY_ID,
          payload: offerActionTrigger,
        });
        resolve(offerActionTrigger);
        return offerActionTrigger;
      });
    });
  } catch (error) {
    console.log(error);
  }
};

export const getOfferTriggersByOfferId = (id) => (dispatch) => {
  if (!id) return;
  try {
    return new Promise((resolve) => {
      return api.get(`offer-trigger/offer/${id}`).then(async (response) => {
        const unNormalizedOfferTriggers = response.data;
        console.log('Get offerTriggers by Offer ID');
        const normalziedOfferTriggers = unNormalizedOfferTriggers.map((offerTrigger) => {
          const unNormalizedOfferActionTriggers = offerTrigger.offerActionTriggers.map(async (offerActionTrigger) => {
            if (typeof offerActionTrigger === 'number') {
              return await getOfferActionTriggerById(offerActionTrigger)(dispatch);
            }
            return offerActionTrigger;
          });
          return {
            ...offerTrigger,
            offerActionTriggers: unNormalizedOfferActionTriggers,
          };
        });
        // );
        console.log('OfferTriggers with nested promises of offerActionTriggers : ', normalziedOfferTriggers);

        const offerTriggers = normalziedOfferTriggers.map(async (offerTrigger) => {
          const offerActionTriggers = offerTrigger.offerActionTriggers;
          return Promise.all(offerActionTriggers).then((oAT) => {
            // return oAT;
            return { ...offerTrigger, offerActionTriggers: oAT };
          });
        });
        Promise.all(offerTriggers).then((offerTriggers) => {
          let offerActionTriggers = offerTriggers.map((offerTrigger) => {
            return offerTrigger.offerActionTriggers;
          });
          offerActionTriggers = flatten(offerActionTriggers).filter(
            (offerActionTrigger) => typeof offerActionTrigger === 'object'
          );
          // console.log(offerActionTriggers);
          dispatch({
            type: GET_OFFER_TRIGGERS_BY_OFFER_ID,
            payload: [offerTriggers, offerActionTriggers],
          });
          resolve(offerTriggers);
          return offerTriggers;
        });
      });
    });
  } catch (error) {
    console.log(error);
  }
};

export const addOfferTrigger = (offerTriggers) => (dispatch) => {
  try {
    return new Promise((resolve) => {
      const savedOfferTriggers = offerTriggers.map((offerTrigger) => {
        return api.post('offer-trigger', offerTrigger);
      });
      Promise.all(savedOfferTriggers).then((response) => {
        const offerTriggers = response.map((res) => res.data);
        console.log('offerTriggers after addOFferTrigFunc : ', offerTriggers);
        dispatch({
          type: ADD_OFFER_TRIGGER,
          payload: offerTriggers,
        });
        resolve(offerTriggers);
        // return offerTriggers;
      });
    });
  } catch (error) {
    console.log(error);
  }
};

export const updateOfferTriggers = (offerTriggers, existingOfferTriggers) => async (dispatch) => {
  if (existingOfferTriggers) {
    await deleteOfferTriggers(existingOfferTriggers, dispatch);
  }
  return new Promise(async (resolve) => {
    try {
      const updatedOfferTriggers = await addOfferTrigger(offerTriggers)(dispatch);
      dispatch({
        type: UPDATE_OFFER_TRIGGERS,
        payload: updatedOfferTriggers,
      });
      console.log(updatedOfferTriggers);
      resolve(updatedOfferTriggers);
      return updatedOfferTriggers;
    } catch (error) {
      console.log(error);
    }
  });
};

export const addOfferQuestionAnswer = (questionAnswers) => (dispatch) => {
  if (_.isEmpty(questionAnswers)) return Promise.resolve([]);

  try {
    return new Promise((resolve) => {
      const savedQuestionAnswers = questionAnswers.map((questionAnswer) => {
        return api.post('offerquestionanswer', questionAnswer);
      });
      Promise.all(savedQuestionAnswers).then((results) => {
        const questionAnswers = results.map((result) => result.data);
        dispatch({
          type: ADD_QUESTION_ANSWER,
          payload: questionAnswers,
        });
        resolve(questionAnswers);
      });
    });
  } catch (error) {
    console.log(error);
  }
};

export const updateOfferQuestionAnswers = (questionAnswers) => (dispatch) => {
  if (_.isEmpty(questionAnswers)) return Promise.resolve([]);

  try {
    return new Promise((resolve) => {
      const updatedQuestionAnswers = questionAnswers.map((questionAnswer) => {
        return api.put(`offerquestionanswer/${questionAnswer.id}`, questionAnswer);
      });
      Promise.all(updatedQuestionAnswers).then((results) => {
        const questionAnswers = results.map((result) => result.data);
        console.log(questionAnswers);
        dispatch({
          type: UPDATE_QUESTION_ANSWERS,
          payload: questionAnswers,
        });
        resolve(questionAnswers);
      });
    });
  } catch (error) {
    console.error(error);
  }
};

export const deleteOfferQuestionAnswers = (questionAnswers, offerTriggersToRemove) => (dispatch) => {
  try {
    deleteOfferTriggers(offerTriggersToRemove, dispatch).then(() => {
      return new Promise((resolve) => {
        const deletedQuestionAnswers = questionAnswers.map((questionAnswer) => {
          console.log(questionAnswer);
          return api.delete(`offerquestionanswer/${questionAnswer.id}`);
        });
        Promise.all(deletedQuestionAnswers).then((questionAnswers) => {
          // console.log(questionAnswers);
          dispatch({
            type: DELETE_QUESTION_ANSWERS,
            payload: questionAnswers,
          });
          resolve(questionAnswers);
        });
      });
    });
  } catch (error) {
    console.log(error);
  }
};

export const removeOfferTriggerState = () => {
  return {
    type: REMOVE_OFFER_TRIGGER_STATE,
    payload: [],
  };
};

export const deleteOfferTriggers = (offerTriggers, dispatch) => {
  // console.log(offerTriggers);
  if (offerTriggers.length === 0) return;
  try {
    return new Promise((resolve) => {
      const deletedOfferTriggers = offerTriggers.map((offerTrigger) => {
        console.log(offerTrigger);
        return api.delete(`offer-trigger/${offerTrigger.id}`);
      });
      console.log('Deleting offer triggers!');
      Promise.all(deletedOfferTriggers).then(() => {
        console.log('Done Deleting offer triggers>>>>>');
        dispatch({
          type: DELETE_OFFER_TRIGGERS,
          payload: deletedOfferTriggers,
        });
        resolve(deletedOfferTriggers);
        // return offerTriggers;
      });
    });
  } catch (error) {
    console.log(error);
  }
};

export const addOfferActionCriteria = (offerActionCriteria) => (dispatch) => {
  try {
    return api.post('offer-action-criteria', offerActionCriteria).then((newOfferAction) => {
      // console.log(JSON.stringify(offerAction));
      console.log(newOfferAction);
      dispatch({
        type: ADD_OFFER_ACTION_CRITERIA,
        payload: newOfferAction,
      });
      return newOfferAction;
    });
  } catch (error) {
    console.log(error);
  }
};

export const addOfferAction = (offerAction, offerActionType) => async (dispatch) => {
  let actionType;

  console.log(offerActionType);
  switch (offerActionType) {
    case 'POST':
      actionType = 'post';
      break;
    case 'LINKOUT':
      actionType = 'linkout';
      break;
    case 'USER_ATTRIBUTE':
      actionType = 'user-attribute';
      break;
    case 'POST_WATERFALL':
      actionType = 'post-waterfall';
      break;
    default:
      actionType = 'linkout-waterfall';
  }

  if (actionType === 'post-waterfall' || actionType === 'linkout-waterfall') {
    delete offerAction.id;
    const offerActionSubTypeName = offerActionType === 'POST_WATERFALL' ? 'offerActionPost' : 'offerActionLinkout';
    offerAction[`${offerActionSubTypeName}WaterfallEntries`].forEach((entry) => {
      entry.id = null;
    });
  }
  try {
    return await api.post(`offer-action-${actionType}`, offerAction).then((result) => {
      console.log(JSON.stringify(result.data));
      console.log(result.data);
      dispatch({
        type: ADD_OFFER_ACTION,
        payload: result.data,
      });
      return result.data;
    });
  } catch (error) {
    console.log(error);
  }
};

export const editOfferAction = (offerAction, offerActionType, id) => (dispatch) => {
  let actionType;

  switch (offerActionType) {
    case 'POST':
      actionType = 'post';
      break;
    case 'LINKOUT':
      actionType = 'linkout';
      break;
    case 'USER_ATTRIBUTE':
      actionType = 'user-attribute';
      break;
    case 'POST_WATERFALL':
      actionType = 'post-waterfall';
      break;
    default:
      actionType = 'linkout-waterfall';
  }

  try {
    return api.put(`offer-action-${actionType}/${id}`, offerAction).then((result) => {
      console.log(JSON.stringify(result.data));
      console.log(result.data);

      dispatch({
        type: EDIT_OFFER_ACTION,
        payload: result.data,
      });
      return result.data;
    });
  } catch (error) {
    console.log(error);
  }
};

// Get All Offer Actions
export const getAllOfferActions = (normalizedOfferActions) => {
  return {
    type: GET_ALL_OFFER_ACTIONS,
    payload: normalizedOfferActions,
  };
};

export const fetchAllOfferActions = () => {
  return function(dispatch) {
    return fetchAllOfferActionsApi().then(
      (normalizedOfferActions) => dispatch(getAllOfferActions(normalizedOfferActions)),
      (error) => console.log(error)
    );
  };
};

function fetchAllOfferActionsApi(limit = 100) {
  return new Promise((resolve) => {
    api
      .get(`offer-action?limit=${limit}`)
      .then((response) => {
        // Set initial data
        let allOfferActionsData = Array.from(response.data.content);

        // Retrieve remaining pages
        const { totalPages } = response.data;
        let allRequests = getAllAdditionalRequests(totalPages, limit, 'offer-action');

        // Concurrently fire all page requests
        axios
          .all(allRequests)
          .then(
            axios.spread((...responses) => {
              // Add remaining response data
              responses.forEach((response) => {
                let content = response.data.content;
                allOfferActionsData = [...allOfferActionsData, ...content];
              });

              let normalizedActions = handleOfferActions(allOfferActionsData, false);
              resolve(normalizedActions);
            })
          )
          .catch((err) => console.log(err));
      })
      .catch((err) => console.log(err));
  });
}

export const fetchAllEntities = (endpoint) => (dispatch) => {
  const limit = 100;
  let typeOfAction;
  switch (endpoint) {
    case 'offer-action/table':
      typeOfAction = FETCH_ALL_ACTIONS_TABLE;
      break;
    default:
      typeOfAction = FETCH_ALL_ENTITIES;
      break;
  }
  return new Promise((resolve) => {
    api
      .get(`${endpoint}?limit=${limit}`)
      .then((response) => {
        // Set initial data
        let entities = Array.from(response.data.content);

        // Retrieve remaining pages
        const { totalPages } = response.data;
        let allRequests = getAllAdditionalRequests(totalPages, limit, endpoint);
        // Concurrently fire all page requests
        axios
          .all(allRequests)
          .then(
            axios.spread((...responses) => {
              // Add remaining response data
              responses.forEach((response) => {
                let content = response.data.content;
                entities = [...entities, ...content];
              });
              let offerActionSubtypes = null;
              if (typeOfAction === FETCH_ALL_ACTIONS_TABLE) {
                offerActionSubtypes = handleOfferActions(entities, false);
              }
              dispatch({
                type: typeOfAction,
                payload: [entities, offerActionSubtypes],
              });
              resolve(entities);
            })
          )
          .catch((err) => console.log(err));
      })
      .catch((err) => console.log(err));
  });
};

export const getAllAdditionalRequests = (totalPages, limit, endpoint) => {
  let allRequests = [];
  for (let i = 1; i < totalPages; i++) {
    allRequests.push(getAdditionalRequest(i, limit, endpoint));
  }
  return allRequests;
};

export const getAdditionalRequest = (offset, limit, endpoint) => {
  return api.get(`${endpoint}?limit=${limit}&offset=${offset}`);
};

function handleOfferActions(offerActions, isRefetch) {
  const offerActionPosts = [];
  const offerActionLinkouts = [];
  let offerActionAttributes = [];
  const offerActionPostWaterfalls = [];
  const offerActionLinkoutWaterfalls = [];
  const offerActionGtmEvents = [];
  const LINKOUT = [];
  const POST = [];
  const USER_ATTRIBUTE = [];
  const POST_WATERFALL = [];
  const LINKOUT_WATERFALL = [];
  const GTM_EVENT = [];

  try {
    offerActions.forEach((offerAction) => {
      const {
        offerActionPost = {},
        offerActionLinkout = {},
        offerActionUserAttributes = [],
        offerActionPostWaterfall = {},
        offerActionType = {},
      } = offerAction;

      if (offerActionType === 'POST') {
        POST.push(offerAction);
        offerActionPosts.push({
          active: offerAction.active || null,
          id: offerActionPost.id || null,
          offerActionId: offerAction.id || null,
          name: offerAction.title || null,
          title: offerAction.title || null,
          advertiser: offerActionPost.advertiser || null,
          url: offerActionPost.url || null,
          postMethod: offerActionPost.postMethod || null,
          http_auth_username: offerActionPost.answerHttpUsername || null,
          http_auth_password: offerActionPost.answerHttpPassword || null,
          body: offerActionPost.body || null,
          has_revenue: offerActionPost.revenue || offerActionPost.revenueAmount ? true : false,
          header_1: offerActionPost.httpHeader1 || null,
          header_2: offerActionPost.httpHeader2 || null,
          header_3: offerActionPost.httpHeader3 || null,
          killswitch_handler_id: offerActionPost.httpKillSwitchHandler || null,
          killswitch_http_status: offerActionPost.otherKillSwitchStatus || null,
          handlerId:
            typeof offerActionPost.httpSuccessHandler === 'object' && offerActionPost.httpSuccessHandler !== null
              ? offerActionPost.httpSuccessHandler.id
              : null,
          revenue_on_gross_or_success: offerActionPost.revenueOn || null,
          revenue: offerActionPost.revenueAmount || null,
          custom_http_status: offerActionPost.otherSuccessfulHttpStatus || null,
        });
      } else if (offerActionType === 'LINKOUT') {
        LINKOUT.push(offerAction);
        offerActionLinkouts.push({
          active: offerAction.active || null,
          id: offerActionLinkout.id || null,
          offerActionId: offerAction.id || null,
          name: offerAction.title || null,
          title: offerAction.title || null,
          advertiser: offerActionLinkout.advertiser || null,
          url: offerActionLinkout.url || null,
          linkout_has_revenue: offerActionLinkout.hasRevenue || null,
          linkout_revenue: offerActionLinkout.revenue || null,
          killswitch_handler_id: offerActionLinkout.httpKillSwitchHandler || null,
          killswitch_http_status: offerActionLinkout.httpKillSwitchHandler || null,
          handlerId:
            typeof offerActionLinkout.httpSuccessHandler === 'object' && offerActionLinkout.httpSuccessHandler !== null
              ? offerActionLinkout.httpSuccessHandler.id
              : null,
        });
      } else if (
        offerActionType === 'USER_ATTRIBUTE' ||
        (offerActionUserAttributes && offerActionUserAttributes.length > 0)
      ) {
        USER_ATTRIBUTE.push(offerAction);
        offerActionAttributes.push({
          active: offerAction.active || null,
          id: (offerActionUserAttributes.length > 0 && offerActionUserAttributes[0].id) || null,
          offerActionId: offerAction.id || null,
          name: offerAction.title || null,
          title: offerAction.title || null,
        });
      } else if (offerActionType === 'POST_WATERFALL') {
        POST_WATERFALL.push(offerAction);
        offerActionPostWaterfalls.push({
          active: offerAction.active || null,
          id: offerActionPostWaterfall?.id || null,
          offerActionId: offerAction.id || null,
          name: offerAction.title || null,
          title: offerAction.title || null,
        });
      } else if (offerActionType === 'LINKOUT_WATERFALL') {
        LINKOUT_WATERFALL.push(offerAction);
        offerActionLinkoutWaterfalls.push({
          active: offerAction.active || null,
          id: offerActionPostWaterfall?.id || null,
          offerActionId: offerAction.id || null,
          name: offerAction.title || null,
          title: offerAction.title || null,
        });
      } else if (offerActionType === 'GTM_EVENT') {
        GTM_EVENT.push(offerAction);
        offerActionGtmEvents.push({
          id: offerAction.id,
          active: offerAction.active || null,
          offerActionId: offerAction.id || null,
          name: offerAction.title || null,
          title: offerAction.title || null,
        });
      }
    });

    if (isRefetch) {
      return [
        ...offerActionPosts,
        ...offerActionLinkouts,
        ...offerActionAttributes,
        ...offerActionPostWaterfalls,
        ...offerActionLinkoutWaterfalls,
        ...offerActionGtmEvents,
      ];
    }
    const actions = {
      linkouts: offerActionLinkouts,
      posts: offerActionPosts,
      attributes: offerActionAttributes,
      postWaterfalls: offerActionPostWaterfalls,
      linkoutWaterfalls: offerActionLinkoutWaterfalls,
      gtmEvents: offerActionGtmEvents,
      LINKOUT,
      POST,
      USER_ATTRIBUTE,
      POST_WATERFALL,
      LINKOUT_WATERFALL,
      GTM_EVENT,
      active: offerActions.active,
      offerActions,
    };
    return actions;
  } catch (error) {
    console.log(error);
    console.log('There was an error handling your data.');
  }
}

export const findAllMarketingPartnersByOfferAction = (offerActionId) => (dispatch) => {
  try {
    return api.get(`marketing_partner/offer_actions/${offerActionId}`).then((result) => {
      console.log(JSON.stringify(result.data));
      console.log(result.data);

      dispatch({
        type: GET_MARKETING_PARTNERS_OFFER_ACTIONS,
        payload: result.data,
      });
      return result.data;
    });
  } catch (error) {
    console.log(error);
  }
};

export const updateMarketingPartnersByOfferAction = (marketingPartner) => (dispatch) => {
  try {
    return api.put(`marketing_partner/${marketingPartner.id}`, marketingPartner).then((result) => {
      console.log(result.data);
      dispatch({
        type: UPDATE_MARKETING_PARTNERS_OFFER_ACTIONS,
        payload: result.data,
      });
      return result.data;
    });
  } catch (error) {
    console.log(error);
  }
};
