import React from 'react';
import DropdownList from 'react-widgets/DropdownList';
import { Alert } from 'reactstrap';

const renderDropdownList = ({ input, allData, meta, label, onChange }) => {
  const { value } = input;
  return (
    <>
      <DropdownList value={value} onChange={onChange || input.onChange} data={allData} textField='name'/>
      {meta.error && meta.touched && <Alert color='danger'>{meta.error}</Alert>}
    </>
  );
};

export default renderDropdownList;
