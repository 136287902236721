import config from 'react-global-configuration';

export const CDN_ASSETS_BASE = process.env.NODE_ENV === 'test' ? 'test' : `${window.env.CDN_ASSETS_BASE}`;
export const OFFER_CDN_URL = process.env.NODE_ENV === 'test' ? 'test' : `${window.env.OFFER_CDN_URL}`;

// const zuulBackendEnvVar = import.meta.env.VITE_ZUUL_API_BASE
//   ? `${import.meta.env.VITE_ZUUL_API_BASE}`
//   : `${window.env.baseURL}`;

const zuulBackendEnvVar =
  process.env.NODE_ENV === 'test'
    ? process.env.VITE_ZUUL_API_BASE
    : import.meta.env.VITE_ZUUL_API_BASE
    ? `${import.meta.env.VITE_ZUUL_API_BASE}`
    : `${window.env.baseURL}`;

console.log('zuulBackendEnvVar', zuulBackendEnvVar);
export const ZUUL_API_BASE = `${zuulBackendEnvVar}v1/`;
// export const CLIENT_ID = `${import.meta.env.VITE_CLIENT_ID}`;

export const PAYOUT_SCHEME = {
  5: {
    id: 5,
    name: 'Advertiser CPC',
  },

  2: {
    id: 2,
    name: 'CPC',
  },

  4: {
    id: 4,
    name: 'Rev Share',
  },
};
export const BROWSERS = {
  Chrome: {
    id: 1,
    name: 'Chrome',
  },
  Chromium: {
    id: 2,
    name: 'Chromium',
  },
  Firefox: {
    id: 3,
    name: 'Firefox',
  },
  Opera: {
    id: 4,
    name: 'Opera',
  },
  IE: {
    id: 5,
    name: 'IE',
  },
  Safari: {
    id: 6,
    name: 'Safari',
  },
  Other: {
    id: 7,
    name: 'Other',
  },
};

export const TRIGGER_TYPES = {
  ON_ANSWER: {
    id: 1,
    name: 'ON_ANSWER',
  },
  Clicked: {
    id: 2,
    name: 'ON_CLICK',
  },
  Impression: {
    id: 3,
    name: 'ON_IMPRESSION',
  },
  'Clicked or skipped': {
    id: 4,
    name: 'ON_CLICK_OR_SKIP',
  },
  post_on_submit: {
    id: 5,
    name: 'SUBMIT',
  },
  post_on_submit_and_param: {
    id: 6,
    name: 'SUBMIT+PARAMS',
  },
  post_on_submit_and_certified_lookup: {
    id: 7,
    name: 'SUBMIT+CERTIFIED_LOOKUP',
  },
};

export const DB_TRIGGER_TYPES = {
  ON_ANSWER: {
    id: 1,
    name: 'ON_ANSWER',
  },
  ON_CLICK: {
    id: 2,
    name: 'ON_CLICK',
  },
  ON_IMPRESSION: {
    id: 3,
    name: 'ON_IMPRESSION',
  },
  ON_CLICK_OR_SKIP: {
    id: 4,
    name: 'ON_CLICK_OR_SKIP',
  },
  SUBMIT: {
    id: 5,
    name: 'SUBMIT',
  },
  'SUBMIT+PARAMS': {
    id: 6,
    name: 'SUBMIT+PARAMS',
  },
  'SUBMIT+CERTIFIED_LOOKUP': {
    id: 7,
    name: 'SUBMIT+CERTIFIED_LOOKUP',
  },
};

export const NEGATIVE_COLOR_HEX = '#f86c6b';
export const POSITIVE_COLOR_HEX = '#4dbd74';
