import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import { change, Field } from 'redux-form';
import { renderActionTypeDropdown, renderField, renderSelectedOfferAction } from '../../Offers/OfferTitle/renderConsts';
import { filterActionTypesByLinkoutCount } from '../../../utils/OfferActionHelper';

class OfferActionForTrigger extends Component {
  handleOfferActionChange = (selector) => {
    this.props.change(this.props.formName, `${selector}.triggerType`, null);
    this.props.change(this.props.formName, `${selector}.name`, null);
    this.props.change(this.props.formName, `${selector}.action_advertiser`, null);
    this.props.change(this.props.formName, `${selector}.action_id`, null);
    this.props.change(this.props.formName, `${selector}.actionStatus`, null);
  };

  handleOfferActionNameChange = (action = {}, selector) => {
    const active = action.active ? action.active : null;
    const advertiser = action.advertiser ? action.advertiser.name : null;
    const action_id = action.offerActionId ? action.offerActionId : '';
    this.props.change(this.props.formName, `${selector}.action_advertiser`, advertiser);
    this.props.change(this.props.formName, `${selector}.action_id`, action_id);
    this.props.change(this.props.formName, `${selector}.actionStatus`, active);
  };

  render() {
    const {
      action,
      action_id,
      actionStatus,
      entityName,
      offerActionForTrigger,
      index,
      fields,
      offerActionType,
      posts,
      linkouts,
      attributes,
      postWaterfalls,
      linkoutWaterfalls,
      gtmEvents,
      shouldShowSuppression,
      offerTriggers,
    } = this.props;
    const actionData =
      offerActionType === 'POST'
        ? posts
        : offerActionType === 'LINKOUT'
        ? linkouts
        : offerActionType === 'USER_ATTRIBUTE'
        ? attributes
        : offerActionType === 'POST_WATERFALL'
        ? postWaterfalls
        : offerActionType === 'LINKOUT_WATERFALL'
        ? linkoutWaterfalls
        : offerActionType === 'GTM_EVENT'
        ? gtmEvents
        : [];

    const disabled = !this.props.offerActions || this.props.offerActions.length < 1;

    let action_id_val = typeof action === 'object' && action !== null ? action.offerActionId : action_id;

    const suppressionOffset = index === 0 && fields.length === 1 ? 3 : 4;
    return (
      <>
        <Row className='offerActionForTrigger' lg={12}>
          {renderActionTypeDropdown({
            data: filterActionTypesByLinkoutCount(offerTriggers),
            handleOfferActionChange: this.handleOfferActionChange,
            fieldName: `${offerActionForTrigger}.offerActionType`,
            offerAction: offerActionForTrigger,
          })}

          {fields.length > 1 && (
            <Col lg={1} style={{ marginTop: '2%' }}>
              <Button
                color='danger'
                className='removeofferInputParent'
                type='button'
                title='Remove Action'
                onClick={() => {
                  fields.remove(index);
                }}
              >
                <i className='fa fa-close' />
              </Button>
            </Col>
          )}

          {offerActionType && (
            <Col lg={1} style={{ marginTop: '2%' }}>
              {!action_id ? (
                <Button color='info' type='button' title='Visit action details' disabled={true}>
                  <i className='fa fa-search' />
                </Button>
              ) : (
                <Link to={`/campaigns/offeractions/${action_id}`} target='_blank'>
                  <Button color='info' type='button' title='Visit action details'>
                    <i className='fa fa-search' />
                  </Button>
                </Link>
              )}
            </Col>
          )}

          {renderSelectedOfferAction({
            actionStatus,
            entityName,
            handleOfferActionNameChange: this.handleOfferActionNameChange,
            offerAction: offerActionForTrigger,
            actionType: offerActionType,
            data: actionData,
            disabled,
            shouldShowSuppression,
          })}

          {offerActionType && (offerActionType === 'POST' || offerActionType === 'LINKOUT') && (
            <Col lg={3}>
              <Field
                name={`${offerActionForTrigger}.action_advertiser`}
                component={renderField}
                type='text'
                disabled
                label='Advertiser Name'
              />
            </Col>
          )}
        </Row>
        <br />
      </>
    );
  }
}

function mapStateToProps(state, props) {
  const { allOfferActions } = state.metadata;
  const { selector, formName } = state.formSelector;
  let offerTriggers = selector(state, `offerWallAdActions`) || [];
  const flattenedOfferTriggers = [];

  if (offerTriggers != null) {
    offerTriggers = offerTriggers.forEach((ot) => {
      if (ot?.actions != null) {
        ot.actions.forEach((a) => {
          flattenedOfferTriggers.push(a);
        });
      }
    });
  }


  return {
    selector,
    formName,
    ...allOfferActions,
    action: selector(state, `${props.offerActionForTrigger}.name`),
    action_id: selector(state, `${props.offerActionForTrigger}.action_id`),
    offerActionType: selector(state, `${props.offerActionForTrigger}.offerActionType`),
    triggerType: selector(state, `${props.offerActionForTrigger}.triggerType`),
    actionStatus: selector(state, `${props.offerActionForTrigger}.actionStatus`),
    offerTriggers: flattenedOfferTriggers,
  };
}

export default connect(mapStateToProps, { change })(OfferActionForTrigger);
