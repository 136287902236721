export const GET_LANDING_PAGE_GROUP = 'GET_LANDING_PAGE_GROUP';
export const GET_ALL_LANDING_PAGE_GROUPS = 'GET_ALL_LANDING_PAGE_GROUPS';
export const SET_LANDING_PAGE_GROUP = 'SET_LANDING_PAGE_GROUP';
export const UPDATE_LANDING_PAGE_GROUP = 'UPDATE_LANDING_PAGE_GROUP';
export const UPDATE_SELECTED_LANDING_PAGES = 'UPDATE_SELECTED_LANDING_PAGES';
export const UPDATE_LANDING_PAGE_GROUP_ERROR = 'UPDATE_LANDING_PAGE_GROUP_ERROR';
export const UPDATE_LANDING_PAGE_GROUP_WARNING = 'UPDATE_LANDING_PAGE_GROUP_WARNING';
export const CREATE_LANDING_PAGE_GROUP = 'CREATE_LANDING_PAGE_GROUP';
export const GET_LANDING_PAGE_GROUPS = 'GET_LANDING_PAGE_GROUPS';
export const GET_SITES = 'GET_SITES';
