import {GET_SITES, GET_SITES_BY_OFFER_ID, GET_OFFER_BLOCK_SUMMARY_BY_OFFER_ID } from '../../actions/types';
import api from '../../../../services';
import { uniqBy } from 'lodash';

export const fetchAllSites = () => (dispatch) => {
  // TODO refactor other instances of fetchAllSites to use this function
  try {
  return api.get('sites').then((response) => {
    const parsedSites = response.data.map((site) => {
      return {
        id: site.id,
        domain: site.domain,
      };
    });
    dispatch({
      type: GET_SITES,
      payload: parsedSites,
    });
    return parsedSites;
  });
  } catch (error) {
    console.log(error);
  }
};

export const getSitesByOfferId = (id) => async (dispatch) => {
  try {
    const response = await api.get(`sites/offer/${id}`);
    const sites = uniqBy(
      response.data.filter((siteLocationDTO) => siteLocationDTO.offersSequenceStatus),
      'id'
    );
    dispatch({
      type: GET_SITES_BY_OFFER_ID,
      payload: [id, sites],
    });
    return sites;
  } catch (error) {
    console.error(error);
  }
};

export const getOfferBlockSummaryByOfferId = (id) => async (dispatch) => {
  try {
    const response = await api.get(`offerblocks/offerBlockSummary/${id}`);
    const offerBlockSummary = response.data;
    dispatch({
      type: GET_OFFER_BLOCK_SUMMARY_BY_OFFER_ID,
      payload: { offerId:id, offerBlockSummary },
    });
    return offerBlockSummary;
  } catch (error) {
    console.error(error);
  }
};
