import React from 'react';
import { Card, CardHeader, CardBody } from 'reactstrap';
import classNames from 'classnames';
import { makeControlSeqsTableData, makeRecommendedSeqsTableData } from './utilityOfferReorderingDetails';

import './OfferReorderingDetails.scss';

const OfferReoderingDetailsComparison = ({ controlSequences, recommendedSequences }) => {
  if (!controlSequences || !recommendedSequences) return null;

  const headers = ['Offer Name', 'Advertiser', 'Offer Type'];
  const controlSeqsTableData = makeControlSeqsTableData(controlSequences);
  const recommendedSeqsTableData = makeRecommendedSeqsTableData(controlSeqsTableData, recommendedSequences);

  const renderTableHeader = () => (
    <thead>
      <tr>
        {headers.map(header => (
          <th key={header}> {header} </th>
        ))}
      </tr>
    </thead>
  );

  const renderTableBody = data => (
    <tbody>
      {data.map(({ advertiser, highlighted, id, name, offerId, offerType }, index) => (
        <tr
          key={`${index}-${id}-${offerId}`}
          className={classNames(highlighted && 'highlightRow', !name && 'missing-data')}
        >
          {renderTableBodyRow({ advertiser, name, offerId, offerType })}
        </tr>
      ))}
    </tbody>
  );

  const renderTableBodyRow = ({ advertiser, name, offerId, offerType }) =>
    !name || !advertiser || !offerType ? (
      <td colSpan={headers.length}>Offer ID: {offerId} was not found in control sequence </td>
    ) : (
      <>
        <td>{name}</td>
        <td>{advertiser}</td>
        <td>{offerType}</td>
      </>
    );

  return (
    <Card className='offer-reordering-details'>
      <CardHeader>
        <h3>Comparison</h3>
      </CardHeader>
      <CardBody className='offer-reordering-details-comparison'>
        <div>
          <h4>Control Sequence</h4>
          <table aria-label='Control Sequence'>
            {renderTableHeader()}
            {renderTableBody(controlSeqsTableData)}
          </table>
        </div>
        <div>
          <h4>Recommended Sequence</h4>
          <table aria-label='Recommended Sequence'>
            {renderTableHeader()}
            {renderTableBody(recommendedSeqsTableData)}
          </table>
        </div>
      </CardBody>
    </Card>
  );
};

export default OfferReoderingDetailsComparison;
