import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Row, Col } from 'reactstrap';
import _ from 'lodash';
import { FieldArray } from 'redux-form';
import { Droppable } from 'react-beautiful-dnd';
import { updateSelectedWallOffers } from '../../../../actions';
import './OfferWall.css';

import Slot from './Slot';

class OfferSlots extends Component {
  state = {
    filteredPosts: [],
    columns: ['postList', 'postWaterfall'],
    searchTerm: '',
    weights: {},
    percentages: {},
  };

  renderSlots = ({
    droppableProvided,
    droppableSnapshot,
    fields,
    offerWallSlotIndex,
  }) => {
    // const getItemStyle = (
    //   isDragging,
    //   draggableStyle,
    //   showTopBorder,
    //   areItemsDragging
    // ) => {
    //   return {
    //     userSelect: 'none',

    //     background: isDragging ? '#515b65' : null,
    //     boxShadow: isDragging
    //       ? '0px 0px 0px 0.2rem rgba(77, 189, 116, 0.25)'
    //       : null,
    //     border: isDragging ? '2px solid #4dbd74' : null,
    //     // styles we need to apply on draggables
    //     ...draggableStyle
    //   };
    // };
    try {
      let slotLabels = ['Control', 'A', 'B', 'C'];
      return fields.map((slot, rowIndex) => {
        // return (
        //   <Draggable key={slot} draggableId={slot} index={rowIndex}>
        //     {(provided, snapshot) => {
        return (
          <Slot
            droppableProvided={droppableProvided}
            droppableSnapshot={droppableSnapshot}
            name={slot}
            slot={slot}
            key={slot}
            offerWallSlotIndex={offerWallSlotIndex}
            slotType={slotLabels[rowIndex]}
            fields={fields}
            index={rowIndex}
          />
        );
        //   }}
        // </Draggable>
        // );
      });
    } catch (err) {
      console.log(err);
    }
  };

  handleOfferWallSlotRemoval = (offerWallSlot, fields, index) => {
    this.props.updateSelectedWallOffers(index, []);
    fields.remove(index);
  };

  render() {
    const { offerSlot, index, fields, offerWall } = this.props;
    const offerWallSlot = offerWall.selectedOffers[index];
    return (
      // <DragDropContext onDragEnd={this.onDragEnd}>
      // <Col>
      // <Draggable draggableId={offerSlot} index={index}>
      //   {(provided, snapshot) => {
      //     let offerWallSlotWidth;
      //     const offerWallSlotTransform = provided.draggableProps
      //       ? provided.draggableProps.style.transform
      //       : null;
      //     // grabs the x value in pixels from the transform property of each offerSlot
      //     let nonDraggingSlotTransform =
      //       typeof offerWallSlotTransform === 'string'
      //         ? offerWallSlotTransform.substring(
      //             10,
      //             offerWallSlotTransform.indexOf('px')
      //           )
      //         : null;
      //     offerWallSlotWidth = nonDraggingSlotTransform * 0.7;
      //     nonDraggingSlotTransform = nonDraggingSlotTransform
      //       ? `translate(${nonDraggingSlotTransform -
      //           offerWallSlotWidth}px, 0px)`
      //       : null;

      //     return (
      //       <div
      //         ref={provided.innerRef}
      //         {...provided.dragHandleProps}
      //         {...provided.draggableProps}
      //         className='offer-wall-slot'
      //         style={{
      //           // maxWidth: 300,

      //           ...provided.draggableProps.style,
      //           // minWidth: snapshot.isDragging ? 460 : 'initial',
      //           // width: snapshot.isDragging ? '40%' : 'initial',
      //           transform: snapshot.isDragging
      //             ? offerWallSlotTransform
      //             : nonDraggingSlotTransform
      //         }}
      //       >
      <>
        <Row>
          <Col sm={10} lg={10}>
            <strong>Offer Wall Slot {index + 1}</strong>{' '}
          </Col>
          <Col sm={2} lg={2}>
            {fields.length > 1 && (
              <Button
                className='delete-offerWall-slot-button'
                onClick={() =>
                  this.handleOfferWallSlotRemoval(offerWallSlot, fields, index)
                }
                color='danger'
              >
                <i className='fa fa-minus' />
              </Button>
            )}
          </Col>
        </Row>
        <Droppable
          droppableId={`offerWallSlot-${index}`}
          key={`offerWallSlot-${index}`}
          type='slot'
        >
          {(droppableProvided, droppableSnapshot) => {
            return (
              <div
                {...droppableProvided.droppableProps}
                ref={droppableProvided.innerRef}
              >
                <FieldArray
                  droppableProvided={droppableProvided}
                  droppableSnapshot={droppableSnapshot}
                  name={`${offerSlot}`}
                  slot={`${offerSlot}`}
                  offerWallSlotIndex={index}
                  component={this.renderSlots}
                />
                {droppableProvided.placeholder}
              </div>
            );
          }}
        </Droppable>
      </>
      //       </div>
      //     );
      //   }}
      // </Draggable>
    );
  }
}

function mapStateToProps(state) {
  const { selector } = state.offerState;
  const { dispatch } = state.criteria;
  return {
    dispatch,
    offerWall: state.offerWall,
    offerSlots: selector(state, `offerSlots`),
  };
}

export default connect(mapStateToProps, { updateSelectedWallOffers })(
  OfferSlots
);
