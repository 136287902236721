import { useMutation, useQueryClient } from '@tanstack/react-query';
import api from '../services';

export const useUpdateStatus = (endpoint) => {
  const queryClient = useQueryClient();

  return useMutation(
    async (saveData) => {
      const result = await api.put(endpoint, saveData);

      if (result.status !== 200) {
        throw new Error(`Failed to update status: ${result.status}`);
      }
      return result;
    },
    {
      onError: (error) => {
        console.error('Error updating status:', error);
      },
      onSuccess: () => {
        queryClient.invalidateQueries();
      },
    }
  );
};
