import { UPDATE_SELECTOR, UPDATE_TAG_FORM } from '../actions/types';
import { formValueSelector } from 'redux-form';

const initialState = {
  selector: formValueSelector('tagForm')
};
export default function(state = initialState, action) {
  switch (action.type) {
    case UPDATE_SELECTOR:
      return { ...state, selector: formValueSelector(action.payload) };
    case UPDATE_TAG_FORM:
      return { ...state, tagForm: action.payload };
    default:
      return state;
  }
}
