import React, { useMemo, useState } from 'react';
import { Alert, Button, Card, CardBody, CardHeader, Col, Label, Row } from 'reactstrap';
import { Field, Form } from 'react-final-form';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import SaveEntityModal from '../../../components/SaveEntityModal/SaveEntityModal';
import { parseAdvPPFormValues } from './advertiserPrePingHelper';
import validate from './validate';
import ZuulLoader from '../../../components/ZuulLoader/ZuulLoader';
import {
  renderActiveToggleSwitch,
  renderField,
  renderToggleSwitch,
  textarea,
} from '../../Offers/OfferTitle/renderConsts';
import { createAdvertiserPrePing, updateAdvertiserPrePing } from '../actions';
import { useHandlers } from '../../Handlers/queries';
import { useAdvertiserPrePing, useAdvertisers } from './queries';
import DropdownList from 'react-widgets/DropdownList';
import { SelectField } from '../../../components/FinalForm';
import renderActiveStatusBadge from '../../../components/ActiveStatusBadge/ActiveStatusBadge';

const AdvertiserDetails = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { advPrePingId } = useParams();
  const [showAdvancedFields, setShowAdvancedFields] = useState(false);
  const { data: advertisers, isLoading: advertisersLoading } = useAdvertisers();
  const { data: handlers, isLoading: handlersLoading } = useHandlers();

  const advertiserPrePingState = useSelector((state) => state.advertiserPrePing);
  const { saveError, saveErrorMessage } = advertiserPrePingState;

  const {
    data: existingAdvertiserPrePing,
    isLoading: advertiserPrePingLoading,
    error: advertiserPrePingError,
  } = useAdvertiserPrePing(advPrePingId);

  const initialValues = useMemo(() => {
    if (existingAdvertiserPrePing) {
      return parseAdvPPFormValues(existingAdvertiserPrePing);
    }
    return {
      active: false,
    };
  }, [existingAdvertiserPrePing]);

  const onSubmit = async (values) => {
    const parsedAdvertiserPrePing = parseAdvPPFormValues(values);
    const now = new Date();

    try {
      if (parsedAdvertiserPrePing.id) {
        dispatch(updateAdvertiserPrePing({ ...parsedAdvertiserPrePing, updatedAt: now }));
      } else {
        dispatch(
          createAdvertiserPrePing({
            ...parsedAdvertiserPrePing,
            createdAt: now,
            updatedAt: now,
          })
        );
      }

      if (!saveError) history.push('/advertiser-pre-pings');
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  const isLoading = advertisersLoading || handlersLoading || (advPrePingId && advertiserPrePingLoading);

  if (isLoading) {
    return <ZuulLoader text='Loading Advertiser Pre-Ping...' />;
  }

  if (advertiserPrePingError) {
    return <Alert color='danger'>Error loading Advertiser Pre-Ping data.</Alert>;
  }

  return (
    <Form
      key={advPrePingId || 'new'}
      onSubmit={onSubmit}
      validate={validate}
      initialValues={initialValues}
      render={({ handleSubmit, form, submitting, invalid, submitFailed, values }) => {
        const advertiserPrePingName = values.name;

        const getErrors = () => ({
          invalid: submitFailed && invalid,
          saveError,
          _error: saveError
            ? saveErrorMessage
            : submitFailed
            ? 'One or more of the fields you entered are invalid.'
            : null,
        });

        const errors = getErrors();
        const shouldErrorRender = Object.values(errors).some(Boolean);

        return (
          <form onSubmit={handleSubmit}>
            <Card>
              <CardHeader>
                <Row>
                  <Col>
                    <span style={{ display: 'flex', alignItems: 'baseline' }}>
                      {renderActiveStatusBadge(existingAdvertiserPrePing?.active)}&nbsp;&nbsp;
                      <h3>{existingAdvertiserPrePing?.name}</h3>&nbsp;&nbsp;
                      <h6>
                        <em> ID: </em>
                        {existingAdvertiserPrePing?.id}
                      </h6>
                    </span>
                  </Col>
                  <Col className='d-flex justify-content-end align-items-center'>
                    <Field name='active' component={renderActiveToggleSwitch} />
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <div>
                  <Row>
                    <Col>
                      <Field name='name' type='text' component={renderField} label='Name' />
                    </Col>
                    <Col lg={4}>
                      <Label>Advertiser</Label>
                      <Field name='advertiser'>
                        {({ input, meta }) => (
                          <>
                            <DropdownList
                              {...input}
                              data={advertisers}
                              value={input ? input.value.name : ''}
                              dataKey={'id'}
                              textField={'name'}
                              minLength={3}
                              filter={'contains'}
                            />
                            {meta.error && meta.touched && <Alert color='danger'>{meta.error}</Alert>}
                          </>
                        )}
                      </Field>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <label>Pre Ping Method</label>
                      <SelectField
                        name='method'
                        options={[
                          { value: '', option: 'Select method...' },
                          { value: 'GET', option: 'GET' },
                          { value: 'POST', option: 'POST' },
                          { value: 'JSON', option: 'JSON' },
                          { value: 'XML', option: 'XML' },
                        ]}
                      />
                    </Col>
                    <Col lg={4}>
                      <Label>Handler</Label>
                      <Field name='handler'>
                        {({ input, meta }) => (
                          <>
                            <DropdownList
                              {...input}
                              data={handlers}
                              value={input ? input.value.name : ''}
                              dataKey={'id'}
                              textField={'name'}
                              minLength={3}
                              filter={'contains'}
                            />
                            {meta.error && meta.touched && <Alert color='danger'>{meta.error}</Alert>}
                          </>
                        )}
                      </Field>
                    </Col>
                    <Col>
                      <Row>
                        <Label>Hashed</Label>
                        <Field name='emailAddressHashed' component={renderToggleSwitch} />
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={12}>
                      <Field name='url' type='text' component={renderField} label='URL' />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={12}>
                      <Label>Body</Label>
                      <Field name='body' component={textarea} type='text' />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={12}>
                      <Button className='float-right' onClick={() => setShowAdvancedFields((prev) => !prev)}>
                        <em>advanced settings</em>
                      </Button>
                    </Col>
                  </Row>
                  {showAdvancedFields && (
                    <>
                      <Row>
                        <Col lg={4}>
                          <Field name='header1' component={renderField} type='text' label='Header 1' />
                        </Col>
                        <Col lg={4}>
                          <Field name='header2' component={renderField} type='text' label='Header 2' />
                        </Col>
                        <Col lg={4}>
                          <Field name='header3' component={renderField} type='text' label='Header 3' />
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={12}>
                          <Field
                            name='customHttpStatus'
                            component={renderField}
                            type='text'
                            label='Other HTTP Status'
                          />
                        </Col>
                      </Row>
                    </>
                  )}
                </div>
              </CardBody>
            </Card>
            <Row>
              <Col lg={6}>{shouldErrorRender && <Alert color='danger'>{errors._error}</Alert>}</Col>
              <Col>
                <SaveEntityModal
                  isEditMode={Boolean(advPrePingId)}
                  entityName={advertiserPrePingName}
                  entityType='Advertiser Pre-Ping'
                  getErrors={getErrors}
                  onClickSubmit={handleSubmit}
                  submitting={submitting}
                />
              </Col>
            </Row>
          </form>
        );
      }}
    />
  );
};

export default AdvertiserDetails;
