import React, { Component } from 'react';
import { Card, CardHeader, CardBody, Row, Col } from 'reactstrap';
import CreateUser from '../Permissions/Modals/CreateUser';
import './Permissions.css';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import '../../../node_modules/react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import UpdateStatus from './Modals/UpdateStatus';
import api from '../../services';
import { formatDate } from '../../utils/conversionFunctions';

class Permissions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      page: 1,
      sizePerPage: 10,
      render: false,
      isAdmin: false,
      selectedUser: {
        name: '',
        email: '',
        role: '',
        id: null,
      },
      isRowClicked: false,
      userId: null,
      roleId: null,
      roleName: null,
    };

    this.idleTimer = null;
    this.onIdle = this._onIdle.bind(this);
    this.switchHandler = this.switchHandler.bind(this);
    this.refreshUsers = this.refreshUsers.bind(this);
    this.updateUser = this.updateUser.bind(this);
  }

  renderSizePerPageDropDown = (props) => {
    return (
      <div className='btn-group'>
        {[10, 25, 30, 50].map((n, idx) => {
          const isActive = n === props.currSizePerPage ? 'active' : null;
          return (
            <button
              key={idx}
              type='button'
              className={`btn btn-info ${isActive}`}
              onClick={() => props.changeSizePerPage(n)}
            >
              {n}
            </button>
          );
        })}
      </div>
    );
  };

  componentDidMount() {
    const thisUserData = JSON.parse(localStorage.getItem('userData'));
    const roleAdmin = thisUserData.zuulUserRole.name === 'Admin';
    this.setState({
      isAdmin: roleAdmin,
    });
    this.refreshUsers();
  }

  updateUser(user) {
    console.log(user);
    this.refreshUsers();
  }

  refreshUsers() {
    api
      .get('zuulusers')
      .then((response) => {
        const newUser = response.data.map((u) => {
          return {
            id: u.id,
            name: u.realname,
            lastLogin: formatDate(u.lastLogin),
            roleName: u.zuulUserRole.name,
            roleId: u.zuulUserRole.id,
            online: u.online,
          };
        });
        const newState = Object.assign({}, this.state, {
          isRowClicked: false,
          users: newUser,
        });
        this.setState(newState);
      })
      .catch((error) => console.log(error));
  }

  switchHandler(row, activeStatus) {
    if (row.id) {
      let saveData;
      saveData = {
        id: row.id,
        online: activeStatus,
      };
      api.put(`zuulusers/status/${row.id}`, saveData).then((result) => {
        console.log(JSON.stringify(result.data));
      });
    }
  }

  renderAppSwitch = (row) => {
    return (
      <UpdateStatus
        isActive={row.online}
        content={row}
        onClickSubmit={(activeStatus) => this.switchHandler(row, activeStatus)}
        isEditMode={true}
      />
    );
  };

  // actionFormatter(cell, row) {
  //   return this.renderAppSwitch(row);
  // }

  // renderEditButton = row => {
  //   return (
  //     <CreateUser
  //       userId={row.id}
  //       roleId={row.roleId}
  //       roleName={row.roleName}
  //       refreshCallBack={this.updateUser}
  //     />
  //   );
  // };

  editUserFormatter(row) {
    // return this.renderEditButton(row);
    return <CreateUser userId={row.id} roleId={row.roleId} roleName={row.roleName} refreshCallBack={this.updateUser} />;
  }

  // switchFormatter(cell, row){
  //   const disableSwitch = !this.state.isAdmin;
  //   if (row.online === true) {
  //     return <AppSwitch disabled = {disableSwitch} onChange = {this.switchHandler.bind(this,row)} className={'mx-1'} variant={'3d'} color={'success'} defaultChecked label dataOn={'\u2713'} dataOff={'\u2715'}/>
  //   }
  //   else {
  //     return <AppSwitch disabled = {disableSwitch} onChange = {this.switchHandler.bind(this,row)}  className={'mx-1'} variant={'3d'} color={'success'}  label dataOn={'\u2713'} dataOff={'\u2715'}/>
  //   }
  // }

  renderUsersList = () => {
    const { SearchBar } = Search;
    const isAdmin = this.state.isAdmin;
    const columns = [
      {
        dataField: 'name',
        text: 'Name',
        headerStyle: {
          backgroundColor: '#2f353a',
        },
        events: {
          onClick: (e, column, columnIndex, row) => {
            this.setState({
              isRowClicked: true,
              userId: row.id,
              roleId: row.roleId,
              roleName: row.roleName,
            });
          },
        },
      },
      {
        dataField: 'lastLogin',
        text: 'Last Login',
        sort: true,
        headerStyle: {
          backgroundColor: '#2f353a',
        },
        events: {
          onClick: (e, column, columnIndex, row) => {
            this.setState({
              isRowClicked: true,
              userId: row.id,
              roleId: row.roleId,
              roleName: row.roleName,
            });
          },
        },
      },
      {
        dataField: 'roleName',
        text: 'Role',
        headerStyle: {
          backgroundColor: '#2f353a',
        },
        events: {
          onClick: (e, column, columnIndex, row) => {
            this.setState({
              isRowClicked: true,
              userId: row.id,
              roleId: row.roleId,
              roleName: row.roleName,
            });
          },
        },
      },
      {
        dataField: 'online',
        text: 'Status',
        isDummyField: true,
        formatter: (cell, row) => (
          <div>
            <Row>
              <Col lg={12}>{this.renderAppSwitch(row)}</Col>
            </Row>
          </div>
        ),
        headerStyle: {
          backgroundColor: '#2f353a',
          width: '5%',
        },
      },
      // {
      //   dataField: 'action',
      //   text: 'Action',
      //   isDummyField: true,
      //   formatter: (cell, row) => (
      //     <div>
      //       {isAdmin === true && (
      //         <Row>
      //           <Col lg={12}>{this.editUserFormatter(row)}</Col>
      //         </Row>
      //       )}
      //     </div>
      //   ),
      //   headerStyle: {
      //     backgroundColor: '#2f353a',
      //     width: '9%'
      //   }
      // }
    ];
    const rowEvents = {
      onMouseEnter: (e, row, rowIndex) => {
        this.setState({ activeRow: rowIndex, isRowClicked: false });
      },
      // onClick: async (e, row, rowIndex) => {
      //   console.log(e);
      //   console.log(row);
      //   this.setState({
      //     isRowClicked: true,
      //     userId: row.id,
      //     roleId: row.roleId,
      //     roleName: row.roleName
      //   });
      // }
    };
    const rowStyle = (row, rowIndex) => {
      const style = {};
      if (rowIndex === this.state.activeRow) {
        style.backgroundColor = '#2f353a';
        style.cursor = 'pointer';
      }
      return style;
    };
    const defaultSorted = [
      {
        dataField: 'lastLogin',
        order: 'desc',
      },
    ];
    return (
      <ToolkitProvider keyField='name' data={this.state.users} columns={columns} dataSort search bootstrap4={true}>
        {(props) => (
          <div>
            <Row>
              <Col lg={6}>
                <span className='display-4'>Staff Permissions</span>
              </Col>
              <Col lg={6}>
                {isAdmin === true && (
                  <CreateUser roleName={'Admin'} isUserCreate={true} roleId={1} refreshCallBack={this.updateUser} />
                )}{' '}
              </Col>
            </Row>
            <br />
            <Row>
              <Col lg={9}>
                <SearchBar {...props.searchProps} tableId='Permissions' />
              </Col>
            </Row>
            <br />
            <BootstrapTable
              {...props.baseProps}
              pagination={paginationFactory()}
              defaultSorted={defaultSorted}
              rowEvents={rowEvents}
              rowStyle={rowStyle}
            />
          </div>
        )}
      </ToolkitProvider>
    );
  };
  render() {
    return (
      <div className='animated'>
        <Card>
          <CardHeader>
            <i className='icon-menu'></i>Staff Permissions{' '}
          </CardHeader>
          <CardBody>
            <Row>
              <Col lg={12}>{this.renderUsersList()}</Col>
            </Row>
            {/* {this.state.isRowClicked && ( */}
            <CreateUser
              isRowClicked={this.state.isRowClicked}
              userId={this.state.userId}
              roleId={this.state.roleId}
              roleName={this.state.roleName}
              refreshCallBack={this.updateUser}
            />
            {/* )} */}
          </CardBody>
        </Card>
      </div>
    );
  }

  _onIdle() {}
}

export default Permissions;
