import React, { Component } from 'react';
import { Button, Card, Col, Label, Row, CardHeader, CardBody } from 'reactstrap';
import { Field, FieldArray } from 'redux-form';
import { renderCriteriaOptions, renderMultiSelect } from '../../views/Offers/OfferTitle/renderConsts';
import { connect } from 'react-redux';

class DomainAffiliate extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  renderDomainAffiliates = ({ fields, name, isLoading }) => {
    return (
      <Row>
        <Col lg={12}>
          {fields.map((fieldName, index) => (
            <>
              <div key={index}>
                <Card>
                  <CardHeader>
                    <Button
                      color='danger'
                      className=''
                      type='button'
                      title='Remove Domain-specific Affiliates'
                      onClick={() => fields.remove(index)}
                    >
                      <i className='fa fa-close' />
                    </Button>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col>
                        <Label>Domains</Label>
                        <Field
                          name={`${fieldName}.domains`}
                          id={`${fieldName}.domains`}
                          component={renderMultiSelect}
                          type='select'
                          textField={(item) => `${item.id}: ${item.name}`}
                          dataKey='name'
                          data={this.props.domains}
                          busy={isLoading}
                        />
                      </Col>
                    </Row>
                    {fields.get(index).domains?.length !== 0 && (
                      <Row>
                        <Col lg={3}>
                          <Label>Include/Exclude</Label>
                          <Field
                            name={`${fieldName}.includeExcludeAction`}
                            type='select'
                            component={renderCriteriaOptions}
                          />
                        </Col>
                        <Col>
                          <Label>Affiliate</Label>
                          <Field
                            id={`${fieldName}.affiliates`}
                            name={`${fieldName}.affiliates`}
                            component={renderMultiSelect}
                            type='select'
                            textField={(item) => `${item.id}: ${item.name}`}
                            dataKey='name'
                            data={this.props.affiliates}
                            busy={this.props.isLoading}
                          />
                        </Col>
                      </Row>
                    )}
                  </CardBody>
                </Card>
              </div>
            </>
          ))}
        </Col>
        <Col lg={{ size: 2 }}>
          <Button
            color='success'
            onClick={() => fields.push({ domains: [], affiliates: [], includeExcludeAction: null })}
            className='domain-affiliate-add'
            style={{ float: 'left', height: '3em', width: '3em' }}
          >
            <i className='fa fa-plus' />
          </Button>
        </Col>
      </Row>
    );
  };

  componentDidMount() {}

  render() {
    return (
      <Row>
        <Col lg={12}>
          <FieldArray
            name='domainAffiliateConfiguration.domainAffiliateEntries'
            component={this.renderDomainAffiliates}
            isLoading={this.props.isLoading}
          />
        </Col>
      </Row>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps, {})(DomainAffiliate);
