import React, { useEffect } from 'react';
import { Field, useFormState } from 'react-final-form';
import { useState } from 'react';
import Combobox from 'react-widgets/Combobox';
import { Alert, Button, Col, Label, Row } from 'reactstrap';
import renderDropdownList from '../../components/PrePing/renderDropdownList';
import api from '../../services';

const renderOfferAction = ({ input, id, offerActions, disabled, onChange, meta: { touched, error }, busy }) => {
  return (
    <>
      <Combobox
        textField='title'
        data={offerActions}
        {...input}
        id={id}
        onChange={onChange || input.onChange}
        onBlur={(_) => input.onBlur()}
        disabled={disabled}
        minLength={3}
        filter='contains'
        busy={busy}
        busySpinner={<span className='fa fa-spinner fa-spin' style={{ color: 'white', marginBottom: '8px' }} />}
      />
      {error && <Alert color='danger'>{error}</Alert>}
    </>
  );
};

const handleOfferActionTypeChange = (selector, setValue, value) => {
  setValue(`${selector}.offerActionType`, value);
  setValue(`${selector}.title`, { title: null });
};

const RenderOfferActionsList = ({
  fields,
  offerActions,
  offerActionSubtypes,
  marketingPartnerId,
  push,
  pop,
  setValue,
  name,
  meta: { touched, error },
}) => {
  // countOfMarketingPartnersByOfferAction is a hashmap where the key is the offerAction.id and the value is the count of marketing partners for that offer action
  const [countOfMarketingPartnersByOfferAction, setCountOfMarketingPartnersByOfferAction] = useState({});
  const [indexToRemove, setIndexToRemove] = useState([]);

  async function getCountOfMarketingPartnersByOfferAction(offerAction) {
    return await api.get(`marketing_partner/offer_actions/${offerAction.id}/${marketingPartnerId}`);
  }

  const validateOfferAction = async (offerAction, value, meta) => {
    // If the user hasn't touched this field AND they didn't try to delete a TCPA compliant post w/ no other marketing partners with the delete button
    if (!meta.visited && !countOfMarketingPartnersByOfferAction.hasOwnProperty([offerAction?.id])) return undefined;
    if ((offerAction != null && !value) || typeof value === 'string') {
      // User deleted the offer action from the combobox or edited the title
      
      if (
        offerAction?.offerActionPost?.isTcpaCompliant &&
        countOfMarketingPartnersByOfferAction[offerAction.id] == null &&
        // If the value is a string, that means the user started to delete the offer action title via the backspace or delete button and the fetch happened in the onChange handler
        typeof value != 'string'
      ) {
          if (countOfMarketingPartnersByOfferAction[offerAction.id] === 0) {
            // There are no other marketing partners for this offer action POST and it is a TCPA Compliant Post. Therefore, we cannot allow the user to delete it.
            return "Can't delete action. It is TCPA Compliant and does not have other marketing partners associated with it.";
          }
          return undefined;
      } else if (
        offerAction?.offerActionPost?.isTcpaCompliant &&
        countOfMarketingPartnersByOfferAction[offerAction.id] === 0
      ) {
        // We've already fetched the count but the user is deleting the offer action title via the backspace or delete button
        return "Can't delete action. It is TCPA Compliant and does not have other marketing partners associated with it.";
      }
      return undefined;
    }
  };

  function updateCountOfMarketingPartnersByOfferAction(offerAction) {
    const newCountOfMarketingPartnersByOfferAction = { ...countOfMarketingPartnersByOfferAction };
    delete newCountOfMarketingPartnersByOfferAction[offerAction.id];
    setCountOfMarketingPartnersByOfferAction(newCountOfMarketingPartnersByOfferAction);
  }

  const handleGetCountMarketingPartnersByOfferAction = async (offerAction, index) => {
    try {
      const result = await getCountOfMarketingPartnersByOfferAction(offerAction);
        // If result.data is 0, there are no other marketing partners for this offer action POST and it is a TCPA Compliant Post. Therefore, we cannot allow the user to delete it.
        setCountOfMarketingPartnersByOfferAction({
          ...countOfMarketingPartnersByOfferAction,
          [offerAction.id]: result.data,
        });
      if(result.data > 0 && index !== null) {
        setIndexToRemove(indexToRemove.includes(index) ? indexToRemove : [...indexToRemove, index]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleOfferActionRemoval = async (index, offerAction) => {
    if (offerAction != null) {
      // User clicked delete button for this offer action again
      if (countOfMarketingPartnersByOfferAction[offerAction.id] === 0) {
        updateCountOfMarketingPartnersByOfferAction(offerAction);
        return;
      }
      // User clicked delete button for this offer action
      else if (
        offerAction?.offerActionPost?.isTcpaCompliant &&
        countOfMarketingPartnersByOfferAction[offerAction.id] == null
      ) {
        handleGetCountMarketingPartnersByOfferAction(offerAction, index);
        return;
      }
      setIndexToRemove(indexToRemove.includes(index) ? indexToRemove : [...indexToRemove, index]);
      return;
    }
  };

  const handleOfferActionChange = async (offerAction, selector, setValue, value) => {
    try {
      if (offerAction.title === value && countOfMarketingPartnersByOfferAction[offerAction.id] === 0) {
        updateCountOfMarketingPartnersByOfferAction(offerAction);
        return;
      } else if (
        offerAction?.offerActionPost?.isTcpaCompliant &&
        countOfMarketingPartnersByOfferAction[offerAction.id] == null
      ) {
        handleGetCountMarketingPartnersByOfferAction(offerAction, null);
        return;
      }
    } catch (error) {
      console.error(error);
      return;
    }
    setValue(`${selector}.title`, value);
  };

  useEffect(() => {
    if (indexToRemove.length > 0) {
      indexToRemove.forEach((index) => {
        fields.remove(index);
      });
      setIndexToRemove([]);
    }
  }, [fields, indexToRemove]);

  const formState = useFormState();
  return (
    <>
      <Row>
        <Col>
          {error && touched && typeof error === 'string' && (
            <Alert color='danger' style={{ margin: 0, padding: '6px' }}>
              {error}
            </Alert>
          )}
        </Col>
        <Col style={{ display: 'flex', justifyContent: 'end' }}>
          <Button
            color='success'
            id='addOfferAction'
            onClick={() => push('offerActions', { offerActionType: null, title: '' })}
          >
            Add Offer Action
          </Button>
        </Col>
        <br />
      </Row>
      {fields.map((offerAction, index) => {
        const initializedOfferActions = formState.values[fields.name];
        const selectedOfferActionType = initializedOfferActions
          ? initializedOfferActions[index]?.offerActionType
          : null;
        const selectedOfferAction = initializedOfferActions ? initializedOfferActions[index] : null;
        const filteredOfferActions =
          countOfMarketingPartnersByOfferAction?.[selectedOfferAction.id] === 0
            ? offerActionSubtypes[selectedOfferActionType].filter((oa) => oa.id === selectedOfferAction.id)
            : offerActionSubtypes[selectedOfferActionType];
        return (
          <Row key={offerAction}>
            <Col>
              <Row className='rowSpacing'>
                <Col lg={4}>
                  <Label>Offer Action Type</Label>
                  <Field
                    name={`${offerAction}.offerActionType`}
                    component={renderDropdownList}
                    onChange={(value) => handleOfferActionTypeChange(offerAction, setValue, value)}
                    className='offerActionType'
                    type='select'
                    label='Action Type'
                    allData={['POST', 'POST_WATERFALL']}
                  />
                </Col>
                <Col lg={6}>
                  <Label>Offer Action</Label>
                  <Field
                    name={`${offerAction}.title`}
                    component={renderOfferAction}
                    onChange={(value) => handleOfferActionChange(selectedOfferAction, offerAction, setValue, value)}
                    validate={(value, _, meta) => validateOfferAction(selectedOfferAction, value, meta)}
                    textField='title'
                    type='select'
                    label='OfferAction'
                    offerActions={filteredOfferActions}
                    busy={offerActions.length === 0}
                    // This key causes component to re-render when countOfMarketingPartnersByOfferAction[offerAction.id] exists because the user clicked the delete button.
                    key={countOfMarketingPartnersByOfferAction.hasOwnProperty(selectedOfferAction?.id) ? 1 : 0}
                  />
                </Col>
                <Col lg={2}>
                  {index !== 0 && (
                    <Button
                      className='delete-offer-action-button'
                      onClick={() => handleOfferActionRemoval(index, selectedOfferAction)}
                      color='danger'
                    >
                      <i className='fa fa-minus' />
                    </Button>
                  )}
                </Col>
              </Row>
              <br />
            </Col>
          </Row>
        );
      })}
    </>
  );
};

export default RenderOfferActionsList;
