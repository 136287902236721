import React from 'react';
import { Toaster } from 'react-hot-toast';

export const ZuulToaster = ({customContainerStyle, duration, style, position}) => (
  <Toaster
    position={position || 'bottom-right'}
    toastOptions={{
      style: {
        background: '#363636',
        color: '#fff',
        ...style,
      },
      duration: duration || 4000,
    }}
    containerStyle={{ ...customContainerStyle }}
  />
);
