import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import api from '../../services';

export const useFeatureStrategies = (enabled = true, selectFn = (data) => data) =>
  useQuery({
    queryKey: ['featureStrategies'],
    queryFn: () => api.get('features/strategies').then((res) => res.data),
    enabled,
    select: selectFn,
  });

export const useFeatureGroups = (enabled = true, selectFn = (data) => data) =>
  useQuery({
    queryKey: ['featureGroups'],
    queryFn: () => api.get('features/groups').then((res) => ["All", ...res.data]),
    enabled,
    select: selectFn,
  });

export const useFeatures = (selectFn = (data) => data) =>
  useQuery({
    queryKey: ['features'],
    queryFn: () => api.get(`features`).then((res) => res.data),
    select: selectFn,
    enabled: true,
  });

export const useUpdateFeature = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data) => api.put(`features`, data, { headers: { 'X-Zuul-User': btoa(localStorage.getItem('userData'))}}),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['features'] });
    },
  });
};
