import React from 'react';
import { Col, Input, Row } from 'reactstrap';
import { MultiselectSummary } from './SummaryPaneComponents';
import { useDailyRestrictionSelectors } from '../hooks';
import CurrentCapAmountByDomains from '../../../views/Offers/OfferActions/CurrentCapAmountByDomains';

const DailyRestrictionSummary = () => {
  const dailyRestrictionSelectors = useDailyRestrictionSelectors();

  return (
    <>
      {dailyRestrictionSelectors.isAnySelectorNotNullOrEmpty && (
        <>
          <h4>Daily Restriction Summary</h4>
          <Row>
            {dailyRestrictionSelectors.criteriaDailyLimit && (
              <Col lg={6}>
                <label>Daily Limit</label>
                <Input value={dailyRestrictionSelectors.criteriaDailyLimit} readOnly disabled />
              </Col>
            )}
            {dailyRestrictionSelectors.criteriaDailyLimitAnswers && (
              <Col lg={6}>
                <label>Answers</label>
                <MultiselectSummary value={dailyRestrictionSelectors.criteriaDailyLimitAnswers} />
              </Col>
            )}
          </Row>
          <Row>
            {dailyRestrictionSelectors.capInterval ? (
              <Col>
                <label>Cap Interval</label>
                <Input value={dailyRestrictionSelectors.capInterval} disabled />
              </Col>
            ) : null}
            {dailyRestrictionSelectors.capAmount ? (
              <Col>
                <label>Cap Amount</label>
                <Input value={dailyRestrictionSelectors.capAmount} disabled />
              </Col>
            ) : null}
          </Row>
          {dailyRestrictionSelectors.capSegmentConfiguration && (
            <>
              <Row className='row-margin-top'>
                <Col>
                  <h5>Cap Segment</h5>
                </Col>
              </Row>
              {dailyRestrictionSelectors.capSegmentConfiguration.capSegments?.map((segment, index) => (
                <Row className='row-margin-top'>
                  <Col>
                    <label>Domains</label>
                    <MultiselectSummary value={segment.domains} />
                  </Col>
                  <Col>
                    <label>Segment Amount</label>
                    <Input value={segment?.segmentAmount} disabled />
                  </Col>
                  <Col>
                    <CurrentCapAmountByDomains
                      id={dailyRestrictionSelectors.offerCriteriaId}
                      domains={segment.domains}
                    />
                  </Col>
                </Row>
              ))}
            </>
          )}
          {dailyRestrictionSelectors.criteriaDayHourRestrictions && (
            <>
              <Row className='row-margin-top'>
                <Col>
                  <h5>Day Parting</h5>
                </Col>
              </Row>
              {dailyRestrictionSelectors.criteriaDayHourRestrictions?.map((restriction, index) => (
                <Row className='row-margin-top'>
                  <Col>
                    <label>Day</label>
                    <Input value={restriction?.restrictionDay} disabled />
                  </Col>
                  <Col>
                    <label>Restriction</label>
                    <Input value={restriction?.restriction} disabled />
                  </Col>
                  <Col>
                    <label>Available Start</label>
                    <Input value={restriction?.restrictionStart} disabled />
                  </Col>
                  <Col>
                    <label>Available End</label>
                    <Input value={restriction?.restrictionEnd} disabled />
                  </Col>
                </Row>
              ))}
            </>
          )}
        </>
      )}
    </>
  );
};
export default DailyRestrictionSummary;
