import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import 'react-widgets/styles.css';
import { Alert, Col, Label, Row } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import { fetchAllEntities } from '../../../actions';
import ActiveStatusModal from '../../../components/ActiveStatusModal/ActiveStatusModal';
import ZuulLoader from '../../../components/ZuulLoader/ZuulLoader';
import api from '../../../services';
import { filterDataBySearchTerm } from '../../../utils/tableFunctions';
import { fetchAllContainers } from '../../Metadata/Containers/actions';
import { fetchAllLayouts } from '../../Metadata/Layouts/actions';
import { getOfferTypes } from '../../Metadata/Offers/actions';
import { fetchAllSites } from '../../Metadata/Sites/actions';
import { renderComboBox, renderOrphanedOffers } from '../../Offers/OfferTitle/renderConsts';
import {
  createSDTS,
  createSequenceCheckoutApi,
  createSequenceViewApi,
  getSequence,
  getSequenceCheckout,
  getSequencePublished,
  getSequenceView,
  publishSequenceApi,
  publishSequenceByIdApi,
  updateOrphanedOffers,
  updateSDTS,
  updateSelectedElement,
  updateSequenceCheckoutApi,
  updateSequenceCheckoutStatus,
  updateSequenceCheckoutStatusIdBySequenceIdApi,
  updateSequenceViewApi,
} from '../actions';
import DnDFlow from './DnDFlow';
import ExitConfirmation from './ExitConfirmation';
import './SequenceDetails.css';
import LeftAside from './components/LeftAside';
import RightAside from './components/RightAside';
import SequenceNav from './components/SequenceNav';
import './dnd.css';
import {
  getSequenceFlowOffersAndEdges,
  handleFlowOffersInit,
  handleOrphanedOffers,
  hasMultipleStartNodes,
  sortOfferSequences,
} from './sequenceUtils';
import validate from './validate';
import { processCustomVariables } from '../../../components/CustomVariables/utils';

class SequenceDetails extends Component {
  layoutNames = [
    'defaultFormLayout',
    'defaultBannerLayout',
    'defaultQuestionLayout',
    'defaultOfferWallLayout',
    'defaultIframeLayout',
  ];
  containerNames = [
    'defaultFormContainer',
    'defaultBannerContainer',
    'defaultQuestionContainer',
    'defaultOfferWallContainer',
    'defaultIframeContainer',
  ];
  interval = null;
  idleTime = 0;

  constructor(props) {
    super(props);
    this.state = {
      allowedIdleTime: 31 * 60000,
      shouldWarnUser: false,
      shouldHideInactiveOffers: true,
      id: this.props.id,
      isActive: false,
      leftAside: true,
      rightAside: true,
      isEditMode: false,
      isCheckedOut: false,
      isManualCheckin: false,
      isDraft: false,
      isPublished: false,
      confirmedExit: false,
      confirmedExitModalVisible: false,
      offerTypeNames: ['Form', 'Banner', 'Question', 'Offer Wall', 'Iframe'],
      sequence: {},
      offerTypeNameSDTSMap: {
        Form: {},
        Banner: {},
        Question: {},
        'Offer Wall': {},
        Iframe: {},
      },
      offersSequences: [],
      publishedSequence: null,
      offers: [],
      searchTerm: '',
      saveAndContinue: false,
      returnUrlOfferSequence: null,
      errors: {},
    };
  }

  /* Instance variables, using them here instead of state because setting and updating state
	 will cause the component and all it's children to re-render
	*/

  toggleAside = (position) => {
    this.setState({ [position]: !this.state[position] });
  };

  onSubmitForm = async (values) => {
    this.setState({ confirmedExit: true });
    const sequenceFlow = this.props.reactFlowInstance.toObject();
    const isSequenceFlowValid = this.validateOffersSequences(sequenceFlow);
    console.log('WET-2724', values);
    if (values.customVariables) values.customVariables = processCustomVariables(values.customVariables);
    // if (!isSequenceFlowValid) return !isSequenceFlowValid;
    // if (this.state.isEditMode && !this.props.newEntityName && !this.state.isDraft) {
    //   return this.updateSequence(values, sequenceFlow);
    // } else {
    //   return this.createSequence(values, sequenceFlow);
    // }
    if (!isSequenceFlowValid) return !isSequenceFlowValid;
    if (this.state.isEditMode && !this.props.newEntityName && !this.state.isDraft) {
      return this.updateSequence(values, sequenceFlow);
    } else if (this.state.isEditMode && this.state.isDraft && !this.props.newEntityName) {
      return this.updateSequenceCheckout(values, sequenceFlow);
    } else {
      return this.createSequence(values, sequenceFlow);
    }
  };

  handleOrphanedOfferClick = (event) => {
    event.preventDefault();
    const clickedId = event.target.getAttribute('data-id');

    const clickedOffer = _.find(this.props.orphanedOffers, (offer) => offer.id === clickedId);

    // Uses clickedOffers' x,y negative position (as offset), adds + 250 so you don't get sent to left side of page
    this.props.reactFlowInstance.setTransform({
      x: -clickedOffer.position.x + 250,
      y: -clickedOffer.position.y,
      zoom: 1,
    });
  };

  validateOffersSequences(sequenceFlow) {
    let [edges, offers] = getSequenceFlowOffersAndEdges(sequenceFlow.elements);
    let updatedOrphanedOffers = [];
    if (offers.length > 1) {
      updatedOrphanedOffers = handleOrphanedOffers(sequenceFlow.elements);
      const hasMoreThanOneOSHead = hasMultipleStartNodes(sequenceFlow.elements);
      this.props.updateOrphanedOffers(updatedOrphanedOffers, hasMoreThanOneOSHead);
    }

    return updatedOrphanedOffers.length === 0;
  }

  handleSDTSSave = (formValues) => {
    const { offerTypeNames } = this.state;

    return offerTypeNames.map((offerTypeName, i) => {
      const container = formValues[this.containerNames[i]];
      const layout = formValues[this.layoutNames[i]];
      const sdts = {
        container: container ? { id: container.id, name: container.name } : null,
        layout: layout ? { id: layout.id, name: layout.name } : null,
        // this.props.offerTypes is a dictionary of objects with keys corresponding with the offerType names
        offerType: this.props.offerTypes[offerTypeName],
      };

      if (this.state.isEditMode && !this.props.newEntityName) {
        const currentSDTS = this.state.offerTypeNameSDTSMap[offerTypeName];
        if (currentSDTS.id) {
          sdts.id = currentSDTS.id;
        }
        sdts.sequence = { id: this.state.id };
      }
      return sdts;
    });
  };

  handleOffersSequencesSave = (formValues, sequenceFlow) => {
    let [sequenceFlowEdges, sequenceFlowOffers] = getSequenceFlowOffersAndEdges(sequenceFlow.elements);

    if (sequenceFlowOffers.length > 1) {
      sequenceFlowOffers = sortOfferSequences(sequenceFlowOffers);
    }

    const offersSequences = sequenceFlowOffers.map((flowElement, i) => {
      const offerId = flowElement.id.split('-')[0];
      const offer = _.find(this.state.offers, (offer) => offer.id === parseInt(offerId));
      const offerTypeName = flowElement.offerType
        ? flowElement.offerType.name
        : offer && offer.offerType
        ? offer.offerType.name
        : '';
      const indexOfOfferTypeName = this.state.offerTypeNames.indexOf(offerTypeName);

      const offersSequenceContainer = formValues[`${flowElement.id}`]
        ? formValues[`${flowElement.id}`].container
        : null;
      const offersSequenceLayout = formValues[`${flowElement.id}`] ? formValues[`${flowElement.id}`].layout : null;
      const defaultContainer = formValues[this.containerNames[indexOfOfferTypeName]] || null;
      const defaultLayout = formValues[this.layoutNames[indexOfOfferTypeName]] || null;
      const container = offersSequenceContainer
        ? { id: offersSequenceContainer.id, name: offersSequenceContainer.name }
        : defaultContainer
        ? { id: defaultContainer.id, name: defaultContainer.name }
        : null;
      const layout = offersSequenceLayout
        ? { id: offersSequenceLayout.id, name: offersSequenceLayout.name }
        : defaultLayout
        ? { id: defaultLayout.id, name: defaultLayout.name }
        : null;
      // const position = flowElement.position;

      if (flowElement.offersSequence && !this.props.newEntityName) {
        // This flowElement already has an offersSequence associated with it, meaning it exists in the DB and wasn't just created by the user
        const offersSequence = flowElement.offersSequence;

        const OSOffer = offersSequence.offer;
        const sequence = offersSequence.sequence;
        const offersSequenceParent = offersSequence.offersSequenceParent;

        offersSequence.orderBy = i + 1;
        offersSequence.container = container;
        offersSequence.layout = layout;
        offersSequence.isContainerOverwritten = !!offersSequenceContainer;
        offersSequence.isLayoutOverwritten = !!offersSequenceLayout;
        // adding Object if it is a Draft. Will remove once we have a better solution for Draft bugs.
        offersSequence.offer = OSOffer
          ? {
              id: OSOffer.id,
              name: OSOffer.name,
              offerType: OSOffer.offerType,
              isActive: OSOffer.isActive,
              advertiser: offersSequence.offer.advertiser,
            }
          : null;
        offersSequence.sequence = sequence
          ? { id: sequence.id }
          : this.state.sequence
          ? { id: this.state.sequence.id }
          : null;
        offersSequence.offersSequenceParent = offersSequenceParent ? { id: offersSequenceParent.id } : null;
        // offersSequence.position = position;
        return flowElement.offersSequence;
      }

      const newOffersSequence = {
        container,
        layout,
        isContainerOverwritten: !!offersSequenceContainer,
        isLayoutOverwritten: !!offersSequenceLayout,
        offer: offer
          ? {
              id: offer.id,
              name: offer.name,
              offerType: offer.offerType,
              isActive: offer.isActive,
              advertiser: offer.advertiser,
            }
          : null,
        orderBy: i + 1,
        status: 1,
        // position
      };
      return newOffersSequence;
    });

    if (this.state.isEditMode && !this.props.newEntityName && this.state.returnUrlOfferSequence) {
      // If this is an existing sequence, we already have an existing returnURLOffer saved in state, we're going to attach it back into the sequence (we removed it in the initialize step so it didn't display in the react flow)
      offersSequences.push(this.state.returnUrlOfferSequence);
    }

    return offersSequences;
  };

  handleSequenceSave = (formValues) => {
    const saveAsSite = formValues.saveAsSite
      ? { id: formValues.saveAsSite.id, domain: formValues.saveAsSite.domain }
      : null;
    return {
      createdAt: formValues.createdAt || new Date(),
      endDate: null,
      flex: parseInt(formValues.flex),
      isActive: this.props.newEntityName ? false : this.state.isActive,
      isDynamic: false,
      name: this.props.newEntityName ? this.props.newEntityName : formValues.sequenceName,
      returnRedirectDomain: formValues.returnRedirectDomain || null,
      returnVisits: formValues.targetReturningUsers ? parseInt(formValues.returnVisits) : 0,
      sequenceDescription: formValues.sequenceDescription || null,
      site:
        this.props.newEntityName && saveAsSite
          ? saveAsSite
          : { id: formValues.site.id, domain: formValues.site.domain },
      startDate: null,
      status: 1,
      targetReturningUsers: formValues.targetReturningUsers || false,
      customVariables: formValues.customVariables
        ? JSON.stringify(processCustomVariables(formValues.customVariables))
        : null,
    };
  };

  handleSequencesCheckoutStatus = (checkInStatusId) => {
    return {
      id: checkInStatusId,
      statusName: checkInStatusId === 1 ? 'checked_in' : 'checked_out',
    };
  };

  attachPositionAndOSParentToSavedOS = (savedOffersSequences, unsavedOS) => {
    return savedOffersSequences.map((offersSequence) => {
      const correspondingUnsavedOS = _.find(unsavedOS, (os) => os.orderBy === offersSequence.orderBy);
      if (offersSequence.id) {
        correspondingUnsavedOS.id = offersSequence.id;
        correspondingUnsavedOS.offersSequenceParent = offersSequence.offersSequenceParent
          ? { id: offersSequence.offersSequenceParent.id }
          : null;
      }
      return correspondingUnsavedOS;
    });
  };

  updateSequence = async (formValues, sequenceFlow) => {
    const sequence = this.handleSequenceSave(formValues);
    sequence.id = this.state.id;
    const sequenceDefaultTemplateSettings = this.handleSDTSSave(formValues);
    const offersSequences = this.handleOffersSequencesSave(formValues, sequenceFlow);
    const sequenceView = {
      sequence,
      sequenceDefaultTemplateSettings,
      offersSequences,
    };

    console.log('Updating Sequence to Publish and Draft');
    const sequencesCheckoutStatus = this.handleSequencesCheckoutStatus(1);
    const zuulUser = JSON.parse(localStorage.getItem('userData'));
    const sequenceDraftResponse = await this.props.updateSequenceViewApi(sequenceView);
    if (this.props.saveError) {
      return false;
    }

    sequenceDraftResponse.sequence = sequence;
    sequenceDraftResponse.offersSequences = this.attachPositionAndOSParentToSavedOS(
      sequenceDraftResponse.offersSequences,
      offersSequences
    );
    sequenceDraftResponse.sequenceDefaultTemplateSettings = sequenceDefaultTemplateSettings;
    console.log(sequenceDraftResponse);
    const sequenceDraft = JSON.stringify(sequenceDraftResponse);
    const sequenceCheckout = {
      createdAt: formValues.createdAt,
      updatedAt: new Date(),
      sequence,
      sequenceDraft,
      sequencesCheckoutStatus,
      zuulUser,
    };
    await this.props.publishSequenceByIdApi(sequence.id, sequenceCheckout);
    if (this.props.saveError) {
      return false;
    }
    sequenceCheckout.isPublished = true;
    await this.props.updateSequenceCheckoutApi(sequenceCheckout);

    if (this.props.saveError || this.props.saveWarning) return false;

    this.props.history.push('/campaigns/sequences');
  };

  updateSequenceCheckout = async (formValues, sequenceFlow) => {
    const sequence = this.handleSequenceSave(formValues);
    sequence.id = this.state.id;
    const sequenceDefaultTemplateSettings = this.handleSDTSSave(formValues);
    const offersSequences = this.handleOffersSequencesSave(formValues, sequenceFlow);
    const sequencesCheckoutStatus = this.handleSequencesCheckoutStatus(1);
    const zuulUser = JSON.parse(localStorage.getItem('userData'));
    const sequenceView = {
      sequence,
      sequenceDefaultTemplateSettings,
      offersSequences,
    };
    const sequenceDraft = JSON.stringify(sequenceView);
    console.log('sequence Draft:', sequenceView);
    const sequenceCheckout = {
      createdAt: this.props.sequenceCheckout.createdAt,
      updatedAt: new Date(),
      sequence,
      sequenceDraft,
      sequencesCheckoutStatus,
      zuulUser,
      isPublished: false,
    };

    await this.props.updateSequenceCheckoutApi(sequenceCheckout);
    this.setState({ isDraft: false });
    if (!this.props.saveError && !this.state.saveAndContinue) this.props.history.push('/campaigns/sequences');
    return !this.props.saveError;
  };

  revertSequence = async () => {
    if (this.state.publishedSequence) {
      const sequenceCheckout = {
        id: this.state.publishedSequence.id,
        sequence: this.state.publishedSequence.sequence,
        zuulUser: this.state.publishedSequence.zuulUser,
        sequenceDraft: this.state.publishedSequence.sequencePublished,
        createdAt: this.props.sequenceCheckout.createdAt,
        updatedAt: new Date(),
        sequencesCheckoutStatus: {
          id: 1,
          statusName: 'checked_in',
        },
        isPublished: true,
      };
      await this.props.updateSequenceCheckoutApi(sequenceCheckout);
    }
    this.props.history.go(0);
  };

  createSequence = async (formValues, sequenceFlow) => {
    if (this.props.newEntityName) {
      formValues.sequenceDescription = null;
    }
    const sequenceView = {
      sequence: this.handleSequenceSave(formValues),
      sequenceDefaultTemplateSettings: this.handleSDTSSave(formValues),
      offersSequences: this.handleOffersSequencesSave(formValues, sequenceFlow),
    };
    sequenceView.sequence.createdAt = new Date();
    const previousSequenceCheckout = this.props.sequenceCheckout;
    const sequenceViewResponse = await this.props.createSequenceViewApi(sequenceView);
    const seqCheckout = {
      createdAt: new Date(),
      updatedAt: new Date(),
      sequenceDraft: JSON.stringify(sequenceViewResponse),
      sequence: sequenceViewResponse.sequence,
      sequencesCheckoutStatus: this.handleSequencesCheckoutStatus(1),
      zuulUser: JSON.parse(localStorage.getItem('userData')),
      isPublished: true,
    };
    const sequenceCheckout = await this.props.createSequenceCheckoutApi(seqCheckout);

    if (!this.state.isDraft || this.props.newEntityName) {
      await this.props.publishSequenceApi(sequenceCheckout);
    }

    if (!this.props.saveError && this.props.newEntityName) {
      this.checkInOrOutSequence(1, previousSequenceCheckout);
    } else if (!this.props.saveError) {
      this.props.history.push('/campaigns/sequences');
    }
    return !this.props.saveError;
  };

  createSequenceDraft = async (formValues, sequenceFlow) => {
    const sequenceView = {
      sequence: this.handleSequenceSave(formValues),
      sequenceDefaultTemplateSettings: this.handleSDTSSave(formValues),
      offersSequences: this.handleOffersSequencesSave(formValues, sequenceFlow),
    };
    sequenceView.sequence.createdAt = new Date();
    const sequenceViewResponse = await this.props.createSequenceViewApi(sequenceView);
    const seqCheckout = {
      createdAt: new Date(),
      updatedAt: new Date(),
      sequenceDraft: JSON.stringify(sequenceViewResponse),
      sequence: sequenceViewResponse.sequence,
      sequencesCheckoutStatus: this.handleSequencesCheckoutStatus(1),
      zuulUser: JSON.parse(localStorage.getItem('userData')),
      isPublished: false,
    };

    await this.props.createSequenceCheckoutApi(seqCheckout);

    if (!this.props.saveError) {
      this.props.history.push('/campaigns/sequences');
    }
    return !this.props.saveError;
  };

  onClickSubmit = (activeStatus) => {
    const { id } = this.props.sequenceCheckout.sequence;
    if (!this.state.isEditMode) {
      this.setState({
        isActive: activeStatus,
      });
      return;
    }
    let isActive = activeStatus !== undefined ? activeStatus : this.state.sequence.isActive;
    const update = {
      id,
      isActive,
    };

    api.put(`sequences/active/${id}`, update).then((result) => {
      console.log(JSON.stringify(result.data));
      this.setState({
        isActive,
      });
    });
  };

  filterOffersBySearchTerm = (searchTerm, offers) => {
    const throttledSearch = _.throttle(filterDataBySearchTerm, 500);
    const keysToSearchBy = ['id', 'name'];
    let filteredOffers = throttledSearch(searchTerm, keysToSearchBy, offers);
    return filteredOffers;
  };

  renderAppSwitch = () => {
    const { sequence, isActive, errors, isEditMode } = this.state;
    return (
      <ActiveStatusModal
        content={sequence}
        isActive={isActive}
        modalType={' Sequence'}
        getErrors={() => errors}
        onClickSubmit={this.onClickSubmit}
        isEditMode={isEditMode}
        disabled={!isEditMode}
      />
    );
  };

  getErrors = () => {
    const {
      saveError,
      saveErrorMessage,
      saveErrorStatus,
      submitFailed,
      invalid,
      saveWarning,
      saveWarningMessage,
    } = this.props;
    let errors = {
      ...this.state.errors,
      invalid: submitFailed ? invalid : false,
      saveError: saveError,
      _error:
        saveError || saveErrorStatus === 504
          ? saveErrorMessage
          : submitFailed
          ? 'One or more of the fields you entered are invalid.'
          : null,
      saveWarning: saveWarning,
      _warning: saveWarningMessage,
    };
    return errors;
  };

  handleSDTSInit = (sequenceDefaultTemplateSettings) => {
    const sdtsInitObject = {};
    const offerTypeNameSDTSMap = {};

    this.state.offerTypeNames.forEach((offerTypeName, i) => {
      const sdts =
        sequenceDefaultTemplateSettings.find((sdts) => {
          return sdts.offerType && sdts.offerType.name === offerTypeName;
        }) || {};
      const layoutFieldName = this.layoutNames[i];
      const containerFieldName = this.containerNames[i];
      sdtsInitObject[layoutFieldName] = sdts.layout || null;
      sdtsInitObject[containerFieldName] = sdts.container || null;

      if (this.state.isEditMode) {
        // sets a hash map in state to keep track of the existing database SDTS entities, using the offerTypeName as the key, and the sdts object as the value.
        offerTypeNameSDTSMap[offerTypeName] = sdts;
      }
    });

    this.setState({
      offerTypeNameSDTSMap,
    });
    return sdtsInitObject;
  };

  initializeSequenceCheckOutData = () => {
    const {
      sequenceCheckout: { sequence, sequenceDraft },
    } = this.props;
    const seqDraft = this.parseSequenceDraft(sequenceDraft);
    const sdts = this.handleSDTSInit(seqDraft.sequenceDefaultTemplateSettings);
    const [offersSequencesSidePanel, reactFlowOffersAndEdges, returnUrlOfferSequence] = handleFlowOffersInit(
      seqDraft.offersSequences,
      false,
      this.state.sequenceOrderBy
    );
    console.log('SEQUENCE DRAFT ==>', seqDraft);
    const sequenceView = {
      createdAt: seqDraft.sequence.createdAt || null,
      endDate: null,
      flex: seqDraft.sequence.flex || 1,
      isActive: sequence.isActive || false,
      isDynamic: false,
      sequenceName: seqDraft.sequence.name || '',
      returnRedirectDomain: seqDraft.sequence.returnRedirectDomain || null,
      returnVisits: seqDraft.sequence.returnVisits || 0,
      site: seqDraft.sequence.site ? { id: seqDraft.sequence.site.id, domain: seqDraft.sequence.site.domain } : null,
      startDate: null,
      status: seqDraft.sequence.status || 0,
      targetReturningUsers: seqDraft.sequence.targetReturningUsers || false,
      sequenceDescription: seqDraft.sequence.sequenceDescription || null,
      customVariables: seqDraft.sequence.customVariables ? JSON.parse(seqDraft.sequence.customVariables) : null,
      ...sdts,
      ...offersSequencesSidePanel,
    };
    // remove in final
    console.log('Initial load for SequenceView: ', sequenceView);
    this.props.initialize(sequenceView);
    this.setState({
      offersSequences: reactFlowOffersAndEdges,
      returnUrlOfferSequence,
      isActive: sequence.isActive,
      sequence: seqDraft.sequence,
    });
  };

  parseSequenceDraft = (sequenceDraft) => {
    let data = JSON.parse(sequenceDraft);
    return data;
  };

  renderSite = (fieldName) => {
    return (
      <Row>
        <Col lg={12}>
          <Label>Site</Label>
          <Field
            name={fieldName}
            id={fieldName}
            component={renderComboBox}
            data={this.props.sites}
            disabled={fieldName === 'site' && this.state.isEditMode}
            valueField='id'
            textField='domain'
          />
        </Col>
      </Row>
    );
  };

  onPageOrTabClose = (e) => {
    e.preventDefault();
    this.checkInOrOutSequence(1);
  };

  checkInOrOutSequence = (checkInOrOutStatusId, existingSequenceCheckout) => {
    let { sequenceCheckout } = this.props;
    if (existingSequenceCheckout && JSON.stringify(existingSequenceCheckout) !== '{}') {
      sequenceCheckout = existingSequenceCheckout;
    }

    console.log(sequenceCheckout);
    sequenceCheckout.sequencesCheckoutStatus = this.handleSequencesCheckoutStatus(checkInOrOutStatusId);
    sequenceCheckout.zuulUser = checkInOrOutStatusId === 1 ? null : JSON.parse(localStorage.getItem('userData'));
    this.props.updateSequenceCheckoutStatusIdBySequenceIdApi(sequenceCheckout.sequence.id, checkInOrOutStatusId);
    if (checkInOrOutStatusId === 1) {
      return this.props.history.push('/campaigns/sequences');
    }
  };

  /*
	The debounce function is used to prevent updates to state multiple times within a second (based on scroll and click events)
	This makes it so the update to state only happens once after 500 milliseconds of inactivity
	*/
  debounce = (idleUpdate, delay) => {
    let debounceTimer;
    return function executedFunction() {
      const context = this;
      const later = function() {
        debounceTimer = null;
        idleUpdate.apply(context);
      };
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(later, delay);
    };
  };

  onUserActivity = this.debounce(() => {
    this.idleTime = 0;
    if (this.state.shouldWarnUser) {
      this.setState({ shouldWarnUser: false });
    }
  }, 1000);

  setTimer() {
    // set an interval that will increment the idleTime property by 1000 for every second that passes. If idleTime is greater than the allowed idle time, log user out.
    this.interval = setInterval(() => {
      this.idleTime += 1000;
      const { allowedIdleTime } = this.state;
      const shouldCheckIn = this.idleTime > allowedIdleTime;
      const shouldWarnUser = this.idleTime === 30 * 60000;
      if (shouldCheckIn) {
        clearInterval(this.interval);
        return this.checkInOrOutSequence(1);
      } else if (shouldWarnUser) {
        this.setState({ shouldWarnUser });
      }
    }, 1000);
  }

  async getRemainingOffers(totalPages) {
    for (let i = 1; i < totalPages; i++) {
      await api
        .get(`offers/table?limit=100&offset=${i}`)
        .then((res) => {
          let offers = res.data.content?.filter((offer) => {
            return offer.offerType?.id !== 8 && offer.offerType?.id !== 6;
          });
          this.setState({
            offers: this.state.offers.concat(offers),
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  async getInitialOffers() {
    await api
      .get(`offers/table?limit=100&offset=0`)
      .then(async (res) => {
        let offers = res.data.content?.filter((offer) => {
          return offer.offerType?.id !== 8 && offer.offerType?.id !== 6;
        });
        this.setState({
          offers: offers,
        });
        await this.getRemainingOffers(res.data.totalPages);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  // Lifecycle Fns
  async componentDidMount() {
    if (
      !localStorage.getItem('userData') ||
      !localStorage.getItem('okta-token-storage') ||
      !JSON.parse(localStorage.getItem('okta-token-storage')).accessToken
    ) {
      return this.props.history.push('/login');
    }

    const oktaInfo = JSON.parse(localStorage.getItem('okta-token-storage'));
    const authToken = oktaInfo.accessToken.accessToken;
    api.defaults.headers.common['Authorization'] = `Bearer ${authToken}`;

    let reqUserData = {
      email: oktaInfo.idToken.claims.email,
      realname: oktaInfo.idToken.claims.name,
      lastLogin: new Date(),
    };

    api
      .put('zuulusers/login', reqUserData)
      .then((response) => {
        let responseJson = response.data;
        let isSignedInNow = responseJson?.zuulUser?.online;

        localStorage.setItem('userData', JSON.stringify(responseJson?.zuulUser));

        return isSignedInNow;
      })
      .catch((error) => {
        console.log('LOGIN ERROR');
        console.log(error);
      });

    this.props.fetchAllContainers();
    this.props.fetchAllLayouts();
    this.props.fetchAllSites();
    this.props.getOfferTypes();

    const { sequenceId, checkIn } = this.props.match.params;
    if (sequenceId) {
      window.addEventListener('mousemove', this.onUserActivity, false);
      window.addEventListener('click', this.onUserActivity, false);
      this.setTimer();
      const { sequenceId } = this.props.match.params;
      const response = await api.get(`offers-sequence/sequences/${sequenceId}/orderBy`);
      const sequenceOrderBy = response.data;

      let sequenceCheckoutResponse;
      sequenceCheckoutResponse = await this.props.getSequenceCheckout(sequenceId);
      let sequencePublishResponse = await this.props.getSequencePublished(sequenceId);
      if (sequencePublishResponse) {
        this.setState({ publishedSequence: sequencePublishResponse });
      }
      if (sequenceCheckoutResponse.isPublished) {
        this.setState({ isPublished: true });
      }
      if (sequenceCheckoutResponse.sequencesCheckoutStatus.id === 1) {
        window.addEventListener('beforeunload', this.onPageOrTabClose);
        this.checkInOrOutSequence(2);
        await this.getInitialOffers();
        this.setState(
          { isEditMode: true, id: parseInt(sequenceId), sequenceOrderBy },
          this.initializeSequenceCheckOutData
        );
      } else if (
        sequenceCheckoutResponse.sequencesCheckoutStatus.id === 2 &&
        (checkIn ? checkIn.toLowerCase() !== 'checkin' : true)
      ) {
        this.setState({ isCheckedOut: true });
      } else if (
        sequenceCheckoutResponse.sequencesCheckoutStatus.id === 2 &&
        (checkIn ? checkIn.toLowerCase() === 'checkin' : false)
      ) {
        this.setState({ isManualCheckin: true });
        return this.checkInOrOutSequence(1);
      }
    } else {
      // No entity, initialize create mode
      await this.getInitialOffers();
      this.props.initialize({});
      this.setState({ isEditMode: false, id: null });
    }
  }

  componentWillUnmount() {
    this.props.updateSelectedElement({});
    this.props.updateOrphanedOffers([], false);
    if (this.state.isEditMode) {
      // remove event listeners once when the component will unmount
      window.removeEventListener('mousemove', this.onUserActivity, false);
      window.removeEventListener('click', this.onUserActivity, false);
      if (!this.state.isCheckedOut) {
        window.removeEventListener('beforeunload', this.onPageOrTabClose);
      }
      // clear any lingering intervals
      clearInterval(this.interval);
    }
  }

  render() {
    const errors = this.getErrors();
    const shouldErrorRender = Object.keys(errors).some((x) => errors[x]);
    const { orphanedOffers, hasMoreThanOneOSHead, history, handleSubmit } = this.props;
    const { sequenceId } = this.props.match.params;
    return (
      <div className='animated fadeIn'>
        {!this.state.isCheckedOut && !this.state.isManualCheckin && (
          <ExitConfirmation
            visible={this.state.confirmedExitModalVisible}
            when={!this.state.confirmedExit && !this.state.shouldWarnUser}
            closeModal={() => this.setState({ confirmedExitModalVisible: false })}
            openModal={() => this.setState({ confirmedExitModalVisible: true })}
            navigate={(path) => {
              this.props.updateSequenceCheckoutStatusIdBySequenceIdApi(sequenceId, 1);
              history.push(path);
            }}
          />
        )}
        <form onSubmit={handleSubmit(this.onSubmitForm)}>
          <SequenceNav
            setConfirmedExitModalTrue={(e) => {
              e.preventDefault();
              this.setState({ confirmedExitModalVisible: true });
            }}
            leftAside={this.state.leftAside}
            toggleAside={this.toggleAside}
            getErrors={this.getErrors}
            setIsDraft={(isDraft) => this.setState({ isDraft })}
            onSubmitForm={this.onSubmitForm}
            isEditMode={this.state.isEditMode}
            offers={this.state.offers}
            sequenceName={this.props.sequenceName}
            submitting={this.props.submitting}
            saveAndContinue={(value) => this.setState({ saveAndContinue: value })}
            renderSite={this.renderSite('saveAsSite')}
            rightAside={this.state.rightAside}
            isPublished={this.state.isPublished}
            revertSequence={this.revertSequence}
            id={this.state.id}
            shouldWarnUser={this.state.shouldWarnUser}
            onUserActivity={this.onUserActivity}
            isCheckedOut={this.state.isCheckedOut}
            shouldErrorRender={shouldErrorRender}
            push={history.push}
            handleSubmit={handleSubmit}
            publishedSequence={this.state.publishedSequence}
          />

          <Row className='sequence-section'>
            {this.state.leftAside && (
              <Col id='leftAside' md={2} sm={12} className={`toggled-${this.state.leftAside ? 'show' : 'hide'}`}>
                <LeftAside isPublished={this.state.isPublished} offers={this.state.offers} />
              </Col>
            )}
            <Col id='sequenceDropzone' md={this.state.leftAside ? 7 : 9} sm={12}>
              <Row>
                <Col lg={12}>
                  {orphanedOffers.length ? (
                    <Alert id='invalid-offers-alert'>
                      {hasMoreThanOneOSHead
                        ? 'A break in this sequence is resulting in two or more starting points. '
                        : ''}
                      The following offers are invalid:{' '}
                      {renderOrphanedOffers(orphanedOffers, this.handleOrphanedOfferClick)}
                    </Alert>
                  ) : null}
                </Col>
              </Row>
              {this.props.initialized ? (
                <DnDFlow
                  initialElements={this.state.offersSequences}
                  offers={this.state.offers}
                  filteredOffers={this.state.offers}
                  sequenceOrderBy={this.state.sequenceOrderBy}
                />
              ) : (
                <ZuulLoader text={'Initializing sequence editor...'} />
              )}
            </Col>
            {this.state.rightAside && (
              <Col
                id='sequenceOfferMetadata'
                md={3}
                sm={12}
                className={`toggled-${this.state.rightAside ? 'show' : 'hide'}`}
              >
                <RightAside
                  rightAside={this.state.rightAside}
                  isPublished={this.state.isPublished}
                  renderAppSwitch={this.renderAppSwitch}
                  renderSite={this.renderSite('site')}
                  targetReturningUsers={this.props.targetReturningUsers}
                  layouts={this.props.layouts}
                  layoutNames={this.layoutNames}
                  offerTypeNames={this.state.offerTypeNames}
                  containerNames={this.containerNames}
                  containers={this.props.containers}
                  offerTypes={this.props.offerTypes}
                  selectedElement={this.props.selectedElement}
                />
              </Col>
            )}
          </Row>
        </form>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const {
    orphanedOffers,
    hasMoreThanOneOSHead,
    selector,
    sequenceView,
    sequenceCheckout,
    saveError,
    saveErrorMessage,
    saveErrorStatus,
    saveWarning,
    saveWarningMessage,
    reactFlowInstance,
    selectedElement,
    isSequenceSaved,
  } = state.sequence;
  const { layouts, containers, sites, offerTypes } = state.metadata;
  const { newEntityName } = state.components;
  return {
    orphanedOffers,
    hasMoreThanOneOSHead,
    selector,
    sequenceName: selector(state, 'sequenceName'),
    sequenceView,
    sequenceCheckout,
    saveError,
    saveErrorMessage,
    saveErrorStatus,
    saveWarning,
    saveWarningMessage,
    isSequenceSaved,
    layouts,
    containers,
    sites,
    offerTypes,
    targetReturningUsers: selector(state, 'targetReturningUsers'),
    newEntityName,
    reactFlowInstance,
    selectedElement,
  };
}

const form = reduxForm({
  form: 'sequenceDetails',
  validate,
});

export default connect(mapStateToProps, {
  fetchAllEntities,
  createSDTS,
  updateSDTS,
  createSequenceViewApi,
  createSequenceCheckoutApi,
  updateSequenceViewApi,
  updateSequenceCheckoutApi,
  updateSequenceCheckoutStatus,
  publishSequenceApi,
  publishSequenceByIdApi,
  getSequence,
  getSequenceView,
  getSequenceCheckout,
  getSequencePublished,
  fetchAllContainers,
  fetchAllLayouts,
  fetchAllSites,
  getOfferTypes,
  updateSelectedElement,
  updateOrphanedOffers,
  updateSequenceCheckoutStatusIdBySequenceIdApi,
})(form(withRouter(SequenceDetails)));
