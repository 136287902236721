import React from 'react';
import { Button, Col, Row } from 'reactstrap';
import { Input } from 'reactstrap';

const DropdownValueItem = ({
  item,
  handleDropdownValueChange,
  removeDropdownValue,
  dropdownValuesLength,
  validateState,
}) => (
  <Row className='d-flex align-items-end my-2'>
    <Col lg={2}>
      <Button
        className='ml-2'
        color='danger'
        onClick={() => removeDropdownValue(item.id)}
        disabled={dropdownValuesLength === 1}
      >
        <i className='fa fa-trash'></i>
      </Button>
    </Col>
    <Col lg={10}>
      <label>Value:</label>
      <Input placeholder='Dropdown Option' value={item.value} onChange={(e) => handleDropdownValueChange(e, item.id)} />
      {validateState.isDuplicate[item.id] && (
        <div style={{ color: '#f86c6b', fontSize: '12px', padding: '5px' }}>This value already exists</div>
      )}
      {validateState.isEmpty[item.id] && (
        <div style={{ color: '#f86c6b', fontSize: '12px', padding: '5px' }}>This field is required</div>
      )}
    </Col>
  </Row>
);

export default DropdownValueItem;
