import api from '../../../services/index';
import {
  GET_ADVERTISER_PRE_PING,
  UPDATE_ADVERTISER_PRE_PING,
  CREATE_ADVERTISER_PRE_PING,
  UPDATE_ADVERTISER_PRE_PING_ERROR
} from './types';

export const getAdvertiserPrePing = advPrePingId => dispatch => {
  try {
    return new Promise(resolve => {
      api.get(`advertiser-pre-pings/${advPrePingId}`).then(response => {
        dispatch({
          type: GET_ADVERTISER_PRE_PING,
          payload: response.data
        });
        resolve(response.data);
      });
    });
  } catch (error) {
    console.log(error);
  }
};

export const createAdvertiserPrePing = advertiserPrePing => dispatch => {
  try {
    return new Promise(resolve => {
      api.post('advertiser-pre-pings', advertiserPrePing).then(response => {
        console.log('Created advertiser pre-ping: ' + JSON.stringify(response.data));
        console.log(response.data);
        dispatch({
          type: CREATE_ADVERTISER_PRE_PING,
          payload: response.data
        });
        resolve(response.data);
      });
    }).catch(savedAdvertiserPrePingError => {
      dispatch(updateAdvertiserPrePingError(savedAdvertiserPrePingError));
    });
  } catch (error) {
    console.log(error);
  }
};

export const updateAdvertiserPrePing = advertiserPrePing => dispatch => {
  console.log(advertiserPrePing);
  try {
    return new Promise(resolve => {
      api
        .put(`advertiser-pre-pings/${advertiserPrePing.id}`, advertiserPrePing)
        .then(response => {
          console.log('Updated advertiser pre-ping: ' + JSON.stringify(response.data));
          console.log(response.data);
          dispatch({
            type: UPDATE_ADVERTISER_PRE_PING,
            payload: response.data
          });
          resolve(response.data);
        })
        .catch(savedAdvertiserPrePingError => {
          dispatch(updateAdvertiserPrePingError(savedAdvertiserPrePingError));
        });
    });
  } catch (error) {
    console.log(error);
  }
};

export const updateAdvertiserPrePingError = e => {
  return {
    type: UPDATE_ADVERTISER_PRE_PING_ERROR,
    payload: e.response.data.message
  };
};
