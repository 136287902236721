import _ from 'lodash';
import React, { useRef, useState } from 'react';
import ReactFlow, { addEdge, Controls, ReactFlowProvider, removeElements } from 'react-flow-renderer';
import Switch from 'react-switch';
import { Col, Row } from 'reactstrap';
import { renderOffersSequenceNode } from '../../Offers/OfferTitle/renderConsts';
import EdgeWithButton from '../OfferBlockDetails/EdgeWithButton';
import {
  findBottomOfferElement,
  findTopOfferElement,
  getNodeAndPosition,
  getSequenceFlowOffersAndEdges,
  handleFlowOffersInit,
  handleOffersSequenceReordering,
  handleOrphanedOffers,
  hasMultipleStartNodes,
  sortOfferSequences,
  updateOffersWithHiddenAttr,
  updateOrphanedOffersState,
} from '../OfferBlockDetails/flowUtils';

const edgeTypes = {
  edgeWithButton: EdgeWithButton,
};

const DnDFlow = (props) => {
  const [elements, setElements] = useState(props.initialElements);
  const [shouldHideInactiveOffers, setShouldHideInactiveOffers] = useState(false);
  const reactFlowWrapper = useRef(null);

  const onConnect = (params) => {
    params.type = 'edgeWithButton';
    params.className = 'osEdge';
    params.style = { strokeWidth: 3 };
    let updatedElements = addEdge({ ...params, type: 'edgeWithButton' }, elements);

    const { orphanedOffers } = props;

    if (orphanedOffers.length) {
      const updatedOrphanedOffers = handleOrphanedOffers(updatedElements);
      const hasMoreThanOneOSHead = hasMultipleStartNodes(updatedElements);
      updatedElements = updateOrphanedOffersState(updatedOrphanedOffers, updatedElements);
      props.updateOrphanedOffers(updatedOrphanedOffers, hasMoreThanOneOSHead);
    }

    setElements(updatedElements);
  };

  const onElementsRemove = (elementsToRemove) => {
    for (let i = 0; i < elementsToRemove.length; i++) {
      const element = elementsToRemove[i];
      if (element.data) {
        const [existingNode, existingNodePosition] = getNodeAndPosition(element, elements);
        if (existingNode.isHiddenPlaceholder) {
          return;
        }
      }
    }

    let updatedElements = removeElements(elementsToRemove, elements);

    if (elementsToRemove.length > 1) {
      // elementsToRemove would have a length of 1 if we're only trying to remove a connection
      let [, sequenceFlowOffers] = getSequenceFlowOffersAndEdges(updatedElements);
      sequenceFlowOffers = sequenceFlowOffers.map((sequenceFlowOffer) =>
        sequenceFlowOffer.offersSequence ? sequenceFlowOffer.offersSequence : sequenceFlowOffer
      );
      const reactFlowOffersAndEdges = handleFlowOffersInit(sequenceFlowOffers);
      updatedElements = reactFlowOffersAndEdges;
    }

    const { orphanedOffers } = props;

    if (orphanedOffers.length) {
      const updatedOrphanedOffers = handleOrphanedOffers(updatedElements);
      const hasMoreThanOneOSHead = hasMultipleStartNodes(updatedElements);
      updatedElements = updateOrphanedOffersState(updatedOrphanedOffers, updatedElements);
      props.updateOrphanedOffers(updatedOrphanedOffers, hasMoreThanOneOSHead);
    }

    setElements(updatedElements);
  };

  const onLoad = (reactFlowInstance) => {
    props.setReactFlowInstance(reactFlowInstance);
  };

  const onDragOver = (event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
    const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();

    const position = props.reactFlowInstance.project({
      x: event.clientX - reactFlowBounds.left - 50,
      y: event.clientY - reactFlowBounds.top - 50,
    });

    const element = {
      position,
    };

    const draggedNodeHeight = 75;
    const draggedNodeWidth = 120;

    const topOfferElement = findTopOfferElement(elements, element, draggedNodeHeight, draggedNodeWidth);
    const bottomOfferElement = findBottomOfferElement(elements, element, draggedNodeHeight, draggedNodeWidth);

    if (topOfferElement) {
      if (!topOfferElement.className?.includes('topOfferElement')) {
        topOfferElement.className += ' topOfferElement';
      }
    }
    if (bottomOfferElement) {
      if (!bottomOfferElement.className?.includes('bottomOfferElement')) {
        bottomOfferElement.className += ' bottomOfferElement';
      }
    }

    const updatedElementsWithClasses = elements.map((el) => {
      if (el.hasOwnProperty('target')) {
        return el;
      }

      updateElementClassNames(el, topOfferElement, bottomOfferElement);

      return el;
    });

    setElements(updatedElementsWithClasses);
  };

  const onDrop = (event) => {
    event.preventDefault();

    const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
    const draggedOffer = JSON.parse(event.dataTransfer.getData('application/reactflow'));

    const position = props.reactFlowInstance.project({
      x: event.clientX - reactFlowBounds.left - 50,
      y: event.clientY - reactFlowBounds.top - 50,
    });

    const i = elements.filter((element) => element.data).length;
    const newNode = {
      id: `${draggedOffer.id}-${i}`,
      offerType: draggedOffer.offerType,
      name: draggedOffer.name,
      offer: draggedOffer,
      position,
      data: { label: renderOffersSequenceNode(draggedOffer, i) },
      className: `offerNode ${!draggedOffer.isActive ? 'inactiveOffer' : null}`,
    };

    let { orphanedOffers } = props;
    let updatedElements = elements.concat(newNode);
    updatedElements = handleOffersSequenceReordering({
      elements: updatedElements,
      draggedNode: newNode,
      updateOrphanedOffersProps: props.updateOrphanedOffers,
      shouldHideInactiveOffers,
      orphanedOffers,
    });

    const formValue = {
      offer_id: draggedOffer.id,
      container_id: null,
      layout_id: null,
      status: draggedOffer.isActive ? 1 : 0,
    };

    props.formPush('offersPublished.offer_block', formValue);
    props.setSelectedElement(newNode);
    setElements(updatedElements);
  };

  const toggleClassName = (element, className) => {
    const classNames = element.className.split(' ');
    const index = classNames.indexOf(className);

    if (index === -1) {
      classNames.push(className);
    } else {
      classNames.splice(index, 1);
    }

    element.className = classNames.join(' ').trim();
  };

  const updateElementClassNames = (element, topOfferElement, bottomOfferElement, isSelectedElement) => {
    if (!element) {
      return; // exit early if element is null or undefined
    }

    if (topOfferElement && topOfferElement.id !== element.id) {
      element.className = element.className?.replace('topOfferElement', '').trim();
    }
    if (bottomOfferElement && bottomOfferElement.id !== element.id) {
      element.className = element.className?.replace('bottomOfferElement', '').trim();
    }
    if (isSelectedElement) {
      toggleClassName(element, 'selectedElement');
    } else {
      element.className = element.className?.replace('selectedElement', '').trim();
    }
  };

  const handleNodeSelection = (event, element) => {
    let selectedOfferId = null;
    let selectedOffer = { id: null };
    if (element.id) {
      [selectedOfferId] = element.id.split('-');
      selectedOffer = _.find(props.offers, (offer) => offer.id === parseInt(selectedOfferId));
    }

    if (element.hasOwnProperty('target')) {
      if (!event.defaultPrevented) {
        return;
      } else {
        return onElementsRemove([element]);
      }
    }

    const draggedNodeHeight = 75;
    const draggedNodeWidth = 120;

    const topOfferElement = findTopOfferElement(elements, element, draggedNodeHeight, draggedNodeWidth);
    const bottomOfferElement = findBottomOfferElement(elements, element, draggedNodeHeight, draggedNodeWidth);

    if (topOfferElement) {
      if (!topOfferElement.className?.includes('topOfferElement')) {
        topOfferElement.className += ' topOfferElement';
      }
    }
    if (bottomOfferElement) {
      if (!bottomOfferElement.className?.includes('bottomOfferElement')) {
        bottomOfferElement.className += ' bottomOfferElement';
      }
    }

    let selectedElement = { id: null };
    const updatedElementsWithClasses = elements.map((el) => {
      if (el.hasOwnProperty('target')) {
        return el;
      }

      const isSelectedElement = el.id === element.id;
      updateElementClassNames(el, topOfferElement, bottomOfferElement, isSelectedElement);

      if (isSelectedElement) {
        selectedElement = el;
        selectedElement.offer = selectedOffer;
      }

      return el;
    });

    props.setSelectedElement(selectedElement);
    setElements(updatedElementsWithClasses);
  };

  const handleNodeSelectionDrag = (event, element) => {
    let selectedOfferId = null;
    let selectedOffer = { id: null };
    if (element.id) {
      [selectedOfferId] = element.id.split('-');
      selectedOffer = _.find(props.offers, (offer) => offer.id === parseInt(selectedOfferId));
    }

    if (element.hasOwnProperty('target')) {
      if (!event.defaultPrevented) {
        return;
      } else {
        return onElementsRemove([element]);
      }
    }

    const draggedNodeHeight = 75;
    const draggedNodeWidth = 120;

    const topOfferElement = findTopOfferElement(elements, element, draggedNodeHeight, draggedNodeWidth);
    const bottomOfferElement = findBottomOfferElement(elements, element, draggedNodeHeight, draggedNodeWidth);

    if (topOfferElement) {
      if (!topOfferElement.className?.includes('topOfferElement')) {
        topOfferElement.className += ' topOfferElement';
      }
    }
    if (bottomOfferElement) {
      if (!bottomOfferElement.className?.includes('bottomOfferElement')) {
        bottomOfferElement.className += ' bottomOfferElement';
      }
    }

    let selectedElement = { id: null };
    const updatedElementsWithClasses = elements.map((el) => {
      if (el.hasOwnProperty('target')) {
        return el;
      }

      const isSelectedElement = el.id === element.id;
      if (!topOfferElement || topOfferElement.id !== el.id) {
        el.className = el.className?.replace('topOfferElement', '').trim();
      }
      if (!bottomOfferElement || bottomOfferElement.id !== el.id) {
        el.className = el.className?.replace('bottomOfferElement', '').trim();
      }
      if (isSelectedElement) {
        if (!el.className?.includes('selectedElement')) {
          el.className += ' selectedElement';
        }
      } else {
        el.className = el.className?.replace('selectedElement', '').trim();
      }

      if (isSelectedElement) {
        selectedElement = el;
        selectedElement.offer = selectedOffer;
      }

      return el;
    });

    props.setSelectedElement(selectedElement);
    setElements(updatedElementsWithClasses);
  };

  const handleNodeDragStop = (event, draggedNode) => {
    let { orphanedOffers } = props;
    elements.forEach((element) => {
      if (element.id === draggedNode.id) {
        element.position = draggedNode.position;
      }
    });

    const updatedElements = handleOffersSequenceReordering({
      elements,
      draggedNode,
      updateOrphanedOffersProps: props.updateOrphanedOffers,
      shouldHideInactiveOffers,
      orphanedOffers,
    });

    setElements(updatedElements);
  };

  const handleShowInactiveOffers = (shouldHideInactiveOffers) => {
    setShouldHideInactiveOffers(!shouldHideInactiveOffers);

    let [sequenceFlowEdges, sequenceFlowOffers] = getSequenceFlowOffersAndEdges(elements);
    sequenceFlowOffers = sortOfferSequences(sequenceFlowOffers);
    [sequenceFlowEdges, sequenceFlowOffers] = updateOffersWithHiddenAttr(sequenceFlowOffers, !shouldHideInactiveOffers);
    setElements(sequenceFlowOffers.concat(sequenceFlowEdges));
  };

  return (
    <div id='dndflow'>
      <Row>
        <Col lg={{ size: 3, offset: 9 }}>
          <Switch
            id='shouldHideInactiveOffers'
            checked={shouldHideInactiveOffers}
            onChange={() => handleShowInactiveOffers(shouldHideInactiveOffers)}
            disabled={props.offers?.length === 0}
            onColor='#4dbd74'
            offColor='#f86c6b'
            onHandleColor='#ffffff'
            uncheckedIcon={false}
            checkedIcon={false}
            boxShadow='0px 1px 5px rgba(0, 0, 0, 0.6)'
            activeBoxShadow='0px 0px 1px 10px rgba(0, 0, 0, 0.2)'
            height={18}
            width={42}
            className='react-switch shouldHideInactiveOffers'
          />
          <label className='shouldHideInactiveOffersLabel' htmlFor='shouldHideInactiveOffers'>
            Hide Inactive Offers
          </label>
        </Col>
      </Row>
      <ReactFlowProvider>
        <div className='reactflow-wrapper' ref={reactFlowWrapper}>
          <ReactFlow
            elements={elements}
            edgeTypes={edgeTypes}
            onConnect={onConnect}
            onElementsRemove={onElementsRemove}
            onLoad={onLoad}
            onDrop={onDrop}
            onNodeDrag={handleNodeSelectionDrag}
            onNodeDragStop={handleNodeDragStop}
            onElementClick={handleNodeSelection}
            onDragOver={onDragOver}
            panOnScroll={true}
          >
            <Controls />
          </ReactFlow>
        </div>
      </ReactFlowProvider>
    </div>
  );
};

export default DnDFlow;
