import { formValueSelector } from 'redux-form';
import {
  GET_ADVERTISER_PRE_PING,
  UPDATE_ADVERTISER_PRE_PING,
  CREATE_ADVERTISER_PRE_PING,
  UPDATE_ADVERTISER_PRE_PING_ERROR
} from './types';
import { RESET_MODAL } from '../../../actions/types';

const initialState = {
  selector: formValueSelector('advertiserPrePing'),
  currAdvPrePing: {},
  saveError: false,
  saveErrorMessage: null,
  isAdvertiserPrePingSaved: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_ADVERTISER_PRE_PING:
      return { ...state, currAdvPrePing: action.payload, isAdvertiserPrePingSaved: false, saveError: false };
    case UPDATE_ADVERTISER_PRE_PING:
      return { ...state, currAdvPrePing: action.payload, isAdvertiserPrePingSaved: true, saveError: false };
    case CREATE_ADVERTISER_PRE_PING:
      return { ...state, currAdvPrePing: action.payload, isAdvertiserPrePingSaved: true, saveError: false };
    case UPDATE_ADVERTISER_PRE_PING_ERROR:
      return { ...state, saveError: true, saveErrorMessage: action.payload };
    case RESET_MODAL:
      return {
        ...state,
        saveError: false,
        saveErrorMessage: null
      };
    default:
      return state;
  }
}
