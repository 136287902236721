import {
  validateCriteria
} from "../../../utils/CriteriaHelpers";

export default function validate(values, props) {
  if(!values) return;
  const errors = {};

  // Error Checks
  if (!values.advertiser) {
    errors.advertiser = {
      _error: 'Advertiser is required.',
    }
  }

  if(!values.offerWallAdName){
    errors.offerWallAdName = {
      _error: 'Name is required.',
    }
  }

  if(!values.offerWallAdImage){
    errors.offerWallAdName = {
      _error: 'Image is required.',
    }
  }

  // Check for linkout action present
  const linkoutPresent =
    values.offerWallAdActions &&
    values.offerWallAdActions.some(
      (triggerWithActions) => {
        return triggerWithActions.actions && triggerWithActions.actions.some(
          (action) => {
            return action && action.offerActionType && (action.offerActionType === 'LINKOUT' || action.offerActionType === 'LINKOUT_WATERFALL')
          })
      }
    );

  if(!linkoutPresent){
    errors.offerWallAdName = {
      _error: 'Linkout action is required.',
    }
  }

  let criteriaErrors = validateCriteria(values);
  return { ...errors, ...criteriaErrors };
}

