import React from 'react';
import { Alert } from 'reactstrap';

const OfferReorderingAlertBanner = ({details, isOpen, toggle}) => {

const { color, headline, subtext } = details
  return (
    <Alert color={color || 'info'} isOpen={isOpen} toggle={toggle}>
      <p className='mb-0'>{headline}</p>
      <small>{subtext}</small>
    </Alert>
  );
};

export default OfferReorderingAlertBanner;
