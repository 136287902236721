import _ from 'lodash';
import React, { Component } from 'react';
import ReactFlow, {
  addEdge,
  ControlButton,
  Controls,
  getIncomers,
  getOutgoers,
  ReactFlowProvider,
  removeElements,
  useStoreState,
} from 'react-flow-renderer';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Tooltip } from 'reactstrap';
import api from '../../../services';
import { renderOffersSequenceNode } from '../../Offers/OfferTitle/renderConsts';
import { setReactFlowInstance, updateOrphanedOffers, updateSelectedElement } from '../actions';
import './dnd.css';
import EdgeWithButton from './EdgeWithButton';
import {
  clearDraggedOfferOutlines,
  findBottomOfferElement,
  findTopOfferElement,
  getNodeAndPosition,
  getSequenceFlowOffersAndEdges,
  handleFlowOffersInit,
  handleOfferReordering,
  handleOffersSequenceReordering,
  handleOrphanedOffers,
  hasMultipleStartNodes,
  sortOfferSequences,
  updateOffersWithHiddenAttr,
  updateOrphanedOffersState,
  updateSequenceFlowOfferWithOSDetails,
} from './sequenceUtils';

const ControlButtonWithTooltip = ({ onClick, tooltipText, iconClassName, buttonId }) => {
  const [tooltipOpen, setTooltipOpen] = React.useState(false);

  return (
    <>
      <ControlButton id={buttonId} onClick={onClick}>
        <i className={iconClassName}></i>
      </ControlButton>
      <Tooltip
        placement='top'
        isOpen={tooltipOpen}
        target={buttonId}
        toggle={() => {
          setTooltipOpen(!tooltipOpen);
        }}
      >
        {tooltipText}
      </Tooltip>
    </>
  );
};

class DnDFlow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reactFlowWrapper: React.createRef(),
      selectedElement: {},
      selectedElements: [],
      selectedOffer: {},
      flowElementId: null,
      edgeTypes: {
        edgeWithButton: EdgeWithButton,
      },
      elements: [],
      shouldHideInactiveOffers: false,
      compactLayout: false,
      showConfirmationModal: false,
      sameOffersInSequenceCount: 0,
      sameOffersInSequenceName: '',
    };
  }

  handleKeyPress = (event) => {
    const { elements, selectedElement, shouldHideInactiveOffers } = this.state;
    if (!selectedElement.id) return;
    let updatedElements = elements;
    if (event.shiftKey) {
      const topNode = getIncomers(selectedElement, elements)[0];
      const bottomNode = getOutgoers(selectedElement, elements)[0];
      if (!topNode && !bottomNode) return elements;
      if (topNode && (topNode.isHiddenPlaceholder || topNode.isHidden) && event.code === 'ArrowUp') return;
      if (bottomNode && (bottomNode.isHiddenPlaceholder || bottomNode.isHidden) && event.code === 'ArrowDown') return;
      if (selectedElement.isHiddenPlaceholder || selectedElement.isHidden) return;
      let tempOrderBy;
      let [sequenceFlowEdges, sequenceFlowOffers] = getSequenceFlowOffersAndEdges(elements);
      let [existingNode, existingNodePosition] = getNodeAndPosition(selectedElement, sequenceFlowOffers);
      if (event.code === 'ArrowUp' && topNode) {
        sequenceFlowOffers = handleOfferReordering(
          sequenceFlowOffers,
          [selectedElement],
          existingNodePosition,
          topNode.orderBy
        );
        tempOrderBy = topNode.orderBy;
        topNode.orderBy = existingNode.orderBy;
        existingNode.orderBy = tempOrderBy;
      } else if (event.code === 'ArrowDown' && bottomNode) {
        sequenceFlowOffers = handleOfferReordering(
          sequenceFlowOffers,
          [existingNode],
          existingNodePosition,
          bottomNode.orderBy
        );
        tempOrderBy = bottomNode.orderBy;
        bottomNode.orderBy = existingNode.orderBy;
        existingNode.orderBy = tempOrderBy;
      } else {
        return;
      }

      sequenceFlowOffers = updateSequenceFlowOfferWithOSDetails(sequenceFlowOffers);
      let [, sequenceFlowOffersAndEdges] = handleFlowOffersInit(
        sequenceFlowOffers,
        this.state.compactLayout,
        this.props.sequenceOrderBy
      );

      if (shouldHideInactiveOffers) {
        [sequenceFlowEdges, sequenceFlowOffers] = updateOffersWithHiddenAttr(
          sequenceFlowOffers,
          shouldHideInactiveOffers,
          this.state.compactLayout
        );
        sequenceFlowOffersAndEdges = sequenceFlowOffers.concat(sequenceFlowEdges);
      }

      updatedElements = sequenceFlowOffersAndEdges;
    }

    this.setState({ elements: updatedElements });
  };

  async componentDidMount() {
    window.addEventListener('keydown', this.handleKeyPress);
    const { sequenceId } = this.props.match.params;
    const response = await api.get(`offers-sequence/sequences/${sequenceId}/orderBy`);
    const sequenceOrderBy = response.data;
    this.setState({ elements: this.props.initialElements, sequenceOrderBy });
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleKeyPress);
  }

  // TODO, update this, along with other willReceiveProps with React version.^17
  componentWillReceiveProps(nextProps) {
    if (nextProps.orphanedOffers.length !== this.props.orphanedOffers.length) {
      let updatedElements = updateOrphanedOffersState(nextProps.orphanedOffers, this.state.elements);
      this.setState({ elements: updatedElements });
    }

    // reactFlowInstance only gets initialized once, which is when we should update the stale offers
    if (nextProps.reactFlowInstance !== null) {
      // update offers within the flow to have the most recently fetched offer data
      let [sequenceFlowEdges, sequenceFlowOffers] = getSequenceFlowOffersAndEdges(this.state.elements);
      sequenceFlowOffers = this.updateOffersWithRecentlyFetchedData(sequenceFlowOffers, nextProps?.offers || []);
      this.setState({ elements: sequenceFlowOffers.concat(sequenceFlowEdges) });
    }
  }
  updateOffersWithRecentlyFetchedData = (sequenceFlowOffers, offers) => {
    const offerMap = _.mapKeys(offers, 'id');
    sequenceFlowOffers.forEach((sequenceFlowOffer) => {
      const offerId = sequenceFlowOffer.offersSequence?.offer?.id;
      const recentlyFetchedOffer = offerMap[offerId];

      if (recentlyFetchedOffer) {
        sequenceFlowOffer.offersSequence.offer = recentlyFetchedOffer;
        sequenceFlowOffer.offer = recentlyFetchedOffer;
        sequenceFlowOffer.data = {
          label: renderOffersSequenceNode(recentlyFetchedOffer, sequenceFlowOffer.orderBy, sequenceFlowOffer.dbOrderBy),
        };

        let className = 'offerNode ';
        if (recentlyFetchedOffer.offerType.name === 'Offer Block') {
          className += 'offerBlockOffer';
        } else if (!recentlyFetchedOffer.isActive) {
          className += 'inactiveOffer';
        } else if (
          sequenceFlowOffer.offersSequence.isLayoutOverwritten ||
          sequenceFlowOffer.offersSequence.isContainerOverwritten
        ) {
          className += 'overwrittenOffer';
        }
        sequenceFlowOffer.className = className;
      }
    });

    return sequenceFlowOffers;
  };

  updateManuallyAttachedOffersByPosition = (elements) => {
    const [sequenceFlowEdges, sequenceFlowOffers] = getSequenceFlowOffersAndEdges(elements);
    let sortedFlowOffers = [...sequenceFlowOffers];
    let i = 0;
    while (i < sortedFlowOffers.length - 1) {
      const currentNode = sequenceFlowOffers[i];
      const nextNode = getOutgoers(currentNode, elements)[0];
      // If there is a nextNode but it's not whats next in the sequenceFlowOffers array, that means the user manually connected a new node to the next position of this current node
      if (nextNode && nextNode.id !== sequenceFlowOffers[i + 1].id) {
        sortedFlowOffers = handleOfferReordering(
          sortedFlowOffers,
          [nextNode],
          sortedFlowOffers.indexOf(nextNode),
          i + 1
        );
      }
      i++;
    }
    sortedFlowOffers.forEach((offer, i) => (offer.orderBy = i));
    return sortedFlowOffers.concat(sequenceFlowEdges);
  };

  onConnect = (params) => {
    const { elements } = this.state;
    params.type = 'edgeWithButton';
    params.className = 'osEdge';
    params.style = { strokeWidth: 3 };
    let updatedElements = addEdge({ ...params, type: 'edgeWithButton' }, elements);

    const { orphanedOffers } = this.props;
    console.log(elements);
    updatedElements = this.updateManuallyAttachedOffersByPosition(updatedElements);
    if (orphanedOffers.length) {
      const updatedOrphanedOffers = handleOrphanedOffers(updatedElements);
      const hasMoreThanOneOSHead = hasMultipleStartNodes(updatedElements);
      updatedElements = updateOrphanedOffersState(updatedOrphanedOffers, updatedElements);
      this.props.updateOrphanedOffers(updatedOrphanedOffers, hasMoreThanOneOSHead);
    }
    console.log(updatedElements);

    this.setState({ elements: updatedElements });
  };

  onElementsRemove = (elementsToRemove) => {
    let elementToBeSelected;

    for (let i = 0; i < elementsToRemove.length; i++) {
      const element = elementsToRemove[i];
      if (elementsToRemove[i].data) {
        const [existingNode] = getNodeAndPosition(element, this.state.elements);
        if (existingNode.isHiddenPlaceholder) return;

        if (getIncomers(existingNode, this.state.elements)) {
          elementToBeSelected = getIncomers(existingNode, this.state.elements)[0];
        }
      }
    }

    let updatedElements = removeElements(elementsToRemove, this.state.elements);

    if (elementsToRemove.length > 1) {
      // elementsToRemove would have a length of 1 if we're only trying to remove a connection
      let [, sequenceFlowOffers] = getSequenceFlowOffersAndEdges(updatedElements);
      sequenceFlowOffers = updateSequenceFlowOfferWithOSDetails(sequenceFlowOffers);
      const [, reactFlowOffersAndEdges] = handleFlowOffersInit(
        sequenceFlowOffers,
        this.state.compactLayout,
        this.props.sequenceOrderBy
      );
      updatedElements = reactFlowOffersAndEdges;
    }

    if (this.props.orphanedOffers.length) {
      const updatedOrphanedOffers = handleOrphanedOffers(updatedElements);
      const hasMoreThanOneOSHead = hasMultipleStartNodes(updatedElements);
      updatedElements = updateOrphanedOffersState(updatedOrphanedOffers, updatedElements);
      this.props.updateOrphanedOffers(updatedOrphanedOffers, hasMoreThanOneOSHead);
    }

    if (elementToBeSelected) {
      updatedElements = updatedElements.map((el) => {
        if (el?.id === elementToBeSelected?.id) {
          el.className = el.className.includes('selectedElement')
            ? el.className.replace('selectedElement', '')
            : 'selectedElement ' + el.className;
        }
        return el;
      });
    }
    this.props.updateSelectedElement(elementToBeSelected);
    this.setState({ elements: updatedElements });
  };

  onLoad = (_reactFlowInstance) => {
    this.setReactFlowInstanceInRedux(_reactFlowInstance);
  };

  onDragOver = (event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';

    const reactFlowBounds = this.state.reactFlowWrapper.current.getBoundingClientRect();

    const position = this.props.reactFlowInstance.project({
      x: event.clientX - reactFlowBounds.left - 50,
      y: event.clientY - reactFlowBounds.top - 50,
    });

    const newNode = {
      id: null,
      offerType: null,
      name: null,
      position,
      data: null,
      className: `offerNode`,
    };
    this.handleNodeSelection(event, newNode);
  };

  onDrop = async (event) => {
    event.preventDefault();
    event.persist();
    let { elements, shouldHideInactiveOffers } = this.state;

    const offerId = parseInt(event.dataTransfer.getData('text/plain'));
    const offerBlockOfferIdsJson = event.dataTransfer.getData('application/json');
    const offerBlockOfferIds = offerBlockOfferIdsJson ?? JSON.parse(offerBlockOfferIdsJson);
    const sameOffersInSequenceCount = this.state.elements.reduce((result, element) => {
      if (offerBlockOfferIds) {
        if (element?.offer?.id === offerId || offerBlockOfferIds.includes(element?.offer?.id)) {
          return result + 1;
        }
      } else {
        if (element?.offer?.id === offerId) {
          return result + 1;
        }
        if (element?.offerType?.name === 'Offer Block') {
          const offerBlock = this.props.offers.find((offer) => offer.id === element.offer.id);
          const existingOfferIds = offerBlock.offerBlock.offerBlockOffers.map((offer) => offer.offer.id);
          if (existingOfferIds.includes(offerId)) {
            return result + 1;
          }
        }
      }
      return result;
    }, 0);
    const draggedOffer = _.find(this.props.filteredOffers, (offer) => offer.id === offerId);

    if (sameOffersInSequenceCount > 0) {
      this.setState({
        sameOffersInSequenceCount,
        sameOffersInSequenceName: offerBlockOfferIds
          ? 'At least one of the offers in this Offer Block'
          : `[${offerId}: ${draggedOffer.name}]`,
      });
      const canceled = await this.handleShow(); //We wait for the user to Accept/Cancel
      if (canceled) {
        this.setState({ elements: clearDraggedOfferOutlines(elements) });
        return;
      }
    }
    const reactFlowBounds = this.state.reactFlowWrapper.current.getBoundingClientRect();

    const position = this.props.reactFlowInstance.project({
      x: event.clientX - reactFlowBounds.left - 50,
      y: event.clientY - reactFlowBounds.top - 50,
    });

    const i = this.state.elements.filter((element) => element.data).length;
    const newNode = {
      id: `${draggedOffer.id}-${i}`,
      offerType: draggedOffer.offerType,
      name: draggedOffer.name,
      offer: draggedOffer,
      position,
      data: { label: renderOffersSequenceNode(draggedOffer, i) },
      className: `offerNode ${
        draggedOffer?.offerType.name === 'Offer Block'
          ? 'offerBlockOffer'
          : !draggedOffer.isActive
          ? 'inactiveOffer'
          : null
      }`,
    };

    let { orphanedOffers } = this.props;
    let updatedElements = elements.concat(newNode);
    updatedElements = handleOffersSequenceReordering({
      elements: updatedElements,
      draggedNodes: [newNode],
      updateOrphanedOffersProps: this.props.updateOrphanedOffers,
      shouldHideInactiveOffers,
      compactLayout: this.state.compactLayout,
      orphanedOffers,
      sequenceOrderBy: this.props.sequenceOrderBy,
    });

    this.setState({ elements: updatedElements });
  };

  handleNodeSelection = (event, element) => {
    let { elements, selectedElements } = this.state;
    let selectedOfferId = null;
    let selectedOffer = { id: null };
    if (element.id) {
      [selectedOfferId] = element.id.split('-');
      selectedOffer = _.find(this.props.offers, (offer) => offer.id === parseInt(selectedOfferId));
    }

    if (element.hasOwnProperty('target')) {
      if (!event.defaultPrevented) {
        // If it's just an edge, return dont proceed
        return;
      } else {
        // User clicked the x button to remove an edge
        const updatedElements = removeElements([element], elements);
        this.setState({ elements: updatedElements });
        return;
      }
    }

    let selectedElement = { id: null };

    const draggedNodeHeight = 35;
    const draggedNodeWidth = 60;

    const topOfferElement = findTopOfferElement(elements, element, draggedNodeHeight, draggedNodeWidth);
    const bottomOfferElement = findBottomOfferElement(elements, element, draggedNodeHeight, draggedNodeWidth);

    if (selectedElements && selectedElements.length === 1) {
      // Add dynamic class to the element above or below the dragging node
      if (topOfferElement) {
        if (!topOfferElement.className.includes('topOfferElement')) {
          topOfferElement.className += ' topOfferElement';
        }
      }
      if (bottomOfferElement) {
        if (!bottomOfferElement.className.includes('bottomOfferElement')) {
          bottomOfferElement.className += ' bottomOfferElement';
        }
      }
    }

    let updatedElementsWithClasses = elements.map((el) => {
      if (el.hasOwnProperty('target')) {
        // If it's just an edge, return the element
        return el;
      }

      // Remove dynamic class to the elements outside the range of the dragging node
      if (!topOfferElement || topOfferElement.id !== el.id) {
        el.className = el.className.replace('topOfferElement', '').trim();
      }
      if (!bottomOfferElement || bottomOfferElement.id !== el.id) {
        el.className = el.className.replace('bottomOfferElement', '').trim();
      }

      // Add or remove class for the clicked node to display green border
      if (el.id === element.id) {
        selectedElement = el;
        selectedElement.offer = selectedOffer;
      }

      return el;
    });

    this.setState({ selectedOffer, selectedElement, elements: updatedElementsWithClasses });
    this.props.updateSelectedElement(selectedElement);
  };

  handleSelectionDrag = (_event, draggedNodes) => {
    let { elements } = this.state;

    if (!draggedNodes || draggedNodes.length === 0) {
      return;
    }

    const draggedNodeHeight = 35;
    const draggedNodeWidth = 60;

    const topOfferElement = findTopOfferElement(elements, draggedNodes[0], draggedNodeHeight, draggedNodeWidth);
    const bottomOfferElement = findBottomOfferElement(
      elements,
      draggedNodes[draggedNodes.length - 1],
      draggedNodeHeight,
      draggedNodeWidth
    );

    // Add dynamic class to the element above or below the dragging node
    if (topOfferElement) {
      if (!topOfferElement.className.includes('topOfferElement')) {
        topOfferElement.className += ' topOfferElement';
      }
    }
    if (bottomOfferElement) {
      if (!bottomOfferElement.className.includes('bottomOfferElement')) {
        bottomOfferElement.className += ' bottomOfferElement';
      }
    }

    let updatedElementsWithClasses = elements.map((el) => {
      if (el.hasOwnProperty('target')) {
        // If it's just an edge, return the element
        return el;
      }

      draggedNodes.forEach((draggedNode, i) => {
        if (i !== 0 && el.id === draggedNode.id) {
          el.className = el.className.replace('topOfferElement', '').trim();
          el.className = el.className.replace('bottomOfferElement', '').trim();
        }
      });
      // Remove dynamic class to the elements outside the range of the dragging node
      if (!topOfferElement || topOfferElement.id !== el.id) {
        el.className = el.className.replace('topOfferElement', '').trim();
      }
      if (!bottomOfferElement || bottomOfferElement.id !== el.id) {
        el.className = el.className.replace('bottomOfferElement', '').trim();
      }

      return el;
    });

    this.setState({ elements: updatedElementsWithClasses });
  };

  handleNodeDragStop = (_event, draggedNode) => {
    let { elements, shouldHideInactiveOffers, selectedElements } = this.state;
    let { orphanedOffers } = this.props;

    if (selectedElements && selectedElements.length !== 1) {
      return;
    }

    elements.forEach((element) => {
      if (element.id === draggedNode.id) {
        element.position = draggedNode.position;
      }
    });

    const updatedElements = handleOffersSequenceReordering({
      elements,
      draggedNodes: [draggedNode],
      updateOrphanedOffersProps: this.props.updateOrphanedOffers,
      shouldHideInactiveOffers,
      compactLayout: this.state.compactLayout,
      orphanedOffers,
      sequenceOrderBy: this.props.sequenceOrderBy,
    });

    this.setState({ elements: updatedElements });
  };

  handleShowInactiveOffers = (shouldHideInactiveOffers) => {
    this.setState({
      shouldHideInactiveOffers: !shouldHideInactiveOffers,
    });

    let [sequenceFlowEdges, sequenceFlowOffers] = getSequenceFlowOffersAndEdges(this.state.elements);
    sequenceFlowOffers = sortOfferSequences(sequenceFlowOffers);
    [sequenceFlowEdges, sequenceFlowOffers] = updateOffersWithHiddenAttr(
      sequenceFlowOffers,
      !shouldHideInactiveOffers,
      this.state.compactLayout
    );
    this.setState({ elements: sequenceFlowOffers.concat(sequenceFlowEdges) });
  };

  handleCompactLayout = (compactLayout) => {
    this.setState({
      compactLayout: !compactLayout,
    });
    let [sequenceFlowEdges, sequenceFlowOffers] = getSequenceFlowOffersAndEdges(this.state.elements);
    sequenceFlowOffers = sortOfferSequences(sequenceFlowOffers);
    [sequenceFlowEdges, sequenceFlowOffers] = updateOffersWithHiddenAttr(
      sequenceFlowOffers,
      this.state.shouldHideInactiveOffers,
      !compactLayout
    );
    console.log('sequenceFlowOffers', sequenceFlowOffers);
    this.setState({ elements: sequenceFlowOffers.concat(sequenceFlowEdges) });
  };

  handleSelectionChanged = (selectedElements) => {
    let { elements } = this.state;
    if (elements.length !== 0 && (!selectedElements || selectedElements.length === 0)) {
      let updatedElements = elements.map((el) => {
        if (el.hasOwnProperty('target')) {
          // If it's just an edge, return the element
          return el;
        }

        return el;
      });

      this.setState({ elements: updatedElements });
    }
  };

  handleSelectionDragStop = (_event, draggedNodes) => {
    let { elements, shouldHideInactiveOffers } = this.state;
    let { orphanedOffers } = this.props;
    elements.forEach((element) => {
      if (element.id === draggedNodes[0].id) {
        element.position = draggedNodes[0].position;
      }
    });

    const updatedElements = handleOffersSequenceReordering({
      elements,
      draggedNodes,
      updateOrphanedOffersProps: this.props.updateOrphanedOffers,
      shouldHideInactiveOffers,
      compactLayout: this.state.compactLayout,
      orphanedOffers,
      sequenceOrderBy: this.props.sequenceOrderBy,
    });

    this.setState({ elements: updatedElements });
  };

  setReactFlowInstanceInRedux = (reactFlowInstance) => {
    this.props.setReactFlowInstance(reactFlowInstance);
  };

  handleShow = () => {
    this.setState({ showConfirmationModal: true });
    var that = this;

    return new Promise(function(resolve) {
      that.setState({ resolver: resolve });
    });
  };

  handleConfirm = () => {
    this.state.resolver && this.state.resolver(false);
    this.setState({ showConfirmationModal: false });
  };

  handleCancel = () => {
    this.state.resolver && this.state.resolver(true);
    this.setState({ showConfirmationModal: false });
  };

  render() {
    return (
      <>
        <div className='animated'>
          <Modal isOpen={this.state.showConfirmationModal} toggle={this.handleCancel} className={'modal-info'}>
            <ModalHeader toggle={this.handleCancel}>{'Confirm Action'}</ModalHeader>
            <ModalBody>
              <h5>
                {`${this.state.sameOffersInSequenceName} is already placed in this sequence ${this.state.sameOffersInSequenceCount} time(s).`}
              </h5>
              <br />
              <h6>Would you like to add it again?</h6>
            </ModalBody>
            <ModalFooter>
              <Button color='success' onClick={this.handleConfirm}>
                Yes
              </Button>{' '}
              <Button color='secondary' onClick={this.handleCancel}>
                No
              </Button>
            </ModalFooter>
          </Modal>
        </div>
        <ReactFlowProvider>
          <div className='reactflow-wrapper' ref={this.state.reactFlowWrapper}>
            <ReactFlow
              elements={this.state.elements}
              edgeTypes={this.state.edgeTypes}
              onConnect={this.onConnect}
              onElementsRemove={this.onElementsRemove}
              onLoad={this.onLoad}
              onDrop={this.onDrop}
              onNodeDrag={this.handleNodeSelection}
              onNodeDragStop={this.handleNodeDragStop}
              onElementClick={this.handleNodeSelection}
              onDragOver={this.onDragOver}
              panOnScroll={true}
              onSelectionChange={this.handleSelectionChanged}
              onSelectionDragStop={this.handleSelectionDragStop}
              onSelectionDrag={this.handleSelectionDrag}
            >
              <Controls>
                <ControlButtonWithTooltip
                  onClick={() => this.handleCompactLayout(this.state.compactLayout)}
                  tooltipText='Compact Layout'
                  iconClassName='fa fa-random'
                  buttonId='compactLayoutButton'
                />
                <ControlButtonWithTooltip
                  onClick={() => this.handleShowInactiveOffers(this.state.shouldHideInactiveOffers)}
                  tooltipText='Show/Hide Inactive Offers'
                  iconClassName='fa fa-eye-slash'
                  buttonId='showInactiveOffersButton'
                />
              </Controls>
              <SelectedElementsUpdater
                setSelectedElements={(selectedElements) => this.setState({ selectedElements })}
              />
            </ReactFlow>
          </div>
        </ReactFlowProvider>
      </>
    );
  }
}

function SelectedElementsUpdater(props) {
  const selectedElements = useStoreState((store) => store.selectedElements);

  React.useEffect(() => {
    props.setSelectedElements(selectedElements);
  }, [selectedElements]);

  return null;
}

function mapStateToProps(state) {
  const { orphanedOffers, reactFlowInstance } = state.sequence;
  return { orphanedOffers, reactFlowInstance };
}

export default connect(mapStateToProps, {
  setReactFlowInstance,
  updateSelectedElement,
  updateOrphanedOffers,
})(withRouter(DnDFlow));
