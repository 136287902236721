import { Button, Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { getContainersTableApi } from '../Metadata/Containers/actions';
import ContainerTable from '../../components/Table/hookTable';
import { columnFormatter } from '../../utils/tableFunctions';
import api from '../../services';
import _ from 'lodash';
import ChangelogModal from '../../components/ChangelogModal/ChangelogModal';
import format from 'date-fns/format';

const ContainersList = () => {
  const [containers, setContainers] = useState([]);
  const [filteredContainers, setFilteredContainers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [offerTypes, setOfferTypes] = useState();
  const [searchTerm, setSearchTerm] = useState('');

  const URL = '/content/containers/';
  const columns = [
    {
      dataField: 'id',
      text: 'ID',
      sort: true,
      headerStyle: {
        backgroundColor: '#2f353a',
      },
      formatter: (cell, row) => {
        return columnFormatter({
          cell,
          row,
          computedURL: URL + row.id,
        });
      },
    },
    {
      dataField: 'name',
      text: 'Name',
      sort: true,
      headerStyle: {
        backgroundColor: '#2f353a',
      },
      formatter: (cell, row) => {
        return columnFormatter({
          cell,
          row,
          computedURL: URL + row.id,
        });
      },
    },
    {
      dataField: 'offerTypeHint',
      text: 'Offer Type ID',
      sort: true,
      headerStyle: {
        backgroundColor: '#2f353a',
      },
      formatter: (cell) => resolveOfferTypeName(offerTypes, cell),
    },
    {
      dataField: 'zuulUser',
      text: 'Last Published',
      sort: true,
      headerStyle: {
        backgroundColor: '#2f353a',
      },
      formatter: (_cell, row) => {
        return row?.zuulUser?.realname
          ? format(new Date(row.lastPublished), 'Pp') + ' by ' + row?.zuulUser?.realname
          : format(new Date(row.lastPublished), 'Pp');
      },
    },
    {
      dataField: 'changelog',
      text: 'Audit',
      isDummyField: true,
      formatter: (_cell, row) => (
        <Row>
          <Col lg={12}>
            <ChangelogModal typeName='Container' apiPath='containers' id={row.id} />
          </Col>
        </Row>
      ),
      headerStyle: {
        backgroundColor: '#2f353a',
        textAlign: 'center',
      },
      searchable: false,
    },
  ];

  const filterContainers = (searchTerm, allContainers) => {
    // Split the term by space and remove empty spaces
    let splitSearchTerm = searchTerm.split(/[ ,]+/).filter(Boolean);
    const filteredContainers = allContainers.filter((container) => {
      const parsedContainer = {
        id: container.id + '' || '',
        name: container.name || '',
        offerTypeHint: resolveOfferTypeName(offerTypes, container.offerTypeHint),
      };
      let doesMatchTerms = true;
      for (let i = 0; i < splitSearchTerm.length; i++) {
        if (
          !Object.keys(parsedContainer).some((key) =>
            parsedContainer[key].toLowerCase().includes(splitSearchTerm[i].toLowerCase())
          )
        ) {
          doesMatchTerms = false;
        }
      }
      return doesMatchTerms;
    });
    setFilteredContainers(filteredContainers);
  };

  const handleInputChange = (event) => {
    const searchTerm = event.target.value;
    // This prevents the user from calling the search function too many times in less than a second, helps with performance
    const throttledSearch = _.throttle(filterContainers, 500);
    setSearchTerm(searchTerm);
    return throttledSearch(searchTerm, containers);
  };

  useEffect(() => {
    api.get('offertypes').then((response) => {
      setOfferTypes(response.data);
    });

    getContainersTableApi().then((response) => {
      setContainers(response);
      setFilteredContainers(response);
      setLoading(false);
    });
  }, []);

  return (
    <div className='animated fadeIn'>
      <Card>
        <CardHeader>
          <Row>
            <Col lg={9}>
              <i className='icon-menu' />
              &nbsp; Containers
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <Row>
            <Col lg={6}>
              <span className='display-4'>Containers</span>
            </Col>
            <Col lg={6}>
              <Link to={URL + 'create'} style={{ textDecoration: 'none', color: 'inherit' }}>
                <Button color='primary' className='float-right'>
                  <i className='fa fa-plus' /> Create Container
                </Button>
              </Link>
            </Col>
          </Row>
          <br />
          {offerTypes && containers && (
            <ContainerTable
              entity={{
                entityName: 'Container',
                columns,
                data: filteredContainers,
              }}
              handleSearchChange={handleInputChange}
              loading={loading}
              searchTerm={searchTerm}
            />
          )}
        </CardBody>
      </Card>
    </div>
  );
};

export const resolveOfferTypeName = (offerTypes, offerTypeId) => {
  const found = offerTypes.find((ot) => ot.id === offerTypeId);
  return found ? found.name : '';
};

export default ContainersList;
