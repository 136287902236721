import React from 'react';
import { Field } from 'react-final-form';
import { Col, Row, TabPane } from 'reactstrap';
import states from '../../../utils/states';
import {
  useAibList,
  useBrowsers,
  useDevices,
  useDomains,
  useOperatingSystems,
} from '../../../views/OfferBlocks/queries';
import { textarea } from '../../../views/Offers/OfferTitle/renderConsts';
import { ColCondition, MultiselectField, NumberField, SelectRow, ToggleSwitchRow } from '../../FinalForm';
import _ from 'lodash';
import { PreviousRegistration } from './PreviousRegistration';

const UsersPane = ({ collapse, allDomains, source }) => {
  const generateName = (field) => `${source}.${field}`;

  const { data: domains, isLoading: domainsLoading } = useDomains(true, (data) =>
    data.map((domain) => ({ id: domain.id, name: domain.name })).sort((a, b) => (a > b ? 1 : -1))
  );

  let domainsForPreviousRegReq = domains ? [...domains] : [];
  domainsForPreviousRegReq.unshift({ name: 'Current Domain', id: null });
  domainsForPreviousRegReq.unshift({ name: 'All Domains', id: null });

  const { data: devices } = useDevices(collapse, (data) =>
    data.map((device) => device.name).sort((a, b) => (a > b ? 1 : -1))
  );
  const { data: operatingSystems } = useOperatingSystems(collapse, (data) =>
    data.map((operatingSystem) => operatingSystem.name).sort((a, b) => (a > b ? 1 : -1))
  );
  const { data: browsers } = useBrowsers(collapse, (data) =>
    data.map((browser) => browser.name).sort((a, b) => (a > b ? 1 : -1))
  );
  const { data: aibLists } = useAibList(collapse, (data) =>
    data.map((aibList) => aibList.listCode).sort((a, b) => (a > b ? 1 : -1))
  );

  return (
    <TabPane tabId='Users'>
      {source !== 'publisherPrePingCriteria' && (
        <ToggleSwitchRow label='TCPA Opt-in Required' name={generateName('criteriaOfferRequiresOptIn')} />
      )}
      <ToggleSwitchRow
        label='TCPA Opt-in Required Over Last Interval'
        name={generateName('criteriaTcpaLastOptInInterval')}
      />
      <ToggleSwitchRow
        label='Brite Verify Phone Validated'
        name={generateName('criteriaPhoneHasBeenVerifiedWithBriteverify')}
      />
      <ToggleSwitchRow
        label='Brite Verify Email Validated'
        name={generateName('criteriaEmailHasBeenVerifiedWithBriteverify')}
      />
      <ToggleSwitchRow label='Include users in the phone BlackList' name={generateName('criteriaPhoneBlacklist')} />
      <Row>
        <Col lg={2}>
          <NumberField name={generateName('criteriaMinAge')} label='Min Age' />
        </Col>
        <Col lg={2}>
          <NumberField name={generateName('criteriaMaxAge')} label='Max Age' />
        </Col>
      </Row>
      {source !== 'publisherPrePingCriteria' && (
        <SelectRow
          name={generateName('criteriaExcludeBrowserType')}
          label='Exclude Browser Type'
          options={[
            { value: '', option: 'Select' },
            { value: 'Desktop', option: 'Desktop' },
            { value: 'Mobile', option: 'Mobile' },
          ]}
        />
      )}
      <SelectRow
        name={generateName('criteriaExcludeGender')}
        label='Exclude Gender'
        options={[
          { value: '', option: 'Select' },
          { value: 'female', option: 'Female' },
          { value: 'male', option: 'Male' },
        ]}
      />
      <Row style={{ marginTop: '1rem' }}>
        <Col lg={2}>
          <h5>Required User Data</h5>
        </Col>
        <Col lg={4}>
          <MultiselectField
            data={['email', 'first_name', 'last_name', 'gender', 'dob', 'phone', 'zip', 'state', 'city', 'address_1']}
            name={generateName('criteriaRequiredUserData')}
            parse={(value) => {
              if (source === 'publisherPrePingCriteria') {
                // Handle value as a string array
                return value;
              } else {
                // Handle value as a concatenated string
                return value.join(',');
              }
            }}
            format={(value) => {
              if (source === 'publisherPrePingCriteria') {
                // Handle value as a string array
                return Array.isArray(value) ? value : [];
              } else {
                // Handle value as a concatenated string
                if (!value) {
                  return [];
                } else if (typeof value !== 'string') {
                  return [value];
                }
                return value.split(',');
              }
            }}
          />
        </Col>
      </Row>
      <PreviousRegistration
        domainsForPreviousRegReq={source === 'publisherPrePingCriteria' ? allDomains : domainsForPreviousRegReq}
        source={source}
        isLoading={domainsLoading}
      />
      {source !== 'publisherPrePingCriteria' && (
        <>
          <SelectRow name={generateName('criteriaNullSourceNewUserIncludeExclude')} label='Null Source New Users' />
          <SelectRow name={generateName('criteriaAibListIncludeExclude')} label='AIB List'>
            <ColCondition when={generateName('criteriaAibListIncludeExclude')}>
              <MultiselectField data={aibLists} name={generateName('criteriaAibList')} />
            </ColCondition>
          </SelectRow>
        </>
      )}
      <SelectRow label='User_ID last digit' name={generateName('criteriaIncludeOrExcludeUserIdLastDigit')}>
        <ColCondition when={generateName('criteriaIncludeOrExcludeUserIdLastDigit')}>
          <MultiselectField data={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9]} name={generateName('criteriaUserIdLastDigit')} />
        </ColCondition>
      </SelectRow>
      {source !== 'publisherPrePingCriteria' && (
        <>
          <SelectRow label='Device' name={generateName('criteriaIncludeOrExcludeDevices')}>
            <ColCondition when={generateName('criteriaIncludeOrExcludeDevices')}>
              <MultiselectField data={devices} name={generateName('criteriaDevices')} />
            </ColCondition>
          </SelectRow>
          <SelectRow label='Browsers' name={generateName('criteriaIncludeOrExcludeBrowsers')}>
            <ColCondition when={generateName('criteriaIncludeOrExcludeBrowsers')}>
              <MultiselectField data={browsers} name={generateName('criteriaBrowsers')} />
            </ColCondition>
          </SelectRow>
          <SelectRow label='What If Domain' name={generateName('criteriaIncludeOrExcludeDomains')}>
            <ColCondition when={generateName('criteriaIncludeOrExcludeDomains')}>
              <MultiselectField
                data={domains}
                name={generateName('criteriaDomains')}
                textField={(item) => `${item.id}: ${item.name}`}
                dataKey='name'
                busy={domainsLoading}
              />
            </ColCondition>
          </SelectRow>
          <SelectRow label='Operating System' name={generateName('criteriaIncludeOrExcludeOperatingSystems')}>
            <ColCondition when={generateName('criteriaIncludeOrExcludeOperatingSystems')}>
              <MultiselectField data={operatingSystems} name={generateName('criteriaOperatingSystems')} />
            </ColCondition>
          </SelectRow>
        </>
      )}
      <SelectRow label='States' name={generateName('criteriaIncludeOrExcludeStates')}>
        <ColCondition when={generateName('criteriaIncludeOrExcludeStates')}>
          <MultiselectField data={states} name={generateName('criteriaStates')} />
        </ColCondition>
      </SelectRow>
      <SelectRow label='Zip Code' name={generateName('criteriaIncludeOrExcludeZips')}>
        <ColCondition when={generateName('criteriaIncludeOrExcludeZips')}>
          <Field name={generateName('criteriaZips')} component={textarea} />
        </ColCondition>
      </SelectRow>
    </TabPane>
  );
};

export default UsersPane;
