import { useQuery } from '@tanstack/react-query';
import api from '../../services';

const fetchUserAttributes = async () => {
  try {
    const response = await api.get('user-attribute');
    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};
export const useUserAttributesTable = () =>
  useQuery({
    queryKey: ['userAttributesTable'],
    queryFn: fetchUserAttributes,
    refetchOnWindowFocus: false,
  });
