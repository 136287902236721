import React, {Component} from 'react';
import {Field, reduxForm} from 'redux-form';
import {connect} from 'react-redux';
import {Button, Col, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row} from 'reactstrap';
import {renderDropdownList, renderField, textarea} from '../Offers/OfferTitle/renderConsts';
import states from '../../utils/states';
import {updateAdvertiserForm, updateSelector} from '../../actions';
import './Advertisers.css';
import './ARIAAdvertiserOverrides.css';
import {CreatableAriaAdvertiserSelectComponent} from "@aria/aria-mapping-ui";
import {ARIA_API_BASE_URL, ARIA_API_KEY} from '../../utils/ariaApiHelper';

class AdvertiserModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      areAdditionalFieldsHidden: true
    };
    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit(values) {
    this.props.toggleModal();

    const ariaAdvertiserId = this.state.ariaAdvertiserId.value;
    values= { ...values, ariaAdvertiserId}

    this.props.onClickSubmit(values, false);
  }

  setAriaAdvertiserId = (value) => {
    console.log(value)
    this.setState({
      ariaAdvertiserId: value
    })
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.isModalOpen === true && nextProps.isModalOpen === false) {
      for (let key in this.props.selectedAdvertiser) {
        this.props.change(`${key}`, null);
      }
    }
  }

  handleAdditionalFieldsToggle = () => {
    this.setState({
      areAdditionalFieldsHidden: !this.state.areAdditionalFieldsHidden
    });
  };

  componentDidMount() {
    this.props.updateSelector('advertiserForm');
    for (let key in this.props.advertiser) {
      this.props.change(`${key}`, this.props.advertiser[key]);
    }
  }

  renderAdvertiserForm = () => {
    const { advertiserName, handleSubmit, anyTouched } = this.props;

    return (
      <form onSubmit={handleSubmit(this.onSubmit)}>
        <ModalBody>
          <div className='colorTest'>
            <Row>
              <Col lg={6}>
                <br />
                <Label>Advertiser Name</Label>
                <Field
                  name='name'
                  required
                  component={renderField}
                  placeholder='Advertiser'
                />
              </Col>
              <Col lg={6}>
                <br />
                <Label>URL</Label>
                <Field
                  required
                  type='text'
                  component={renderField}
                  name='url'
                  placeholder='URL'
                />
              </Col>
            </Row>
            <br />
            <Row>
              <Col lg={12}>
                <Label>ARIA Advertiser</Label>
                <CreatableAriaAdvertiserSelectComponent
                  setParentIdValue={this.setAriaAdvertiserId}
                  isDisabled={true}
                  apiBaseUrl={ARIA_API_BASE_URL} apiKey={ARIA_API_KEY}
                  initialValue={this.props.advertiser.ariaAdvertiserId} />
              </Col>
            </Row>
            <br />
            <Row>
              <Col lg={12}>
                <Label>Notes</Label>
                <Field
                  name='notes'
                  component={textarea}
                  rows='10'
                  type='text'
                />
              </Col>
            </Row>
            <br />
            <Row>
              <Col lg={12}>
                <Button
                  className='float-left'
                  onClick={this.handleAdditionalFieldsToggle}
                >
                  <em>Additional Fields</em>
                </Button>
              </Col>
            </Row>
            <br />
            {!this.state.areAdditionalFieldsHidden && (
              <>
                <Row>
                  <Col lg={6}>
                    <div>
                      <Field
                        type='text'
                        component={renderField}
                        name='contactFirstName'
                        placeholder='Contact First Name'
                        label='Contact First Name'
                      />
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div>
                      <Field
                        type='text'
                        component={renderField}
                        name='contactLastName'
                        placeholder='Contact Last Name'
                        label='Contact Last Name'
                      />
                    </div>
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col lg={6}>
                    <div>
                      <Field
                        required
                        type='email'
                        component={renderField}
                        name='contactEmail'
                        placeholder='Email'
                        label='Contact Email'
                      />
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div>
                      <Field
                        required
                        type='text'
                        component={renderField}
                        name='contactPhone'
                        placeholder=' Phone'
                        label='Contact Phone'
                      />
                    </div>
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col lg={6}>
                    <div>
                      <Field
                        required
                        type='text'
                        component={renderField}
                        name='address1'
                        placeholder='Address 1'
                        label='Address 1'
                      />
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div>
                      <Field
                        required
                        type='text'
                        component={renderField}
                        name='address2'
                        placeholder='Address 2'
                        label='Address 2'
                      />
                    </div>
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col lg={3}>
                    <div>
                      <Field
                        required
                        type='text'
                        component={renderField}
                        name='city'
                        placeholder='City'
                        label='City'
                      />
                    </div>
                  </Col>
                  <Col lg={3}>
                    <div>
                      <Label>State</Label>
                      <Field
                        required
                        type='select'
                        component={renderDropdownList}
                        data={states}
                        name='state'
                        placeholder='State'
                      />
                    </div>
                  </Col>
                  <Col lg={3}>
                    <div>
                      <Field
                        required
                        type='text'
                        component={renderField}
                        name='zip'
                        placeholder='Zip'
                        label='Zip'
                      />
                    </div>
                  </Col>
                  <Col lg={3}>
                    <div>
                      <Field
                        required
                        type='text'
                        component={renderField}
                        name='country'
                        placeholder='Country'
                        label='Country'
                      />
                    </div>
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col lg={4}>
                    <div>
                      <Field
                        required
                        type='text'
                        component={renderField}
                        name='phone1'
                        placeholder='Phone 1'
                        label='Phone 1'
                      />
                    </div>
                  </Col>
                  <Col lg={4}>
                    <div>
                      <Field
                        required
                        type='text'
                        component={renderField}
                        name='phone2'
                        placeholder='Phone 2'
                        label='Phone 2'
                      />
                    </div>
                  </Col>
                  <Col lg={4}>
                    <div>
                      <Field
                        required
                        type='text'
                        component={renderField}
                        name='phone3'
                        placeholder='Phone 3'
                        label='Phone 3'
                      />
                    </div>
                  </Col>
                </Row>
              </>
            )}
            <br />
          </div>
        </ModalBody>
        <ModalFooter>
          {anyTouched && advertiserName && (
            <Button color='success'>Save</Button>
          )}
          <Button color='secondary' onClick={this.props.toggleModal}>
            Cancel
          </Button>
        </ModalFooter>
      </form>
    );
  };

  render() {
    const { isModalOpen, toggleModal, action } = this.props;
    return (
      <div className='animated'>
        &nbsp;&nbsp;&nbsp;&nbsp;
        <Modal isOpen={isModalOpen} toggle={toggleModal}>
          <ModalHeader toggle={toggleModal}>
            {action} Edit Advertiser
          </ModalHeader>
          {this.renderAdvertiserForm()}
        </Modal>
      </div>
    );
  }
}

const form = reduxForm({
  form: 'advertiserForm'
});

function mapStateToProps(state) {
  const { selector } = state.advertisers;
  const advertiserName = selector(state, 'name');
  return {
    advertiserName
  };
}

export default connect(mapStateToProps, {
  updateSelector,
  updateAdvertiserForm
})(form(AdvertiserModal));
