import React from 'react';
import ModalButton from './ModalButton';

const DeleteModalButton = ({ onClick }) => (
  <ModalButton
    buttonLabel='Delete'
    className='modal-danger'
    modalHeader='Delete'
    modalBody='Are you sure you want to delete this?'
    onClick={onClick}
  />
);
export default DeleteModalButton;
