import React, { useEffect, useState } from 'react';
import Switch from 'react-switch';
import { Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import './SaveOfferModal.css';

const ActiveStatusModal = ({ isActive: propIsActive, errors: propErrors, getErrors, ...props }) => {
  const [success, setSuccess] = useState(false);
  const [isActive, setIsActive] = useState(propIsActive);

  useEffect(() => {
    setIsActive(propIsActive);
  }, [propIsActive]);

  const toggleSuccess = () => {
    setIsActive(!isActive);
    setSuccess(!success);
  };

  const saveSuccess = () => {
    props.onClickSubmit(isActive);
    setSuccess(!success);
  };

  const renderAppSwitch = (activeStatus) => {
    const statusErrors = propErrors || getErrors();
    const isUrlMissing = statusErrors ? statusErrors.isUrlMissing : false;
    const isUserAttributeMissing = statusErrors ? statusErrors.isUserAttributeMissing : false;
    const isDisabled =
      propIsActive === null || props.disabled === true || isUrlMissing === true || isUserAttributeMissing === true;
    return (
      <Switch
        className={'react-switch'}
        disabled={isDisabled}
        onChange={toggleSuccess}
        checked={activeStatus !== null ? activeStatus : false}
        onColor={'#4dbd74'}
        offColor={'#f86c6b'}
        width={70}
        //  handleDiameter={28}
        checkedIcon={
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '96%',
              fontSize: 11,
            }}
          >
            Active
          </div>
        }
        uncheckedIcon={
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '96%',
              fontSize: 11,
            }}
          >
            Inactive
          </div>
        }
      />
    );
  };

  const modalType = props?.modalType
    ? props.modalType
    : props.content !== null && props.content?.hasOwnProperty('offerActionType')
    ? ' offer action'
    : ' offer';

  return (
    <div className='animated activeStatusModal'>
      {renderAppSwitch(isActive)}
      <Modal isOpen={success} toggle={() => toggleSuccess(true)} className={'modal-danger ' + props.className}>
        <ModalHeader toggle={() => toggleSuccess(true)}>Active Status</ModalHeader>
        <ModalBody>
          <Row>
            <Col
              lg={2}
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                color: '#f86c6b',
              }}
            >
              <i className='fa fa-warning fa-3x'></i>
            </Col>
            <Col lg={10}>
              <h3>{props.content && props.content.name}</h3>
              Are you sure you want to
              {!isActive ? ' deactivate ' : ' activate '} this
              {modalType}?
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color='danger' onClick={saveSuccess}>
            {!isActive ? 'Deactivate ' : 'Activate '}
          </Button>{' '}
          <Button color='secondary' onClick={() => toggleSuccess(true)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ActiveStatusModal;
