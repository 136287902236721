import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import api from '../../services';

export const useCreateAffiliate = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data) => api.post(`affiliates`, data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['affiliates'] });
    },
  });
};

export const useAffiliates = (enabled = true, selectFn = (data) => data) =>
  useQuery({
    queryKey: ['affiliates'],
    queryFn: () => api.get('affiliates').then((res) => res.data),
    enabled,
    select: selectFn,
  });

export const useUpdateAffiliate = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data) => api.put(`affiliates/${data.id}`, data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['affiliates'] });
    },
  });
};

export const useDeleteAffiliate = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (id) => api.delete(`affiliates/${id}`),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['affiliates'] });
    },
  });
};

// Add useAffiliateTags function to fetch tags by type
export const useAffiliateTags = (tagType) =>
  useQuery({
    queryKey: ['tags', tagType],
    queryFn: () => api.get(`/tags/type/${tagType}`).then((res) => res.data),
  });
