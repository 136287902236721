import { format } from 'date-fns';
import React from 'react';
import { Link } from 'react-router-dom';
import 'react-widgets/styles.css';
import { Button, Col, Row } from 'reactstrap';
import LocationsModal from '../../components/LocationsModal/LocationsModal';
import SummaryCard from '../../components/SummaryCard/SummaryCard';
import ToggleActiveModal from './components/ToggleActiveModal';
import { columnDefaults } from '../../utils/tableFunctions';
import { useOfferBlocks } from './queries';

function linkFormatter(cell, row) {
  return (
    <Link to={`/campaigns/offerblocks/${row.id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
      {cell}
    </Link>
  );
}

const columns = [
  {
    dataField: 'id',
    text: 'ID',
    formatter: linkFormatter,
    ...columnDefaults,
  },
  {
    dataField: 'offer.name',
    text: 'Offer Block Name',
    formatter: linkFormatter,
    ...columnDefaults,
  },
  {
    dataField: 'lastPublishedBy',
    text: 'Last Published',
    formatter: linkFormatter,
    sortValue: (cell, row) => new Date(row.publishedAt),
    ...columnDefaults,
  },
  {
    dataField: 'sites',
    text: 'Site Locations',
    isDummyField: true,
    formatter: (_cell, row) => (
      <Row>
        <Col lg={12}>
          <LocationsModal content={row?.offer} />
        </Col>
      </Row>
    ),
    headerStyle: {
      backgroundColor: '#2f353a',
    },
  },
  {
    dataField: 'active',
    text: 'Status',
    formatter: (_cell, row) => (
      <ToggleActiveModal
        content={row.offer}
        isActive={row.offer?.isActive}
        modalType=' Offer Block'
        endpoint={`offers/active/${row.offer?.id}`}
      />
    ),
    ...columnDefaults,
  },
];
const OfferBlocksSummary = () => {
  const { data, isLoading, error } = useOfferBlocks();
  const dataWithFormattedDate = data?.map((offer) => {
    const formattedDate = offer?.publishedAt ? format(new Date(offer.publishedAt), 'Pp') : '';
    const lastPublishedBy =
      offer.zuulUser && formattedDate ? formattedDate + ' by ' + offer.zuulUser.realname : formattedDate;
    return { ...offer, lastPublishedBy };
  });

  return (
    <>
      <SummaryCard
        entity='Offer Blocks'
        createComponent={
          <Link to='/campaigns/offerblocks/create' style={{ textDecoration: 'none', color: 'inherit' }}>
            <Button color='primary' className='float-right'>
              &nbsp; Add Offer Block
            </Button>
          </Link>
        }
        columns={columns}
        defaultSorted={[{ dataField: 'lastPublishedBy', order: 'desc' }]}
        data={dataWithFormattedDate}
        loading={isLoading}
        error={error}
      />
    </>
  );
};

export default OfferBlocksSummary;
