import React, { Component } from 'react';
import { sortByName } from '../../actions';
import api from '../../services';
import { Button, Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import 'react-widgets/styles.css';
import EditTag from './EditTag';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import _ from 'lodash';
import ActiveStatusModal from '../Offers/OfferTitle/ActiveStatusModal';
import { getTagTypes } from './api';
import SearchLabel from '../../components/SearchLabel/SearchLabel';

const defaultTag = {
  name: '',
  type: {},
  metadata: {},
  active: true,
};

class Tags extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeRow: null,
      tags: [],
      advertiserValue: null,
      isAddModalOpen: false,
      isEditModalOpen: false,
      sortedTags: [],
      selectedTag: {},
      defaultTag,
      tagTypes: [],
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.switchHandler = this.switchHandler.bind(this);
    this.toggleAddModal = this.toggleAddModal.bind(this);
  }

  toggleEditModal = () => {
    this.setState({
      isEditModalOpen: !this.state.isEditModalOpen,
    });
  };

  toggleAddModal() {
    this.setState((state) => ({ isAddModalOpen: !state.isAddModalOpen }));
  }

  renderAppSwitch = (row) => {
    return (
      <ActiveStatusModal
        content={row}
        isActive={row.active}
        getErrors={() => this.state.errors || null}
        onClickSubmit={(active) => this.switchHandler(row, active)}
      />
    );
  };

  switchHandler(row, active) {
    if (row.id) {
      let saveData = { ...row, active };
      api.put(`tags/${row.id}`, saveData).then((result) => {
        let jsonResult = result.data;
        console.log(jsonResult);
      });
    }
  }

  onSubmit(values, isNewTag) {
    if (isNewTag) {
      return api.post('tags', values).then((result) => {
        console.log(JSON.stringify(result.data));
        this.getTags();
      });
    } else {
      return api.put(`tags/${this.state.selectedTag.id}`, values).then((result) => {
        console.log(JSON.stringify(result.data));
        this.getTags();
        this.setState({ selectedTag: {} });
      });
    }
  }

  renderTagList = () => {
    const actionFormatter = (cell, row) => {
      return (
        <div>
          <Row>
            <Col lg={12}>{this.renderAppSwitch(row)}</Col>
          </Row>
        </div>
      );
    };
    const { SearchBar } = Search;
    const cellClickHandler = (e, column, columnIndex, row, rowIndex) => {
      const selectedTag = this.state.tags[row.id];
      console.log(selectedTag);
      this.setState({ selectedTag, isEditModalOpen: true });
    };
    const columns = [
      {
        dataField: 'id',
        text: 'Tag ID',
        sort: true,
        sortCaret: (order, column) => {
          if (!order)
            return (
              <span>
                &nbsp;&nbsp;&nbsp;
                <i className='fa fa-sort' />
              </span>
            );
          else if (order === 'asc')
            return (
              <span>
                &nbsp;&nbsp;
                <i className='fa fa-sort' />
              </span>
            );
          else if (order === 'desc')
            return (
              <span>
                &nbsp;&nbsp;
                <i className='fa fa-sort' />
              </span>
            );
          return null;
        },
        events: {
          onClick: cellClickHandler,
        },
        style: { cursor: 'pointer' },

        headerStyle: {
          backgroundColor: '#2f353a',
        },
      },
      {
        dataField: 'type.label',
        text: 'Tag Type',
        sort: true,
        events: {
          onClick: cellClickHandler,
        },
        style: { cursor: 'pointer' },

        headerStyle: {
          backgroundColor: '#2f353a',
        },
      },
      {
        dataField: 'name',
        text: 'Tag Name',
        sort: true,
        events: {
          onClick: cellClickHandler,
        },
        style: { cursor: 'pointer' },

        headerStyle: {
          backgroundColor: '#2f353a',
        },
      },
      {
        dataField: 'active',
        text: 'Action',
        sort: true,
        formatter: actionFormatter,
        headerStyle: {
          backgroundColor: '#2f353a',
        },
      },
    ];

    return (
      <div>
        <Row>
          <Col lg={9}>
            <span className='display-4'>Tags</span>
          </Col>
          <Col lg={3}>
            {this.state.tagTypes.length !== 0 && (
              <Button color='primary' onClick={this.toggleAddModal} style={{ float: 'right' }}>
                <i className='fa fa-plus' /> Add Tag
              </Button>
            )}
            {this.state.isAddModalOpen && (
              <EditTag
                title='Add Tag'
                tag={this.state.defaultTag}
                isModalOpen={this.state.isAddModalOpen}
                tagTypes={this.state.tagTypes}
                toggleModal={this.toggleAddModal}
                onClickSubmit={this.onSubmit}
              />
            )}
          </Col>
        </Row>
        <br />
        <ToolkitProvider keyField='id' data={this.state.sortedTags} columns={columns} dataSort search bootstrap4={true}>
          {(props) => (
            <div>
              <Row>
                <Col lg={9}>
                  <SearchLabel />
                  <br />
                  <SearchBar {...props.searchProps} tableId='Tags' />
                </Col>
              </Row>
              <br />
              <BootstrapTable hover filter={filterFactory()} {...props.baseProps} pagination={paginationFactory()} />
            </div>
          )}
        </ToolkitProvider>
      </div>
    );
  };

  getTags() {
    return new Promise((resolve) => {
      api
        .get('tags')
        .then((response) => {
          const tags = _.mapKeys(response.data, 'id');
          const sortedTags = sortByName(response.data);
          const newState = Object.assign({}, this.state, {
            sortedTags,
            tags,
          });
          this.setState(newState);
          resolve(newState);
        })
        .catch((error) => console.log(error));
    });
  }

  async componentDidMount() {
    this.getTags();
    try {
      const tagTypes = await getTagTypes();
      this.setState({
        tagTypes,
        defaultTag: {
          ...defaultTag,
          type: tagTypes[0],
        },
      });
    } catch (e) {
      console.error(e);
    }
  }

  render() {
    return (
      <div className='animated fadeIn'>
        <Card>
          <CardHeader>
            <i className='icon-menu' /> Tags
          </CardHeader>
          <CardBody>
            <Row>
              <Col lg='12'>{this.renderTagList()}</Col>
            </Row>
            <Row>
              <Col lg='12'>
                {this.state.isEditModalOpen && (
                  <EditTag
                    title='Edit Tag'
                    tag={this.state.selectedTag}
                    isModalOpen={this.state.isEditModalOpen}
                    tagTypes={this.state.tagTypes}
                    toggleModal={this.toggleEditModal}
                    onClickSubmit={this.onSubmit}
                  />
                )}
              </Col>
            </Row>
          </CardBody>
        </Card>
      </div>
    );
  }
}

export default Tags;
