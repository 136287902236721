import { useParams, useHistory } from 'react-router-dom';
import React, { useCallback, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  Label,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Button,
  FormFeedback,
} from 'reactstrap';
import CopyLayoutModal from './modals/CopyLayoutModal';
import DeleteConfirmationModal from '../../components/DeleteConfirmationModal/DeleteConfirmationModal';
import { useCreateLayout, useUpdateLayout, useLayout, useDeleteLayout } from './queries';
import ZuulLoader from '../../components/ZuulLoader/ZuulLoader';
import { ZuulToaster } from '../../components/Toaster/ZuulToaster';
import { ZuulEditor } from '../../components/ZuulEditor/ZuulEditor';

export const isValidName = (name) => !!name && name.length > 0 && /^[^<>:"/\\|?*\s]+$/i.test(name);

const LayoutsDetails = () => {
  const { layoutId } = useParams();
  const history = useHistory();
  const { data: layout, isLayoutLoading, error } = useLayout(layoutId);
  const [layoutData, setLayoutData] = useState();
  const [showCopyModal, setShowCopyModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [validationErrors, setValidationErrors] = useState(false);
  const { mutateAsync: updateLayout, isLoading: isUpdating } = useUpdateLayout();
  const { mutateAsync: createLayout, isLoading: isCreating } = useCreateLayout();
  const { mutateAsync: deleteLayout, isLoading: isDeleting } = useDeleteLayout();

  const isProcessing = isUpdating || isCreating || isDeleting;
  const zuulUser = JSON.parse(localStorage.getItem('userData'));

  const handleSave = useCallback(() => {
    const updatedLayoutData = { ...layoutData, zuulUser };
    if (layoutData.id) {
      toast.promise(updateLayout(updatedLayoutData), {
        loading: 'Updating layout...',
        success: (data) => (data.status === 200 ? 'Layout updated successfully!' : 'No content changes detected.'),
        error: (err) => err?.response?.data?.message || err.message,
      });
    } else {
      toast.promise(createLayout(updatedLayoutData), {
        loading: 'Creating layout...',
        success: 'Layout created successfully!',
        error: (err) => err?.response?.data?.message || err.message,
      });
    }
  }, [layoutData, updateLayout, createLayout]);

  const handleDelete = useCallback(() => {
    toast.promise(deleteLayout(layoutData.id), {
      loading: 'Deleting layout...',
      success: 'Layout deleted successfully!',
      error: (err) => err?.response?.data?.message || err.message,
    });
  }, [layoutData, deleteLayout]);

  const handleEditorChange = (value, errors) => {
    setValidationErrors(errors);
    if (errors) {
      toast.dismiss();
      toast.error('Invalid HTML detected, please fix the highlighted errors!', {duration: Infinity});
    } else {
      toast.dismiss();
    }
    setLayoutData({ ...layoutData, content: value });
  };

  const handleNameChange = (event) => {
    setLayoutData({ ...layoutData, name: event.target.value });
  };

  useEffect(() => {
    if (isNaN(layoutId)) {
      setLayoutData({ id: null, name: '', content: '' });
    }
  }, [layoutId]);

  useEffect(() => {
    if (!isLayoutLoading && layout) {
      setLayoutData(layout);
    }
    if (!isLayoutLoading && error) {
      toast.error('Error Loading Layout!');
    }
  }, [isLayoutLoading, layout, error]);

  if (isLayoutLoading) {
    return <ZuulLoader text={'Loading Layout...'} />;
  }

  return (
    <div className='animated fadeIn'>
      {layoutData && (
        <>
          <Card>
            <CardHeader>
              <Row>
                <Col lg={4} style={{ display: 'flex', alignItems: 'baseline' }}>
                  <Label for='layout-name' style={{ width: '4em' }}>
                    Name:
                  </Label>
                  <InputGroup>
                    <InputGroupAddon addonType='prepend'>
                      <InputGroupText>
                        <i className='fa fa-edit' />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      required
                      type='text'
                      id='layout-name'
                      name='layout-name'
                      value={layoutData.name}
                      onChange={handleNameChange}
                      placeholder='Layout name'
                      style={{ justifyContent: 'center' }}
                      invalid={!isValidName(layoutData.name)}
                    />
                    <FormFeedback>
                      {'The name cannot be empty or contain spaces nor any of the following characters: <>:"/\\|?*'}
                    </FormFeedback>
                  </InputGroup>
                </Col>
                {!!layoutData.id && (
                  <Col lg={2} style={{ display: 'flex', alignItems: 'center' }}>
                    Id: {layoutData.id}
                  </Col>
                )}
                <Col
                  lg={3}
                  style={{ display: 'flex', alignItems: 'baseline', justifyContent: 'flex-end', marginLeft: 'auto' }}
                >
                  <Button
                    color='success'
                    onClick={handleSave}
                    disabled={!isValidName(layoutData.name) || isProcessing || validationErrors}>
                    {!!layoutData.id ? 'Save' : 'Create'}
                  </Button>
                  {!!layoutData.id && (
                    <Button
                      color='primary'
                      onClick={() => setShowCopyModal(true)}
                      style={{ marginLeft: '1em' }}
                      disabled={isProcessing || validationErrors}
                    >
                      Copy
                    </Button>
                  )}
                  <Button
                    color='secondary'
                    className='text-nowrap'
                    style={{ marginLeft: '1em' }}
                    onClick={() => history.push('/content/layouts')}
                    disabled={isProcessing}
                  >
                    Cancel
                  </Button>
                  {!!layoutData.id && (
                    <Button
                      color='danger'
                      style={{ marginLeft: '1em' }}
                      onClick={() => setShowDeleteModal(true)}
                      disabled={isProcessing || validationErrors}
                    >
                      <i className='fa fa-trash' />
                    </Button>
                  )}
                </Col>
              </Row>
            </CardHeader>
            <CardBody style={{ padding: 0 }}>
              <Row>
                <Col lg='12'>
                  <ZuulToaster
                    position={'top-right'}
                    style={{ minWidth: '250px', fontSize: '1.1em' }}
                    customContainerStyle={{
                      position: 'relative',
                      left: -40,
                    }}
                  />
                  <ZuulEditor
                    content={layoutData.content}
                    handleEditorChange={handleEditorChange}
                    minimapOn
                  />
                </Col>
              </Row>
            </CardBody>
          </Card>
          {layout && (
            <>
              <CopyLayoutModal
                isOpen={showCopyModal}
                layout={layoutData}
                closeModal={() => setShowCopyModal(false)}
                onCopy={createLayout}
              />
              <DeleteConfirmationModal
                type='layout'
                isOpen={showDeleteModal}
                closeModal={() => setShowDeleteModal(false)}
                onConfirm={handleDelete}
              />
            </>
          )}
        </>
      )}
    </div>
  );
};

export default LayoutsDetails;
