import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { DropdownItem, DropdownMenu, DropdownToggle, Nav, NavItem, NavLink } from 'reactstrap';
import { AppAsideToggler, AppHeaderDropdown, AppNavbarBrand, AppSidebarToggler } from '@coreui/react';
import zuulnew from '../../assets/img/brand/zuulnew.svg';
import KButton from '../../components/CommandK/Button/KButton';

const DefaultHeader = (props) => {
  const [asideToggled, setAsideToggled] = useState(false);
  const [sidebarToggled, setSidebarToggled] = useState(false);

  const toggleAside = () => setAsideToggled(!asideToggled);
  const toggleSidebar = () => setSidebarToggled(!sidebarToggled);

  return (
    <>
      <AppSidebarToggler className='d-lg-none' display='md' mobile />
      <AppNavbarBrand
        full={{ src: zuulnew, width: 89, height: 25, alt: 'zuul' }}
        minimized={{ src: zuulnew, width: 39, height: 20, alt: 'zuul' }}
      />
      <div onClick={toggleSidebar}>
        <AppSidebarToggler className='d-md-down-none '>
          {sidebarToggled ? (
            <i className='fa fa-chevron-right text-white font-sm'></i>
          ) : (
            <i className='fa fa-chevron-left text-white font-sm'></i>
          )}
        </AppSidebarToggler>
      </div>
      <Nav className='d-md-down-none' navbar>
        <NavItem className='px-3'>
          <NavLink href='/'>Dashboard</NavLink>
        </NavItem>
        <NavItem className='px-3'>
          <Link to='/users'>Users</Link>
        </NavItem>
        <NavItem className='px-3'>
          <NavLink href='#'>Settings</NavLink>
        </NavItem>
      </Nav>
      <Nav className='ml-auto' navbar>
        <KButton hide={false} />
        <AppHeaderDropdown direction='down'>
          <DropdownToggle nav>
            <i className='cui-account-logout icons'></i>
          </DropdownToggle>
          <DropdownMenu right style={{ right: 'auto' }}>
            <DropdownItem onClick={(e) => props.onLogout(e)}>
              <i className='fa fa-lock' /> Logout
            </DropdownItem>
          </DropdownMenu>
        </AppHeaderDropdown>
      </Nav>
      <div onClick={toggleAside}>
        <AppAsideToggler className='d-md-down-none'>
          {/*<FontAwesome icon='fa-solid fa-arrows-spin' />*/}
          {asideToggled ? (
            <i className='fa fa-chevron-left text-white font-sm'></i>
          ) : (
            <i className='fa fa-chevron-right text-white font-sm'></i>
          )}
        </AppAsideToggler>
      </div>
      {/*<AppAsideToggler className="d-lg-none" mobile />*/}
    </>
  );
};

export default DefaultHeader;
