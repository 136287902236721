import React, { useState, useEffect } from 'react';
import ViewContainer from '../../components/ViewContainer';
import ZuulTable from '../../components/Table/Table';
import { getSequenceRecommendations } from '../../services/sequenceRecommendations/api';
import { getSites } from '../../services/sites/api';
import seqRecoTableColumns from './tableColumns';
import { filterSeqRecos } from './offerReorderingHelper';
import { Row, Col, Label } from 'reactstrap';
import { renderToggleSwitch } from '../Offers/OfferTitle/renderConsts';
import ErrorBoundary from '../../components/ErrorBoundary/ErrorBoundary';
import _fp from 'lodash/fp';
import './OfferReordering.scss';
import { makeSiteNameFilterComponent } from '../../components/SiteNameFilter/makeSiteNameFilter';

const OfferReordering = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [seqRecos, setSeqRecos] = useState([]);
  const [filteredSeqRecos, setFilteredSeqRecos] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({ loadSeqRecosError: false });
  const [showRejected, setShowRejected] = useState(false);
  const [site, setSite] = useState([]);
  const [selectedSite, setSelectedSite] = useState('');

  // How to fetch data safely with hooks
  // https://www.robinwieruch.de/react-hooks-fetch-data
  useEffect(() => {
    (async () => {
      setIsLoading(true);
      setErrors(_errors => ({ ..._errors, loadSeqRecosError: false }));

      try {
        const [_sites, _seqRecos] = await Promise.all([getSites(), getSequenceRecommendations()]);
        const sortedSites = _fp.pipe(_fp.sortBy(['domain']))(_sites);
        setSite(sortedSites);

        setSeqRecos(_seqRecos);
        /**
         * Since showRejected is false on component load, using false here instead of showRejected so we don't need
         * to reload the whole component when the showRejected toggle button is clicked.
         */
        setFilteredSeqRecos(filterSeqRecos(false)(_seqRecos));
        setIsLoading(false);
      } catch (e) {
        console.error(e);
        setErrors(_errors => ({ ..._errors, loadSeqRecosError: true }));
      }
    })();
  }, []);

  const toggleShowRejected = () => {
    const newValue = !showRejected;
    setShowRejected(newValue);
    setFilteredSeqRecos(filterSeqRecos(newValue, searchTerm, selectedSite.domain)(seqRecos));
  };

  const label = 'Offer Reordering';
  const viewContainerEntityMetadata = {
    label: label,
    iconClassName: 'fa fa-reorder'
  };
  const tableEntityMetadata = {
    entityName: label,
    entityCreateURL: '',
    columns: seqRecoTableColumns,
    data: filteredSeqRecos,
    defaultSorted: [
      {
        dataField: 'createdAt',
        order: 'desc'
      }
    ]
  };

  const handleSearchChange = event => {
    const _searchTerm = event.target.value;

    setSearchTerm(_searchTerm);
    setFilteredSeqRecos(filterSeqRecos(showRejected, _searchTerm, selectedSite.domain)(seqRecos));
  };

  const ShowRejectedToggle = () => {
    return (
      <div className='show-rejected-toggle'>
        <Label className='show-rejected-toggle--label'>Show Rejected</Label>
        <div className='show-rejected-toggle--switch'>
          {renderToggleSwitch({
            input: {
              value: showRejected,
              onChange: toggleShowRejected
            }
          })}
        </div>
      </div>
    );
  };

  const onSiteNameChange = site => {
    setSelectedSite(site);
    _fp.pipe(filterSeqRecos(showRejected, searchTerm, site.domain), setFilteredSeqRecos)(seqRecos);
  };

  const SiteNameFilter = makeSiteNameFilterComponent(site, onSiteNameChange, { textField: 'domain' });

  const errorMessage = 'Error loading Offer Reorderings';

  return (
    <ErrorBoundary message={errorMessage}>
      <ViewContainer entity={viewContainerEntityMetadata} isError={errors.loadSeqRecosError} message={errorMessage}>
        <Row>
          <Col lg={12}>
            <span className='display-4'>{label}s</span>
          </Col>
        </Row>
        <ZuulTable
          entity={tableEntityMetadata}
          handleSearchChange={handleSearchChange}
          loading={isLoading}
          searchTerm={searchTerm}
          tableHeaderItems={[SiteNameFilter, ShowRejectedToggle]}
        />
      </ViewContainer>
    </ErrorBoundary>
  );
};

export default OfferReordering;
