import _ from 'lodash';
import React, { Component } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import { Link } from 'react-router-dom';
import { Button, Card, CardBody, CardHeader, Col, Input, Row } from 'reactstrap';
import SearchLabel from '../../components/SearchLabel/SearchLabel';
import api from '../../services';
import { formatDate } from '../../utils/conversionFunctions';
import { columnFormatter } from '../../utils/tableFunctions';
import ActiveStatusModal from '../Offers/OfferTitle/ActiveStatusModal';
import './OfferWallAds.css';
import ZuulLoader from '../../components/ZuulLoader/ZuulLoader';

class OfferWallAds extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.idleTimer = null;
    this.state = {
      offerWallAds: [],
      filteredOfferWallAds: [],
      isActive: null,
      offerWallAdSelection: [],
      render: false,
      toOfferWallAdsPage: false,
      toId: null,
      page: 1,
      searchTerm: '',
      sizePerPage: 100,
      paginationSize: 10,
      totalElements: 10,
      isEditMode: false,
      offerType: 'Question',
      loading: false,
    };
    this.options = {
      onPageChange: this.onPageChange.bind(this),
      onSizePerPageList: this.sizePerPageListChange.bind(this),
      page: this.state.page,
      sizePerPage: this.state.sizePerPage,
      paginationSize: this.state.paginationSize,
      sizePerPageDropDown: this.renderSizePerPageDropDown,
      // defaultSortName: 'lastPublished',
      // defaultSortOrder: 'desc',
      prePage: 'Prev',
      nextPage: 'Next',
      firstPage: 'First',
      lastPage: 'Last',
      paginationShowsTotal: true,
      paginationPosition: 'bottom',
      alwaysShowAllBtns: true,
      withFirstAndLast: true,
    };

    this.getData = this.getData.bind(this);
  }

  renderSizePerPageDropDown = (props) => {
    return (
      <div className='btn-group'>
        {[10, 25, 30, 50].map((n, idx) => {
          const isActive = n === props.currSizePerPage ? 'active' : '';
          return (
            <button
              key={idx}
              type='button'
              className={`btn btn-info ${isActive}`}
              onClick={() => props.changeSizePerPage(n)}
            >
              {n}
            </button>
          );
        })}
      </div>
    );
  };

  renderOfferWallAds(response) {
    // console.log(response);
    return response.data.content.map((offerWallAd) => {
      return {
        id: offerWallAd.id !== null && offerWallAd.id !== undefined ? offerWallAd.id : '',
        name: offerWallAd.name !== null ? offerWallAd.name : '',
        advertiserName: offerWallAd.advertiser !== null ? offerWallAd.advertiser.name : '',
        lastEdited:
          offerWallAd.lastEdited !== null ? formatDate(offerWallAd.lastEdited) : formatDate('2019-01-01T12:00:00'),
        isActive: offerWallAd.isActive,
        status: offerWallAd.status,
      };
    });
  }

  getAdditionalData = () => {
    const offerPromises = [];

    for (let i = 1; i <= this.state.totalPages; i++) {
      const offerPromise = api.get(`offerwallads?limit=${100}&offset=${i}`);
      offerPromises.push(offerPromise);
    }

    return offerPromises;
  };

  getData(page = this.state.page, limit = this.state.sizePerPage > 0 ? this.state.sizePerPage : 100) {
    let offset = page - 1;
    api
      .get(`offerwallads?limit=${limit}&offset=${offset}`)
      .then((response) => {
        const existingOfferWallAds = this.renderOfferWallAds(response);
        const sortedOfferWallAds = existingOfferWallAds.sort((a, b) => this.sortDates(a, b, 'asc'));
        const totalPages = response.data.totalPages;
        const total = response.data.totalElements;
        const newState = Object.assign({}, this.state, {
          offerWallAds: sortedOfferWallAds,
          totalElements: total,
          totalPages,
          loading: true,
        });

        this.setState(newState, () => {
          Promise.all(this.getAdditionalData()).then((responses) => {
            const parsedOfferWallAds = _.flatten(
              responses.map((response) => {
                return this.renderOfferWallAds(response);
              })
            );

            this.setState((prevState) => {
              let totalSortedOfferWallAds = [...prevState.offerWallAds, ...parsedOfferWallAds].sort((a, b) =>
                this.sortDates(a, b, 'asc')
              );
              console.log(totalSortedOfferWallAds);
              totalSortedOfferWallAds = totalSortedOfferWallAds.filter(
                (offerWallAd) => offerWallAd.status && offerWallAd.status === 1
              );
              //console.log(totalSortedOfferWallAds);
              return {
                offerWallAds: totalSortedOfferWallAds,
                loading: false,
                render: true,
              };
            });
          });
        });
      })
      .catch((error) => console.log(error));
  }

  updateData(page = this.state.page, limit = this.state.sizePerPage) {
    let offset = page - 1;
    let existingOfferWallAds = this.state.offerWallAds;
    api
      .get(`offerwallads?limit=${limit}&offset=${offset}`)
      .then((response) => {
        const newOffers = this.renderOfferWallAds(response);
        //console.log(response.data);
        const first = response.data.pageable.offset;
        const num = response.data.numberOfElements;
        for (let i = first, j = 0; j < num; i++, j++) {
          existingOfferWallAds[i] = newOffers[j];
        }

        const newState = Object.assign({}, this.state, {
          offerWallAds: existingOfferWallAds,
        });

        this.setState(newState);
      })
      .catch((error) => console.log(error));
  }

  componentDidMount() {
    this.getData();
  }

  sizePerPageListChange(sizePerPage) {
    this.updateData(1, sizePerPage);
  }

  onPageChange(page, sizePerPage) {
    this.updateData(page, sizePerPage);
  }

  sortDates(a, b, order) {
    var dateA = new Date(a.lastEdited).getTime();
    var dateB = new Date(b.lastEdited).getTime();
    if (order === 'asc') {
      return dateA > dateB ? -1 : 1;
    }
    return dateA > dateB ? 1 : -1;
  }

  filterOfferWallAds = (searchTerm, unfilteredOfferWallAds) => {
    // Split the term by space and remove empty spaces
    let splitSearchTerm = searchTerm.split(/[ ,]+/).filter(Boolean);
    const filteredOfferWallAds = unfilteredOfferWallAds.filter((offerWallAd) => {
      const parsedOfferWallAd = {
        id: offerWallAd.id + '' || '',
        lastEdited: offerWallAd.lastEdited || '',
        advertiserName: offerWallAd.advertiserName || '',
        name: offerWallAd.name || '',
      };
      let doesMatchTerms = true;
      for (let i = 0; i < splitSearchTerm.length; i++) {
        if (
          !Object.keys(parsedOfferWallAd).some((key) =>
            parsedOfferWallAd[key].toLowerCase().includes(splitSearchTerm[i].toLowerCase())
          )
        ) {
          doesMatchTerms = false;
        }
      }
      return doesMatchTerms;
    });
    this.setState({ filteredOfferWallAds });
  };

  handleInputChange = (event) => {
    const searchTerm = event.target.value;
    // This prevents the user from calling the search function too many times in less than a second, helps with performance
    const throttledSearch = _.throttle(this.filterOfferWallAds, 500);
    this.setState({ searchTerm });
    return throttledSearch(searchTerm, this.state.offerWallAds);
  };

  updateStatus = (row, activeStatus, endpoint) => {
    if (row.id) {
      let saveData = {
        id: row.id,
        isActive: activeStatus,
      };
      api.put(`${endpoint}/${row.id}/active`, saveData).then((result) => {
        console.log(JSON.stringify(result));
      });
    }
  };

  activeStatusSwitch = (row, entity) => {
    return (
      <ActiveStatusModal
        content={row}
        isActive={row.isActive}
        modalType={entity.modalType}
        getErrors={() => this.state.errors || null}
        onClickSubmit={(activeStatus) => this.updateStatus(row, activeStatus, entity.endpoint)}
      />
    );
  };

  actionFormatter = (cell, row) => {
    return (
      <Row>
        <Col lg={12}>
          {this.activeStatusSwitch(row, {
            modalType: ' Offer Wall Ad',
            endpoint: 'offerwallads',
          })}
        </Col>
      </Row>
    );
  };

  render() {
    const URL = '/campaigns/offerwallads/';

    const columns = [
      {
        dataField: 'id',
        text: 'ID',
        formatter: (cell, row) => {
          return columnFormatter({
            cell,
            row,
            computedURL: URL + row.id,
          });
        },
        headerStyle: {
          backgroundColor: '#2f353a',
        },
      },
      {
        dataField: 'name',
        text: 'Offer Wall Ad Name',
        formatter: (cell, row) => {
          return columnFormatter({
            cell,
            row,
            computedURL: URL + row.id,
          });
        },
        sort: true,
        sortCaret: (order) => {
          if (!order)
            return (
              <span>
                &nbsp;&nbsp;&nbsp;
                <i className='fa fa-sort' />
              </span>
            );
          else if (order === 'asc')
            return (
              <span>
                &nbsp;&nbsp;
                <i className='fa fa-sort' />
              </span>
            );
          else if (order === 'desc')
            return (
              <span>
                &nbsp;&nbsp;
                <i className='fa fa-sort' />
              </span>
            );
          return null;
        },
        headerStyle: {
          backgroundColor: '#2f353a',
        },
      },
      {
        dataField: 'advertiserName',
        text: 'Advertiser',
        formatter: (cell, row) => {
          return columnFormatter({
            cell,
            row,
            computedURL: URL + row.id,
          });
        },
        sort: true,
        sortCaret: (order) => {
          if (!order)
            return (
              <span>
                &nbsp;&nbsp;&nbsp;
                <i className='fa fa-sort' />
              </span>
            );
          else if (order === 'asc')
            return (
              <span>
                &nbsp;&nbsp;
                <i className='fa fa-sort' />
              </span>
            );
          else if (order === 'desc')
            return (
              <span>
                &nbsp;&nbsp;
                <i className='fa fa-sort' />
              </span>
            );
          return null;
        },
        headerStyle: {
          backgroundColor: '#2f353a',
        },
      },
      {
        dataField: 'lastEdited',
        text: 'Last Edited',
        formatter: (cell, row) => {
          return columnFormatter({
            cell,
            row,
            computedURL: URL + row.id,
          });
        },
        sort: true,
        sortCaret: (order) => {
          if (!order)
            return (
              <span>
                &nbsp;&nbsp;&nbsp;
                <i className='fa fa-sort' />
              </span>
            );
          else if (order === 'asc')
            return (
              <span>
                &nbsp;&nbsp;
                <i className='fa fa-sort' />
              </span>
            );
          else if (order === 'desc')
            return (
              <span>
                &nbsp;&nbsp;
                <i className='fa fa-sort' />
              </span>
            );

          return null;
        },
        sortFunc: this.sortDates,
        headerStyle: {
          backgroundColor: '#2f353a',
        },
      },
      {
        dataField: 'isActive',
        text: 'Active',
        // This formatter is different from the other column formatter implementations because the endpoint to update the status doesn't follow the same pattern as well as the active vs. isActive boolean field
        formatter: this.actionFormatter,
        sort: true,
        sortCaret: (order) => {
          if (!order)
            return (
              <span>
                &nbsp;&nbsp;&nbsp;
                <i className='fa fa-sort' />
              </span>
            );
          else if (order === 'asc')
            return (
              <span>
                &nbsp;&nbsp;
                <i className='fa fa-sort' />
              </span>
            );
          else if (order === 'desc')
            return (
              <span>
                &nbsp;&nbsp;
                <i className='fa fa-sort' />
              </span>
            );
          return null;
        },
        headerStyle: {
          backgroundColor: '#2f353a',
          width: '5%',
        },
      },
    ];
    const rowEvents = {
      onMouseEnter: (e, row, rowIndex) => {
        this.setState({ activeRow: rowIndex });
      },
    };
    const rowStyle = (row, rowIndex) => {
      const style = {};
      if (this.state.activeRow === rowIndex) {
        style.cursor = 'pointer';
      }
      return style;
    };

    return (
      <div>
        {' '}
        <div className='animated fadeIn'>
          <Card>
            <CardHeader>
              <i className='icon-menu' />
              Offer Wall Ads
            </CardHeader>
            <CardBody>
              <ToolkitProvider
                keyField='id'
                data={this.state.searchTerm.length > 0 ? this.state.filteredOfferWallAds : this.state.offerWallAds}
                columns={columns}
                search
                bootstrap4={true}
              >
                {(props) => {
                  return (
                    <div>
                      <Row>
                        <Col lg={6}>
                          <span className='display-4'>Offer Wall Ads</span>
                        </Col>
                        <Col lg={6}>
                          <Link to={`/campaigns/offerwallads/create`}>
                            <Button color='primary' className='float-right' orgs={this.state.offerWallAd}>
                              <i className='fa fa-plus' />
                              &nbsp; Add Offer Wall Ad{' '}
                            </Button>
                          </Link>
                        </Col>
                      </Row>
                      <br />
                      {this.state.loading === true && (
                        <ZuulLoader text={`Loading offer wall ads...`} />
                      )}
                      {this.state.loading === false && (
                        <div>
                          <Row>
                            <Col lg={3}>
                              <SearchLabel />
                              <Input
                                type='text'
                                id='wallad-search'
                                name='offerWallAdsSearch'
                                placeholder='Use spaces to separate search terms'
                                onChange={this.handleInputChange}
                                value={this.state.searchTerm}
                                aria-label='Search'
                              />
                            </Col>
                          </Row>
                          <hr />
                          <Row>
                            <Col lg={12}>
                              <BootstrapTable
                                hover
                                {...props.baseProps}
                                pagination={paginationFactory()}
                                rowEvents={rowEvents}
                                rowStyle={rowStyle}
                              />
                            </Col>
                          </Row>
                        </div>
                      )}
                    </div>
                  );
                }}
              </ToolkitProvider>
            </CardBody>
          </Card>
        </div>
      </div>
    );
  }
}

export default OfferWallAds;
