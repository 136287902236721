import React, { useEffect, useState } from 'react';
import KButton from '../Button/KButton';
import Modal from '../NavigationModal/NavigationModal';

const list = [
  {
    sectionName: 'Campaigns',
    items: [
      {
        icon: '📧',
        title: 'Landing Pages',
        url: '/campaigns/landingpages',
      },
      {
        icon: '📧',
        title: 'Landing Page Groups',
        url: '/campaigns/landingpagegroups',
      },
      {
        icon: '📊',
        title: 'Offers',
        url: '/campaigns/offertable',
      },
      {
        icon: '🗂',
        title: 'Offer Actions',
        url: '/campaigns/offeractions',
      },
      {
        icon: '📊',
        title: 'Offer Wall Ads',
        url: '/campaigns/offerwallads',
      },
      {
        icon: '📈',
        title: 'Offer Blocks',
        url: '/campaigns/offerblocks',
      },
      {
        icon: '📈',
        title: 'Sequences',
        url: '/campaigns/sequences',
      },
      {
        icon: '🔍',
        title: 'Tags',
        url: '/campaigns/tags',
      },
      {
        icon: '🔍',
        title: 'Sites',
        url: '/campaigns/sites',
      },
    ],
  },
  {
    items: [
      {
        icon: '🔍',
        title: 'Advertisers',
        url: '/advertisers',
      },
      {
        icon: '🔍',
        title: 'Marketing Partners',
        url: '/marketingpartners',
      },
      {
        icon: '🔍',
        title: 'Publisher Pre-Pings',
        url: '/publisher-pre-pings',
      },
      {
        icon: '🔍',
        title: 'Advertiser Pre-Ping',
        url: '/advertiser-pre-pings',
      },
      {
        icon: '🔍',
        title: 'Revshare Percentage',
        url: '/revshare-percentage',
      },
      {
        icon: '🔍',
        title: 'Domains',
        url: '/domains',
      },
    ],
  },
  {
    sectionName: 'Content',
    items: [
      {
        icon: '📝',
        title: 'Layouts',
        url: '/content/layouts',
      },
      {
        icon: '📝',
        title: 'Containers',
        url: '/content/containers',
      },
      {
        icon: '📝',
        title: 'Template Editor',
        url: '/content/template-editor',
      },
      {
        icon: '📧',
        title: 'Handlers',
        url: '/content/handlers',
      },
      {
        icon: '📧',
        title: 'User Attributes',
        url: '/content/userattributes',
      },
    ],
  },
  {
    items: [
      {
        icon: '🔍',
        title: 'Offer Reordering',
        url: '/offerreordering',
      },
      {
        icon: '🔍',
        title: 'Users',
        url: '/users',
      },
    ],
  },
];

const CommandK = () => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if ((event.metaKey && event.key === 'k') || (event.ctrlKey && event.key === 'k')) {
        event.preventDefault();
        const button = document.getElementById('search-button');
        if (button) {
          const x = window.scrollX;
          const y = window.scrollY;
          setIsOpen(!isOpen);
          button.focus({
            preventScroll: true,
          });
          window.scrollTo(x, y);
        }
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    // prevents scrolling when modal is open
    if (isOpen && typeof window != 'undefined' && window.document) {
      document.body.style.overflow = 'hidden';
    }

    if (!isOpen) {
      document.body.style.overflow = '';
      return () => {
        document.removeEventListener('keydown', handleKeyDown);
      };
    }
  }, [isOpen]);

  return (
    <div data-testid='commandk'>
      {isOpen && <Modal data={list} isOpen={isOpen} toggle={() => setIsOpen(false)} />}
      <KButton onClick={() => setIsOpen(true)} />
    </div>
  );
};

export default CommandK;
