export default function validate(values) {
  console.log('values', values);
  const errors = {};
  if (!values.name) {
    errors.name = 'Required';
  }
  if (!values.site) {
    errors.site = 'Required';
  }

  if (values.customVariables) {
    errors.customVariables = [];
    values.customVariables.forEach((customVariable, index) => {
      if ((customVariable.key === 'DUD_') | (customVariable.key === '')) {
        errors.customVariables[index] = { key: 'Please enter a key.' };
      }
    });
  }
  return errors;
}
