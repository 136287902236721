import { Card, CardBody, CardHeader, Col, Row, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { getLayoutsApi, getLayoutsTableApi } from '../Metadata/Layouts/actions';

import LayoutTable from '../../components/Table/hookTable';
import { columnFormatter } from '../../utils/tableFunctions';
import _ from 'lodash';
import ChangelogModal from '../../components/ChangelogModal/ChangelogModal';
import format from 'date-fns/format';

const LayoutsList = () => {
  const [layouts, setLayouts] = useState([]);
  const [filteredLayouts, setFilteredLayouts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');

  const URL = '/content/layouts/';
  const columns = [
    {
      dataField: 'id',
      text: 'ID',
      sort: true,
      headerStyle: {
        backgroundColor: '#2f353a',
      },
      formatter: (cell, row) => {
        return columnFormatter({
          cell,
          row,
          computedURL: URL + row.id,
        });
      },
    },
    {
      dataField: 'name',
      text: 'Name',
      sort: true,
      headerStyle: {
        backgroundColor: '#2f353a',
      },
      formatter: (cell, row) => {
        return columnFormatter({
          cell,
          row,
          computedURL: URL + row.id,
        });
      },
    },
    {
      dataField: 'zuulUser',
      text: 'Last Published',
      sort: true,
      headerStyle: {
        backgroundColor: '#2f353a',
      },
      formatter: (_cell, row) => {
        return row?.zuulUser?.realname
          ? format(new Date(row.lastPublished), 'Pp') + ' by ' + row?.zuulUser?.realname
          : format(new Date(row.lastPublished), 'Pp');
      },
    },
    {
      dataField: 'changelog',
      text: 'Audit',
      isDummyField: true,
      formatter: (_cell, row) => (
        <Row>
          <Col lg={12}>
            <ChangelogModal typeName='Layout' apiPath='layouts' id={row.id} />
          </Col>
        </Row>
      ),
      headerStyle: {
        backgroundColor: '#2f353a',
        textAlign: 'center',
      },
      searchable: false,
    },
  ];

  const filterLayouts = (searchTerm, allLayouts) => {
    // Split the term by space and remove empty spaces
    let splitSearchTerm = searchTerm.split(/[ ,]+/).filter(Boolean);
    const filteredLayouts = allLayouts.filter((layout) => {
      const parsedLayout = {
        id: layout.id + '' || '',
        name: layout.name || '',
      };
      let doesMatchTerms = true;
      for (let i = 0; i < splitSearchTerm.length; i++) {
        if (
          !Object.keys(parsedLayout).some((key) =>
            parsedLayout[key].toLowerCase().includes(splitSearchTerm[i].toLowerCase())
          )
        ) {
          doesMatchTerms = false;
        }
      }
      return doesMatchTerms;
    });
    setFilteredLayouts(filteredLayouts);
  };

  const handleInputChange = (event) => {
    const searchTerm = event.target.value;
    // This prevents the user from calling the search function too many times in less than a second, helps with performance
    const throttledSearch = _.throttle(filterLayouts, 500);
    setSearchTerm(searchTerm);
    return throttledSearch(searchTerm, layouts);
  };

  useEffect(() => {
    getLayoutsTableApi().then((response) => {
      setLayouts(response);
      setFilteredLayouts(response);
      setLoading(false);
    });
  }, []);

  return (
    <div className='animated fadeIn'>
      <Card>
        <CardHeader>
          <Row>
            <Col lg={9}>
              <i className='icon-menu' />
              &nbsp; Layouts
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <Row>
            <Col lg={6}>
              <span className='display-4'>Layouts</span>
            </Col>
            <Col lg={6}>
              <Link to={URL + 'create'} style={{ textDecoration: 'none', color: 'inherit' }}>
                <Button color='primary' className='float-right'>
                  <i className='fa fa-plus' /> Create Layout
                </Button>
              </Link>
            </Col>
          </Row>
          <br />
          {layouts && (
            <LayoutTable
              entity={{
                entityName: 'Layout',
                columns,
                data: filteredLayouts,
              }}
              handleSearchChange={handleInputChange}
              loading={loading}
              searchTerm={searchTerm}
            />
          )}
        </CardBody>
      </Card>
    </div>
  );
};

export default LayoutsList;
