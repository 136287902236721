import React, { useState } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

const SimpleModal = (props) => {
  const [modal, setModal] = useState(true);

  const toggle = () => {
    props.toggleUserActivity();
    setModal(!modal);
  };

  return (
    <div className='animated'>
      <Modal isOpen={modal} toggle={toggle} className='modal-warning'>
        <ModalHeader toggle={toggle}>{props.header}</ModalHeader>
        <ModalBody>{props.body}</ModalBody>
        <ModalFooter>
          <Button color='secondary' onClick={toggle}>
            OK
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default SimpleModal;
