import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import DropdownList from 'react-widgets/DropdownList';
import 'react-widgets/styles.css';
import { Button, Card, CardBody, CardHeader, Col, Collapse, Row } from 'reactstrap';
import { Field, FieldArray, reduxForm } from 'redux-form';
import { getOfferTriggersByOfferId, removeOfferTriggerState, saveInitData } from '../../../../actions';
import Criteria from '../../../../components/Criteria/Criteria';
import { ImageCard } from '../../../../components/ImageCard/ImageCard';
import PreviewOfferModal from '../../../../components/PreviewOfferModal/PreviewOfferModal';
import ToggleButton from '../../../../components/ToggleButton/ToggleButton';
import api from '../../../../services';
import { handleCriteriaInitialize } from '../../../../utils/CriteriaHelpers';
import { filterActionTypesByLinkoutCount } from '../../../../utils/OfferActionHelper';
import { CDN_ASSETS_BASE } from '../../../../utils/constants';
import validate from '../../validate';
import CancelOfferModal from '../CancelOfferModal';
import CopyButton from '../CopyButton';
import FormEditor from '../OfferQuestion/FormEditor';
import '../OfferQuestion/OfferQuestion.css';
import OfferVariationModalButton from '../OfferVariationModalButton';
import SaveOfferModal from '../SaveOfferModal';
import { getActionAdvertiser, getActionStatus, initializeOfferTriggers } from '../offerTitleHelper/offerTitleHelper';
import {
  handleChangeQuestionType,
  renderActionCriteriaAndOr,
  renderActionTypeDropdown,
  renderField,
  renderFormTypeDropdown,
  renderQuestionType,
  renderSelectedOfferAction,
  renderTriggerDropdown,
} from '../renderConsts';
import CustomVariablesCard from '../../../../components/CustomVariables/CustomVariablesCard';
import { processCustomVariables } from '../../../../components/CustomVariables/utils';

const OfferFormConfigurationToggles = ({ formType }) => {
  return (
    <>
      <br />
      {formType === 'Question' && (
        <>
          <Row>
            <Col lg={4}>
              <ToggleButton id='requiresOptInBeforeSend' fieldName='requiresOptInBeforeSend'>
                Require TCPA Confirmation before sending
              </ToggleButton>
            </Col>
            <Col lg={4}>
              <ToggleButton id='randomizeAnswers' fieldName='randomizeAnswers'>
                Randomize Answers
              </ToggleButton>
            </Col>
          </Row>
        </>
      )}
      <Row>
        <Col lg={4}>
          <ToggleButton id='verifyWithBriteverify' fieldName='verifyWithBriteverify'>
            Validate with BriteVerify
          </ToggleButton>
        </Col>
        <Col lg={4}>
          <ToggleButton id='scrubIsActive' fieldName='scrubIsActive'>
            Pixel is Active
          </ToggleButton>
        </Col>
        <Col lg={4}>
          <ToggleButton id='triggersRegistration' fieldName='triggersRegistration'>
            Count as Registration
          </ToggleButton>
        </Col>
      </Row>
      <Row>
        <Col lg={4}>
          <ToggleButton id='doNotEvaluateOfferActionTcpaCriteria' fieldName='doNotEvaluateOfferActionTcpaCriteria'>
            Do not Evaluate Offer Action TCPA Criteria
          </ToggleButton>
        </Col>
        <Col lg={4}>
          <ToggleButton id='generateJornayaLeadId' fieldName='generateJornayaLeadId'>
            Generate Jornaya LeadId
          </ToggleButton>
        </Col>
        <Col lg={4}>
          <ToggleButton id='generateTrustedFormLeadId' fieldName='generateTrustedFormLeadId'>
            Generate Trusted Form LeadId
          </ToggleButton>
        </Col>
      </Row>
      <br />
    </>
  );
};

class OfferForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: '1',
      action: '',
      selectValue: 'Monday',
      value: [],
      imageFile: [],
      isEditMode: false,
      userAttribute: 'select',
      questionActions: [],
      questionActionChildren: 'select',
      advertisers: [],
      initialState: 'select',
      offerContent: {},
      collapseForm: true,
      collapseHeaderFooter: false,
      offerAction: '',
      prePingOption: '',
      httpIsHidden: true,
      errors: {
        disable: false,
        questionText: false,
        questionType: false,
        invalidYesNo: false,
      },
      answers: [],
      critQuestionAnswers: [],
      formType: '',
    };
    this.toggle = this.toggle.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.handleInitialize = this.handleInitialize.bind(this);
    this.handleChangeQuestionText = this.handleChangeQuestionText.bind(this);
    this.handleChangeForUserAttribute = this.handleChangeForUserAttribute.bind(this);
    this.handleChangeForActions = this.handleChangeForActions.bind(this);
    this.handleChangeForActionsChildren = this.handleChangeForActionsChildren.bind(this);
    this.toggleHeaderFooter = this.toggleHeaderFooter.bind(this);
    this.handlePrePing = this.handlePrePing.bind(this);
    this.handleHttpStatusToggle = this.handleHttpStatusToggle.bind(this);
    this.getErrors = this.getErrors.bind(this);
    this.handleAnswers = this.handleAnswers.bind(this);
    this.handleFormTypeChange = this.handleFormTypeChange.bind(this);
  }

  getErrors = () => {
    let errors = {
      ...this.props.errors,
      ...this.state.errors,
      invalid: this.props.submitFailed ? this.props.invalid : false,
    };

    return errors;
  };

  getPreview = async (formValues) => {
    const offerID = this.props.offer.id;
    const containerID = formValues.container.id;
    const layoutID = formValues.layout.id;
    const siteID = formValues.site.id;

    return new Promise((resolve) => {
      api.get(`offers/offer/preview/${offerID}/${containerID}/${layoutID}/${siteID}`).then((response) => {
        const myWindow = window.open();
        let html = response.data.replace(/{{{nr_script_header}}}/g, '').replace(/{{{nr_script_footer}}}/g, '');
        myWindow.document.write(html);
        resolve(html);
      });
    });
  };

  ageLimit = (value) => {
    return value && (value < 10 || value > 99) ? 'Age must be between 10 and 99' : undefined;
  };

  componentDidMount() {
    const { offer, offerContent } = this.props;
    const content = offerContent && offerContent !== null ? offerContent : {};
    let questionAnswersIndicies = content.question_answers ? Object.keys(content.question_answers) : [];
    questionAnswersIndicies = questionAnswersIndicies.map((index) => parseInt(index));

    if (offer === null || (offer && !offer.id)) {
      this.setState(
        {
          offerContent: this.props.initData,
          isEditMode: false,
        },
        this.handleInitialize
      );
    } else if (offer.id) {
      this.props.getOfferTriggersByOfferId(offer.id).then((offerTriggers) => {
        this.setState(
          {
            offerContent: offer,
            isEditMode: true,
            questionActions: questionAnswersIndicies,
          },
          this.handleInitialize
        );
      });
    }
  }

  componentWillUnmount() {
    // console.log(this.props);
    if (!this.state.isEditMode) {
      this.props.saveInitData('offerform');
    }
    this.props.destroy('offerform');
    this.props.removeOfferTriggerState();
  }

  handleAnswers(event) {
    let ans = this.state.answers;
    if (this.state.answers.indexOf(event.target.value) <= -1) ans.push(event.target.value);
    this.setState({
      answers: ans,
    });
  }

  onSubmit(values) {
    console.log(values);
    if (!this.props.advertiser) {
      this.setState((prevState) => {
        return {
          ...prevState,
          errors: {
            ...prevState.errors,
            isAdvertiserMissing: true,
            _error: 'This offer is missing an advertiser.',
          },
        };
      });
      setTimeout(() => {
        this.setState({
          errors: {
            ...this.state.errors,
            isAdvertiserMissing: false,
            _error: null,
          },
        });
      }, 2000);
    }
    const offerContent =
      !this.state.offerContent.content || this.state.offerContent.content == null
        ? {}
        : JSON.parse(this.state.offerContent.content);
    console.log('OFFER CONTENT = ' + JSON.stringify(offerContent));

    let contentValue = {};
    const questionText =
      !values.questionText || values.questionText === 'Enter Question Text' || values.questionText === null
        ? null
        : values.questionText;
    let questionType = values.questionType === null ? null : values.questionType;
    let formType = values.formType === null ? null : values.formType;
    const questionAnswers = values.questionAnswers == null ? [] : values.questionAnswers;
    let doNotEvaluateOfferActionTcpaCriteria = values.doNotEvaluateOfferActionTcpaCriteria
      ? values.doNotEvaluateOfferActionTcpaCriteria
      : false;
    let generateJornayaLeadId = values.generateJornayaLeadId ? values.generateJornayaLeadId : false;
    let generateTrustedFormLeadId = values.generateTrustedFormLeadId ? values.generateTrustedFormLeadId : false;
    const unNormalizedQuestionAnswers = questionAnswers ? questionAnswers : [];
    const offerPosts = values.offerFormPosts == null ? null : values.offerFormPosts;
    const getsPosts = values.offerGetsPosts == null ? null : values.offerGetsPosts;
    const oldQuestionId = offerContent.question_id == null ? null : offerContent.question_id;
    const oldOfferQuestionId = offerContent.offer_question_id == null ? null : offerContent.offer_question_id;

    if (questionAnswers != null && questionAnswers.length > 0) {
      questionAnswers.forEach((questionAnswer, i) => {
        if (questionAnswer.additionalAnswerAction && questionAnswer.additionalAnswerAction.length > 0) {
          let criteriaCheckBoxCounter = 0;
          criteriaCheckBoxCounter = questionAnswer.shouldShowCriteria
            ? ++criteriaCheckBoxCounter
            : criteriaCheckBoxCounter;
          questionAnswer.additionalAnswerAction.forEach((action) => {
            criteriaCheckBoxCounter = action.shouldShowCriteria ? ++criteriaCheckBoxCounter : criteriaCheckBoxCounter;
          });
          if (criteriaCheckBoxCounter > 1 && !questionAnswer.actionCriteriaAndOr) {
            questionAnswer.actionCriteriaAndOr = 'AND';
          } else if (questionAnswer.actionCriteriaAndOr && criteriaCheckBoxCounter <= 1) {
            // User unchecked the suppression box and the dropdown was removed from the UI but still saved on form submission
            questionAnswer.actionCriteriaAndOr = null;
          }
        }
      });

      contentValue = Object.assign({}, contentValue, {
        question_answers: questionAnswers,
      });
    }

    if (questionText !== null) {
      contentValue = {
        question_text: formType !== 'Question' ? null : questionText,
      };
    }

    if (questionType !== null) {
      contentValue = Object.assign({}, contentValue, {
        question_type: formType !== 'Question' ? null : questionType,
      });
    }

    if (getsPosts != null && getsPosts.length > 0) {
      contentValue = Object.assign({}, contentValue, { gets_posts: getsPosts });
    }

    contentValue = Object.assign({}, contentValue, {
      question_id: oldQuestionId,
    });

    contentValue = Object.assign({}, contentValue, {
      offer_question_id: oldOfferQuestionId,
    });

    let offerActionArray = [];
    if (questionAnswers !== undefined && questionAnswers !== null) {
      questionAnswers.forEach((questionAnswer) => {
        offerActionArray.push({
          answer: questionAnswer.text,
          name: questionAnswer.name,
          actionType: questionAnswer.question_actions || (questionAnswer.name ? 'POST' : null),
          showOfferByOfferActionCriteria: questionAnswer.shouldShowCriteria || false,
          triggerType: 'ON_ANSWER',
          countAsAccepted: questionAnswer.countAsAccepted || false,
        });
        if (questionAnswer.additionalAnswerAction && questionAnswer.additionalAnswerAction.length > 0) {
          questionAnswer.additionalAnswerAction.forEach((action) => {
            offerActionArray.push({
              answer: questionAnswer.text,
              name: action.name,
              actionType: action.question_actions || (action.name ? 'POST' : null),
              showOfferByOfferActionCriteria: action.shouldShowCriteria || false,
              triggerType: 'ON_ANSWER',
              countAsAccepted: questionAnswer.countAsAccepted || false,
            });
          });
        }
      });
    }
    if (offerPosts !== undefined && offerPosts !== null) {
      offerPosts.forEach((action) => {
        offerActionArray.push({
          name: action.name,
          triggerType: action.post_type || 'SUBMIT',
          actionType: action.question_actions || 'POST',
          paramName: action.param_name,
          paramValue: action.param_value,
          showOfferByOfferActionCriteria: action.shouldShowCriteria || false,
        });
      });
      contentValue = Object.assign({}, contentValue, {
        offer_posts: offerPosts,
      });
    }
    const randomizeAnswers = values.formType !== 'Question' ? null : values.randomizeAnswers;

    let questionData = Object.assign({}, values, {
      questionText: formType !== 'Question' ? null : questionText,
      questionType: formType !== 'Question' ? null : questionType,
      formType,
      doNotEvaluateOfferActionTcpaCriteria,
      generateJornayaLeadId,
      generateTrustedFormLeadId,
      offerActions: offerActionArray,
      unNormalizedQuestionAnswers: formType !== 'Question' ? [] : unNormalizedQuestionAnswers,
      content: JSON.stringify(contentValue) === '{}' ? '{}' : JSON.stringify(contentValue),
      customVariables:
        values.customVariables && values.customVariables.length > 0
          ? JSON.stringify(processCustomVariables(values.customVariables))
          : null,
      randomizeAnswers,
    });

    this.props.saveOffer(questionData);
    this.setState({ isEditMode: true });
  }

  handleInitialize() {
    const offer = this.state.offerContent;
    const { offerTriggers } = this.props;
    const offerContent =
      !this.state.offerContent.content || this.state.offerContent.content == null
        ? {}
        : JSON.parse(this.state.offerContent.content);
    // const offerTriggerFromContent = offerContent.offer_posts;
    let questionText = null,
      questionType = null,
      questionAnswers = null,
      offerFormPosts = null,
      offerGetsPosts = null;
    let formType = offer.formType !== null ? offer.formType : null;
    let generateJornayaLeadId = offer.generateJornayaLeadId !== null ? offer.generateJornayaLeadId : false;
    let doNotEvaluateOfferActionTcpaCriteria =
      offer.doNotEvaluateOfferActionTcpaCriteria !== null ? offer.doNotEvaluateOfferActionTcpaCriteria : false;
    let generateTrustedFormLeadId = offer.generateTrustedFormLeadId !== null ? offer.generateTrustedFormLeadId : false;
    let initData;

    if (offer) {
      const imageURL =
        offer.image1 && offer.image1 !== null && offer.image1.id !== null
          ? CDN_ASSETS_BASE + offer.image1.id + '/original.' + offer.image1.fileExtension
          : null;

      if (imageURL !== null) {
        let sizeCoordinates = offer.image1.sizeCoordinates ? offer.image1.sizeCoordinates : '{}';
        let imageDimensions = JSON.parse(sizeCoordinates);
        // grab only the image dimensions to use later on if the base image doesn't render
        imageDimensions = Object.keys(imageDimensions).filter((dimension) => dimension !== 'base');

        const imageDataFile = {
          name: offer.image1.fileName,
          id: offer.image1.id,
          doNotResize: offer.image1.doNotResize,
          encodedImageData: offer.image1.encodedImageData,
          sizeCoordinates: offer.image1.sizeCoordinates,
          fileName: offer.image1.fileName,
          fileExtension: offer.image1.fileExtension,
          targetHeight: offer.image1.targetHeight,
          targetWidth: offer.image1.targetWidth,
          version: offer.image1.version,
          preview: imageURL,
          size: 0,
        };
        this.setState({
          imageFile: [imageDataFile],
          imageDimensions,
        });
      }
      if (offerTriggers.length > 0) {
        const offerTriggersWithQuestionAnswers = offerTriggers.filter((offerTrigger) => {
          return offerTrigger.offerQuestionAnswer;
        });
        questionText =
          offerTriggersWithQuestionAnswers.length > 0
            ? offerTriggersWithQuestionAnswers[0].offerQuestionAnswer.offerQuestion.question.question
            : offerContent.question_text
            ? offerContent.question_text
            : null;
        questionType =
          offerContent.question_type && offerContent.question_type === null ? null : offerContent.question_type;
        questionAnswers = offerTriggersWithQuestionAnswers.length === 0 ? [] : offerTriggersWithQuestionAnswers;
        if (questionAnswers) {
          questionAnswers = this.handleQuestionAnswers(questionAnswers);
        }

        let answerArray = [];
        if (questionAnswers !== undefined && questionAnswers !== null) {
          for (let ans of questionAnswers) {
            if (answerArray.indexOf(ans) <= -1) {
              answerArray.push(ans.text);
            }
            if (!ans.hasOwnProperty('additionalAnswerAction')) {
              ans['additionalAnswerAction'] = ans.additional_gets_posts;
            }
          }
        }
        const questionActions = questionAnswers
          .filter((answer) => answer.name && answer.question_actions)
          .map((ans, i) => ans.orderNumber - 1);
        answerArray.length > 0 && this.setState({ answers: answerArray, questionActions });
        offerFormPosts = offerTriggers ? initializeOfferTriggers(offerTriggers) : null;
      }

      this.setState({ formType });

      // If no question answers exist, initialize them as yes and no answers
      questionAnswers = questionAnswers
        ? questionAnswers
        : !offer.id
        ? [
            { additionalAnswerAction: [], text: 'Yes', orderNumber: 1, countAsAccepted: true },
            { additionalAnswerAction: [], text: 'No', orderNumber: 2, countAsAccepted: false },
          ]
        : [];
      const randomizeAnswers =
        offer.formType !== 'Question' ? null : offer.randomizeAnswers ? offer.randomizeAnswers : false;
      const offerCriteria = handleCriteriaInitialize(offer, null);
      initData = {
        offerFormPosts: offerFormPosts === null ? null : offerFormPosts,
        offerGetsPosts: offerGetsPosts === null ? null : offerGetsPosts,
        questionText:
          questionText === null && offerContent.question_text
            ? offerContent.question_text
            : questionText !== null && questionText
            ? questionText
            : null,
        questionType: questionType ?? 'Yes/No',
        questionAnswers,
        footer: this.state.offerContent.footer === null ? null : this.state.offerContent.footer,
        customVariables: offer.customVariables ?? null,
        formType,
        generateJornayaLeadId,
        doNotEvaluateOfferActionTcpaCriteria,
        generateTrustedFormLeadId,
        isActive: offer.isActive === null ? true : offer.isActive,
        // Image Upload
        image1: offer.image1 === null ? null : offer.image1,
        scrubIsActive: offer.scrubIsActive ? true : false,
        verifyWithBriteverify: offer.verifyWithBriteverify ? true : false,
        randomizeAnswers,
        ...offerCriteria,
      };
    }
    this.props.initialize(initData);
  }

  toggleFormContent = () => {
    this.setState({ collapseForm: !this.state.collapseForm });
  };

  toggleHeaderFooter() {
    this.setState({ collapseHeaderFooter: !this.state.collapseHeaderFooter });
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  handleFormTypeChange = (e) => {
    this.setState({
      formType: e.target.value,
    });
  };

  handleChangeForUserAttribute = (e) => {
    this.setState({ userAttribute: e.target.value });
  };
  handleChangeForActions = (e) => {
    if (e.target.value === 'GET') {
      this.setState({ questionAction: 'GET' });
    }
    if (e.target.value === 'POST') {
      this.setState({
        questionAction: 'POST',
      });
    }
  };
  handleChangeForActionsChildren = (e) => {
    this.setState({ questionActionChildren: e.target.value });
  };

  handlePrePing = (e) => {
    this.setState({
      prePingOption: e.target.value,
    });
  };

  handleChangeQuestionText = (event) => {
    this.setState({
      questionText: event.target.value,
      errors: Object.assign({}, this.state.errors, {
        questionText: event.target.value.length === 0,
      }),
    });
  };

  handleQuestionAnswers = (questionAnswers) => {
    let offerQuestionAnswers = [];
    const orderNumbersAlreadyInArray = [];
    questionAnswers.forEach((offerTrigger) => {
      const questionAnswers = [];
      const orderNumber = offerTrigger.offerQuestionAnswer && offerTrigger.offerQuestionAnswer.orderNumber;
      const countAsAccepted =
        (offerTrigger.offerQuestionAnswer && offerTrigger.offerQuestionAnswer.countAsAccepted) || false;
      if (offerTrigger.offerActionTriggers.length === 0) {
        if (!orderNumbersAlreadyInArray.includes(orderNumber)) {
          questionAnswers.push({
            additionalAnswerAction: [],
            text: offerTrigger.offerQuestionAnswer && offerTrigger.offerQuestionAnswer.answer,
            orderNumber,
            showOfferAndOrLogic: offerTrigger.showOfferAndOrLogic,
            countAsAccepted,
          });
          orderNumbersAlreadyInArray.push(orderNumber);
        }
      } else {
        offerTrigger.offerActionTriggers.length > 0 &&
          offerTrigger.offerActionTriggers.forEach((offerActionTrigger) => {
            if (questionAnswers.length === 0) {
              if (orderNumbersAlreadyInArray.includes(orderNumber)) {
                const questionAnsIndex = _.findIndex(offerQuestionAnswers, (questionAnswer) => {
                  return questionAnswer.orderNumber === orderNumber;
                });
                if (offerQuestionAnswers[questionAnsIndex].text === offerTrigger.offerQuestionAnswer.answer) {
                  const currentQuestionAnswer = offerQuestionAnswers[questionAnsIndex];
                  // console.log(currentQuestionAnswer);
                  if (!offerQuestionAnswers[questionAnsIndex].name) {
                    currentQuestionAnswer.name = offerActionTrigger && offerActionTrigger.offerAction.title;
                    currentQuestionAnswer.question_actions =
                      offerActionTrigger && offerActionTrigger.offerAction.offerActionType;
                    currentQuestionAnswer.action_advertiser = getActionAdvertiser(offerActionTrigger);
                    currentQuestionAnswer.action_id = offerActionTrigger && offerActionTrigger.offerAction.id;
                    currentQuestionAnswer.actionStatus = getActionStatus(offerActionTrigger);
                    currentQuestionAnswer.actionCriteriaAndOr = offerTrigger && offerTrigger.showOfferAndOrLogic;
                    currentQuestionAnswer.shouldShowCriteria =
                      offerActionTrigger && offerActionTrigger.showOfferByOfferActionCriteria;
                    // console.log(offerActionTrigger);
                  } else {
                    currentQuestionAnswer.additionalAnswerAction.push({
                      text: offerTrigger.offerQuestionAnswer && offerTrigger.offerQuestionAnswer.answer,
                      name: offerActionTrigger && offerActionTrigger.offerAction.title,
                      question_actions: offerActionTrigger && offerActionTrigger.offerAction.offerActionType,
                      orderNumber,
                      action_advertiser: getActionAdvertiser(offerActionTrigger),
                      action_id: offerActionTrigger && offerActionTrigger.offerAction.id,
                      actionStatus: getActionStatus(offerActionTrigger),
                      shouldShowCriteria: offerActionTrigger && offerActionTrigger.showOfferByOfferActionCriteria,
                      countAsAccepted,
                    });
                    console.log(offerActionTrigger);
                  }
                }
              } else {
                questionAnswers.push({
                  additionalAnswerAction: [],
                  text: offerTrigger.offerQuestionAnswer && offerTrigger.offerQuestionAnswer.answer,
                  name: offerActionTrigger && offerActionTrigger.offerAction.title,
                  question_actions: offerActionTrigger && offerActionTrigger.offerAction.offerActionType,
                  orderNumber,
                  action_advertiser: getActionAdvertiser(offerActionTrigger),
                  action_id: offerActionTrigger && offerActionTrigger.offerAction.id,
                  actionStatus: getActionStatus(offerActionTrigger),
                  actionCriteriaAndOr: offerTrigger && offerTrigger.showOfferAndOrLogic,
                  shouldShowCriteria: offerActionTrigger && offerActionTrigger.showOfferByOfferActionCriteria,
                  countAsAccepted,
                });
                orderNumbersAlreadyInArray.push(orderNumber);
              }
            } else {
              questionAnswers[0].additionalAnswerAction.push({
                name: typeof offerActionTrigger.offerAction === 'object' && offerActionTrigger.offerAction.title,
                question_actions:
                  typeof offerActionTrigger.offerAction === 'object' && offerActionTrigger.offerAction.offerActionType,
                action_advertiser: getActionAdvertiser(offerActionTrigger),
                action_id: offerActionTrigger && offerActionTrigger.offerAction.id,
                actionStatus: getActionStatus(offerActionTrigger),
                shouldShowCriteria: offerActionTrigger && offerActionTrigger.showOfferByOfferActionCriteria,
              });
            }
          });
      }
      offerQuestionAnswers.push(...questionAnswers);
    });
    return offerQuestionAnswers.sort((a, b) => (a.orderNumber > b.orderNumber ? 1 : -1));
  };

  handleHttpStatusToggle = () => {
    this.setState({
      httpIsHidden: !this.state.httpIsHidden,
    });
  };

  handleActionRemoval = (index, answerAction) => {
    this.setState((prevState) => ({
      questionActions: prevState.questionActions.filter((idx) => idx !== index),
    }));
    const fieldsToReset = ['question_actions', 'name'];
    fieldsToReset.forEach((field) => this.props.change(`${answerAction}.${field}`, null));
  };

  handleOfferActionChange = (selector) => {
    this.props.change(`${selector}.question_actions`, null);
    this.props.change(`${selector}.name`, null);
    this.props.change(`${selector}.action_advertiser`, null);
    this.props.change(`${selector}.action_id`, null);
    this.props.change(`${selector}.actionStatus`, null);
  };

  handleOfferActionNameChange = (action = {}, selector) => {
    const active = action.active ? action.active : null;
    const advertiser = action.advertiser ? action.advertiser : null;
    const action_id = action.offerActionId ? action.offerActionId : '';
    this.props.change(`${selector}.action_advertiser`, advertiser);
    this.props.change(`${selector}.action_id`, action_id);
    this.props.change(`${selector}.actionStatus`, active);
  };

  resetForm = () => this.setState({ imageFile: [] }, () => this.props.reset());

  render() {
    const { selector } = this.props.offerState;
    const advertiserList = this.props.advertisers;
    const { formType } = this.state;

    const { posts, attributes, linkouts, postWaterfalls, linkoutWaterfalls, gtmEvents } = this.props;

    const disabled = linkouts.length === 0 || posts.length === 0;

    let InputGroup = ({ answers, index, fields }) => {
      return (
        <div key={index} className='colorTest'>
          <br />
          <Row>
            <Col lg={1}>
              <br />

              <Button
                color='danger'
                className='removeofferInputParent'
                type='button'
                title='Remove Parent Attribute'
                onClick={() => fields.remove(index)}
              >
                <i className='fa fa-close' />
              </Button>
            </Col>
            <Col lg={8}>
              <Field
                name={`${answers}.text`}
                type='text'
                component={renderField}
                label='Answer'
                onBlur={this.handleAnswers}
              />
            </Col>
            <Col lg={3}>
              <ToggleButton id='countAsAccepted' fieldName={`${answers}.countAsAccepted`}>
                Count as Accepted
              </ToggleButton>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <Field name={answers} component={renderActionGroup} index={index} />
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <FieldArray
                name={`${answers}.additionalAnswerAction`}
                answers={`${answers}.additionalAnswerAction`}
                answer={answers}
                component={renderAdditionalActionGroup}
                index={index}
              />
            </Col>
          </Row>

          <br />
        </div>
      );
    };

    const renderInputGroup = ({ fields, meta: { touched, error } }) => {
      return (
        <div>
          <Row>
            <Col lg={2}>
              <Field
                name='questionType'
                type='select'
                id='questionType'
                component={renderQuestionType}
                label='Question Type'
                onChange={(event) => handleChangeQuestionType(fields, event)}
                className={this.state.errors.questionType === true ? 'error' : ''}
              />
            </Col>
            <Col lg={10}>
              <Button color='success' className='float-right' onClick={() => fields.push({})}>
                Add Offer Answer
              </Button>
              {touched && error && <span>{error}</span>}
            </Col>
          </Row>
          <br />
          {fields.map((questionAnswers, index) => (
            <InputGroup answers={questionAnswers} fields={fields} index={index} key={index} />
          ))}
        </div>
      );
    };
    let renderActionGroup = (props) => {
      const {
        index,
        meta: { touched, error, initial },
        input: { name },
      } = props;

      const { questionActions } = this.state;
      let hasQuestionAnswer = questionActions.indexOf(index) > -1;
      return (
        <>
          {!hasQuestionAnswer && (
            <Row>
              <Col lg={9} />
              <Col lg={3}>
                <Row>
                  <Col lg={5}></Col>
                  <Col lg={7}>
                    <Button
                      color='success'
                      className='float-right'
                      onClick={() =>
                        this.setState((prevState) => ({
                          questionActions: [...prevState.questionActions, index],
                        }))
                      }
                    >
                      Add Answer Action
                    </Button>
                    {touched && error && <span>{error}</span>}
                  </Col>
                </Row>
              </Col>
            </Row>
          )}
          {hasQuestionAnswer && <ActionGroup index={index} answerAction={name} />}
        </>
      );
    };

    let ActionGroup = ({ action, action_id, actionStatus, answerAction, index, actionType, offerTrigger }) => {
      // Action was selected for the first time or changed from an existing action
      action_id = typeof action === 'object' && action !== null ? action.offerActionId : action_id;
      return (
        <div key={index} id='answerAction'>
          <Row>
            <Col lg={1} />
            <Col lg={1} style={{ marginTop: '2%' }}>
              <Button
                color='danger'
                className='removeofferInputParent'
                type='button'
                title='Remove Parent Attribute'
                onClick={() => this.handleActionRemoval(index, answerAction)}
              >
                <i className='fa fa-close' />
              </Button>
            </Col>
            <Col lg={1} style={{ marginTop: '2%' }}>
              {!action_id ? (
                <Button color='info' type='button' title='Visit action details' disabled={true}>
                  <i className='fa fa-search' />
                </Button>
              ) : (
                <Link to={`/campaigns/offeractions/${action_id}`} target='_blank'>
                  <Button color='info' type='button' title='Visit action details'>
                    <i className='fa fa-search' />
                  </Button>
                </Link>
              )}
            </Col>
            {renderMainActiondata({
              actionType,
              answerAction,
              actionStatus,
              offerTrigger,
            })}
          </Row>
        </div>
      );
    };
    ActionGroup = connect((state, props) => {
      const offerTrigger = selector(state, `questionAnswers[${props.index}]`);
      return {
        action_id: selector(state, `${props.answerAction}.action_id`),
        action: selector(state, `${props.answerAction}.name`),
        actionStatus: selector(state, `${props.answerAction}.actionStatus`),
        actionType: selector(state, `${props.answerAction}.question_actions`),
        offerTrigger,
      };
    })(ActionGroup);

    const renderMainActiondata = ({ actionType, answerAction, actionStatus, offerTrigger }) => {
      const actionData =
        actionType === 'POST'
          ? posts
          : actionType === 'LINKOUT'
          ? linkouts
          : actionType === 'USER_ATTRIBUTE'
          ? attributes
          : actionType === 'POST_WATERFALL'
          ? postWaterfalls
          : actionType === 'LINKOUT_WATERFALL'
          ? linkoutWaterfalls
          : actionType === 'GTM_EVENT'
          ? gtmEvents
          : [];

      const offerTriggers = [offerTrigger];
      offerTrigger?.additionalAnswerAction?.forEach((ot) => {
        offerTriggers.push(ot);
      });
      return (
        <>
          {renderActionTypeDropdown({
            data: filterActionTypesByLinkoutCount(offerTriggers),
            handleOfferActionChange: this.handleOfferActionChange,
            fieldName: `${answerAction}.question_actions`,
            offerAction: answerAction,
          })}
          {renderSelectedOfferAction({
            actionStatus,
            entityName: 'Offer',
            handleOfferActionNameChange: this.handleOfferActionNameChange,
            offerAction: answerAction,
            actionType,
            data: actionData,
            disabled,
          })}
          {actionType && (actionType === 'POST' || actionType === 'LINKOUT') && (
            <Col lg={3}>
              <Field
                name={`${answerAction}.action_advertiser`}
                component={renderField}
                type='text'
                disabled
                label='Advertiser Name'
              />
            </Col>
          )}
        </>
      );
    };
    /************* Start of additional action group ***************/
    let AdditionalActionGroup = ({
      action,
      action_id,
      actionStatus,
      additionalAnswerAction,
      index,
      fields,
      actionType,
      offerTrigger,
    }) => {
      action_id = typeof action === 'object' && action !== null ? action.offerActionId : action_id;
      return (
        <>
          <Row key={index} id='additionalAnswerAction'>
            <Col lg={1} />
            <Col lg={1}>
              <br />
              <Button
                color='danger'
                className='removeofferInputParent'
                type='button'
                title='Remove Parent Attribute'
                onClick={() => fields.remove(index)}
              >
                <i className='fa fa-close' />
              </Button>
            </Col>
            <Col lg={1}>
              <br />
              {!action_id ? (
                <Button color='info' type='button' title='Visit action details' disabled={true}>
                  <i className='fa fa-search' />
                </Button>
              ) : (
                <Link to={`/campaigns/offeractions/${action_id}`} target='_blank'>
                  <Button color='info' type='button' title='Visit action details'>
                    <i className='fa fa-search' />
                  </Button>
                </Link>
              )}
            </Col>
            <br />
            {renderMainActiondata({
              actionType,
              answerAction: additionalAnswerAction,
              actionStatus,
              offerTrigger,
            })}
          </Row>
          <br />
        </>
      );
    };
    AdditionalActionGroup = connect((state, props) => {
      let actionType = selector(state, `${props.additionalAnswerAction}.question_actions`);
      actionType = typeof actionType === 'string' ? actionType : '';
      const offerTrigger = selector(state, `questionAnswers[${props.offerTriggerIndex}]`);
      return {
        action_id: selector(state, `${props.additionalAnswerAction}.action_id`),
        action: selector(state, `${props.additionalAnswerAction}.name`),
        actionStatus: selector(state, `${props.additionalAnswerAction}.actionStatus`),
        actionType,
        offerTrigger,
      };
    })(AdditionalActionGroup);

    const renderAdditionalActionGroup = connect((state, props) => {
      const questionAnswers = selector(state, `questionAnswers`);
      return { questionAnswers };
    })(({ answer, questionAnswers, fields, index, meta: { touched, error } }) => {
      const { questionActions } = this.state;

      let hasQuestionAnswer = questionActions.indexOf(index) > -1;
      let shouldRenderAndOrDropdown = false;
      let criteriaCheckBoxCounter = 0;
      const currentAnswer = questionAnswers[index];
      criteriaCheckBoxCounter = currentAnswer.shouldShowCriteria ? ++criteriaCheckBoxCounter : criteriaCheckBoxCounter;
      const offerTriggerIndex = index;
      currentAnswer.additionalAnswerAction &&
        currentAnswer.additionalAnswerAction.forEach((action) => {
          criteriaCheckBoxCounter = action.shouldShowCriteria ? ++criteriaCheckBoxCounter : criteriaCheckBoxCounter;
        });

      shouldRenderAndOrDropdown = criteriaCheckBoxCounter > 1;
      return (
        <div>
          <br />
          {fields.map((questionAnswers, index) => (
            <AdditionalActionGroup
              additionalAnswerAction={questionAnswers}
              fields={fields}
              index={index}
              key={index}
              offerTriggerIndex={offerTriggerIndex}
            />
          ))}
          {hasQuestionAnswer && (
            <Row>
              <br />
              <Col lg={8} />
              <Col lg={4}>
                <Row>
                  <Col lg={6}>
                    {shouldRenderAndOrDropdown && (
                      <Field
                        className={'actionCriteriaAndOr'}
                        name={`${answer}.actionCriteriaAndOr`}
                        type='select'
                        component={renderActionCriteriaAndOr}
                      />
                    )}
                  </Col>
                  <Col lg={6}>
                    <Button color='success' className='float-right' onClick={() => fields.push({})}>
                      Add Answer Action
                    </Button>
                    {touched && error && <span>{error}</span>}
                  </Col>
                </Row>
              </Col>
              <br />
            </Row>
          )}
        </div>
      );
    });

    /*******************  Start of Offer Posts *************************/

    let OfferPosts = ({
      action,
      action_id,
      actionStatus,
      offer_posts,
      index,
      fields,
      trigger,
      hasLastName,
      actionType,
      has_revenue,
      linkout_has_revenue,
      offerTriggers,
    }) => {
      const actionData =
        actionType === 'POST'
          ? posts
          : actionType === 'LINKOUT'
          ? linkouts
          : actionType === 'USER_ATTRIBUTE'
          ? attributes
          : actionType === 'POST_WATERFALL'
          ? postWaterfalls
          : actionType === 'LINKOUT_WATERFALL'
          ? linkoutWaterfalls
          : actionType === 'GTM_EVENT'
          ? gtmEvents
          : [];
      action_id = typeof action === 'object' && action !== null ? action.offerActionId : action_id;
      return (
        <div key={index} className='colorTest'>
          <br />
          <Row>
            <Col lg={1}>
              <br />
              <Button
                color='danger'
                className='removeofferInputParent'
                type='button'
                title='Remove Parent Attribute'
                onClick={() => fields.remove(index)}
              >
                <i className='fa fa-close' />
              </Button>
            </Col>
            <Col lg={1}>
              <br />
              {!action_id ? (
                <Button color='info' type='button' title='Visit action details' disabled={true}>
                  <i className='fa fa-search' />
                </Button>
              ) : (
                <Link to={`/campaigns/offeractions/${action_id}`} target='_blank'>
                  <Button color='info' type='button' title='Visit action details'>
                    <i className='fa fa-search' />
                  </Button>
                </Link>
              )}
            </Col>
            <Col lg={2}>
              <Field
                type='select'
                label='Trigger'
                name={`${offer_posts}.post_type`}
                component={renderTriggerDropdown}
              />
            </Col>
            {renderActionTypeDropdown({
              data: filterActionTypesByLinkoutCount(offerTriggers),
              handleOfferActionChange: this.handleOfferActionChange,
              fieldName: `${offer_posts}.question_actions`,
              offerAction: offer_posts,
            })}
            {renderSelectedOfferAction({
              actionStatus,
              entityName: 'Offer',
              handleOfferActionNameChange: this.handleOfferActionNameChange,
              offerAction: offer_posts,
              actionType,
              data: actionData,
              disabled,
            })}
            {actionType && actionType === 'POST' && (
              <Col lg={3}>
                <Field
                  name={`${offer_posts}.action_advertiser`}
                  component={renderField}
                  type='text'
                  disabled
                  label='Advertiser Name'
                />
              </Col>
            )}
          </Row>
          <Row>
            <Col lg={3}> </Col>
          </Row>
          {trigger === 'SUBMIT+PARAMS' && (
            <>
              <br />
              <Row>
                <Col lg={2}> </Col>
                <Col lg={3}>
                  <Field name={`${offer_posts}.param_name`} component={renderField} label='Param Name' />
                </Col>
                <Col lg={3}>
                  <Field name={`${offer_posts}.param_value`} component={renderField} label='Param Value' />
                </Col>
                <Col lg={3}> </Col>
              </Row>
            </>
          )}
          <br />
        </div>
      );
    };
    OfferPosts = connect((state, props) => {
      const offerTriggers = selector(state, `offerFormPosts`) || [];
      return {
        action: selector(state, `${props.offer_posts}.name`),
        action_id: selector(state, `${props.offer_posts}.action_id`),
        trigger: selector(state, `${props.offer_posts}.post_type`),
        has_revenue: !!selector(state, `${props.offer_posts}.has_revenue`),
        actionType: selector(state, `${props.offer_posts}.question_actions`),
        linkout_has_revenue: !!selector(state, `${props.offer_posts}.linkout_has_revenue`),
        actionStatus: selector(state, `${props.offer_posts}.actionStatus`),
        offerTriggers,
      };
    })(OfferPosts);

    const renderOfferPosts = ({ fields, meta: { touched, error } }) => (
      <div>
        <hr />
        <Row>
          <Col lg={3}>
            <h4>Offer Actions</h4>
          </Col>
          <Col lg={9}>
            <Button color='success' className='float-right' onClick={() => fields.push({})}>
              Add Offer Action
            </Button>
            {touched && error && <span>{error}</span>}
          </Col>
        </Row>
        <br />
        {fields.map((questionAnswers, index) => (
          <OfferPosts offer_posts={questionAnswers} fields={fields} index={index} key={index} />
        ))}
      </div>
    );

    /********************* End of Offer Posts ************************/

    /******************** End of Payout Amount  *********************/
    const handlerList = this.props.handlers;

    const renderHandlerList = ({ input, data, valueField, textField }) => (
      <DropdownList {...input} data={data} valueField={valueField} textField={textField} onChange={input.onChange} />
    );

    return (
      <div className='animated fadeIn'>
        <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
          <ImageCard
            handleError={this.handleBrokenURL}
            fieldName='image1'
            imageFile={this.state.imageFile}
            clearOnClick={this.resetForm}
            setImageFile={(imageFile) => this.setState({ imageFile: [imageFile] })}
          />
          <Card>
            <CardHeader>
              <Row>
                <Col lg='10' onClick={this.toggleFormContent}>
                  <h4>
                    Form Details &nbsp;&nbsp;
                    {this.state.collapseForm ? (
                      <i className='fa fa-chevron-up opened' />
                    ) : (
                      <i className='fa fa-chevron-down closed' />
                    )}
                  </h4>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <Collapse isOpen={this.state.collapseForm}>
                <Row>
                  <Col lg={12}>
                    <Field
                      name='formType'
                      component={renderFormTypeDropdown}
                      type='select'
                      onChange={this.handleFormTypeChange}
                      label='Form Type'
                    />
                  </Col>
                </Row>
                <br />
                {formType !== '' && <OfferFormConfigurationToggles formType={formType} />}
                {formType === 'Question' && (
                  <>
                    <Row>
                      <Col lg='12'>
                        <Field name='questionText' type='text' id='questionText' component={FormEditor} />
                      </Col>
                    </Row>
                    <br />
                    <Row>
                      <Col>
                        <FieldArray name='questionAnswers' component={renderInputGroup} />
                      </Col>
                    </Row>
                  </>
                )}
                <Row>
                  <Col lg={12}>
                    <FieldArray name='offerFormPosts' component={renderOfferPosts} />
                  </Col>
                </Row>
              </Collapse>
            </CardBody>
          </Card>
          <Card>
            <CardHeader onClick={this.toggleHeaderFooter} id='clickHeader'>
              {this.state.collapseHeaderFooter ? (
                <div>
                  <h4>
                    Header/Footer&nbsp;&nbsp; <i className='fa fa-chevron-up opened' />
                  </h4>
                </div>
              ) : (
                <div>
                  <h4>
                    Header/Footer&nbsp;&nbsp; <i className='fa fa-chevron-down closed' />
                  </h4>
                </div>
              )}
            </CardHeader>
            <CardBody>
              <Collapse isOpen={this.state.collapseHeaderFooter}>
                <Row>
                  <Col>
                    <Field name='footer' type='text' id='footer' component={FormEditor} />
                  </Col>
                </Row>
              </Collapse>
            </CardBody>
          </Card>
          <FieldArray name='customVariables' component={CustomVariablesCard} customVarFlag='UD_' />
          <Criteria
            limitAnswers={this.state.answers}
            capAmount={this.props.capAmount}
            // capSegmentConfiguration={this.props.capSegmentConfiguration}
          />
          <Row className='justify-content-end pb-4'>
            <Col xs='auto'>
              <PreviewOfferModal
                isLP={false}
                isEditMode={this.state.isEditMode}
                getErrors={this.getErrors}
                onClickSubmit={this.props.handleSubmit(this.getPreview)}
              />
            </Col>
            {this.state.isEditMode && (
              <Col xs='auto'>
                <OfferVariationModalButton
                  getErrors={this.getErrors}
                  isDisabled={this.getErrors().invalid}
                  onClickSubmit={this.props.handleSubmit((values) => {
                    this.props.setCopyToVariation(true);
                    this.onSubmit(values);
                  })}
                  linkouts={this.props.linkouts}
                  posts={this.props.posts}
                />
              </Col>
            )}
            {this.state.isEditMode && (
              <Col xs='auto'>
                <CopyButton
                  getErrors={this.getErrors}
                  isDisabled={this.getErrors().invalid}
                  onClickSubmit={this.props.handleSubmit(this.onSubmit)}
                  linkouts={this.props.linkouts}
                  posts={this.props.posts}
                />
              </Col>
            )}
            <SaveOfferModal
              isDisabled={this.getErrors().invalid}
              offerName={this.props.offerName}
              getErrors={this.getErrors}
              onClickSubmit={this.props.handleSubmit(this.onSubmit)}
              linkouts={this.props.linkouts}
              posts={this.props.posts}
            />
            <Col xs='auto'>
              <CancelOfferModal linkouts={this.props.linkouts} posts={this.props.posts} getErrors={this.getErrors} />
            </Col>
          </Row>
        </form>
      </div>
    );
  }
}

const form = reduxForm({
  form: 'offerform',
  destroyOnUnmount: false,
  touchOnChange: true,
  validate,
});

function mapStateToProps(state) {
  const { advertiser, offerTriggers, offerActionTriggers } = state.offerState;
  const { initData } = state.criteria;
  const capAmount = state.form.offerform?.values?.capAmount;
  // const capSegmentConfiguration = state.form.offerform?.values?.capSegmentConfiguration || [];

  return {
    advertiser,
    capAmount,
    // capSegmentConfiguration,
    initData,
    offerActionTriggers,
    offerTriggers,
    offerState: state.offerState,
  };
}

export default connect(mapStateToProps, {
  getOfferTriggersByOfferId,
  saveInitData,
  removeOfferTriggerState,
})(form(OfferForm));
