import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Col, Input, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { saveAsOfferName, saveOffer } from '../../../actions';
import './SaveOfferModal.css';

class CopyButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      success: false,
      newOfferName: '',
      submitting: false,
    };

    this.toggleSuccess = this.toggleSuccess.bind(this);
    this.saveSuccess = this.saveSuccess.bind(this);
  }

  saveSuccess = () => {
    this.setState({
      submitting: true,
    });
    return this.props.onClickSubmit();
  };

  toggleSuccess() {
    this.setState({
      success: !this.state.success,
    });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.isOfferSaved && this.state.submitting) {
      setTimeout(() => {
        this.props.saveOffer(false);
      }, 4000);
      this.setState({ success: !this.state.success, submitting: false });
    }

    // if form submission failed due to validation
    const errors = this.props.getErrors();
    if (errors.invalid) {
      this.setState({ success: false, submitting: false });
    }
  }

  handleInputChange = (event) => {
    const { value, name } = event.target;
    this.setState({
      [name]: value,
    });
    this.props.saveAsOfferName(value);
  };

  toggleFunc = () => {
    if (!this.state.submitting && this.state.success) {
      return this.toggleSuccess();
    }
    return () => {};
  };

  render() {
    const errors = this.props.getErrors();
    const links = this.props.linkouts || [];
    const posts = this.props.posts || [];
    const isDisabled = links.length === 0 || posts.length === 0 || errors.invalid;
    const { submitting } = this.state;
    const { isOfferSaved } = this.props;
    const { isAdvertiserMissing, areUserAttributesMissing } = errors;
    const shouldErrorRender =
      Object.keys(errors).some((x) => errors[x]) || isAdvertiserMissing || areUserAttributesMissing;
    return (
      <div className='animated'>
        {/* {isOfferSaved && (
          <Alert color='success'>Offer Saved Successfully</Alert>
        )} */}

        <Button color='success' onClick={this.toggleSuccess} disabled={isDisabled}>
          Copy Offer
        </Button>
        <Modal isOpen={this.state.success} toggle={this.toggleFunc} className={'modal-warning ' + this.props.className}>
          {/* {errors.advertiser && <strong>{errors.advertiser}</strong>} */}
          <ModalHeader toggle={this.toggleFunc}>Copy</ModalHeader>
          <ModalBody>
            <Row>
              <Col
                lg={2}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  color: '#ffc107',
                }}
              >
                <i className='fa fa-warning fa-3x'></i>
              </Col>
              <Col lg={10}>
                <h3>{this.props.content && this.props.content.name}</h3>
                Any changes made without saving will be <strong>copied</strong> to the new offer but not reflected in
                the <strong>current</strong> offer until you click save offer.
              </Col>
            </Row>
            <br />
            <Row>
              <Col lg={12}>
                <Input
                  name='newOfferName'
                  placeholder='New Offer Name'
                  type='text'
                  onChange={this.handleInputChange}
                  value={this.state.newOfferName}
                />{' '}
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button color='warning' disabled={submitting} onClick={this.saveSuccess}>
              {submitting ? 'Processing' : 'Copy'}
            </Button>
            <Button color='secondary' disabled={submitting} onClick={this.toggleSuccess}>
              {submitting ? 'Processing' : 'Cancel'}
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { advertiser, selector, isOfferSaved } = state.offerState;
  const criteriaUserAttributes = selector(state, 'criteriaUserAttributes');

  return {
    advertiser,
    criteriaUserAttributes,
    isOfferSaved,
  };
}

export default connect(mapStateToProps, { saveOffer, saveAsOfferName })(CopyButton);
