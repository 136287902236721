import React from 'react';
import { Card, CardBody, Col, Input, Label, Row } from 'reactstrap';
import { MultiselectSummary } from './SummaryPaneComponents';
import { useAttributeSelectors } from '../hooks';

const RuleRenderer = ({ rule, level = 0 }) => {
  const indentStyle = {
    marginLeft: `${level * 40}px`,
  };

  if (typeof rule === 'string') {
    return (
      <Row className='row-margin-top' style={indentStyle}>
        <Col xs={2}>
          <Input value={rule} readOnly disabled />
        </Col>
      </Row>
    );
  } else if (rule.rules) {
    return (
      <>
        {rule.rules.map((nestedRule, index) => (
          <RuleRenderer key={index} rule={nestedRule} level={level + 1} />
        ))}
      </>
    );
  } else {
    return (
      <Row className='row-margin-top' style={indentStyle}>
        <Col>
          <Input value={rule.field} readOnly disabled />
        </Col>
        <Col>
          <Input value={rule.value} readOnly disabled />
        </Col>
      </Row>
    );
  }
};

const AttributeSummary = () => {
  const attributeSelectors = useAttributeSelectors();

  console.log('criteriaUserAttributes', attributeSelectors.criteriaUserAttributes);
  return (
    <>
      {attributeSelectors.isAnySelectorNotNullOrEmpty && (
        <>
          <h4>Attributes Summary</h4>
          {attributeSelectors.userAttributeSelect && attributeSelectors.userAttributeSelect !== 'None' && (
            <Row>
              <Col xs={2}>
                <h5>User Attributes</h5>
              </Col>
              <Col xs={2}>
                <Input value={attributeSelectors.userAttributeSelect} readOnly disabled />
              </Col>
              {['Include', 'Exclude'].includes(attributeSelectors.userAttributeSelect) && (
                <Col>
                  {attributeSelectors.userAttributeRules?.rules?.map((rule, index) => (
                    <Row className='row-margin-top'>
                      {index % 2 === 0 ? (
                        <>
                          <Col>
                            <Input value={rule.field} readOnly disabled />
                          </Col>
                          <Col>
                            <Input value={rule.value} readOnly disabled />
                          </Col>
                        </>
                      ) : (
                        <Col xs={2}>
                          <Input value={rule} readOnly disabled />
                        </Col>
                      )}
                    </Row>
                  ))}
                </Col>
              )}
            </Row>
          )}
          {attributeSelectors.criteriaIncludeOrExcludeAffNames && (
            <Row className='row-margin-top'>
              <Col xs={2}>
                <h5>Affiliate Names</h5>
              </Col>
              <Col xs={2}>
                <Input value={attributeSelectors.criteriaIncludeOrExcludeAffNames} readOnly disabled />
              </Col>
              {(attributeSelectors.criteriaAffiliate === 'Include' ||
                attributeSelectors.criteriaAffiliate === 'Exclude') && (
                <Col xs='4'>
                  <MultiselectSummary value={attributeSelectors.criteriaAffNames} />
                </Col>
              )}
            </Row>
          )}
          {attributeSelectors.domainAffiliateConfiguration?.domainAffiliateEntries[0]?.includeExcludeAction && (
            <Row className='row-margin-top'>
              <Col xs={2}>
                <h5>Domain-specific Affiliates</h5>
              </Col>
              <Col xs={10}>
                {attributeSelectors.domainAffiliateConfiguration?.domainAffiliateEntries?.map((item, index) => (
                  <Card>
                    <CardBody>
                      <div key={index}>
                        <Row>
                          <Col>
                            <Label>Domains</Label>
                            <MultiselectSummary value={item?.domains} />
                          </Col>
                        </Row>
                        <Row style={{ paddingTop: '1rem' }}>
                          <Col>
                            <Label>Include/Exclude</Label>
                            <Input value={item?.includeExcludeAction} readOnly disabled />
                          </Col>
                          <Col>
                            <Label>Affiliates</Label>
                            <MultiselectSummary value={item?.affiliates} />
                          </Col>
                        </Row>
                      </div>
                    </CardBody>
                  </Card>
                ))}
              </Col>
            </Row>
          )}
          {attributeSelectors.criteriaLandingPageGroupIncludeExclude && (
            <Row className='row-margin-top'>
              <Col xs={2}>
                <h5>Landing Page Groups</h5>
              </Col>
              <Col xs={2}>
                <Input value={attributeSelectors.criteriaLandingPageGroupIncludeExclude} readOnly disabled />
              </Col>
              <Col xs='4'>
                <MultiselectSummary value={attributeSelectors.criteriaLandingPageGroupIds?.map((lpg) => lpg?.name || lpg)} />
              </Col>
            </Row>
          )}
          {attributeSelectors.criteriaLandingPageIncludeExclude && (
            <Row className='row-margin-top'>
              <Col xs={2}>
                <h5>Landing Pages</h5>
              </Col>
              <Col xs={2}>
                <Input value={attributeSelectors.criteriaLandingPageIncludeExclude} readOnly disabled />
              </Col>
              {(attributeSelectors.criteriaLandingPageIncludeExclude === 'Include' ||
                attributeSelectors.criteriaLandingPageIncludeExclude === 'Exclude') && (
                <Col xs={4}>
                  <MultiselectSummary value={attributeSelectors.criteriaLandingPageIds?.map((lp) => lp?.name || lp)} />
                </Col>
              )}
            </Row>
          )}
          {attributeSelectors.criteriaIncludeOrExcludeEmailDomains && (
            <Row className='row-margin-top'>
              <Col xs={2}>
                <h5>Email Domains</h5>
              </Col>
              <Col xs={2}>
                <Input value={attributeSelectors.criteriaIncludeOrExcludeEmailDomains} readOnly disabled />
              </Col>
              {(attributeSelectors.criteriaEmail === 'Include' || attributeSelectors.criteriaEmail === 'Exclude') && (
                <Col xs={4}>
                  <MultiselectSummary value={attributeSelectors.criteriaEmailDomains} />
                </Col>
              )}
            </Row>
          )}
          {attributeSelectors.selectedQuestion && (
            <>
              <Row className='row-margin-top'>
                <Col xs={12}>
                  <Label>Criteria Question</Label>
                  <Input value={attributeSelectors.selectedQuestion} readOnly disabled />
                </Col>
              </Row>
              {attributeSelectors.criteriaQuestionAnswers && (
                <Row className='row-margin-top'>
                  <Col xs={12}>
                    <Label>Criteria Question Answers</Label>
                    <MultiselectSummary value={attributeSelectors.criteriaQuestionAnswers} />
                  </Col>
                </Row>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

export default AttributeSummary;
