import { useQuery } from '@tanstack/react-query';
import api from '../../../services';

const fetchCurrentCapAmount = async (id) => {
  return api.get(`offer-action/current-cap-amount/daily/${id}`).then((res) => res.data);
};

export const useCurrentCapAmount = (id) =>
  useQuery({
    queryKey: ['currentCapAmount', id],
    queryFn: () => fetchCurrentCapAmount(id),
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });

const fetchCurrentCapAmountByDomains = async (id, domains) => {
  return api
    .get(`offer-action/current-cap-amount/daily/${id}?domain=${domains.join('&domain=')}`)
    .then((res) => res.data);
};

export const useCurrentCapAmountByDomains = (id, domains) =>
  useQuery({
    queryKey: ['currentCapAmount', id, domains],
    queryFn: () => fetchCurrentCapAmountByDomains(id, domains),
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    disabled: !domains.length || domains.length === 0,
    initialData: 0,
  });
