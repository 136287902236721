import { useQuery } from '@tanstack/react-query';
import api from '../../../services';
import { normalizeAdvertisers, sortByName } from '../../../actions';

export const useAdvertisers = () =>
  useQuery({
    queryKey: ['advertisers'],
    queryFn: () =>
      api.get('advertisers').then((response) => {
        let sortedAdvertisers = sortByName(response.data);
        const normalizedAdvertisers = normalizeAdvertisers(sortedAdvertisers);
        return normalizedAdvertisers;
      }),
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });

export const useAdvertiserPrePing = (advPrePingId) =>
  useQuery({
    queryKey: ['advertiserPrePing', advPrePingId],
    queryFn: () => api.get(`advertiser-pre-pings/${advPrePingId}`).then((response) => response.data),
    enabled: Boolean(advPrePingId),
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });
