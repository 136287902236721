import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, Col, Input, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { getEntitiesByActionId } from '../../actions';
import { getSitesByOfferId } from '../../views/Metadata/Sites/actions';
import { getOfferBlockSummaryByOfferId } from '../../views/Metadata/Sites/actions';
import './LocationsModal.css';
function LocationsModal(props) {
  const { content } = props;
  const [success, setSuccess] = useState(false);
  const [isOffer, setIsOffer] = useState(false);
  const dispatch = useDispatch();
  const locations = useSelector((state) => state.offerAction?.locations);
  const siteLocations = useSelector((state) => state.metadata?.siteLocations);
  const offerBlockLocations = useSelector((state) => state.metadata?.offerBlockLocations);

  const toggleSuccess = useCallback(async () => {
    if (!success) {
      const entityId = content.id;
      const isOffer = content && content.hasOwnProperty('offerType');

      if (isOffer) {
        await dispatch(getSitesByOfferId(entityId));
        await dispatch(getOfferBlockSummaryByOfferId(entityId));
      } else {
        await dispatch(getEntitiesByActionId(entityId));
      }
      setIsOffer(isOffer);
    }
    setSuccess(!success);
  }, [success, content, dispatch]);
  const handleEntityConfiguration = useCallback((entity, isOffer) => {
    if (isOffer && !entity.offerBlock) {
      return ['#', 'site', `site-${entity.id}`];
    }
    const url =
      !entity.offerType && !entity.offerAction && !entity.offerBlock
        ? `/campaigns/offerwallads/${entity.id}`
        : entity.offerType && entity.offerType.id === 8
        ? `/campaigns/landingpages/${entity.landingPageId}`
        : entity.offerAction
        ? `/campaigns/offeractions/${entity.id}`
        : entity.offerBlock
        ? `/campaigns/offerblocks/${entity.id}`
        : `/campaigns/offertable/offer/${entity.id}`;
    const entityType =
      !entity.offerType && !entity.offerAction && !entity.offerBlock
        ? 'offer wall ad'
        : entity.offerType && entity.offerType.id === 8
        ? 'landing page'
        : entity.offerAction
        ? 'offer action'
        : entity.offerBlock
        ? 'offer block'
        : 'offer';

    const computedId =
      entityType === 'offer wall ad'
        ? `offer-wall-ad-${entity.id}`
        : entityType === 'landing page'
        ? `landing-page-${entity.landingPageId}`
        : entityType === 'offer action'
        ? `offer-action-${entity.id}`
        : entityType === 'offer block'
        ? `offer-block-${entity.id}`
        : `offer-${entity.id}`;

    return [url, entityType, computedId];
  }, []);
  const renderOfferLocations = useCallback(() => {
    if (success) {
      const entityId = content.id;
      const entityLocations = isOffer ? [...siteLocations[entityId], ...offerBlockLocations[entityId]] : locations[entityId];
      return (
        <>
          <ModalBody>
            <div>
              {(entityLocations || []).map((entity) => {
                const [url, entityType, computedId] = handleEntityConfiguration(entity, isOffer);
                return (
                  <Row style={{ marginTop: 12, marginBottom: 12 }} key={entity.id}>
                    <Col lg={2}>
                      <Link to={url} target='_blank'>
                        <Button
                          color={'info'}
                          type='button'
                          title={
                            isOffer && !entity.offerBlock
                              ? 'This feature will be available when sites get moved to Zuul 2.0'
                              : `Visit ${entityType} details`
                          }
                          disabled={isOffer && !entity.offerBlock}

                        >
                          <i className='fa fa-search' />
                        </Button>
                      </Link>
                    </Col>

                    <Col lg={8}>
                      <Input
                        type='text'
                        id={computedId}
                        name={`${entityType}-name`}
                        disabled
                        title={
                          entityType === 'offer action'
                            ? 'Post Waterfall Action'
                            : entity.offerType
                            ? entity.offerType.name + ' Offer'
                            : entityType
                        }
                        className={entityType === 'offer action' ? 'post_waterfall_input' : entity.offerBlock ? "offerBlock" : " "}
                        value={entity.name || entity.domain}
                      />
                    </Col>
                  </Row>
                );
              })}
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color='secondary' onClick={toggleSuccess}>
              Cancel
            </Button>
          </ModalFooter>
        </>
      );
    }
    return <></>;
  }, [success, isOffer, content, siteLocations, locations, handleEntityConfiguration, toggleSuccess]);
  const entityId = content ? content.id : null;
  let numOflocations = 0;
  if (entityId === null || !locations) {
    return <></>;
  }
  if (entityId) {
    numOflocations =
      isOffer && (siteLocations[entityId] || offerBlockLocations[entityId])
        ? siteLocations[entityId].length + offerBlockLocations[entityId].length
        : locations[entityId] && locations[entityId].length;
  }
  return (
    <div id='locations-modal' className='animated'>
      <Button color='primary' id='locations-button' onClick={toggleSuccess}>
        <i className='fa fa-search' /> Locations
      </Button>
      <Modal isOpen={success} toggle={toggleSuccess} className={'modal-success'}>
        <ModalHeader toggle={toggleSuccess}>
          {isOffer ? 'Site' : 'Trigger'} Locations{` (${numOflocations})`}
        </ModalHeader>
        {renderOfferLocations()}
      </Modal>
    </div>
  );
}
export default LocationsModal;
