import React, { useState } from 'react';
import { Alert, Button, Col, Input, InputGroup, InputGroupAddon, Label, Nav, NavItem, NavLink, Row } from 'reactstrap';
import { Field } from 'redux-form';
import SearchLabel from '../../../../components/SearchLabel/SearchLabel';
import DraggableOffer from '../DraggableOffer';

const NameField = ({ input, disabled, type, placeholder, isPublished, meta: { touched, error } }) => (
  <>
    <div>
      <InputGroup>
        <Input {...input} disabled={disabled} placeholder={placeholder} type={type} />
        {!isPublished && (
          <InputGroupAddon addonType='append'>
            <Button color='primary' className='draftBtn' disabled onClick={(e) => e.preventDefault()}>
              Draft
            </Button>
          </InputGroupAddon>
        )}
      </InputGroup>
      {touched && error && <Alert color='danger'>{error}</Alert>}
    </div>
  </>
);

const LeftAside = ({ offers, isPublished }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [activeTab, setActiveTab] = useState('Offers');

  const draggableOffers = offers.filter((offer) => {
    const isOfferBlock = offer.offerType.name === 'Offer Block';
    const matchesSearch =
      offer?.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      offer?.id?.toString().includes(searchTerm) ||
      offer?.offerBlock?.id?.toString().includes(searchTerm);

    return (
      (activeTab === 'Offers' && !isOfferBlock && matchesSearch) ||
      (activeTab === 'Offer Blocks' && isOfferBlock && matchesSearch)
    );
  });

  return (
    <>
      <Row>
        <Col>
          <Label>Sequence Name</Label>
          <Field
            required
            type='text'
            component={NameField}
            name='sequenceName'
            placeholder='Sequence Name'
            isPublished={isPublished}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <SearchLabel />
          <Input
            type='text'
            placeholder='Use spaces to separate search terms'
            onChange={(e) => setSearchTerm(e.target.value)}
            value={searchTerm}
          />
        </Col>
      </Row>
      <Nav tabs style={{ display: 'flex' }}>
        <NavItem style={{ flex: 1, textAlign: 'center' }} onClick={() => setActiveTab('Offers')}>
          <NavLink active={activeTab === 'Offers'}>Offers</NavLink>
        </NavItem>
        <NavItem style={{ flex: 1, textAlign: 'center' }} onClick={() => setActiveTab('Offer Blocks')}>
          <NavLink active={activeTab === 'Offer Blocks'}>Offer Blocks</NavLink>
        </NavItem>
      </Nav>
      <Row>
        <div className='draggableOffersPool'>
          {draggableOffers.map((offer) => {
            return <DraggableOffer offer={offer} key={offer.id} />;
          })}
        </div>
      </Row>
    </>
  );
};

export default LeftAside;
