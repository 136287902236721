import api from '../../../services';
import { GET_OFFER_WALL_AD, UPDATE_OFFER_WALL_AD, CREATE_OFFER_WALL_AD, UPDATE_OFFER_WALL_AD_ERROR } from './types';

export const getOfferWallAd = (offerWallAd) => {
  return {
    type: GET_OFFER_WALL_AD,
    payload: offerWallAd,
  };
};

export const updateOfferWallAd = (offerWallAd) => {
  return {
    type: UPDATE_OFFER_WALL_AD,
    payload: offerWallAd,
  };
};

export const updateOfferWallAdError = (e) => {
  return {
    type: UPDATE_OFFER_WALL_AD_ERROR,
    payload: e.response.data.message
  };
};

export const createOfferWallAd = (offerWallAd) => {
  return {
    type: CREATE_OFFER_WALL_AD,
    payload: offerWallAd,
  };
};

export const editOfferWallAd = (offerWallAd, triggersWithActions, id) => (dispatch) => {
  try {
    return api.put(`offerwallads/${id}/withActions`, { entity: offerWallAd, triggersWithActions }).then((result) => {
      console.log('Saved offer wall ad: ' + JSON.stringify(result.data));
      dispatch(updateOfferWallAd(result.data));
      return result.data;
    }).catch(savedOfferWallAdError => {
      dispatch(updateOfferWallAdError(savedOfferWallAdError));
    });
  } catch (error) {
    console.log(error);
  }
};

export const createOfferWallAdApi = (offerWallAd, triggersWithActions) => (dispatch) => {
  try {
    return api.post('offerwallads/withActions', { entity: offerWallAd, triggersWithActions }).then((result) => {
      console.log('Created offer wall ad: ' + JSON.stringify(result.data));
      dispatch(createOfferWallAd(result.data));
      return result.data;
    }).catch(savedOfferWallAdError => {
      dispatch(updateOfferWallAdError(savedOfferWallAdError));
    });
  } catch (error) {
    console.log(error);
  }
};
