import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import 'react-widgets/styles.css';
import { Alert, Card, CardBody, CardHeader, Col, Collapse, Row } from 'reactstrap';
import { change, reduxForm } from 'redux-form';
import {
  addOfferAction,
  addOfferActionCriteria,
  editOfferAction,
  findAllMarketingPartnersByOfferAction,
  getOfferAction,
  getOfferActionCriteria,
  getOfferActionSubtype,
  getUserAttributes,
  normalizeAdvertisers,
  saveAsOfferName,
  saveOffer,
  setOfferAction,
  setOfferActions,
  sortByName,
  updateCriteriaType,
  updateMarketingPartnersByOfferAction,
  updateSelector,
} from '../../../actions';
import Criteria from '../../../components/Criteria/Criteria';
import LocationsModal from '../../../components/LocationsModal/LocationsModal';
import ZuulLoader from '../../../components/ZuulLoader/ZuulLoader';
import api from '../../../services';
import { handleCriteriaInitialize, handleCriteriaSubmit } from '../../../utils/CriteriaHelpers';
import { PAYOUT_SCHEME } from '../../../utils/constants';
import { fetchAllHandlers } from '../../Metadata/Handlers/actions';
import ActiveStatusBadge from '../OfferTitle/ActiveStatusBadge';
import ActiveStatusModal from '../OfferTitle/ActiveStatusModal';
import '../OfferTitle/OfferQuestion/OfferQuestion.css';
import validate from '../validate';
import Linkout from './Linkout';
import { handleOfferActionSubtypeInit, parseOfferActionSubtype } from './OfferActionsHelpers';
import Post from './Post';
import Waterfall from './Waterfall';
import SaveAsOfferActionModal from './SaveAsOfferActionModal';
import SaveOfferActionModal from './SaveOfferActionModal';
import UserAttribute from './UserAttribute';

async function fetchMarketingPartnersIfRequired(props, offerActionType, offerActionId) {
  if (offerActionType === 'POST') {
    return await props.findAllMarketingPartnersByOfferAction(offerActionId);
  }
  return [];
}

function determineSelectedOfferActionSubtype(selectedOfferAction) {
  return (
    selectedOfferAction.offerActionPost ||
    selectedOfferAction.offerActionLinkout ||
    (selectedOfferAction.offerActionUserAttributes?.length > 0 && selectedOfferAction.offerActionUserAttributes[0]) ||
    selectedOfferAction.offerActionPostWaterfall ||
    selectedOfferAction.offerActionLinkoutWaterfall ||
    null
  );
}

class OfferActionDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      offerAction: {},
      selectedOfferAction: null,
      offerActionType: '',
      value: [],
      content: {
        isActive: false,
      },
      isActive: false,
      isLoading: true,
      actionName: 'Offer Action',
      isEditMode: false,
      userAttribute: 'select',
      questionAction: [],
      questionText: '',
      advertisers: [],
      offerActionSubtypeId: null,
      collapse: false,
      collapseOfferAction: true,
      collapseHeaderFooter: false,
      collapseTinymceHeadline: false,
      httpIsHidden: true,
      errors: {
        disable: false,
      },
      advancedSettingsIsHidden: true,
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.handleInitialize = this.handleInitialize.bind(this);
    this.getErrors = this.getErrors.bind(this);
    // this.handleHttpStatusToggle = this.handleHttpStatusToggle.bind(this);
    this.handleAdvancedSettingsToggle = this.handleAdvancedSettingsToggle.bind(this);
    this.handleChangeQuestionText = this.handleChangeQuestionText.bind(this);
    this.findIdFromName = this.findIdFromName.bind(this);
  }

  handleTitleChange = ({ target: { value } }) => {
    this.setState({
      actionName: value,
    });
  };

  handleAdvancedSettingsToggle = () => {
    this.setState({
      advancedSettingsIsHidden: !this.state.advancedSettingsIsHidden,
    });
  };

  findIdFromName(jsonContainer, name) {
    for (let o of jsonContainer) {
      if (o.name === name) {
        const { id, name, code } = o;
        return { id, name, code };
      }
    }
    return null;
  }

  handleChangeQuestionText = (event) => {
    this.setState({
      questionText: event.target.value,
      errors: Object.assign({}, this.state.errors, {
        questionText: event.target.value.length === 0,
      }),
    });
  };

  getStatusErrors = () => {
    const { offerActionType } = this.props;
    const { url, attributeName, attributeValue } = this.props;
    let errors;
    if (!url && (offerActionType === 'POST' || offerActionType === 'LINKOUT')) {
      errors = {
        ...this.state.errors,
        isUrlMissing: true,
        _error: 'You must enter a url to mark this offer action as active.',
      };
    } else if (url && (offerActionType === 'POST' || offerActionType === 'LINKOUT')) {
      errors = {
        ...this.state.errors,
        isUrlMissing: false,
        _error: '',
      };
    } else if ((!attributeName || !attributeValue) && offerActionType === 'USER_ATTRIBUTE') {
      errors = {
        ...this.state.errors,
        isUserAttributeMissing: true,
        _error: 'Please fill out user attribute fields',
      };
    } else if (attributeName && attributeValue && offerActionType === 'USER_ATTRIBUTE') {
      errors = {
        ...this.state.errors,
        isUserAttributeMissing: false,
        _error: '',
      };
    }
    return errors;
  };

  getErrors = () => {
    let errors = {
      ...this.state.errors,
      invalid: this.props.submitFailed ? this.props.invalid : false,
    };
    errors._error = errors.invalid ? 'One or more of the fields you entered are invalid.' : null;
    return errors;
  };

  async handleInitialize() {
    let { offerActionSubtype, offerCriteria, offerAction, offerActionType, marketingPartners } = this.state;
    console.log(this.state);
    if (!offerActionType) return false;
    await this.fetchCurrentCapAmount(offerCriteria.id);

    const criteriaObj = handleCriteriaInitialize(offerCriteria);
    let {
      basicAuthPassword = null,
      basicAuthUsername = null,
      body = null,
      capAmount = null,
      capInterval = null,
      capSegmentConfiguration = null,
      claimTrustedForm = false,
      hasRevenue = null,
      httpHeader1 = null,
      httpHeader2 = null,
      httpHeader3 = null,
      httpKillSwitchHandler = null,
      postMethod = 'GET',
      httpSuccessHandler = null,
      delayedPostingType = null,
      delayedPostingAmount = null,
      isPreProcessed = false,
      otherKillSwitchStatus = null,
      otherSuccessfulHttpStatus = null,
      name = null,
      advertiser = null,
      payoutAmount = null,
      payoutType = null,
      revenue = null,
      revenueAmount = null,
      revenueOn = null,
      url = null,
      userAttributeValue,
    } = offerActionSubtype;
    name = offerAction.title ? offerAction.title : name;
    capAmount = criteriaObj.capAmount && criteriaObj.capAmount !== null ? criteriaObj.capAmount : capAmount;
    capInterval = criteriaObj.capInterval && criteriaObj.capInterval !== null ? criteriaObj.capInterval : capInterval;
    capSegmentConfiguration =
      criteriaObj.capSegmentConfiguration && criteriaObj.capSegmentConfiguration !== null
        ? criteriaObj.capSegmentConfiguration
        : capSegmentConfiguration;
    hasRevenue = hasRevenue || revenue || revenueAmount ? true : false;
    let initData = {
      offerCriteriaId: offerCriteria.id,
    };
    if (offerActionType === 'USER_ATTRIBUTE') {
      const attributeName =
        userAttributeValue && userAttributeValue.userAttribute ? userAttributeValue.userAttribute : null;
      const attributeValue = userAttributeValue ? userAttributeValue.userAttributeValue : null;
      const userAttributesValueString = userAttributeValue ? userAttributeValue.userAttributesValueString : null;
      initData = {
        ...initData,
        title: name || offerAction.title,
        attributeName: attributeName,
        attributeValue,
        userAttributesValueString,
        offerActionType,
        ...criteriaObj,
      };
    } else if (offerActionType?.includes('WATERFALL')) {
      initData = handleOfferActionSubtypeInit(offerAction);
    } else {
      initData = {
        ...initData,
        advertiser,
        capAmount,
        capInterval,
        capSegmentConfiguration,
        httpKillSwitchHandler,
        httpSuccessHandler,
        title: name,
        offerActionType,
        revenue: revenueAmount || revenue,
        url,
        ...criteriaObj,
      };
      if (offerActionType === 'POST') {
        const isTcpaCompliant = offerAction?.offerActionPost ? offerAction.offerActionPost.isTcpaCompliant : false;

        initData = {
          ...initData,
          advertiser:
            initData.advertiser === null
              ? this.deriveObjFromName(this.state.advertisers, 'What If')
              : initData.advertiser,
          basicAuthPassword,
          basicAuthUsername,
          body,
          claimTrustedForm,
          hasRevenue,
          httpHeader1,
          httpHeader2,
          httpHeader3,
          postMethod,
          delayedPostingType,
          delayedPostingAmount,
          isPreProcessed,
          otherKillSwitchStatus,
          otherSuccessfulHttpStatus,
          revenueOn,
          isTcpaCompliant,
          marketingPartners,
        };
      } else {
        initData = {
          ...initData,
          payoutAmount,
          payoutType: payoutType && payoutType.id !== null ? payoutType.id : null,
        };
      }
    }
    console.log(initData);
    this.props.initialize(initData);
    this.setState({
      isLoading: false,
    });
  }

  deriveObjFromName(array, name) {
    const mapArrayToObj = _.mapKeys(array, 'name');
    return mapArrayToObj[name];
  }

  onSubmit(values) {
    console.log(values);
    const parsedOfferActionSubtype = parseOfferActionSubtype(values);

    const { newOfferActionName } = this.props;
    const { offerActionType } = this.props;
    let {
      attributeName = null,
      attributeValue = null,
      userAttributesValueString = null,
      basicAuthPassword = null,
      basicAuthUsername = null,
      body = null,
      claimTrustedForm = false,
      httpHeader1 = null,
      httpHeader2 = null,
      httpHeader3 = null,
      delayedPostingType = null,
      delayedPostingAmount = null,
      isPreProcessed = false,
      httpKillSwitchHandler = null,
      postMethod = 'GET',
      httpSuccessHandler = null,
      otherKillSwitchStatus = null,
      otherSuccessfulHttpStatus = null,
      title = null,
      advertiser = null,
      payoutAmount = null,
      payoutType = null,
      revenue = null,
      revenueOn = null,
      url = null,
      isTcpaCompliant = false,
    } = values;
    payoutType = payoutType && PAYOUT_SCHEME[parseInt(payoutType)];
    revenueOn = revenueOn === 'None' ? null : revenueOn;
    revenue = revenueOn === null ? null : revenue;

    let offerCriteria = handleCriteriaSubmit(values, this.props.handlers);

    let offerAction;
    const zuulUser = JSON.parse(localStorage.getItem('userData'));
    if (this.state.isEditMode && !newOfferActionName) {
      const currentActiveStatus = this.state.active !== null ? this.state.active : this.state.offerAction.active;
      offerCriteria.id = this.state.offerAction.offerCriteria ? this.state.offerAction.offerCriteria.id : null;
      offerAction = {
        active: currentActiveStatus,
        id: this.state.offerAction.id,
        lastEdited: new Date(),
        offerActionType,
        offerCriteria,
        title,
        zuulUser,
      };
    } else {
      let isActive;
      if (newOfferActionName) {
        isActive = this.state.active !== null ? this.state.active : this.state.offerAction.active;
      }

      if (offerActionType === 'USER_ATTRIBUTE') {
        isActive = attributeName ? true : false;
        console.log(isActive);
      } else {
        isActive = url ? true : false;
      }

      offerAction = {
        active: isActive,
        lastEdited: new Date(),
        offerActionType,
        offerCriteria,
        title: title,
        zuulUser,
      };
    }

    let newOfferAction;
    if (offerActionType === 'USER_ATTRIBUTE') {
      attributeName =
        typeof attributeName === 'string'
          ? attributeName
          : typeof attributeName === 'object' && attributeName !== null
          ? attributeName.name
          : '';
      attributeValue = attributeValue && attributeValue === 'false' ? false : true;
      newOfferAction = {
        offerAction,
        userAttributeValue: {
          userAttribute: {
            name: attributeName,
          },
        },
      };
      if (this.state.isEditMode && !newOfferActionName) {
        newOfferAction = { ...this.state.offerActionSubtype, offerAction };
      }
      const userAttrObj = attributeName ? this.deriveObjFromName(this.props.userAttributes, attributeName) : {};
      newOfferAction.userAttributeValue.userAttribute.name = userAttrObj.name;
      newOfferAction.userAttributeValue.userAttribute.id = userAttrObj.id;
      newOfferAction.userAttributeValue.userAttributeValue = attributeValue;
      newOfferAction.userAttributeValue.userAttributesValueString = userAttributesValueString;
    } else if (offerActionType?.includes('WATERFALL')) {
      newOfferAction = { ...parsedOfferActionSubtype, offerAction };
    } else {
      newOfferAction = {
        name: title,
        offerAction,
        url,
      };
      if (this.state.offerActionSubtypeId && !newOfferActionName) {
        newOfferAction.id = this.state.offerActionSubtypeId;
      }
      let advertiserValue = null;
      if (advertiser && typeof advertiser === 'string') {
        advertiserValue = this.deriveObjFromName(this.state.advertisers, advertiser);
        newOfferAction = Object.assign(newOfferAction, {
          advertiser: advertiserValue,
        });
      } else if (typeof advertiser === 'object') {
        newOfferAction = Object.assign(newOfferAction, {
          advertiser,
        });
      }
      if (httpKillSwitchHandler && typeof httpKillSwitchHandler === 'string') {
        httpKillSwitchHandler = this.deriveObjFromName(this.props.handlers, httpKillSwitchHandler);
        newOfferAction = Object.assign(newOfferAction, {
          httpKillSwitchHandler,
        });
      } else if (typeof httpKillSwitchHandler === 'object') {
        newOfferAction = Object.assign(newOfferAction, {
          httpKillSwitchHandler,
        });
      }
      if (httpSuccessHandler && typeof httpSuccessHandler === 'string') {
        httpSuccessHandler = this.deriveObjFromName(this.props.handlers, httpSuccessHandler);
        newOfferAction = Object.assign(newOfferAction, { httpSuccessHandler });
      } else if (typeof httpSuccessHandler === 'object') {
        newOfferAction = Object.assign(newOfferAction, {
          httpSuccessHandler,
        });
      }
      if (offerActionType === 'LINKOUT') {
        newOfferAction = {
          ...newOfferAction,
          payoutAmount,
          payoutType,
          revenue: revenue ? parseFloat(revenue) : null,
        };
      } else if (offerActionType === 'POST') {
        if (this.state.isEditMode && isTcpaCompliant && values.marketingPartners.length > 0) {
          this.updateMarketingPartnersThatWereRemoved(values.marketingPartners, newOfferAction);
          this.attachOfferActionToExistingMarketingPartners(values.marketingPartners, newOfferAction);
        }
        newOfferAction = {
          ...newOfferAction,
          basicAuthPassword,
          basicAuthUsername,
          body,
          claimTrustedForm,
          httpHeader1,
          httpHeader2,
          httpHeader3,
          postMethod: postMethod === 'NONE' ? 'GET' : postMethod,
          delayedPostingType,
          isPreProcessed,
          delayedPostingAmount,
          otherKillSwitchStatus,
          otherSuccessfulHttpStatus,
          revenueAmount: revenue ? parseFloat(revenue) : null,
          revenueOn,
          isTcpaCompliant,
        };
      }
    }
    console.log(newOfferAction);
    console.log(JSON.stringify(newOfferAction));
    if (this.state.isEditMode && !newOfferActionName) {
      return this.props.editOfferAction(newOfferAction, offerActionType, this.state.offerActionSubtypeId).then(() => {
        this.props.saveOffer(true);
        this.props.setOfferActions([]);
      });
    }
    if (newOfferActionName) {
      newOfferAction.name = newOfferActionName;
      newOfferAction.offerAction.title = newOfferActionName;
    }
    if (offerActionType === 'POST') {
      if (newOfferAction.advertiser === null || newOfferAction.advertiser === '') {
        newOfferAction.advertiser = this.deriveObjFromName(this.state.advertisers, 'What If');
      }
    }
    return this.props.addOfferAction(newOfferAction, offerActionType).then(() => {
      this.props.saveAsOfferName('');
      this.props.saveOffer(true);
      this.props.setOfferActions([]);
      if (!newOfferActionName) {
        this.props.history.push('/campaigns/offeractions');
      }
    });
  }

  updateMarketingPartnersThatWereRemoved = (marketingPartners, offerActionPost) => {
    function byId(a, b) {
      return a.id === b.id;
    }

    const marketingPartnersThatWereRemoved =
      _.differenceWith(this.state.marketingPartners, marketingPartners, byId) || [];

    marketingPartnersThatWereRemoved.forEach((marketingPartner) => {
      const indexOfOfferAction = _.findIndex(marketingPartner.offerActions, (offerAction) => {
        return offerAction.id === offerActionPost.offerAction.id;
      });
      marketingPartner.offerActions.splice(indexOfOfferAction, 1);
      this.props.updateMarketingPartnersByOfferAction(marketingPartner);
    });
  };

  attachOfferActionToExistingMarketingPartners = (marketingPartners, offerActionPost) => {
    marketingPartners.forEach((marketingPartner) => {
      const shouldAddOfferActionToMP = !_.find(
        marketingPartner?.offerActions,
        (offerAction) => offerAction.id === offerActionPost?.offerAction?.id
      );
      if (shouldAddOfferActionToMP) {
        marketingPartner.offerActions.push(offerActionPost.offerAction);
        marketingPartner.offerActions = marketingPartner.offerActions.map((offerAction) => {
          return { id: offerAction.id };
        });
        this.props.updateMarketingPartnersByOfferAction(marketingPartner);
      }
    });
  };

  renderAppSwitch = () => {
    return (
      <ActiveStatusModal
        isActive={this.state.active}
        disabled={this.state.isLoading}
        content={this.state.offerAction}
        errors={this.getStatusErrors()}
        getErrors={this.getStatusErrors}
        onClickSubmit={this.onClickSubmit}
      />
    );
  };

  renderBadge = () => {
    return this.state.isEditMode ? <ActiveStatusBadge isActive={this.state.active} /> : <></>;
  };

  onClickSubmit = (activeStatus) => {
    const { id } = this.state.offerAction;
    let isActive = activeStatus !== undefined ? activeStatus : this.state.content.isActive;
    console.log(this.state.offerAction);
    const zuulUser = JSON.parse(localStorage.getItem('userData'));
    const update = {
      id,
      active: isActive,
      zuulUser,
    };

    api.put(`offer-action/active/${id}`, update).then((result) => {
      console.log(JSON.stringify(result));
      this.setState({
        active: isActive,
        offerAction: { ...this.state.offerAction, active: isActive },
      });
    });
  };
  renderActionType = () => {
    const {
      numOfAdvancedFields,
      offerActionType,
      postMethod,
      hasRevenue,
      payoutType,
      handlers,
      userAttributes,
    } = this.props;
    const { advertisers, advancedSettingsIsHidden, isEditMode, offerCriteriaId } = this.state;
    const { handleTitleChange, handleAdvancedSettingsToggle } = this;
    const props = {
      numOfAdvancedFields,
      userAttributes,
      postMethod,
      payoutType,
      hasRevenue,
      advertisers,
      isEditMode,
      offerCriteriaId,
      handlers,
      advancedSettingsIsHidden,
      handleTitleChange,
      handleAdvancedSettingsToggle,
    };
    switch (offerActionType) {
      case 'LINKOUT':
        return (
          <Linkout
            domains={this.state.domains}
            {...props}
            offerCriteriaId={this.state?.offerAction?.offerCriteria.id}
          />
        );
      case 'POST':
        return <Post domains={this.state.domains} {...props} />;
      case 'USER_ATTRIBUTE':
        return <UserAttribute {...props} offerCriteriaId={this.state?.offerAction?.offerCriteria.id} />;
      default:
        return <Waterfall {...props} offerActionType={offerActionType} />;
    }
  };

  getAdvertisers() {
    return new Promise((resolve) => {
      api
        .get('advertisers')
        .then((response) => {
          let sortedAdvertisers = sortByName(response.data);
          const normalizedAdvertisers = normalizeAdvertisers(sortedAdvertisers);
          const newState = Object.assign({}, this.state, {
            advertisers: normalizedAdvertisers,
          });
          this.setState(newState);
          resolve(newState);
        })
        .catch((error) => console.log(error));
    });
  }

  fetchCurrentCapAmount = async (offerCriteriaId) => {
    try {
      const response = await api.get(`offer-action/current-cap-amount/daily/${offerCriteriaId}`);
      const currentCapAmount = response.data;
      this.props.dispatch(change('offerActionDetail', 'currentCapAmount', currentCapAmount));
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  async getDomains() {
    try {
      const response = await api.get('domains');
      const domains = response.data.map((a) => a.name);
      return domains;
    } catch (error) {
      throw error;
    }
  }

  async componentDidMount() {
    this.props.updateCriteriaType('Offer Action Criteria');
    this.props.updateSelector('Action Detail');

    try {
      const domains = await this.getDomains();
      this.setState({ domains });

      this.props.getUserAttributes();
      this.props.fetchAllHandlers();
      await this.getAdvertisers();

      const { actionId } = this.props.match.params;
      const { state } = this.props.location;
      if (actionId) {
        const selectedOfferAction = await this.props.getOfferAction(actionId);
        const { offerActionType, id: offerActionId, active, title } = selectedOfferAction || {};

        const marketingPartners = await fetchMarketingPartnersIfRequired(this.props, offerActionType, offerActionId);
        const selectedOfferActionSubtype = determineSelectedOfferActionSubtype(selectedOfferAction);
        const offerCriteria = selectedOfferAction?.offerCriteria;
        const offerActionSubtypeId = selectedOfferActionSubtype?.id;
        const actionName = title || selectedOfferActionSubtype?.name;

        this.setState(
          {
            active,
            offerAction: selectedOfferAction,
            offerActionType,
            offerActionSubtype: selectedOfferActionSubtype,
            offerCriteria,
            offerActionSubtypeId,
            isEditMode: true,
            actionName,
            marketingPartners,
          },
          this.handleInitialize
        );
      } else {
        const offerAction = state?.offerAction;
        const offerActionSubtype = state?.offerActionSubtype;
        this.setState(
          {
            offerAction,
            offerActionSubtype,
            offerActionSubtypeId: offerActionSubtype?.id,
            offerActionType: state?.offerActionType,
            offerCriteria: offerAction?.offerCriteria,
          },
          this.handleInitialize
        );
        this.setState({
          isLoading: false,
        });
      }
    } catch (error) {
      console.error('Error in componentDidMount:', error);
    }
  }

  componentWillUnmount() {
    this.props.setOfferAction({});
  }

  componentWillReceiveProps(nextProps) {
    nextProps.isOfferSaved &&
      setTimeout(() => {
        this.props.saveOffer(false);
      }, 4000);
  }

  renderActionHeader() {
    const { offerActionType } = this.props;
    switch (offerActionType) {
      case 'LINKOUT':
        return 'Linkout';
      case 'USER_ATTRIBUTE':
        return 'User Attribute';
      case 'POST_WATERFALL':
        return 'Post Waterfall';
      case 'LINKOUT_WATERFALL':
        return 'Linkout Waterfall';
      default:
        return 'Post';
    }
  }

  resetForm = () => this.props.reset();

  render() {
    const errors = this.getErrors();
    const shouldErrorRender = Object.keys(errors).some((x) => errors[x]);

    if (this.state.isLoading) {
      return <ZuulLoader />;
    }
    return (
      <div className='animated fadeIn'>
        <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
          <Card>
            <CardHeader>
              <Row>
                <Col
                  onClick={() => {
                    this.setState({ collapseOfferAction: !this.state.collapseOfferAction });
                  }}
                  lg='8'
                  sm='8'
                >
                  <span style={{ display: 'flex', alignItems: 'baseline' }}>
                    {this.renderBadge()} &nbsp;&nbsp;
                    <h4>{this.state.actionName}</h4>
                    &nbsp;&nbsp;
                    <h6>
                      <em>{this.renderActionHeader()} Details</em> &nbsp;&nbsp;
                      {this.state.collapseOfferAction ? (
                        <i className='fa fa-chevron-up opened' />
                      ) : (
                        <i className='fa fa-chevron-down closed' />
                      )}
                    </h6>
                  </span>
                </Col>
                <Col lg='3' sm='2' xs='10' id='action-detail-locations-modal'>
                  <LocationsModal content={this.props.offerAction ? this.props.offerAction.offerAction : {}} />
                </Col>
                <Col lg='1' sm='2' xs='2' className='renderAppSwitchCol'>
                  {this.renderAppSwitch()}
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <Collapse isOpen={this.state.collapseOfferAction}>
                <Row>
                  <Col lg='12'>{this.renderActionType()}</Col>
                </Row>
                <br />
              </Collapse>
            </CardBody>
          </Card>
          {!this.props.offerActionType?.includes('WATERFALL') && <Criteria />}
          <Row>
            <Col lg={6}>
              {shouldErrorRender && <Alert color='danger'>{errors._error}</Alert>}
              {this.props.isOfferSaved && <Alert color='success'>Offer Action Saved Successfully</Alert>}
            </Col>
            <Col lg={6}>
              {this.state.isEditMode && (
                <SaveAsOfferActionModal
                  getErrors={this.getErrors}
                  onClickSubmit={this.props.handleSubmit(this.onSubmit)}
                />
              )}
              <SaveOfferActionModal
                offerActionName={this.props.title}
                getErrors={this.getErrors}
                isEditMode={this.state.isEditMode}
                onClickSubmit={this.props.handleSubmit(this.onSubmit)}
              />
            </Col>
          </Row>
        </form>
      </div>
    );
  }
}

const form = reduxForm({
  form: 'offerActionDetail',
  validate,
});

function mapStateToProps(state) {
  const { offerAction } = state;
  const { newOfferName, isOfferSaved } = state.offerState;
  const { additionalOffersArray, additionalOffersObj, userAttributes } = state.criteria;
  const { handlers } = state.metadata;
  const { selector } = offerAction;
  const advancedFields = {
    httpHeader1: selector(state, 'httpHeader1'),
    httpHeader2: selector(state, 'httpHeader2'),
    httpHeader3: selector(state, 'httpHeader3'),
    basicAuthUsername: selector(state, 'basicAuthUsername'),
    basicAuthPassword: selector(state, 'basicAuthPassword'),
    otherSuccessfulHttpStatus: selector(state, 'otherSuccessfulHttpStatus'),
    httpKillSwitchHandler: selector(state, 'httpKillSwitchHandler'),
    otherKillSwitchStatus: selector(state, 'otherKillSwitchStatus'),
    delayedPostingType: selector(state, 'delayedPostingType'),
    delayedPostingAmount: selector(state, 'delayedPostingAmount'),
  };
  const numOfAdvancedFields = Object.keys(advancedFields).reduce((acc, key) => {
    return acc + (advancedFields[key] ? 1 : 0);
  }, 0);
  return {
    additionalOffersArray,
    additionalOffersObj,
    hasRevenue: !!selector(state, 'hasRevenue'),
    has_payout: !!selector(state, 'has_payout'),
    handlers,
    payoutType: selector(state, 'payoutType'),
    postMethod: selector(state, 'postMethod'),
    isOfferSaved,
    url: selector(state, 'url'),
    newOfferActionName: newOfferName,
    attributeName: selector(state, 'attributeName'),
    attributeValue: selector(state, 'attributeValue'),
    numOfAdvancedFields,
    offerAction,
    offerActionType: selector(state, 'offerActionType') || 'POST',
    selector,
    title: selector(state, 'title') || selector(state, 'attributeName'),
    userAttributes,
  };
}

export default connect(mapStateToProps, {
  getOfferAction,
  getOfferActionSubtype,
  getOfferActionCriteria,
  addOfferActionCriteria,
  addOfferAction,
  editOfferAction,
  saveAsOfferName,
  saveOffer,
  updateCriteriaType,
  updateSelector,
  setOfferAction,
  setOfferActions,
  findAllMarketingPartnersByOfferAction,
  updateMarketingPartnersByOfferAction,
  fetchAllHandlers,
  getUserAttributes,
})(form(OfferActionDetail));
