import React, { Component } from 'react';
import { differenceBy, uniqBy } from 'lodash';
import { Link } from 'react-router-dom';
import { Input, Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import api from '../../services';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import './UserDetails.css';
class Users extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDisabled: true,
      searchValue: '',
      users: [],
      usersSelected: [],
      loading: false,
      open: false,
      shouldRender: true,
    };

    this.toggle = this.toggle.bind(this);
  }
  handleOnChange = (event) => {
    this.setState({ searchValue: event.target.value, shouldRender: true });
  };

  setSelectedRows = (rows, isChecked) => {
    let selectedUsers = [];
    const { usersSelected } = this.state;
    if (!isChecked) {
      const deSelected = Array.isArray(rows) ? rows : [rows];
      selectedUsers = differenceBy(usersSelected, deSelected, 'id');
    } else {
      selectedUsers = Array.isArray(rows) ? rows : [rows];
      selectedUsers = uniqBy(usersSelected.concat(selectedUsers), 'id');
    }
    let isDisabled = selectedUsers.length === 0;
    this.setState({
      usersSelected: selectedUsers,
      shouldRender: false,
      isDisabled,
    });
    return selectedUsers.map((user) => user.id);
  };

  eraseSelectedRows = () => {
    this.setState({ usersSelected: [], shouldRender: false, isDisabled: true });
  };

  handleSearch = () => {
    this.eraseSelectedRows();
    this.makeApiCall(this.state.searchValue);
  };

  makeApiCall = async (searchInput) => {
    this.setState({ loading: true, shouldRender: true });
    var isEmail = searchInput.indexOf('@');
    var url = 'user';
    if (isEmail >= 0) {
      url = url + '/email/' + searchInput;
    } else {
      url = url + '/phone/' + searchInput;
    }
    let res = await api.get(url);

    this.setState({
      users: res.data,
      open: false,
      loading: false,
      shouldRender: true,
    });
  };

  toggle() {
    if (this.state.usersSelected.length > 0) {
      this.setState({ open: !this.state.open, shouldRender: true });
    }
  }

  deidentifyUser = async (searchInput) => {
    var ids = '';
    for (let i = 0; i < this.state.usersSelected.length; i++) {
      if (i + 1 === this.state.usersSelected.length) {
        ids = ids + this.state.usersSelected[i].id;
      } else {
        ids = ids + this.state.usersSelected[i].id + ',';
      }
    }
    let res = await api.put(`user/deidentify/userIds/?userIds=${ids}`);

    if (res.status === 200) {
      this.handleSearch();
    }
  };

  renderUserTable = () => {
    const columns = [
      {
        text: 'User Details Location',
        dataField: 'id',
        formatter: (cell, row) => (
          <div>
            <Link to={`/userdetails/${cell}`} target='_blank'>
              <Button color='info' type='button' title='Visit user details' className='fa fa-search'></Button>
            </Link>
            &nbsp;&nbsp; UserID: {cell}
          </div>
        ),
      },
      {
        dataField: 'email',
        text: 'Email',
        sort: true,
      },
      {
        dataField: 'phone',
        text: 'Phone',
        sort: true,
      },
      {
        dataField: 'firstName',
        text: 'First Name',
        sort: true,
      },
      {
        dataField: 'lastName',
        text: 'Last Name',
        sort: true,
      },
      {
        dataField: 'address1',
        text: 'Address',
        sort: true,
      },
      {
        dataField: 'dob',
        text: 'Date of Birth',
        sort: true,
      },
      // ,
      // {
      //   dataField: 'esId',
      //   text: 'esId',
      //   sort: true
      // }
    ];

    const selectRow = {
      mode: 'checkbox', // multiple row selection
      clickToSelect: false,
      onSelect: (row, isChecked, rowIndex, e) => {
        this.setSelectedRows(row, isChecked);
      },
      onSelectAll: (isChecked, rows, e) => {
        if (isChecked) {
          this.setSelectedRows(rows, isChecked);
        } else {
          this.eraseSelectedRows();
          return true;
        }
      },
      selected: this.state.usersSelected.map((user) => user.id),
    };
    return (
      <BootstrapTable
        selectRow={selectRow}
        // ref='table'
        keyField='id'
        data={this.state.users}
        columns={columns}
        pagination={paginationFactory()}
        sort
      />
    );
  };
  render() {
    return (
      <div>
        <div className='display-4'>Users</div>
        <hr />
        <Row>
          <Col lg={10}>
            <Input
              name='text'
              type='text'
              placeholder='Search Email or Phone Number'
              onChange={(event) => this.handleOnChange(event)}
              value={this.state.searchValue}
            />
          </Col>
          <Col lg={2}>
            <Button
              color='primary'
              onClick={() => {
                if (this.state.searchValue != null && this.state.searchValue !== '') {
                  this.handleSearch();
                }
              }}
            >
              Search
            </Button>{' '}
            <br />
          </Col>
        </Row>
        {this.state.users.length > 0 ? (
          <p className='text-success'>Select some of these users</p>
        ) : (
          <p className='text-warning'>Try searching for new user</p>
        )}
        {this.renderUserTable()}
        <br />
        <div>
          <div>
            <div className='float-right'>
              <Button
                color='danger'
                className='deIdentifybtn'
                disabled={this.state.isDisabled}
                onClick={() => this.toggle()}
              >
                <i className='fa fa-trash'></i>&nbsp; DeIdentify
              </Button>
              <br />
            </div>
            <br />
            <Modal isOpen={this.state.open} toggle={this.toggle} className={'modal-danger'}>
              <ModalHeader toggle={this.toggleSuccess}>Delete User</ModalHeader>
              <ModalBody>
                <Row>
                  <Col
                    lg={2}
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      color: '#f86c6b',
                    }}
                  >
                    <i className='fa fa-warning fa-3x'></i>
                  </Col>
                  <Col lg={10}>
                    {`Are you sure you want to delete the user${this.state.usersSelected.length > 1 ? 's' : ''} with the
                    following credentials:`}
                    <br />
                    <br />
                    {this.state.usersSelected.map((item, index) => (
                      <div key={item.id}>
                        <span>
                          &nbsp;&nbsp;&nbsp; <strong>&nbsp;ID:</strong> {item.id}
                        </span>
                        <br />
                        <span>
                          &nbsp;&nbsp;&nbsp;{' '}
                          <strong>
                            <i className='fa fa-envelope'></i> &nbsp;Email:
                          </strong>{' '}
                          {item.email}
                        </span>
                        <br />
                        <span>
                          &nbsp;&nbsp;&nbsp;&nbsp;{' '}
                          <strong>
                            <i className='fa fa-phone'></i> &nbsp;Phone:
                          </strong>{' '}
                          {item.phone}
                        </span>
                        <br />
                        <hr />
                      </div>
                    ))}
                  </Col>
                </Row>
                <br />
              </ModalBody>
              <ModalFooter>
                <Button className='deIdentifyBtn' color='danger' onClick={() => this.deidentifyUser()}>
                  DeIdentify
                </Button>
                <Button color='secondary' onClick={this.toggle}>
                  Cancel
                </Button>
              </ModalFooter>
            </Modal>
          </div>
        </div>
      </div>
    );
  }
}

export default Users;
