import {Button, Col, Row} from "reactstrap";
import {Field} from "redux-form";
import {renderActionCriteriaAndOr} from "../../Offers/OfferTitle/renderConsts";
import React, {Component} from "react";
import OfferActionForTrigger from "./OfferActionForTrigger";
import {connect} from "react-redux";

class OfferActionsForTrigger extends Component {

  render() {

    const {
      entityName,
      offerActionsForTrigger,
      actions,
      index,
      fields,
      shouldShowSuppression,
    } = this.props;

    let shouldRenderAndOrDropdown =
      shouldShowSuppression &&
      actions
        ? actions.filter(
        (offerAction) =>
          offerAction &&
          offerAction.shouldShowCriteria &&
          offerAction.shouldShowCriteria === true
      ).length > 1
        : false;

    if (fields.length === 0) fields.push({});

    return (
      <>
        {fields.map((offerActionForTriggerField, index) => (
          <OfferActionForTrigger
            offerActionForTrigger={offerActionForTriggerField}
            entityName={entityName}
            fields={fields}
            index={index}
            key={index}
            shouldShowSuppression={shouldShowSuppression}
          />
        ))}

        <Row>
          <Col lg={7}></Col>
          <Col lg={2}>
            {shouldRenderAndOrDropdown && (
              <Field
                className={'actionCriteriaAndOr'}
                name={`${offerActionsForTrigger}.actionCriteriaAndOr`}
                type='select'
                defaultValue={'AND'}
                component={renderActionCriteriaAndOr}
              />
            )}
          </Col>
          <Col lg={3}>
            <Button
              color='success'
              className='float-right'
              onClick={() => fields.push(index)}
            >
              Add Additional Action
            </Button>
          </Col>
        </Row>
      </>
    );
  };

};

function mapStateToProps(state, props) {
  const { selector } = state.formSelector;
  return {
    actions: selector(state, `${props.offerActionsForTrigger}.actions`),
  };
}

export default connect(mapStateToProps)(OfferActionsForTrigger);
;
