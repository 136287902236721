import React, { Component } from 'react';
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Label
} from 'reactstrap';

class CreateUserDropdown extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.select = this.select.bind(this);
    this.state = {
      dropdownOpen: false,
      name: this.props.name,
      value: this.props.value
    };
  }

  toggle() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    });
  }

  select(e) {
    let id = e.currentTarget.getAttribute('id');
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
      name: e.currentTarget.textContent,
      value: id
    });
    this.props.callbackFromParent(id);
  }
  render() {
    return (
      <div>
        <div className='row'>
          <div className='col-12'>
            <Label>Role:</Label>
            <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggle}>
              <DropdownToggle caret>{this.state.name}</DropdownToggle>
              <DropdownMenu>
                <DropdownItem id={1} onClick={this.select}>
                  Admin
                </DropdownItem>
                <DropdownItem id={2} onClick={this.select}>
                  Engineer
                </DropdownItem>
                <DropdownItem id={3} onClick={this.select}>
                  Analyst
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </div>
        </div>
      </div>
    );
  }
}

export default CreateUserDropdown;
