import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import api from '../../services';

import { useHistory } from 'react-router-dom';

export const useCreateLayout = () => {
  const queryClient = useQueryClient();
  const history = useHistory();

  return useMutation({
    mutationFn: (data) => api.post(`layouts`, data),
    onSuccess: (response) => {
      queryClient.invalidateQueries({ queryKey: ['layouts'] });
      history.push(`/content/layouts/${response.data.id}`);
    },
  });
};

export const useLayout = (layoutId, selectFn = (data) => data) =>
  useQuery({
    queryKey: ['layouts', layoutId],
    queryFn: () => api.get(`layouts/${layoutId}`).then((res) => res.data),
    enabled: isNaN(layoutId) ? false : true,
    select: selectFn,
  });

export const useLayouts = (selectFn = (data) => data) =>
  useQuery({
    queryKey: ['layouts'],
    queryFn: () => api.get(`layouts`).then((res) => res.data),
    select: selectFn,
    enabled: true,
  });

export const useLayoutsTable = (selectFn = (data) => data) =>
  useQuery({
    queryKey: ['layoutsTable'],
    queryFn: () => api.get(`layouts/table`).then((res) => res.data),
    select: selectFn,
    enabled: true,
  });

export const useUpdateLayout = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data) => api.put(`layouts/${data.id}`, data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['layouts'] });
    },
  });
};

export const useGetLayoutById = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (id) => api.get(`layouts/${id}`).then((res) => res.data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['layouts'] });
    },
  });
};

export const useDeleteLayout = () => {
  const queryClient = useQueryClient();
  const history = useHistory();
  return useMutation({
    mutationFn: async (id) => api.delete(`layouts/${id}`),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['layouts'] });
      history.push(`/content/layouts`);
    },
  });
};
