import React, { useState } from 'react';
import Switch from 'react-switch';

const ActiveStatusSwitch = ({ input, isActive, isDisabled, handleToggleSwitch }) => {
  return (
    <Switch
      className={'react-switch'}
      disabled={isDisabled}
      onChange={() => handleToggleSwitch(!isActive)}
      checked={isActive}
      onColor={'#4dbd74'}
      offColor={'#f86c6b'}
      width={70}
      //  handleDiameter={28}
      checkedIcon={
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '96%',
            fontSize: 11,
          }}
        >
          Active
        </div>
      }
      uncheckedIcon={
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '96%',
            fontSize: 11,
          }}
        >
          Inactive
        </div>
      }
    />
  );
};

export default ActiveStatusSwitch;
