import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import api from '../../services';

export const useLandingPageGroups = () =>
  useQuery({
    queryKey: ['landingPageGroups'],
    queryFn: () => api.get(`landing-page-groups/all`).then((res) => res.data),
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });

export const useUpdateLandingPageGroup = () => {
  const queryClient = useQueryClient();
  return useMutation((landingPageGroup) => api.put(`landing-page-groups/${landingPageGroup.id}`, landingPageGroup), {
    onSuccess: () => {
      queryClient.invalidateQueries('landingPageGroups');
    },
  });
};
