import React, { useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import { Col, Input, Row } from 'reactstrap';
import SearchLabel from '../../components/SearchLabel/SearchLabel';
import ZuulLoader from '../ZuulLoader/ZuulLoader';

/**
 * Handles display of a table of some entity like offers, landing pages, offer actions, etc.
 *
 * @param entity an object containing data about the entity and some entity specific configuration for the table
 * @param handleSearchChange function that handles filtering of data
 * @param loading boolean if data is loading
 * @param searchTerm a string containing the search term. will be provided to handleSearchChange.
 * @param paginationConfig  configuration for bootstrap-table-next paginator
 *                          https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/pagination-props.html#paginationsizeperpage-number
 * @param remoteConfig  configuration for bootstrap-table-next remote
 *                      https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/table-props.html#remote-bool-object
 * @param onTableChange function used to communicate with the table when remote is enabled
 *                      https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/table-props.html#ontablechange-function
 * @param tableToggles a list of components to render that will effect what items show up in the table that are not the
 *                     search term
 * @returns {JSX.Element} the table
 */
const TableCard = ({
  entity,
  handleSearchChange,
  loading,
  searchTerm,
  paginationConfig,
  remoteConfig,
  onTableChange,
  tableToggles,
}) => {
  const [activeRow, setActiveRow] = useState(null);

  const handleRowEvents = {
    onMouseEnter: (e, row, rowIndex) => {
      setActiveRow(rowIndex);
    },
  };

  const handleRowStyle = (row, rowIndex) => {
    if (activeRow === rowIndex) {
      return { cursor: 'pointer' };
    }
  };

  const TableToggles = () => {
    return (
      <>
        {tableToggles &&
          tableToggles.map((toggle, idx) => {
            return <Row key={idx}>{toggle()}</Row>;
          })}
      </>
    );
  };

  return (
    <div>
      <ToolkitProvider keyField='id' data={entity.data} columns={entity.columns} search bootstrap4={true}>
        {(props) => {
          return (
            <div>
              {loading === true ? (
                <ZuulLoader text={`Loading ${entity.entityName}s...`} />
              ) : (
                <div>
                  <Row>
                    <Col lg={3}>
                      <SearchLabel />
                      <Input
                        type='text'
                        id={`${entity.entityName}Search`}
                        name={`${entity.entityName}Search`}
                        placeholder='Use spaces to separate search terms'
                        onChange={handleSearchChange}
                        value={searchTerm}
                        aria-label={`${entity.entityName}Search`}
                      />
                    </Col>
                  </Row>
                  <TableToggles />
                  <hr />
                  <Row>
                    <Col lg={12}>
                      <BootstrapTable
                        hover
                        headerClasses='header-class'
                        {...props.baseProps}
                        remote={remoteConfig}
                        pagination={paginationFactory(paginationConfig)}
                        rowStyle={handleRowStyle}
                        rowEvents={handleRowEvents}
                        onTableChange={onTableChange}
                        defaultSorted={entity.defaultSorted}
                      />
                    </Col>
                  </Row>
                </div>
              )}
            </div>
          );
        }}
      </ToolkitProvider>
    </div>
  );
};

export default TableCard;
