import _ from 'lodash/fp';
import * as offersApi from './api';

/**
 * Creates an offer and creates or updates an offerQuestion. If the question text did not change, a default is
 * returned. If there is no question text for any reason, a default is returned.
 *
 * A new question is always created so we can version questions. An offerQuestion is created if there is not one.
 * An offerQuestion is updated with the newly created question if the offer is being updated.
 */
const saveQuestionIfChanged = async ({ newQuestionText, oldQuestionText, offer }) => {
  if (!offer) throw new Error('Offer must be present to save.');
  if (!offer.content) throw new Error('Offer content must be present to save.');

  const newOfferContent = JSON.parse(offer.content);
  const isQuestionTextEq = newQuestionText === oldQuestionText;
  const hasExistingOfferQuestionId = !_.isNil(newOfferContent.offer_question_id);
  const defaultReturn = {
    offerQuestion: { id: newOfferContent.offer_question_id },
    question: { id: newOfferContent.question_id },
  };

  if (_.isNil(newQuestionText) || _.isEmpty(newQuestionText)) {
    console.log('No new question text. Skipping question save.');
    return defaultReturn;
  }

  if (isQuestionTextEq && hasExistingOfferQuestionId) {
    console.log('Question text did not change. Skipping question save.');
    return defaultReturn;
  }

  const question = await createQuestion({ questionText: newQuestionText });
  const offerQuestionToSave = { question, offer };
  const offerQuestion = hasExistingOfferQuestionId
    ? await updateOfferQuestion(newOfferContent.offer_question_id, offerQuestionToSave)
    : await createOfferQuestion(offerQuestionToSave);
  return { offerQuestion, question };
};

const createQuestion = async ({ questionText }) => {
  console.log(`Creating new question with text <${questionText}>`);
  const question = { createdAt: new Date(), question: questionText };

  try {
    const questionResp = await offersApi.createQuestion(question);
    console.log('Created question: ', questionResp);
    return questionResp;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const createOfferQuestion = async (offerQuestion) => {
  console.log(`Creating offer question: `, offerQuestion);
  try {
    const offerQuestionResp = await offersApi.createOfferQuestion(offerQuestion);
    console.log('Created offerQuestion ', offerQuestionResp);
    return offerQuestionResp;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const updateOfferQuestion = async (id, offerQuestion) => {
  console.log(`Updating offer question: `, offerQuestion);
  try {
    const offerQuestionResp = await offersApi.updateOfferQuestion({
      ...offerQuestion,
      id,
    });
    console.log('Updated offerQuestion ', offerQuestionResp);
    return offerQuestionResp;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export { saveQuestionIfChanged };
