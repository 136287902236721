import React from 'react';
import { TabPane } from 'reactstrap';
import UserSummary from './UserSummary';
import AttributeSummary from './AttributeSummary';
import PrepingSummary from './PrepingSummary';
import DailyRestrictionSummary from './DailyRestrictionSummary';

export const SummaryPane = ({ criteriaType }) => {
  return (
    <TabPane tabId='Summary'>
      <UserSummary />
      <AttributeSummary />
      <PrepingSummary />
      <DailyRestrictionSummary />
    </TabPane>
  );
};
