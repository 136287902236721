import React, { Component } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { Button, Col, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Field } from 'redux-form';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { renderField, renderComboBox } from '../renderConsts';
import { updateSelectedWallOffers } from '../../../../actions';
import { normalizeWeight } from '../../../../utils/conversionFunctions';
import './OfferWall.css';
class Slot extends Component {
  // calculatePercentages = weights => {
  //     const percentages = {};
  //     let totalWeights = 0;
  //     for (let [key, value] of Object.entries(weights)) {
  //       value = value ? value : 0;
  //       totalWeights += value;
  //       // console.log(key, value);
  //     }
  //     // console.log(totalWeights);
  //     for (let [key, value] of Object.entries(weights)) {
  //       const id = key.split('-')[1];
  //       const weightedFlex = parseFloat((value / totalWeights) * 100).toFixed(2);
  //       // console.log(weightedFlex);
  //       percentages[`percent-${id}`] = weightedFlex;
  //       // console.log(key, value);
  //     }

  //     // console.log(percentages);
  //     return percentages;
  //   };
  //   handleWeightChange = (event, id) => {
  //     const weight = parseInt(event.target.value) || 1;
  //     // if (weight < 0 || weight > 100) return false;

  //     // this.setState({ weights: { ...this.state.weights, [id]: weight } });
  //     const { selectedPosts } = this.props;

  //     const { weights } = this.state;
  //     const updatedPosts = selectedPosts.map(post => {
  //       const selectedPost = post.offerActionPost ? post.offerActionPost : post;
  //       if (selectedPost.id === id) {
  //         post.weight = weight;
  //         weights[`weight-${selectedPost.id}`] = weight;
  //       }
  //       return post;
  //     });
  //     this.setState({ weights });
  //     const percentages = this.calculatePercentages(weights);
  //     for (let key in percentages) {
  //       this.props.change(key, percentages[key]);
  //     }

  //     this.props.updateSelectedPosts(updatedPosts);
  //   };

  getItemStyle = (isDraggingOver, draggableStyle, draggableSnapshot) => {
    const { isDragging } = draggableSnapshot;
    return {
      background: (isDraggingOver || isDragging) && '#3a4149',
      boxShadow: isDraggingOver
        ? '0px 0px 0px 0.2rem rgb(58,65,73, .25)'
        : null,
      border:
        isDraggingOver && isDragging
          ? '5px solid #4dbd74'
          : (!isDraggingOver && isDragging) || isDraggingOver
          ? '5px solid #f86c6b'
          : 'none',
      borderRadius: '1em',
      // margin: '0 -1em 0 0',
      padding: '1em',
      ...draggableStyle,
    };
  };

  normalizeOffer(newValue) {
    const offerObj = _.find(
      this.props.offerWall.WALLOFFERS,
      (offer) => offer.id === newValue.id || offer.name === newValue
    );
    // Will return the existing object or undefined
    return offerObj;
  }

  getSelectedOfferId = (selectedOfferStr) => {
    console.log(selectedOfferStr);
    if (typeof selectedOfferStr === 'number') return selectedOfferStr;
    let id = null;
    let name =
      typeof selectedOfferStr === 'object' && selectedOfferStr !== null
        ? selectedOfferStr.name
        : selectedOfferStr;
    for (let i = 0; i < this.props.offerWall.WALLOFFERS.length; i++) {
      if (this.props.offerWall.WALLOFFERS[i].name === name) {
        id = this.props.offerWall.WALLOFFERS[i].id;
        break;
      }
    }
    return id;
  };

  handleSelectedChanged = (selected, newValue, previousValue) => {
    const { offerWallSlotIndex } = this.props;
    // const selectedOffer = this.normalizeOffer(newValue);

    console.log(selected);
    console.log(newValue);
    console.log(previousValue);
    // console.log(selectedOffer);
    console.log(offerWallSlotIndex);

    let selectedOffers =
      this.props.offerWall.selectedOffers[offerWallSlotIndex] || [];

    console.log(selectedOffers);
    // case where user removed the wall ad and it was an object/selected ad
    if (
      typeof newValue !== 'object' &&
      previousValue &&
      typeof previousValue === 'object'
    ) {
      const newOffers = selectedOffers.filter((offer) => {
        const id = previousValue.id
          ? previousValue.id
          : this.getSelectedOfferId(previousValue);
        return offer !== id;
      });
      console.log(newOffers);
      selectedOffers = newOffers;
    }
    // case where there was a previously selected ad and user selected a new dropdown option, we swap the previous with the new value
    else if (
      newValue &&
      typeof newValue === 'object' &&
      previousValue &&
      typeof previousValue === 'object' &&
      selectedOffers.indexOf(previousValue.id) > -1
    ) {
      console.log(newValue);
      console.log(previousValue);
      const previousIdx = selectedOffers.indexOf(previousValue.id);
      selectedOffers[previousIdx] = newValue.id;
    }
    // case where user typed and selected an offer wall ad or just selected one from the dropdown
    else if (typeof newValue === 'object') {
      const offerAdId = newValue.id
        ? newValue.id
        : this.getSelectedOfferId(newValue);
      console.log('fourth case');
      console.log(offerAdId);
      selectedOffers.push(offerAdId);
    }
    console.log(selectedOffers);
    this.props.updateSelectedWallOffers(offerWallSlotIndex, selectedOffers);
  };

  handleSlotRemoval = (offerWallSlot, offerWallSlotIndex, fields, index) => {
    console.log(this.props.slotAd);

    if (this.props.slotAd && this.props.slotAd.id) {
      const indexOfSlotAd = offerWallSlot.indexOf(this.props.slotAd.id);
      offerWallSlot.splice(indexOfSlotAd, 1);
      this.props.updateSelectedWallOffers(offerWallSlotIndex, offerWallSlot);
    }
    fields.remove(index);
  };

  // getItemStyle = (isDragging, draggableStyle) => ({
  //   // some basic styles to make the items look a bit nicer
  //   userSelect: 'none',
  //   // padding: grid * 2,
  //   // margin: `0 0 ${grid}px 0`,

  //   // change background colour if dragging
  //   background: isDragging ? 'lightgreen' : 'grey',

  //   // styles we need to apply on draggables
  //   ...draggableStyle
  // });

  getListStyle = (
    isDragging,
    draggableStyle,
    showTopBorder,
    areItemsDragging
  ) => {
    return {
      userSelect: 'none',

      background: isDragging ? '#515b65' : null,
      boxShadow: isDragging
        ? '0px 0px 0px 0.2rem rgba(77, 189, 116, 0.25)'
        : null,
      border: isDragging ? '2px solid #4dbd74' : null,
      // styles we need to apply on draggables
      ...draggableStyle,
    };
  };

  render() {
    const {
      droppableProvided,
      droppableSnapshot,
      slot,
      slotAd,
      slotType,
      fields,
      offerWallSlotIndex,
      index,
      offerWall,
    } = this.props;
    const offerWallSlot = offerWall.selectedOffers[offerWallSlotIndex];
    const filteredSlots = offerWall.WALLOFFERS.filter((wallAd) => {
      if (offerWallSlot) {
        return !offerWallSlot.includes(wallAd.id);
      }
      return true;
    });

    return (
      <Draggable key={slot} draggableId={slot} index={index}>
        {(draggableProvided, draggableSnapshot) => {
          return (
            <div
              ref={draggableProvided.innerRef}
              {...draggableProvided.draggableProps}
              {...draggableProvided.dragHandleProps}
              style={this.getItemStyle(
                droppableSnapshot.isDraggingOver,
                draggableProvided.draggableProps.style,
                draggableSnapshot
              )}
            >
              <Row className='slot-ad'>
                <Col xs={10} sm={10} lg={9}>
                  <label htmlFor={`${slot}.slot_ad`}>{slotType}</label>
                  <Field
                    onChange={this.handleSelectedChanged}
                    // name={`slot_${index + 1}_ad_id`}
                    name={`${slot}.slot_ad`}
                    component={renderComboBox}
                    data={filteredSlots}
                    valueField='id'
                    textField='name'
                  />
                </Col>
                <Col xs={1} sm={1} lg={1}>
                  {!slotAd.id ? (
                    <Button
                      color='info'
                      type='button'
                      title='Visit offer wall ad details'
                      disabled={true}
                      className='offerwall-ads-link'
                    >
                      <i className='fa fa-search' />
                    </Button>
                  ) : (
                    <Link
                      to={`/campaigns/offerwallads/${slotAd.id}`}
                      target='_blank'
                    >
                      <Button
                        color='info'
                        type='button'
                        title='Visit offer wall ad details'
                        className='offerwall-ads-link'
                      >
                        <i className='fa fa-search' />
                      </Button>
                    </Link>
                  )}
                </Col>
              </Row>
              <Row className='flex'>
                <Col xs={8} sm={8} lg={8}>
                  <Field
                    // onChange={this.handleSlotQuantityChange}
                    name={`${slot}.flex`}
                    normalize={normalizeWeight}
                    component={renderField}
                    type='number'
                    label='Flex'
                  />
                </Col>
                <Col xs={4} sm={4} lg={4}>
                  {index > 0 ? (
                    <Button
                      className='delete-slot-button'
                      onClick={() =>
                        this.handleSlotRemoval(
                          offerWallSlot,
                          offerWallSlotIndex,
                          fields,
                          index
                        )
                      }
                      color='danger'
                    >
                      <i className='fa fa-minus' />
                    </Button>
                  ) : (
                    <Button
                      className='add-slot-button'
                      disabled={fields.length === 4}
                      onClick={() => fields.push({})}
                      color='success'
                    >
                      <i className='fa fa-plus' />
                    </Button>
                  )}
                </Col>
              </Row>
              <br />
            </div>
          );
        }}
      </Draggable>
      //   </div>
      //     );
      //   }}
      //     </Droppable>
      //   </DragDropContext>
      //   </div>
    );
  }
}

function mapStateToProps(state, props) {
  const { selector } = state.offerState;
  // console.log(state.offerWall);
  return {
    action: selector(state, `${props.slot}.name`),
    offerWall: state.offerWall,
    slotAd: selector(state, `${props.slot}.slot_ad`) || {},
  };
}

export default connect(mapStateToProps, { updateSelectedWallOffers })(Slot);
