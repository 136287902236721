import React, { Component } from 'react';
import { Button, Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import './AdvPrePing.css';
import { columnFormatter } from '../../utils/tableFunctions';
import { formatDate } from '../../utils/conversionFunctions';
import api from '../../services';
import _ from 'lodash';
import AdvertiserTable from '../../components/Table/hookTable';
import ActiveStatusModal from '../Offers/OfferTitle/ActiveStatusModal';
import { Link } from 'react-router-dom';

class AdvPrePing extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.idleTimer = null;
    this.state = {
      advertiserPrePing: [],
      filteredAdvertiserPrePing: [],
      active: null,
      advertiserPrePingSelection: [],
      render: false,
      toOfferWallAdsPage: false,
      toId: null,
      page: 1,
      searchTerm: '',
      sizePerPage: 10,
      paginationSize: 10,
      totalElements: 10,
      idEditMode: false,
      offerType: 'Question',
      loading: false,
    };
    this.options = {
      onPageChange: this.onPageChange.bind(this),
      onSizePerPageList: this.sizePerPageListChange.bind(this),
      page: this.state.page,
      sizePerPage: this.state.sizePerPage,
      paginationSize: this.state.paginationSize,
      sizePerPageDropDown: this.renderSizePerPageDropDown,
      prePage: 'Prev',
      nextPage: 'Next',
      firstPage: 'First',
      lastPage: 'Last',
      paginationShowsTotal: true,
      paginationPosition: 'bottom',
      alwaysShowAllBtns: true,
      withFirstAndLast: true,
    };

    this.getData = this.getData.bind(this);
  }

  renderSizePerPageDropDown = (props) => {
    return (
      <div className='btn-group'>
        {[10, 25, 30, 50].map((n, idx) => {
          const isActive = n === props.currSizePerPage ? 'active' : '';
          return (
            <button
              key={idx}
              type='button'
              className={`btn btn-info ${isActive}`}
              onClick={() => props.changeSizePerPage(n)}
            >
              {n}
            </button>
          );
        })}
      </div>
    );
  };

  renderAdvertiserPrePings(response) {
    return response.data.content.map((prePing) => {
      return {
        id: prePing.id !== null && prePing.id !== undefined ? prePing.id : '',
        name: prePing.name !== null ? prePing.name : '',
        advertiser: prePing.advertiser !== null ? prePing.advertiser.name : '',
        lastEdited: prePing.updatedAt !== null ? formatDate(prePing.updatedAt) : formatDate('2019-01-01T12:00:00'),
        active: prePing.active,
      };
    });
  }

  getAdditionalData = () => {
    const offerPromises = [];

    for (let i = 1; i <= this.state.totalPages; i++) {
      const offerPromise = api.get(`advertiser-pre-pings?limit=${10}&offset=${i}`);
      offerPromises.push(offerPromise);
    }

    return offerPromises;
  };

  sortDates(a, b, order) {
    var dateA = new Date(a.lastEdited).getTime();
    var dateB = new Date(b.lastEdited).getTime();
    if (order === 'asc') {
      return dateA > dateB ? -1 : 1;
    }
    return dateA > dateB ? 1 : -1;
  }

  getData(page = this.state.page, limit = this.state.sizePerPage > 0 ? this.state.sizePerPage : 10) {
    let offset = page - 1;
    api
      .get(`advertiser-pre-pings?limit=${limit}&offset=${offset}`)
      .then((response) => {
        const existingAdvertiserPrePing = this.renderAdvertiserPrePings(response);
        const sortedAdvertiserPrePing = existingAdvertiserPrePing.sort((a, b) => this.sortDates(a, b, 'asc'));
        const totalPages = response.data.totalPages;
        const total = response.data.totalElements;
        const newState = Object.assign({}, this.state, {
          advertiserPrePing: sortedAdvertiserPrePing,
          totalElements: total,
          totalPages,
          loading: true,
        });

        this.setState(newState, () => {
          Promise.all(this.getAdditionalData()).then((responses) => {
            const parsedAdvertiserPrePings = _.flatten(
              responses.map((response) => {
                return this.renderAdvertiserPrePings(response);
              })
            );

            this.setState((prevState) => {
              let totalSortedAdvertiserPrePings = [
                ...prevState.advertiserPrePing,
                ...parsedAdvertiserPrePings,
              ].sort((a, b) => this.sortDates(a, b, 'asc'));
              return {
                advertiserPrePing: totalSortedAdvertiserPrePings,
                loading: false,
                render: true,
              };
            });
          });
        });
      })
      .catch((error) => console.log(error));
  }

  updateData(page = this.state.page, limit = this.state.sizePerPage) {
    let offset = page - 1;
    let existingAdvertiserPrePings = this.state.advertiserPrePing;
    api
      .get(`advertiser-pre-pings?limit=${limit}&offset=${offset}`)
      .then((response) => {
        const newPrePings = this.renderAdvertiserPrePings(response);
        const first = response.data.pageable.offset;
        const num = response.data.numberOfElements;
        for (let i = first, j = 0; j < num; i++, j++) {
          existingAdvertiserPrePings[i] = newPrePings[j];
        }

        const newState = Object.assign({}, this.state, {
          advertiserPrePing: existingAdvertiserPrePings,
        });

        this.setState(newState);
      })
      .catch((error) => console.log(error));
  }

  componentDidMount() {
    this.getData();
  }

  sizePerPageListChange(sizePerPage) {
    this.updateData(1, sizePerPage);
  }

  onPageChange(page, sizePerPage) {
    this.updateData(page, sizePerPage);
  }

  filterAdvertiserPrePing = (searchTerm, unfilteredAdvertiserPrePing) => {
    // Split the term by space and remove empty spaces
    let splitSearchTerm = searchTerm.split(/[ ,]+/).filter(Boolean);
    const filteredAdvertiserPrePing = unfilteredAdvertiserPrePing.filter((prePing) => {
      const parsedPrePing = {
        id: prePing.id + '' || '',
        lastEdited: prePing.updatedAt || '',
        advertiser: prePing.advertiser || '',
        name: prePing.name || '',
      };
      let doesMatchTerms = true;
      for (let i = 0; i < splitSearchTerm.length; i++) {
        if (
          !Object.keys(parsedPrePing).some((key) =>
            parsedPrePing[key].toLowerCase().includes(splitSearchTerm[i].toLowerCase())
          )
        ) {
          doesMatchTerms = false;
        }
      }
      return doesMatchTerms;
    });
    this.setState({ filteredAdvertiserPrePing });
  };

  handleInputChange = (event) => {
    const searchTerm = event.target.value;
    // This prevents the user from calling the search function too many times in less than a second, helps with performance
    const throttledSearch = _.throttle(this.filterAdvertiserPrePing, 500);
    this.setState({ searchTerm });
    return throttledSearch(searchTerm, this.state.advertiserPrePing);
  };

  updateStatus = (row, activeStatus, endpoint) => {
    if (row.id) {
      let saveData = {
        id: row.id,
        active: activeStatus,
      };
      api.put(`${endpoint}/active/${row.id}`, saveData).then((result) => {});
    }
  };

  activeStatusSwitch = (row, entity) => {
    return (
      <ActiveStatusModal
        content={row}
        isActive={row.active}
        modalType={entity.modalType}
        getErrors={() => this.state.errors || null}
        onClickSubmit={(activeStatus) => this.updateStatus(row, activeStatus, entity.endpoint)}
      />
    );
  };

  actionFormatter = (cell, row) => {
    return (
      <Row>
        <Col lg={12}>
          {this.activeStatusSwitch(row, {
            modalType: ' Advertiser Preping',
            endpoint: 'advertiser-pre-pings',
          })}
        </Col>
      </Row>
    );
  };

  render() {
    const URL = '/advertiser-pre-pings/';

    const columns = [
      {
        dataField: 'id',
        text: 'ID',
        formatter: (cell, row) => {
          return columnFormatter({
            cell,
            row,
            computedURL: URL + row.id,
          });
        },
        sort: true,
        headerStyle: {
          backgroundColor: '#2f353a',
        },
      },
      {
        dataField: 'name',
        text: 'Advertiser Pre-Ping',
        formatter: (cell, row) => {
          return columnFormatter({
            cell,
            row,
            computedURL: URL + row.id,
          });
        },
        sort: true,
        sortCaret: (order) => {
          if (!order)
            return (
              <span>
                &nbsp;&nbsp;&nbsp;
                <i className='fa fa-sort' />
              </span>
            );
          else if (order === 'asc')
            return (
              <span>
                &nbsp;&nbsp;
                <i className='fa fa-sort' />
              </span>
            );
          else if (order === 'desc')
            return (
              <span>
                &nbsp;&nbsp;
                <i className='fa fa-sort' />
              </span>
            );
          return null;
        },
        headerStyle: {
          backgroundColor: '#2f353a',
        },
      },
      {
        dataField: 'advertiser',
        text: 'Advertiser',
        formatter: (cell, row) => {
          return columnFormatter({
            cell,
            row,
            computedURL: URL + row.id,
          });
        },
        sort: true,
        sortCaret: (order) => {
          if (!order)
            return (
              <span>
                &nbsp;&nbsp;&nbsp;
                <i className='fa fa-sort' />
              </span>
            );
          else if (order === 'asc')
            return (
              <span>
                &nbsp;&nbsp;
                <i className='fa fa-sort' />
              </span>
            );
          else if (order === 'desc')
            return (
              <span>
                &nbsp;&nbsp;
                <i className='fa fa-sort' />
              </span>
            );
          return null;
        },
        headerStyle: {
          backgroundColor: '#2f353a',
        },
      },
      {
        dataField: 'lastEdited',
        text: 'Last Edited',
        formatter: (cell, row) => {
          return columnFormatter({
            cell,
            row,
            computedURL: URL + row.id,
          });
        },
        sort: true,
        sortCaret: (order) => {
          if (!order)
            return (
              <span>
                &nbsp;&nbsp;&nbsp;
                <i className='fa fa-sort' />
              </span>
            );
          else if (order === 'asc')
            return (
              <span>
                &nbsp;&nbsp;
                <i className='fa fa-sort' />
              </span>
            );
          else if (order === 'desc')
            return (
              <span>
                &nbsp;&nbsp;
                <i className='fa fa-sort' />
              </span>
            );
          return null;
        },
        headerStyle: {
          backgroundColor: '#2f353a',
        },
      },
      {
        dataField: 'active',
        text: 'Active',
        // This formatter is different from the other column formatter implementations because the endpoint to update the status doesn't follow the same pattern as well as the active vs. isActive boolean field
        formatter: this.actionFormatter,
        sort: true,
        sortCaret: (order) => {
          if (!order)
            return (
              <span>
                &nbsp;&nbsp;&nbsp;
                <i className='fa fa-sort' />
              </span>
            );
          else if (order === 'asc')
            return (
              <span>
                &nbsp;&nbsp;
                <i className='fa fa-sort' />
              </span>
            );
          else if (order === 'desc')
            return (
              <span>
                &nbsp;&nbsp;
                <i className='fa fa-sort' />
              </span>
            );
          return null;
        },
        headerStyle: {
          backgroundColor: '#2f353a',
          width: '5%',
        },
      },
    ];

    return (
      <div>
        <div className='animated fadeIn'>
          <Card>
            <CardHeader>
              <i className='icon-menu' />
              Advertiser Pre-Ping
            </CardHeader>
            <CardBody>
              <Row>
                <Col lg={6}>
                  <span className='display-4'>Advertiser Pre-Ping</span>
                </Col>
                <Col lg={6}>
                  <Link to={'/advertiser-pre-pings/create'}>
                    <Button color='primary' className='float-right'>
                      <i className='fa fa=plus' />
                      &nbsp; Create New Advertiser Pre-Ping
                    </Button>
                  </Link>
                </Col>
              </Row>
              <AdvertiserTable
                entity={{
                  entityName: 'Advertiser Pre-Ping',
                  columns,
                  data:
                    this.state.searchTerm.length > 0
                      ? this.state.filteredAdvertiserPrePing
                      : this.state.advertiserPrePing,
                  defaultSorted: [
                    {
                      dataField: 'createdAt',
                      order: 'desc',
                    },
                  ],
                }}
                handleSearchChange={this.handleInputChange}
                loading={this.state.loading}
                searchTerm={this.state.searchTerm}
              />
            </CardBody>
          </Card>
        </div>
      </div>
    );
  }
}

export default AdvPrePing;
