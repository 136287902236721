import React, { Component } from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col
} from 'reactstrap';
import './SaveOfferActionModal.css';
class SaveOfferActionModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      success: false
    };

    this.toggleSuccess = this.toggleSuccess.bind(this);
    this.saveSuccess = this.saveSuccess.bind(this);
  }

  saveSuccess() {
    this.setState({
      success: !this.state.success
    });
    this.props.onClickSubmit();
  }

  toggleSuccess() {
    this.setState({
      success: !this.state.success
    });
  }

  render() {
    // const errors = this.props.getErrors();
    // errors._error = errors.invalid
    //   ? 'One or more of the fields you entered are invalid.'
    //   : null;
    // const isDisabled = Object.keys(errors).some(x => errors[x]);
    const { isEditMode } = this.props;
    return !this.props.offerActionName ? (
      <></>
    ) : (
      <div className='animated'>
        <Button
          color='success'
          onClick={this.toggleSuccess}
          // disabled={isDisabled}
          className='saveOfferBtn'
        >
          {isEditMode ? 'Save' : 'Create New'} Offer Action
        </Button>
        <Modal
          isOpen={this.state.success}
          toggle={this.toggleSuccess}
          className={'modal-success ' + this.props.className}
        >
          <ModalHeader toggle={this.toggleSuccess}>
            {isEditMode ? 'Save ' : 'Create New'} Offer Action
          </ModalHeader>
          <ModalBody>
            <Row>
              <Col
                lg={2}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  color: '#4dbd74'
                }}
              >
                <i className='fa fa-check-circle-o fa-3x'></i>
              </Col>
              <Col lg={10}>
                You are about to {isEditMode ? 'edit this' : 'create a new'}{' '}
                offer action:
                <br />
                <br />
                <h3>{this.props.offerActionName}</h3>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            {/* <Link to='/campaigns/offeractions'> */}
            <Button color='success' onClick={this.saveSuccess}>
              {isEditMode ? 'Save' : 'Create'}
            </Button>{' '}
            {/* </Link> */}
            <Button color='secondary' onClick={this.toggleSuccess}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default SaveOfferActionModal;
