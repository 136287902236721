import { find, flatten, uniq } from 'lodash';
import { renderOffersSequenceNode } from '../../Offers/OfferTitle/renderConsts';

export const getSequenceFlowOffersAndEdges = (elements) => {
  const edges = [];
  const offers = [];
  if (!elements) {
    return [edges, offers];
  }
  elements.forEach((offersAndEdges) =>
    offersAndEdges.hasOwnProperty('target') ? edges.push(offersAndEdges) : offers.push(offersAndEdges)
  );
  return [edges, offers];
};

export const getOffersSequencesSourcesAndTargets = (edges) => {
  //  Grabs the combination of sources and targets, and flattens the result so we have a list of all the sequenceFlow obj IDs (2417-1, 3093-2, 3123-3) This combination makes up the order in which the offersSequences should be laid out when submitting this sequence
  return uniq(flatten(edges.map((edge) => [edge.source, edge.target])));
};

export const getSequenceFlowTargets = (edges) => {
  return edges.map((edge) => edge.target);
};

export const handleOrphanedOffers = (elements) => {
  let [edges, offers] = getSequenceFlowOffersAndEdges(elements);
  let totalListOfTargets = getSequenceFlowTargets(edges);
  const edgesThatBranchFromHeadNode = findEdgesThatBranchFromHeadNode(edges);

  return offers.filter((offer) => {
    const isOSHeadNode = find(edgesThatBranchFromHeadNode, (edge) => edge.source === offer.id);
    // TODO Both the first and second offer can be invalid with this logic, the first is invalid because it points to no next node, and the second is invalid because it's a node not connected to another node and thus does not exist in the targets array. Check with OPS on the desired outcome of this situation
    if (offer.isHidden) {
      return false;
    } else if (isOSHeadNode && edgesThatBranchFromHeadNode.length > 1) {
      return true;
    } else if (!isOSHeadNode && !totalListOfTargets.includes(offer.id)) {
      return true;
    }
    return false;
  });
};

export const findEdgesThatBranchFromHeadNode = (sequenceFlowEdges) => {
  const OSTargets = sequenceFlowEdges.map((edge) => edge.target);
  return sequenceFlowEdges.filter((edge) => {
    // Invalid offersSequences (multiple orphaned offers) will have multiple "firstOffersSequencesEdges". So this function will return an array of those edges that come from the head node of a list. If OSTargets doesn't have this edge's source as a target, that means this edge's source is the head of this flow and we should sort the offers accordingly
    return !OSTargets.includes(edge.source);
  });
};

export const hasMultipleStartNodes = (elements) => {
  const [edges] = getSequenceFlowOffersAndEdges(elements);
  const edgesThatBranchFromHeadNode = findEdgesThatBranchFromHeadNode(edges);
  return edgesThatBranchFromHeadNode.length > 1;
};

export const sortOfferSequences = (sequenceFlowOffers) => {
  return sequenceFlowOffers.sort((a, b) => {
    return a.orderBy > b.orderBy ? 1 : -1;
  });
};

export const findTopOfferElement = (elements, selectedElement, draggedNodeHeight, draggedNodeWidth) => {
  return find(elements, (element) => {
    if (element.id === selectedElement.id || element.isHidden) return false;
    if (element.position) {
      return isWithinRangeOfTopOfferElement(element, selectedElement, draggedNodeHeight, draggedNodeWidth);
    }
  });
};

export const findBottomOfferElement = (elements, selectedElement, draggedNodeHeight, draggedNodeWidth) => {
  return find(elements, (element) => {
    if (element.id === selectedElement.id || element.isHidden) return false;
    if (element.position) {
      return isWithinRangeOfBottomOfferElement(element, selectedElement, draggedNodeHeight, draggedNodeWidth);
    }
  });
};

const elementIsWithinRangeOfXValue = (element, draggedNode, draggedNodeWidth) => {
  return (
    element.position.x + draggedNodeWidth + 25 > draggedNode.position.x &&
    element.position.x - draggedNodeWidth - 25 < draggedNode.position.x
  );
};

export const isWithinRangeOfTopOfferElement = (element, draggedNode, draggedNodeHeight, draggedNodeWidth) => {
  return (
    elementIsWithinRangeOfXValue(element, draggedNode, draggedNodeWidth) &&
    element.position.y + draggedNodeHeight + 25 > draggedNode.position.y &&
    element.position.y + draggedNodeHeight / 2 < draggedNode.position.y
  );
};

export const isWithinRangeOfBottomOfferElement = (element, draggedNode, draggedNodeHeight, draggedNodeWidth) => {
  return (
    elementIsWithinRangeOfXValue(element, draggedNode, draggedNodeWidth) &&
    element.position.y - draggedNodeHeight - 25 < draggedNode.position.y &&
    element.position.y - draggedNodeHeight / 2 > draggedNode.position.y
  );
};

export const getNodeAndPosition = (draggedNode, elements) => {
  let existingNodePosition = null;
  const existingNode = find(elements, (element, i) => {
    if (element.id === draggedNode.id) {
      existingNodePosition = element.orderBy ? element.orderBy : i;
      return true;
    }
  });
  return [existingNode, existingNodePosition];
};

export const handleOfferReordering = (sequenceFlowOffers, existingNode, existingNodePosition, positionToInsertNode) => {
  sequenceFlowOffers.splice(existingNodePosition, 1);
  sequenceFlowOffers.splice(positionToInsertNode, 0, existingNode);
  return sequenceFlowOffers;
};

// Will either pass in offerBlock offers or offersSequences
export const handleFlowOffersInit = (offersSequencesOrBlockOffers) => {
  const reactFlowOffersAndEdges = [];

  const sortedOfferParentEntities = offersSequencesOrBlockOffers
    .slice()
    .sort((a, b) => a.orderBy - b.orderBy)
    .filter((os) => os.offer != null);

  if (sortedOfferParentEntities.length === 0) {
    return [reactFlowOffersAndEdges];
  }
  const offers = sortedOfferParentEntities.map(({ offer }) => offer);
  const duplicateOffers = getDuplicateOffersMap(offers);

  // Helper function to create className string
  const createClassName = (parts) => parts.filter(Boolean).join(' ');

  sortedOfferParentEntities.forEach((entity, i) => {
    const {
      offer,
      layout,
      container,
      className,
      isHidden,
      isHiddenPlaceholder,
      offersSequenceParent,
      offersSequence,
    } = entity;

    const id = `${offer.id}-${i}`;

    const label = renderOffersSequenceNode(offer, i);
    const classNameParts = [
      'offerNode',
      !offer.isActive && 'inactiveOffer',
      (layout || container) && 'overwrittenOffer',
      duplicateOffers[offer.id] > 1 && 'duplicateOffer',
      className?.includes('selectedElement') && 'selectedElement',
    ];
    const osReactFlowBlock = {
      id,
      offerType: offer.offerType,
      name: offer.name,
      data: {
        label,
      },
      offer,
      position: {
        x: 250,
        y: (i + 1) * 150,
      },
      className: createClassName(classNameParts),
      orderBy: i,
    };

    if (isHidden != null) {
      osReactFlowBlock.isHidden = isHidden;
    }

    if (isHiddenPlaceholder != null) {
      osReactFlowBlock.isHiddenPlaceholder = isHiddenPlaceholder;
    }

    if (offersSequenceParent != null) {
      osReactFlowBlock.offersSequence = offersSequenceParent;
    } else if (offersSequence != null) {
      osReactFlowBlock.offersSequence = offersSequence;
    }

    reactFlowOffersAndEdges.push(osReactFlowBlock);

    const targetId = `${offer.id}-${i}`;
    if (i > 0) {
      const sourceId = `${sortedOfferParentEntities[i - 1].offer.id}-${i - 1}`;
      const osReactFlowConnection = {
        id: `${sourceId}-${targetId}`,
        source: sourceId,
        target: targetId,
        type: 'edgeWithButton',
        style: { strokeWidth: 3 },
        className: 'osEdge',
      };
      reactFlowOffersAndEdges.push(osReactFlowConnection);
    }
  });
  return reactFlowOffersAndEdges;
};

export const updateOrphanedOffersState = (orphanedOffers, currentElements) => {
  const orphanedOfferIds = orphanedOffers.map((offer) => offer.id);
  return currentElements.map((element) => {
    if (orphanedOfferIds.includes(element.id) && !element.className.includes('invalidOffer')) {
      element.className += ' invalidOffer';
    } else if (!orphanedOfferIds.includes(element.id)) {
      element.className = element.className.replace('invalidOffer', '').trim();
    }
    return element;
  });
};

export const handleOffersSequenceReordering = ({
  elements,
  draggedNode,
  updateOrphanedOffersProps,
  shouldHideInactiveOffers,
  orphanedOffers,
}) => {
  const draggedNodeHeight = 75;
  const draggedNodeWidth = 120;

  const topOfferElement = findTopOfferElement(elements, draggedNode, draggedNodeHeight, draggedNodeWidth);
  const bottomOfferElement = findBottomOfferElement(elements, draggedNode, draggedNodeHeight, draggedNodeWidth);
  let [sequenceFlowEdges, sequenceFlowOffers] = getSequenceFlowOffersAndEdges(elements);
  let [existingNode, existingNodePosition] = getNodeAndPosition(draggedNode, sequenceFlowOffers);
  let newExistingNodePosition = existingNodePosition;
  if (existingNode && existingNode.isHiddenPlaceholder) {
    return elements;
  }

  elements = elements.map((element) => {
    if (element.className?.includes('selectedElement')) {
      element.className = element.className.replace('selectedElement', '').trim();
    }
    return element;
  });
  existingNode.className += ' selectedElement';

  if (topOfferElement || bottomOfferElement) {
    // While dragging an offer, two offers were highlighted/eligible to have this dragged offer inserted
    let edgeFromTopOfferElToBottomOfferEl = null;
    let edgeFromTopOfferEl = null;
    let edgeToBottomOfferEl = null;
    let edgeToDraggedNode = null;
    let edgeFromDraggedNode = null;

    elements.forEach((element) => {
      if (
        topOfferElement &&
        element.source === topOfferElement.id &&
        bottomOfferElement &&
        element.target === bottomOfferElement.id
      ) {
        edgeFromTopOfferElToBottomOfferEl = element;
      } else if (topOfferElement && element.source === topOfferElement.id) {
        edgeFromTopOfferEl = element;
      } else if (bottomOfferElement && element.target === bottomOfferElement.id) {
        edgeToBottomOfferEl = element;
      }
      if (element.target === draggedNode.id) {
        edgeToDraggedNode = element;
      }
      if (element.source === draggedNode.id) {
        edgeFromDraggedNode = element;
      }

      element.className = element.className?.replace('topOfferElement', '');
      element.className = element.className?.replace('bottomOfferElement', '');
    });

    if (edgeFromTopOfferEl && edgeToDraggedNode) {
      if (edgeFromTopOfferEl.id === edgeToDraggedNode.id) return elements;
    }
    if (edgeToBottomOfferEl && edgeFromDraggedNode) {
      if (edgeToBottomOfferEl.id === edgeFromDraggedNode.id) return elements;
    }
    if (edgeFromTopOfferElToBottomOfferEl && edgeFromTopOfferElToBottomOfferEl.target === draggedNode.id)
      return elements;
    if (edgeToBottomOfferEl && edgeToBottomOfferEl.source === draggedNode.id) return elements;
    if (edgeFromTopOfferEl && edgeFromTopOfferEl.target === draggedNode.id) return elements;

    if ((topOfferElement && bottomOfferElement) || topOfferElement) {
      // dropping a node between two nodes that are currently disconnected
      console.log('1-------------');
      const numOfHiddenOffers = topOfferElement.numOfHiddenOffers || 0;
      if (existingNodePosition < topOfferElement.orderBy) {
        // take it from above/before the top element and splice it ahead of the top element
        sequenceFlowOffers = handleOfferReordering(
          sequenceFlowOffers,
          existingNode,
          existingNodePosition,
          topOfferElement.orderBy + numOfHiddenOffers
        );
        newExistingNodePosition = topOfferElement.orderBy + numOfHiddenOffers;
      } else if (existingNodePosition > topOfferElement.orderBy) {
        //  take it from below/after the top element and splice it after the top element
        sequenceFlowOffers = handleOfferReordering(
          sequenceFlowOffers,
          existingNode,
          existingNodePosition,
          topOfferElement.orderBy + 1 + numOfHiddenOffers
        );
        newExistingNodePosition = topOfferElement.orderBy + 1 + numOfHiddenOffers;
      }
    } else if (bottomOfferElement) {
      // adding to the top of the flow or to the top of an orphaned node
      console.log('2-------------');
      const numOfHiddenOffers = bottomOfferElement.numOfHiddenOffers || 0;
      if (existingNodePosition < bottomOfferElement.orderBy) {
        // take it from above/before the top element and splice it ahead of the top element
        sequenceFlowOffers = handleOfferReordering(
          sequenceFlowOffers,
          existingNode,
          existingNodePosition,
          bottomOfferElement.orderBy + numOfHiddenOffers - 1
        );
        newExistingNodePosition = bottomOfferElement.orderBy + numOfHiddenOffers - 1;
      } else if (existingNodePosition > bottomOfferElement.orderBy) {
        //  take it from below/after the top element and splice it after the top element
        sequenceFlowOffers = handleOfferReordering(
          sequenceFlowOffers,
          existingNode,
          existingNodePosition,
          bottomOfferElement.orderBy + numOfHiddenOffers
        );
        newExistingNodePosition = bottomOfferElement.orderBy + numOfHiddenOffers;
      }
    }

    // offers are correctly sorted by this point because of handleOfferReordering(), now we just need to adjust the orderBy
    sequenceFlowOffers = updateSequenceFlowOfferWithOSDetails(sequenceFlowOffers);

    // calling the handleFlowOffersInit renders the OS as if it was just pulled from the backend, we depend on the above sorting to render them in the right order. The Edges will get created automatically from that function
    let sequenceFlowOffersAndEdges = handleFlowOffersInit(sequenceFlowOffers);
    [sequenceFlowEdges, sequenceFlowOffers] = getSequenceFlowOffersAndEdges(sequenceFlowOffersAndEdges);

    if (shouldHideInactiveOffers) {
      [sequenceFlowEdges, sequenceFlowOffers] = updateOffersWithHiddenAttr(
        sequenceFlowOffers,
        shouldHideInactiveOffers
      );
    }

    elements = sequenceFlowOffers.concat(sequenceFlowEdges);

    if (orphanedOffers.length) {
      const updatedOrphanedOffers = handleOrphanedOffers(elements);
      const hasMoreThanOneOSHead = hasMultipleStartNodes(elements);
      elements = updateOrphanedOffersState(updatedOrphanedOffers, elements);
      updateOrphanedOffersProps(updatedOrphanedOffers, hasMoreThanOneOSHead);
    }
  }
  return elements;
};

export const updateSequenceFlowOfferWithOSDetails = (sequenceFlowOffers) => {
  sequenceFlowOffers = sequenceFlowOffers.map((sequenceFlowOffer, i) => {
    sequenceFlowOffer.isContainerOverwritten = sequenceFlowOffer.offersSequence?.isContainerOverwritten || false;
    sequenceFlowOffer.isLayoutOverwritten = sequenceFlowOffer.offersSequence?.isLayoutOverwritten || false;
    sequenceFlowOffer.container = sequenceFlowOffer.offersSequence?.container;
    sequenceFlowOffer.layout = sequenceFlowOffer.offersSequence?.layout;
    sequenceFlowOffer.orderBy = i;
    return sequenceFlowOffer;
  });
  return sequenceFlowOffers;
};

export const updateOffersWithHiddenAttr = (sequenceFlowOffers, shouldHideInactiveOffers) => {
  const finalOSOrder = [];
  const indicesOfAllHiddenOffers = [];
  const reactFlowOfferNodes = [];
  const sequenceFlowEdges = [];
  let actualPosition = -1;
  sequenceFlowOffers.forEach((element, i) => {
    const offer = element.offer ? element.offer : element.offersSequence.offer;
    const isHiddenOfferBlock = shouldHideInactiveOffers && !offer.isActive;
    element.orderBy = i;
    // check if offer already exists in indicesOfNextHiddenOffers which means it's in a hiddenOfferBlock and has already been accounted and should be skipped
    if (indicesOfAllHiddenOffers.indexOf(i) > -1) {
      element.isHidden = true;
      offer.isHiddenPlaceHolder = false;
      element.isHiddenPlaceholder = false;
      element.data = {
        label: renderOffersSequenceNode(offer, actualPosition),
      };
      element.className = element.className.replace('hiddenPlaceholder', '').trim();
      element.numOfHiddenOffers = 0;
      reactFlowOfferNodes.push(element);
    } else if (isHiddenOfferBlock) {
      finalOSOrder.push(i);
      actualPosition += 1;
      let lastInactiveOfferIndex;
      let indicesOfCurrentHiddenOffers = [i];
      for (let j = i + 1; j < sequenceFlowOffers.length; j++) {
        const nextOffer = sequenceFlowOffers[j].offer
          ? sequenceFlowOffers[j].offer
          : sequenceFlowOffers[j].offersSequence.offer;
        if (!nextOffer.isActive) {
          indicesOfAllHiddenOffers.push(j);
          indicesOfCurrentHiddenOffers.push(j);
        } else {
          lastInactiveOfferIndex = j;
          break;
        }
      }
      offer.hiddenOfferName =
        `(${indicesOfCurrentHiddenOffers.length} hidden inactive offer` +
        `${indicesOfCurrentHiddenOffers.length > 1 ? 's' : ''})`;
      offer.isHiddenPlaceholder = true;
      element.isHiddenPlaceholder = true;
      element.isHidden = false;
      element.position = {
        x: 250,
        y: (actualPosition + 1) * 150,
      };
      element.data = {
        label: renderOffersSequenceNode(offer, actualPosition),
      };
      element.className = element.className + ' hiddenPlaceholder';
      element.numOfHiddenOffers = lastInactiveOfferIndex - i - 1;
      reactFlowOfferNodes.push(element);
    } else {
      finalOSOrder.push(i);
      actualPosition += 1;
      element.isHidden = false;
      element.position = {
        x: 250,
        y: (actualPosition + 1) * 150,
      };
      offer.isHiddenPlaceholder = false;
      element.isHiddenPlaceholder = false;
      element.data = {
        label: renderOffersSequenceNode(offer, actualPosition),
      };
      element.className = element.className.replace('hiddenPlaceholder', '').trim();
      element.numOfHiddenOffers = 0;
      reactFlowOfferNodes.push(element);
    }

    if (i > 0 && !element.isHidden) {
      const hiddenOrVisibleSource = element.isHidden
        ? sequenceFlowOffers[i - 1].id
        : `${sequenceFlowOffers[finalOSOrder[actualPosition - 1]].id}`;
      const osReactFlowConnection = {
        id: `${hiddenOrVisibleSource}-${element.id}`,
        source: hiddenOrVisibleSource,
        target: `${element.id}`,
        type: 'edgeWithButton',
        style: { strokeWidth: 3 },
        className: 'osEdge',
        isHidden: element.isHidden,
      };
      sequenceFlowEdges.push(osReactFlowConnection);
    }
  });
  return [sequenceFlowEdges, reactFlowOfferNodes];
};

export const getDuplicateOffersMap = (offers) => {
  // Hashmap with offerId as the key and 1 or greater as the value/count of duplicate offers
  const duplicateOffers = {};
  offers.forEach((offer) => {
    if (duplicateOffers[offer.id]) {
      duplicateOffers[offer.id]++;
    } else {
      duplicateOffers[offer.id] = 1;
    }
  });
  return duplicateOffers;
};
