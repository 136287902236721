import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import 'react-widgets/styles.css';
import { Button, Card, CardBody, CardHeader, Col, Collapse, Row } from 'reactstrap';
import { Field, FieldArray, reduxForm } from 'redux-form';
import { getOfferTriggersByOfferId, removeOfferTriggerState, saveInitData } from '../../../../actions';
import Criteria from '../../../../components/Criteria/Criteria';
import { ImageCard } from '../../../../components/ImageCard/ImageCard';
import PreviewOfferModal from '../../../../components/PreviewOfferModal/PreviewOfferModal';
import api from '../../../../services';
import { handleCriteriaInitialize } from '../../../../utils/CriteriaHelpers';
import { filterActionTypesByLinkoutCount } from '../../../../utils/OfferActionHelper';
import { CDN_ASSETS_BASE } from '../../../../utils/constants';
import validate from '../../validate';
import CancelOfferModal from '../CancelOfferModal';
import CopyButton from '../CopyButton';
import FormEditor from '../OfferQuestion/FormEditor';
import '../OfferQuestion/OfferQuestion.css';
import OfferVariationModalButton from '../OfferVariationModalButton';
import SaveOfferModal from '../SaveOfferModal';
import { initializeOfferTriggers } from '../offerTitleHelper/offerTitleHelper';
import {
  renderActionTypeDropdown,
  renderField,
  renderOfferPostAction,
  renderSelectedOfferAction,
  renderToggleSwitch,
} from '../renderConsts';
import CustomVariablesCard from '../../../../components/CustomVariables/CustomVariablesCard';
import { processCustomVariables } from '../../../../components/CustomVariables/utils';

class OfferBanner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      files: [],
      selected: [],
      activeTab: '1',
      action: '',
      selectValue: 'Monday',
      value: [],
      isEditMode: false,
      imageFile: [],
      imageDimensions: [],
      userAttribute: 'select',
      questionAction: [],
      questionActionChildren: 'select',
      advertisers: [],
      initialState: 'select',
      offerContent: ''.replace,
      collapseBanner: true,
      collapseHeaderFooter: false,
      offerAction: '',
      prePingOption: null,
      httpIsHidden: true,
      errors: {
        disable: false,
        questionText: false,
        questionType: false,
        invalidYesNo: false,
      },
      answers: [],
      critQuestionAnswers: [],
    };
    this.toggle = this.toggle.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.toggleHeaderFooter = this.toggleHeaderFooter.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeForUserAttribute = this.handleChangeForUserAttribute.bind(this);
    this.handleChangeForActions = this.handleChangeForActions.bind(this);
    this.handleChangeForActionsChildren = this.handleChangeForActionsChildren.bind(this);
    this.handleInitialize = this.handleInitialize.bind(this);
    this.getErrors = this.getErrors.bind(this);
    this.handleHttpStatusToggle = this.handleHttpStatusToggle.bind(this);
    this.handleAnswers = this.handleAnswers.bind(this);
  }

  handleAnswers(event) {
    let ans = this.state.answers;
    if (this.state.answers.indexOf(event.target.value) <= -1) ans.push(event.target.value);
    this.setState({
      answers: ans,
    });
  }

  getErrors = () => {
    return {
      ...this.props.errors,
      ...this.state.errors,
      invalid: this.props.submitFailed ? this.props.invalid : false,
    };
  };

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  toggleBannerContent = () => {
    this.setState({ collapseBanner: !this.state.collapseBanner });
  };

  toggleHeaderFooter() {
    this.setState({ collapseHeaderFooter: !this.state.collapseHeaderFooter });
  }

  handleChange(e) {
    this.setState({
      selectValue: e.target.value,
    });
  }

  handleHttpStatusToggle = () => {
    this.setState({
      httpIsHidden: !this.state.httpIsHidden,
    });
  };

  handleChangeForUserAttribute = (e) => {
    this.setState({ userAttribute: e.target.value });
  };

  handleChangeForActions = (e) => {
    if (e.target.value === 'GET') {
      this.setState({ questionAction: 'GET' });
    }
    if (e.target.value === 'POST') {
      this.setState({
        questionAction: 'POST',
      });
    }
  };

  handleChangeForActionsChildren = (e) => {
    this.setState({ questionActionChildren: e.target.value });
  };

  onSubmit(values) {
    if (!this.props.advertiser) {
      this.setState((prevState) => {
        return {
          ...prevState,
          errors: {
            ...prevState.errors,
            isAdvertiserMissing: true,
            _error: 'This offer is missing an advertiser.',
          },
        };
      });
      setTimeout(() => {
        this.setState({
          errors: {
            ...this.state.errors,
            isAdvertiserMissing: false,
            _error: null,
          },
        });
      }, 2000);
    }

    let contentValue = null;
    const questionText = values.questionText === null ? null : values.questionText;
    let questionType = values.questionType === null ? null : values.questionType;
    const questionAnswers = values.questionAnswers == null ? null : values.questionAnswers;
    const offerPosts = values.offerBannerPosts == null ? null : values.offerBannerPosts;
    let image1 = this.state.imageFile;
    if (questionText !== null) {
      contentValue = { question_text: questionText };
    }

    if (questionAnswers != null && questionAnswers.length > 0) {
      contentValue = Object.assign({}, contentValue, {
        question_answers: questionAnswers,
      });
    }

    if (questionType !== null) {
      contentValue = Object.assign({}, contentValue, {
        question_type: questionType,
      });
    }

    let offerActionArray = [];
    if (offerPosts != null && offerPosts.length > 0) {
      contentValue = Object.assign({}, contentValue, {
        offer_posts: offerPosts,
      });
      offerPosts.forEach((action) => {
        offerActionArray.push({
          name: action.name,
          triggerType: action.post_type || 'ON_IMPRESSION',
          actionType: action.question_actions || 'POST',
          showOfferByOfferActionCriteria: action.shouldShowCriteria || false,
        });
      });
    }

    let questionData = Object.assign({}, values, {
      questionText: undefined,
      offerPosts: undefined,
      questionAnswers: undefined,
      questionType: undefined,
      offerActions: offerActionArray,
      image1,
      offerBannerPosts: undefined,
      content: JSON.stringify(contentValue) === '{}' ? '{}' : JSON.stringify(contentValue),
      customVariables:
        values.customVariables && values.customVariables.length > 0
          ? JSON.stringify(processCustomVariables(values.customVariables))
          : null,
    });

    this.props.saveOffer(questionData);
    this.setState({ isEditMode: true });
  }

  handleInitialize() {
    const offer = this.state.offerContent;
    console.log(offer);
    const { offerTriggers } = this.props;
    const offerContent = this.state.offerContent.content == null ? null : JSON.parse(this.state.offerContent.content);
    let questionAnswers = null,
      offerBannerPosts = null;
    let initData;
    if (offer !== null) {
      const imageURL =
        offer.image1 && offer.image1.id !== null
          ? CDN_ASSETS_BASE + offer.image1.id + '/original.' + offer.image1.fileExtension
          : null;
      if (imageURL !== null) {
        let sizeCoordinates = offer.image1.sizeCoordinates ? offer.image1.sizeCoordinates : '{}';
        let imageDimensions = JSON.parse(sizeCoordinates);
        console.log(imageDimensions);
        // grab only the image dimensions to use later on if the base image doesn't render
        imageDimensions = Object.keys(imageDimensions).filter((dimension) => dimension !== 'base');

        const imageDataFile = {
          name: offer.image1.fileName,
          id: offer.image1.id,
          fileName: offer.image1.fileName,
          fileExtension: offer.image1.fileExtension,
          doNotResize: offer.image1.doNotResize,
          encodedImageData: offer.image1.encodedImageData,
          sizeCoordinates: offer.image1.sizeCoordinates,
          targetHeight: offer.image1.targetHeight,
          targetWidth: offer.image1.targetWidth,
          version: offer.image1.version,
          preview: imageURL,
          size: 0,
        };
        this.setState({
          imageFile: [imageDataFile],
          imageDimensions,
        });
      }
      if (offerContent) {
        offerBannerPosts = offerContent.offer_posts === null ? null : offerContent.offer_posts;
        questionAnswers = offerContent.question_answers === null ? null : offerContent.question_answers;
        let answerArray = [];
        if (questionAnswers !== undefined && questionAnswers !== null) {
          for (let ans of questionAnswers) {
            if (answerArray.indexOf(ans) <= -1) {
              answerArray.push(ans.text);
            }
          }
        }
        this.setState({ answers: answerArray });
        offerBannerPosts = offerTriggers === null ? null : initializeOfferTriggers(offerTriggers);
      }

      let image1 = offer.image1 ? offer.image1 : null;
      image1 = Array.isArray(image1) ? image1[0] : image1;
      const doNotResize = image1 ? offer.image1.doNotResize : null;

      const offerCriteria = handleCriteriaInitialize(offer, null);

      initData = {
        offerUrl: offer.offerUrl && offer.offerUrl !== 'false' ? offer.offerUrl : '',
        offerBannerPosts: offerBannerPosts === null ? null : offerBannerPosts,
        questionAnswers: questionAnswers === null ? null : questionAnswers,
        footer: offer.footer === null ? null : offer.footer,
        customVariables: offer.customVariables ?? null,
        isActive: offer.isActive === null ? true : offer.isActive,
        // Image Upload
        image1: offer.image1 === null ? null : offer.image1,
        shouldResizeImage: image1 ? !doNotResize : null,
        ...offerCriteria,
      };
    }
    this.props.initialize(initData);
  }

  componentDidMount() {
    const { offer } = this.props;
    if (offer === null || (offer && !offer.id)) {
      this.setState(
        {
          offerContent: this.props.initData,
          isEditMode: false,
        },
        this.handleInitialize
      );
    } else if (offer.id) {
      this.props.getOfferTriggersByOfferId(offer.id).then((offerTriggers) => {
        console.log(offerTriggers);
        this.setState(
          {
            offerContent: offer,
            isEditMode: true,
          },
          this.handleInitialize
        );
      });
    }
  }

  componentWillUnmount() {
    if (!this.state.isEditMode) {
      this.props.saveInitData('offerbanner');
    }
    this.props.destroy('offerbanner');
    this.props.removeOfferTriggerState();
  }

  handleBrokenURL = () => {
    const { imageDimensions } = this.state;
    console.log(this.state.imageFile);
    if (imageDimensions.length === 0) return;
    // remove the first dimension from the array so you don't keep trying the same dimension for every failed render
    const newDimension = imageDimensions.shift();
    const newURL = `${CDN_ASSETS_BASE}${this.state.offerContent.image1.id}/${newDimension}.${this.state.offerContent.image1.fileExtension}`;
    const imageDataFile = {
      name: newDimension,
      encodedImageData: this.state.offerContent.image1.encodedImageData,
      preview: newURL,
      id: this.state.offerContent.image1.id,
      size: 0,
      sizeCoordinates: this.state.offerContent.image1.sizeCoordinates,
      fileName: this.state.offerContent.image1.fileName,
      fileExtension: this.state.offerContent.image1.fileExtension,
    };
    this.setState({
      imageFile: [imageDataFile],
      imageDimensions: imageDimensions,
    });
  };

  handleOfferActionChange = (selector) => {
    this.props.change(`${selector}.question_actions`, null);
    this.props.change(`${selector}.name`, null);
    this.props.change(`${selector}.action_advertiser`, null);
    this.props.change(`${selector}.action_id`, null);
    this.props.change(`${selector}.actionStatus`, null);
  };

  handleOfferActionNameChange = (action = {}, selector) => {
    const active = action.active ? action.active : null;
    const advertiser = action.advertiser ? action.advertiser : null;
    const action_id = action.offerActionId ? action.offerActionId : '';
    this.props.change(`${selector}.action_advertiser`, advertiser);
    this.props.change(`${selector}.action_id`, action_id);
    this.props.change(`${selector}.actionStatus`, active);
  };

  resetForm = () => {
    this.setState({ imageFile: [] });
    this.props.change('shouldResizeImage', null);
  };

  getPreview = async (formValues) => {
    const offerID = this.props.offer.id;
    const containerID = formValues.container.id;
    const layoutID = formValues.layout.id;
    const siteID = formValues.site.id;

    return new Promise((resolve) => {
      api.get(`offers/offer/preview/${offerID}/${containerID}/${layoutID}/${siteID}`).then((response) => {
        const myWindow = window.open();
        let html = response.data.replace(/{{{nr_script_header}}}/g, '').replace(/{{{nr_script_footer}}}/g, '');
        myWindow.document.write(html);
        resolve(html);
      });
    });
  };

  render() {
    const { selector } = this.props.offerState;
    const { posts, attributes, linkouts, postWaterfalls, linkoutWaterfalls, gtmEvents } = this.props;
    const disabled = linkouts.length === 0 || posts.length === 0;

    /*******************  Start of Offer Posts *************************/
    let OfferPosts = ({ action, action_id, actionStatus, offer_posts, index, fields, actionType, offerTriggers }) => {
      action_id = typeof action === 'object' && action !== null ? action.offerActionId : action_id;
      const actionData =
        actionType === 'POST'
          ? posts
          : actionType === 'LINKOUT'
          ? linkouts
          : actionType === 'USER_ATTRIBUTE'
          ? attributes
          : actionType === 'POST_WATERFALL'
          ? postWaterfalls
          : actionType === 'LINKOUT_WATERFALL'
          ? linkoutWaterfalls
          : actionType === 'GTM_EVENT'
          ? gtmEvents
          : [];
      return (
        <div key={index} className='colorTest'>
          <br />
          <Row>
            <Col lg={1}>
              <br />
              <Button
                color='danger'
                className='removeofferInputParent'
                type='button'
                title='Remove Parent Attribute'
                onClick={() => fields.remove(index)}
              >
                <i className='fa fa-close' />
              </Button>
            </Col>
            <Col lg={1}>
              <br />
              {!action_id ? (
                <Button color='info' type='button' title='Visit action details' disabled={true}>
                  <i className='fa fa-search' />
                </Button>
              ) : (
                <Link to={`/campaigns/offeractions/${action_id}`} target='_blank'>
                  <Button color='info' type='button' title='Visit action details'>
                    <i className='fa fa-search' />
                  </Button>
                </Link>
              )}
            </Col>
            <Col lg={2}>
              <Field
                name={`${offer_posts}.post_type`}
                type='select'
                component={renderOfferPostAction}
                label='Action happens on:'
              />
            </Col>
            {renderActionTypeDropdown({
              data: filterActionTypesByLinkoutCount(offerTriggers),
              handleOfferActionChange: this.handleOfferActionChange,
              fieldName: `${offer_posts}.question_actions`,
              offerAction: offer_posts,
            })}
            {renderSelectedOfferAction({
              actionStatus,
              entityName: 'Offer',
              handleOfferActionNameChange: this.handleOfferActionNameChange,
              offerAction: offer_posts,
              actionType,
              data: actionData,
              disabled,
            })}
            {actionType && (actionType === 'POST' || actionType === 'LINKOUT') && (
              <Col lg={3}>
                <Field
                  name={`${offer_posts}.action_advertiser`}
                  component={renderField}
                  type='text'
                  disabled
                  label='Advertiser Name'
                />
              </Col>
            )}
          </Row>
          <br />
        </div>
      );
    };

    OfferPosts = connect((state, props) => {
      const offerTriggers = selector(state, `offerBannerPosts`) || [];
      return {
        action: selector(state, `${props.offer_posts}.name`),
        action_id: selector(state, `${props.offer_posts}.action_id`),
        actionStatus: selector(state, `${props.offer_posts}.actionStatus`),
        has_revenue: !!selector(state, `${props.offer_posts}.has_revenue`),
        actionType: selector(state, `${props.offer_posts}.question_actions`),
        linkout_has_revenue: !!selector(state, `${props.offer_posts}.linkout_has_revenue`),
        offerTriggers,
      };
    })(OfferPosts);

    let renderOfferPosts = ({ fields, meta: { touched, error } }) => (
      <div>
        <hr />
        <Row>
          <Col lg={3}>
            <h4>Offer Actions</h4>
          </Col>
          <Col lg={9}>
            <Button color='success' className='float-right' onClick={() => fields.push({})}>
              Add Offer Action
            </Button>
            {touched && error && <span>{error}</span>}
          </Col>
        </Row>
        <br />
        {fields.map((questionAnswers, index) => (
          <OfferPosts offer_posts={questionAnswers} fields={fields} index={index} key={index} />
        ))}
      </div>
    );
    renderOfferPosts = connect((state, props) => {
      return {
        offerPosts: selector(state, 'offerBannerPosts'),
      };
    })(renderOfferPosts);

    /********************* End of Offer Posts ************************/
    const initialOfferType = {
      offerType: 'banner',
    };

    return (
      <div className='animated fadeIn'>
        <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
          <ImageCard
            handleError={this.handleBrokenURL}
            fieldName='image1'
            imageFile={this.state.imageFile}
            clearOnClick={this.resetForm}
            setImageFile={(imageFile) => this.setState({ imageFile: [imageFile] })}
          />
          <Card>
            <CardHeader>
              <Row>
                <Col lg='10' onClick={this.toggleBannerContent}>
                  <h4>
                    Banner Input &nbsp;&nbsp;
                    {this.state.collapseBanner ? (
                      <i className='fa fa-chevron-up opened' />
                    ) : (
                      <i className='fa fa-chevron-down closed' />
                    )}
                  </h4>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <Collapse isOpen={this.state.collapseBanner}>
                <Row>
                  <Col lg={12}>
                    <label htmlFor='triggersRegistration' style={{ verticalAlign: 'super' }}>
                      Count as Registration
                    </label>
                    &nbsp;&nbsp;&nbsp;
                    <Field name='triggersRegistration' id='triggersRegistration' component={renderToggleSwitch} />
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col lg={12}>
                    <FieldArray name='offerBannerPosts' component={renderOfferPosts} />
                  </Col>
                </Row>
                <Field name='offerType' value={initialOfferType} component='input' type='hidden' />
              </Collapse>
            </CardBody>
          </Card>
          <Card>
            <CardHeader onClick={this.toggleHeaderFooter} id='clickHeader'>
              {this.state.collapseHeaderFooter ? (
                <div>
                  <h4>
                    Header/Footer&nbsp;&nbsp; <i className='fa fa-chevron-up opened' />
                  </h4>
                </div>
              ) : (
                <div>
                  <h4>
                    Header/Footer&nbsp;&nbsp; <i className='fa fa-chevron-down closed' />
                  </h4>
                </div>
              )}
            </CardHeader>
            <CardBody>
              <Collapse isOpen={this.state.collapseHeaderFooter}>
                <Row>
                  <Col>
                    <Field name='footer' type='text' id='footer' component={FormEditor} />
                  </Col>
                </Row>
              </Collapse>
            </CardBody>
          </Card>
          <FieldArray name='customVariables' component={CustomVariablesCard} customVarFlag='UD_' />
          <Criteria
            limitAnswers={this.state.answers}
            capAmount={this.props.capAmount}
            // capSegmentConfiguration={this.props.capSegmentConfiguration}
          />
          <Row className='justify-content-end'>
            <Col xs='auto'>
              <PreviewOfferModal
                isLP={false}
                isEditMode={this.state.isEditMode}
                getErrors={this.getErrors}
                onClickSubmit={this.props.handleSubmit(this.getPreview)}
              />
            </Col>
            {this.state.isEditMode && (
              <Col xs='auto'>
                <OfferVariationModalButton
                  getErrors={this.getErrors}
                  isDisabled={this.getErrors().invalid}
                  onClickSubmit={this.props.handleSubmit((values) => {
                    this.props.setCopyToVariation(true);
                    this.onSubmit(values);
                  })}
                  linkouts={this.props.linkouts}
                  posts={this.props.posts}
                />
              </Col>
            )}
            {this.state.isEditMode && (
              <Col xs='auto'>
                <CopyButton
                  getErrors={this.getErrors}
                  isDisabled={this.getErrors().invalid}
                  onClickSubmit={this.props.handleSubmit(this.onSubmit)}
                  linkouts={this.props.linkouts}
                  posts={this.props.posts}
                />
              </Col>
            )}
            <SaveOfferModal
              isDisabled={this.getErrors().invalid}
              offerName={this.props.offerName}
              getErrors={this.getErrors}
              onClickSubmit={this.props.handleSubmit(this.onSubmit)}
              linkouts={this.props.linkouts}
              posts={this.props.posts}
            />
            <Col xs='auto'>
              <CancelOfferModal linkouts={this.props.linkouts} posts={this.props.posts} getErrors={this.getErrors} />
            </Col>
          </Row>
        </form>
      </div>
    );
  }
}

const form = reduxForm({
  form: 'offerbanner',
  destroyOnUnmount: false,
  touchOnChange: true,
  validate,
});

function mapStateToProps(state) {
  const { advertiser, offerTriggers, offerActionTriggers } = state.offerState;
  const { initData } = state.criteria;
  const capAmount = state.form.offerbanner?.values?.capAmount;

  return {
    advertiser,
    capAmount,
    offerTriggers,
    offerActionTriggers,
    offerState: state.offerState,
    offerContent: state.offerContent,
    initData,
  };
}

export default connect(mapStateToProps, {
  getOfferTriggersByOfferId,
  saveInitData,
  removeOfferTriggerState,
})(form(OfferBanner));
