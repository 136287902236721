import { Button, Col, Row } from 'reactstrap';

export const CapSegmentRemainder = (props) => {
  const { capAmount, capSegments, capSegmentRemainder, updateCapSegmentRemainder } = props;
  if (!capSegments || capSegments.length === 0) return null;
  const sumSegmentAmounts = capSegments.reduce((acc, cur) => {
    if (cur.segmentAmount > 0) {
      return acc + cur.segmentAmount;
    } else {
      return acc;
    }
  }, 0);

  const remainder = capAmount - sumSegmentAmounts;
  const percentageLeft = ((remainder / capAmount) * 100).toFixed(0);

  // Check if there is a difference between remainder and capSegmentRemainder
  if (remainder !== capSegmentRemainder) {
    // Set capSegmentRemainder to the new remainder
    updateCapSegmentRemainder(remainder);
  }

  return (
    <div>
      <Button
        disabled
        style={{ background: '#3a4149', color: 'white', width: '100%', border: '1px solid #23282c', opacity: '100%' }}
      >
        <Row>
          <Col className='text-left'>
            <>
              {remainder} <div style={{ display: 'inline', fontSize: '70%' }}>({percentageLeft}%)</div>
            </>
          </Col>
        </Row>
      </Button>
    </div>
  );
};

export default CapSegmentRemainder;
