import React, { Component } from 'react';
import { Button, Col, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import api from '../../../services';
import { Field, FieldArray, reduxForm } from 'redux-form';
import CustomVariablesForm from '../../../components/CustomVariables/CustomVariablesForm';
import { renderComboBox, renderCreatableSelect, renderField } from '../../Offers/OfferTitle/renderConsts';
import activeStatusSwitch from '../../../components/ActiveStatusSwitch/ActiveStatusSwitch';
import { connect } from 'react-redux';
import validate from '../validate';
import { ImportCustomVariablesButton } from '../../../components/CustomVariables/ImportCustomVariables';

class DomainDetails extends Component {
  constructor(props) {
    super(props);
    const { record } = this.props.record;

    this.state = {
      id: record?.id || null,
      name: record?.name || null,
      isActive: record?.isActive || false,
      site: record?.site != null ? record?.site : null,
      sites: this.props.record?.sites.sort((a, b) => (a.domain > b.domain ? 1 : -1)),
      gtmId: record?.gtmId,
      customVariables: record?.customVariables ? JSON.parse(record?.customVariables) : [],
      isModalOpen: true,
      domainVertical: record?.domainVertical != null ? record?.domainVertical : null,
      domainVerticals: this.props.record?.domainVerticals.sort((a, b) => (a.domain > b.domain ? 1 : -1)),
    };
  }

  fetchDomainVerticals = () => {
    api
      .get('domain-verticals')
      .then((response) => {
        this.setState({ domainVerticals: response.data });
      })
      .catch((error) => {
        console.error('Error fetching domain verticals:', error);
      });
  };

  handleCreateVertical = (newVerticalName) => {
    api
      .post('domain-verticals', { name: newVerticalName })
      .then((response) => {
        const newVertical = { id: response.data.id, name: response.data.name };
        this.setState((prevState) => ({
          domainVerticals: [...prevState.domainVerticals, newVertical],
        }));
        // Set the newly created vertical as the selected value
        this.props.change('domainVertical', { value: newVertical.id, label: newVertical.name });
        this.setState({ domainVertical: { id: newVertical.id, name: newVertical.name } });
        this.fetchDomainVerticals(); // Re-fetch domain verticals
      })
      .catch((error) => {
        console.error('Error creating new vertical:', error);
      });
  };

  saveOnEdit = (saveData) => {
    api.put(`domains/${this.state.id}`, saveData).then((result) => {
      this.props.refreshCallBack();
      this.props.action();
      console.log(JSON.stringify(result));
    });
  };

  saveOnCreate = (saveData) => {
    api.post(`domains`, saveData).then((result) => {
      this.props.refreshCallBack();
      this.props.action();
      console.log(JSON.stringify(result));
    });
  };

  setCustomVariables = (customVariables) => {
    this.setState({ customVariables: JSON.parse(customVariables) });
    this.props.change('customVariables', JSON.parse(customVariables));
  };

  onSubmit = (form) => {
    console.log(form);
    let saveData = {
      name: form.name,
      site: form.site && typeof form.site === 'object' ? form.site : null,
      isActive: this.state.isActive,
      gtmId: form.gtmId || null,
      customVariables: form.customVariables ? JSON.stringify(form.customVariables) : null,
      domainVertical: form.domainVertical ? { id: form.domainVertical.value, name: form.domainVertical.label } : null,
    };

    try {
      if (this.state.id) {
        saveData.id = this.state.id;
        this.saveOnEdit(saveData);
      } else {
        this.saveOnCreate(saveData);
      }
    } catch (error) {
      console.error(error);
    }
  };

  handleInitialize() {
    const initData = {
      ...this.state,
      domainVertical: this.state.domainVertical
        ? { value: this.state.domainVertical.id, label: this.state.domainVertical.name }
        : null,
    };
    this.props.initialize(initData);
  }

  componentDidMount() {
    this.fetchDomainVerticals();
    this.handleInitialize();
  }

  render() {
    return (
      <>
        <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
          <Modal size='lg' isOpen={this.state.isModalOpen}>
            <ModalHeader>{this.state.id ? 'Edit' : 'Create'} Domain</ModalHeader>
            <ModalBody>
              <Row>
                <Col lg={9}>
                  <Field placeholder='Name' name='name' component={renderField} label='Name: ' />
                </Col>
                <Col lg={2}>
                  <Field
                    name={'isActive'}
                    id={'isActive'}
                    component={activeStatusSwitch}
                    isActive={this.state.isActive}
                    handleToggleSwitch={() => this.setState({ isActive: !this.state.isActive })}
                  />
                </Col>
              </Row>
              <br />
              <Row>
                <Col lg={8}>
                  <Label>Site: </Label>
                  <Field
                    type='select'
                    name='site'
                    data={this.state.sites}
                    component={renderComboBox}
                    allData={this.state.sites}
                    textField='domain'
                    filter='contains'
                  />
                </Col>
                <Col lg={4}>
                  <Field placeholder='GTM ID' name='gtmId' component={renderField} label='GTM ID: ' />
                </Col>
              </Row>
              <br />
              <Row>
                <Col lg={8}>
                  <Label>Domain Vertical: </Label>
                  <Field
                    type='select'
                    name='domainVertical'
                    data={this.state.domainVerticals}
                    component={renderCreatableSelect}
                    allData={this.state.domainVerticals}
                    textField='name'
                    valueField='id'
                    filter='contains'
                    onCreate={this.handleCreateVertical} // Pass the handleCreateVertical function
                    isClearable={true} // Allow clearing the selection
                  />
                </Col>
              </Row>
              <br />
              <Row>
                <Col lg={12}>
                  <Row style={{ alignItems: 'baseline' }}>
                    <h4 style={{ padding: '0.2em 1em' }}>Custom Variables</h4>
                    <ImportCustomVariablesButton
                      customVariables={this.state.customVariables}
                      domains={this.props.domains}
                      setCustomVariables={this.setCustomVariables}
                    />
                  </Row>
                  <hr style={{ display: 'block' }} />
                </Col>
              </Row>
              <Row>
                <Col lg={12}>
                  <FieldArray name='customVariables' component={CustomVariablesForm} customVarFlag='DUD_' />
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter>
              <Button color='success' onClick={this.props.handleSubmit(this.onSubmit)}>
                Save
              </Button>
              <Button color='secondary' onClick={this.props.action}>
                Cancel
              </Button>
            </ModalFooter>
          </Modal>
        </form>
      </>
    );
  }
}

const form = reduxForm({
  form: 'domainDetails',
  validate,
});

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps)(form(DomainDetails));
