import { GET_ALL_ADVERTISERS } from './types';
import api from '../../../services';
import { normalizeAdvertisers, sortByName } from '../../../actions';

export const getAllAdvertisers = (advertisers) => {
  return {
    type: GET_ALL_ADVERTISERS,
    payload: advertisers,
  };
};

export const fetchAllAdvertisers = () => {
  return function (dispatch) {
    return getAdvertisersApi().then(
      (normalizedAdvertisers) => dispatch(getAllAdvertisers(normalizedAdvertisers)),
      (error) => console.log(error)
    );
  };
};

export const getAdvertisersApi = (dispatch) => {
  return new Promise((resolve) => {
    api
      .get('advertisers')
      .then((response) => {
        let sortedAdvertisers = sortByName(response.data);
        const normalizedAdvertisers = normalizeAdvertisers(sortedAdvertisers);

        resolve(normalizedAdvertisers);
      })
      .catch((error) => console.log(error));
  });
};
