import { addMinutes, format } from 'date-fns';
import { useEffect, useState } from 'react';

const useTimeIntervals = (intervalInMinutes = 30, numberOfIntervals = 48) => {
  const [timeIntervals, setTimeIntervals] = useState([]);

  useEffect(() => {
    const intervals = [];
    let currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);

    for (let i = 0; i < numberOfIntervals; i++) {
      intervals.push({
        value: format(currentDate, 'h:mm a'),
        option: format(currentDate, 'h:mm a'),
      });
      currentDate = addMinutes(currentDate, intervalInMinutes);
    }

    setTimeIntervals(intervals);
  }, [intervalInMinutes, numberOfIntervals]);

  return timeIntervals;
};

export default useTimeIntervals;
