export const GET_SEQUENCE = 'GET_SEQUENCE';
export const GET_SEQUENCE_VIEW = 'GET_SEQUENCE_VIEW';
export const CREATE_SEQUENCE_VIEW = 'CREATE_SEQUENCE_VIEW';
export const SET_SEQUENCE_VIEW = 'SET_SEQUENCE_VIEW,';
export const UPDATE_SEQUENCE_VIEW = 'UPDATE_SEQUENCE_VIEW';
export const GET_SDTS = 'GET_SDTS';
export const CREATE_SDTS = 'CREATE_SDTS';
export const UPDATE_SDTS = 'UPDATE_SDTS';
export const GET_SEQUENCES = 'GET_SEQUENCES';
export const GET_SEQUENCES_TABLE = 'GET_SEQUENCES_TABLE';
export const CREATE_SEQUENCE = ' CREATE_SEQUENCE_VIEW';
export const SET_SEQUENCE = 'SET_SEQUENCE_VIEW';
export const DELETE_SEQUENCE = 'DELETE_SEQUENCE';
export const UPDATE_SEQUENCE_ERROR = 'UPDATE_SEQUENCE_ERROR';
export const UPDATE_SEQUENCE_WARNING = 'UPDATE_SEQUENCE_WARNING';
export const SET_REACT_FLOW_INSTANCE = 'SET_REACT_FLOW_INSTANCE';
export const UPDATE_SELECTED_ELEMENT = 'UPDATE_SELECTED_ELEMENT';
export const UPDATE_ORPHANED_OFFERS = 'UPDATE_ORPHANED_OFFERS';
export const GET_SEQUENCE_CHECKOUT = 'GET_SEQUENCE_CHECKOUT';
export const CREATE_SEQUENCE_CHECKOUT = 'CREATE_SEQUENCE_CHECKOUT';
export const UPDATE_SEQUENCE_CHECKOUT = 'UPDATE_SEQUENCE_CHECKOUT';
export const DELETE_SEQUENCE_CHECKOUT = 'DELETE_SEQUENCE_CHECKOUT';
export const GET_SEQUENCE_PUBLISHED = 'GET_SEQUENCE_PUBLISHED';
export const PUBLISH_SEQUENCE = 'PUBLISH_SEQUENCE';
export const PUBLISH_SEQUENCE_BY_ID = 'PUBLISH_SEQUENCE_BY_ID';
