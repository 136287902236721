import React, { Component } from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Row,
  Col,
  Card,
  CardBody, Label
} from 'reactstrap';
import { connect } from 'react-redux';
import { getContainerForPreview, getLayoutForPreview, getSiteForPreview } from '../../views/LandingPages/actions';
import { resetModal } from '../../actions';
import {Field} from "redux-form";
import {
  renderComboBox
} from "../../views/Offers/OfferTitle/renderConsts";

import {fetchAllSites} from '../../views/Metadata/Sites/actions';
import {fetchAllLayouts} from '../../views/Metadata/Layouts/actions';
import {fetchAllContainers} from '../../views/Metadata/Containers/actions';

const initialData = { isOpen: false, formValidationError: false, formErrorMessage: null, saveErrorMessage: null };
const states = {
  open: 'OPEN',
  closed: 'CLOSED',
  saving: 'SAVING',
  updating: 'UPDATING',
  updatingLayout: 'UPDATING_LAYOUT',
  updatingContainer: 'UPDATING_CONTAINER',
  updatingSite: 'UPDATING_SITE',
  saveError: 'SAVE_ERROR',
  formValidationError: 'FORM_VALIDATION_ERROR',
};
const colors = {
  error: '#FF485D',
};


class PreviewOfferModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      state: states.closed,
      data: initialData,
    };
  }

  async componentDidMount() {
    this.props.fetchAllContainers();
    this.props.fetchAllLayouts();
    this.props.fetchAllSites();
  }

  componentWillReceiveProps(nextProps, nextContext) {
    // toggle success state if stuck
    const isStuck = nextProps.isLandingPageSaved && this.state.state === states.submitting;
    if (isStuck) {
      this.moveToState(states.closed);
    }

    // if form submission failed due to validation
    const errors = this.props.getErrors();
    if (errors.invalid) {
      this.moveToState(states.formValidationError);
    }

    if (nextProps.saveError && nextProps.saveError.response) {
      this.moveToState(states.saveError);
    }
  }

  moveToState = (state, payload = {}) => {
    const { open, closed, saving, updating, updatingLayout, updatingContainer, updatingSite, saveError, formValidationError } = states;

    switch (state) {
      case open:
        this.setState((prevState) => ({
          state: open,
          data: { ...prevState.data, isOpen: true, formValidationError: false },
        }));
        break;
      case closed:
        this.setState(
          (prevState) => ({
            state: closed,
            data: { ...prevState.data, ...initialData },
          }),
          () => this.props.resetModal()
        );
        break;
      case saving:
        const afterSetLocal = async () => {
          if (!this.props.isEditMode) {
            this.setState((prevState) => ({
              ...prevState,
              data: { ...prevState.data, formErrorMessage: 'Please save first.' },
            }));
            this.moveToState(open);
          } else if (this.props.isLP && !(this.props.siteIDForPreview > -1)) {
            this.setState((prevState) => ({
              ...prevState,
              data: { ...prevState.data, formErrorMessage: 'Must select a valid site.' },
            }));
            this.moveToState(open);
          } else if (!(this.props.isLP) && (!(this.props.containerIDForPreview > -1) || !(this.props.layoutIDForPreview > -1) || !(this.props.siteIDForPreview > -1))) {
            this.setState((prevState) => ({
              ...prevState,
              data: { ...prevState.data, formErrorMessage: 'Must select a valid layout, container, and site.' },
            }));
            this.moveToState(open);
          } else {
            const isSuccessful = await this.props.onClickSubmit();

            if (isSuccessful) {
              this.moveToState(closed);
            } else {
              this.moveToState(saveError, { saveError: 'Failed to save' });
            }
          }
        };

        this.setState(
          (prevState) => ({
            state: saving,
            data: { ...prevState.data, formErrorMessage: null },
          }),
          afterSetLocal
        );

        break;
      case updatingContainer:
        this.setState(
          (prevState) => ({
            state: updating,
            data: {
              ...prevState.data,
              formErrorMessage: null,
            },
          }),
          () => {
            this.props.getContainerForPreview(payload.containerID);
            this.moveToState(open);
          }
        );
        break;
      case updatingLayout:
        this.setState(
          (prevState) => ({
            state: updating,
            data: {
              ...prevState.data,
              formErrorMessage: null,
            },
          }),
          () => {
            this.props.getLayoutForPreview(payload.layoutID);
            this.moveToState(open);
          }
        );
        break;
      case updatingSite:
        this.setState(
          (prevState) => ({
            state: updating,
            data: {
              ...prevState.data,
              formErrorMessage: null,
            },
          }),
          () => {
            this.props.getSiteForPreview(payload.siteID);
            this.moveToState(open);
          }
        );
        break;
      case saveError:
        this.setState((prevState) => ({
          state: saveError,
          data: { ...prevState.data, ...payload },
        }));
        break;
      case formValidationError:
        this.setState((prevState) => ({
          state: formValidationError,
          data: { ...prevState.data },
        }));
        break;
      default:
        console.log(`<${state}> is not a valid state`);
    }
  };

  save = () => {
    this.moveToState(states.saving);
  };

  closeModal = () => {
    this.moveToState(states.closed);
  };

  openModal = () => {
    this.moveToState(states.open);
  };

  setContainer = (event) => {
    this.moveToState(states.updatingContainer, { containerID: event.id });
  };

  setLayout = (event) => {
    this.moveToState(states.updatingLayout, { layoutID: event.id });
  };


  setSite = (event) => {
    this.moveToState(states.updatingSite, { siteID: event.id });
  };

  toggleFunc = () => {
    const { state } = this.state;

    if (state !== states.saving) {
      return this.moveToState(states.closed);
    }

    return () => {};
  };

  /**
   * Need this to share what an error means to rendering the body and footer in the modal.
   * This differs from this.props.getErrors in that it is used to tell if the button should be shown or not.
   */
  getError = () => this.props.saveError || this.state.data.saveError || null;

  renderBody = () => {
    if (this.props.isLP) {
      return this.renderLPBody();
    } else {
      return this.renderOfferBody();
    }
  }


  renderLPBody = () => {
    const inputStyle = this.state.data.formErrorMessage ? { borderColor: colors.error } : {};
    const { state } = this.state;
    const isSaving = state === states.saving;

    return (
      <ModalBody>
        <Row>
          <Col lg={10}>
            <h3>{this.props.content && this.props.content.name}</h3>
            Choose a site for your Landing Page.
          </Col>
        </Row>
        <br />
        <Row>
          <Col lg={12}>
            <span style={{ color: colors.error }}>
              {this.state.data.formErrorMessage ? this.state.data.formErrorMessage : ''}
            </span>

            <form>
              <Card>
                <CardBody>
                  <Row>
                    <Col>
                      <Label>Site</Label>
                      <Field
                        required
                        type='select'
                        component={renderComboBox}
                        id='site'
                        name='site'
                        placeholder='Site'
                        label='Landing Page Site'
                        textField='domain'
                        valueField='id'
                        data={this.props.sites}
                        style={inputStyle}
                        onChange={this.setSite}
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Card>

              <Row>
                <Col >
                  <Button
                    onClick={this.save}
                    color='info'
                    disabled={isSaving}
                    style={{
                      color: 'white',
                      marginBottom: '2%',
                      marginLeft: '2%',
                      float: 'right'
                    }}
                  >
                    {isSaving ? 'Processing' : 'Preview'}
                  </Button>
                  <Button
                    color='secondary'
                    disabled={isSaving}
                    onClick={this.closeModal}
                    style={{
                      color: 'white',
                      marginBottom: '2%',
                      marginLeft: '2%',
                      float: 'right'
                    }}
                  >
                    {isSaving ? 'Processing' : 'Cancel'}
                  </Button>
                </Col>
              </Row>
            </form>
          </Col>
        </Row>
      </ModalBody>
    );
  };



  renderOfferBody = () => {
    const inputStyle = this.state.data.formErrorMessage ? { borderColor: colors.error } : {};
    const { state } = this.state;
    const isSaving = state === states.saving;

    return (
      <ModalBody>
        <Row>
          <Col lg={10}>
            <h3>{this.props.content && this.props.content.name}</h3>
            Choose fill out all fields.
          </Col>
        </Row>
        <br />
        <Row>
          <Col lg={12}>
            <span style={{ color: colors.error }}>
              {this.state.data.formErrorMessage ? this.state.data.formErrorMessage : ''}
            </span>

            <form>
              <Card>
                <CardBody>
                  <Row>
                    <Col>
                      <Label>Layout</Label>
                      <Field
                        required
                        type='select'
                        component={renderComboBox}
                        id='layout'
                        name='layout'
                        placeholder='Layout'
                        label='Offer Layout'
                        textField='name'
                        valueField='id'
                        data={this.props.layouts}
                        style={inputStyle}
                        isLayout = {true}
                        isContainer = {false}
                        isSite = {false}
                        onChange={this.setLayout}
                      />
                      <Label>Container</Label>
                      <Field
                        required
                        type='select'
                        component={renderComboBox}
                        id='container'
                        name='container'
                        placeholder='Container'
                        label='Offer Container'
                        textField='name'
                        valueField='id'
                        data={this.props.containers}
                        style={inputStyle}
                        onChange={this.setContainer}
                      />
                      <Label>Site</Label>
                      <Field
                        required
                        type='select'
                        component={renderComboBox}
                        id='site'
                        name='site'
                        placeholder='Site'
                        label='Landing Page Site'
                        textField='domain'
                        valueField='id'
                        data={this.props.sites}
                        style={inputStyle}
                        onChange={this.setSite}
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Card>

              <Row>
                <Col >
                  <Button
                    onClick={this.save}
                    color='info'
                    disabled={isSaving}
                    style={{
                      color: 'white',
                      marginBottom: '2%',
                      marginLeft: '2%',
                      float: 'right'
                    }}
                  >
                    {isSaving ? 'Processing' : 'Preview'}
                  </Button>
                  <Button
                    color='secondary'
                    disabled={isSaving}
                    onClick={this.closeModal}
                    style={{
                      color: 'white',
                      marginBottom: '2%',
                      marginLeft: '2%',
                      float: 'right'
                    }}
                  >
                    {isSaving ? 'Processing' : 'Cancel'}
                  </Button>
                </Col>
              </Row>
            </form>
          </Col>
        </Row>
      </ModalBody>
    );
  };





  render() {
    const errors = this.props.getErrors();
    const isDisabled = errors.invalid;

    return (
      <div className='animated'>
        <Button
          color='info'
          onClick={this.openModal}
          disabled={isDisabled}
          style={{
            color: 'white',
            marginBottom: '2%',
            marginLeft: '2%',
            float: 'right',
          }}
        >
          Preview
        </Button>
        <Modal
          isOpen={this.state.data.isOpen}
          toggle={this.toggleFunc}
          onClosed={this.closeModal}
          className={'modal-info'}
          disabled={isDisabled}
        >
          <ModalHeader toggle={this.closeModal}>Preview</ModalHeader>
          {this.renderBody()}
          {/*{this.renderFooter()}*/}
        </Modal>
      </div>
    );
  }
}


const mapState = (state) => {
  const { isLandingPageSaved, saveError, containerIDForPreview, layoutIDForPreview, siteIDForPreview } = state.landingPage;
  const {containers, layouts, sites} = state.metadata;
  return { isLandingPageSaved, containers, layouts, sites, saveError, containerIDForPreview, layoutIDForPreview, siteIDForPreview };
};



const mapDispatch = {getContainerForPreview, getLayoutForPreview, getSiteForPreview, resetModal, fetchAllLayouts, fetchAllContainers, fetchAllSites };

export default connect(mapState, mapDispatch)(PreviewOfferModal);



