import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Card, CardBody, CardHeader } from 'reactstrap';
import classNames from 'classnames';

import OfferReorderingDetailsModal from './OfferReorderingDetailsModal';
import {
  createScorecardTableData,
  createSubHeadlines,
  getDifferenceColorClassName,
  formatTableBodyRow
} from './utilityOfferReorderingDetails';
import ErrorBoundary from '../../../components/ErrorBoundary/ErrorBoundary';
import './OfferReorderingDetails.scss';

const OfferReorderingDetailsScorecard = ({ recommendedSequences }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isApprove, setIsApprove] = useState(false);
  const [isHidden, setIsHidden] = useState(false);

  const tableData = createScorecardTableData(recommendedSequences?.additionalFields || '');
  const subHeadlines = createSubHeadlines(recommendedSequences?.sequence);

  const renderSubHeadline = () =>
    subHeadlines.map(({ label, value }) => (
      <p key={label}>
        {label}:&nbsp;
        <span>{value || 'N/A'}</span>
      </p>
    ));

  const renderTableHeader = () => tableData.map(({ label }) => <th key={label}> {label}</th>);

  const renderTableBody = () =>
    tableData.map(({ value, valueType }, index) => (
      <td key={index} className={getDifferenceColorClassName(value, valueType)}>
        {formatTableBodyRow({ value, valueType })}
      </td>
    ));

  const headline = recommendedSequences?.dataScienceRecommendationId || '';

  const toggleModal = e => {
    setIsApprove(e.target.value === 'approve');
    setIsModalOpen(!isModalOpen);
  };

  const displayDetailStatusOption = status =>
    status === 'PENDING' ? (
      <div className={classNames('offer-reordering-details-buttons', isHidden ? 'hide-element' : '')}>
        <Button color='primary' onClick={toggleModal} value='approve'>
          Approve
        </Button>
        <Button color='secondary' onClick={toggleModal} value='reject'>
          Reject
        </Button>
      </div>
    ) : status === 'APPROVED' ? (
      <Link to={'/campaigns/sequences/' + recommendedSequences.createdSequence.id} target='_blank'>
        Go to created Sequence <i className='fa fa-external-link' aria-hidden='true'></i>
      </Link>
    ) : null;

  return (
    <ErrorBoundary message='There was an error with Sequence Recommendation'>
      <OfferReorderingDetailsModal
        isApprove={isApprove}
        isModalOpen={isModalOpen}
        setIsHidden={setIsHidden}
        setIsModalOpen={setIsModalOpen}
        toggleModal={toggleModal}
      />
      <Card className='offer-reordering-details'>
        <CardHeader>
          <div className='offer-reordering-details-headline'>
            <h3>{headline}</h3>
            {displayDetailStatusOption(recommendedSequences?.status)}
          </div>
          <h6 className='offer-reordering-details-sub-headline'>{renderSubHeadline()}</h6>
        </CardHeader>
        <CardBody>
          <div className='offer-reordering-details-scoreboard'>
            <h3>Sequence Scorecard</h3>
            <table aria-label='Sequence Scorecard'>
              <thead>
                <tr className='center'>{renderTableHeader()}</tr>
              </thead>
              <tbody>
                <tr className='center'>{renderTableBody()}</tr>
              </tbody>
            </table>
          </div>
        </CardBody>
      </Card>
    </ErrorBoundary>
  );
};
export default OfferReorderingDetailsScorecard;
