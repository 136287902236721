import axios from 'axios';
import config from 'react-global-configuration';
import { ZUUL_API_BASE } from '../utils/constants';

export default axios.create({
  baseURL: ZUUL_API_BASE,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Content-Security-Policy': 'upgrade-insecure-requests',
    mode: 'cors',
  },
});
