import _ from 'lodash';
import React, { useState } from 'react';
import { Field } from 'react-final-form';
import Combobox from 'react-widgets/Combobox';
import { Alert, Button, Col, Input, Label, Row, TabPane } from 'reactstrap';
import { useOffersTable } from '../../../views/Offers/queries';
import { ColCondition, Condition, MultiselectField, NumberField, SelectField, ToggleSwitchRow } from '../../FinalForm';
import { useHandlers } from '../../../views/OfferBlocks/queries';

const PrePingPane = ({ collapse, source }) => {
  const generateName = (field) => `${source}.${field}`;
  const { data: offers, loading, error } = useOffersTable();
  const { data: handlers } = useHandlers(collapse, (data) => _.orderBy(data, ['name'], ['asc']));
  const alphabitizedOffers = _.orderBy(offers, ['name'], ['asc']);
  const [advancedSettings, setAdvancedSettings] = useState(false);

  return (
    <TabPane tabId='Pre-ping'>
      <Row>
        <Col>
          <Row>
            <Col>
              <h5>Internal Pre-Ping</h5>
            </Col>
          </Row>
          <hr />
          <Row />
          <Row>
            <Col>
              <h6>
                <em>Suppression Duration</em>
              </h6>
            </Col>
          </Row>
          <Row>
            <Col>
              <Label>Type</Label>
              <SelectField
                name={generateName('suppressionRules.duration.type')}
                options={[
                  { value: '', option: 'None' },
                  { value: 'days', option: 'Days' },
                  { value: 'hours', option: 'Hours' },
                  { value: 'minutes', option: 'Minutes' },
                ]}
              />
            </Col>
            <ColCondition when={generateName('suppressionRules.duration.type')}>
              <NumberField name={generateName('suppressionRules.duration.amount')} label='Amount' />
            </ColCondition>
            <ColCondition when={generateName('suppressionRules.duration.type')}>
              <Label>Level</Label>
              <SelectField
                name={generateName('suppressionRules.suppression_type')}
                options={[
                  { value: '', option: 'Select' },
                  { value: 'none', option: 'None' },
                  { value: 'calculated_revenue', option: 'calculated_revenue' },
                  { value: 'accepted', option: 'accepted' },
                  { value: 'impression', option: 'impression' },
                ]}
              />
            </ColCondition>
            <ColCondition when={generateName('suppressionRules.duration.type')}>
              <NumberField name={generateName('suppressionRules.duration.max_limit')} label='Max Limit' />
            </ColCondition>
          </Row>
          {source !== 'publisherPrePingCriteria' && (
            <>
              <Row>
                <ColCondition when={generateName('suppressionRules.duration.type')}>
                  <Label>Additional Offers</Label>
                  <MultiselectField
                    name={generateName('suppressionRules.additional_offer_ids')}
                    data={alphabitizedOffers}
                    parse={(values) => {
                      return values.map((item) => (item.id ? item.id : item));
                    }}
                  />
                </ColCondition>
              </Row>
            </>
          )}
        </Col>
        {source !== 'publisherPrePingCriteria' && (
          <>
            <Col>
              <div>
                <Row>
                  <Col>
                    <h5>External Pre-Ping</h5>
                  </Col>
                </Row>
                <hr />
                <Row>
                  <Col>
                    <ToggleSwitchRow
                      name={generateName('prePingResultSessionCapture')}
                      label='Pre-Ping Result Stored'
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <label>Pre Ping Method</label>
                    <SelectField
                      name={generateName('prePingMethod')}
                      options={[
                        { value: '', option: 'Select method...' },
                        { value: 'GET', option: 'GET' },
                        { value: 'POST', option: 'POST' },
                        { value: 'JSON', option: 'JSON' },
                        { value: 'XML', option: 'XML' },
                      ]}
                    />
                  </Col>
                  <Col>
                    <Label>Handler</Label>
                    <Field name={generateName('prePingHandlerId')}>
                      {({ input, meta }) => (
                        <>
                          <Combobox
                            {...input}
                            data={handlers}
                            textField='name'
                            onChange={(value) => input.onChange(value)}
                          />
                          {meta.error && meta.touched && <Alert color='danger'>{meta.error}</Alert>}
                        </>
                      )}
                    </Field>
                  </Col>
                </Row>
                <Condition when={generateName('prePingMethod')} is={['GET', 'POST', 'JSON', 'XML']}>
                  <br />
                  <Row>
                    <Col>
                      <Label>URL</Label>
                      <Field name={generateName('prePing')}>
                        {({ input }) => <Input {...input} type='text' placeholder='URL' />}
                      </Field>
                    </Col>
                  </Row>
                  <Condition when={generateName('prePingMethod')} is={['POST', 'JSON', 'XML']}>
                    <Row>
                      <Col>
                        <Label>Body</Label>
                        <Field name={generateName('prePingBody')}>
                          {({ input }) => <Input {...input} type='textarea' placeholder='Body' />}
                        </Field>
                      </Col>
                    </Row>
                  </Condition>
                  <Row>
                    <Col>
                      <Button className='float-right' onClick={() => setAdvancedSettings(!advancedSettings)}>
                        <em>advanced settings</em>
                      </Button>
                    </Col>
                  </Row>
                  <br />
                  {advancedSettings && (
                    <div>
                      <br />
                      <Row>
                        <Col lg={3}>
                          <Field name={generateName('prePingTimeout')}>
                            {({ input }) => <Input {...input} type='number' placeholder='Timeout (ms)' />}
                          </Field>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Field name={generateName('prePingHeader1')}>
                            {({ input }) => <Input {...input} type='text' placeholder='Header 1' />}
                          </Field>
                        </Col>
                        <Col>
                          <Field name={generateName('prePingHeader2')}>
                            {({ input }) => <Input {...input} type='text' placeholder='Header 2' />}
                          </Field>
                        </Col>
                        <Col>
                          <Field name={generateName('prePingHeader3')}>
                            {({ input }) => <Input {...input} type='text' placeholder='Header 3' />}
                          </Field>
                        </Col>
                      </Row>
                      <br />
                      <Row>
                        <Col>
                          <Field name={generateName('prePingCustomHttpStatus')}>
                            {({ input }) => <Input {...input} type='text' placeholder='HTTP Status' />}
                          </Field>
                        </Col>
                      </Row>
                    </div>
                  )}
                </Condition>
              </div>
            </Col>
          </>
        )}
      </Row>
    </TabPane>
  );
};

export default PrePingPane;
