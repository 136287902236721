import { useCallback, useEffect, useState } from 'react';
import { Field, Form } from 'react-final-form';
import toast from 'react-hot-toast';
import 'react-widgets/styles.css';
import {Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import SummaryCard from '../../components/SummaryCard/SummaryCard';
import { Multiselect } from 'react-widgets';
import { columnDefaults } from '../../utils/tableFunctions';
import { renderField } from '../Offers/OfferTitle/renderConsts';
import { useCreateAffiliate, useDeleteAffiliate, useAffiliates, useUpdateAffiliate, useAffiliateTags } from './queries'; // add useAffiliateTags to fetch tags
import { ZuulToaster } from '../../components/Toaster/ZuulToaster';

const columns = [
  {
    dataField: 'id',
    text: 'ID',
    ...columnDefaults,
  },
  {
    dataField: 'name',
    text: 'Name',
    ...columnDefaults,
  },
  {
    dataField: 'networkAffiliateId',
    text: 'Network Affiliate Id',
    ...columnDefaults,
  },
];

const AffiliatesSummary = () => {
  const { data: affiliates, isLoading, error } = useAffiliates(); // Fetch affiliates data
  const { data: tags, isLoading: tagsLoading } = useAffiliateTags('affiliate'); // Fetch tags of type "affiliate"
  const [modal, setModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedTags, setSelectedTags] = useState([]); // Track selected tags
  const [row, setRow] = useState({
    name: '',
    accountExecutiveId: '',
    networkAffiliateId: '',
    networkEmployeeId: '',
    trafficType: '',
    tags: [], // Initialize with an empty array for selected tags
  });
  const { mutate: updateAffiliate, isSuccess: updateSuccess, isError: updateError } = useUpdateAffiliate();
  const { mutate: createAffiliate, isSuccess: createSuccess, isError: createError } = useCreateAffiliate();
  const { mutate: deleteAffiliate, isSuccess: deleteSuccess, isError: deleteError } = useDeleteAffiliate();

  
  useEffect(() => {
    if (deleteSuccess) {
      toast.success('Affiliate Deleted!');
    }
    if (deleteError) {
      toast.error('Error Deleting Affiliate!');
    }
  }, [deleteSuccess, deleteError]);

  useEffect(() => {
    if (createSuccess || updateSuccess) {
      toast.success('Affiliate Saved!');
    }
    if (updateError || createError) {
      toast.error('Error Saving Affiliate!');
    }
  }, [createSuccess, updateSuccess, updateError, createError]);

  const handleRowClick = useCallback((row) => {
    setModal(true); // Open the modal when a row is clicked
    
    // Ensure tags are in an array format, not a JSON string
    const parsedTags = typeof row.tags === 'string' ? JSON.parse(row.tags) : row.tags;
    
    setRow(row); // Pre-fill the form with the selected affiliate's data
    setSelectedTags(parsedTags || []); // Set initial selected tags, ensuring it's an array
  }, []);
  

  const handleFormSubmit = useCallback(
    (values) => {
      // Serialize selectedTags into a JSON string
      const tagsJson = JSON.stringify(selectedTags.map(tag => ({
        id: tag.id,
        name: tag.name,
        type: tag.type,
        metadata: tag.metadata,
        active: tag.active
      })));

      const affiliateData = { 
        ...values, 
        tags: tagsJson // Include serialized tags as a JSON string
      };

      console.log('Affiliate Data:', affiliateData); // Log to check the payload structure

      if (row?.id) {
        updateAffiliate({ ...affiliateData, id: row.id }); // Call update API with row ID
      } else {
        createAffiliate(affiliateData); // Call create API
      }
      setModal(false);
    },
    [row, updateAffiliate, createAffiliate, selectedTags]
  );

  const handleDelete = useCallback(() => {
    setModal(false);
    setDeleteModal(true);
  }, []);

  const confirmDelete = useCallback(() => {
    deleteAffiliate(row?.id);
    setDeleteModal(false);
  }, [deleteAffiliate, row]);

  return (
    <>
      <Form
        onSubmit={handleFormSubmit}
        initialValues={row}
        render={({ handleSubmit, submitting }) => (
          <form onSubmit={handleSubmit}>
            <Modal isOpen={modal} toggle={() => setModal((prevModal) => !prevModal)} className={'modal-info'} backdrop="static">
              <ModalHeader toggle={() => setModal((prevModal) => !prevModal)}>
                {row?.id ? 'Edit Affiliate' : 'Add Affiliate'}
              </ModalHeader>
              <ModalBody>
                <Row className="mt-3">
                  <Col xs={'auto'}>Name</Col>
                  <Col>
                    <Field name='name' component={renderField} type='text' placeholder='Name' readOnly={true}   />
                  </Col>
                </Row>
                <Row>
                  <Col xs={'auto'}>Account Executive ID</Col>
                  <Col>
                    <Field name='accountExecutiveId' component={renderField} type='text' placeholder='Account Executive ID' readOnly={true}  />
                  </Col>
                </Row>
                <Row>
                  <Col xs={'auto'}>Network Affiliate ID</Col>
                  <Col>
                    <Field name='networkAffiliateId' component={renderField} type='text' placeholder='Network Affiliate ID' readOnly={true}  />
                  </Col>
                </Row>
                <Row>
                  <Col xs={'auto'}>Network Employee ID</Col>
                  <Col>
                    <Field name='networkEmployeeId' component={renderField} type='text' placeholder='Network Employee ID' readOnly={true} />
                  </Col>
                </Row>
                <Row>
                  <Col xs={'auto'}>Traffic Type</Col>
                  <Col>
                    <Field name='trafficType' component={renderField} type='text' placeholder='Traffic Type' readOnly={true}  />
                  </Col>
                </Row>
                <Row>
                  <Col xs={'auto'}>Tags</Col>
                  <Col>
                    <Multiselect
                      data={tags || []} // Tags data for affiliate type
                      value={selectedTags} // Currently selected tags
                      onChange={setSelectedTags} // Handle tag selection
                      textField="name" // Display tag name
                      dataKey="id" // Use tag id for value
                      placeholder="Select Tags"
                      disabled={tagsLoading} // Disable if tags are loading
                    />
                  </Col>
                </Row>
              </ModalBody>
              <ModalFooter
                style={{
                  justifyContent: 'space-between',
                }}
              >
                {row?.id && (
                  <button className='btn btn-danger' type='button' onClick={handleDelete}>
                    <i className='fa fa-trash'></i>
                  </button>
                )}
                <Row>
                  <Col>
                    <button className='btn btn-primary' type='submit' disabled={submitting} onClick={handleSubmit}>
                      Update
                    </button>
                  </Col>
                  <Col>
                    <button className='btn btn-secondary' onClick={() => setModal(false)}>
                      Cancel
                    </button>
                  </Col>
                </Row>
              </ModalFooter>
            </Modal>
          </form>
        )}
      />
      <Modal isOpen={deleteModal} toggle={() => setDeleteModal((prevModal) => !prevModal)} className='modal-danger' backdrop="static">
        <ModalHeader toggle={() => setDeleteModal((prevModal) => !prevModal)}>Delete Affiliate</ModalHeader>
        <ModalBody>Are you sure you want to delete this affiliate?</ModalBody>
        <ModalFooter>
          <button className='btn btn-danger' type='button' onClick={confirmDelete}>
            Delete
          </button>
          <button className='btn btn-secondary' onClick={() => setDeleteModal(false)}>
            Cancel
          </button>
        </ModalFooter>
      </Modal>
      <SummaryCard
        entity='Affiliates'
        columns={columns}
        defaultSorted={[{ dataField: 'id', order: 'desc' }]}
        data={affiliates}
        loading={isLoading}
        error={error}
        rowEvents={{
          onClick: (e, row, rowIndex) => {
            handleRowClick(row); // Open the modal when clicking on a row
          },
        }}
      />
      <ZuulToaster />
    </>
  );
};

export default AffiliatesSummary;
