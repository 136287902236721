import _ from "lodash";
import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import SearchLabel from "../../components/SearchLabel/SearchLabel";
import BulkDeleteModal from "../../components/BulkDeleteModal/BulkDeleteModal";
import ZuulLoader from "../ZuulLoader/ZuulLoader";

/**
 * Renders a summary card with a table and search functionality.
 * @param {string} entity - The name of the entity being displayed.
 * @param {array} columns - The columns to display in the table.
 * @param {object} selectRow - The row selection config: Example: const selectRow = { mode: 'checkbox', clickToSelect: true, style: { backgroundColor: '#c8e6c9' }};
 * @param {React.Component} createComponent - The endpoint to create a new entity.
 * @param {array} data - The data to display in the table.
 * @param {array} defaultSorted - The default sorting for the table.
 * @param {boolean} loading - Whether the data is currently being loaded.
 * @param {boolean} error - Whether an error occurred while fetching the data.
 * @param {node} children - Additional components to render in the card body.
 * @param {object} rowEvents - The events to attach to each row in the table.
 */
const SummaryCard = ({
  entity,
  columns,
  selectRow,
  selectedRows,
  createComponent,
  data,
  defaultSorted,
  loading,
  error,
  children,
  rowEvents,
}) => {
  const { SearchBar } = Search;

  if (loading) {
    return (
      <Card>
        <CardHeader>
          <i className='icon-menu' />
          {entity}
        </CardHeader>
        <CardBody>
          <ZuulLoader text={`Loading ${entity}...`} />
        </CardBody>
      </Card>
    );
  }

  if (error) {
    return (
      <Card>
        <CardHeader>
          <i className='icon-menu' />
          {entity}
        </CardHeader>
        <CardBody>
          <div className='text-center'>Failed to fetch {entity}</div>
        </CardBody>
      </Card>
    );
  }

  // Column matching for the table
  function onColumnMatch({ searchText, value, column, row }) {
    let match = true;
    if (searchText === '') {
      return match;
    }
    let splitSearchTerm = searchText.split(/[ ,]+/).filter(Boolean);
    let rowValues = Object.values(_.mapValues(row, (value) => (value?.name ? value.name : value)));
    let rowValuesString = rowValues.join(' ');
    splitSearchTerm.forEach((term) => {
      if (!rowValuesString.toLowerCase().includes(term.toLowerCase())) {
        match = false;
      }
    });
    return match;
  }

  return (
    <Card>
      <CardHeader>
        <i className='icon-menu' />
        {entity}
      </CardHeader>
      <CardBody>
        <Row>
          <Col lg={6}>
            <span className='display-4'>{entity}</span>
          </Col>
          <Col lg={6}>{createComponent}</Col>
        </Row>
        <ToolkitProvider
          bootstrap4
          keyField='id'
          data={data}
          columns={columns}
          search={{
            onColumnMatch: onColumnMatch,
          }}
        >
          {(props) => (
            <div style={{ paddingTop: '1rem' }}>
              <SearchLabel />
              <Row>
                <Col>
                  <SearchBar
                    {...props.searchProps}
                    placeholder='Use spaces to separate search terms'
                    style={{ height: '2.4rem', width: '18rem' }}
                  />
                </Col>
                <Col>{children}</Col>
                <Col></Col>
                <Col>
                  {selectedRows?.length ? (
                    <BulkDeleteModal modalType={` ${entity}`} ids={selectedRows} entity={entity} />
                  ) : (
                    <></>
                  )}
                </Col>
              </Row>
              <hr />
              <BootstrapTable
                pagination={paginationFactory()}
                filter={filterFactory()}
                defaultSorted={defaultSorted}
                rowStyle={() => {
                  return { cursor: 'pointer' };
                }}
                keyField='id'
                rowKey='id'
                rowEvents={rowEvents}
                selectRow={selectRow}
                hover
                {...props.baseProps}
              />
            </div>
          )}
        </ToolkitProvider>
      </CardBody>
    </Card>
  );
};

export default SummaryCard;
