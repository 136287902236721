import _ from 'lodash';
import React, { Component } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import 'react-widgets/styles.css';
import { Button, Card, CardBody, CardHeader, Col, Collapse, Row } from 'reactstrap';
import { change, Field, FieldArray, reduxForm } from 'redux-form';
import {
  addOfferTrigger,
  getOfferActionsById,
  getOfferData,
  getOfferTriggersByOfferId,
  getOfferWallOffer,
  getWallOffers,
  removeOfferTriggerState,
  saveAsOfferName,
  saveInitData,
  saveOffer,
  swapOfferWallSlots,
  updateOfferTriggers,
  updateSelectedPosts,
  updateSelectedWallOffers,
} from '../../../../actions';
import Criteria from '../../../../components/Criteria/Criteria';
import PreviewOfferModal from '../../../../components/PreviewOfferModal/PreviewOfferModal';
import api from '../../../../services';
import { handleCriteriaInitialize, handleCriteriaSubmit } from '../../../../utils/CriteriaHelpers';
import { filterActionTypesByLinkoutCount } from '../../../../utils/OfferActionHelper';
import { DB_TRIGGER_TYPES, TRIGGER_TYPES } from '../../../../utils/constants';
import validate from '../../validate';
import CancelOfferModal from '../CancelOfferModal';
import CopyButton from '../CopyButton';
import FormEditor from '../OfferQuestion/FormEditor';
import '../OfferQuestion/OfferQuestion.css';
import OfferVariationModalButton from '../OfferVariationModalButton';
import SaveOfferModal from '../SaveOfferModal';
import { initializeOfferTriggers, prepareOfferToCopy } from '../offerTitleHelper/offerTitleHelper';
import {
  renderActionTypeDropdown,
  renderField,
  renderOfferPostAction,
  renderSelectedOfferAction,
  renderToggleSwitch,
} from '../renderConsts';
import { validateWholeNums } from '../valildation';
import './OfferWall.css';
import OfferSlots from './Offerslots';
import CustomVariablesCard from '../../../../components/CustomVariables/CustomVariablesCard';
import { processCustomVariables } from '../../../../components/CustomVariables/utils';

class OfferWall extends Component {
  constructor(props) {
    super(props);
    this.state = {
      files: [],
      activeTab: '1',

      action: '',
      // advertiserValue: null,
      selectValue: 'Monday',
      value: [],
      wallOffersObj: {},
      userAttribute: 'select',
      questionAction: [],
      questionText: '',
      questionActionChildren: 'select',
      advertisers: [],
      isEditMode: false,
      // isActive: false,
      initialState: 'select',
      offerContent: {},
      offerId: null,
      offerWallOfferId: null,
      collapse: false,
      collapseOfferWall: true,
      collapseHeaderFooter: false,
      collapseTinymceHeadline: false,
      httpIsHidden: true,
      errors: {
        disable: false,
      },
      copyToVariation: false,
    };
    this.toggle = this.toggle.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.handleInitialize = this.handleInitialize.bind(this);
    this.toggleHeaderFooter = this.toggleHeaderFooter.bind(this);
    this.toggleTinymceHeadline = this.toggleTinymceHeadline.bind(this);
    this.getErrors = this.getErrors.bind(this);
    this.handleHttpStatusToggle = this.handleHttpStatusToggle.bind(this);
    this.handleChangeQuestionText = this.handleChangeQuestionText.bind(this);
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  toggleOfferWallContent = () => {
    this.setState({ collapseOfferWall: !this.state.collapseOfferWall });
  };

  toggleHeaderFooter() {
    this.setState({ collapseHeaderFooter: !this.state.collapseHeaderFooter });
  }

  toggleTinymceHeadline() {
    this.setState({ collapseTinymceHeadline: !this.state.collapseTinymceHeadline });
  }

  getSelectedOfferId(selectedOfferStr) {
    console.log(selectedOfferStr);
    if (typeof selectedOfferStr === 'number') return selectedOfferStr;
    let id = typeof selectedOfferStr === 'object' && selectedOfferStr !== null ? selectedOfferStr.id : null;
    if (id) return id;
    let name =
      typeof selectedOfferStr === 'object' && selectedOfferStr !== null ? selectedOfferStr.name : selectedOfferStr;
    for (let i = 0; i < this.props.offerWall.WALLOFFERS.length; i++) {
      if (this.props.offerWall.WALLOFFERS[i].name === name) {
        id = this.props.offerWall.WALLOFFERS[i].id;
        break;
      }
    }
    return id;
  }

  handleChangeQuestionText = (event) => {
    this.setState({
      questionText: event.target.value,
      errors: Object.assign({}, this.state.errors, {
        questionText: event.target.value.length === 0,
      }),
    });
  };

  getErrors = () => {
    let errors = {
      ...this.props.errors,
      ...this.state.errors,
      invalid: this.props.submitFailed ? this.props.invalid : false,
    };

    return errors;
  };

  handleHttpStatusToggle = () => {
    this.setState({
      httpIsHidden: !this.state.httpIsHidden,
    });
  };

  handleOfferSlots = (offerWallSlotEntries = [], offerContent, offerWallObj) => {
    let sortedOfferWallSlots = [];

    // Because the slotIds correspond with the offer wall slot numbers, and the slot labels are alphabetically placed in the UI, we can use sorting to make the code look cleaner
    offerWallSlotEntries = offerWallSlotEntries.sort((a, b) => {
      if (a.slotId - b.slotId !== 0) return a.slotId - b.slotId;
      else {
        if (a.slotLabel < b.slotLabel) return -1;
        if (a.slotLabel > b.slotLabel) return 1;
        return 0;
      }
    });
    const SLOT_LABELS = ['Control', 'A', 'B', 'C'];
    offerWallSlotEntries.forEach((offerWallSlotEntry, j) => {
      if (!sortedOfferWallSlots[offerWallSlotEntry.slotId]) {
        sortedOfferWallSlots[offerWallSlotEntry.slotId] = [];
      }

      // Inserts the offerWallSlotEntry in the sortedOfferWallSlots matrix [[slot1], [slot2]] in the postion that corresponds with the slotLabel i.e. control is first position, A is second, etc.
      const slotLabelIndex = SLOT_LABELS.indexOf(offerWallSlotEntry.slotLabel);
      if (slotLabelIndex === -1 || offerWallSlotEntry.slotLabel === null) {
        return;
      }
      sortedOfferWallSlots[offerWallSlotEntry?.slotId][slotLabelIndex] = offerWallSlotEntry;
    });

    console.log(sortedOfferWallSlots);
    // Horizontal Offer Wall Slot
    let parsedOfferWallSlots = sortedOfferWallSlots.map((offerWallSlot) => {
      if (offerWallSlot.length > 0) {
        // Vertical Slots
        const parsedOfferWallSlot = offerWallSlot.map((slot) => {
          return {
            flex: slot.flex,
            id: slot.id,
            slot_ad: slot.offerWallAd,
          };
        });

        return parsedOfferWallSlot;
      } else {
        // Vertical Slots
        return {
          flex: 1,
          id: null,
          slot_ad: null,
          name: null,
        };
      }
    });
    console.log(parsedOfferWallSlots);
    parsedOfferWallSlots =
      parsedOfferWallSlots.length > 0
        ? parsedOfferWallSlots
        : [
            [
              {
                flex: 1,
                id: null,
                slot_ad: null,
                name: null,
              },
            ],
            [
              {
                flex: 1,
                id: null,
                slot_ad: null,
                name: null,
              },
            ],
            [
              {
                flex: 1,
                id: null,
                slot_ad: null,
                name: null,
              },
            ],
            [
              {
                flex: 1,
                id: null,
                slot_ad: null,
                name: null,
              },
            ],
          ];
    console.log(parsedOfferWallSlots);
    return parsedOfferWallSlots;
  };

  findIdFromName(jsonContainer, name) {
    for (let o of jsonContainer) {
      if (o.name === name) {
        return o.id;
      }
    }
    return null;
  }

  handleInitialize() {
    const offer = this.state.offerContent;
    const { offerWallOffer } = this.state;
    const { offerTriggers } = this.props;
    if (!offer) return false;
    const offerContent = offer.content == null ? {} : JSON.parse(offer.content);
    let offerSlots = [],
      offerActions = null,
      offer_wall_headline = null,
      offer_wall_sub_headline = null,
      slot_1_ad_id = null,
      slot_2_ad_id = null,
      slot_3_ad_id = null,
      slot_4_ad_id = null;

    let initData;
    if (offer !== null) {
      const offerWallObj = _.mapKeys(this.props.offerWall.WALLOFFERS, 'id');

      offerSlots = this.handleOfferSlots(offerWallOffer.offerWallSlotEntries, offerContent, offerWallObj);
      console.log(offerSlots);
      offerActions = offerTriggers === null ? null : initializeOfferTriggers(offerTriggers);

      if (offerContent && offerContent !== null) {
        offer_wall_headline = offerContent.offer_wall_headline || null;
        offer_wall_sub_headline = offerContent.offer_wall_sub_headline || null;
        slot_1_ad_id = offerContent.slot_1_ad_id || null;
        slot_2_ad_id = offerContent.slot_2_ad_id || null;
        slot_3_ad_id = offerContent.slot_3_ad_id || null;
        slot_4_ad_id = offerContent.slot_4_ad_id || null;
      }

      if (offerWallOffer && offerWallOffer !== null) {
        offer_wall_headline = offerWallOffer.headline || null;
        offer_wall_sub_headline = offerWallOffer.subheadline || null;
      }
      const offerCriteria = handleCriteriaInitialize(offer, null);
      initData = {
        offerSlots,
        offerActions: offerActions === null ? null : offerActions,
        offer_wall_headline,
        offer_wall_sub_headline,
        displayedSlots: offerWallOffer.displayedSlots || 4,
        footer: offer.footer === null ? null : offer.footer,
        tinymceHeadline: offerWallOffer.tinymceHeadline ? offerWallOffer.tinymceHeadline : null,
        customVariables: offer.customVariables ?? null,
        isActive: offer.isActive === null ? true : offer.isActive,
        isPrimaryVariant: offer.isPrimaryVariant,
        ...offerCriteria,
      };
    }
    let selectedOffers = [slot_1_ad_id, slot_2_ad_id, slot_3_ad_id, slot_4_ad_id];

    console.log(offerSlots);
    this.setState({
      offerWallSlots: offerSlots,
    });

    console.log(initData);
    const selectedWallOffersParsed = offerSlots.map((offerSlot, index) => {
      // return { [`offerWall-${index + 1}`]: [offerAd] };
      return offerSlot
        .map((slot) => (slot.slot_ad && slot.slot_ad ? slot.slot_ad.id : null))
        .filter((offerSlot) => offerSlot !== null);
    });
    console.log(selectedWallOffersParsed);
    selectedWallOffersParsed.forEach((offerWallAdArr, index) => {
      this.props.updateSelectedWallOffers(index, offerWallAdArr);
    });
    this.props.initialize(initData);
  }

  handleOfferActions = (offerActions, isRefetch) => {
    const offerActionPosts = [];
    const offerActionLinkouts = [];
    let offerActionAttributes = [];
    // console.log(response.data.content);
    try {
      offerActions.forEach((offerAction) => {
        const { offerActionPost, offerActionLinkout, offerActionUserAttributes, active } = offerAction;
        if (offerActionPost) {
          offerActionPosts.push({
            active: offerAction.active,
            id: offerActionPost.id,
            offerActionId: offerAction.id,
            name: offerAction.title,
            advertiser: offerActionPost.advertiser,
            url: offerActionPost.url,
            postMethod: offerActionPost.postMethod,
            http_auth_username: offerActionPost.answerHttpUsername,
            http_auth_password: offerActionPost.answerHttpPassword,
            body: offerActionPost.body,
            has_revenue: offerActionPost.revenue || offerActionPost.revenueAmount ? true : false,
            header_1: offerActionPost.httpHeader1,
            header_2: offerActionPost.httpHeader2,
            header_3: offerActionPost.httpHeader3,
            killswitch_handler_id:
              typeof offerActionPost.httpKillSwitchHandler === 'object' &&
              offerActionPost.httpKillSwitchHandler !== null
                ? offerActionPost.httpKillSwitchHandler.id
                : null,
            killswitch_http_status: offerActionPost.otherKillSwitchStatus,
            handlerId:
              typeof offerActionPost.httpSuccessHandler === 'object' && offerActionPost.httpSuccessHandler !== null
                ? offerActionPost.httpSuccessHandler.id
                : null,
            revenue_on_gross_or_success: offerActionPost.revenueOn,
            revenue: offerActionPost.revenueAmount,
            custom_http_status: offerActionPost.otherSuccessfulHttpStatus,
          });
        } else if (offerActionLinkout) {
          offerActionLinkouts.push({
            active: offerAction.active,
            id: offerActionLinkout.id,
            offerActionId: offerAction.id,
            name: offerAction.title,
            advertiser: offerActionLinkout.advertiser,
            url: offerActionLinkout.url,
            linkout_has_revenue: offerActionLinkout.hasRevenue,
            linkout_revenue: offerActionLinkout.revenue,
            killswitch_handler_id:
              typeof offerActionLinkout.httpKillSwitchHandler === 'object' &&
              offerActionLinkout.httpKillSwitchHandler !== null
                ? offerActionLinkout.httpKillSwitchHandler.id
                : null,
            killswitch_http_status: offerActionLinkout.httpKillSwitchHandler,
            handlerId:
              typeof offerActionLinkout.httpSuccessHandler === 'object' &&
              offerActionLinkout.httpSuccessHandler !== null
                ? offerActionLinkout.httpSuccessHandler.id
                : null,
          });
        } else if (offerActionUserAttributes && offerActionUserAttributes.length > 0) {
          offerActionAttributes.push({
            active: offerAction.active,
            id: offerActionUserAttributes[0].id,
            offerActionId: offerAction.id,
            name: offerAction.title,
          });
        }
      });

      if (isRefetch) {
        return [...offerActionPosts, ...offerActionLinkouts, ...offerActionAttributes];
      }
      const actions = Object.assign({}, this.state, {
        linkouts: offerActionLinkouts,
        posts: offerActionPosts,
        attributes: offerActionAttributes,
        active: offerActions.active,
        offerActions,
      });

      this.setState(actions);
    } catch (error) {
      console.log(error);
      console.log('There was an error handling your data.');
    }
  };

  saveOfferData = async (values) => {
    const { newOfferName } = this.props;
    let oType = 7;

    let { offerActionArray } = values;

    const offerCriteria = handleCriteriaSubmit(values, this.props.handlers);
    const zuulUser = JSON.parse(localStorage.getItem('userData'));
    let formatData = Object.assign({}, values, {
      zuulUser,
      offerType: {
        id: oType,
      },
      isActive: this.props.isActive,
      status: 1,
      name: this.props.offerName,
      lastPublished: new Date(),
      isPrimaryVariant: values.isPrimaryVariant,
      ...offerCriteria,
    });
    if (this.props.advertiser) {
      formatData.advertiser = {
        id: this.props.advertiser.id,
      };
    }

    console.log(formatData);
    let SLOT_LABELS = ['Control', 'A', 'B', 'C'];
    console.log(values.offerSlots);
    let offerWallSlotEntries = values.offerSlots.map((offerSlot, index) => {
      return offerSlot.map((slot, idx) => {
        const slotLabel = SLOT_LABELS[idx];
        console.log(slot);
        const slotEntry = {
          flex: parseInt(slot.flex),
          offerWallAd: { id: this.getSelectedOfferId(slot.slot_ad) },
          isControl: slotLabel === 'Control',
          slotId: index,
          slotLabel: slotLabel,
        };
        // If this slot already has an ID and this is not a copied offer
        if (slot.id && !newOfferName) {
          slotEntry.id = slot.id;
        }
        return slotEntry;
      });
    });

    console.log(offerWallSlotEntries);

    const offerWallOffer = {
      displayedSlots: values.displayedSlots,
      headline: values.offer_wall_headline,
      isUpgraded: true,
      offer: formatData,
      offerWallSlotEntries: _.flatten(offerWallSlotEntries),
      subheadline: values.offer_wall_sub_headline,
      tinymceHeadline: values.tinymceHeadline,
    };
    console.log(offerWallOffer);
    if (this.state.isEditMode === true && !newOfferName && this.state.offerWallOfferId) {
      if (this.state.offerId) {
        formatData.id = this.state.offerId;
      }

      offerWallOffer.id = this.state.offerWallOfferId;
      console.log('EDIT: ' + JSON.stringify(formatData));

      api.put(`offerwalloffers/with-offer/${this.state.offerWallOfferId}`, offerWallOffer).then(async (result) => {
        const offerWall = result.data;
        const offerId = offerWall.offer.id;
        console.log(offerWall);
        console.log(JSON.stringify(offerWall));
        let offerTriggers = [];
        offerActionArray.forEach((offerAction) => {
          offerTriggers.push({
            offer: {
              id: offerId,
            },
            offerActionTriggers: [
              {
                offerAction: {
                  id: parseInt(offerAction.id),
                },
                offerActionType: offerAction.actionType,
                showOfferByOfferActionCriteria: offerAction.showOfferByOfferActionCriteria,
              },
            ],
            offerQuestionAnswer: null,
            offerWallAd: null,
            triggerType: {
              id: TRIGGER_TYPES[offerAction.triggerType]
                ? TRIGGER_TYPES[offerAction.triggerType].id
                : DB_TRIGGER_TYPES[offerAction.triggerType].id,
            },
            paramName: offerAction.paramName ? offerAction.paramName : null,
            paramValue: offerAction.paramValue ? offerAction.paramValue : null,
          });
        });

        console.log('OFFER TRIGGERS', offerTriggers);
        console.log('OFFER TRIGGERS', JSON.stringify(offerTriggers));
        return this.props.updateOfferTriggers(offerTriggers, this.props.offerTriggers).then((offerTriggers) => {
          console.log('UPDATED OFFER TRIGGERS', offerTriggers);
          this.props.saveOffer(true);
        });
      });
    } else {
      if (this.state.offerId) {
        formatData.id = this.state.offerId;
      }

      if (newOfferName) {
        formatData.name = newOfferName;
        formatData.id = null;
        formatData.isActive = false;
      }

      console.log(offerWallOffer);
      console.log('CREATE: ' + JSON.stringify(offerWallOffer));
      api.post('offerwalloffers', offerWallOffer).then(async (result) => {
        const offerWall = result.data;
        const offerId = offerWall.offer.id;
        if (this.state.copyToVariation) {
          console.log('COPY TO VARIATION');
          this.props.saveAsOfferName('');
          await this.addOfferTriggers({ offerId, offerActionArray, redirect: false });

          api
            .post(
              `offervariations/create-for-two-offers?primaryOfferId=${this.props.match.params.offerId}&secondaryOfferId=${offerId}`
            )
            .then(() => {
              this.setState({ copyToVariation: false });
              return this.props.refetchOfferVariations();
            });
        }
      });
    }
    if (this.state.copyToVariation) {
      console.log('COPY TO VARIATION');
      const saveData = prepareOfferToCopy({ formData: formatData, newOfferName });
      saveData.isPrimaryVariant = false;
      saveData.isActive = false;
      console.log('CREATE Variation: ' + saveData);
      api.post('offers', saveData).then(async (result) => {
        const offer = result.data;
        const offerId = offer.id;
        this.props.saveAsOfferName('');

        await this.addOfferTriggers({ offerId, offerActionArray, redirect: false });

        api
          .post(
            `offervariations/create-for-two-offers?primaryOfferId=${this.props.match.params.offerId}&secondaryOfferId=${offerId}`
          )
          .then(() => {
            this.setState({ copyToVariation: false });
            return this.props.refetchOfferVariations();
          });
      });
    }
  };

  async addOfferTriggers({ offerId, offerActionArray, redirect }) {
    let offerTriggers = [];
    offerActionArray.forEach((offerAction) => {
      offerTriggers.push({
        offer: {
          id: offerId,
        },
        offerActionTriggers: [
          {
            offerAction: {
              id: parseInt(offerAction.id),
            },
            offerActionType: offerAction.actionType,
            showOfferByOfferActionCriteria: offerAction.showOfferByOfferActionCriteria,
          },
        ],
        offerQuestionAnswer: null,
        offerWallAd: null,
        triggerType: {
          id: TRIGGER_TYPES[offerAction.triggerType]
            ? TRIGGER_TYPES[offerAction.triggerType].id
            : DB_TRIGGER_TYPES[offerAction.triggerType].id,
        },
        paramName: offerAction.paramName ? offerAction.paramName : null,
        paramValue: offerAction.paramValue ? offerAction.paramValue : null,
      });
    });

    console.log('OFFER TRIGGERS', offerTriggers);
    console.log('OFFER TRIGGERS', JSON.stringify(offerTriggers));
    return this.props.addOfferTrigger(offerTriggers).then((offerTriggers) => {
      console.log('ADDED OFFER TRIGGERS', offerTriggers);
      this.props.saveOffer(true);
      if (redirect) {
        // If there's no new offerName, this is a new offer and we should re-route them back to the offer table
        this.props.history.push('/campaigns/offertable');
      }
    });
  }

  onSubmit(values, copyToVariation) {
    console.log(copyToVariation);
    // return;
    if (!this.props.advertiser) {
      this.setState((prevState) => {
        return {
          ...prevState,
          errors: {
            ...prevState.errors,
            isAdvertiserMissing: true,
            _error: 'This offer is missing an advertiser.',
          },
        };
      });
      setTimeout(() => {
        this.setState({
          errors: {
            ...this.state.errors,
            isAdvertiserMissing: false,
            _error: null,
          },
        });
      }, 2000);
    }

    let { offer_wall_headline, offer_wall_sub_headline, displayedSlots, offerSlots, tinymceHeadline } = values;
    let contentValue = {
      offer_wall_headline,
      offer_wall_sub_headline,
    };
    const offerActions = values.offerActions == null ? null : values.offerActions;
    const offerActionArray = [];

    if (offerActions != null && offerActions.length > 0) {
      offerActions.forEach((action) => {
        console.log(action);
        offerActionArray.push({
          id: action.action_id,
          name: action.name,
          triggerType: action.post_type || 'ON_IMPRESSION',
          actionType: action.question_actions || 'POST',
          showOfferByOfferActionCriteria: action.shouldShowCriteria || false,
        });
      });
    }
    console.log(offerActionArray);
    let questionData = Object.assign({}, values, {
      offer_wall_headline,
      offer_wall_sub_headline,
      offerSlots,
      displayedSlots: parseInt(displayedSlots),
      offerActionArray,
      tinymceHeadline,
      content: JSON.stringify(contentValue),
      customVariables:
        values.customVariables && values.customVariables.length > 0
          ? JSON.stringify(processCustomVariables(values.customVariables))
          : null,
    });
    console.log(questionData);

    this.saveOfferData(questionData);
    this.setState({ isEditMode: true });
  }

  handleOfferActionChange = (selector) => {
    this.props.change(`${selector}.question_actions`, null);
    this.props.change(`${selector}.name`, null);
    this.props.change(`${selector}.action_advertiser`, null);
    this.props.change(`${selector}.action_id`, null);
    this.props.change(`${selector}.actionStatus`, null);
  };

  handleOfferActionNameChange = (action, selector) => {
    const active = action.active ? action.active : null;
    const advertiser = action.advertiser ? action.advertiser : null;
    const action_id = action.offerActionId ? action.offerActionId : '';
    this.props.change(`${selector}.action_advertiser`, advertiser);
    this.props.change(`${selector}.action_id`, action_id);
    this.props.change(`${selector}.actionStatus`, active);
  };

  resetForm = () => this.props.reset();

  ageLimit = (value) => {
    return value && (value < 10 || value > 99) ? 'Age must be between 10 and 99' : undefined;
  };

  async componentDidMount() {
    const { offer } = this.props;
    if (offer === null || (offer && !offer.id)) {
      this.setState(
        {
          offerContent: this.props.initData,
          offerWallOffer: {},
          isEditMode: false,
        },
        async () => {
          this.handleInitialize();
          await this.props.getWallOffers();
        }
      );
    } else if (offer.id) {
      const offerWallOffer = (await this.props.getOfferWallOffer(offer.id)) || {};
      console.log('OFFER WALL OFFER : ', offerWallOffer);
      this.props.getOfferTriggersByOfferId(offer.id).then((offerTriggers) => {
        console.log(offerTriggers);
        this.setState(
          {
            offerContent: offer,
            offerId: offer.id,
            offerWallOffer,
            offerWallOfferId: offerWallOffer && offerWallOffer !== null ? offerWallOffer.id : null,
            isEditMode: true,
          },
          async () => {
            this.handleInitialize();
            await this.props.getWallOffers();
          }
        );
      });
    }
  }

  componentWillUnmount() {
    if (!this.state.isEditMode) {
      this.props.saveInitData('offerwall');
    }
    this.props.destroy('offerwall');
    this.props.removeOfferTriggerState();
  }

  onDragEnd = (result) => {
    const { destination, source, draggableId, type } = result;
    console.log(destination, source, draggableId, type);

    if (!destination) {
      return;
    }

    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return;
    }

    if (type === 'slot' && destination.droppableId === source.droppableId) {
      this.reorderSlots(destination, source, draggableId);
    } else if (type === 'column') {
      this.reorder(destination, source, draggableId);
    }
  };

  reorder = (destination, source, draggableId) => {
    const { dispatch } = this.props;
    console.log(destination);
    console.log(source);
    console.log(this.props.offerSlots);

    let selectedSlot = this.props.offerSlots[source.index];
    const newOfferWallSlots = Array.from(this.props.offerSlots);
    newOfferWallSlots.splice(source.index, 1);
    newOfferWallSlots.splice(destination.index, 0, selectedSlot);
    this.props.swapOfferWallSlots(source.index, destination.index);
    dispatch(change('offerwall', `offerSlots`, newOfferWallSlots));
  };

  getListStyle = (isDraggingOver, draggableProps, draggableSnapshot, transform) => {
    return {
      ...draggableProps,
      border:
        isDraggingOver && draggableSnapshot.isDragging
          ? '5px solid #4dbd74'
          : isDraggingOver
          ? '5px solid #f86c6b'
          : 'none',
      transform,
    };
  };

  reorderSlots = (destination, source, draggableId) => {
    const { dispatch } = this.props;
    const offerWallSlot = destination.droppableId.split('-')[1];
    let selectedSlots = this.props.offerSlots[offerWallSlot];
    const slot = selectedSlots[source.index];
    const newSlots = Array.from(selectedSlots);
    newSlots.splice(source.index, 1);
    newSlots.splice(destination.index, 0, slot);
    dispatch(change('offerwall', `offerSlots[${offerWallSlot}]`, newSlots));
  };

  getPreview = async (formValues) => {
    const offerID = this.props.curLandingPage.offer.id || null;
    const containerID = formValues.container.id;
    const layoutID = formValues.layout.id;
    const siteID = formValues.site.id;

    return new Promise((resolve) => {
      api.get(`offers/offer/preview/${offerID}/${containerID}/${layoutID}/${siteID}`).then((response) => {
        const myWindow = window.open();
        let html = response.data.replace(/{{{nr_script_header}}}/g, '').replace(/{{{nr_script_footer}}}/g, '');
        myWindow.document.write(html);
        resolve(html);
      });
    });
  };

  renderOfferSlots(fields) {
    return (
      <Row>
        <Col lg={12}>
          <div id='offer-wall' className='colorTest'>
            <br />
            <h3>Offer Wall</h3>
            <DragDropContext onDragEnd={this.onDragEnd}>
              <Droppable droppableId='offerWall' direction='horizontal' type='column'>
                {(droppableProvided, droppableSnapshot) => {
                  return (
                    <div
                      {...droppableProvided.droppableProps}
                      ref={droppableProvided.innerRef}
                      id='offer-wall-droppable'
                    >
                      <Row id='offer-wall-row'>
                        {fields.map((offerSlot, index) => {
                          return (
                            <Draggable draggableId={offerSlot} key={offerSlot} index={index}>
                              {(draggableProvided, draggableSnapshot) => {
                                let offerWallSlotWidth;
                                const offerWallSlotTransform = draggableProvided.draggableProps
                                  ? draggableProvided.draggableProps.style.transform
                                  : null;
                                // grabs the x value in pixels from the transform property of each offerSlot
                                let nonDraggingSlotTransform =
                                  typeof offerWallSlotTransform === 'string'
                                    ? offerWallSlotTransform.substring(10, offerWallSlotTransform.indexOf('px'))
                                    : null;
                                offerWallSlotWidth = nonDraggingSlotTransform * 0.7;
                                nonDraggingSlotTransform = nonDraggingSlotTransform
                                  ? `translate(${nonDraggingSlotTransform - offerWallSlotWidth}px, 0px)`
                                  : null;
                                return (
                                  <Col key={index} xl={3} lg={4} md={6} sm={12} xs={12}>
                                    <div
                                      ref={draggableProvided.innerRef}
                                      {...draggableProvided.dragHandleProps}
                                      {...draggableProvided.draggableProps}
                                      className='offer-wall-slot'
                                      style={this.getListStyle(
                                        droppableSnapshot.isDraggingOver,
                                        draggableProvided.draggableProps.style,
                                        draggableSnapshot,
                                        draggableSnapshot.isDragging ? offerWallSlotTransform : nonDraggingSlotTransform
                                      )}
                                    >
                                      <OfferSlots
                                        name={offerSlot}
                                        offerSlot={offerSlot}
                                        key={index}
                                        fields={fields}
                                        index={index}
                                      />
                                    </div>
                                  </Col>
                                );
                              }}
                            </Draggable>
                          );
                        })}
                      </Row>
                    </div>
                  );
                }}
              </Droppable>
            </DragDropContext>
          </div>
        </Col>
      </Row>
    );
  }

  renderOfferSlotSection = ({ fields, meta: { touched, error } }) => {
    return (
      <>
        <Row>
          <Col sm={6} lg={2}>
            <Field
              name='displayedSlots'
              normalize={validateWholeNums}
              component={renderField}
              type='number'
              label='Displayed Slots'
            />
          </Col>

          <Col sm={6} lg={{ offset: 4 }}>
            <Button
              color='success'
              id='addOfferWallSlot'
              onClick={() =>
                fields.push([
                  {
                    flex: 1,
                    id: null,
                    slot_ad: null,
                  },
                ])
              }
            >
              Add Offer Wall Slot
            </Button>
          </Col>
        </Row>
        {this.renderOfferSlots(fields)}
      </>
    );
  };

  render() {
    const { selector } = this.props.offerState;
    const { attributes, posts, postWaterfalls, linkoutWaterfalls, gtmEvents, linkouts } = this.props;
    const disabled = posts.length === 0;

    //******* Start of offerActions *******/
    let OfferActions = ({ action, action_id, actionStatus, offer_posts, index, fields, actionType, offerTriggers }) => {
      action_id = typeof action === 'object' && action !== null ? action.offerActionId : action_id;
      const actionData =
        actionType === 'POST'
          ? posts
          : actionType === 'USER_ATTRIBUTE'
          ? attributes
          : actionType === 'POST_WATERFALL'
          ? postWaterfalls
          : actionType === 'LINKOUT_WATERFALL'
          ? linkoutWaterfalls
          : actionType === 'GTM_EVENT'
          ? gtmEvents
          : actionType === 'LINKOUT'
          ? linkouts
          : [];
      return (
        <div key={index} className='colorTest'>
          <br />

          <Row>
            <Col lg={1}>
              <br />

              <Button
                color='danger'
                className='removeofferInputParent'
                type='button'
                title='Remove Parent Attribute'
                onClick={() => fields.remove(index)}
              >
                <i className='fa fa-close' />
              </Button>
            </Col>
            <Col lg={1}>
              <br />
              {!action_id ? (
                <Button color='info' type='button' title='Visit action details' disabled={true}>
                  <i className='fa fa-search' />
                </Button>
              ) : (
                <Link to={`/campaigns/offeractions/${action_id}`} target='_blank'>
                  <Button color='info' type='button' title='Visit action details'>
                    <i className='fa fa-search' />
                  </Button>
                </Link>
              )}
            </Col>
            <Col lg={2}>
              <Field
                name={`${offer_posts}.post_type`}
                type='select'
                component={renderOfferPostAction}
                label='Action happens on:'
              />
            </Col>
            {renderActionTypeDropdown({
              data: filterActionTypesByLinkoutCount(offerTriggers),
              handleOfferActionChange: this.handleOfferActionChange,
              fieldName: `${offer_posts}.question_actions`,
              offerAction: offer_posts,
            })}
            {renderSelectedOfferAction({
              actionStatus,
              entityName: 'Offer',
              handleOfferActionNameChange: this.handleOfferActionNameChange,
              offerAction: offer_posts,
              actionType,
              data: actionData,
              disabled,
            })}
            {actionType && actionType === 'POST' && (
              <Col lg={3}>
                <Field
                  name={`${offer_posts}.action_advertiser`}
                  component={renderField}
                  type='text'
                  disabled
                  label='Advertiser Name'
                />
              </Col>
            )}
          </Row>

          <br />
        </div>
      );
    };
    OfferActions = connect((state, props) => {
      const offerTriggers = selector(state, `offerActions`) || [];
      return {
        action: selector(state, `${props.offer_posts}.name`),
        action_id: selector(state, `${props.offer_posts}.action_id`),
        actionStatus: selector(state, `${props.offer_posts}.actionStatus`),
        has_revenue: !!selector(state, `${props.offer_posts}.has_revenue`),
        actionType: selector(state, `${props.offer_posts}.question_actions`),
        linkout_has_revenue: !!selector(state, `${props.offer_posts}.linkout_has_revenue`),
        offerTriggers,
      };
    })(OfferActions);

    const renderOfferActions = ({ fields, meta: { touched, error } }) => (
      <div>
        <Row>
          <Col lg={3}>
            <h4>Offer Actions</h4>
          </Col>
          <Col lg={9}>
            <Button color='success' className='float-right' onClick={() => fields.push({})}>
              Add Offer Action
            </Button>
            {touched && error && <span>{error}</span>}
          </Col>
        </Row>
        <br />
        {fields.map((questionAnswers, index) => (
          <OfferActions offer_posts={questionAnswers} fields={fields} index={index} key={index} />
        ))}
      </div>
    );
    return (
      <div className='animated fadeIn'>
        <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
          <Card>
            <CardHeader>
              <Row>
                <Col lg='10' onClick={this.toggleOfferWallContent}>
                  <h4>
                    Offer Wall Details &nbsp;&nbsp;
                    {this.state.collapseOfferWall ? (
                      <i className='fa fa-chevron-up opened' />
                    ) : (
                      <i className='fa fa-chevron-down closed' />
                    )}
                  </h4>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <Collapse isOpen={this.state.collapseOfferWall}>
                <Row>
                  <Col lg='6'>
                    <Field
                      name='offer_wall_headline'
                      type='text'
                      id='offer_wall_headline'
                      component={renderField}
                      label='Headline'
                      onChange={this.handleChangeQuestionText}
                      className={this.state.errors.questionText === true ? 'error' : ''}
                    />
                  </Col>
                  <Col lg='6'>
                    <Field
                      name='offer_wall_sub_headline'
                      type='text'
                      id='offer_wall_sub_headline'
                      component={renderField}
                      label='Sub-Headline'
                      onChange={this.handleChangeQuestionText}
                      className={this.state.errors.questionText === true ? 'error' : ''}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Card>
                      <CardHeader onClick={this.toggleTinymceHeadline} id='clickTinymceHeadline'>
                        <h4>
                          Offer Wall Copy&nbsp;&nbsp;
                          {this.state.collapseTinymceHeadline ? (
                            <i className='fa fa-chevron-up opened' />
                          ) : (
                            <i className='fa fa-chevron-down closed' />
                          )}
                        </h4>
                      </CardHeader>
                      <CardBody>
                        <Collapse isOpen={this.state.collapseTinymceHeadline}>
                          <Row>
                            <Col>
                              <Field name='tinymceHeadline' type='text' id='tinymceHeadline' component={FormEditor} />
                            </Col>
                          </Row>
                        </Collapse>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
                <br />
                <FieldArray name='offerSlots' component={this.renderOfferSlotSection} />
                <Row>
                  <Col lg={12}>
                    <label htmlFor='triggersRegistration' style={{ verticalAlign: 'super' }}>
                      Count as Registration
                    </label>
                    &nbsp;&nbsp;&nbsp;
                    <Field name='triggersRegistration' id='triggersRegistration' component={renderToggleSwitch} />
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col lg={12}>
                    <FieldArray name='offerActions' component={renderOfferActions} />
                  </Col>
                </Row>
              </Collapse>
            </CardBody>
          </Card>
          <Card>
            <CardHeader onClick={this.toggleHeaderFooter} id='clickHeader'>
              {this.state.collapseHeaderFooter ? (
                <div>
                  <h4>
                    Header/Footer&nbsp;&nbsp; <i className='fa fa-chevron-up opened' />
                  </h4>
                </div>
              ) : (
                <div>
                  <h4>
                    Header/Footer&nbsp;&nbsp; <i className='fa fa-chevron-down closed' />
                  </h4>
                </div>
              )}
            </CardHeader>
            <CardBody>
              <Collapse isOpen={this.state.collapseHeaderFooter}>
                <Row>
                  <Col>
                    <Field name='footer' type='text' id='footer' component={FormEditor} />
                  </Col>
                </Row>
              </Collapse>
            </CardBody>
          </Card>
          <FieldArray name='customVariables' component={CustomVariablesCard} customVarFlag='UD_' />
          <Criteria
            limitAnswers={this.state.answers}
            capAmount={this.props.capAmount}
            // capSegmentConfiguration={this.props.capSegmentConfiguration}
          />
          <Row className='justify-content-end'>
            <Col xs='auto'>
              <PreviewOfferModal
                isLP={false}
                isEditMode={this.state.isEditMode}
                getErrors={this.getErrors}
                onClickSubmit={this.props.handleSubmit(this.getPreview)}
              />
            </Col>
            {this.state.isEditMode && (
              <Col xs='auto'>
                <OfferVariationModalButton
                  getErrors={this.getErrors}
                  isDisabled={this.getErrors().invalid}
                  onClickSubmit={this.props.handleSubmit((values) => {
                    this.setState({ copyToVariation: true }, this.onSubmit(values, true));
                  })}
                  linkouts={this.props.linkouts}
                  posts={this.props.posts}
                />
              </Col>
            )}
            {this.state.isEditMode && (
              <Col xs='auto'>
                <CopyButton
                  getErrors={this.getErrors}
                  isDisabled={this.getErrors().invalid}
                  onClickSubmit={this.props.handleSubmit(this.onSubmit)}
                  linkouts={this.props.linkouts}
                  posts={this.props.posts}
                />
              </Col>
            )}
            <SaveOfferModal
              isDisabled={this.getErrors().invalid}
              offerName={this.props.offerName}
              getErrors={this.getErrors}
              onClickSubmit={this.props.handleSubmit(this.onSubmit)}
              linkouts={this.props.linkouts}
              posts={this.props.posts}
            />
            <Col xs='auto'>
              <CancelOfferModal linkouts={this.props.linkouts} posts={this.props.posts} getErrors={this.getErrors} />
            </Col>
          </Row>
        </form>
      </div>
    );
  }
}

const form = reduxForm({
  form: 'offerwall',
  destroyOnUnmount: false,
  touchOnChange: true,
  validate,
});

function mapStateToProps(state) {
  const {
    advertiser,
    newOfferName,
    // offerName,
    offerTriggers,
    offerActionTriggers,
    selector,
  } = state.offerState;
  const { dispatch, initData, handlers } = state.criteria;
  const capAmount = state.form.offerwall?.values?.capAmount;
  // const capSegmentConfiguration = state.form.offerwall?.values?.capSegmentConfiguration || [];

  return {
    advertiser,
    capAmount,
    // capSegmentConfiguration,
    dispatch,
    initData,
    newOfferName,
    handlers,
    offerTriggers,
    offerActionTriggers,
    offerContent: state.offerContent,
    offerWall: state.offerWall,
    offerSlots: selector(state, 'offerSlots'),
    displayedSlots: selector(state, 'displayedSlots'),
    offerState: state.offerState,
  };
}

export default connect(mapStateToProps, {
  addOfferTrigger,
  getOfferActionsById,
  getOfferData,
  getOfferWallOffer,
  getWallOffers,
  saveOffer,
  saveInitData,
  saveAsOfferName,
  updateOfferTriggers,
  updateSelectedWallOffers,
  updateSelectedPosts,
  getOfferTriggersByOfferId,
  removeOfferTriggerState,
  swapOfferWallSlots,
})(form(withRouter(OfferWall)));
