import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import Combobox from 'react-widgets/Combobox';
import { Field } from 'react-final-form';
import { Col, TabPane } from 'reactstrap';
import { useAffiliates, useDomains, useUserAttributes } from '../../../views/OfferBlocks/queries';
import { ColCondition, Condition, MultiselectField, SelectRow } from '../../FinalForm';
import { UserAttribute } from '../UserAttribute';
import { DomainAffiliate } from './DomainAffiliate';

const AttributePane = ({ collapse, push, source }) => {
  const [loadingData, setLoadingData] = useState(true);
  const generateName = (field) => `${source}.${field}`;
  const { data: userAttributes } = useUserAttributes(collapse, (data) => _.orderBy(data, ['name'], ['asc']));
  const { data: affiliates, isLoading: affiliatesLoading } = useAffiliates(true, (data) =>
    _.orderBy(data, ['name'], ['asc']).map(({ name, networkAffiliateId }) => ({
      id: networkAffiliateId,
      name,
    }))
  );
  const { data: domains, isLoading: domainsLoading } = useDomains(collapse, (data) =>
    data.map((domain) => ({ id: domain.id, name: domain.name })).sort((a, b) => (a > b ? 1 : -1))
  );

  let affiliateNames = affiliatesLoading
    ? [{ name: '[None]', id: null }]
    : [{ name: '[None]', id: null }, ...affiliates];

  useEffect(() => {
    domainsLoading || affiliatesLoading ? setLoadingData(true) : setLoadingData(false);
  }, [domainsLoading, affiliatesLoading]);

  return (
    <TabPane tabId='Attribute'>
      <SelectRow name={generateName('criteriaIncludeOrExcludeUserAttributes')} label='User Attributes'></SelectRow>
      <Condition when={generateName('criteriaIncludeOrExcludeUserAttributes')} is={['Include', 'Exclude']}>
        <Col xs={'auto'} style={{ flexGrow: 1 }}>
          <Field name={generateName('userAttributeRules')} component={UserAttribute} userAttributes={userAttributes} />
        </Col>
      </Condition>
      {source !== 'publisherPrePingCriteria' && (
        <>
          <SelectRow name={generateName('criteriaIncludeOrExcludeAffNames')} label='Affiliate Names'>
            <ColCondition when={generateName('criteriaIncludeOrExcludeAffNames')}>
              <MultiselectField
                name={generateName('criteriaAffNames')}
                data={affiliateNames}
                textField={(item) => `${item.id}: ${item.name}`}
                dataKey='name'
                busy={affiliatesLoading}
              />
            </ColCondition>
          </SelectRow>
          <DomainAffiliate domains={domains} affiliateNames={affiliateNames} isLoading={loadingData} />
        </>
      )}
      <SelectRow name={generateName('criteriaIncludeOrExcludeEmailDomains')} label='Email Domains'>
        <ColCondition when={generateName('criteriaIncludeOrExcludeEmailDomains')}>
          <MultiselectField
            name={generateName('criteriaEmailDomains')}
            data={[
              'aim.com',
              'aol.com',
              'gmail.com',
              'google.com',
              'hotmail.com',
              'live.com',
              'outlook.com',
              'sbcglobal.net',
              'yahoo.com',
            ]}
            parse={(value) => {
              if (source === 'publisherPrePingCriteria') {
                // Handle value as a string array
                return value;
              } else {
                // Handle value as a concatenated string
                return value.join(',');
              }
            }}
            format={(value) => {
              if (source === 'publisherPrePingCriteria') {
                // Handle value as a string array
                return Array.isArray(value) ? value : [];
              } else {
                // Handle value as a concatenated string
                if (!value) {
                  return [];
                } else if (typeof value !== 'string') {
                  return [value];
                }
                return value.split(',');
              }
            }}
          />
        </ColCondition>
      </SelectRow>
      {/* Landing Page Includes/Excludes */}
    </TabPane>
  );
};

export default AttributePane;
