import React from 'react';
import { Field, Form } from 'react-final-form';
import Switch from 'react-switch';
import DatePicker from 'react-widgets/DatePicker';
import { Alert, Button, Col, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import api from '../../services';
import { RenderNumberPicker } from './components';

const renderToggleSwitch = (field) => {
  let isChecked = field.input.value;
  if (field.input.value === 'TRUE') {
    isChecked = true;
  } else if (field.input.value === 'FALSE') {
    isChecked = false;
  }
  return (
    <Switch
      checked={isChecked}
      onChange={field.input.onChange}
      disabled={field.disabled}
      onColor='#4dbd74'
      offColor='#f86c6b'
      onHandleColor='#ffffff'
      uncheckedIcon={false}
      checkedIcon={false}
      boxShadow='0px 1px 5px rgba(0, 0, 0, 0.6)'
      activeBoxShadow='0px 0px 1px 10px rgba(0, 0, 0, 0.2)'
      height={18}
      width={42}
      className='react-switch'
    />
  );
};

const formatDate = (dataObj) => {
  dataObj = typeof dataObj === 'string' ? new Date(dataObj) : dataObj;

  if (dataObj === undefined) {
    return '';
  } else {
    return `${dataObj.getFullYear()}-${(dataObj.getMonth() + 1)
      .toString()
      .padStart(2, '0')}-${dataObj.getDate()} ${dataObj
      .getHours()
      .toString()
      .padStart(2, '0')}:${dataObj
      .getMinutes()
      .toString()
      .padStart(2, '0')}`;
  }
};

const RevShareExceptionModal = (props) => {
  const onSubmit = (form) => {
    console.log('🚀 ~ file: RevShareExceptionModal.js ~ line 53 ~ onSubmit ~ form', form);
    console.log('Hit RevShareExceptionModal onSubmit');
    const originalStartDate = form.startDate;
    const originalEndDate = form.endDate;
    if (typeof form.startDate === 'string') {
      var startDate = new Date(form.startDate);
      startDate.setDate(startDate.getDate() + 1);
      form.startDate = formatDate(startDate);
    }
    if (typeof form.endDate === 'string') {
      if (form.endDate !== '') {
        var endDate = new Date(form.endDate);
        endDate.setDate(endDate.getDate() + 1);
        form.endDate = formatDate(endDate);
      }
    }

    if (props.oldRule) {
      props.oldRule.default = false;
      props.oldRule.endDate = form.startDate;
      if (typeof props.oldRule.startDate === 'string') {
        var oldStartDate = new Date(props.oldRule.startDate);
        oldStartDate.setDate(oldStartDate.getDate() + 1);
        props.oldRule.startDate = formatDate(oldStartDate);
      }
      api
        .put(`revshare-percentage-rules/${props.oldRule.id}`, props.oldRule)
        .then((resp) => {
          console.log('🚀 ~ file: RevShareExceptionModal.js ~ line 81 ~ .then ~ resp', resp);
          delete form.affiliateName;
          form.id
            ? api.put(`revshare-percentage-rules/${form.id}`, form).then(() => {
                // getRevSharePercentageRules();
              })
            : api.post(`revshare-percentage-rules`, form).then(() => {
                // getRevSharePercentageRules();
              });
          form.startDate = originalStartDate;
          form.endDate = originalEndDate;
        })
        .finally(() => {
          props.closeModal();
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data.message);
          }
        });
    } else {
      delete form.affiliateName;
      form.id
        ? api.put(`revshare-percentage-rules/${form.id}`, form).finally(() => {
            props.closeModal();
          })
        : api.post(`revshare-percentage-rules`, form).finally(() => {
            props.closeModal();
          });
      form.startDate = originalStartDate;
      form.endDate = originalEndDate;
    }
  };

  if (props.oldRule) {
    return (
      <Modal isOpen={props.exceptionModalOpen} className='modal-danger' size='xl'>
        <ModalHeader>
          <>
            Action Needed: Newly-created Revshare Percentage Rule conflicts with an existing Rule:{' '}
            {props.exceptionReason}
          </>
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col md={6}>
              <Form
                onSubmit={onSubmit} // validate={validate}
                initialValues={props.oldRule}
                render={({ handleSubmit, values }) => (
                  <form onSubmit={handleSubmit}>
                    <>
                      <Label>Affiliate ID: </Label>
                      <Row>
                        <Col>
                          <Field
                            name='affiliateId'
                            component={RenderNumberPicker}
                            placeholder={'Affiliate ID'}
                            disabled={true}
                          />
                        </Col>
                        <Col>
                          <Input
                            disabled={true}
                            value={
                              props.allAffiliates?.find((el) => el.networkAffiliateId === values.affiliateId)
                                ? props.allAffiliates?.find((el) => el.networkAffiliateId === values.affiliateId)?.name
                                : values.affiliateName
                            }
                          />
                        </Col>
                      </Row>

                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          paddingTop: 10,
                        }}
                      >
                        <Label>Default: </Label>
                        <Field name='default' component={renderToggleSwitch} disabled={true} />
                      </div>

                      <Label>Revshare Percentage: </Label>
                      <Field
                        name='revSharePercentage'
                        component={RenderNumberPicker}
                        placeholder='Revshare Percentage'
                        precision={1}
                        step={0.1}
                        disabled={true}
                      />

                      <Label>Start Date: </Label>
                      <Field name='startDate' parse={(date) => formatDate(date)}>
                        {({ input, meta }) => (
                          <>
                            <DatePicker
                              defaultValue={new Date(input.value?.replace(/-/g, '/'))}
                              onChange={input.onChange}
                              disabled={true}
                            />
                            {meta.error && meta.touched && <Alert color='danger'>{meta.error}</Alert>}
                          </>
                        )}
                      </Field>

                      <Label>End Date: </Label>
                      <Field
                        name='endDate'
                        parse={(date) => {
                          try {
                            formatDate(date);
                          } catch (error) {
                            return null;
                          }
                          return formatDate(date);
                        }}
                      >
                        {({ input }) => (
                          <>
                            <DatePicker
                              value={input.value ? new Date(input.value?.replace(/-/g, '/')) : null}
                              onChange={input.onChange}
                              disabled={true}
                            />
                          </>
                        )}
                      </Field>
                      <Label>Domain: </Label>
                      <Field name='webDomain'>
                        {({ input }) => (
                          <>
                            <Input
                              placeholder='Domain'
                              defaultValue={input.value}
                              onChange={input.onChange}
                              disabled={true}
                            />
                          </>
                        )}
                      </Field>
                      <Label>Sub ID: </Label>
                      <Field name='subId' component={RenderNumberPicker} placeholder='Sub ID' disabled={true} />
                      {values.affiliateId === 1006 && (
                        <>
                          <Label>AIB List ID: </Label>
                          <Field
                            name='aibListId'
                            component={RenderNumberPicker}
                            placeholder='AIB List ID'
                            disabled={true}
                          />
                          <Label>AIB Type ID: </Label>
                          <Field
                            name='aibTypeId'
                            component={RenderNumberPicker}
                            placeholder='AIB Type ID'
                            disabled={true}
                          />
                        </>
                      )}
                      <Label>Landing Page Group ID: </Label>
                      <Field
                        name='landingPageGroupId'
                        component={RenderNumberPicker}
                        placeholder='Landing Page Group ID'
                        disabled={true}
                      />
                    </>
                  </form>
                )}
              />
            </Col>
            <Col md={6}>
              <Form
                // validate={validate}
                onSubmit={onSubmit}
                initialValues={props.rulesStoredInDB}
                render={({ handleSubmit, values }) => (
                  <form onSubmit={handleSubmit}>
                    <>
                      <Label>Affiliate ID: </Label>
                      <Row>
                        <Col>
                          <Field
                            name='affiliateId'
                            component={RenderNumberPicker}
                            placeholder={'Affiliate ID'}
                            disabled={values.default === 'TRUE' ? true : false}
                          />
                        </Col>
                        <Col>
                          <Input
                            disabled={true}
                            value={
                              props.allAffiliates.find((el) => el.networkAffiliateId === values.affiliateId)
                                ? props.allAffiliates.find((el) => el.networkAffiliateId === values.affiliateId)?.name
                                : values.affiliateName
                            }
                          />
                        </Col>
                      </Row>

                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          paddingTop: 10,
                        }}
                      >
                        <Label>Default: </Label>
                        <Field name='default' component={renderToggleSwitch} />
                      </div>

                      <Label>Revshare Percentage: </Label>
                      <Field
                        name='revSharePercentage'
                        component={RenderNumberPicker}
                        placeholder='Revshare Percentage'
                        precision={1}
                        step={0.1}
                      />

                      <Label>Start Date: </Label>
                      <Field name='startDate' parse={(date) => formatDate(date)}>
                        {({ input, meta }) => (
                          <>
                            <DatePicker defaultValue={new Date(input.value)} onChange={input.onChange} />
                            {meta.error && meta.touched && <Alert color='danger'>{meta.error}</Alert>}
                          </>
                        )}
                      </Field>

                      <Label>End Date: </Label>
                      <Field
                        name='endDate'
                        parse={(date) => {
                          try {
                            formatDate(date);
                          } catch (error) {
                            return null;
                          }
                          return formatDate(date);
                        }}
                      >
                        {({ input }) => (
                          <>
                            <DatePicker
                              value={input.value ? new Date(input.value) : null}
                              onChange={input.onChange}
                              disabled={values.default === 'TRUE' ? true : false}
                            />
                          </>
                        )}
                      </Field>
                      <Label>Domain: </Label>
                      <Field name='webDomain'>
                        {({ input }) => (
                          <>
                            <Input
                              placeholder='Domain'
                              defaultValue={input.value}
                              onChange={input.onChange}
                              disabled={values.default}
                            />
                          </>
                        )}
                      </Field>
                      <Label>Sub ID: </Label>
                      <Field
                        name='subId'
                        component={RenderNumberPicker}
                        placeholder='Sub ID'
                        disabled={values.default === 'TRUE' ? true : false}
                      />
                      {values.affiliateId === 1006 && (
                        <>
                          <Label>AIB List ID: </Label>
                          <Field
                            name='aibListId'
                            component={RenderNumberPicker}
                            placeholder='AIB List ID'
                            disabled={values.default === 'TRUE' ? true : false}
                          />
                          <Label>AIB Type ID: </Label>
                          <Field
                            name='aibTypeId'
                            component={RenderNumberPicker}
                            placeholder='AIB Type ID'
                            disabled={values.default === 'TRUE' ? true : false}
                          />
                        </>
                      )}
                      <Label>Landing Page Group ID: </Label>
                      <Field
                        name='landingPageGroupId'
                        component={RenderNumberPicker}
                        placeholder='Landing Page Group ID'
                        disabled={values.default === 'TRUE' ? true : false}
                      />
                      <ModalFooter>
                        <Button color='success' type='submit'>
                          Submit
                        </Button>
                        <Button color='primary' onClick={() => props.closeModal()}>
                          Cancel
                        </Button>
                      </ModalFooter>
                    </>
                  </form>
                )}
              />
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    );
  }
  return (
    <Modal isOpen={props.exceptionModalOpen} className='modal-danger' size='xl'>
      <ModalHeader>
        Action Needed: Newly-created Revshare Percentage Rule conflicts with an existing Rule: {props.exceptionReason}
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col>
            <Form
              onSubmit={onSubmit} // validate={validate}
              initialValues={props.newRule}
              render={({ handleSubmit, values }) => (
                <form onSubmit={handleSubmit}>
                  <>
                    <Label>Affiliate ID: </Label>
                    <Row>
                      <Col>
                        <Field
                          name='affiliateId'
                          component={RenderNumberPicker}
                          placeholder={'Affiliate ID'}
                          disabled={values.default === 'TRUE' ? true : false}
                        />
                      </Col>
                      <Col>
                        <Input
                          disabled={true}
                          value={
                            props.allAffiliates?.find((el) => el.networkAffiliateId === values.affiliateId)
                              ? props.allAffiliates?.find((el) => el.networkAffiliateId === values.affiliateId)?.name
                              : values.affiliateName
                          }
                        />
                      </Col>
                    </Row>

                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        paddingTop: 10,
                      }}
                    >
                      <Label>Default: </Label>
                      <Field name='default' component={renderToggleSwitch} />
                    </div>

                    <Label>Revshare Percentage: </Label>
                    <Field
                      name='revSharePercentage'
                      component={RenderNumberPicker}
                      placeholder='Revshare Percentage'
                      precision={1}
                      step={0.1}
                    />

                    <Label>Start Date: </Label>
                    <Field name='startDate' parse={(date) => formatDate(date)}>
                      {({ input, meta }) => (
                        <>
                          <DatePicker defaultValue={new Date(input.value)} onChange={input.onChange} />
                          {meta.error && meta.touched && <Alert color='danger'>{meta.error}</Alert>}
                        </>
                      )}
                    </Field>

                    <Label>End Date: </Label>
                    <Field
                      name='endDate'
                      parse={(date) => {
                        try {
                          formatDate(date);
                        } catch (error) {
                          return null;
                        }
                        return formatDate(date);
                      }}
                    >
                      {({ input }) => (
                        <>
                          <DatePicker
                            value={input.value ? new Date(input.value?.replace(/-/g, '/')) : null}
                            onChange={input.onChange}
                            disabled={values.default === 'TRUE' ? true : false}
                          />
                        </>
                      )}
                    </Field>
                    <Label>Domain: </Label>
                    <Field name='webDomain'>
                      {({ input }) => (
                        <>
                          <Input
                            placeholder='Domain'
                            defaultValue={input.value}
                            onChange={input.onChange}
                            disabled={values.default}
                          />
                        </>
                      )}
                    </Field>
                    <Label>Sub ID: </Label>
                    <Field
                      name='subId'
                      component={RenderNumberPicker}
                      placeholder='Sub ID'
                      disabled={values.default === 'TRUE' ? true : false}
                    />
                    {values.affiliateId === 1006 && (
                      <>
                        <Label>AIB List ID: </Label>
                        <Field
                          name='aibListId'
                          component={RenderNumberPicker}
                          placeholder='AIB List ID'
                          disabled={values.default === 'TRUE' ? true : false}
                        />
                        <Label>AIB Type ID: </Label>
                        <Field
                          name='aibTypeId'
                          component={RenderNumberPicker}
                          placeholder='AIB Type ID'
                          disabled={values.default === 'TRUE' ? true : false}
                        />
                      </>
                    )}
                    <Label>Landing Page Group ID: </Label>
                    <Field
                      name='landingPageGroupId'
                      component={RenderNumberPicker}
                      placeholder='Landing Page Group ID'
                      disabled={values.default === 'TRUE' ? true : false}
                    />
                  </>
                </form>
              )}
            />
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button color='success' type='submit'>
          Submit
        </Button>
        <Button color='primary' onClick={() => props.closeModal()}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default RevShareExceptionModal;
