import axios from 'axios';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Alert, Card, CardBody, CardHeader, Col, Collapse, Label, Row } from 'reactstrap';
import { Field, FieldArray, reduxForm } from 'redux-form';
import { fetchAllOfferActions, updateCriteriaType, updateSelector } from '../../../actions';
import CancelEntityModal from '../../../components/CancelEntityModal/CancelEntityModal';
import { ImageCard } from '../../../components/ImageCard/ImageCard';
import SaveEntityModal from '../../../components/SaveEntityModal/SaveEntityModal';
import api from '../../../services';
import { handleCriteriaInitialize, handleCriteriaSubmit } from '../../../utils/CriteriaHelpers';
import Criteria from '../../../components/Criteria/Criteria';
import { CDN_ASSETS_BASE } from '../../../utils/constants';
import { fetchAllAdvertisers } from '../../Advertisers/actions';
import { fetchAllHandlers } from '../../Metadata/Handlers/actions';
import OfferActionsForm from '../../OfferActions/OfferActionsForm/OfferActionsForm';
import { handleInitializeActions } from '../../OfferActions/OfferActionsHelpers';
import ActiveStatusBadge from '../../Offers/OfferTitle/ActiveStatusBadge';
import ActiveStatusModal from '../../Offers/OfferTitle/ActiveStatusModal';
import { renderComboBox, renderField, renderOfferPostAction } from '../../Offers/OfferTitle/renderConsts';
import { createOfferWallAdApi, editOfferWallAd, getOfferWallAd } from '../actions';
import './OfferWallAdDetails.css';
import validate from './validate';

class OfferWallAdDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.id,
      isActive: false,
      curOfferWallAd: null,
      offerWallAdName: '',
      offerWallAdCopy: '',
      offerWallAdCTACopy: '',
      offerWallAdCriteria: [],
      offerWallAdActions: [],
      advertisers: [],
      advertiserValue: null,
      advertiserName: '',
      errors: {
        offerWallAdName: false,
        offerWallAdCopy: false,
        offerWallAdCTACopy: false,
      },
    };
  }

  // Image
  handleBrokenURL = () => {
    const { imageDimensions } = this.state;
    console.log(this.state.imageFile);
    if (imageDimensions.length === 0) return;
    // remove the first dimension from the array so you don't keep trying the same dimension for every failed render
    const newDimension = imageDimensions.shift();
    const newURL = `${CDN_ASSETS_BASE}${this.props.curOfferWallAd.image.id}/${newDimension}.${this.props.curOfferWallAd.image.fileExtension}`;
    const imageDataFile = {
      name: newDimension,
      encodedImageData: this.props.curOfferWallAd.image.encodedImageData,
      preview: newURL,
      id: this.props.curOfferWallAd.image.id,
      size: 0,
      sizeCoordinates: this.props.curOfferWallAd.image.sizeCoordinates,
      fileName: this.props.curOfferWallAd.image.fileName,
      fileExtension: this.props.curOfferWallAd.image.fileExtension,
    };
    this.setState({
      imageFile: [imageDataFile],
      imageDimensions: imageDimensions,
    });
  };

  resetForm = () => {
    this.setState({ imageFile: [] });
    this.props.change('shouldResizeImage', null);
  };

  toggleActions = () => {
    this.setState({ collapseAction: !this.state.collapseAction });
  };

  initializeOfferWallAdData = () => {
    let curOfferWallAd;
    let id = null;
    if (this.state.isEditMode === true) {
      id = this.state.id;
    }

    return new Promise((resolve, reject) => {
      if (this.state.isEditMode === false) {
        curOfferWallAd = {
          isActive: false,
        };
        this.setState({
          isOfferWallAdFetched: true,
          loading: false,
        });

        this.props.getOfferWallAd(curOfferWallAd);

        resolve(curOfferWallAd);
      } else {
        this.setState({
          isOfferWallAdFetched: false,
          loading: true,
        });

        axios
          .all([api.get(`offerwallads/${id}`), api.get(`offer-trigger/offerwallad/${id}`)])
          .then(
            axios.spread((offerWallAdResponse, offerWallAdTriggersResponse) => {
              let offerWallAdData = offerWallAdResponse.data;
              console.log(offerWallAdData);
              const name = !offerWallAdData.name ? '' : offerWallAdData.name;

              // Criteria
              let offerWallAdCriteria =
                offerWallAdData !== null && offerWallAdData.offerCriteria !== null
                  ? handleCriteriaInitialize(offerWallAdData.offerCriteria, this.props.additionalOffersObj)
                  : null;

              // Advertiser
              const advertiser = offerWallAdData.advertiser
                ? {
                    id: offerWallAdData.advertiser.id,
                    name: offerWallAdData.advertiser.name,
                  }
                : null;

              //Images
              const imageURL =
                offerWallAdData.image && offerWallAdData.image.id !== null
                  ? CDN_ASSETS_BASE + offerWallAdData.image.id + '/original.' + offerWallAdData.image.fileExtension
                  : null;

              if (imageURL !== null) {
                let sizeCoordinates = offerWallAdData.image.sizeCoordinates || '{}';
                let imageDimensions = JSON.parse(sizeCoordinates);
                console.log(imageDimensions);
                // grab only the image dimensions to use later on if the base image doesn't render
                imageDimensions = Object.keys(imageDimensions).filter((dimension) => dimension !== 'base');

                var doNotResize = offerWallAdData.image && offerWallAdData.image.doNotResize;

                var imageDataFile = {
                  id: offerWallAdData.image.id,
                  name: offerWallAdData.image.fileName,
                  fileName: offerWallAdData.image.fileName,
                  fileExtension: offerWallAdData.image.fileExtension,
                  doNotResize: doNotResize,
                  encodedImageData: offerWallAdData.image.encodedImageData,
                  sizeCoordinates: offerWallAdData.image.sizeCoordinates,
                  targetHeight: offerWallAdData.image.targetHeight,
                  targetWidth: offerWallAdData.image.targetWidth,
                  version: offerWallAdData.image.version,
                  preview: imageURL,
                  size: 0,
                };
                this.setState({
                  imageFile: [imageDataFile],
                  shouldResizeImage: !doNotResize,
                  imageDimensions,
                });
              }

              // Initialize triggers and actions
              let offerWallAdActions = handleInitializeActions(offerWallAdTriggersResponse.data);

              // Send data to store
              this.props.getOfferWallAd(offerWallAdData);

              // Set State from initialized values
              this.props.initialize({
                isOfferWallAdSaved: false,
                offerWallAdName: name,
                offerWallAdCopy: offerWallAdData.copy,
                offerWallAdCTACopy: offerWallAdData.ctaCopy,
                offerWallAdImage: imageDataFile,
                shouldResizeImage: !doNotResize,
                advertiser,
                advertiserName: offerWallAdData.advertiser && offerWallAdData.advertiser.name,
                ...offerWallAdCriteria,
                offerWallAdActions,
              });

              this.setState(
                {
                  isOfferWallAdFetched: true,
                  loading: false,
                  collapseAction: offerWallAdActions && offerWallAdActions.length > 0,
                },
                () => {
                  resolve(offerWallAdData);
                }
              );
            })
          )
          .catch((error) => console.log(error));
      }
    });
  };

  onSubmitForm = async (values) => {
    // Retrieve values from form data
    let {
      offerWallAdName = null,
      offerWallAdCopy = null,
      offerWallAdCTACopy = null,
      advertiser = null,
      offerWallAdActions = [],
      offerWallAdImage = null,
      shouldResizeImage = false,
    } = values;

    // Get active flag
    const currentActiveStatus = this.props.isActive !== null ? this.props.isActive : this.props.curOfferWallAd.isActive;

    // Get image
    const doNotResize = !shouldResizeImage;
    let image = offerWallAdImage ? Object.assign({}, offerWallAdImage, { doNotResize }) : null;

    // Parse actions
    const offerWallAdActionsReduced = offerWallAdActions.map((triggerWithActions) => {
      let reducedActions = triggerWithActions.actions.map((action) => {
        return {
          actionId: action.action_id,
          offerActionType: action.offerActionType,
          showOfferByOfferActionCriteria: action.shouldShowCriteria,
        };
      });

      return {
        triggerType: triggerWithActions.triggerType || 'ON_IMPRESSION',
        showOfferAndOrLogic: triggerWithActions.actionCriteriaAndOr || 'AND',
        actions: reducedActions,
      };
    });

    // Assemble criteria values
    let offerWallAdCriteria = handleCriteriaSubmit(values, this.props.handlers);

    if (this.state.isEditMode) {
      // Edit mode, update existing offer wall ad criteria
      offerWallAdCriteria.id = this.props.curOfferWallAd.offerCriteria
        ? this.props.curOfferWallAd.offerCriteria.id
        : null;
    }

    let offerWallAdToSave = {
      isActive: currentActiveStatus,
      offerCriteria: offerWallAdCriteria,
      name: offerWallAdName,
      copy: offerWallAdCopy,
      ctaCopy: offerWallAdCTACopy,
      image: image,
      advertiser: advertiser,
      status: 1,
    };

    // Send data to api
    if (this.state.isEditMode) {
      // Edit existing offer wall ad
      offerWallAdToSave.id = this.state.id;
      console.log('submitting');
      await this.props.editOfferWallAd(offerWallAdToSave, offerWallAdActionsReduced, offerWallAdToSave.id);
      return !this.props.saveError;
    } else {
      // Creating new offer wall ad
      return this.props
        .createOfferWallAdApi(offerWallAdToSave, offerWallAdActionsReduced)
        .then((createdOfferWallAd) => {
          console.log('Completed create offer wall ad.');
          if (!this.props.saveError) this.props.history.push('/campaigns/offerwallads');
        });
    }
  };

  getErrors = () => {
    const { saveError, saveErrorMessage, submitFailed, invalid } = this.props;
    let errors = {
      ...this.state.errors,
      invalid: submitFailed ? invalid : false,
      saveError: saveError,
      _error: saveError ? saveErrorMessage : submitFailed ? 'One or more of the fields you entered are invalid.' : null,
    };
    return errors;
  };

  renderActiveBadge = () => {
    return <ActiveStatusBadge isActive={this.props.isActive} />;
  };

  renderActiveSwitch = () => {
    return (
      <ActiveStatusModal
        content={this.props.curOfferWallAd}
        isActive={this.props.isActive}
        disabled={this.state.loading}
        getErrors={() => this.state.errors}
        onClickSubmit={this.onClickActiveSwitchSubmit}
        modalType={' offer wall ad'}
      />
    );
  };

  onClickActiveSwitchSubmit = (activeStatus) => {
    const { id } = this.state;
    let isActive = activeStatus !== undefined ? activeStatus : this.props.curOfferWallAd.active;
    const update = {
      id,
      isActive,
    };

    api.put(`offerwallads/${update.id}/active`, update).then((result) => {
      console.log(JSON.stringify(result));
      this.initializeOfferWallAdData();
    });
  };

  // Lifecycle Fns
  componentDidMount() {
    this.props.updateCriteriaType('Offer Wall Ad Criteria');
    this.props.updateSelector('Offer Wall Ad Detail');

    this.props.fetchAllOfferActions();
    this.props.fetchAllAdvertisers();
    this.props.fetchAllHandlers();

    // Retrieve single entity if id provided
    const { offerWallAdId } = this.props.match.params;
    if (offerWallAdId) {
      this.setState({ isEditMode: true, id: parseInt(offerWallAdId) }, this.initializeOfferWallAdData);
    } else {
      // No entity, initialize create mode
      this.setState({ isEditMode: false, id: null }, this.initializeOfferWallAdData);
    }
  }

  render() {
    const errors = this.getErrors();
    const shouldErrorRender = Object.keys(errors).some((x) => errors[x]);

    return (
      <div className='animated fadeIn'>
        <form onSubmit={this.props.handleSubmit(this.onSubmitForm)}>
          <Card>
            <CardHeader>
              <Row>
                <Col lg='11'>
                  <span style={{ display: 'flex', alignItems: 'baseline' }}>
                    {this.renderActiveBadge()}&nbsp;&nbsp;
                    <h3>{this.props.offerWallAdName}</h3>&nbsp;&nbsp;
                    <h6>
                      <em>Offer Wall Ad ID: </em>
                      {this.state.id}
                    </h6>
                  </span>
                </Col>
                <Col lg='1' className='float-left'>
                  {this.renderActiveSwitch()}
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <Row>
                <Col lg={3}>
                  <Field
                    required
                    type='text'
                    component={renderField}
                    id='offerWallAdName'
                    name='offerWallAdName'
                    placeholder='Offer Wall Ad Name'
                    label='Offer Wall Ad Name'
                  />
                </Col>
                <Col lg='3'>
                  <Label>Advertiser</Label>
                  <Field
                    type='select'
                    name='advertiser'
                    component={renderComboBox}
                    data={this.props.advertisers}
                    textField='name'
                    valueField='id'
                  />
                </Col>
              </Row>
              <Row>
                <Col lg='6'>
                  <Field
                    required
                    type='text'
                    component={renderField}
                    id='offerWallAdCopy'
                    name='offerWallAdCopy'
                    placeholder='Copy'
                    label='Offer Wall Ad Copy'
                  />
                </Col>
              </Row>
              <Row>
                <Col lg='6'>
                  <Field
                    required
                    type='text'
                    component={renderField}
                    id='offerWallAdCTACopy'
                    name='offerWallAdCTACopy'
                    placeholder='Copy'
                    label='Call to Action Copy'
                  />
                </Col>
              </Row>
            </CardBody>
          </Card>
          <ImageCard
            handleError={this.handleBrokenURL}
            fieldName='offerWallAdImage'
            imageFile={this.state.imageFile}
            clearOnClick={this.resetForm}
            setImageFile={(imageFile) => this.setState({ imageFile: [imageFile] })}
          />
          {/* ---- Actions ---- */}
          <Card>
            <CardHeader>
              <Row>
                <Col onClick={this.toggleActions} id='click' lg={10}>
                  {this.state.collapseAction ? (
                    <div>
                      <h4>
                        Actions&nbsp;&nbsp; <i className='fa fa-chevron-up opened' />
                      </h4>
                    </div>
                  ) : (
                    <div>
                      <h4>
                        Actions&nbsp;&nbsp; <i className='fa fa-chevron-down closed' />
                      </h4>
                    </div>
                  )}
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <Collapse isOpen={this.state.collapseAction}>
                <Col lg={12}>
                  <FieldArray
                    name='offerWallAdActions'
                    component={OfferActionsForm}
                    triggerTypes={renderOfferPostAction}
                    entityName={'Offer Wall Ad'}
                  />
                </Col>
              </Collapse>
            </CardBody>
          </Card>

          {/* ---- Criteria  ---- */}
          <Criteria />

          <Row>
            <Col lg={6}>
              {shouldErrorRender && <Alert color='danger'>{errors._error}</Alert>}
              {this.props.isOfferWallAdSaved && <Alert color='success'>Offer Wall Ad Saved Successfully</Alert>}
            </Col>
            <Col lg={6}>
              <CancelEntityModal getErrors={this.getErrors} entityUrl={'/campaigns/offerwallads'} />
              <SaveEntityModal
                isEditMode={this.state.isEditMode}
                entityName={this.props.offerWallAdName}
                entityType={'Offer Wall Ad'}
                getErrors={this.getErrors}
                onClickSubmit={this.props.handleSubmit(this.onSubmitForm)}
                submitting={this.props.submitting}
              />
            </Col>
          </Row>
        </form>
      </div>
    );
  }
}

const form = reduxForm({
  form: 'offerWallAdDetails',
  validate,
});

function mapStateToProps(state) {
  const { selector } = state.formSelector;
  const { curOfferWallAd, isOfferWallAdSaved, saveError, saveErrorMessage } = state.offerWallAd;
  const { additionalOffersArray, additionalOffersObj } = state.criteria;
  const { advertisers } = state.advertiser;
  const { handlers } = state.metadata;
  const capAmount = state.form.offerWallAdDetails?.values?.capAmount;
  // const capSegmentConfiguration = state.form.offerWallAdDetails?.values?.capSegmentConfiguration || [];

  return {
    curOfferWallAd,
    capAmount,
    // capSegmentConfiguration,
    offerCriteria: curOfferWallAd.offerCriteria,
    offerWallAdName: selector(state, 'offerWallAdName'),
    isActive: curOfferWallAd.isActive !== null ? curOfferWallAd.isActive : false,
    isOfferWallAdSaved,
    saveError,
    saveErrorMessage,
    additionalOffersObj,
    additionalOffersArray,
    advertisers,
    handlers,
  };
}

export default connect(mapStateToProps, {
  createOfferWallAdApi,
  getOfferWallAd,
  editOfferWallAd,
  fetchAllOfferActions,
  fetchAllAdvertisers,
  fetchAllHandlers,
  updateSelector,
  updateCriteriaType,
})(form(withRouter(OfferWallAdDetails)));
