import React, { useState, useEffect, useCallback } from 'react';
import toast from 'react-hot-toast';
import { Card, CardBody, CardHeader, Col, Row, Label, Button } from 'reactstrap';
import { renderComboBox } from '../Offers/OfferTitle/renderConsts';
import { Field, reduxForm } from 'redux-form';
import { useGetContainerById, useUpdateContainer, useContainersTable } from '../Containers/queries';
import { ZuulEditor } from '../../components/ZuulEditor/ZuulEditor';

const SimpleContainerEditor = ({ containerData, setContainerData }) => {
  const { data: containers } = useContainersTable();
  const { mutateAsync: updateContainer, isLoading } = useUpdateContainer();
  const { data: container, mutate: getContainerById } = useGetContainerById();
  const [validationErrors, setValidationErrors] = useState(false);

  const handleEditorChange = (value, errors) => {
    setValidationErrors(errors);
    if (errors) {
      toast.dismiss();
      toast.error('Invalid HTML detected, please fix the highlighted errors!', { duration: Infinity });
    } else {
      toast.dismiss();
    }
    setContainerData({ ...container, content: value });
  };

  const handleSave = useCallback(() => {
    toast.promise(updateContainer(containerData), {
      loading: 'Updating container...',
      success: (data) => (data.status === 200 ? 'Container updated successfully!' : 'No content changes detected.'),
      error: (err) => err?.response?.data?.message || err.message,
    });
  }, [containerData, updateContainer]);

  useEffect(() => {
    if (container) setContainerData(container);
  }, [container]);

  return (
    <>
      {containers && (
        <Card>
          <CardHeader>
            <Row>
              <Col lg={9} style={{ display: 'flex', alignItems: 'baseline' }}>
                <Label for='container' style={{ width: '7em' }}>
                  Container:
                </Label>
                <Field
                  required
                  type='select'
                  component={renderComboBox}
                  id='container'
                  name='container'
                  placeholder='Container'
                  label='Landing Page Container'
                  textField='name'
                  valueField='id'
                  data={containers}
                  onChange={(value) => value.id && getContainerById(value.id)}
                  style={{ width: '100%' }}
                />
              </Col>
              <Col
                lg={3}
                style={{ display: 'flex', alignItems: 'baseline', justifyContent: 'flex-end', marginLeft: 'auto' }}
              >
                <Button color='success' onClick={handleSave} disabled={isLoading || validationErrors}>
                  Save
                </Button>
              </Col>
            </Row>
          </CardHeader>
          <CardBody style={{ padding: 0 }}>
            <Row>
              <Col lg='12'>
                <ZuulEditor
                  height='36.4vh'
                  content={containerData.content}
                  handleEditorChange={handleEditorChange}
                  minimapOn
                />
              </Col>
            </Row>
          </CardBody>
        </Card>
      )}
    </>
  );
};

const form = reduxForm({
  form: 'simpleContainerEditor',
});

export default form(SimpleContainerEditor);
