import _ from 'lodash';
import api from '../index';
export const fetchAll = async (url) => {
  const response = await api.get(`${url}?limit=100&offset=0`);
  const totalPages = response.data.totalPages;
  let allData = [...response.data.content];

  const requests = [];
  for (let i = 1; i < totalPages; i++) {
    requests.push(api.get(`${url}?limit=100&offset=${i}`));
  }

  const responses = await Promise.all(requests);
  responses.forEach((res) => {
    allData.push(...res.data.content);
  });

  return _.uniqBy(allData, 'id');
};
