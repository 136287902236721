import {
  SAVE_AS_ENTITY_NAME,
  RESET_MODAL,
  DELETE_ENTITY,
  DELETE_ENTITIES,
  UPDATE_ENTITY_ERROR,
} from '../actions/types';

const initialState = {
  newEntityName: '',
  error: {},
  saveError: false,
};
export default function(state = initialState, action) {
  switch (action.type) {
    case SAVE_AS_ENTITY_NAME:
      return {
        ...state,
        newEntityName: action.payload,
      };
    case RESET_MODAL:
      return {
        ...state,
        saveError: false,
      };
    case DELETE_ENTITY:
      return {
        ...state,
        saveError: false,
      };
    case DELETE_ENTITIES:
      return {
        ...state,
        saveError: false,
      };
    case UPDATE_ENTITY_ERROR:
      return {
        ...state,
        saveError: true,
        error: action.payload,
      };
    default:
      return state;
  }
}
